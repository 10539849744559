import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./post_previews.css";
import IG_icons from "../../../images/ig_icons.png";
import IGicon from "../../../images/social_media/instagram.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"


const PreviewIG = ({ publicationData, calSelectedEvent, selectedPhotos }) => {
    let ig_check = false;

    let ig_index;

    if (calSelectedEvent) {
        ig_index = publicationData.social_accounts.findIndex((account) => account._id.toString() === calSelectedEvent._id.toString());
    }
    else {
        ig_index = publicationData.social_accounts.findIndex((account) => account.media === "Instagram");
    }

    // if (ig_index !== -1 && selectedPhotos.length > 0) {
    //     ig_check = true;
    // }

    if (ig_index !== -1) {
        ig_check = true;
    }

    const hashtagsFormat = (hashtag) => {
        const formattedText = '<span className="ig_tags">' + hashtag + '</span>';
        return formattedText
    }

    const formatText = (text) => {
        let formatted_text = text;
        const regex = /#[0-9A-Za-zÀ-ÿ '-]*/g;
        formatted_text.replace(regex, hashtagsFormat);

        return formatted_text.replace(/\r\n|\r|\n/g, "<br />") + '<br />';
    }

    const formatFirstComment = (comment) => {
        let formatted_text = comment;
        return formatted_text.replace(/\r\n|\r|\n/g, "<br />");
    }

    const source_text = publicationData.alt_text_Instagram || publicationData.text;

    // const optimizedMediaURL = (url) => {
    //     if (url.includes("tr:")) {
    //         return url;
    //     }
    //     const url_options = "tr:n-ik_ml_thumbnail";
    //     // const url_options = "tr:h-150,pr-true";
    //     let arrayedURL = url.split("/");
    //     arrayedURL.splice(arrayedURL.length - 1, 0, url_options);
    //     const optimized_url = arrayedURL.join('/');
    //     return optimized_url;
    // }

    return (
        <div>
            {ig_check ?
                <div className={publicationData.draft ? "preview_card preview_card_draft ig_preview" : "preview_card ig_preview"}>
                    <div className="sm-badge-wrapper">
                        <img src={IGicon} alt="Instagram" />
                    </div>
                    <div className="ig_header">
                        <div className="header_block">
                            <img className="ig_icon" src={publicationData.social_accounts[ig_index].profile_picture_url} alt="" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <span className="ig_nickname">{publicationData.social_accounts[ig_index].social_name}</span>
                        </div>
                        <div className="header_block">
                            <svg viewBox="0 0 24 24">
                                <path fill="current" d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z" />
                            </svg>
                        </div>
                    </div>
                    {publicationData.bulk && selectedPhotos.length > publicationData.bulk_media_index ?
                        selectedPhotos[publicationData.bulk_media_index].type === "image" ?
                            <div className='ig_photo_container'>
                                <img src={selectedPhotos[publicationData.bulk_media_index].file_url} alt="" />
                                {selectedPhotos[publicationData.bulk_media_index].ig_tags && selectedPhotos[publicationData.bulk_media_index].ig_tags.length > 0 ?
                                    selectedPhotos[publicationData.bulk_media_index].ig_tags.map((tag, t) => {
                                        return (
                                            <div key={"photo_tag_" + t} className="photo_tag" style={{ top: tag.ig_Y * 100 + "%", left: tag.ig_X * 100 + "%" }}>{tag.tag}</div>
                                        )
                                    })
                                    : ""
                                }
                                {selectedPhotos[publicationData.bulk_media_index].ig_product_tags && selectedPhotos[publicationData.bulk_media_index].ig_product_tags.length > 0 ?
                                    selectedPhotos[publicationData.bulk_media_index].ig_product_tags.map((tag, t) => {
                                        return (
                                            <div key={"photo_tag_product_" + t} className="photo_tag" style={{ top: tag.ig_Y * 100 + "%", left: tag.ig_X * 100 + "%" }}>
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="current" d="M21.41 11.58L12.41 2.58A2 2 0 0 0 11 2H4A2 2 0 0 0 2 4V11A2 2 0 0 0 2.59 12.42L11.59 21.42A2 2 0 0 0 13 22A2 2 0 0 0 14.41 21.41L21.41 14.41A2 2 0 0 0 22 13A2 2 0 0 0 21.41 11.58M13 20L4 11V4H11L20 13M6.5 5A1.5 1.5 0 1 1 5 6.5A1.5 1.5 0 0 1 6.5 5Z" />
                                                </svg>
                                                {tag.product_name}
                                            </div>
                                        )
                                    })
                                    : ""
                                }
                            </div>
                            :
                            <div className="media_wrapper">
                                {selectedPhotos[publicationData.bulk_media_index].thumbnail_url ?
                                    <img key={selectedPhotos[publicationData.bulk_media_index]._id} src={selectedPhotos[publicationData.bulk_media_index].thumbnail_url} alt="" />
                                    :
                                    <video key={selectedPhotos[publicationData.bulk_media_index]._id}>
                                        <source src={selectedPhotos[publicationData.bulk_media_index].file_url} />
                                    </video>
                                }
                                <div className='video_icon'>
                                    <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                        <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                    </svg>
                                </div>
                                {/* <div className="video_overlay">
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                    </svg>
                                </div> */}
                            </div>
                        : selectedPhotos.length === 1 && selectedPhotos[0].type === "image" ?
                            <div className='ig_photo_container'>
                                <img src={selectedPhotos[0].file_url} alt="" />
                                {selectedPhotos[0].ig_tags && selectedPhotos[0].ig_tags.length > 0 ?
                                    selectedPhotos[0].ig_tags.map((tag, t) => {
                                        return (
                                            <div key={"photo_tag_" + t} className="photo_tag" style={{ top: tag.ig_Y * 100 + "%", left: tag.ig_X * 100 + "%" }}>{tag.tag}</div>
                                        )
                                    })
                                    : ""
                                }
                                {selectedPhotos[0].ig_product_tags && selectedPhotos[0].ig_product_tags.length > 0 ?
                                    selectedPhotos[0].ig_product_tags.map((tag, t) => {
                                        return (
                                            <div key={"photo_tag_product_" + t} className="photo_tag" style={{ top: tag.ig_Y * 100 + "%", left: tag.ig_X * 100 + "%" }}>
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="current" d="M21.41 11.58L12.41 2.58A2 2 0 0 0 11 2H4A2 2 0 0 0 2 4V11A2 2 0 0 0 2.59 12.42L11.59 21.42A2 2 0 0 0 13 22A2 2 0 0 0 14.41 21.41L21.41 14.41A2 2 0 0 0 22 13A2 2 0 0 0 21.41 11.58M13 20L4 11V4H11L20 13M6.5 5A1.5 1.5 0 1 1 5 6.5A1.5 1.5 0 0 1 6.5 5Z" />
                                                </svg>
                                                {tag.product_name}
                                            </div>
                                        )
                                    })
                                    : ""
                                }
                            </div>
                            :
                            selectedPhotos.length > 1 && selectedPhotos[0].type === "image" ?
                                <Carousel
                                    showArrows={false}
                                    infiniteLoop={false}
                                    showThumbs={false}
                                    showStatus={false}
                                    autoPlay={false}
                                    emulateTouch={true}
                                >
                                    {selectedPhotos.map((photo, p) => {
                                        return (
                                            <div className='ig_photo_container' key={photo._id}>
                                                <img src={photo.file_url} alt="" />
                                                {photo.ig_tags && photo.ig_tags.length > 0 ?
                                                    photo.ig_tags.map((tag, t) => {
                                                        return (
                                                            <div key={photo._id + "_photo_tag_" + t} className="photo_tag" style={{ top: tag.ig_Y * 100 + "%", left: tag.ig_X * 100 + "%" }}>{tag.tag}</div>
                                                        )
                                                    })
                                                    : ""
                                                }
                                                {photo.ig_product_tags && photo.ig_product_tags.length > 0 ?
                                                    photo.ig_product_tags.map((tag, t) => {
                                                        return (
                                                            <div key={photo._id + "_photo_tag_product_" + t} className="photo_tag" style={{ top: tag.ig_Y * 100 + "%", left: tag.ig_X * 100 + "%" }}>
                                                                <svg viewBox="0 0 24 24">
                                                                    <path fill="current" d="M21.41 11.58L12.41 2.58A2 2 0 0 0 11 2H4A2 2 0 0 0 2 4V11A2 2 0 0 0 2.59 12.42L11.59 21.42A2 2 0 0 0 13 22A2 2 0 0 0 14.41 21.41L21.41 14.41A2 2 0 0 0 22 13A2 2 0 0 0 21.41 11.58M13 20L4 11V4H11L20 13M6.5 5A1.5 1.5 0 1 1 5 6.5A1.5 1.5 0 0 1 6.5 5Z" />
                                                                </svg>
                                                                {tag.product_name}
                                                            </div>
                                                        )
                                                    })
                                                    : ""
                                                }
                                            </div>
                                        )
                                    })
                                    }
                                </Carousel>
                                :
                                selectedPhotos.length === 1 && selectedPhotos[0].type === "video" ?
                                    <div className="media_wrapper">
                                        {selectedPhotos[0].thumbnail_url ?
                                            <img key={selectedPhotos[0]._id} src={selectedPhotos[0].thumbnail_url} alt="" />
                                            :
                                            <video key={selectedPhotos[0]._id}>
                                                <source src={selectedPhotos[0].file_url} />
                                            </video>
                                        }
                                        <div className='video_icon'>
                                            <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                                <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                            </svg>
                                        </div>
                                        {/* <div className="video_overlay">
                                            <svg viewBox="0 0 24 24">
                                                <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                            </svg>
                                        </div> */}
                                    </div>
                                    : ""
                    }
                    <img className="ig_icons" src={IG_icons} alt="" />
                    <span className="ig_likes">323 likes</span>
                    <div className="content ig_content">
                        <div className="text"><div dangerouslySetInnerHTML={{ __html: formatText(source_text) }}></div></div>
                        <div className="ig_tags">{publicationData.tags} </div>
                    </div>
                    {/* {publicationData.first_comment && publicationData.first_comment.length > 0 ? */}
                    {publicationData.first_comment && publicationData.first_comment.length > 0 && publicationData.first_comment_accounts && publicationData.first_comment_accounts.length > 0 && publicationData.first_comment_accounts.some(el => el._id.toString() === publicationData.social_accounts[ig_index]._id.toString()) ?
                        <div className="preview_first_comment">
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="M12 28h24v-3H12Zm0-6.5h24v-3H12Zm0-6.5h24v-3H12Zm32 29-8-8H7q-1.15 0-2.075-.925Q4 34.15 4 33V7q0-1.15.925-2.075Q5.85 4 7 4h34q1.2 0 2.1.925Q44 5.85 44 7ZM7 7v26h30.25L41 36.75V7H7Zm0 0v29.75V7Z" />
                            </svg>
                            <p className="p-small text-grey" dangerouslySetInnerHTML={{ __html: formatFirstComment(publicationData.first_comment) }}></p>
                        </div>
                        : ''}
                </div>
                : ""
            }
        </div>
    )
}

export default PreviewIG

// {watermark ? "#SocialPika" : ""}