import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useAuth } from "../../auth";
import { useTranslation } from "react-i18next";
import moment from 'moment'
import StateModal from '../Loader/StateModal';

import ContentRSS from './EditorComponents/ContentRSS';
import OptionsRSS from './EditorComponents/OptionsRSS';
import PublishRSS from './EditorComponents/PublishRSS';

import UpgradeModal from '../Plans/UpgradeModal';
import SocialsModal from '../Manage/ManageComponents/SocialsModal';

import PreviewBlank from '../Composer/PostPreviews/PreviewBlank';
import PreviewFB from '../Composer/PostPreviews/PreviewFB';
import PreviewLK from '../Composer/PostPreviews/PreviewLK';
import PreviewTW from '../Composer/PostPreviews/PreviewTW';

import "./editor.css"


const RSSPostEditor = (props) => {
    const { authTokens } = useAuth();
    const currentPlan = Number(authTokens.plan);
    const { t } = useTranslation(['composer']);

    const headers = {
        Authorization: authTokens.token,
    };

    const [parserErrorMsg, setParserErrorMsg] = useState('');

    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const displayUpgradeModal = () => {
        setShowUpgradeModal(!showUpgradeModal);
    }

    const [showSocialsModal, setShowSocialsModal] = useState(false);
    const closeSocialsModal = () => {
        setShowSocialsModal(false);
    }

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }

    const feed_id = props.match.params.id;


    const [editorMenuItem, setEditorMenuItem] = useState('content');


    const initialPublicationData = {
        _id: "",
        name: "",
        tags: "",
        draft: false,
        company_id: authTokens.account_id,
        social_accounts: [],
        rss_link: "",
        new_articles_only: 'new_only',
        publishing_schedule: "immediate",
        custom_schedule_id: "",
        text: "",
        link_url: "",
        media: [],
        medias: [],
    }
    const [publicationData, setPublicationData] = useState(initialPublicationData);



    const [linkPreview, setLinkPreview] = useState();
    const [urlLoader, setUrlLoader] = useState(false);

    const grabRSSFeed = async () => {


        if (publicationData.rss_link.length === 0) return;

        setParserErrorMsg('');

        setUrlLoader(true);

        const payload = {
            rss_url: publicationData.rss_link
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/scraper/rss_parser", payload, {
                headers: headers,
            })
            .then((response) => {
                setLinkPreview(response.data);
                setPublicationData({
                    ...publicationData,
                    name: response.data.site,
                    text: response.data.title
                })
            })
            .catch((error) => {
                setParserErrorMsg(error.response.data);
            });

        setUrlLoader(false);
    }



    const fetchRSSFeedDetails = async (_id) => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });
        const payload = {
            _id: _id,
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/rss/get_single_feed_data", payload, {
                headers: headers,
            })
            .then((response) => {
                const feed = response.data;
                // console.log(feed);
                setPublicationData({
                    _id: feed._id,
                    name: feed.name,
                    tags: feed.tags,
                    draft: feed.draft,
                    company_id: feed.company_id,
                    social_accounts: feed.social_accounts,
                    rss_link: feed.feed_url,
                    new_articles_only: feed.new_articles_only ? 'new_only' : 'old_and_new',
                    publishing_schedule: feed.immediate ? "immediate" : "custom",
                    custom_schedule_id: feed.custom_schedule ? feed.custom_schedule._id : "",
                    text: "",
                    link_url: "",
                    medias: [],
                });


                // grabRSSFeed());

                // setEditingCustomScheduleData({
                //     editing: true,
                //     custom_schedule_choice: !feed.immediate,
                //     custom_schedule_data: feed.custom_schedule
                // })



            })
            .catch(() => {
            });
        closeStateModal();
    }


    const [allSMAccounts, setAllSMAccounts] = useState([]);
    const [SMAccounts, setSMAccounts] = useState([]);
    const [SMGroups, setSMGroups] = useState([]);

    const recordPost = async (type) => {
        let authError = false;

        let post_payload = publicationData;

        if (type === 'draft') {
            post_payload.draft = true;
        }
        else if (type === 'now') {
            post_payload.date = moment().add(3, 'minutes');
            post_payload.draft = false;
        }

        if (!post_payload.rss_link) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('rss_link_missing')
            });
            return
        }

        if (post_payload.publishing_schedule === 'custom' && !post_payload.custom_schedule_id) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('custom_schedule_needed')
            });
            return
        }


        if (!post_payload.draft && (!post_payload.social_accounts || post_payload.social_accounts.length === 0)) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('select_a_social_account')
            });
            return
        }



        // if (!post_payload.date && type === "schedule") {
        //     setStateModalOptions({
        //         ...stateModalOptions,
        //         display: true,
        //         loader: false,
        //         status: "info",
        //         message: t('no_date')
        //     });
        //     return
        // }

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });


        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/rss/save_rss_feed", post_payload, {
                headers: headers,
            })
            .then(() => {
                if (!post_payload.draft) {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        upgradeOption: currentPlan === 0 ? true : false,
                        status: "check",
                        message: t('post_recorded'),
                        button_text: t('go_to_calendar'),
                        button_link: "/calendar/monthly"
                    });
                }
                else {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        upgradeOption: currentPlan === 0 ? true : false,
                        status: "check",
                        message: t('post_recorded'),
                        button_text: t('go_to_drafts'),
                        button_link: "/drafts"
                    });
                }
                // setPublicationData(initialPublicationData);
                // setUrlToScrap();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
                else {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        status: "error",
                        message: t('post_not_recorded')
                    });
                }

            });
        if (authError) return;

    }

    const fetchAvailableSocialMedia = async () => {
        let authError = false;
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });


        // NEW FUNCTION - TEAM COMPLIANT

        const payload = {
            user_id: authTokens.user_id,
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_accounts_for_rss", payload, {
                headers: headers,
            })
            .then((response) => {
                closeStateModal();
                setAllSMAccounts(response.data.sm_accounts);
                setSMAccounts(response.data.sm_accounts);
                setSMGroups(response.data.sm_groups);
                if (response.data.sm_accounts.length === 0) {
                    setShowSocialsModal(true);
                }

            })
            .catch((error) => {
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
            });
        if (authError) return;
    }

    useEffect(() => {

        if (feed_id) {
            fetchRSSFeedDetails(feed_id);
        }

        fetchAvailableSocialMedia();

        props.changeNav("New Post", "NewPost");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div className="content_row_maxed nowrap">
            {showUpgradeModal ? <UpgradeModal upgrade={true} closeModal={() => setShowUpgradeModal(false)} closeButton={true} /> : ''}
            {showSocialsModal ? <SocialsModal closeSocialsModal={closeSocialsModal} /> : ""}
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            <div className="editor_card">
                <div className='editor_menu'>
                    <div className={editorMenuItem === 'content' ? 'editor_menu_item active' : 'editor_menu_item'} onClick={() => setEditorMenuItem('content')}>
                        {t('content')}
                    </div>
                    <div className={editorMenuItem === 'options' ? 'editor_menu_item active' : 'editor_menu_item'} onClick={() => setEditorMenuItem('options')}>
                        {t('options')}
                    </div>
                    <div className={editorMenuItem === 'publication' ? 'editor_menu_item active' : 'editor_menu_item'} onClick={() => setEditorMenuItem('publication')}>
                        {t('publication')}
                    </div>
                    <div className='editor_menu_indicator'></div>
                </div >
                <div className='editor_components margin-t1'>
                    <ContentRSS
                        editorMenuItem={editorMenuItem}
                        publicationData={publicationData}
                        setPublicationData={setPublicationData}
                        allSMAccounts={allSMAccounts}
                        SMAccounts={SMAccounts}
                        setSMAccounts={setSMAccounts}
                        SMGroups={SMGroups}
                        grabRSSFeed={grabRSSFeed}
                        parserErrorMsg={parserErrorMsg}
                        urlLoader={urlLoader}
                    />
                    <OptionsRSS
                        editorMenuItem={editorMenuItem}
                        publicationData={publicationData}
                        setPublicationData={setPublicationData}
                    />
                    <PublishRSS
                        authTokens={authTokens}
                        displayUpgradeModal={displayUpgradeModal}
                        editorMenuItem={editorMenuItem}
                        publicationData={publicationData}
                        setPublicationData={setPublicationData}
                        recordPost={recordPost}
                    />
                </div>
            </div >
            <div className="card_preview">
                <div className='composer_component_thread'>
                    <div className="title">
                        {t('post_preview')}
                    </div>
                    <div className='composer_component_previews'>
                        {<PreviewBlank publicationData={publicationData} selectedPhotos={publicationData.medias} linkPreview={linkPreview} />}
                        {<PreviewFB publicationData={publicationData} selectedPhotos={publicationData.medias} linkPreview={linkPreview} />}
                        {<PreviewLK publicationData={publicationData} selectedPhotos={publicationData.medias} linkPreview={linkPreview} />}
                        {<PreviewTW publicationData={publicationData} selectedPhotos={publicationData.medias} linkPreview={linkPreview} />}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default RSSPostEditor