import React, { useState, useEffect } from 'react'
import moment from 'moment'

import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import PNicon from "../../../images/social_media/pinterest.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import TKicon from "../../../images/social_media/tiktok-spk.svg";
import YTicon from "../../../images/social_media/youtube.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"

import "./post_preview_generic.css";

const PostPreviewGeneric = ({ event }) => {
    let ig_check = false;

    if (event.sm_account.media === 'Instagram') {
        ig_check = true;
    }

    const formatText = (text) => {
        if (!text) return;
        let formatted_text = text;

        if (event.sm_account.media === 'Facebook' && event.scheduled_post.alt_text_Facebook && event.scheduled_post.alt_text_Facebook.length > 0) {
            formatted_text = event.scheduled_post.alt_text_Facebook
        }
        else if (event.sm_account.media === 'Instagram' && event.scheduled_post.alt_text_Instagram && event.scheduled_post.alt_text_Instagram.length > 0) {
            formatted_text = event.scheduled_post.alt_text_Instagram
        }
        else if (event.sm_account.media === 'GMB' && event.scheduled_post.alt_text_GMB && event.scheduled_post.alt_text_GMB.length > 0) {
            formatted_text = event.scheduled_post.alt_text_GMB
        }
        else if (event.sm_account.media === 'LinkedIn' && event.scheduled_post.alt_text_LinkedIn && event.scheduled_post.alt_text_LinkedIn.length > 0) {
            formatted_text = event.scheduled_post.alt_text_LinkedIn
        }
        else if (event.sm_account.media === 'Twitter' && event.scheduled_post.alt_text_Twitter && event.scheduled_post.alt_text_Twitter.length > 0) {
            formatted_text = event.scheduled_post.alt_text_Twitter
        }


        if (event.scheduled_post.thread) {
            formatted_text = '🧵' + text;
        }

        if (formatted_text && formatted_text.length > 80) {
            formatted_text = formatted_text.substring(0, 80) + "...";
        }
        return formatted_text;
    }

    const formatTags = (tags) => {
        if (!tags) return;

        let formatted_text = tags;
        if (formatted_text && formatted_text.length > 40) {
            formatted_text = formatted_text.substring(0, 40) + "...";
        }
        return formatted_text;
    }

    const formatLink = (link) => {
        if (!link) return;

        let formatted_link = link.replace('https://', '');
        formatted_link = formatted_link.replace('http://', '');
        formatted_link = formatted_link.replace('www.', '');
        formatted_link = 'www.' + formatted_link
        if (formatted_link.length > 30) {
            formatted_link = formatted_link.substring(0, 30) + "...";
        }

        return formatted_link;
    }

    // const optimizedMediaURL = (url) => {
    //     if (url.includes("tr:")) {
    //         return url;
    //     }
    //     const url_options = "tr:n-ik_ml_thumbnail";
    //     // const url_options = "tr:h-150,pr-true";
    //     let arrayedURL = url.split("/");
    //     arrayedURL.splice(arrayedURL.length - 1, 0, url_options);
    //     const optimized_url = arrayedURL.join('/');
    //     return optimized_url;
    // }


    const checkAccountPhoto = (calendarEvent) => {
        switch (calendarEvent.media) {
            case "Facebook":
                const fb_picture_url = "https://graph.facebook.com/v11.0/" + calendarEvent.social_id + "/picture";
                return (
                    <div className="generic-event-profile">
                        <img className="generic-event-profile-picture" src={fb_picture_url} alt={calendarEvent.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="generic-event-profile-media" src={FBicon} alt="Media" />
                    </div>)
            case "Twitter":
                return (
                    <div className="generic-event-profile">
                        <img className="generic-event-profile-picture" src={calendarEvent.profile_picture_url || SPKNoProfile} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="generic-event-profile-media" src={TWicon} alt="Media" />
                    </div>)
            case "LinkedIn":
                return (
                    <div className="generic-event-profile">
                        <img className="generic-event-profile-picture" src={calendarEvent.profile_picture_url || SPKNoProfile} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="generic-event-profile-media" src={LKicon} alt="Media" />
                    </div>)
            case "Instagram":
                return (
                    <div className="generic-event-profile">
                        <img className="generic-event-profile-picture" src={calendarEvent.profile_picture_url || SPKNoProfile} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="generic-event-profile-media" src={IGicon} alt="Media" />
                    </div>)
            case "Pinterest":
                return (
                    <div className="generic-event-profile">
                        <img className="generic-event-profile-picture" src={calendarEvent.profile_picture_url || SPKNoProfile} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="generic-event-profile-media" src={PNicon} alt="Media" />
                    </div>)
            case "GMB":
                return (
                    <div className="generic-event-profile">
                        <img className="generic-event-profile-picture" src={calendarEvent.profile_picture_url || SPKNoProfile} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="generic-event-profile-media" src={GMBicon} alt="Media" />
                    </div>)
            case "TikTok":
                return (
                    <div className="generic-event-profile">
                        <img className="generic-event-profile-picture" src={calendarEvent.profile_picture_url || SPKNoProfile} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="generic-event-profile-media" src={TKicon} alt="Media" />
                    </div>)
            case "Youtube":
                return (
                    <div className="generic-event-profile">
                        <img className="generic-event-profile-picture" src={calendarEvent.profile_picture_url || SPKNoProfile} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="generic-event-profile-media" src={YTicon} alt="Media" />
                    </div>)
            default:
                return (
                    <div className="generic-event-profile">
                        <img className="generic-event-profile-picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" />
                        <img className="generic-event-profile-media" src={FBicon} alt="Media" />
                    </div>)
        }
    }

    const [mediaSource, setMediaSource] = useState([]);


    useEffect(() => {
        if (!event.scheduled_post.thread) {
            setMediaSource([...event.scheduled_post.medias])
        }
        else {
            setMediaSource([...event.scheduled_post.thread_content[0].medias])
        }
    }, [event])


    return (
        <div className='generic-event-wrapper'>
            <div className='generic-event-header'>
                {checkAccountPhoto(event.sm_account)}
                <div className='generic-event-header-text'>
                    <p className="generic-event-social-name"> {event.sm_account.social_name}</p>
                    <p className="generic-event-pub-date"> {moment(event.publication_date).format('DD MMMM YYYY, HH:mm')}</p>
                </div>

            </div>

            <div className={ig_check ? 'generic-event-content reversed' : 'generic-event-content'}>
                <div className='generic-event-text-content'>
                    <div className="generic-event-text"><div dangerouslySetInnerHTML={{ __html: event.scheduled_post.thread ? formatText(event.scheduled_post.thread_content[0].text) : formatText(event.scheduled_post.text) }}></div></div>
                    <div className="generic-event-tags">{formatLink(event.scheduled_post.link_url)}</div>
                    <div className="generic-event-tags">{formatTags(event.scheduled_post.tags)}</div>
                </div>
                {event.scheduled_post.bulk && mediaSource.length > event.bulk_media_index ?
                    mediaSource[event.bulk_media_index].type === "image" ?
                        <div className='generic-event-media-content'>
                            <img src={mediaSource[event.bulk_media_index].file_url} alt="" />
                        </div>
                        :
                        <span className="generic-event-media-content">
                            {mediaSource[event.bulk_media_index].thumbnail_url ?
                                <img key={mediaSource[event.bulk_media_index]._id} src={mediaSource[event.bulk_media_index].thumbnail_url} alt="" />
                                :
                                <video className="reference-thumb-video">
                                    <source src={mediaSource[event.bulk_media_index].file_url} />
                                </video>
                            }
                            <div className="video-overlay">
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                </svg>
                            </div>
                        </span>
                    : mediaSource.length > 0 ?
                        mediaSource[0].type === "image" ?
                            <div className='generic-event-media-content'>
                                <img src={mediaSource[0].file_url} alt="" />
                            </div>
                            :
                            <span className="generic-event-media-content">
                                {mediaSource[0].thumbnail_url ?
                                    <img key={mediaSource[0]._id} src={mediaSource[0].thumbnail_url} alt="" />
                                    :
                                    <video className="reference-thumb-video">
                                        <source src={mediaSource[0].file_url} />
                                    </video>
                                }
                                <div className="video-overlay">
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                    </svg>
                                </div>
                            </span>
                        : event.scheduled_post.lk_poll && event.scheduled_post.lk_poll.display && event.scheduled_post.lk_poll.question && event.scheduled_post.lk_poll.choices && event.scheduled_post.lk_poll.choices.length > 1 ?
                            <div className="lk_poll_block">
                                <div className='lk_poll_question'>{event.scheduled_post.lk_poll.question}</div>
                                {event.scheduled_post.lk_poll.choices.map((choice, c) => {
                                    if (choice.length > 0) {
                                        return (
                                            <div key={`choice_${c}`} className='lk_poll_choice_item'>{choice}</div>
                                        )
                                    }

                                })}
                            </div>
                            : ''
                }
            </div>

        </div>
    )
}

export default PostPreviewGeneric