import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useAuth } from "../../auth";
import { useTranslation } from "react-i18next";
import StateModal from '../Loader/StateModal';

import ThirdPartyAdd from './ManageComponents/ThirdPartyAdd';
import ThirdParties from './ManageComponents/ThirdParties';
import UpgradeModal from '../Plans/UpgradeModal';

const ThirdPartyManagement = (props) => {
  const { t, i18n } = useTranslation(['third_party']);

  const { authTokens } = useAuth();
  const isAdmin = authTokens.user_admin;
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const headers = {
    Authorization: authTokens.token,
  };
  const [partyData, setPartyData] = useState({
    third_party_name: '',
    third_party_email: ''
  })

  const [stateModalOptions, setStateModalOptions] = useState({
    display: false,
    status: "",
    message: "",
    loader: false
  });
  const closeStateModal = () => {
    setStateModalOptions({
      display: false,
      status: "",
      message: "",
      loader: false,
      button_text: "",
      button_link: ""
    })
  }

  const [SMGroups, setSMGroups] = useState([]);
  const [allSMAccounts, setAllSMAccounts] = useState([]);
  const [SMAccounts, setSMAccounts] = useState([]);
  const [selectedSMAccounts, setSelectedSMAccounts] = useState([]);
  const [thirdParties, setThirdParties] = useState([]);




  const saveThirdParty = async () => {
    if (!partyData.third_party_email || !partyData.third_party_name) {
      return;
    }

    if (selectedSMAccounts.length === 0) {
      setStateModalOptions({
        ...stateModalOptions,
        display: true,
        loader: false,
        status: "info",
        message: t('select_one_account')
      });
      return
    }

    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true
    });

    const payload = {
      company_id: authTokens.account_id,
      user_id: authTokens.user_id,
      name: partyData.third_party_name,
      email: partyData.third_party_email,
      language: i18n.language,
      accounts: selectedSMAccounts
    }

    await axios
      .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/third_party/add_party", payload, {
        headers: headers,
      })
      .then(() => {
        setSelectedSMAccounts([]);
        setPartyData({
          third_party_name: '',
          third_party_email: ''
        })
        fetchThirdParties();
      })
      .catch(() => {
      });

    closeStateModal();
  }


  const fetchAvailableSocialMedia = async () => {
    let authError = false;
    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true
    });

    // NEW FUNCTION - TEAM COMPLIANT
    const payload = {
      company_id: authTokens.account_id,
      user_id: authTokens.user_id
    }

    await axios
      .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_accounts_no_auth_check", payload, {
        headers: headers,
      })
      .then((response) => {
        setAllSMAccounts(response.data.sm_accounts);
        setSMAccounts(response.data.sm_accounts);
        setSMGroups(response.data.sm_groups);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          closeStateModal();
          props.checkUnauthorizedResponse(error.response.data);
          authError = true;
        }
      });

    if (authError) return;
    closeStateModal();
  }

  const fetchThirdParties = async () => {
    let authError = false;
    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true
    });

    // NEW FUNCTION - TEAM COMPLIANT
    const payload = {
      company_id: authTokens.account_id
    }

    await axios
      .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/third_party/get_third_parties", payload, {
        headers: headers,
      })
      .then((response) => {
        setThirdParties(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          closeStateModal();
          props.checkUnauthorizedResponse(error.response.data);
          authError = true;
        }
      });

    if (authError) return;
    closeStateModal();
  }

  useEffect(() => {
    if (authTokens.plan > 0 && authTokens.cai) {
      fetchAvailableSocialMedia();
      fetchThirdParties();
    }
    else {
      setShowUpgradeModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div>
      {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
      {showUpgradeModal ?
        <UpgradeModal upgrade={true} />
        :
        <div className='content_row_maxed'>
          <ThirdParties
            headers={headers}
            authTokens={authTokens}
            isAdmin={isAdmin}
            thirdParties={thirdParties}
            setThirdParties={setThirdParties}
            fetchThirdParties={fetchThirdParties}
            SMAccounts={SMAccounts}
            setStateModalOptions={setStateModalOptions}
            stateModalOptions={stateModalOptions}
            closeStateModal={closeStateModal} />
          {authTokens.plan > 0 && authTokens.cai ?
            <ThirdPartyAdd
              partyData={partyData}
              setPartyData={setPartyData}
              saveThirdParty={saveThirdParty}
              selectedSMAccounts={selectedSMAccounts}
              setSelectedSMAccounts={setSelectedSMAccounts}
              SMGroups={SMGroups}
              allSMAccounts={allSMAccounts}
              SMAccounts={SMAccounts}
              setSMAccounts={setSMAccounts}
            />
            : ''}
        </div>
      }
    </div>
  )
}

export default ThirdPartyManagement