import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";


import "./team_dropdown.css";

const TeamDropDown = ({ team, isAdmin, authTokens, userIdForLibrary, setUserIdForLibrary }) => {
    const { t } = useTranslation(['library']);


    const [showDropdown, setShowDropdown] = useState(false);
    const displayDropdown = () => {
        setShowDropdown(!showDropdown);
    }

    const [selectedTeammate, setSelectedTeammate] = useState({});
    const updateTeammate = (teammate_id) => {
        const indexOfMember = team.findIndex(member => member._id === teammate_id);
        if (indexOfMember !== -1) {
            setSelectedTeammate(team[indexOfMember])
        }
        else {
            return;
        }
    }


    useEffect(() => {
        if (userIdForLibrary && userIdForLibrary.length > 0) {
            updateTeammate(userIdForLibrary);
        }
        else {
            setSelectedTeammate({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIdForLibrary])


    return (
        <div>
            {team && team.length > 1 ?
                <div className='team-dropdown-main' onClick={() => displayDropdown()}>
                    {Object.keys(selectedTeammate).length > 0 ?
                        <div className='team-dropdown-profile'>
                            <div className={selectedTeammate.account_admin ? "profile_icon profile_blue border-gradient-blue margin-r1" : "profile_icon profile_green border-gradient-green margin-r1"}>
                                {
                                    selectedTeammate.user_profile_url ?
                                        <img src={selectedTeammate.user_profile_url} alt={selectedTeammate.first_name + " " + selectedTeammate.name} />
                                        : selectedTeammate.first_name.charAt() + selectedTeammate.name.charAt()
                                }
                            </div >
                            <p className='p-small'>{selectedTeammate.first_name}</p>
                        </div>
                        :
                        <div className='team-dropdown-profile'>
                            <div className="profile_icon profile_green team_profile margin-r1">
                                Team
                            </div >
                            <p className='p-small'>{t('team_shared')}</p>
                        </div>
                    }
                    <svg viewBox="0 0 24 24">
                        <path fill="current" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                    </svg>

                    <ul className={showDropdown ? 'team-dropdown-list displayed' : 'team-dropdown-list'}>
                        {team.map(teammate => {
                            if (isAdmin || teammate._id === authTokens.user_id) {
                                return (
                                    <li onClick={() => setUserIdForLibrary(teammate._id)} key={teammate._id} className={teammate._id === userIdForLibrary ? "selected" : ""}>
                                        <div className={teammate.account_admin ? "profile_icon profile_blue border-gradient-blue margin-r1" : "profile_icon profile_green border-gradient-green margin-r1"}>
                                            {
                                                teammate.user_profile_url ?
                                                    <img src={teammate.user_profile_url} alt={teammate.first_name + " " + teammate.name} />
                                                    : teammate.first_name.charAt() + teammate.name.charAt()
                                            }
                                        </div >
                                        <p className='p-small'>{teammate.first_name}</p>
                                    </li>
                                )
                            }
                        })}
                        <li onClick={() => setUserIdForLibrary()} className={!userIdForLibrary ? "selected" : ""}>
                            <div className="profile_icon profile_green team_profile margin-r1">
                                Team
                            </div >
                            <p className='p-small'>{t('team_shared')}</p>
                        </li>
                    </ul>
                </div>
                : ""}
        </div>
    )
}

export default TeamDropDown