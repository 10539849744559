import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../auth";
import { useTranslation } from "react-i18next";


const DashboardCoNews = () => {

    const { authTokens } = useAuth();

    const { t, i18n } = useTranslation(['settings']);
    const headers = {
        Authorization: authTokens.token,
    };
    const [whatsNew, setWhatsNew] = useState([])

    const fetchWhatsNew = async () => {

        await axios
            .get(process.env.REACT_APP_SOCIALPIKA_API_URL + "/kanban/get_whats_new", {
                headers: headers,
            })
            .then((response) => {
                setWhatsNew(response.data);
            })
            .catch(() => {
            });
    }

    useEffect(() => {
        fetchWhatsNew();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            {whatsNew && whatsNew.length > 0 ?
                <ul className='latest_news_dashboard'>
                    {whatsNew.map((newItem, n) => {
                        return (
                            <li key={n}>
                                <div className='row row_spaced nowrap'>
                                    <div>
                                        <div className='update_title_gradient'>{i18n.language === "fr" ? newItem.title_fr : newItem.title_en}</div>
                                        <div className='latest_news_description margin-t1'>{i18n.language === "fr" ? newItem.desc_fr : newItem.desc_en}</div>
                                        {newItem.link && newItem.link.length > 0 ?
                                            <div className='learn_more margin-t1'>
                                                <Link to={newItem.link}>
                                                    {t('try')}
                                                </Link>
                                            </div>
                                            : newItem.alink && newItem.alink.length > 0 ?
                                                <div className='learn_more margin-t1'>
                                                    <a href={newItem.alink} target="_blank" rel="noreferrer">
                                                        {t('try')}
                                                    </a>
                                                </div>
                                                : ""}
                                    </div>

                                </div>
                            </li>
                        )
                    })
                    }

                </ul>
                :
                <p className="text-grey">{t('nothing_new')}</p>
            }
        </div>
    )
}

export default DashboardCoNews