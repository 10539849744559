import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../auth";
import { useTranslation } from "react-i18next";

import PostPreviewGeneric from '../../Composer/PostPreviews/PostPreviewGeneric';
// import PostPreviewGeneric from '../../new_post/PostPreviewGeneric';

const DashboardCoActivityNext = () => {
    const { t } = useTranslation(['dashboard']);
    const { authTokens } = useAuth();
    const [nextPub, setNextPub] = useState([]);

    const headers = {
        Authorization: authTokens.token,
    };

    const fetchNextPub = async () => {

        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/get_next_publication", payload, {
                headers: headers,
            })
            .then((response) => {
                setNextPub(response.data);
            })
            .catch(() => {
            });
    }

    useEffect(() => {
        fetchNextPub();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className='row row_hcenter'>
            {nextPub && nextPub.length > 0 ?
                <div>
                    <PostPreviewGeneric event={nextPub[0]} />

                </div>
                :
                <div>
                    <p className="text-grey">{t('no_next_pub')}</p>
                    <Link to='/post/create_post' className='row_hcenter margin-t2'>
                        <div className='action_button'>
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h19.7v3H9v30h30V19.3h3V39q0 1.2-.9 2.1-.9.9-2.1.9Zm7.05-7.85v-3H32v3Zm0-6.35v-3H32v3Zm0-6.35v-3H32v3ZM34.6 17.8v-4.4h-4.4v-3h4.4V6h3v4.4H42v3h-4.4v4.4Z" />
                            </svg>
                            {t('create_new_post')}
                        </div>
                    </Link>
                </div>
            }
        </div>
    )
}

export default DashboardCoActivityNext
