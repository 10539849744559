import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import StateModal from '../Loader/StateModal';

const OrderComplete = (props) => {

    const { authTokens } = useAuth();
    const { setAuthTokens } = useAuth();
    const { t } = useTranslation(['plans_advanced']);

    const headers = {
        Authorization: authTokens.token,
    };

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    const [companyData, setCompanyData] = useState({
        _id: authTokens.account_id,
        name: "",
        address_line_1: "",
        address_line_2: "",
        post_code: "",
        city: "",
        country: "",
        other_data: "",
        currency: authTokens.currency || "",
        isSubmitting: false
    });
    const [activeCompanyForm, setActiveCompanyForm] = useState(false);

    const activateCompanyForm = () => {
        setActiveCompanyForm(!activeCompanyForm);
    }

    const handleCompanyProfileInputChange = (e) => {
        setCompanyData({
            ...companyData,
            [e.target.name]: e.target.value
        })
    }

    const checkCurrencySymbol = (currency) => {

        let currencySymbol = "";
        switch (currency) {
            case "eur":
                currencySymbol = "€"
                break;
            case "usd":
                currencySymbol = "$"
                break;
            case "chf":
                currencySymbol = "CHF"
                break;
            case "gbp":
                currencySymbol = "£"
                break;
            default:
                currencySymbol = "-"
        }

        return currencySymbol;
    }

    const editCompanyProfile = async (e) => {
        e.preventDefault();

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        setCompanyData({
            ...companyData,
            isSubmitting: true
        });

        setAuthTokens({
            ...authTokens,
            currency: companyData.currency,
        });

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/update_company_profile", companyData, {
                headers: headers,
            })
            .then(() => {
                activateCompanyForm();
                setCompanyData({
                    ...companyData,
                    isSubmitting: false
                });
            })
            .catch(() => {
                setCompanyData({
                    ...companyData,
                    isSubmitting: false
                });
            });
        closeStateModal();

    }

    useEffect(() => {
        const session_id = props.match.params.id;

        if (session_id) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: true
            });
            axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/payment/record_payment_stripe_checkout", {
                session_id: session_id
            }, {
                headers: headers,
            })
                .then((response) => {
                    setAuthTokens({
                        ...authTokens,
                        token: response.data.token,
                        cai: response.data.cai === 'true' ? true : false,
                        plan: response.data.plan,
                        plan_expiry: response.data.plan_expiry,
                        billing: response.data.billing
                    });

                    props.triggerHeaderReload();
                })
                .catch(() => {
                });

            closeStateModal();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div>
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            <div>
                <div className="card">
                    <div className="row nowrap">
                        <div className='sad-pika margin-r4'>
                            <svg viewBox="0 0 948 948" fill="none">
                                <circle cx="473.97" cy="473.97" r="462.97" fill="white" stroke="white" stroke-width="22" />
                                <path d="M178.086 564.759V806.892L765.813 806.928V564.759C765.813 494.766 741.519 430.51 700.987 379.899V212.49H701.014C701.014 172.072 668.415 139.313 628.184 139.313C587.945 139.313 555.328 172.072 555.328 212.49V281.665C528.909 273.82 500.923 269.532 471.963 269.532C442.985 269.532 415.016 273.811 388.58 281.665V212.49C388.58 172.072 355.963 139.313 315.741 139.313C275.51 139.313 242.894 172.072 242.894 212.49V379.908C202.38 430.51 178.086 494.766 178.086 564.759Z" fill="white" />
                                <path d="M316.839 489.163C336.73 489.163 352.855 472.963 352.855 452.979C352.855 432.995 336.73 416.795 316.839 416.795C296.947 416.795 280.822 432.995 280.822 452.979C280.822 472.963 296.947 489.163 316.839 489.163Z" fill="#3D2BFF" />
                                <path d="M627.105 489.163C646.996 489.163 663.121 472.963 663.121 452.979C663.121 432.995 646.996 416.795 627.105 416.795C607.213 416.795 591.088 432.995 591.088 452.979C591.088 472.963 607.213 489.163 627.105 489.163Z" fill="#3D2BFF" />
                                <path d="M388.083 573.93C383.054 568.86 374.891 568.86 369.862 573.93C364.825 579 364.825 587.183 369.862 592.253C397.132 619.658 433.387 634.735 471.962 634.735C510.511 634.735 546.775 619.658 574.044 592.253C579.108 587.192 579.108 579.009 574.062 573.93C569.033 568.86 560.87 568.86 555.823 573.93C536.461 593.365 511.67 605.231 484.853 608.122V559.983C494.503 555.216 501.161 545.245 501.161 533.664C501.161 517.484 488.076 504.32 471.962 504.32C455.813 504.32 442.745 517.475 442.745 533.664C442.745 545.245 449.403 555.216 459.054 559.983V608.122C432.263 605.222 407.437 593.356 388.083 573.93Z" fill="#3D2BFF" />
                                <path d="M471.972 29C227.32 29 29 228.243 29 474.032C29 606.493 86.6723 725.372 178.086 806.892V564.759C178.086 494.766 202.38 430.51 242.894 379.908V212.49C242.894 172.072 275.51 139.313 315.741 139.313C355.963 139.313 388.58 172.072 388.58 212.49V281.665C415.016 273.811 442.985 269.532 471.963 269.532C500.923 269.532 528.909 273.82 555.328 281.665V212.49C555.328 172.072 587.945 139.313 628.184 139.313C668.415 139.313 701.014 172.072 701.014 212.49H700.987V379.899C741.519 430.51 765.813 494.766 765.813 564.759V806.928C857.253 725.407 914.943 606.52 914.943 474.032C914.943 228.243 716.623 29 471.972 29Z" fill="#3D2BFF" />
                            </svg>
                        </div>
                        <div className='column'>
                            <h1>{t('thank_you')}</h1>
                            <p className="text-grey">{t('order_complete')}</p>
                            <Link to='/post/create_post' className='row_hcenter margin-t2'>
                                <div className='action_button'>
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h19.7v3H9v30h30V19.3h3V39q0 1.2-.9 2.1-.9.9-2.1.9Zm7.05-7.85v-3H32v3Zm0-6.35v-3H32v3Zm0-6.35v-3H32v3ZM34.6 17.8v-4.4h-4.4v-3h4.4V6h3v4.4H42v3h-4.4v4.4Z" />
                                    </svg>
                                    {t('create_new_post')}
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <h2>{t('invoicing_data')}</h2>
                    <p className="text-grey">{t('verify_company')}</p>
                    {activeCompanyForm ?
                        <form onSubmit={editCompanyProfile}>
                            <div className="form_row">
                                <label>{t('company_name')}</label>
                                <input type="text" name="name" placeholder={t('co_company_name')} value={companyData.name} onChange={handleCompanyProfileInputChange} />
                            </div>
                            <div className="form_row">
                                <label>{t('address_1')}</label>
                                <input type="text" name="address_line_1" placeholder={t('co_add1')} value={companyData.address_line_1} onChange={handleCompanyProfileInputChange} />
                            </div>
                            <div className="form_row">
                                <label>{t('address_2')}</label>
                                <input type="text" name="address_line_2" placeholder={t('co_add2')} value={companyData.address_line_2} onChange={handleCompanyProfileInputChange} />
                            </div>
                            <div className="form_row">
                                <label>{t('post_code')}</label>
                                <input type="text" name="post_code" placeholder={t('co_post_code')} value={companyData.post_code} onChange={handleCompanyProfileInputChange} />
                            </div>
                            <div className="form_row">
                                <label>{t('city')}</label>
                                <input type="text" name="city" placeholder={t('co_city')} value={companyData.city} onChange={handleCompanyProfileInputChange} />
                            </div>
                            <div className="form_row">
                                <label>{t('country')}</label>
                                <input type="text" name="country" placeholder={t('co_country')} value={companyData.country} onChange={handleCompanyProfileInputChange} />
                            </div>
                            <div className="form_row">
                                <label>{t('currency')}</label>
                                <select name="currency" value={companyData.currency} onChange={handleCompanyProfileInputChange}>
                                    <option>{t('billing_currency')}</option>
                                    <option value="eur">EUR (€)</option>
                                    <option value="usd">USD ($)</option>
                                    <option value="gbp">GBP (£)</option>
                                    <option value="chf">CHF</option>
                                </select>
                            </div>
                            <div className="form_row">
                                <label>{t('other_data')}</label>
                                <input type="text" name="other_data" placeholder={t('co_other')} value={companyData.other_data} onChange={handleCompanyProfileInputChange} />
                            </div>
                            <div className="form_row">
                                <button>{t('update_company')}</button>
                            </div>
                        </form>
                        :
                        <div className="margin-t4">
                            <p className="p-no-margin text-grey">{companyData.name}</p>
                            {companyData.address_line_1 ? <p className="p-no-margin text-grey">{companyData.address_line_1}</p> : ""}
                            {companyData.address_line_2 ? <p className="p-no-margin text-grey">{companyData.address_line_2}</p> : ""}
                            {companyData.address_post_code ? <p className="p-no-margin text-grey">{companyData.address_post_code}</p> : ""}
                            {companyData.city ? <p className="p-no-margin text-grey">{companyData.city}</p> : ""}
                            {companyData.country ? <p className="p-no-margin text-grey">{companyData.country}</p> : ""}
                            <p className="p-no-margin text-grey">{t('currency')}: {checkCurrencySymbol(companyData.currency)}</p>
                            {companyData.other_data ? <p className="p-no-margin text-grey">Other data: {companyData.other_data}</p> : ""}
                        </div>
                    }
                    <div className='row margin-t2'>
                        {!activeCompanyForm ?
                            <div className="action_button_outline" onClick={activateCompanyForm}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                </svg>
                                {t('edit')}
                            </div>
                            : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderComplete
