import React, { useState } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../auth";
import StateModal from '../Loader/StateModal';
import TooltipHelp from '../settings/TooltipHelp';

// import PricingTable from './PricingTable';
import NewPricingTable from './NewPricingTable';
import Arrow from "../../images/arrow-pricing.png";


import "../styles/switch2.css";
import "./plans_mdn.css";

const base_price = 9;


const PlansMDN = () => {

    const { t, i18n } = useTranslation(['plans_advanced']);
    const history = useHistory();


    const { setAuthTokens } = useAuth();
    const { authTokens } = useAuth();
    const headers = {
        Authorization: authTokens.token,
    };

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    const currentPlan = Number(authTokens.plan);
    const currentPlanAnnual = Number(authTokens.billing) === 12 ? true : false;
    const isAdmin = authTokens.user_admin;


    const [yearlyBilling, setYearlyBilling] = useState(true);

    const initQtyValue = currentPlan > 0 ? currentPlan : 2;
    const [subsQty, setSubsQty] = useState(initQtyValue);


    const handleYearlyBilling = (e) => {
        if (e.target.checked) {
            setYearlyBilling(false);
            setProductToBill({
                ...productToBill,
                billing_frequency_factor: 0
            });
        }
        else {
            setYearlyBilling(true);
            setProductToBill({
                ...productToBill,
                billing_frequency_factor: 1
            });
        }
    }

    const checkCurrencySymbol = (currency) => {

        let currencySymbol = "";
        switch (currency) {
            case "eur":
                currencySymbol = "€"
                break;
            case "usd":
                currencySymbol = "$"
                break;
            case "chf":
                currencySymbol = "CHF"
                break;
            case "gbp":
                currencySymbol = "£"
                break;
            default:
                currencySymbol = ""
        }

        return currencySymbol;
    }

    const [productToBill, setProductToBill] = useState({
        price: "",
        currency: authTokens.currency || "eur",
        currencySymbol: checkCurrencySymbol(authTokens.currency || "eur"),
        name: authTokens.initials + " " + authTokens.user_name,
        email: authTokens.user_email,
        company_id: authTokens.account_id,
        billing_frequency_factor: 1
    });

    const goToCancellation = () => {
        history.push({ pathname: '/cancel_plan' });
    }


    const updateCurrency = (currency) => {
        setAuthTokens({
            ...authTokens,
            currency: currency,
        });

        setProductToBill({
            ...productToBill,
            currency: currency,
            currencySymbol: checkCurrencySymbol(currency)
        })
    }


    const goToCheckout = async () => {

        if (currentPlan > 0) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: true,
                message: t('updating_plan')
            });
            //UPDATE SUBSCRIPTION
            await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/payment/update_subscription_simple", {
                product_details: productToBill,
                currency: authTokens.currency || "eur",
                language: i18n.language,
                qty: subsQty,
                company_id: authTokens.account_id
            }, {
                headers: headers,
            })
                .then((response) => {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        status: "ok",
                        message: t('plan_updated')
                    });
                    setAuthTokens({
                        ...authTokens,
                        plan: response.data.plan,
                        plan_expiry: response.data.plan_expiry,
                        billing: response.data.billing
                    })
                })
                .catch(error => {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        status: "error",
                        message: t('order_error')
                    });
                });
        }
        else {
            //STRIPE CHECKOUT
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: true,
                message: t('redirecting_to_stripe')
            });
            //CHECKOUT
            await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/payment/stripe_checkout_simple_free_trial", {
                product_details: productToBill,
                currency: authTokens.currency || "eur",
                language: i18n.language,
                qty: subsQty,
            }, {
                headers: headers,
            })
                .then((response) => {
                    window.location.href = response.data.stripe_url;
                })
                .catch(error => {
                    closeStateModal();
                });
        }



    }

    return (
        <div className='column'>
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            <h1>{t('plan_title')}</h1>


            <div className="row margin-t4">
                <div className={authTokens.currency === 'eur' ? 'currency_button active' : 'currency_button'} onClick={() => updateCurrency('eur')}>€</div>
                <div className={authTokens.currency === 'usd' ? 'currency_button active' : 'currency_button'} onClick={() => updateCurrency('usd')}>$</div>
                <div className={authTokens.currency === 'gbp' ? 'currency_button active' : 'currency_button'} onClick={() => updateCurrency('gbp')}>£</div>
                <div className={authTokens.currency === 'chf' ? 'currency_button active' : 'currency_button'} onClick={() => updateCurrency('chf')}>CHF</div>
            </div>
            <div className="row row_vcenter margin-0">
                <svg viewBox="0 0 24 24" className="svg-2 svg-green margin-l2">
                    <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                </svg>
                <p>{t('cancel_upgrade_anytime')}</p>
            </div>
            <div className='row margin-t4'>

                <div className="card-pricing flex-1">
                    {(currentPlan === 0) ?
                        <div className="selected_plan">
                            {t('current_plan')}
                        </div>
                        : ""
                    }
                    <div className="plan_name">{t('free')}</div>
                    <div className="plan_price">0 {productToBill.currencySymbol}<span className="plan_frequency">/{t('month')}</span></div>
                    <p className="text-grey">{t('forever')}</p>

                    <div className="row margin-t4">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-blue">
                                <path fill="current" d="M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z" />
                            </svg>
                        </div>
                        <p>5 {t('social_accounts')}</p>
                        <TooltipHelp content={t('tt_accounts')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-blue">
                                <path fill="current" d="M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z" />
                            </svg>
                        </div>
                        <p>15 {t('pub_per_months')}</p>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-blue">
                                <path fill="current" d="M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z" />
                            </svg>
                        </div>
                        <p>1 {t('user')}</p>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-blue">
                                <path fill="current" d="M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z" />
                            </svg>
                        </div>
                        <p>{t('photos_4')}</p>
                    </div>

                    <div className="row margin-t2">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>{t('no_watermarks')}</p>
                        <TooltipHelp content={t('tt_no_watermark')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>

                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>Tags (Instagram, LinkedIn)</p>
                        <TooltipHelp content={t('tt_tags')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>{t('GMB_events')}</p>
                        <TooltipHelp content={t('tt_gmb')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>Emoji</p>
                    </div>

                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>{t('bulk_posting')}</p>
                        <TooltipHelp content={t('tt_bulk')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>{t('text_variation')}</p>
                        <TooltipHelp content={t('tt_text_variation')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>{t('rss_feed')}</p>
                        <TooltipHelp content={t('tt_rss')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>{t('spread_posts')}</p>
                        <TooltipHelp content={t('tt_spread')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className='svg-red'>
                                <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                            </svg>
                        </div>
                        <p>Videos, Reels</p>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className='svg-red'>
                                <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                            </svg>
                        </div>
                        <p>{t('analytics_addon')}</p>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className='svg-red'>
                                <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                            </svg>
                        </div>
                        <p>GIF (Giphy)</p>
                    </div>
                </div>
                <div className="card-pricing-blue flex-1">
                    {(currentPlan === subsQty && currentPlanAnnual === yearlyBilling) ?
                        <div className="selected_plan">
                            {t('current_plan')}
                        </div>
                        : ""
                    }
                    <div className="plan_name">{t('pro')}</div>
                    <div className="plan_price">{yearlyBilling ? (subsQty * base_price) : (subsQty * base_price * 1.2).toFixed(2)} {productToBill.currencySymbol}<span className="plan_frequency">/{t('month')}</span></div>
                    <p className="text-grey">{yearlyBilling ? `${(Math.round(subsQty * base_price * 12))} ${productToBill.currencySymbol} ${t('billed_once_a_year')}` : t('billed_every_month')}</p>

                    <div className="row margin-t4">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-blue">
                                <path fill="current" d="M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z" />
                            </svg>
                        </div>
                        <p>{Math.round(subsQty * 5)} {t('social_accounts')}</p>
                        <TooltipHelp content={t('tt_accounts_pro')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-blue">
                                <path fill="current" d="M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z" />
                            </svg>
                        </div>
                        <p>{Math.round(subsQty * 50 + (subsQty - 1) * 100)} {t('pub_per_months')}</p>
                        <TooltipHelp content={t('tt_publications_pro')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>

                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-blue">
                                <path fill="current" d="M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z" />
                            </svg>
                        </div>
                        <p>{Math.round(subsQty)} {subsQty === 1 ? t('user') : t('users')}</p>
                        <TooltipHelp content={t('tt_publications_pro')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>

                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-blue">
                                <path fill="current" d="M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z" />
                            </svg>
                        </div>
                        <p>{t('photos_10')}</p>
                    </div>
                    <div className="row margin-t2">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>{t('no_watermarks')}</p>
                        <TooltipHelp content={t('tt_no_watermark')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>

                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>Tags (Instagram, LinkedIn)</p>
                        <TooltipHelp content={t('tt_tags')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>{t('GMB_events')}</p>
                        <TooltipHelp content={t('tt_gmb')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>Emoji</p>
                    </div>

                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>{t('bulk_posting')}</p>
                        <TooltipHelp content={t('tt_bulk')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>{t('text_variation')}</p>
                        <TooltipHelp content={t('tt_text_variation')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>{t('rss_feed')}</p>
                        <TooltipHelp content={t('tt_rss')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>{t('spread_posts')}</p>
                        <TooltipHelp content={t('tt_spread')} direction="bottom">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>Videos, Reels (250 Mo)</p>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>{t('analytics_addon')}</p>
                    </div>
                    <div className="row margin-t1">
                        <div className="bullet-point">
                            <svg viewBox="0 0 24 24" className="svg-2 svg-green">
                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                            </svg>
                        </div>
                        <p>GIF (Giphy)</p>
                    </div>
                    {isAdmin ?
                        currentPlan !== subsQty || currentPlanAnnual !== yearlyBilling ?
                            <div className='column_button margin-t2' onClick={() => goToCheckout()}>
                                {t('select')}
                                {currentPlan === 0 ?
                                    <div className="sub-button">{t('7_days_free_trial')}</div>
                                    : ""}
                            </div>
                            : ""
                        : ""}
                </div>
                <div className="column flex-1">
                    <div className="billing_options_row">
                        <p className={yearlyBilling ? "text-blue column_left" : "column_left"}>{t('yearly')}<span className="discount">{t('discount')}</span></p>
                        <div className="onoffswitch">
                            <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" tabIndex="0" onChange={handleYearlyBilling} />
                            <label className="onoffswitch-label" htmlFor="myonoffswitch">
                                <span className="onoffswitch-inner"></span>
                                <span className="onoffswitch-switch"></span>
                            </label>
                        </div>
                        <p className={yearlyBilling ? "" : "text-blue"}>{t('monthly')}</p>
                    </div>
                    <div className="row row_hcenter row-100 margin-0 padding-0">
                        <h3 className='text-centered'>{t('nb_accounts_needed')}</h3>
                    </div>
                    <div className="row row_hcenter row_vcenter row-100 margin-0 padding-0">
                        <div className="pricing_action" onClick={() => { if (subsQty > 1) { setSubsQty(subsQty - 1) } }}>
                            <svg className="svg-2 svg-red" viewBox="0 0 24 24">
                                <path fill="current" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7,13H17V11H7" />
                            </svg>
                        </div>
                        <div className="accounts_needed">{Math.round(subsQty * 5)}</div>
                        <div className="pricing_action" onClick={() => setSubsQty(subsQty + 1)}>
                            <svg className="svg-2 svg-green" viewBox="0 0 24 24">
                                <path fill="current" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z" />
                            </svg>
                        </div>
                    </div>
                    <div className='pricing-arrow'>
                        <img src={Arrow} alt="" />
                    </div>

                    {currentPlan > 0 ?
                        <div className="content_row">
                            <div className="card">
                                <div className="title text-red text-bold">{t('danger_zone')}</div>
                                <div className="row row_hcenter">
                                    <div className="red_button" onClick={e => goToCancellation()}>{t('cancel_plan')}</div>
                                </div>
                            </div>
                        </div>
                        : ""}
                </div>
            </div>



            <div className='row-100 card margin-t8'>
                <NewPricingTable />
            </div>

        </div>
    )
}

export default PlansMDN