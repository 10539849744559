import React, { useState, useEffect } from 'react'
import axios from "axios";
import AWS from 'aws-sdk'
import { useAuth } from "../../auth";
import moment from 'moment'
import { useTranslation } from "react-i18next";
import StateModal from '../Loader/StateModal';
import TooltipHelp from '../settings/TooltipHelp';
import Resizer from "react-image-file-resizer";
import LibraryPickerSelect from '../library/LibraryPickerSelect';
import TimezonesSelection from '../settings/TimezonesSelection';
// import ImageCropper from '../library/ImageCropper';
import Cropper from '../library/Cropper';
import ComposerCoThreadTweet from './ComposerComponents/ComposerCoThreadTweet';

import SocialsModal from '../Manage/ManageComponents/SocialsModal';
import UpgradeModal from '../Plans/UpgradeModal';

import TWicon from "../../images/social_media/twitter.svg";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_S3_BUCKET_LOCATION;
const IKpublicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY;
const IKauthenticationEndpoint = process.env.REACT_APP_SOCIALPIKA_API_URL + '/imagekit/auth';


AWS.config.update({
    accessKeyId: process.env.REACT_APP_S3_BUCKET_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_KEY
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

const ComposeThreadS3 = (props) => {
    const { authTokens } = useAuth();
    const { t } = useTranslation(['composer']);

    const currentPlan = Number(authTokens.plan);

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }

    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const displayUpgradeModal = () => {
        setShowUpgradeModal(!showUpgradeModal);
    }


    const [showTimeZones, setShowTimeZones] = useState(false);

    const displayTimeZones = () => {
        if (authTokens.plan > 0) {
            setShowTimeZones(!showTimeZones)
        }
        else {
            displayUpgradeModal();
        }
    }


    const handleTimeZone = (tzInput) => {
        setPublicationData({
            ...publicationData,
            timezone: tzInput
        });
    }

    const [showSocialsModal, setShowSocialsModal] = useState(false);
    const closeSocialsModal = () => {
        setShowSocialsModal(false);
    }

    const headers = {
        Authorization: authTokens.token,
    };
    const scheduled_post_id = props.match.params.id;


    const [SMAccounts, setSMAccounts] = useState([]);

    const initialPublicationData = {
        _id: "",
        name: "",
        date: "",
        timezone: { label: Intl.DateTimeFormat().resolvedOptions().timeZone, value: Intl.DateTimeFormat().resolvedOptions().timeZone },
        frequency: "0",
        repeat: "",
        spread: false,
        spread_period: "5",
        company_id: authTokens.account_id,
        draft: false,
        thread: true,
        thread_count: false,
        social_accounts: []
    }
    const [publicationData, setPublicationData] = useState(initialPublicationData);
    const [showExpiry, setShowExpiry] = useState(false);
    const [activeArea, setActiveArea] = useState(0);

    const [videoMeta, setVideoMeta] = useState({})



    const handleShowExpiry = (e) => {
        e.target.value !== "0" ? setShowExpiry(true) : setShowExpiry(false);
    }
    const handleCheckbox = (e) => {
        if (e.target.name === 'draft') {
            setPublicationData({
                ...publicationData,
                [e.target.name]: !e.target.checked
            })
        }
        else {
            setPublicationData({
                ...publicationData,
                [e.target.name]: e.target.checked
            })
        }
    }
    const handleInputChange = (e) => {
        setPublicationData({
            ...publicationData,
            [e.target.name]: e.target.value
        })
    }

    const handleDateChange = (e) => {
        setPublicationData({
            ...publicationData,
            date: e.target.value
        })
    }

    const toDatetimeLocal = (isoDate) => {
        let date = new Date(isoDate);

        const ten = (i) => {
            return (i < 10 ? '0' : '') + i;
        }

        const YYYY = date.getFullYear();
        const MM = ten(date.getMonth() + 1);
        const DD = ten(date.getDate());
        const HH = ten(date.getHours());
        const II = ten(date.getMinutes());
        const SS = ten(date.getSeconds());

        return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II + ':' + SS;
    }

    const selectMediaAccount = (account) => {
        let selectedMediaAccount = publicationData.social_accounts;

        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === account.social_id);

        if (indexFound !== -1) {
            const filteredMedia = selectedMediaAccount.filter(item => item.social_id !== account.social_id);
            setPublicationData({
                ...publicationData,
                social_accounts: filteredMedia
            });
        }
        else {
            selectedMediaAccount.push(account);

            setPublicationData({
                ...publicationData,
                social_accounts: selectedMediaAccount
            });
        }
    }


    const checkAccountPhoto = (accountData) => {

        const selectedMediaAccount = publicationData.social_accounts;
        let selected = false;
        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === accountData.social_id);

        if (indexFound !== -1) {
            selected = true;
        }

        return (
            <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" />
                <img className="account_profile_media" src={TWicon} alt="Media" />
            </div>)
    }


    const handleThreadCount = (e) => {
        if (currentPlan > 0) {
            // setThreadCount(!threadCount);
            setPublicationData({
                ...publicationData,
                [e.target.name]: e.target.checked
            })
        }
        else {
            displayUpgradeModal();
        }
    }

    // INDIVIDUAL TWEETS

    const initialTweetData = {
        text: '',
        medias: [],
        link: ''
    }

    const [threadData, setThreadData] = useState([initialTweetData]);

    const addTweet = (tweetIndex) => {

        let items = [...threadData];
        let item = { ...items[tweetIndex] };

        item.text = item.text.trim();

        setThreadData([
            ...threadData.slice(0, tweetIndex),
            item,
            initialTweetData,
            ...threadData.slice(tweetIndex + 1)
        ]);

        setTimeout(() => {
            setActiveArea(tweetIndex + 1)
        }, 100);


    }

    const deleteTweet = (tweetIndex) => {
        let items = [...threadData];
        if (items.length === 1) return;
        items.splice(tweetIndex, 1);
        setThreadData(items);

        setTimeout(() => {
            setActiveArea(tweetIndex - 1)
        }, 100);
    }

    const handleTweetText = (value, tweetIndex) => {
        let items = [...threadData];
        let item = { ...items[tweetIndex] };

        item.text = value;
        items[tweetIndex] = item;
        setThreadData(items);
    }


    // MEDIA
    const [showCropper, setShowCropper] = useState(false);
    const [mediaLoader, setMediaLoader] = useState(false);
    const [imagesRaw, setImagesRaw] = useState([]);
    const [libraryNeedsRefresh, setLibraryNeedsRefresh] = useState(false);
    const [currentFolder, setCurrentFolder] = useState({
        name: "",
        _id: "",
        parent_folder_id: ""
    });
    const [userIdForLibrary, setUserIdForLibrary] = useState(authTokens.user_id);
    const [showLibrary, setShowLibrary] = useState(false);
    const displayLibrary = () => {
        setShowLibrary(!showLibrary);
    }

    const displayCropper = () => {
        setShowCropper(!showCropper);
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1200,
                1080,
                "JPEG",
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    const handleMedia = (new_media) => {

        let items = [...threadData];
        let item = { ...items[activeArea] };

        let currentMedia = item.medias;


        if (currentMedia.length <= 3 && new_media.type === 'image') {
            currentMedia.push(new_media)
        }
        else {
            currentMedia = [new_media];
        }

        item.medias = currentMedia;
        items[activeArea] = item;
        setThreadData(items);

    }

    const deleteMedia = async (fileId) => {

        let items = [...threadData];
        let item = { ...items[activeArea] };

        const updated_media = item.medias.filter(mediaData => mediaData._id !== fileId);
        item.medias = updated_media;
        items[activeArea] = item;
        setThreadData(items);
    }

    const uploadImage = async (image, file_type) => {
        let authError = false;
        setMediaLoader(true);

        const payload = {
            company_id: authTokens.account_id
        }

        const IKauth = await axios.post(IKauthenticationEndpoint, payload, {
            headers: headers,
        })
            .catch((error) => {
                if (error.response.status === 401) {
                    setMediaLoader(false);
                    // props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
            });

        if (authError) return;

        // UPLOAD TO IMAGEKIT
        const destination_folder = authTokens.account_id;

        const imagekit_headers = {
            'Content-Type': 'multipart/form-data'
        }
        const params = new FormData();
        params.append('file', image)
        params.append('publicKey', IKpublicKey)
        params.append('signature', IKauth.data.signature)
        params.append('expire', IKauth.data.expire)
        params.append('token', IKauth.data.token)
        params.append('fileName', 'social_pika')
        params.append('folder', '/' + destination_folder)

        const ik_upload_url = 'https://upload.imagekit.io/api/v1/files/upload';

        let media_data_for_db = {};

        await axios.post(ik_upload_url, params, {
            headers: imagekit_headers
        })
            .then(res => {

                let formatted_photo_url = res.data.url;

                media_data_for_db = {
                    file_name: res.data.name,
                    file_type: res.data.fileType,
                    file_url: res.data.url,
                    file_url_watermark: formatted_photo_url,
                    file_id: res.data.fileId,
                    file_size: res.data.size,
                    file_height: res.data.height,
                    file_width: res.data.width,
                    source: file_type || 'image',
                    type: file_type || 'image',
                    used: false,
                    folder_id: currentFolder._id,
                    user_id_for_folder: userIdForLibrary,
                    company_id: authTokens.account_id
                }
            })
            .catch(() => {
            })


        // CREATE NEW MEDIA IN DB
        await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/media/save_media_in_folder", media_data_for_db, {
            headers: headers,
        })
            .then((response) => {
                media_data_for_db._id = response.data.media_id
            })
            .catch(() => {
            });


        handleMedia(media_data_for_db);
        setMediaLoader(false);
    }

    const [videoUploadProgress, setVideoUploadProgress] = useState(0);

    const uploadVideo = async (video_file) => {

        setMediaLoader(true);

        const video_name = video_file.name;
        const video_file_name = Math.random().toString(16).slice(2) + '_' + video_name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
        const destination_path = authTokens.account_id + '/' + video_file_name;
        const destination_url = process.env.REACT_APP_S3_BUCKET_URI + destination_path

        const params = {
            ACL: 'public-read',
            Body: video_file,
            Bucket: S3_BUCKET,
            Key: destination_path
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setVideoUploadProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send(async (err, data) => {
                if (err) {
                    // console.log("Error @ S3 upload");
                    // console.log(err);
                    setMediaLoader(false);
                }
                else {
                    // console.log("video uploaded");
                    // console.log(data);

                    await recordVideoDataInDB(video_file_name, destination_url);
                }

            })
    }

    const recordVideoDataInDB = async (video_file_name, url) => {

        const media_data_for_db = {
            file_name: video_file_name,
            file_type: 'video',
            file_url: url,
            file_id: 'S3-video',
            file_size: videoMeta.video_size,
            source: 'video',
            type: 'video',
            used: false,
            folder_id: currentFolder._id,
            user_id_for_folder: userIdForLibrary,
            company_id: authTokens.account_id,
            video_duration: videoMeta.video_duration,
            video_width: videoMeta.video_width,
            video_height: videoMeta.video_height,
            video_size: videoMeta.video_size
        }
        // CREATE NEW MEDIA IN DB
        await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/media/save_media_in_folder", media_data_for_db, {
            headers: headers,
        })
            .then((response) => {
                media_data_for_db._id = response.data.media_id
            })
            .catch(() => {
            });


        handleMedia(media_data_for_db);
        setMediaLoader(false);
    }


    const toggleSelectMedia = (mediaSelected) => {

        // let selectedMedia = publicationData.medias;

        let items = [...threadData];
        let item = { ...items[activeArea] };

        let selectedMedia = item.medias;

        //Check if media is selected
        let indexFound = selectedMedia.findIndex((media) => media._id === mediaSelected._id);

        if (indexFound !== -1) {
            //REMOVE THE MEDIA FROM SELECTED MEDIA
            const updated_media = selectedMedia.filter(mediaData => mediaData._id !== mediaSelected._id);
            setPublicationData({
                ...publicationData,
                medias: updated_media
            })
        }
        else {

            let formatted_photo_url = mediaSelected.file_url;

            let new_image = {
                _id: mediaSelected._id,
                file_name: mediaSelected.file_name,
                file_type: mediaSelected.file_type,
                file_url: mediaSelected.file_url,
                file_url_watermark: formatted_photo_url,
                file_id: mediaSelected.file_id,
                file_size: mediaSelected.file_size,
                file_source_url: mediaSelected.file_url,
                file_height: mediaSelected.file_height,
                file_width: mediaSelected.file_width,
                source: mediaSelected.source,
                type: mediaSelected.type
            }

            handleMedia(new_image);
        }
    }



    // RECORD THREAD

    const recordPost = async (type) => {
        let authError = false;

        let post_payload = publicationData;

        if (type === 'draft') {
            post_payload.draft = true;
        }
        else if (type === 'now') {
            post_payload.date = moment().add(3, 'minutes');
        }


        if (!post_payload.draft && (!post_payload.social_accounts || post_payload.social_accounts.length === 0)) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('select_a_social_account')
            });
            return
        }

        if (!post_payload.date && type === "schedule") {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('no_date')
            });
            return
        }

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        const payload = {
            publication_data: post_payload,
            thread_data: threadData
        }


        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/save_thread_spread_option", payload, {
                headers: headers,
            })
            .then(() => {
                if (!publicationData.draft) {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        upgradeOption: currentPlan === 0 ? true : false,
                        status: "check",
                        message: t('thread_recorded'),
                        button_text: t('go_to_calendar'),
                        button_link: "/calendar/monthly"
                    });
                }
                else {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        upgradeOption: currentPlan === 0 ? true : false,
                        status: "check",
                        message: t('thread_recorded'),
                        button_text: t('go_to_drafts'),
                        button_link: "/drafts"
                    });
                }
                setPublicationData(initialPublicationData);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
                else {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        status: "error",
                        message: t('post_not_recorded')
                    });
                }
            });
        if (authError) return;
    }

    const [team, setTeam] = useState([]);

    const fetchTeamData = async () => {

        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/get_full_team", payload, {
                headers: headers,
            })
            .then((response) => {
                setTeam(response.data);
            })
            .catch(() => {
            });
    }

    const fetchAvailableSocialMedia = async () => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        const payload = {
            user_id: authTokens.user_id,
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_accounts_for_twitter", payload, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.length === 0) {
                    setShowSocialsModal(true);

                }
                else if (!scheduled_post_id && response.data.length > 0) {
                    console.log("setting account")
                    setPublicationData({
                        ...publicationData,
                        social_accounts: [response.data[0]]
                    });
                }

                setSMAccounts(response.data);
                closeStateModal();

            })
            .catch((error) => {
                closeStateModal();
                console.log(error);
            });
    }

    const fetchScheduledPostDetails = async (_id) => {
        const payload = {
            _id: _id,
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/get_thread_data", payload, {
                headers: headers,
            })
            .then((response) => {
                setPublicationData(response.data);
                setThreadData(response.data.thread_content);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        if (scheduled_post_id) {
            fetchScheduledPostDetails(scheduled_post_id);
        }
        // fetchAllMedia();
        fetchTeamData();
        fetchAvailableSocialMedia();
        props.changeNav("New Post", "NewPost");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);


    return (
        <div>
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            {showSocialsModal ? <SocialsModal closeSocialsModal={closeSocialsModal} twitter={true} /> : ""}
            {showUpgradeModal ? <UpgradeModal upgrade={true} closeModal={() => setShowUpgradeModal(false)} closeButton={true} /> : ''}
            {showLibrary ?
                <>
                    <div className="transparent_background" onClick={displayLibrary}></div>
                    <div className="centered_picker">
                        <span className="close_modal" onClick={displayLibrary}>&times;</span>
                        <LibraryPickerSelect authTokens={authTokens} userIdForLibrary={userIdForLibrary} setUserIdForLibrary={setUserIdForLibrary} team={team} currentFolder={currentFolder} libraryNeedsRefresh={libraryNeedsRefresh} setLibraryNeedsRefresh={setLibraryNeedsRefresh} setCurrentFolder={setCurrentFolder} toggleSelectMedia={toggleSelectMedia} maxHeight='400px' displayCropper={displayCropper} setImagesRaw={setImagesRaw} hideGif={true} hideUnsplash={true} />
                    </div>
                </>
                : ""
            }
            {/* {showCropper ? <ImageCropper displayCropper={displayCropper} imagesRaw={imagesRaw} uploadImage={uploadImage} setMediaLoader={setMediaLoader} /> : ""} */}
            {showCropper ? <Cropper displayCropper={displayCropper} imagesRaw={imagesRaw} uploadImage={uploadImage} setMediaLoader={setMediaLoader} /> : ""}
            <div className="content_row_maxed nowrap">
                <div className='card'>
                    <div className='composer_component_thread'>
                        <div className="title">
                            {t('accounts_selection')}
                        </div>
                        <div className="form_row">
                            <div className="row">
                                {SMAccounts.map((account, i) => {
                                    return (
                                        <div key={i} onClick={e => selectMediaAccount(account)}>
                                            {checkAccountPhoto(account)}
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </div>
                        <div className="title margin-t2">
                            {t('options')}
                        </div>
                        <div className='row'>
                            <div className="onoffswitch_draft">
                                <input type="checkbox" name="thread_count" className="onoffswitch_draft-checkbox" id="myonoffswitchCount" checked={publicationData.thread_count} onChange={(e) => handleThreadCount(e)} />
                                <label className="onoffswitch_draft-label" htmlFor="myonoffswitchCount">
                                    <span className="onoffswitch_draft-inner"></span>
                                    <span className="onoffswitch_draft-switch"></span>
                                </label>
                            </div>
                            <label className='margin-l1'>{t('thread_count')}</label>
                            <TooltipHelp content={t('thread_count_desc')} direction="bottom">
                                <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                    <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                                </svg>
                            </TooltipHelp>
                        </div>
                        <form>
                            <div className="margin-t2">
                                <div className="action_button_outline" name="draft" onClick={() => recordPost('draft')}>
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="m37.55 18.2-7.75-7.75 3.5-3.5q.85-.85 2.1-.85t2.1.85l3.55 3.55q.85.85.85 2.1t-.85 2.1ZM6 42v-7.8l9.7-9.7L4.65 13.4l8.7-8.8L24.5 15.75l5.3-5.3 7.75 7.75-5.3 5.3L43.4 34.65l-8.75 8.65-11.1-11.05L13.8 42Zm11.85-19.65 4.5-4.5-3.65-3.65-2.4 2.4-2.1-2.1 2.4-2.4-3.2-3.2-4.5 4.5Zm16.7 16.75 4.5-4.5-3.2-3.2-2.4 2.4-2.1-2.1 2.4-2.4-3.65-3.65-4.5 4.5ZM9 39h3.5l20.75-20.75-3.5-3.5L9 35.5Z" />
                                    </svg>
                                    {t('save_post_as_draft')}
                                </div>
                                <div className="action_button margin-t1" name="draft" onClick={() => recordPost('now')}>
                                    <svg viewBox="0 0 48 48">
                                        <path d="M9 42C8.2 42 7.5 41.7 6.9 41.1C6.3 40.5 6 39.8 6 39V9C6 8.2 6.3 7.5 6.9 6.9C7.5 6.3 8.2 6 9 6H28.7V9H9V39H39V19.3H42V39C42 39.8 41.7 40.5 41.1 41.1C40.5 41.7 39.8 42 39 42H9ZM16.05 34.15V31.15H32V34.15H16.05ZM16.05 27.8V24.8H32V27.8H16.05ZM16.05 21.45V18.45H32V21.45H16.05Z" fill="current" />
                                        <path d="M44.4049 13.7585L41.2936 13.7585L41.2936 7.81885L34.6468 14.4657L32.5255 12.3443L39.1723 5.69753L33.2326 5.69753L33.2326 2.58626H44.4049V13.7585Z" fill="current" />
                                    </svg>
                                    {t('post_now_3_mn')}
                                </div>

                            </div>
                            <div className="title margin-t2">
                                {t('or_post_scheduling')}
                            </div>
                            <div className="form_row">
                                <label>{t('date_time_of_publication')}</label>
                                <div className="editor_input_block_dates">
                                    <input type="datetime-local" className='editor' name="date" min={new Date().toISOString().slice(0, -8)} value={toDatetimeLocal(publicationData.date)} onChange={handleDateChange} />
                                    {/* <input type="date" name="date" value={publicationData.date} onChange={handleInputChange} /> @<input type="time" name="time" value={publicationData.time} onChange={handleInputChange} /> */}
                                </div>
                            </div>
                            <div className='row margin-t1'>
                                <div className="onoffswitch_draft">
                                    <input type="checkbox" className="onoffswitch_draft-checkbox" id="onoffSwitchTZ" checked={showTimeZones} onChange={() => displayTimeZones()} />
                                    <label className="onoffswitch_draft-label" htmlFor="onoffSwitchTZ">
                                        <span className="onoffswitch_draft-inner"></span>
                                        <span className="onoffswitch_draft-switch"></span>
                                    </label>
                                </div>
                                <label className='margin-l1'>{t('select_different_timezone')}</label>
                            </div>
                            {showTimeZones ?
                                <TimezonesSelection
                                    timezoneChanged={handleTimeZone}
                                    currentTimeZone={publicationData.timezone}
                                />
                                : ''}
                            {publicationData.social_accounts.length > 1 || (publicationData.boards && publicationData.boards.length > 1) ?
                                <div className="row row_vcenter margin-t2">
                                    <div className="onoffswitch_draft">
                                        <input type="checkbox" name="spread" className="onoffswitch_draft-checkbox" id="myonoffswitchSpread" checked={publicationData.spread} onChange={handleCheckbox} />
                                        <label className="onoffswitch_draft-label" htmlFor="myonoffswitchSpread">
                                            <span className="onoffswitch_draft-inner"></span>
                                            <span className="onoffswitch_draft-switch"></span>
                                        </label>
                                    </div>
                                    {!publicationData.spread ?
                                        <>
                                            <label className='margin-l1'>{t('no_spread')}</label>
                                            <TooltipHelp content={t('tooltip_spread')} direction="bottom">
                                                <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                                    <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                                                </svg>
                                            </TooltipHelp>
                                        </>
                                        :
                                        <>
                                            <label className='margin-l1'>{t('spread')}</label>
                                            <select className='editor margin-l1' name="spread_period" onChange={e => handleInputChange(e)}>
                                                <option value="5">{t('spread_5_mn')}</option>
                                                <option value="30">{t('spread_30_mn')}</option>
                                                <option value="60">{t('spread_1_hr')}</option>
                                                <option value="360">{t('spread_6_hrs')}</option>
                                                <option value="720">{t('spread_12_hrs')}</option>
                                                <option value="1440">{t('spread_1_day')}</option>
                                                <option value="2880">{t('spread_2_days')}</option>
                                                <option value="4320">{t('spread_3_days')}</option>
                                            </select>
                                        </>
                                    }
                                </div>
                                : ""
                            }
                            <div className="form_row">
                                <label>{t('repeat')}</label>
                                <div className='row'>
                                    <select className="editor" name="frequency" onChange={e => { handleInputChange(e); handleShowExpiry(e) }}>
                                        <option value="0">{t('never')}</option>
                                        <option value="2d">{t('every_2_days')}</option>
                                        <option value="1">{t('every_week')}</option>
                                        <option value="2">{t('every_2_weeks')}</option>
                                        <option value="4">{t('every_4_weeks')}</option>
                                        <option value="3m">{t('every_3_months')}</option>
                                        <option value="6m">{t('every_6_months')}</option>
                                        <option value="12m">{t('every_year')}</option>
                                    </select>
                                    {publicationData.frequency === "2d" ?
                                        <TooltipHelp content={t('2d_warning')} direction="right">
                                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                                <path fill="current" d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
                                            </svg>
                                        </TooltipHelp>
                                        : ""}
                                </div>
                            </div>
                            <div className={showExpiry ? "form_row" : "form_row hidden"}>
                                <label>{t('expires_after_x_pub')}</label>
                                <div className="row_vcenter">
                                    <select name="repeat" onChange={handleInputChange}>
                                        <option>-</option>
                                        <option value="1">1 {t('other_publication')}</option>
                                        <option value="2">2 {t('other_publications')}</option>
                                        <option value="3">3 {t('other_publications')}</option>
                                        <option value="4">4 {t('other_publications')}</option>
                                        <option value="5">5 {t('other_publications')}</option>
                                        <option value="6">6 {t('other_publications')}</option>
                                        <option value="7">7 {t('other_publications')}</option>
                                        <option value="8">8 {t('other_publications')}</option>
                                        <option value="9">9 {t('other_publications')}</option>
                                        <option value="10">10 {t('other_publications')}</option>
                                        <option value="20">20 {t('other_publications')}</option>
                                        <option value="30">30 {t('other_publications')}</option>
                                    </select>
                                </div>
                            </div>


                            <div className="row margin-t2">
                                <div className="action_button" name="schedule" onClick={() => recordPost('schedule')}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
                                    </svg>
                                    {t('schedule')}
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
                <div className='card card_thread'>
                    <div className='composer_component_thread'>
                        {(threadData && threadData.length > 0) ?
                            threadData.map((tweet, t) => {
                                return (
                                    <ComposerCoThreadTweet
                                        key={t}
                                        headers={headers}
                                        displayUpgradeModal={displayUpgradeModal}
                                        tweetIndex={t}
                                        threadLength={threadData.length}
                                        accountData={publicationData.social_accounts.length > 0 ? publicationData.social_accounts[0] : []}
                                        authTokens={authTokens}
                                        currentPlan={currentPlan}
                                        publicationData={publicationData}
                                        handleTweetText={handleTweetText}
                                        singleTweetData={tweet}
                                        addTweet={addTweet}
                                        deleteTweet={deleteTweet}
                                        setActiveArea={setActiveArea}
                                        activeArea={activeArea}
                                        displayCropper={displayCropper}
                                        resizeFile={resizeFile}
                                        uploadImage={uploadImage}
                                        uploadVideo={uploadVideo}
                                        videoUploadProgress={videoUploadProgress}
                                        mediaLoader={mediaLoader}
                                        setVideoMeta={setVideoMeta}
                                        setImagesRaw={setImagesRaw}
                                        deleteMedia={deleteMedia}
                                        displayLibrary={displayLibrary}
                                        stateModalOptions={stateModalOptions}
                                        setStateModalOptions={setStateModalOptions}
                                    />
                                )
                            })
                            : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComposeThreadS3