import React, { useEffect, useState } from 'react';
import axios from "axios";
import DataTable from 'react-data-table-component';
import { useTranslation } from "react-i18next";
import StateModal from '../../Loader/StateModal';
import Tooltip from '../../sidebar/Tooltip';

import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import PNicon from "../../../images/social_media/pinterest.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import TKicon from "../../../images/social_media/tiktok-spk.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"


const ExpandableComponentSMAccountsGroup = (props) => {
  return (
    <div className="expandedRow">
      <div className="column_left">
        <p className='text-grey'>{props.t('assigned_accounts')}:</p>
        <div className="row margin-t1">
          {props.SMAccounts.map((account, i) => {
            return (
              <Tooltip key={i} content={account.social_name} direction="top">
                <div onClick={e => props.selectMediaAccountForTeamMember(account, props.data)}>
                  {props.checkAccountPhoto(account, props.data.sm_accounts)}
                </div>
              </Tooltip>
            )
          }
          )}
        </div>
      </div>
    </div>
  )
}

const Team = ({ authTokens, isAdmin, headers }) => {

  const { t, i18n } = useTranslation(['settings']);


  const [stateModalOptions, setStateModalOptions] = useState({
    display: false,
    status: "",
    message: "",
    loader: false
  });
  const closeStateModal = () => {
    setStateModalOptions({
      display: false,
      status: "",
      message: "",
      loader: false
    })
  }

  const [SMAccounts, setSMAccounts] = useState([]);
  const [team, setTeam] = useState([]);

  const selectMediaAccountForTeamMember = (account, teamMember) => {

    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true
    });
    //Select all groups
    let updatedTeam = [...team];
    //Select the group
    let indexTeamMember = team.findIndex((member) => member._id === teamMember._id);

    //check within that group if account already exist.
    let indexSpecialGroup = updatedTeam[indexTeamMember].sm_accounts.findIndex(sm_account => sm_account === account._id);
    if (indexSpecialGroup === -1) {
      updatedTeam[indexTeamMember].sm_accounts.push(account._id);
    }
    else {
      const updated_sm_accounts = updatedTeam[indexTeamMember].sm_accounts.filter(sm_account => sm_account !== account._id);
      updatedTeam[indexTeamMember].sm_accounts = updated_sm_accounts;
    }

    // AXIOS UPDATE GROUP DOCUMENT WITH NEW ASSOCIATED ACCOUNTS
    const payload = updatedTeam[indexTeamMember];

    axios
      .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/update_accounts_team_member", payload, {
        headers: headers,
      })
      .then(() => {
      })
      .catch(() => {
      });

    setTeam(updatedTeam);

    closeStateModal();
  }

  const unlinkAccountConfirmation = (data, confirmation) => {

    if (!confirmation) {
      closeStateModal();
      return;
    }

    const payload = {
      _id: data.account_id,
      company_id: authTokens.account_id,
      admin_id: authTokens.user_id
    }

    axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/remove_user_account/", payload, {
      headers: headers,
    }).then(() => {
      setTeam(team.filter((account) => account._id !== data.account_id));
      fetchTeamData();
    })
      .catch((error) => {
        console.log(error);
      });

    closeStateModal();

  }

  const unlinkAccountModal = (account_id) => {

    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: false,
      status: "alert",
      message: t('warning_team_delete'),
      choice: true,
      choice_data: {
        button_action_1_title: t('remove_account'),
        button_action_1_style: "action_button red",
        button_action_2_title: t('keep_account'),
        button_action_2_style: "action_button",
      },
      callBackFn: unlinkAccountConfirmation,
      dataForCallback: {
        account_id: account_id
      }
    });
  }

  const makeAdminConfirmation = (data, confirmation) => {
    if (!confirmation) {
      closeStateModal();
      return;
    }

    const payload = {
      _id: data.account_id,
      company_id: authTokens.account_id,
      admin_id: authTokens.user_id
    }

    axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/make_admin/", payload, {
      headers: headers,
    }).then(() => {
      fetchTeamData();
      setStateModalOptions({
        ...stateModalOptions,
        display: true,
        loader: false,
        status: "info",
        message: t('made_admin')
      });
    })
      .catch((error) => {
        console.log(error);
        closeStateModal();
      });

  }

  const makeAdminModal = (account_id) => {

    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: false,
      status: "alert",
      message: t('warning_make_admin'),
      choice: true,
      choice_data: {
        button_action_1_title: t('confirm'),
        button_action_1_style: "action_button",
        button_action_2_title: t('cancel'),
        button_action_2_style: "action_button red",
      },
      callBackFn: makeAdminConfirmation,
      dataForCallback: {
        account_id: account_id
      }
    });
  }

  const makeRegUserConfirmation = (data, confirmation) => {
    if (!confirmation) {
      closeStateModal();
      return;
    }

    const payload = {
      _id: data.account_id,
      company_id: authTokens.account_id,
      admin_id: authTokens.user_id
    }

    axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/make_regular_user/", payload, {
      headers: headers,
    }).then(() => {
      fetchTeamData();
      setStateModalOptions({
        ...stateModalOptions,
        display: true,
        loader: false,
        status: "info",
        message: t('made_reg')
      });
    })
      .catch((error) => {
        console.log(error);
        closeStateModal();
      });

  }

  const makeRegUserModal = (account_id) => {
    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: false,
      status: "alert",
      message: t('warning_make_reg_user'),
      choice: true,
      choice_data: {
        button_action_1_title: t('confirm'),
        button_action_1_style: "action_button",
        button_action_2_title: t('cancel'),
        button_action_2_style: "action_button red",
      },
      callBackFn: makeRegUserConfirmation,
      dataForCallback: {
        account_id: account_id
      }
    });
  }


  const TeamDeleteButtonCell = ({ row, unlinkAccountModal }) => {
    return (
      <div>
        <Tooltip content={t('delete_account')} direction="left">
          <svg viewBox="0 0 24 24" className="social_delete_button" onClick={() => unlinkAccountModal(row._id)}>
            <path fill="current" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
          </svg>
        </Tooltip>
      </div>
    )
  }

  const TeamAdminButtonCell = ({ row, makeAdminModal, makeRegUserModal }) => {
    return (
      <div>
        {row.account_admin ?
          <Tooltip content={t('make_reg')} direction="left">
            <svg viewBox="0 0 24 24" className="social_make_admin_button" onClick={() => makeRegUserModal(row._id)}>
              <path fill="current" d="M10 12C12.21 12 14 10.21 14 8S12.21 4 10 4 6 5.79 6 8 7.79 12 10 12M10 6C11.11 6 12 6.9 12 8S11.11 10 10 10 8 9.11 8 8 8.9 6 10 6M12 20H2V17C2 14.33 7.33 13 10 13C11 13 12.38 13.19 13.71 13.56C13.41 14.12 13.23 14.74 13.21 15.39C12.23 15.1 11.11 14.9 10 14.9C7.03 14.9 3.9 16.36 3.9 17V18.1H12C12 18.13 12 18.17 12 18.2V20M20.8 17V15.5C20.8 14.1 19.4 13 18 13C16.6 13 15.2 14.1 15.2 15.5V17C14.6 17 14 17.6 14 18.2V21.7C14 22.4 14.6 23 15.2 23H20.7C21.4 23 22 22.4 22 21.8V18.3C22 17.6 21.4 17 20.8 17M19.5 17H16.5V15.5C16.5 14.7 17.2 14.2 18 14.2C18.8 14.2 19.5 14.7 19.5 15.5V17Z" />
            </svg>
          </Tooltip>
          :
          <Tooltip content={t('make_admin')} direction="left">
            <svg viewBox="0 0 24 24" className="social_make_admin_button" onClick={() => makeAdminModal(row._id)}>
              <path fill="current" d="M5.8 10C5.4 8.8 4.3 8 3 8C1.3 8 0 9.3 0 11S1.3 14 3 14C4.3 14 5.4 13.2 5.8 12H7V14H9V12H11V10H5.8M3 12C2.4 12 2 11.6 2 11S2.4 10 3 10 4 10.4 4 11 3.6 12 3 12M16 4C13.8 4 12 5.8 12 8S13.8 12 16 12 20 10.2 20 8 18.2 4 16 4M16 10.1C14.8 10.1 13.9 9.2 13.9 8C13.9 6.8 14.8 5.9 16 5.9C17.2 5.9 18.1 6.8 18.1 8S17.2 10.1 16 10.1M16 13C13.3 13 8 14.3 8 17V20H24V17C24 14.3 18.7 13 16 13M22.1 18.1H9.9V17C9.9 16.4 13 14.9 16 14.9C19 14.9 22.1 16.4 22.1 17V18.1Z" />
            </svg>
          </Tooltip>
        }
      </div>
    )
  }

  const TeamCols = [
    {
      name: 'Profile',
      sortable: false,
      width: '75px',
      cell: row => {
        return (
          // <div className="profile_icon">
          <div className={row.account_admin ? "profile_icon profile_blue border-gradient-blue" : "profile_icon profile_green border-gradient-green"}>
            {
              row.user_profile_url ?
                <img src={row.user_profile_url} alt={row.first_name + " " + row.name} />
                : row.first_name.charAt() + row.name.charAt()
            }
          </div >
        )
      }
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: row => {
        return row.first_name + " " + row.name
      }
    },
    {
      cell: row => <TeamAdminButtonCell row={row} makeAdminModal={makeAdminModal} makeRegUserModal={makeRegUserModal} />,
      allowOverflow: true,
      omit: !isAdmin,
      button: true,
      width: '56px',
    },
    {
      cell: row => <TeamDeleteButtonCell row={row} unlinkAccountModal={unlinkAccountModal} />,
      allowOverflow: true,
      omit: !isAdmin,
      button: true,
      width: '56px',
    }
    // {
    //   name: 'Permissions',
    //   selector: 'permissions',
    //   sortable: true,
    //   cell: row => {
    //     if (row.account_admin) {
    //       return t('admin')
    //     }
    //     else {
    //       return t('team_member')
    //     }
    //   }
    // }
  ]

  const [tmEmail, setTmEmail] = useState({
    email: '',
    isSubmitting: false
  });
  const [errorMsg, setErrorMsg] = useState('');

  const addTeamMember = (e) => {
    if (!tmEmail.email) return;

    setErrorMsg('');
    setTmEmail({
      ...tmEmail,
      isSubmitting: true
    })

    const payload = {
      email: tmEmail.email,
      language: i18n.language,
      first_name: authTokens.user_first_name,
      name: authTokens.user_name,
      company_id: authTokens.account_id,
      user_id: authTokens.user_id
    }

    axios
      .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/invite_team_member", payload, {
        headers: headers,
      })
      .then(() => {
        setTmEmail({
          email: '',
          isSubmitting: false
        })
        setStateModalOptions({
          ...stateModalOptions,
          display: true,
          loader: false,
          status: "sent",
          message: t('invitation_sent'),
          choice: false
        });

      })
      .catch((error) => {
        setTmEmail({
          ...tmEmail,
          isSubmitting: false
        })
        if (error.response.status === 400 && error.response.data.message.length > 0) {
          setErrorMsg(error.response.data.message);
        }
      });

  }


  const checkAccountPhoto = (accountData, teamMemberSMAccounts) => {

    let selected = false;
    let indexFound = teamMemberSMAccounts.findIndex((media_id) => media_id === accountData._id);

    if (indexFound !== -1) {
      selected = true;
    }

    switch (accountData.media) {
      case "Facebook":
        const fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={fb_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={FBicon} alt="Media" />
          </div>)
      case "Twitter":
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={TWicon} alt="Media" />
          </div>)
      case "LinkedIn":
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={LKicon} alt="Media" />
          </div>)
      case "Instagram":
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={IGicon} alt="Media" />
          </div>)
      case "Pinterest":
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={PNicon} alt="Media" />
          </div>)
      case "GMB":
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={GMBicon} alt="Media" />
          </div>)
      case "TikTok":
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={TKicon} alt="Media" />
          </div>)
      default:
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={TWicon} alt="Media" />
          </div>)
    }
  }


  const fetchTeamData = async () => {

    const payload = {
      company_id: authTokens.account_id,
      user_id: authTokens.user_id
    }

    await axios
      .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/get_team", payload, {
        headers: headers,
      })
      .then((response) => {
        setTeam(response.data);
      })
      .catch(() => {
      });
  }

  const fetchAvailableSocialMedia = async () => {

    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true
    });

    const payload = {
      user_id: authTokens.user_id,
      company_id: authTokens.account_id
    }

    await axios
      .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_accounts_no_auth_check", payload, {
        headers: headers,
      })
      .then((response) => {
        setSMAccounts(response.data.sm_accounts);
        fetchTeamData();
      })
      .catch(() => {
      });
    closeStateModal();
  }

  useEffect(() => {
    fetchAvailableSocialMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
      {isAdmin ?
        <span className="table_search_bar">
          <input type="text" name="tm_email" className="dashboard_input" placeholder={t('invite_by_email')} value={tmEmail.email} onChange={(e) => setTmEmail({ ...tmEmail, email: e.target.value })} />
          <span className="table_add_item">
            {!tmEmail.isSubmitting ?
              <svg viewBox="0 0 24 24" onClick={addTeamMember}>
                <path fill="current" d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
              </svg>
              :
              <div className="lds-ring-loader-small"><div></div><div></div><div></div><div></div></div>
            }
          </span>
        </span>
        : ""}
      {errorMsg ?
        <p className='text-red p-small'>{errorMsg}</p>
        : ""
      }
      {(team && team.length > 0) ?
        isAdmin ?
          <DataTable
            columns={TeamCols}
            data={team}
            noHeader={true}
            noTableHead={true}
            expandableRows
            expandableRowsComponent={ExpandableComponentSMAccountsGroup}
            expandableRowsComponentProps={{ SMAccounts: SMAccounts, checkAccountPhoto: checkAccountPhoto, selectMediaAccountForTeamMember: selectMediaAccountForTeamMember, t: t }}
          />
          :
          <DataTable
            columns={TeamCols}
            data={team}
            noHeader={true}
            noTableHead={true}
          />
        :
        <p className="text-grey text-bold">{t('invite_team_members')}</p>

      }
    </div>
  )
}

export default Team