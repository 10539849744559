import React, { useState, useEffect } from 'react'
import axios from "axios";
import moment from 'moment'
import StateModal from '../../Loader/StateModal';
import { useTranslation } from "react-i18next";
import Tooltip from '../../sidebar/Tooltip';
import { useHistory } from "react-router-dom";

import HeaderThird from '../../header/HeaderThird';

import CalendarThirdModal from './CalendarThirdModal';
import CalendarThird from './CalendarThird';
import CalendarThirdHelpModal from './CalendarThirdHelpModal';

import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import PNicon from "../../../images/social_media/pinterest.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"


const CalendarThirdMonthly = (props) => {
    const party_id = props.match.params.id;

    const { t } = useTranslation(['calendar']);
    const history = useHistory();

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }

    const [SMAccounts, setSMAccounts] = useState([]);
    const [selectedSMAccounts, setSelectedSMAccounts] = useState([]);


    const selectMediaAccount = (account) => {
        let selectedMediaAccount = selectedSMAccounts;

        let resultingAccountsArray = [];

        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === account.social_id);

        if (indexFound !== -1) {
            const filteredMedia = selectedMediaAccount.filter(item => item.social_id !== account.social_id);
            resultingAccountsArray = [...filteredMedia];
            setSelectedSMAccounts(filteredMedia);

        }
        else {
            selectedMediaAccount.push(account);
            resultingAccountsArray = [...selectedMediaAccount];

            setSelectedSMAccounts([...selectedMediaAccount]);
        }

        if (resultingAccountsArray.length > 0) {
            let calendar_array = [];
            calendarEvents.forEach(event => {
                let indexFound = resultingAccountsArray.findIndex((media) => media._id === event.sm_account._id);

                if (indexFound !== -1) {
                    calendar_array.push(event)
                }
            })

            setFilteredCalendarEvents(calendar_array)
        }
        else {
            setFilteredCalendarEvents([...calendarEvents]);
        }


    }

    const checkAccountPhoto = (accountData) => {

        if (!accountData) return;

        const selectedMediaAccount = selectedSMAccounts;
        let selected = false;
        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === accountData.social_id);

        if (indexFound !== -1) {
            selected = true;
        }

        switch (accountData.media) {
            case "Facebook":
                let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                if (accountData.type === "group") {
                    fb_picture_url = "https://graph.facebook.com/v12.0/" + accountData.social_id + "/picture?access_token=" + accountData.access_token;
                }

                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={fb_picture_url} alt={accountData.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={FBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Twitter":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "LinkedIn":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={LKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Instagram":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={IGicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Pinterest":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={PNicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "GMB":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={GMBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
        }
    }


    const [thirdPartyName, setThirdPartyName] = useState('')
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [filteredCalendarEvents, setFilteredCalendarEvents] = useState([]);

    const [selectedEvent, setSelectedEvent] = useState({});
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
        setShowModal(false);
    }

    const [showHelpModal, setShowHelpModal] = useState(false);
    const closeHelpModal = () => {
        setShowHelpModal(false);
    }

    const selectEvent = async (event) => {
        setSelectedEvent(event);
        setShowModal(true);
    }


    const [currentMonth, setCurrentMonth] = useState(moment());

    const nextMonth = () => {
        setCurrentMonth(moment(currentMonth).add(1, 'months'));
    };

    const prevMonth = () => {
        setCurrentMonth(moment(currentMonth).subtract(1, 'months'));
    };

    // const goToCurrentMonth = () => {
    //     setCurrentMonth(moment());
    // };

    const checkFilteredEvents = (all_events) => {
        if (selectedSMAccounts.length > 0) {
            let calendar_array = [];
            all_events.forEach(event => {
                let indexFound = selectedSMAccounts.findIndex((media) => media.social_id === event.sm_account._id);

                if (indexFound !== -1) {
                    calendar_array.push(event)
                }
            })

            setFilteredCalendarEvents(calendar_array)
        }
        else {
            setFilteredCalendarEvents([...all_events]);
        }
    }

    const fetchAllCalendarEvents = async () => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            party_id: party_id,
            date_range: currentMonth.startOf('month')
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/third_party/get_monthly_calendar_third_party", payload)
            .then((response) => {
                setThirdPartyName(response.data.third_party_name);
                setSMAccounts(response.data.accounts)
                setCalendarEvents(response.data.events);
                checkFilteredEvents(response.data.events);
            })
            .catch(() => {
            });

        closeStateModal();
    }

    const goToWeekly = () => {
        history.push({
            pathname: '/3ps/w/' + party_id,
        });
    }

    useEffect(() => {
        fetchAllCalendarEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMonth]);

    useEffect(() => {
        if (party_id) {
            fetchAllCalendarEvents();
        }
        setShowHelpModal(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <HeaderThird name={thirdPartyName} />
            <div className="content_row_third_party">
                {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
                {showModal ? <CalendarThirdModal closeModal={closeModal} selectedEvent={selectedEvent} /> : ""}
                {showHelpModal ? <CalendarThirdHelpModal closeModal={closeHelpModal} /> : ""}
                <div className="card flex-1">
                    <p className="text-grey text-italic">{t('filter_by_account')}:</p>
                    <div className='row row_spaced row_vcenter'>
                        <div className='row'>
                            {SMAccounts.map((account, i) => {
                                return (
                                    <div className="account_selection_account" key={i} onClick={e => selectMediaAccount(account)}>
                                        {checkAccountPhoto(account)}
                                    </div>
                                )
                            }
                            )}
                        </div>
                        <div className='row'>
                            <div className="nav_button margin-l1" onClick={() => goToWeekly()}>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9Zm18.7-3h6.15V11H25.7v26Zm-9.5 0h6.15V11H16.2v26ZM7 37h6.2V11H7v26Zm27.85 0H41V11h-6.15v26Z" />
                                </svg>
                                {t('weekly')}
                            </div>
                            <div className="nav_button margin-l1" onClick={() => setShowHelpModal(true)}>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M23.85 35.7q.8 0 1.35-.55t.55-1.35q0-.8-.55-1.35t-1.35-.55q-.8 0-1.35.55t-.55 1.35q0 .8.55 1.35t1.35.55Zm-1.65-7.4h2.85q0-1.55.5-2.525.5-.975 1.75-2.225 1.65-1.65 2.3-2.925.65-1.275.65-2.675 0-2.65-1.7-4.2-1.7-1.55-4.6-1.55-2.55 0-4.375 1.225Q17.75 14.65 16.9 16.8l2.65 1.1q.7-1.4 1.8-2.125 1.1-.725 2.6-.725 1.6 0 2.525.8.925.8.925 2.2 0 1-.55 1.925T25 22.15q-1.85 1.75-2.325 3-.475 1.25-.475 3.15ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z" />
                                </svg>
                                {t('help')}
                            </div>
                        </div>
                    </div>
                    <CalendarThird events={filteredCalendarEvents} currentMonth={currentMonth} prevMonth={prevMonth} nextMonth={nextMonth} selectEvent={selectEvent} />
                </div>
            </div>
        </>
    )
}



export default CalendarThirdMonthly