import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";


const SignaturesList = ({ authTokens }) => {

    const { t } = useTranslation(['manage']);
    const headers = {
        Authorization: authTokens.token,
    };

    const [signatures, setSignatures] = useState([]);

    const deleteSignature = async (signature_id) => {
        const payload = {
            signature_id: signature_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/delete_signature", payload, {
                headers: headers,
            })
            .then(() => {
                setSignatures(signatures.filter(sign => sign._id !== signature_id));
            })
            .catch(() => {
            });
    }

    const fetchSignatures = async () => {
        const payload = {
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/get_signatures", payload, {
                headers: headers,
            })
            .then((response) => {
                setSignatures(response.data);
            })
            .catch(() => {
            });
    }

    const formatText = (text) => {
        let formatted_text = text;
        return formatted_text.replace(/\r\n|\r|\n/g, "<br />");
    }

    useEffect(() => {
        fetchSignatures();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="card flex-1">
            <div className="title">{t('signatures_title')}</div>
            <ul className='tools_list'>
                {signatures && signatures.length > 0 ?
                    signatures.map((signature, i) => {
                        return (
                            <li key={i}>
                                <div>
                                    <div className='tools_list_title'>
                                        {signature.name}
                                        <svg viewBox="0 0 24 24" className="social_delete_button" onClick={() => deleteSignature(signature._id)}>
                                            <path fill="current" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                                        </svg>
                                    </div>

                                    <div className='row margin-t1'>
                                        <p className='text-grey text-italic' dangerouslySetInnerHTML={{ __html: formatText(signature.signature) }}></p>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                    :
                    <div className='row centered'>
                        <p className='text-grey'>{t('create_signatures')}</p>
                    </div>
                }
            </ul>
        </div>
    )
}

export default SignaturesList