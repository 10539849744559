import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useAuth } from '../../auth';
import { useTranslation } from "react-i18next";
import StateModal from '../Loader/StateModal';
// import LibraryPickerMDF from '../library/LibraryPickerMDF';

import CAIModalSelection from './CAIModalSelection';

import CAICoBrands from './CAIComponents/CAICoBrands';
import CAICoVisuals from './CAIComponents/CAICoVisuals';
import CAICoCaption from './CAIComponents/CAICoCaption';
import CAICoExport from './CAIComponents/CAICoExport';
import CAICoPreview from './CAIComponents/CAICoPreview';
import CAICoTemplates from './CAIComponents/CAICoTemplates';
import CAICoSelectedTemplates from './CAIComponents/CAICoSelectedTemplates';

import CAIpreviewImg from '../../images/illustrations/CAI-prepreview.png';


import "./content_ai.css";



let loaderBuildUp;

const ContentAIComposer = () => {
    const { t } = useTranslation(['content_ai']);
    const { authTokens } = useAuth();
    const { setAuthTokens } = useAuth();

    const headers = {
        Authorization: authTokens.token,
    };

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });

    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }


    const [showCAIModalSelection, setShowCAIModalSelection] = useState(false);
    const displayCAIModalSelection = () => {
        setShowCAIModalSelection(!showCAIModalSelection);
    }


    const [composerMenuItem, setComposerMenuItem] = useState('brand');

    const [itemsUnlocked, setItemsUnlocked] = useState({
        visuals: false,
        caption: false,
        export: false
    });


    const [templateData, setTemplateData] = useState({
        logo: '',
        brand_name: '',
        primary_color: '',
        description: '',
        headline: '',
        text: '',
        img: '',
        cta: '',
        cta_icon: 'arrow_right',
        size: 'square',
        dark_logo: 'logo',
        language: 'english'
    });

    const [captions, setCaptions] = useState([]);


    const [selectedCaptions, setSelectedCaptions] = useState([]);

    const [templates, setTemplates] = useState([]);
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [selectedTemplatesIds, setSelectedTemplatesIds] = useState([]);





    const templatesSelection = (media) => {
        const index_media = selectedTemplates.findIndex(selected_media => selected_media._id === media._id);

        if (index_media !== -1) {
            setSelectedTemplates(selectedTemplates.filter(selected_media => selected_media._id !== media._id));
            setSelectedTemplatesIds(selectedTemplatesIds.filter(selected_media_ids => selected_media_ids !== media._id));
        }
        else {
            setSelectedTemplates([...selectedTemplates,
                media
            ])
            setSelectedTemplatesIds([...selectedTemplatesIds,
            media._id
            ])
        }
    }




    const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
    const [isLoadingMoreTemplates, setIsLoadingMoreTemplates] = useState(false);
    const [loadingTemplatesArray, setLoadingTemplatesArray] = useState(0);


    useEffect(() => {

        if (isLoadingTemplates) {
            loaderBuildUp = setInterval(() => {
                setLoadingTemplatesArray((prev) => prev + 1)
            }, 1700)
        }
        else {
            clearInterval(loaderBuildUp);
            setLoadingTemplatesArray(0);
        }
    }, [isLoadingTemplates])


    const [team, setTeam] = useState([]);

    const fetchTeamData = async () => {

        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/get_full_team", payload, {
                headers: headers,
            })
            .then((response) => {
                setTeam(response.data);
            })
            .catch(() => {
            });
    }

    useEffect(() => {
        fetchTeamData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])


    return (
        <div className="content_row_maxed nowrap">
            {showCAIModalSelection ? <CAIModalSelection closeCAIModalSelection={displayCAIModalSelection} /> : ""}
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}

            <div className="composer_card">
                <div className='composer_menu'>
                    <div className={composerMenuItem === 'brand' ? 'composer_menu_item active' : 'composer_menu_item'} onClick={() => setComposerMenuItem('brand')}>
                        <div className='composer_menu_item_row'>
                            <svg viewBox="0 0 24 24">
                                <path fill="current" d="M12,11A1,1 0 0,1 13,12A1,1 0 0,1 12,13A1,1 0 0,1 11,12A1,1 0 0,1 12,11M4.22,4.22C5.65,2.79 8.75,3.43 12,5.56C15.25,3.43 18.35,2.79 19.78,4.22C21.21,5.65 20.57,8.75 18.44,12C20.57,15.25 21.21,18.35 19.78,19.78C18.35,21.21 15.25,20.57 12,18.44C8.75,20.57 5.65,21.21 4.22,19.78C2.79,18.35 3.43,15.25 5.56,12C3.43,8.75 2.79,5.65 4.22,4.22M15.54,8.46C16.15,9.08 16.71,9.71 17.23,10.34C18.61,8.21 19.11,6.38 18.36,5.64C17.62,4.89 15.79,5.39 13.66,6.77C14.29,7.29 14.92,7.85 15.54,8.46M8.46,15.54C7.85,14.92 7.29,14.29 6.77,13.66C5.39,15.79 4.89,17.62 5.64,18.36C6.38,19.11 8.21,18.61 10.34,17.23C9.71,16.71 9.08,16.15 8.46,15.54M5.64,5.64C4.89,6.38 5.39,8.21 6.77,10.34C7.29,9.71 7.85,9.08 8.46,8.46C9.08,7.85 9.71,7.29 10.34,6.77C8.21,5.39 6.38,4.89 5.64,5.64M9.88,14.12C10.58,14.82 11.3,15.46 12,16.03C12.7,15.46 13.42,14.82 14.12,14.12C14.82,13.42 15.46,12.7 16.03,12C15.46,11.3 14.82,10.58 14.12,9.88C13.42,9.18 12.7,8.54 12,7.97C11.3,8.54 10.58,9.18 9.88,9.88C9.18,10.58 8.54,11.3 7.97,12C8.54,12.7 9.18,13.42 9.88,14.12M18.36,18.36C19.11,17.62 18.61,15.79 17.23,13.66C16.71,14.29 16.15,14.92 15.54,15.54C14.92,16.15 14.29,16.71 13.66,17.23C15.79,18.61 17.62,19.11 18.36,18.36Z" />
                            </svg>
                        </div>
                        {t('brand')}
                    </div>
                    <div className={composerMenuItem === 'visuals' ? 'composer_menu_item active' : itemsUnlocked.visuals ? 'composer_menu_item' : 'composer_menu_item inactive'} onClick={() => itemsUnlocked.visuals ? setComposerMenuItem('visuals') : null}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M20.7 4.1C18.7 4.8 15.9 5.5 12 5.5C8.1 5.5 5.1 4.7 3.3 4.1C2.7 3.8 2 4.3 2 5V19C2 19.7 2.7 20.2 3.3 20C5.4 19.3 8.1 18.5 12 18.5C15.9 18.5 18.7 19.3 20.7 20C21.4 20.2 22 19.7 22 19V5C22 4.3 21.3 3.8 20.7 4.1M12 15C9.7 15 7.5 15.1 5.5 15.4L9.2 11L11.2 13.4L14 10L18.5 15.4C16.5 15.1 14.3 15 12 15Z" />
                        </svg>
                        {t('ai_image')}
                    </div>
                    <div className={composerMenuItem === 'caption' ? 'composer_menu_item active' : itemsUnlocked.caption ? 'composer_menu_item' : 'composer_menu_item inactive'} onClick={() => itemsUnlocked.caption ? setComposerMenuItem('caption') : null}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,6V8H3V6H21M3,18H12V16H3V18M3,13H21V11H3V13Z" />
                        </svg>
                        {t('ai_caption')}
                    </div>
                    <div className={composerMenuItem === 'export' ? 'composer_menu_item active' : itemsUnlocked.export ? 'composer_menu_item' : 'composer_menu_item inactive'} onClick={() => itemsUnlocked.export ? setComposerMenuItem('export') : null}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M11.93 5L14.76 7.83L5 17.59L6.42 19L16.18 9.25L19 12.07V5H11.93Z" />
                        </svg>
                        {t('export')}
                    </div>
                    <div className='composer_menu_indicator_4'></div>
                </div>
                <div className='composer_components margin-t1'>
                    <CAICoBrands authTokens={authTokens} headers={headers} menuItem={composerMenuItem} setMenuItem={setComposerMenuItem} templateData={templateData} setTemplateData={setTemplateData} itemsUnlocked={itemsUnlocked} setItemsUnlocked={setItemsUnlocked} stateModalOptions={stateModalOptions} setStateModalOptions={setStateModalOptions} closeStateModal={closeStateModal} />
                    <CAICoVisuals
                        authTokens={authTokens}
                        headers={headers}
                        displayCAIModalSelection={displayCAIModalSelection}
                        menuItem={composerMenuItem}
                        setMenuItem={setComposerMenuItem}
                        templateData={templateData}
                        setTemplateData={setTemplateData}
                        setCaptions={setCaptions}
                        itemsUnlocked={itemsUnlocked}
                        setItemsUnlocked={setItemsUnlocked}
                        templates={templates}
                        setTemplates={setTemplates}
                        setIsLoadingTemplates={setIsLoadingTemplates}
                        setSelectedTemplates={setSelectedTemplates}
                        setSelectedTemplatesIds={setSelectedTemplatesIds}
                        selectedTemplates={selectedTemplates}
                        stateModalOptions={stateModalOptions}
                        setStateModalOptions={setStateModalOptions}
                        closeStateModal={closeStateModal}
                        setIsLoadingMoreTemplates={setIsLoadingMoreTemplates}
                        team={team} />
                    <CAICoCaption authTokens={authTokens} headers={headers} menuItem={composerMenuItem} setMenuItem={setComposerMenuItem} templateData={templateData} setTemplateData={setTemplateData} itemsUnlocked={itemsUnlocked} setItemsUnlocked={setItemsUnlocked} captions={captions} setCaptions={setCaptions} selectedCaptions={selectedCaptions} setSelectedCaptions={setSelectedCaptions} />
                    <CAICoExport authTokens={authTokens} setAuthTokens={setAuthTokens} headers={headers} menuItem={composerMenuItem} selectedCaptions={selectedCaptions} selectedTemplates={selectedTemplates} templateData={templateData} stateModalOptions={stateModalOptions} setStateModalOptions={setStateModalOptions} closeStateModal={closeStateModal} />
                </div>
            </div>
            {isLoadingTemplates ?
                <div className='card_preview'>
                    <div className='composer_component_thread'>
                        <div className="title">
                            {t('templates')}
                        </div>
                        <div className='content_ai_loading_view'>
                            <div className='templates_loader'>
                                <div className="lds-ring-loader-small"><div></div><div></div><div></div><div></div></div>
                            </div>
                            <ul>
                                <li className={loadingTemplatesArray >= 0 ? 'done' : ''}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                    </svg>
                                    {t('uploading_data')}
                                </li>
                                <li className={loadingTemplatesArray >= 1 ? 'done' : ''}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                    </svg>
                                    {t('analyse_logo')}
                                </li>
                                <li className={loadingTemplatesArray >= 2 ? 'done' : ''}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                    </svg>
                                    {t('analyse_size')}
                                </li>
                                <li className={loadingTemplatesArray >= 3 ? 'done' : ''}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                    </svg>
                                    {t('analyse_headline')}
                                </li>
                                <li className={loadingTemplatesArray >= 4 ? 'done' : ''}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                    </svg>
                                    {t('generating_captions')}
                                </li>
                                <li className={loadingTemplatesArray >= 5 ? 'done' : ''}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                    </svg>
                                    {t('generating_templates')}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                : selectedTemplates && selectedTemplates.length > 0 && (composerMenuItem === 'caption' || composerMenuItem === 'export') ?
                    <CAICoSelectedTemplates templateData={templateData} selectedTemplates={selectedTemplates} setSelectedTemplates={setSelectedTemplates} selectedTemplatesIds={selectedTemplatesIds} setSelectedTemplatesIds={setSelectedTemplatesIds} selectedCaptions={selectedCaptions} />
                    : templates && templates.length > 0 ?
                        <CAICoTemplates templateData={templateData} templates={templates} selectedTemplatesIds={selectedTemplatesIds} templatesSelection={templatesSelection} isLoadingMoreTemplates={isLoadingMoreTemplates} />
                        : templateData.primary_color ?
                            <div className="card_preview">
                                <div className='composer_component_thread'>
                                    <div className="title">
                                        {t('preview')}
                                    </div>
                                    <CAICoPreview templateData={templateData} />
                                </div>
                            </div>
                            :
                            <div className="cai_preview_img">
                                <img src={CAIpreviewImg} alt="" />
                            </div>
            }

        </div>
    )
}

export default ContentAIComposer