import React, { useEffect } from "react";
import axios from "axios";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./auth";
import moment from 'moment'


let last_token_check;
let api_launched;

const PrivateRoute = ({ component: Component, render: Render, ...rest }) => {


    const { authTokens } = useAuth();
    const { setAuthTokens } = useAuth();

    const now = Math.round(Date.now() / 1000);


    const checkToken = async (token) => {
        api_launched = true;
        const new_nac = Number(moment(Date.now()).add(1, 'days').format("X"));

        const payload = {
            token: token,
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/check_token", payload)
            .then(() => {
                setAuthTokens({
                    ...authTokens,
                    nac: new_nac
                });
                last_token_check = new_nac;
                api_launched = false;
            })
            .catch(() => {
                setAuthTokens({
                    name: null,
                    isAuthenticated: false,
                    token: null,
                });

            });
    };


    if (!last_token_check) {
        if (authTokens.nac) {
            last_token_check = authTokens.nac;
        }
        else {
            last_token_check = Number(moment(Date.now()).add(1, 'hours').format("X"));
        }
    }

    if (last_token_check < now && api_launched !== true) {
        checkToken(authTokens.token);
    }


    useEffect(() => {
        if (!authTokens.token || !authTokens.isAuthenticated) {
            setAuthTokens({
                name: null,
                isAuthenticated: false,
                token: null,
            });
        }
    }, [authTokens])

    return (
        <Route
            {...rest}
            render={(props) =>
                authTokens.isAuthenticated ? (
                    Render ? (
                        Render(props)
                    ) : Component ? (
                        <Component {...props} />
                    ) : null
                ) : (
                    <Redirect to="/signin" />
                )
            }
        />
    );
}

export default PrivateRoute;
