import React from 'react'
import { useTranslation } from "react-i18next";
import TooltipHelp from '../../settings/TooltipHelp';

import "../../styles/vswitch.css";


const OptionsRSS = ({
    editorMenuItem,
    publicationData,
    setPublicationData
}) => {

    const { t } = useTranslation(['composer']);


    return (
        <>
            <div className={editorMenuItem === 'options' ? 'editor_component active' : 'editor_component'}>
                <div className='editor_title margin-t1'>{t('about_first_publication')}</div>
                <div className="toggle-switch switch-vertical margin-t2">
                    <input id="toggle-a" type="radio" name="new_articles_only" onChange={e => { setPublicationData({ ...publicationData, [e.target.name]: 'new_only' }); }} checked={publicationData.new_articles_only === 'new_only' ? true : false} />
                    <label htmlFor="toggle-a">
                        {t('newest_article_n_following')}
                        <TooltipHelp content={t('tooltip_new_only')} direction="right">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </label>
                    <input id="toggle-b" type="radio" name="new_articles_only" onChange={e => { setPublicationData({ ...publicationData, [e.target.name]: 'old_and_new' }); }} checked={publicationData.new_articles_only === 'old_and_new' ? true : false} />
                    <label htmlFor="toggle-b">
                        {t('last_five_articles_n_following')}
                        <TooltipHelp content={t('tooltip_new_and_five')} direction="right">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>

                    </label>
                    <span className="toggle-outside">
                        <span className="toggle-inside"></span>
                    </span>
                </div>
            </div>
        </>
    )
}

export default OptionsRSS