import React, { useState } from 'react'

import "./tooltip.css"

const Tooltip = (props) => {

    const [active, setActive] = useState(false);

    const showTip = () => {
        setActive(true);
        setTimeout(() => {
            setActive(false)
        }, 2000);
    }

    const hideTip = () => {
        setActive(false);
    }

    return (
        <div className="tooltip_wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
            {props.children}
            {active && (
                <div className={`tooltip_tip ${props.direction || "right"}`}>
                    {props.content}
                </div>
            )}
        </div>
    )
}

export default Tooltip
