import React from 'react'
import { useTranslation } from "react-i18next";

import "./mdn_table.css";


import FBicon from "../../images/sm_icons/facebook.svg";
import TWicon from "../../images/sm_icons/twitter.svg";
import IGicon from "../../images/sm_icons/instagram.svg";
import LKicon from "../../images/sm_icons/linkedin.svg";
import PNicon from "../../images/sm_icons/pinterest.svg";
import GMBicon from "../../images/sm_icons/gmb.svg";

const NewPricingTable = () => {
    const { t } = useTranslation('pricingTableMDN');

    return (
        <div className='pricing-feature-table'>
            <div className='pft-body'>
                <div className='pft-row'>
                    <div className='pft-body-cell'></div>
                    <div className='pft-body-cell plan'>{t('free_plan')}</div>
                    <div className='pft-body-cell plan'>{t('pro_plan')}</div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell title'>{t('social_networks')}</div>
                    <div className='pft-body-cell'></div>
                    <div className='pft-body-cell'></div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('networks_avail')}</div>
                    <div className='pft-body-cell icons'>
                        <img src={FBicon} alt="Facebook" />
                        <img src={IGicon} alt="Instagram" />
                        <img src={TWicon} alt="Twitter" />
                        <img src={LKicon} alt="LinkedIn" />
                        <img src={GMBicon} alt="Google My Business" />
                        <img src={PNicon} alt="Pinterest" />
                    </div>
                    <div className='pft-body-cell icons'>
                        <img src={FBicon} alt="Facebook" />
                        <img src={IGicon} alt="Instagram" />
                        <img src={TWicon} alt="Twitter" />
                        <img src={LKicon} alt="LinkedIn" />
                        <img src={GMBicon} alt="Google My Business" />
                        <img src={PNicon} alt="Pinterest" />
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('max_accounts')}</div>
                    <div className='pft-body-cell'>5</div>
                    <div className='pft-body-cell'>{t('unlimited')}</div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('max_pub')}</div>
                    <div className='pft-body-cell'>15 /{t('month')}</div>
                    <div className='pft-body-cell'>{t('unlimited')}</div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell title'>{t('post_composer')}</div>
                    <div className='pft-body-cell'></div>
                    <div className='pft-body-cell'></div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('txt_variation')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('fb_link_post')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('fb_images_post')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('fb_video_post')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('fb_tags')}</div>
                    <div className='pft-body-cell svg-red'>
                        {t('soon')}
                    </div>
                    <div className='pft-body-cell'>{t('soon')}</div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('ig_single_image_post')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('ig_multi_images_post')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                        (Carousel)
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                        (Carousel)
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('ig_video_posts')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('ig_reels')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('ig_stories')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell'>{t('soon')}</div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('ig_tags')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('lk_link_post')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('lk_images_post')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('lk_video_post')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('lk_tags')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('tw_link_post')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('tw_images_post')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('tw_video_post')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('tw_threads')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('tw_tags')}</div>
                    <div className='pft-body-cell svg-red'>
                        {t('soon')}
                    </div>
                    <div className='pft-body-cell'>{t('soon')}</div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('gmb_post')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('gmb_events')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('gmb_action_button')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('pn_image_pins')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('pn_video_pins')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell title'>{t('media')}</div>
                    <div className='pft-body-cell'></div>
                    <div className='pft-body-cell'></div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('max_photo_post')}</div>
                    <div className='pft-body-cell'>
                        4
                    </div>
                    <div className='pft-body-cell'>
                        10
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('media_library')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('photos_upload')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('canva_designs')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('unsplash')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('no_watermark')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('video_upload')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                        (max 250Mo/video)
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>GIF</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('emoji')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>

                <div className='pft-row'>
                    <div className='pft-body-cell title'>{t('tools')}</div>
                    <div className='pft-body-cell'></div>
                    <div className='pft-body-cell'></div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('instant_preview')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('link_preview')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('accounts_groups')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('posts_rss')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>

                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('hashtags_groups')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('hashtags_sensitivity')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('hashtags_pop')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell title'>{t('scheduling')}</div>
                    <div className='pft-body-cell'></div>
                    <div className='pft-body-cell'></div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('scheduled_posts')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('repeat_posts')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('reschedule_posts')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('spread_posts')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell title'>{t('calendars')}</div>
                    <div className='pft-body-cell'></div>
                    <div className='pft-body-cell'></div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('monthly_calendar')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('weekly_calendar')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('pub_indicator')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell title'>{t('organizer')}</div>
                    <div className='pft-body-cell'></div>
                    <div className='pft-body-cell'></div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('organizer_access')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>

                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('edit_delete')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('campaigns')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell title'>{t('analytics')}</div>
                    <div className='pft-body-cell'></div>
                    <div className='pft-body-cell'></div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('global_analytics')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('analytics_network')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('analytics_post')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('spk_matrix')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('best_perf_post')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell title'>{t('team')}</div>
                    <div className='pft-body-cell'></div>
                    <div className='pft-body-cell'></div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('team_management')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('assigned_acc')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('media_folder')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell title'>{t('assistance')}</div>
                    <div className='pft-body-cell'></div>
                    <div className='pft-body-cell'></div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('chat_support')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('email_support')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>

                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('new_feature_vote')}</div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('priority_support')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
                <div className='pft-row'>
                    <div className='pft-body-cell'>{t('feature_request')}</div>
                    <div className='pft-body-cell svg-red'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </div>
                    <div className='pft-body-cell svg-green'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewPricingTable