import React, { useState, useLayoutEffect, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import Tooltip from '../../sidebar/Tooltip';
import Picker from 'emoji-picker-react';
import GifPicker from '@progresso/react-giphy-picker-https';
import UnsplashReact, {
    withDefaultProps,
    BlobUploader,
} from "unsplash-react"
import { Tweet } from 'react-twitter-widgets'
import axios from "axios";

import "./composer_thread.css";


import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"


const MIN_TEXTAREA_HEIGHT = 35;
const MAX_CHAR = 275; // 280-5 chars for thread count.

const handleMediaFile = (file) => {
    return new Promise(function (resolve, reject) {
        let fr = new FileReader();

        fr.onload = function () {
            resolve(fr.result);
        };

        fr.onerror = function () {
            reject(fr);
        };

        fr.readAsDataURL(file);
    });
}


const ComposerCoThreadTweet = ({
    headers,
    tweetIndex,
    threadLength,
    currentPlan,
    displayUpgradeModal,
    accountData,
    publicationData,
    handleTweetText,
    singleTweetData,
    addTweet,
    deleteTweet,
    setActiveArea,
    activeArea,
    displayLibrary,
    deleteMedia,
    resizeFile,
    setVideoMeta,
    uploadImage,
    uploadVideo,
    videoUploadProgress,
    mediaLoader,
    setImagesRaw,
    displayCropper,
    stateModalOptions,
    setStateModalOptions
}) => {
    const { t } = useTranslation(['composer']);

    const textareaRef = React.useRef(null);

    const [areaFocused, setAreaFocused] = useState(false);

    const [bigFileMsg, setBigFileMsg] = useState(false);

    const [characterCount, setCharacterCount] = useState(MAX_CHAR);
    const [ringStyle, setRingStyle] = useState()

    const styleCounter = (charLength) => {
        const charCount = MAX_CHAR - charLength;
        const r = 10;
        const circleLength = 2 * Math.PI * r;
        const twitterBlue = "rgb(29, 161, 242)";
        let colored = (circleLength * (MAX_CHAR - charCount)) / MAX_CHAR;
        let gray = circleLength - colored;

        setRingStyle({
            stroke: (charCount) <= 0 ? "red" :
                (charCount) <= 20 ? "orange" :
                    twitterBlue,
            strokeDasharray: `${colored} ${gray}`
        })
    }

    const [link, setLink] = useState('');
    const initialLinkPreview = {
        image: '',
        site: '',
        title: ''
    }
    const [linkPreview, setLinkPreview] = useState(initialLinkPreview);
    const [quoteId, setQuoteId] = useState('');


    const handleInputChange = (e) => {

        let new_text = e.target.value;


        let matches = new_text.match(/\bhttp\S+/gi);
        let string_to_count = e.target.value;
        let url_count = 0;

        if (matches) {
            matches.forEach(string => {
                string_to_count = string_to_count.replaceAll(string, "");
                url_count++;
            })

            if (matches[0].includes('twitter.com') && matches[0].includes('status')) {
                let raw_quote_link = matches[0].split('/status/');
                setQuoteId(raw_quote_link[1].replaceAll("/", ""));
                setLinkPreview(initialLinkPreview);
            }
            else {
                setQuoteId('');
                setLink(matches[0]);
            }
        }
        else {
            setQuoteId('');
            setLink(initialLinkPreview);
        }

        handleTweetText(e.target.value, tweetIndex);
        setCharacterCount(MAX_CHAR - string_to_count.length - 23 * url_count);
        styleCounter(string_to_count.length + 23 * url_count);

        if (MAX_CHAR - string_to_count.length - 23 * url_count <= 0) {
            addTweet(tweetIndex);
            return;
        }

        let line_breaks = new_text.split('\n\n\n');
        console.log(line_breaks.length);
        if (line_breaks.length > 1) {
            addTweet(tweetIndex);
        }
    }

    const [dragActive, setDragActive] = useState(false);

    const onDragOver = (event) => {
        setDragActive(true);
        event.stopPropagation();
        event.preventDefault();
    }

    const onDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const onDragLeave = (event) => {
        setDragActive(false);
        event.preventDefault();
        event.stopPropagation();
    }

    const onFileDrop = async (event) => {
        setBigFileMsg(false);
        setDragActive(false);
        event.preventDefault();
        event.stopPropagation();

        let files = event.dataTransfer.files;
        let readers = [];

        // Abort if there were no files selected
        if (!files.length) return;

        // Store promises in array  
        for (let i = 0; i < files.length; i++) {
            readers.push(handleMediaFile(files[i]));
        }

        const filesData = await Promise.all(readers);

        let imagesArray = [];
        for (let f = 0; f < filesData.length; f++) {
            let match = filesData[f].match(/^data:([^/]+)\/([^;]+);/) || [];

            let type = match[1];
            console.log("dropped file type");
            console.log(type);
            if (type === 'image') {
                const image = await resizeFile(files[f]);
                imagesArray.push(image);
                if (f > 3) {
                    break;
                }
                setVideoMeta({});
            }
            else if (type === 'video' && currentPlan > 0) {

                const fsize = files[f].size;
                const fsizef = Math.round((fsize / 1024));

                if (fsizef > 250000) {
                    setBigFileMsg(true);
                    return;
                }

                const video = document.createElement('video')
                video.addEventListener('loadedmetadata', event => {
                    setVideoMeta({
                        video_duration: video.duration,
                        video_width: video.videoWidth,
                        video_height: video.videoHeight,
                        video_size: fsizef
                    })
                })
                video.src = URL.createObjectURL(files[f])

                await uploadVideo(files[f]);
                imagesArray = [];
                return;
            }
            else if (type === 'video' && currentPlan === 0) {
                setStateModalOptions({
                    ...stateModalOptions,
                    display: true,
                    loader: false,
                    status: "info",
                    message: t('video_upgrade'),
                    button_text: t('go_to_plans'),
                    button_link: "/subscription"
                });
                return;
            }
        }

        if (imagesArray.length > 0) {
            setImagesRaw(imagesArray)
            displayCropper();
        }

    };

    const [showEmoji, setShowEmoji] = useState(false);
    const displayEmoji = () => {
        setShowEmoji(!showEmoji);
    }

    const onEmojiClick = (emojiData) => {
        // const new_text = singleTweetData.text + emojiObject.emoji;
        const cursorPosition = textareaRef.current.selectionStart;
        const new_text = singleTweetData.text.substring(0, cursorPosition) + emojiData.emoji + singleTweetData.text.substring(cursorPosition);

        handleTweetText(new_text, tweetIndex);
        setCharacterCount(MAX_CHAR - new_text.length);
        styleCounter(new_text.length);
        displayEmoji();
    };

    const [showGifPicker, setShowGifPicker] = useState(false);
    const displayGifPicker = () => {
        if (currentPlan > 0) {
            setShowGifPicker(!showGifPicker);
        }
        else {
            displayUpgradeModal();
        }
    }

    const onGifClick = async (gif) => {
        displayGifPicker();
        await uploadImage(gif.original.mp4, 'video');

    }

    const [showUnsplashPicker, setShowUnsplashPicker] = useState(false);
    const displayUnsplashPicker = () => {
        if (currentPlan > 0) {
            setShowUnsplashPicker(!showUnsplashPicker);
        }
        else {
            displayUpgradeModal();
        }
    }

    const handleFinishedUnsplashUploading = async (imageURL) => {
        const image = await resizeFile(imageURL);
        setImagesRaw([image]);
        displayCropper();
        displayUnsplashPicker();
    }

    useLayoutEffect(() => {
        textareaRef.current.style.height = "inherit";
        textareaRef.current.style.height = `${Math.max(
            textareaRef.current.scrollHeight + 30,
            MIN_TEXTAREA_HEIGHT
        )}px`;

    }, [singleTweetData.text]);

    const isValidUrl = urlString => {
        var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
        return !!urlPattern.test(urlString);
    }

    const handleAttachedLink = (url) => {

        if (url && isValidUrl(url)) {
            axios
                .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/scraper/open_graph", { url }, {
                    headers: headers,
                })
                .then((response) => {
                    setLinkPreview(response.data);
                })
                .catch(() => {
                });
        }
        else {
            setLinkPreview(initialLinkPreview);
        }
    }

    const checkHandle = (tweetIndex) => {
        if (tweetIndex > 0) {
            return 'Replying to <span class="thread_tags">@your_twitter_handle</span>'
        }
        else {
            return '@your_twitter_handle'
        }
    }

    useEffect(() => {
        if (activeArea === tweetIndex) {
            setAreaFocused(true);
            textareaRef.current.focus();
        }
        else {
            setAreaFocused(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeArea])

    useEffect(() => {
        handleAttachedLink(link)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [link])


    return (
        <div className={tweetIndex > 0 ? "tweet-wrapper margin-t2" : "tweet-wrapper"} onClick={() => setActiveArea(tweetIndex)}>
            {tweetIndex > 0 ?
                <div className='thread-left-line'></div>
                : ''}
            <div className="twitter-handle-area">
                <div className='tweet-account-photo'>
                    {accountData.profile_picture_url ?
                        <img src={accountData.profile_picture_url} alt="" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        :
                        <img src={SPKNoProfile} alt="" />
                    }
                </div>
                <div className='tweet-account-data'>
                    <div className='tweet-account-name'>{accountData.social_name ? accountData.social_name : t('select_twitter_account')} {tweetIndex > 0 ? <span className='tweet-account-handle'>@your_twitter_handle</span> : ''}</div>
                    <div className='tweet-account-handle' dangerouslySetInnerHTML={{ __html: checkHandle(tweetIndex) }}></div>
                </div>
            </div>
            {tweetIndex > 0 ?
                <div className={dragActive ? 'tweet-input-area-replies drag' : 'tweet-input-area-replies'} onDrop={(e) => onFileDrop(e)} onDragEnter={(e) => onDragEnter(e)} onDragOver={(e) => onDragOver(e)} onDragLeave={(e) => onDragLeave(e)}>
                    <textarea style={{ minHeight: MIN_TEXTAREA_HEIGHT, resize: "none" }} ref={textareaRef} name="text" placeholder={t('continue_thread_here')} value={singleTweetData.text} onChange={e => { handleInputChange(e); }}></textarea>
                    <p>{publicationData.thread_count ? Number(tweetIndex + 1) + '/' + threadLength : ''}</p>
                </div>
                :
                <div className={dragActive ? 'tweet-input-area drag' : 'tweet-input-area'} onDrop={(e) => onFileDrop(e)} onDragEnter={(e) => onDragEnter(e)} onDragOver={(e) => onDragOver(e)} onDragLeave={(e) => onDragLeave(e)}>
                    <textarea style={{ minHeight: MIN_TEXTAREA_HEIGHT, resize: "none" }} ref={textareaRef} name="text" placeholder={t('type_tweet_text_here')} value={singleTweetData.text} onChange={e => { handleInputChange(e); }}></textarea>
                    <p>{publicationData.thread_count ? Number(tweetIndex + 1) + '/' + threadLength : ''}</p>
                </div>
            }
            {mediaLoader && areaFocused ?
                <div className='tweet-thread-media-container-thread'>
                    <div className="lds-ring-loader">
                        {videoUploadProgress && videoUploadProgress > 0 ?
                            <span className='progress'>{videoUploadProgress}%</span>
                            : ""}
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                : quoteId.length > 0 && singleTweetData.medias.length === 0 ?
                    <div className={tweetIndex === 0 ? "tweet-quote" : "tweet-quote-thread"}>
                        <Tweet tweetId={quoteId} />
                    </div>
                    : link.length > 0 && singleTweetData.medias.length === 0 ?
                        <div className={tweetIndex === 0 ? 'tweet-thread-link-container' : 'tweet-thread-link-container-thread'}>
                            <img src={linkPreview.image} alt="" />
                            <div className="tweet-thread-link-site">{linkPreview.site}</div>
                            <div className="tweet-thread-link-title">{linkPreview.title}</div>
                        </div>

                        : singleTweetData.medias.length > 0 ?
                            <div className={tweetIndex === 0 ? 'tweet-thread-media-container' : 'tweet-thread-media-container-thread'}>
                                {singleTweetData.medias.map((photoData, i) => {
                                    if (photoData.type === "image") {
                                        return (
                                            <div className="tweet-thread-media-wrapper" key={'image_' + i}>
                                                <img key={i} src={photoData.file_url} alt="" />
                                                {areaFocused ?
                                                    <div className='delete_tw_media' onClick={() => deleteMedia(photoData._id)}>
                                                        <svg viewBox="0 0 24 24">
                                                            <path fill="current" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
                                                        </svg>
                                                    </div>
                                                    : ''}
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className="tweet-thread-media-wrapper">
                                                <div className="tweet-thread-media-wrapper" key={'image_' + i}>
                                                    <video>
                                                        <source src={singleTweetData.medias[0].file_url} />
                                                    </video>
                                                    <div className='video_icon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 485 512.03"><path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" /></svg>
                                                    </div>
                                                    {areaFocused ?
                                                        <div className='delete_tw_media' onClick={() => deleteMedia(photoData._id)}>
                                                            <svg viewBox="0 0 24 24">
                                                                <path fill="current" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
                                                            </svg>
                                                        </div>
                                                        : ''}
                                                </div>

                                            </div>
                                        )
                                    }
                                })
                                }
                            </div>

                            : ""
            }
            {areaFocused ?
                <div className='tweet-action-wrapper'>
                    <div className='tweet-buttons-block margin-l4'>
                        <div className="tweet-button">
                            <Tooltip content={t('emojis')} direction="bottom">
                                <svg viewBox="0 0 24 24" onClick={() => displayEmoji()}>
                                    <path fill="current" d="M12,17.5C14.33,17.5 16.3,16.04 17.11,14H6.89C7.69,16.04 9.67,17.5 12,17.5M8.5,11A1.5,1.5 0 0,0 10,9.5A1.5,1.5 0 0,0 8.5,8A1.5,1.5 0 0,0 7,9.5A1.5,1.5 0 0,0 8.5,11M15.5,11A1.5,1.5 0 0,0 17,9.5A1.5,1.5 0 0,0 15.5,8A1.5,1.5 0 0,0 14,9.5A1.5,1.5 0 0,0 15.5,11M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                </svg>
                            </Tooltip>
                            <Tooltip content={t('media_library')} direction="bottom">
                                <svg viewBox="0 0 48 48" className='margin-l1' onClick={() => displayLibrary()}>
                                    <path fill="current" d="M11 36q-1.2 0-2.1-.9Q8 34.2 8 33V7q0-1.2.9-2.1Q9.8 4 11 4h12.25l3 3H43q1.2 0 2.1.9.9.9.9 2.1v23q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h32V10H25l-3-3H11v26Zm29.5 9H5q-1.2 0-2.1-.9Q2 40.2 2 39V10h3v29h35.5ZM16.7 27.45h20.6l-6.6-8.8-5.5 7.3-3.95-4.3ZM11 33V7v26Z" />
                                </svg>
                            </Tooltip>
                            <Tooltip content={t('unsplash_library')} direction="bottom">
                                <svg viewBox="0 0 448 512" className='margin-l1' onClick={() => displayUnsplashPicker()}>
                                    <path fill="current" d="M448,230.17V480H0V230.17H141.13V355.09H306.87V230.17ZM306.87,32H141.13V156.91H306.87Z" />
                                </svg>
                            </Tooltip>
                            <Tooltip content={t('gif_library')} direction="bottom">
                                <svg viewBox="0 0 48 48" className='margin-l1' onClick={() => displayGifPicker()}>
                                    <path fill="current" d="M16.5 28h3.05q.6 0 1.025-.45Q21 27.1 21 26.5v-2h-1.5v2h-3v-5h4.4q0-.6-.4-1.05-.4-.45-1-.45h-3q-.65 0-1.075.425Q15 20.85 15 21.5v5q0 .65.425 1.075Q15.85 28 16.5 28Zm6.75 0h1.5v-8h-1.5ZM27 28h1.5v-3H32v-1.5h-3.5v-2H33V20h-6ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z" />
                                </svg>
                            </Tooltip>
                        </div>
                    </div>

                    <div className='tweet-buttons-block'>
                        <div className='counter_ring'>
                            <svg>
                                <circle id="gray" cx="50%" cy="50%" r="10"></circle>
                                <circle id="colored" cx="50%" cy="50%" r="10" style={ringStyle}></circle>
                            </svg>
                            {characterCount <= 20 ? <div className='char_counter_overlay'>{characterCount}</div> : ""}
                        </div>
                        <div className="tweet-button" onClick={() => addTweet(tweetIndex)}>
                            <Tooltip content={t('add_tweet')} direction="bottom">
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M20.7,7C21.1,6.6 21.1,6 20.7,5.6L18.4,3.3C18,2.9 17.4,2.9 17,3.3L15.2,5.1L19,8.9M3,17.2V21H6.8L17.8,9.9L14.1,6.1L3,17.2M7,2V5H10V7H7V10H5V7H2V5H5V2H7Z" />
                                </svg>
                            </Tooltip>
                        </div>
                        <div className="tweet-button trash" onClick={() => deleteTweet(tweetIndex)}>
                            <Tooltip content={t('delete_tweet')} direction="bottom">
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
                                </svg>
                            </Tooltip>
                        </div>
                    </div>
                </div>

                : ""}
            {showEmoji ?
                <>
                    <div className="transparent_background" onClick={displayEmoji}></div>
                    <div className="unsplash_picker">
                        <span className="close_modal" onClick={displayEmoji}>&times;</span>
                        <Picker onEmojiClick={onEmojiClick} width={350} height={450} />
                    </div>
                </>
                : ""}
            {showUnsplashPicker ?
                <>
                    <div className="transparent_background" onClick={displayUnsplashPicker}></div>
                    <div className="unsplash_picker">
                        <span className="close_modal" onClick={displayUnsplashPicker}>&times;</span>
                        <UnsplashReact
                            accessKey={process.env.REACT_APP_UNSPLASH_ACCESS_KEY}
                            applicationName="SocialPika"
                            columns={2}
                            Uploader={withDefaultProps(BlobUploader, { onBlobLoaded: handleFinishedUnsplashUploading })}
                            preferredSize={{ width: 1200, height: 1080 }}
                        />
                    </div>
                </>
                : ""
            }
            {showGifPicker ?
                <>
                    <div className="transparent_background" onClick={displayGifPicker}></div>
                    <div className="unsplash_picker">
                        <span className="close_modal" onClick={displayGifPicker}>&times;</span>
                        <GifPicker width={300} apiKey={process.env.REACT_APP_GIPHY_API_KEY} onSelected={onGifClick} modal={false} />
                    </div>
                </>
                : ""
            }
        </div >
    )
}

export default ComposerCoThreadTweet