import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";

import Plans from './Plans'

const UpgradeModal = ({ upgrade, closeModal, closeButton }) => {
    const { t } = useTranslation(['plans_CESAM']);

    const [loaded, setLoaded] = useState(false);
    const [showPlans, setShowPlans] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 10);
        return () => clearTimeout(timer);
    }, [])

    return (
        <div>
            {closeButton ?
                <div className={loaded ? "modal_background active" : "modal_background"}></div>
                : ''}
            <div className={loaded ? "plans_modal_wrapper" : "plans_modal_wrapper inactive"}>
                {closeButton ?
                    <span className="close_modal" onClick={closeModal}>&times;</span>
                    : ''}
                {showPlans ?
                    <Plans upgrade={upgrade} />
                    :
                    <div className="column centered margin-t2">
                        {t('feature_not_available_with_plan')}
                        <div className='row margin-t4'>
                            <div className='action_button' onClick={() => setShowPlans(true)}>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="m11 39.6 5.3-2.15q-.65-1.9-1.125-3.85-.475-1.95-.825-3.95L11 31.9Zm8.3-2.6h9.4q1.1-2.95 1.7-5.875Q31 28.2 31 26q0-5.75-1.65-10.1T24 7.65q-3.7 3.9-5.35 8.25Q17 20.25 17 26q0 2.2.6 5.125T19.3 37ZM24 25.5q-1.45 0-2.475-1.025Q20.5 23.45 20.5 22q0-1.45 1.025-2.475Q22.55 18.5 24 18.5q1.45 0 2.475 1.025Q27.5 20.55 27.5 22q0 1.45-1.025 2.475Q25.45 25.5 24 25.5Zm13 14.1v-7.7l-3.35-2.25q-.35 2-.825 3.95-.475 1.95-1.125 3.85ZM24 3.55q5.1 4.5 7.55 9.975T34 26v.25l4.65 3.15q.65.4 1 1.05t.35 1.4V44l-10-4H18L8 44V31.85q0-.75.35-1.4.35-.65 1-1.05L14 26.25V26q0-7 2.45-12.475T24 3.55Z" />
                                </svg>
                                {t('upgrade')}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default UpgradeModal