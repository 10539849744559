import React from 'react'
import { useTranslation } from "react-i18next";

const OnboardingCoPublications = ({ onboardingResponses, handleResponse }) => {
    const { t } = useTranslation(['onboarding_cesam']);
    return (
        <div className="onboarding_content">
            <div className='column_left'>
                <div className='onboarding_question'>{t('onbq_pub')}</div>
                <div className='onboarding_sub_question'>{t('onbq_pub_sub')}</div>
                <div className='onboarding_divider_wrapper'>
                    <div className='onboarding_divider'></div>
                </div>
                <div className='onboarding_response'>
                    <label className="onboarding_radio">
                        <input type="radio" name="publications" value="50" checked={onboardingResponses.publications === '15' ? true : false} onChange={e => handleResponse(e)} />
                        {t('less_50')}
                    </label>
                    <label className="onboarding_radio">
                        <input type="radio" name="publications" value="100" checked={onboardingResponses.publications === '100' ? true : false} onChange={e => handleResponse(e)} />
                        {t('50_200')}
                    </label>
                    <label className="onboarding_radio">
                        <input type="radio" name="publications" value="200" checked={onboardingResponses.publications === '200' ? true : false} onChange={e => handleResponse(e)} />
                        {t('more_200')}
                    </label>
                </div>
            </div>
        </div>
    )
}

export default OnboardingCoPublications