import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";


import Welcome1EN from "../../images/welcome/welcome-en-1.png";
import Welcome2EN from "../../images/welcome/welcome-en-2.png";
import Welcome1FR from "../../images/welcome/welcome-fr-1.png";
import Welcome2FR from "../../images/welcome/welcome-fr-2.png";

const WelcomeModal = ({ closeWelcomeModal }) => {

    const { t, i18n } = useTranslation(['welcome']);


    const welcomeItems = ['socials', 'nav'];
    const [welcomeItem, setWelcomeItem] = useState('socials');
    const [currentIndex, setCurrentIndex] = useState(0);
    const goToNav = (index) => {
        setWelcomeItem(welcomeItems[index]);
        setCurrentIndex(index);
    }

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 10);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            <div className={loaded ? "modal_background active" : "modal_background"}></div>
            <div className={loaded ? "welcome_modal_wrapper" : "welcome_modal_wrapper inactive"}>
                <span className="close_modal" onClick={closeWelcomeModal}>&times;</span>
                <div className="welcome_items_wrapper">
                    <div className="welcome_items">
                        <div className={welcomeItem === 'socials' ? 'welcome_item active' : 'welcome_item'} >
                            <img src={i18n.language === 'fr' ? Welcome1FR : Welcome1EN} alt="" />
                            <div className='welcome_title'>{t('welcome_to_spk')}<br />{t('first_link_acc')}</div>
                            <div className="welcome_desc">{t('first_link_acc_desc')}</div>
                        </div>
                        <div className={welcomeItem === 'nav' ? 'welcome_item active' : 'welcome_item'} >
                            <img src={i18n.language === 'fr' ? Welcome2FR : Welcome2EN} alt="" />
                            <div className='welcome_title'>{t('then_explore')}</div>
                            <div className="welcome_desc">{t('then_explore_desc')}</div>
                        </div>
                        <div className="welcome_nav">
                            {welcomeItems && welcomeItems.length > 0 ?
                                welcomeItems.map((element, index) => {
                                    return (
                                        <div key={index} className={index === currentIndex ? 'welcome_nav_dot active' : 'welcome_nav_dot'} onClick={() => index !== currentIndex ? goToNav(index) : null}></div>
                                    )
                                })
                                : ''
                            }
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default WelcomeModal