import React, { useState, useEffect } from 'react'
import axios from "axios";
import moment from 'moment'
import { useTranslation } from "react-i18next";
import StateModal from '../Loader/StateModal';

import "./plans_mdn.css";


const Invoices = ({ authTokens, headers }) => {

    const { t } = useTranslation(['plans_advanced']);

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    const goToStripePortal = async () => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true,
            message: t('redirecting_to_stripe')
        });

        const payload = {
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/payment/open_customer_portal", payload, {
                headers: headers,
            })
            .then((response) => {
                window.location.href = response.data.stripe_url;
            })
            .catch(error => {
                closeStateModal();
            });
    }

    const [invoicesList, setInvoicesList] = useState([]);


    const openInvoicePDF = async (invoice) => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        const payload = {
            company_id: authTokens.account_id,
            stripe_id: invoice.invoice_stripe_id
        }

        await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/payment/get_invoice_data", payload, {
            headers: headers,
        })
            .then((response) => {
                window.open(response.data.url, '_blank', 'noopener,noreferrer');
            })
            .catch(() => {
            });

        closeStateModal();
    }


    const getInvoicesList = async () => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        const payload = {
            company_id: authTokens.account_id
        }

        await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/payment/get_invoices_list", payload, {
            headers: headers,
        })
            .then((response) => {
                setInvoicesList(response.data);
            })
            .catch(() => {
            });

        closeStateModal();
    }


    useEffect(() => {
        getInvoicesList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            <div>
                {invoicesList && invoicesList.length > 0 ?
                    <>
                        <div className="action_button margin-b1" onClick={goToStripePortal}>
                            <svg viewBox="0 96 960 960">
                                <path fill="current" d="M880 316v520q0 24-18 42t-42 18H140q-24 0-42-18t-18-42V316q0-24 18-42t42-18h680q24 0 42 18t18 42ZM140 425h680V316H140v109Zm0 129v282h680V554H140Zm0 282V316v520Z" />
                            </svg>
                            {t('manage_payment_method')}
                        </div>
                        <p className="text-grey">{t('open_invoice_pdf')}</p>
                        <p className="text-grey text-italic">{t('open_invoice_pdf_loading')}</p>
                    </>
                    :
                    <p className="text-grey">{t('no_invoice_pdf')}</p>
                }
                <div className='row margin-t2'>
                    <ul className='invoice_list'>
                        {invoicesList && invoicesList.length > 0 ?
                            invoicesList.map((invoice, i) => {
                                return (
                                    <li key={i} onClick={() => openInvoicePDF(invoice)}>{invoice.invoice_no} <p className='text-grey'>- {moment(invoice.createdAt).format("DD MMM YYYY")}</p></li>
                                )
                            })
                            : ""
                        }
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default Invoices