import React from 'react'
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Tooltip from '../../sidebar/Tooltip';

import PNicon from "../../../images/social_media/pinterest.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"

const AccountsSelectionPN = ({ PNAccounts, publicationData, setPublicationData, selectedBoards, setSelectedBoards }) => {
    const { t } = useTranslation(['composer']);
    const history = useHistory();
    const goToAccounts = () => {
        history.push({ pathname: '/sm_accounts' });
    }

    const selectBoard = (e, board, account) => {
        let addBoard = false;
        if (e.target.checked) {
            addBoard = true;
        }

        let filteredBoards = selectedBoards.filter(boardData => boardData._id !== board.id);

        if (addBoard) {
            filteredBoards.push({
                _id: board.id,
                account_id: account._id,
                name: board.name
            });
        }

        setSelectedBoards(filteredBoards);

        //ADD Accounts to publicationData
        let allMediaAccountsUnsorted = [];
        filteredBoards.forEach(boardData => {
            PNAccounts.forEach(pn_account => {
                if (pn_account[0]._id === boardData.account_id) {
                    allMediaAccountsUnsorted.push(pn_account[0]);
                }
            })

        });
        console.log(filteredBoards);
        setPublicationData({
            ...publicationData,
            boards: [...filteredBoards],
            social_accounts: [...new Set(allMediaAccountsUnsorted)]
        });
    }


    const checkAccountPhotoWOSelection = (accountData) => {

        if (!accountData) return;

        return (
            <Tooltip content={accountData.social_name} direction="right">
                <div className="account_profile_photo selected">
                    <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                    <img className="account_profile_media" src={PNicon} alt="Media" />
                </div>
            </Tooltip>
        )
    }

    const checkBoardSelected = (board) => {
        let indexFound = selectedBoards.findIndex((boardData) => boardData._id === board.id);

        if (indexFound !== -1) {
            return true;
        }
        else {
            return false;
        }
    }


    return (
        <div>
            <div className='composer_title'>
                {t('boards_selection')}
            </div>
            <div className="row margin-t2">
                {PNAccounts && PNAccounts.length > 0 ?
                    PNAccounts.map((account, i) => {
                        return (
                            <div className="row nowrap margin-t2" key={i}>
                                <div className="account_selection_account">
                                    {checkAccountPhotoWOSelection(account[0])}
                                </div>
                                <div className='row row-100'>
                                    {account[1].items && account[1].items.length > 0 ?
                                        account[1].items.map((board, b) => {
                                            return (
                                                <div className="row" key={b + '_board_' + i}>
                                                    <input type='checkbox' name='board' checked={checkBoardSelected(board)} onChange={(e) => selectBoard(e, board, account[0])} /><p className='p-small'>{board.name}</p>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="action_button red" onClick={() => goToAccounts()}>
                                            <svg viewBox="0 0 24 24">
                                                <path fill="current" d="M10.59,13.41C11,13.8 11,14.44 10.59,14.83C10.2,15.22 9.56,15.22 9.17,14.83C7.22,12.88 7.22,9.71 9.17,7.76V7.76L12.71,4.22C14.66,2.27 17.83,2.27 19.78,4.22C21.73,6.17 21.73,9.34 19.78,11.29L18.29,12.78C18.3,11.96 18.17,11.14 17.89,10.36L18.36,9.88C19.54,8.71 19.54,6.81 18.36,5.64C17.19,4.46 15.29,4.46 14.12,5.64L10.59,9.17C9.41,10.34 9.41,12.24 10.59,13.41M13.41,9.17C13.8,8.78 14.44,8.78 14.83,9.17C16.78,11.12 16.78,14.29 14.83,16.24V16.24L11.29,19.78C9.34,21.73 6.17,21.73 4.22,19.78C2.27,17.83 2.27,14.66 4.22,12.71L5.71,11.22C5.7,12.04 5.83,12.86 6.11,13.65L5.64,14.12C4.46,15.29 4.46,17.19 5.64,18.36C6.81,19.54 8.71,19.54 9.88,18.36L13.41,14.83C14.59,13.66 14.59,11.76 13.41,10.59C13,10.2 13,9.56 13.41,9.17Z" />
                                            </svg>
                                            {t('add_sm_accounts_dashboard')}
                                        </div>
                                    }
                                </div>

                            </div>
                        )
                    })

                    : ''}
            </div>
        </div>
    )
}

export default AccountsSelectionPN