import React, { useState, useEffect } from 'react'
import axios from "axios";
import moment from 'moment'
import { useTranslation } from "react-i18next";

import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"


const EngageCoMsgList = ({
    authTokens,
    selectedAccount,
    selectedMessage,
    setSelectedMessage,
    stateModalOptions,
    setStateModalOptions,
    closeStateModal
}) => {

    const { t, i18n } = useTranslation(['engage']);

    const headers = {
        Authorization: authTokens.token,
    };

    const checkProfilePic = (comment_data, account_data) => {

        if (account_data.media !== 'Facebook') {
            if (comment_data.from_id === account_data.social_id) {
                return <img className="msg_list_profile_picture" src={account_data.profile_picture_url ? account_data.profile_picture_url : SPKNoProfile} alt={account_data.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            }
            else {
                return <img className="msg_list_profile_picture" src={comment_data.profile_url ? comment_data.profile_url : SPKNoProfile} alt={comment_data.from_handle} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            }
        }
        else {
            if (comment_data.from_id === account_data.social_id) {
                const profile_url = `https://graph.facebook.com/v16.0/${comment_data.from_id}/picture`
                return <img className="msg_list_profile_picture" src={profile_url} alt={comment_data.from_handle} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            }
            else {
                const profile_url = `https://graph.facebook.com/v16.0/${comment_data.from_id}/picture?access_token=${account_data.access_token}`
                return <img className="msg_list_profile_picture" src={profile_url} alt={comment_data.from_handle} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            }
        }
    }

    const checkTimeAgo = (date) => {
        let now = moment();
        let comment_date = moment(date);
        let time_diff = now.diff(comment_date, 'minutes');

        if (time_diff < 1) {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${t('just_now')}`
        }
        else if (time_diff < 2) {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${time_diff} ${t('minute')} ${i18n.language === 'en' ? t('ago') : ''}`
        }
        else if (time_diff < 60) {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${time_diff} ${t('minutes')} ${i18n.language === 'en' ? t('ago') : ''}`
        }
        else if (time_diff < 120) {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${Math.floor(time_diff / 60).toFixed(0)} ${t('hour')} ${i18n.language === 'en' ? t('ago') : ''}`
        }
        else if (time_diff < 1440) {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${Math.floor(time_diff / 60).toFixed(0)} ${t('hours')} ${i18n.language === 'en' ? t('ago') : ''}`
        }
        else if (time_diff < 2880) {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${Math.floor(time_diff / 60 / 24).toFixed(0)} ${t('day')} ${i18n.language === 'en' ? t('ago') : ''}`
        }
        else {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${Math.floor(time_diff / 60 / 24).toFixed(0)} ${t('days')} ${i18n.language === 'en' ? t('ago') : ''}`
        }
    }

    const checkStarRating = (starRating) => {

        if (starRating === 'STAR_RATING_UNSPECIFIED') return '';

        let full_star_svg = '<svg class="gmb_star" viewBox="0 0 24 24"><path fill="current" d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" /></svg>'
        let empty_star_svg = '<svg class="gmb_star" viewBox="0 0 24 24"><path fill="current" d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" /></svg>'
        let half_full_star_svg = '<svg class="gmb_star" viewBox="0 0 24 24"><path fill="current" d="M12,15.4V6.1L13.71,10.13L18.09,10.5L14.77,13.39L15.76,17.67M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" /></svg>'

        let full_stars = 0;
        let half_stars = 0;
        let empty_stars = 0;

        switch (starRating) {
            case "FIVE":
                full_stars = 5;
                empty_stars = 5 - full_stars;
                break;

            case "FOUR":
                full_stars = 4;
                empty_stars = 5 - full_stars;
                break;

            case "THREE":
                full_stars = 3;
                empty_stars = 5 - full_stars;
                break;

            case "TWO":
                full_stars = 2;
                empty_stars = 5 - full_stars;
                break;

            case "ONE":
                full_stars = 1;
                empty_stars = 5 - full_stars;
                break;

            case "FOUR_HALF":
                full_stars = 3;
                half_stars = 1;
                empty_stars = 5 - full_stars - half_stars;
                break;
            default:
        }

        let rendering = '';
        for (let i = 0; i < full_stars; i++) {
            rendering += full_star_svg;
        }
        for (let i = 0; i < half_stars; i++) {
            rendering += half_full_star_svg;
        }
        for (let i = 0; i < empty_stars; i++) {
            rendering += empty_star_svg;
        }

        return rendering;
    }

    const [messages, setMessages] = useState([]);
    const getMessages = async () => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });


        const payload = {
            company_id: authTokens.account_id,
            account_data: selectedAccount
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/engagement/get_last_messages", payload, {
                headers: headers,
            })
            .then((response) => {
                setMessages(response.data);
            })
            .catch(() => {
            });

        closeStateModal();
    }

    const handleMessage = async (msgData) => {

        setSelectedMessage(msgData);

        const payload = {
            message_id: msgData.comment_data.id,
            media: msgData.post_data.sm_account.media
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/engagement/handle_message", payload, {
                headers: headers,
            })
            .then(() => {
            })
            .catch(() => {
            });
    }


    useEffect(() => {
        if (Object.keys(selectedAccount).length > 0) {
            getMessages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount])



    return (
        <div className="msg_list_column">
            <div className='title'>{t('msg_com')}</div>
            {messages && messages.length > 0 ?
                messages.map(msgData => {
                    return (
                        <div key={msgData.comment_data.id} className={selectedMessage && selectedMessage.comment_data && selectedMessage.comment_data.id === msgData.comment_data.id ? "msg_list_item selected" : "msg_list_item"} onClick={() => handleMessage(msgData)}>
                            {msgData.post_data.scheduled_post && msgData.post_data.scheduled_post.medias && msgData.post_data.scheduled_post.medias.length > 0 ?
                                <div className='msg_list_post_preview'>
                                    <img src={msgData.post_data.scheduled_post.medias[0].file_url} alt="post preview" />
                                </div>
                                : ''}
                            <div className="msg_list_account_data">
                                {checkProfilePic(msgData.comment_data, msgData.post_data.sm_account)}
                                {/* <img className="msg_list_profile_picture" src={msgData.comment_data.profile_url ? msgData.comment_data.profile_url : SPKNoProfile} alt={msgData.comment_data.from_handle} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} /> */}
                                <div className="msg_list_account_header">
                                    <div className="msg_list_handle">{msgData.comment_data.from_handle}</div>
                                    <div className='msg_list_time'>{checkTimeAgo(msgData.comment_data.date)}</div>
                                </div>
                            </div>
                            {msgData.comment_data.star ?
                                <div className="gmb_rating" dangerouslySetInnerHTML={{ __html: checkStarRating(msgData.comment_data.star) }}></div>
                                : ''}
                            <div className='msg_list_comment_preview'>{msgData.comment_data.comment}</div>
                        </div>
                    )
                })
                :
                <div className="no_msg_list">
                    <svg viewBox="0 0 48 48">
                        <path fill="current" d="M12.4 27.85q-.85 0-1.425-.575-.575-.575-.575-1.425 0-.85.575-1.425.575-.575 1.425-.575.85 0 1.425.575.575.575.575 1.425 0 .85-.575 1.425-.575.575-1.425.575ZM39.7 36l-3-3h3.05V7h-29.1l-3-3h32.1q1.15 0 2.075.925.925.925.925 2.075v26q0 1.15-.875 2.075Q41 36 39.7 36ZM19 15.35l-1.2-1.2v-1.8h17.25v3Zm6 6-3-3h13.05v3ZM41.75 46.6 31.15 36h-20.4l-8 8V7.55L1.55 6.4 3.6 4.2l40.3 40.3Zm-36-36.05v26.2L9.5 33h18.65ZM23.7 20Zm-6.75 1.75Zm-4.55.1q-.85 0-1.425-.575-.575-.575-.575-1.425 0-.85.575-1.425.575-.575 1.425-.575.85 0 1.425.575.575.575.575 1.425 0 .85-.575 1.425-.575.575-1.425.575Z" />
                    </svg>
                    <p className="text-grey">No comments created on posts published the past 30 days.</p>
                </div>
            }
        </div>
    )
}

export default EngageCoMsgList


