import React from 'react'
import moment from 'moment-timezone'
import { useTranslation } from "react-i18next";
import TimezonesSelection from '../../settings/TimezonesSelection';

import PreviewFB from '../../Composer/PostPreviews/PreviewFB';
import PreviewIG from '../../Composer/PostPreviews/PreviewIG';
import PreviewTW from '../../Composer/PostPreviews/PreviewTW';
import PreviewLK from '../../Composer/PostPreviews/PreviewLK';
import PreviewGMB from '../../Composer/PostPreviews/PreviewGMB';
import PostPreviewPN from '../../Composer/PostPreviews/PostPreviewPN';
import PreviewTK from '../../Composer/PostPreviews/PreviewTK';
import PreviewYT from '../../Composer/PostPreviews/PreviewYT';

const CalendarCoModalTz = ({
    closeModal,
    selectedEvent,
    reschedulePublication,
    rescheduleFailedPublication,
    deletePublication,
    editPost,
    newPublicationDate,
    setNewPublicationDate,
    confirmDraft,
    updateDraft,
    deleteDraft
}) => {
    const { t } = useTranslation(['calendar']);

    // const toDatetimeLocal = (isoDate) => {
    //     let date = new Date(isoDate);

    //     const ten = (i) => {
    //         return (i < 10 ? '0' : '') + i;
    //     }

    //     const YYYY = date.getFullYear();
    //     const MM = ten(date.getMonth() + 1);
    //     const DD = ten(date.getDate());
    //     const HH = ten(date.getHours());
    //     const II = ten(date.getMinutes());
    //     const SS = ten(date.getSeconds());

    //     return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II + ':' + SS;
    // }

    // const localizedDateTime = (utcDate, timezone) => {
    //     console.log(utcDate);
    //     const formattedDate = moment(utcDate).tz(timezone).format('YYYY-MM-DD HH:mm');
    //     console.log(formattedDate);
    //     return formattedDate;
    // }

    const handleDateChange = (e) => {
        setNewPublicationDate({
            ...newPublicationDate,
            date: e.target.value
        })
    }

    const handleTimeZone = (tz) => {
        setNewPublicationDate({
            ...newPublicationDate,
            timezone: tz
        })
    }




    return (
        <div className="modal">
            <div className="event_modal_background" onClick={closeModal}></div>
            <div className="event_modal_wrapper">
                <span className="close_modal" onClick={closeModal}>&times;</span>
                <div className="event_modal_row">
                    <div className="modal_block">
                        {selectedEvent.sm_account.media === 'Facebook' ? <PreviewFB publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} linkPreview={selectedEvent.scheduled_post.link_url} />
                            : selectedEvent.sm_account.media === 'Instagram' ? <PreviewIG publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} linkPreview={selectedEvent.scheduled_post.link_url} />
                                : selectedEvent.sm_account.media === 'LinkedIn' ? <PreviewLK publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} linkPreview={selectedEvent.scheduled_post.link_url} />
                                    : selectedEvent.sm_account.media === 'Pinterest' ? <PostPreviewPN publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} linkPreview={selectedEvent.scheduled_post.link_url} />
                                        : selectedEvent.sm_account.media === 'Twitter' ? <PreviewTW publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} linkPreview={selectedEvent.scheduled_post.link_url} />
                                            : selectedEvent.sm_account.media === 'GMB' ? <PreviewGMB publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} />
                                                : selectedEvent.sm_account.media === 'TikTok' ? <PreviewTK publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} />
                                                    : selectedEvent.sm_account.media === 'Youtube' ? <PreviewYT publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} />
                                                        : ''}
                    </div>
                    {selectedEvent.draft ?
                        <div className="modal_block margin-l2">
                            <div className="form_row">
                                <label>{t('confirm_draft_question')}</label>
                                <div className="editor_input_block_dates margin-t1">
                                    <input className='editor' type="datetime-local" name="date" min={new Date().toISOString().slice(0, -8)} value={newPublicationDate.date} onChange={handleDateChange} />
                                </div>
                                <TimezonesSelection
                                    customClassName={'margin-t1'}
                                    timezoneChanged={handleTimeZone}
                                    currentTimeZone={newPublicationDate.timezone}
                                />
                                <div className="row">
                                    <div className="action_button margin-t1" onClick={() => confirmDraft()}>
                                        <svg viewBox="0 0 48 48">
                                            <path fill="current" d="M24.05 40.1q-6.55 0-11.275-4.725Q8.05 30.65 8.05 24.1v-2.25l-4 4L2.1 23.9l7.45-7.45L17 23.9l-1.95 1.95-4-4v2.25q0 5.35 3.825 9.175Q18.7 37.1 24.05 37.1q1.45 0 2.75-.25t2.45-.75l2.15 2.15q-1.8 1-3.625 1.425-1.825.425-3.725.425Zm14.45-8.45-7.45-7.45 2-2L37 26.15V24.1q0-5.35-3.825-9.175Q29.35 11.1 24 11.1q-1.45 0-2.75.275t-2.45.675L16.65 9.9q1.8-1 3.625-1.4Q22.1 8.1 24 8.1q6.55 0 11.275 4.725Q40 17.55 40 24.1v2.15l4-4 1.95 1.95Z" />
                                        </svg>
                                        {t('confirm_draft')}
                                    </div>
                                    <div className="action_button_outline margin-l2 margin-t1" onClick={() => updateDraft()}>
                                        <svg viewBox="0 0 48 48">
                                            <path fill="current" d="m37.55 18.2-7.75-7.75 3.5-3.5q.85-.85 2.1-.85t2.1.85l3.55 3.55q.85.85.85 2.1t-.85 2.1ZM6 42v-7.8l9.7-9.7L4.65 13.4l8.7-8.8L24.5 15.75l5.3-5.3 7.75 7.75-5.3 5.3L43.4 34.65l-8.75 8.65-11.1-11.05L13.8 42Zm11.85-19.65 4.5-4.5-3.65-3.65-2.4 2.4-2.1-2.1 2.4-2.4-3.2-3.2-4.5 4.5Zm16.7 16.75 4.5-4.5-3.2-3.2-2.4 2.4-2.1-2.1 2.4-2.4-3.65-3.65-4.5 4.5ZM9 39h3.5l20.75-20.75-3.5-3.5L9 35.5Z" />
                                        </svg>
                                        {t('update_draft')}
                                    </div>
                                </div>
                                <label className="margin_reg">{t('delete_draft_question')}</label>
                                <div className="row">
                                    <div className="action_button red" onClick={() => deleteDraft()}>
                                        <svg viewBox="0 0 48 48">
                                            <path fill="current" d="M18.05 33.05 24 27l6 6.05 2.35-2.4-5.95-6.05 5.95-6.05-2.35-2.4-6 6.05-5.95-6.05-2.4 2.4 6 6.05-6 6.05Zm-5 8.95q-1.2 0-2.1-.9-.9-.9-.9-2.1V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm21.9-31.5h-21.9V39h21.9Zm-21.9 0V39Z" />
                                        </svg>
                                        {t('delete_draft')}
                                    </div>
                                </div>
                                <label className="margin_reg">{t('edit_draft_question')}</label>
                                <div className="row">
                                    <div className="action_button" onClick={() => editPost(selectedEvent.scheduled_post._id, selectedEvent.scheduled_post.thread, selectedEvent.sm_account.media, selectedEvent.scheduled_post.bulk)}>
                                        <svg viewBox="0 0 48 48">
                                            <path fill="current" d="M9 39h2.2l22.15-22.15-2.2-2.2L9 36.8Zm30.7-24.3-6.4-6.4 2.1-2.1q.85-.85 2.1-.85t2.1.85l2.2 2.2q.85.85.85 2.1t-.85 2.1Zm-2.1 2.1L12.4 42H6v-6.4l25.2-25.2Zm-5.35-1.05-1.1-1.1 2.2 2.2Z" />
                                        </svg>
                                        {t('edit_draft')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : selectedEvent.published === false && moment(selectedEvent.publication_date) > moment() ?
                            <div className="modal_block margin-l2">
                                <div className="form_row">
                                    <label>{t('reschedule_question')}</label>
                                    <div className="editor_input_block_dates margin-t1">
                                        <input className='editor' type="datetime-local" name="date" min={new Date().toISOString().slice(0, -8)} value={newPublicationDate.date} onChange={handleDateChange} />
                                    </div>
                                    <TimezonesSelection
                                        customClassName={'margin-t1'}
                                        timezoneChanged={handleTimeZone}
                                        currentTimeZone={newPublicationDate.timezone}
                                    />
                                    <div className='row'>
                                        <div className="action_button margin-t1" onClick={() => reschedulePublication()}>
                                            <svg viewBox="0 0 48 48">
                                                <path fill="current" d="M34.55 44q-3.9 0-6.675-2.775T25.1 34.55q0-3.9 2.775-6.675T34.55 25.1q3.9 0 6.675 2.775T44 34.55q0 3.9-2.775 6.675T34.55 44Zm2.9-4.4 1.4-1.4-3.75-3.75v-5.6h-1.95v6.3ZM9 41.3q-1.25 0-2.125-.875T6 38.3V9.2q0-1.25.775-2.125T8.1 6.2h10.1q.35-1.75 1.725-2.875T23.1 2.2q1.8 0 3.175 1.125Q27.65 4.45 28 6.2h10.1q1.25 0 2.125.875T41.1 9.2v14.75q-.75-.45-1.475-.775-.725-.325-1.525-.575V9.2h-5.3v6.5H14.3V9.2H9v29.1h13.65q.25.75.6 1.475.35.725.85 1.525ZM24 9q.85 0 1.425-.575Q26 7.85 26 7q0-.85-.575-1.425Q24.85 5 24 5q-.85 0-1.425.575Q22 6.15 22 7q0 .85.575 1.425Q23.15 9 24 9Z" />
                                            </svg>
                                            {t('reschedule_post')}
                                        </div>
                                    </div>
                                    <label className="margin_reg">{t('delete_question')}</label>
                                    <div className="row">
                                        <div className="action_button red" onClick={() => deletePublication()}>
                                            <svg viewBox="0 0 48 48">
                                                <path fill="current" d="M18.05 33.05 24 27l6 6.05 2.35-2.4-5.95-6.05 5.95-6.05-2.35-2.4-6 6.05-5.95-6.05-2.4 2.4 6 6.05-6 6.05Zm-5 8.95q-1.2 0-2.1-.9-.9-.9-.9-2.1V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm21.9-31.5h-21.9V39h21.9Zm-21.9 0V39Z" />
                                            </svg>
                                            {t('delete_post')}
                                        </div>
                                    </div>
                                    <label className="margin_reg">{t('edit_question')}</label>
                                    <div className="row">
                                        <div className="action_button" onClick={() => editPost(selectedEvent.scheduled_post._id, selectedEvent.scheduled_post.thread, selectedEvent.sm_account.media, selectedEvent.scheduled_post.bulk)}>
                                            <svg viewBox="0 0 48 48">
                                                <path fill="current" d="M9 39h2.2l22.15-22.15-2.2-2.2L9 36.8Zm30.7-24.3-6.4-6.4 2.1-2.1q.85-.85 2.1-.85t2.1.85l2.2 2.2q.85.85.85 2.1t-.85 2.1Zm-2.1 2.1L12.4 42H6v-6.4l25.2-25.2Zm-5.35-1.05-1.1-1.1 2.2 2.2Z" />
                                            </svg>
                                            {t('edit_post')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : selectedEvent.published === false && moment().diff(moment(selectedEvent.publication_date), 'minutes') > 3 ?
                                <div className="modal_block">
                                    <div className="form_row">
                                        <label>{t('reschedule_question')}</label>
                                        <div className="editor_input_block_dates margin-t1">
                                            <input className='editor' type="datetime-local" name="date" min={new Date().toISOString().slice(0, -8)} value={newPublicationDate.date} onChange={handleDateChange} />
                                        </div>
                                        <TimezonesSelection
                                            customClassName={'margin-t1'}
                                            timezoneChanged={handleTimeZone}
                                            currentTimeZone={newPublicationDate.timezone}
                                        />
                                        <div className="row">
                                            <div className="action_button margin-t1" onClick={() => rescheduleFailedPublication()}>
                                                <svg viewBox="0 0 48 48">
                                                    <path fill="current" d="M34.55 44q-3.9 0-6.675-2.775T25.1 34.55q0-3.9 2.775-6.675T34.55 25.1q3.9 0 6.675 2.775T44 34.55q0 3.9-2.775 6.675T34.55 44Zm2.9-4.4 1.4-1.4-3.75-3.75v-5.6h-1.95v6.3ZM9 41.3q-1.25 0-2.125-.875T6 38.3V9.2q0-1.25.775-2.125T8.1 6.2h10.1q.35-1.75 1.725-2.875T23.1 2.2q1.8 0 3.175 1.125Q27.65 4.45 28 6.2h10.1q1.25 0 2.125.875T41.1 9.2v14.75q-.75-.45-1.475-.775-.725-.325-1.525-.575V9.2h-5.3v6.5H14.3V9.2H9v29.1h13.65q.25.75.6 1.475.35.725.85 1.525ZM24 9q.85 0 1.425-.575Q26 7.85 26 7q0-.85-.575-1.425Q24.85 5 24 5q-.85 0-1.425.575Q22 6.15 22 7q0 .85.575 1.425Q23.15 9 24 9Z" />
                                                </svg>
                                                {t('reschedule_post')}
                                            </div>
                                        </div>
                                        <label className="margin_reg">
                                            {t('delete_question')}</label>
                                        <div className="row">
                                            <div className="action_button red" onClick={() => deletePublication()}>
                                                <svg viewBox="0 0 48 48">
                                                    <path fill="current" d="M18.05 33.05 24 27l6 6.05 2.35-2.4-5.95-6.05 5.95-6.05-2.35-2.4-6 6.05-5.95-6.05-2.4 2.4 6 6.05-6 6.05Zm-5 8.95q-1.2 0-2.1-.9-.9-.9-.9-2.1V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm21.9-31.5h-21.9V39h21.9Zm-21.9 0V39Z" />
                                                </svg>
                                                {t('delete_post')}
                                            </div>
                                        </div>
                                        <label className="margin_reg">{t('edit_question')}</label>
                                        <div className="row">
                                            <div className="action_button" onClick={() => editPost(selectedEvent.scheduled_post._id, selectedEvent.scheduled_post.thread, selectedEvent.scheduled_post.medias, selectedEvent.scheduled_post.bulk)}>
                                                <svg viewBox="0 0 48 48">
                                                    <path fill="current" d="M9 39h2.2l22.15-22.15-2.2-2.2L9 36.8Zm30.7-24.3-6.4-6.4 2.1-2.1q.85-.85 2.1-.85t2.1.85l2.2 2.2q.85.85.85 2.1t-.85 2.1Zm-2.1 2.1L12.4 42H6v-6.4l25.2-25.2Zm-5.35-1.05-1.1-1.1 2.2 2.2Z" />
                                                </svg>
                                                {t('edit_post')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ""
                    }
                </div>
            </div>
        </div>
    )
}

export default CalendarCoModalTz