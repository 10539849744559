import React from 'react'

import PreviewFB from '../../Composer/PostPreviews/PreviewFB';
import PreviewIG from '../../Composer/PostPreviews/PreviewIG';
import PreviewTW from '../../Composer/PostPreviews/PreviewTW';
import PreviewLK from '../../Composer/PostPreviews/PreviewLK';
import PreviewGMB from '../../Composer/PostPreviews/PreviewGMB';
import PostPreviewPN from '../../Composer/PostPreviews/PostPreviewPN';
import PreviewTK from '../../Composer/PostPreviews/PreviewTK';
import PreviewYT from '../../Composer/PostPreviews/PreviewYT';

const CalendarThirdModal = ({ closeModal, selectedEvent }) => {

    return (
        <div className="modal">
            <div className="event_modal_background" onClick={closeModal}></div>
            <div className="event_modal_wrapper">
                <span className="close_modal" onClick={closeModal}>&times;</span>
                <div className="event_modal_row">
                    <div className="modal_block">
                        {selectedEvent.sm_account.media === 'Facebook' ? <PreviewFB publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} linkPreview={selectedEvent.scheduled_post.link_url} />
                            : selectedEvent.sm_account.media === 'Instagram' ? <PreviewIG publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} linkPreview={selectedEvent.scheduled_post.link_url} />
                                : selectedEvent.sm_account.media === 'LinkedIn' ? <PreviewLK publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} linkPreview={selectedEvent.scheduled_post.link_url} />
                                    : selectedEvent.sm_account.media === 'Pinterest' ? <PostPreviewPN publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} linkPreview={selectedEvent.scheduled_post.link_url} />
                                        : selectedEvent.sm_account.media === 'Twitter' ? <PreviewTW publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} linkPreview={selectedEvent.scheduled_post.link_url} />
                                            : selectedEvent.sm_account.media === 'GMB' ? <PreviewGMB publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} />
                                                : selectedEvent.sm_account.media === 'TikTok' ? <PreviewTK publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} />
                                                    : selectedEvent.sm_account.media === 'Youtube' ? <PreviewYT publicationData={selectedEvent.scheduled_post} calSelectedEvent={selectedEvent.sm_account} selectedPhotos={selectedEvent.scheduled_post.bulk ? [selectedEvent.scheduled_post.medias[selectedEvent.bulk_media_index]] : selectedEvent.scheduled_post.medias} />
                                                        : ''}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalendarThirdModal