import React from 'react'

import "./post_previews.css";
import TWicon from "../../../images/social_media/twitter.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"

const PreviewTW = ({ publicationData, calSelectedEvent, selectedPhotos, linkPreview }) => {


    let tw_check = false;

    let tw_index;

    if (calSelectedEvent) {
        tw_index = publicationData.social_accounts.findIndex((account) => account._id.toString() === calSelectedEvent._id.toString());
    }
    else {
        tw_index = publicationData.social_accounts.findIndex((account) => account.media === "Twitter");
    }
    // const tw_index = publicationData.social_accounts.findIndex((account) => account.media === "Twitter");

    if (tw_index !== -1) {
        tw_check = true;
    }

    let tw_formatted_link = '';
    if (publicationData.link_url) {
        const link_str = publicationData.link_url;
        tw_formatted_link = link_str.replace('https://', '');
        tw_formatted_link = tw_formatted_link.replace('http://', '');
        tw_formatted_link = tw_formatted_link.replace('www.', '');
        tw_formatted_link = " " + tw_formatted_link.substring(0, 20) + "...";
    }

    const hashtagsFormat = (hashtag) => {
        const formattedText = '<span class="tw_tags">' + hashtag + '</span>';
        return formattedText
    }

    const formatText = (text) => {
        const regex = /#[0-9A-Za-zÀ-ÿ '-]*/g;
        return text.replace(regex, hashtagsFormat);
    }

    const source_text = publicationData.alt_text_Twitter || publicationData.text || (publicationData.thread_content && publicationData.thread_content.length > 0 ? publicationData.thread_content[0].text : '');

    // const optimizedMediaURL = (url) => {
    //     if (url.includes("tr:")) {
    //         return url;
    //     }
    //     const url_options = "tr:n-ik_ml_thumbnail";
    //     // const url_options = "tr:h-150,pr-true";
    //     let arrayedURL = url.split("/");
    //     arrayedURL.splice(arrayedURL.length - 1, 0, url_options);
    //     const optimized_url = arrayedURL.join('/');
    //     return optimized_url;
    // }



    return (
        <div>
            {tw_check ?
                <div className={publicationData.draft ? "preview_card preview_card_draft" : "preview_card"}>
                    <div className="sm-badge-wrapper">
                        <img src={TWicon} alt="Twitter" />
                    </div>
                    <div className="header">
                        <img className="co-logo" src={publicationData.social_accounts[tw_index].profile_picture_url} alt="" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <div className="co-name-tw">{publicationData.social_accounts[tw_index].social_name}<span className="tw_icon tw_icon_verified"></span></div>
                        <div className="time">@MyTwitterName</div>
                    </div>
                    <div className="content">
                        <div className="text"><div dangerouslySetInnerHTML={{ __html: formatText(source_text) }}></div></div>
                        <div className="tw_tags">{publicationData.tags}</div>
                        <div className="tw_tags">{tw_formatted_link}</div>

                    </div>

                    {(linkPreview && selectedPhotos.length === 0) ?
                        <div className="link_preview link_preview_tw">
                            <img className="link_thumb" src={linkPreview.image} alt="" />
                            <div className="link_title">{linkPreview.title}</div>
                        </div>
                        : (selectedPhotos.length > 0) ?
                            <div className="tw_media_container">
                                {publicationData.bulk && selectedPhotos.length > publicationData.bulk_media_index ?
                                    selectedPhotos[publicationData.bulk_media_index].type === "image" ?
                                        <div className='tw_media_wrapper'>
                                            <img src={selectedPhotos[publicationData.bulk_media_index].file_url} alt="" />
                                        </div>
                                        :
                                        <div className="tw_media_wrapper">
                                            {selectedPhotos[publicationData.bulk_media_index].thumbnail_url ?
                                                <img key={selectedPhotos[publicationData.bulk_media_index]._id} src={selectedPhotos[publicationData.bulk_media_index].thumbnail_url} alt="" />
                                                :
                                                <video key={selectedPhotos[publicationData.bulk_media_index]._id}>
                                                    <source src={selectedPhotos[publicationData.bulk_media_index].file_url} />
                                                </video>
                                            }
                                            <div className='video_icon'>
                                                <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                                    <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                                </svg>
                                            </div>
                                            {/* <div className="video_overlay">
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                                </svg>
                                            </div> */}
                                        </div>
                                    :
                                    selectedPhotos[0].type === "image" ?
                                        selectedPhotos.slice(0, 4).map((photoData, i) => {
                                            return (
                                                <div className='tw_media_wrapper' key={'image_' + i}>
                                                    <img key={i} src={photoData.file_url} alt="" />
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="tw_media_wrapper">
                                            {selectedPhotos[0].thumbnail_url ?
                                                <img key={selectedPhotos[0]._id} src={selectedPhotos[0].thumbnail_url} alt="" />
                                                :
                                                <video key={selectedPhotos[0]._id}>
                                                    <source src={selectedPhotos[0].file_url} />
                                                </video>
                                            }
                                            <div className='video_icon'>
                                                <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                                    <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                                </svg>
                                            </div>
                                            {/* <div className="video_overlay">
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                                </svg>
                                            </div> */}
                                        </div>
                                }
                            </div>
                            : ""
                    }
                    <div className="tw_footer">
                        <span className="tw_icon tw_icon_heart"></span>
                        <span className="tw_icon tw_icon_share"></span>
                    </div>
                </div>
                : ""
            }
        </div>
    )
}

export default PreviewTW