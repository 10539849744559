import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import TooltipHelp from '../../settings/TooltipHelp';
import TimezonesSelection from '../../settings/TimezonesSelection';


const Publish = ({
    authTokens,
    displayUpgradeModal,
    editorMenuItem,
    publicationData,
    setPublicationData,
    recordPost
}) => {

    const { t } = useTranslation(['composer']);

    const [showTimeZones, setShowTimeZones] = useState(false);

    const displayTimeZones = () => {
        if (authTokens.plan > 0) {
            setShowTimeZones(!showTimeZones)
        }
        else {
            displayUpgradeModal();
        }
    }


    const handleTimeZone = (tzInput) => {
        setPublicationData({
            ...publicationData,
            timezone: tzInput
        });

        // console.log('Date converted');
        // console.log(`date of post:${publicationData.date} - tz:${tzInput.value}`)

        // console.log('UTC time conversion:');
        // let utc_date = publicationData.date;
        // // console.log(`UTC time conversion:${utc_date}`);
        // // utc_date = moment(publicationData.date);
        // console.log(`UTC time conversion:${utc_date}`);
        // utc_date = moment.tz(publicationData.date, tzInput.value);
        // console.log(`UTC time conversion:${utc_date}`);
        // utc_date.utc().format();
        // console.log(`UTC time final:${utc_date}`);

        // const publicationDate = new Date(utc_date).toISOString();
        // console.log(`Server date:${publicationDate}`);
    }

    const handleDateChange = (e) => {
        setPublicationData({
            ...publicationData,
            date: e.target.value
        })

    }

    const toDatetimeLocal = (isoDate) => {
        if (!isoDate) return;
        let date = new Date(isoDate);

        const ten = (i) => {
            return (i < 10 ? '0' : '') + i;
        }

        const YYYY = date.getFullYear();
        const MM = ten(date.getMonth() + 1);
        const DD = ten(date.getDate());
        const HH = ten(date.getHours());
        const II = ten(date.getMinutes());

        return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II;
    }

    const handleInputChange = (e) => {
        setPublicationData({
            ...publicationData,
            [e.target.name]: e.target.value
        })
    }

    const handleCheckbox = (e) => {
        setPublicationData({
            ...publicationData,
            [e.target.name]: e.target.checked
        })
    }

    const [showExpiry, setShowExpiry] = useState(false);
    const handleShowExpiry = (e) => {
        e.target.value !== "0" ? setShowExpiry(true) : setShowExpiry(false);
    }



    return (
        <div className={editorMenuItem === 'publication' ? 'editor_component active' : 'editor_component'}>
            <div className="row margin-t2">
                <div className="action_button" name="draft" onClick={() => recordPost('now')}>
                    <svg viewBox="0 0 48 48">
                        <path d="M9 42C8.2 42 7.5 41.7 6.9 41.1C6.3 40.5 6 39.8 6 39V9C6 8.2 6.3 7.5 6.9 6.9C7.5 6.3 8.2 6 9 6H28.7V9H9V39H39V19.3H42V39C42 39.8 41.7 40.5 41.1 41.1C40.5 41.7 39.8 42 39 42H9ZM16.05 34.15V31.15H32V34.15H16.05ZM16.05 27.8V24.8H32V27.8H16.05ZM16.05 21.45V18.45H32V21.45H16.05Z" fill="current" />
                        <path d="M44.4049 13.7585L41.2936 13.7585L41.2936 7.81885L34.6468 14.4657L32.5255 12.3443L39.1723 5.69753L33.2326 5.69753L33.2326 2.58626H44.4049V13.7585Z" fill="current" />
                    </svg>
                    {t('post_now_3_mn')}
                </div>
            </div>
            <div className='composer_title margin-t2'>{t('or_post_scheduling')}</div>
            <div className="margin-t2">
                <label>{t('date_time_of_publication')}&nbsp;{showTimeZones ? '' : <span className="text-grey text-italic">({t('current_timezone')})</span>}</label>
                <div className='editor_input_block_dates'>
                    <input type="datetime-local" className='editor' name="date" min={new Date().toISOString().slice(0, -8)} value={toDatetimeLocal(publicationData.date)} onChange={handleDateChange} />
                </div>
            </div>
            <div className='row margin-t1'>
                <div className="onoffswitch_draft">
                    <input type="checkbox" className="onoffswitch_draft-checkbox" id="onoffSwitchTZ" checked={showTimeZones} onChange={() => displayTimeZones()} />
                    <label className="onoffswitch_draft-label" htmlFor="onoffSwitchTZ">
                        <span className="onoffswitch_draft-inner"></span>
                        <span className="onoffswitch_draft-switch"></span>
                    </label>
                </div>
                <label className='margin-l1'>{t('select_different_timezone')}</label>
            </div>
            {showTimeZones ?
                <TimezonesSelection
                    customClassName='margin-t1'
                    timezoneChanged={handleTimeZone}
                    currentTimeZone={publicationData.timezone}
                />
                : ''}
            {publicationData.social_accounts.length > 1 || (publicationData.boards && publicationData.boards.length > 1) ?
                <div className="row row_vcenter margin-t2">
                    <div className="onoffswitch_draft">
                        <input type="checkbox" name="spread" className="onoffswitch_draft-checkbox" id="myonoffswitchSpread" checked={publicationData.spread} onChange={(e) => handleCheckbox(e)} />
                        <label className="onoffswitch_draft-label" htmlFor="myonoffswitchSpread">
                            <span className="onoffswitch_draft-inner"></span>
                            <span className="onoffswitch_draft-switch"></span>
                        </label>
                    </div>
                    {!publicationData.spread ?
                        <>
                            <label className='margin-l1'>{t('no_spread')}</label>
                            <TooltipHelp content={t('tooltip_spread')} direction="bottom">
                                <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                    <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                                </svg>
                            </TooltipHelp>
                        </>
                        :
                        <>
                            <label className='margin-l1'>{t('spread')}</label>
                            <select className='editor margin-l1' name="spread_period" onChange={e => handleInputChange(e)}>
                                <option value="5">{t('spread_5_mn')}</option>
                                <option value="30">{t('spread_30_mn')}</option>
                                <option value="60">{t('spread_1_hr')}</option>
                                <option value="180">{t('spread_3_hrs')}</option>
                                <option value="360">{t('spread_6_hrs')}</option>
                                <option value="720">{t('spread_12_hrs')}</option>
                                <option value="1440">{t('spread_1_day')}</option>
                                <option value="2880">{t('spread_2_days')}</option>
                                <option value="4320">{t('spread_3_days')}</option>
                            </select>
                        </>
                    }
                </div>
                : ""
            }
            <div className="margin-t2">
                <label>{t('repeat_publication')}</label>
                <div className='row'>
                    <select className='editor' name="frequency" onChange={e => { handleInputChange(e); handleShowExpiry(e) }}>
                        <option value="0">{t('never')}</option>
                        <option value="2d">{t('every_2_days')}</option>
                        <option value="1">{t('every_week')}</option>
                        <option value="2">{t('every_2_weeks')}</option>
                        <option value="4">{t('every_4_weeks')}</option>
                        <option value="3m">{t('every_3_months')}</option>
                        <option value="6m">{t('every_6_months')}</option>
                        <option value="12m">{t('every_year')}</option>
                    </select>
                    {publicationData.frequency === "2d" ?
                        <TooltipHelp content={t('2d_warning')} direction="right">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
                            </svg>
                        </TooltipHelp>
                        : ""}
                </div>
            </div>
            <div className={showExpiry ? "form_row" : "form_row hidden"}>
                <label>{t('expires_after_x_pub')}</label>
                <div className="row_vcenter">
                    <select className='editor' name="repeat" onChange={handleInputChange}>
                        <option>-</option>
                        <option value="1">1 {t('other_publication')}</option>
                        <option value="2">2 {t('other_publications')}</option>
                        <option value="3">3 {t('other_publications')}</option>
                        <option value="4">4 {t('other_publications')}</option>
                        <option value="5">5 {t('other_publications')}</option>
                        <option value="6">6 {t('other_publications')}</option>
                        <option value="7">7 {t('other_publications')}</option>
                        <option value="8">8 {t('other_publications')}</option>
                        <option value="9">9 {t('other_publications')}</option>
                        <option value="10">10 {t('other_publications')}</option>
                        <option value="20">20 {t('other_publications')}</option>
                        <option value="30">30 {t('other_publications')}</option>
                    </select>

                </div>
            </div>
            <div className="row margin-t2">
                <div className="action_button" name="schedule" onClick={() => recordPost('schedule')}>
                    <svg viewBox="0 0 24 24">
                        <path fill="current" d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
                    </svg>
                    {t('schedule')}
                </div>
                <div className="action_button_outline margin-l1" name="draft" onClick={() => recordPost('draft')}>
                    <svg viewBox="0 0 48 48">
                        <path fill="current" d="m37.55 18.2-7.75-7.75 3.5-3.5q.85-.85 2.1-.85t2.1.85l3.55 3.55q.85.85.85 2.1t-.85 2.1ZM6 42v-7.8l9.7-9.7L4.65 13.4l8.7-8.8L24.5 15.75l5.3-5.3 7.75 7.75-5.3 5.3L43.4 34.65l-8.75 8.65-11.1-11.05L13.8 42Zm11.85-19.65 4.5-4.5-3.65-3.65-2.4 2.4-2.1-2.1 2.4-2.4-3.2-3.2-4.5 4.5Zm16.7 16.75 4.5-4.5-3.2-3.2-2.4 2.4-2.1-2.1 2.4-2.4-3.65-3.65-4.5 4.5ZM9 39h3.5l20.75-20.75-3.5-3.5L9 35.5Z" />
                    </svg>
                    {t('save_post_as_draft')}
                </div>
            </div>
            <p className='text-grey text-italic margin-t2 p-small'>{t('draft_in_calendars')}</p>
        </div>
    )
}

export default Publish