import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import Tooltip from '../sidebar/Tooltip';
import TooltipHelp from '../settings/TooltipHelp';
import TimezonesSelection from '../settings/TimezonesSelection';

import FBicon from "../../images/social_media/facebook.svg";
import TWicon from "../../images/social_media/twitter.svg";
import LKicon from "../../images/social_media/linkedin.svg";
import IGicon from "../../images/social_media/instagram.svg";
// import PNicon from "../../images/social_media/pinterest.svg";
import GMBicon from "../../images/social_media/gmb.svg";
import TKTKicon from "../../images/social_media/tiktok-spk.svg";
import YTicon from "../../images/social_media/youtube.svg";
import SPKNoProfile from "../../images/social_media/SPK-no-profile.png"
import FBGroupIcon from "../../images/social_media/fb_group.png";

const DraftModal = ({ index, modalAbsolutePosition, displayModal, recordPost, editPost, deleteScheduledPost, publicationData, setPublicationData, SMAccounts, SMGroups, selectedGroup, selectGroup, selectMediaAccount, deleteRSS, confirmRSS }) => {
    const { t } = useTranslation(['composer']);


    const handleInputChange = (e) => {
        setPublicationData({
            ...publicationData,
            [e.target.name]: e.target.value
        });
    }

    const handleDateChange = (e) => {
        setPublicationData({
            ...publicationData,
            date: e.target.value
        })
    }

    const handleTimeZone = (tzInput) => {
        setPublicationData({
            ...publicationData,
            timezone: tzInput
        });
    }

    const [showExpiry, setShowExpiry] = useState(false);
    const handleShowExpiry = (e) => {
        e.target.value !== "0" ? setShowExpiry(true) : setShowExpiry(false);
    }

    const toDatetimeLocal = (isoDate) => {
        if (!isoDate) return;
        let date = new Date(isoDate);

        const ten = (i) => {
            return (i < 10 ? '0' : '') + i;
        }

        const YYYY = date.getFullYear();
        const MM = ten(date.getMonth() + 1);
        const DD = ten(date.getDate());
        const HH = ten(date.getHours());
        const II = ten(date.getMinutes());
        // const SS = ten(date.getSeconds());

        return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II;
    }

    // const localizedDateTime = (utcDate, timezone) => {
    //     console.log(utcDate);
    //     const formattedDate = moment(utcDate).tz(timezone).format('YYYY-MM-DD HH:mm');
    //     console.log(formattedDate);
    //     return formattedDate;
    // }

    const checkAccountPhoto = (accountData) => {

        if (!accountData) return;

        const selectedMediaAccount = publicationData.social_accounts;
        let selected = false;
        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === accountData.social_id);

        if (indexFound !== -1) {
            selected = true;
        }

        switch (accountData.media) {
            case "Facebook":
                let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                if(accountData.fb_group){
                    fb_picture_url=FBGroupIcon
                }

                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={fb_picture_url} alt={accountData.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={FBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Twitter":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "LinkedIn":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={LKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Instagram":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={IGicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "GMB":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={GMBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "TikTok":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TKTKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Youtube":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={YTicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
        }
    }


    return (
        <div className="draft_modal" style={modalAbsolutePosition}>
            <span className="close_modal" onClick={() => displayModal(null, index)}>&times;</span>
            {publicationData.feed_url ?
                <div className='row'>
                    {publicationData.draft ?
                        <div className="action_button" onClick={() => confirmRSS(publicationData._id, index)}>
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="M24.05 40.1q-6.55 0-11.275-4.725Q8.05 30.65 8.05 24.1v-2.25l-4 4L2.1 23.9l7.45-7.45L17 23.9l-1.95 1.95-4-4v2.25q0 5.35 3.825 9.175Q18.7 37.1 24.05 37.1q1.45 0 2.75-.25t2.45-.75l2.15 2.15q-1.8 1-3.625 1.425-1.825.425-3.725.425Zm14.45-8.45-7.45-7.45 2-2L37 26.15V24.1q0-5.35-3.825-9.175Q29.35 11.1 24 11.1q-1.45 0-2.75.275t-2.45.675L16.65 9.9q1.8-1 3.625-1.4Q22.1 8.1 24 8.1q6.55 0 11.275 4.725Q40 17.55 40 24.1v2.15l4-4 1.95 1.95Z" />
                            </svg>
                            {t('turn_rss_into_post')}
                        </div>
                        : ''}
                    <div className='action_button margin-l1' onClick={() => editPost(publicationData._id, publicationData.thread, publicationData.social_accounts, publicationData.bulk, true)}>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M9 39h2.2l22.15-22.15-2.2-2.2L9 36.8Zm30.7-24.3-6.4-6.4 2.1-2.1q.85-.85 2.1-.85t2.1.85l2.2 2.2q.85.85.85 2.1t-.85 2.1Zm-2.1 2.1L12.4 42H6v-6.4l25.2-25.2Zm-5.35-1.05-1.1-1.1 2.2 2.2Z" />
                        </svg>
                        {t('edit_rss')}
                    </div>
                    <div className='action_button red margin-l1' onClick={() => deleteRSS(publicationData._id, index)}>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.05 33.05 24 27l6 6.05 2.35-2.4-5.95-6.05 5.95-6.05-2.35-2.4-6 6.05-5.95-6.05-2.4 2.4 6 6.05-6 6.05Zm-5 8.95q-1.2 0-2.1-.9-.9-.9-.9-2.1V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm21.9-31.5h-21.9V39h21.9Zm-21.9 0V39Z" />
                        </svg>
                        {t('delete_rss')}
                    </div>
                </div>
                :
                <>
                    <div className='column_left'>
                        <div className='action_button' onClick={() => editPost(publicationData._id, publicationData.thread, publicationData.social_accounts, publicationData.bulk)}>
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="M9 39h2.2l22.15-22.15-2.2-2.2L9 36.8Zm30.7-24.3-6.4-6.4 2.1-2.1q.85-.85 2.1-.85t2.1.85l2.2 2.2q.85.85.85 2.1t-.85 2.1Zm-2.1 2.1L12.4 42H6v-6.4l25.2-25.2Zm-5.35-1.05-1.1-1.1 2.2 2.2Z" />
                            </svg>
                            {t('edit')}
                        </div>
                        <div className='action_button red margin-t2' onClick={() => deleteScheduledPost(publicationData._id, index)}>
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="M18.05 33.05 24 27l6 6.05 2.35-2.4-5.95-6.05 5.95-6.05-2.35-2.4-6 6.05-5.95-6.05-2.4 2.4 6 6.05-6 6.05Zm-5 8.95q-1.2 0-2.1-.9-.9-.9-.9-2.1V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm21.9-31.5h-21.9V39h21.9Zm-21.9 0V39Z" />
                            </svg>
                            {t('delete')}
                        </div>
                    </div>

                    <div className='v-divider-max margin-s2'></div>
                    <div className='column_left'>
                        <div className="form_row">
                            {SMGroups && SMGroups.length > 0 ?
                                <select className="editor" name="group" onChange={e => selectGroup(e)} value={selectedGroup}>
                                    <option value="">{t('all_accounts')}</option>
                                    {SMGroups.map((group, i) => {
                                        return (
                                            <option key={i} value={group._id}>{group.name}</option>
                                        )
                                    })}
                                </select>
                                :
                                ""
                            }
                        </div>
                        <div className="form_row">
                            <div className="row">
                                {SMAccounts && SMAccounts.length > 0 ?
                                    SMAccounts.map((account, i) => {
                                        return (
                                            <div className="account_selection_account" key={i} onClick={e => selectMediaAccount(account)}>
                                                {checkAccountPhoto(account)}
                                            </div>
                                        )
                                    }
                                    )
                                    :
                                    <div className="action_button red" >
                                        <svg viewBox="0 0 24 24">
                                            <path fill="current" d="M10.59,13.41C11,13.8 11,14.44 10.59,14.83C10.2,15.22 9.56,15.22 9.17,14.83C7.22,12.88 7.22,9.71 9.17,7.76V7.76L12.71,4.22C14.66,2.27 17.83,2.27 19.78,4.22C21.73,6.17 21.73,9.34 19.78,11.29L18.29,12.78C18.3,11.96 18.17,11.14 17.89,10.36L18.36,9.88C19.54,8.71 19.54,6.81 18.36,5.64C17.19,4.46 15.29,4.46 14.12,5.64L10.59,9.17C9.41,10.34 9.41,12.24 10.59,13.41M13.41,9.17C13.8,8.78 14.44,8.78 14.83,9.17C16.78,11.12 16.78,14.29 14.83,16.24V16.24L11.29,19.78C9.34,21.73 6.17,21.73 4.22,19.78C2.27,17.83 2.27,14.66 4.22,12.71L5.71,11.22C5.7,12.04 5.83,12.86 6.11,13.65L5.64,14.12C4.46,15.29 4.46,17.19 5.64,18.36C6.81,19.54 8.71,19.54 9.88,18.36L13.41,14.83C14.59,13.66 14.59,11.76 13.41,10.59C13,10.2 13,9.56 13.41,9.17Z" />
                                        </svg>
                                        {t('add_sm_accounts_dashboard')}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="margin-t2">
                            <label>{t('date_time_of_publication')}</label>
                            <div className="editor_input_block_dates margin-t1">
                                {/* <input className='editor' type="datetime-local" name="date" min={new Date().toISOString().slice(0, -8)} value={localizedDateTime(publicationData.date, publicationData.timezone && publicationData.timezone.value ? publicationData.timezone.value : Intl.DateTimeFormat().resolvedOptions().timeZone)} onChange={handleDateChange} /> */}
                                <input className='editor' type="datetime-local" name="date" min={new Date().toISOString().slice(0, -8)} value={toDatetimeLocal(publicationData.date)} onChange={handleDateChange} />
                            </div>
                            <TimezonesSelection
                                customClassName='margin-t1'
                                timezoneChanged={handleTimeZone}
                                currentTimeZone={publicationData.timezone}
                            />
                        </div>
                        <div className="margin-t2">
                            <label>{t('repeat_publication')}</label>
                            <div className='row'>
                                <select className='editor' name="frequency" onChange={e => { handleInputChange(e); handleShowExpiry(e) }}>
                                    <option value="0">{t('never')}</option>
                                    <option value="2d">{t('every_2_days')}</option>
                                    <option value="1">{t('every_week')}</option>
                                    <option value="2">{t('every_2_weeks')}</option>
                                    <option value="4">{t('every_4_weeks')}</option>
                                    <option value="3m">{t('every_3_months')}</option>
                                    <option value="6m">{t('every_6_months')}</option>
                                    <option value="12m">{t('every_year')}</option>
                                </select>
                                {publicationData.frequency === "2d" ?
                                    <TooltipHelp content={t('2d_warning')} direction="right">
                                        <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                            <path fill="current" d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
                                        </svg>
                                    </TooltipHelp>
                                    : ""}
                            </div>
                        </div>
                        <div className={showExpiry ? "form_row" : "form_row hidden"}>
                            <label>{t('expires_after_x_pub')}</label>
                            <div className="row_vcenter">
                                <select className='editor' name="repeat" onChange={handleInputChange}>
                                    <option>-</option>
                                    <option value="1">1 {t('other_publication')}</option>
                                    <option value="2">2 {t('other_publications')}</option>
                                    <option value="3">3 {t('other_publications')}</option>
                                    <option value="4">4 {t('other_publications')}</option>
                                    <option value="5">5 {t('other_publications')}</option>
                                    <option value="6">6 {t('other_publications')}</option>
                                    <option value="7">7 {t('other_publications')}</option>
                                    <option value="8">8 {t('other_publications')}</option>
                                    <option value="9">9 {t('other_publications')}</option>
                                    <option value="10">10 {t('other_publications')}</option>
                                    <option value="20">20 {t('other_publications')}</option>
                                    <option value="30">30 {t('other_publications')}</option>
                                </select>

                            </div>
                        </div>
                        <div className="row margin-t2">
                            <div className="action_button" name="schedule" onClick={() => recordPost(index, true)}>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M34.55 44q-3.9 0-6.675-2.775T25.1 34.55q0-3.9 2.775-6.675T34.55 25.1q3.9 0 6.675 2.775T44 34.55q0 3.9-2.775 6.675T34.55 44Zm2.9-4.4 1.4-1.4-3.75-3.75v-5.6h-1.95v6.3ZM9 41.3q-1.25 0-2.125-.875T6 38.3V9.2q0-1.25.775-2.125T8.1 6.2h10.1q.35-1.75 1.725-2.875T23.1 2.2q1.8 0 3.175 1.125Q27.65 4.45 28 6.2h10.1q1.25 0 2.125.875T41.1 9.2v14.75q-.75-.45-1.475-.775-.725-.325-1.525-.575V9.2h-5.3v6.5H14.3V9.2H9v29.1h13.65q.25.75.6 1.475.35.725.85 1.525ZM24 9q.85 0 1.425-.575Q26 7.85 26 7q0-.85-.575-1.425Q24.85 5 24 5q-.85 0-1.425.575Q22 6.15 22 7q0 .85.575 1.425Q23.15 9 24 9Z" />
                                </svg>
                                {t('save_n_schedule_post')}
                            </div>
                            <div className="action_button_outline margin-l2" name="schedule" onClick={() => recordPost(index, false)}>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="m37.55 18.2-7.75-7.75 3.5-3.5q.85-.85 2.1-.85t2.1.85l3.55 3.55q.85.85.85 2.1t-.85 2.1ZM6 42v-7.8l9.7-9.7L4.65 13.4l8.7-8.8L24.5 15.75l5.3-5.3 7.75 7.75-5.3 5.3L43.4 34.65l-8.75 8.65-11.1-11.05L13.8 42Zm11.85-19.65 4.5-4.5-3.65-3.65-2.4 2.4-2.1-2.1 2.4-2.4-3.2-3.2-4.5 4.5Zm16.7 16.75 4.5-4.5-3.2-3.2-2.4 2.4-2.1-2.1 2.4-2.4-3.65-3.65-4.5 4.5ZM9 39h3.5l20.75-20.75-3.5-3.5L9 35.5Z" />
                                </svg>
                                {t('update_draft')}
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default DraftModal