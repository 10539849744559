import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useAuth } from '../../../auth';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const CalendarCoShareThird = ({ displayThirdPartyModal }) => {
    const { t } = useTranslation(['calendar']);

    const { authTokens } = useAuth();
    const headers = {
        Authorization: authTokens.token,
    };
    const history = useHistory();

    const [localLoader, setLocalLoader] = useState(false);
    const [allParties, setAllParties] = useState([]);
    const [selectedThirdParty, setSelectedThirdParty] = useState('');
    const [sendingStatus, setSendingStatus] = useState({
        sending: false,
        sent: false,
        error: false
    })

    const [copiedClipboard, setCopiedClipboard] = useState(false);

    const updateSelectedThirdParty = (e) => {
        setSelectedThirdParty(e.target.value);
    }

    const sharePublicationCalendar = async () => {

        setSendingStatus({
            sending: true,
            sent: false,
            error: false
        })

        const payload = {
            company_id: authTokens.account_id,
            third_party_id: selectedThirdParty
        }


        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/third_party/send_calendar_share_email", payload, {
                headers: headers,
            })
            .then(() => {
                setSendingStatus({
                    ...sendingStatus,
                    sending: false,
                    sent: true
                })
            })
            .catch(() => {
                setSendingStatus({
                    ...sendingStatus,
                    sending: false,
                    error: true
                })
            });
    }

    const goToThirdParties = () => {
        history.push({ pathname: '/third_parties' });
    }

    const fetchAllParties = async () => {
        let authError = false;
        setLocalLoader(true);

        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/third_party/get_third_parties", payload, {
                headers: headers,
            })
            .then((response) => {
                setAllParties(response.data);
            })
            .catch((error) => {
            });

        if (authError) return;
        setLocalLoader(false);
    }

    useEffect(() => {
        fetchAllParties();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="modal">
            <div className="event_modal_background" onClick={displayThirdPartyModal}></div>
            <div className="event_modal_wrapper">
                <span className="close_modal" onClick={displayThirdPartyModal}>&times;</span>
                {localLoader ?
                    <div className="lds-ring-loader-small"><div></div><div></div><div></div><div></div></div>
                    : allParties && allParties.length > 0 ?
                        <div>
                            <h2>{t('share_pub_calendar_title')}</h2>
                            <p>{t('share_pub_calendar_sub')}</p>
                            <select onChange={(e) => updateSelectedThirdParty(e)}>
                                <option value=''>{t('select_a_party')}</option>

                                {allParties && allParties.length > 0 ?
                                    allParties.map(party => {
                                        return (
                                            <option value={party._id}>{party.name} ({party.email})</option>
                                        )
                                    })
                                    : ''
                                }
                            </select>
                            {selectedThirdParty.length > 0 ?
                                <>
                                    <div className="action_button margin-t2" onClick={() => sendingStatus.sending ? null : sharePublicationCalendar()}>
                                        {sendingStatus.sending ?
                                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                            :
                                            <>
                                                <svg viewBox="0 0 48 48">
                                                    <path fill="current" d="M11 46q-1.2 0-2.1-.9Q8 44.2 8 43V17.55q0-1.2.9-2.1.9-.9 2.1-.9h8.45v3H11V43h26V17.55h-8.55v-3H37q1.2 0 2.1.9.9.9.9 2.1V43q0 1.2-.9 2.1-.9.9-2.1.9Zm11.45-15.35V7.8l-4.4 4.4-2.15-2.15L23.95 2 32 10.05l-2.15 2.15-4.4-4.4v22.85Z" />
                                                </svg>
                                                {t('share_pub_calendar')}
                                            </>
                                        }

                                    </div>
                                    {sendingStatus.sent ?
                                        <div className='row_hcenter'>
                                            <p className="p-small text-green">{t('third_party_sent')}</p>
                                        </div>
                                        : sendingStatus.error ?
                                            <div className='row_hcenter'>
                                                <p className="p-small text-red">{t('third_party_error')}</p>
                                            </div>
                                            : ''
                                    }
                                    <div className='row_hcenter'>
                                        <a href={`https://app.socialpika.com/3ps/m/${selectedThirdParty}`} target="_blank" rel="noreferrer">
                                            <p className="p-small text-underline">{t('see_what_thirdparty_sees')}</p>
                                        </a>
                                    </div>
                                    {/* <div className='divider-100'></div> */}
                                    <p className='margin-t4'>{t('or_copy_link')}</p>
                                    <div className='row nowrap'>
                                        <input className='long_input_copy' type="text" value={`https://app.socialpika.com/3ps/m/${selectedThirdParty}`} disabled />
                                        <div className="action_button" onClick={() => { navigator.clipboard.writeText(`https://app.socialpika.com/3ps/m/${selectedThirdParty}`); setCopiedClipboard(true); }}>
                                            <svg viewBox="0 0 48 48">
                                                <path fill="current" d="M9 43.95q-1.2 0-2.1-.9-.9-.9-.9-2.1V10.8h3v30.15h23.7v3Zm6-6q-1.2 0-2.1-.9-.9-.9-.9-2.1v-28q0-1.2.9-2.1.9-.9 2.1-.9h22q1.2 0 2.1.9.9.9.9 2.1v28q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h22v-28H15v28Zm0 0v-28 28Z" />
                                            </svg>
                                        </div>
                                    </div>
                                    {copiedClipboard ?
                                        <p className='text-red'>{t('link_copied')}</p>
                                        : ''}
                                </>
                                : ''}
                        </div>
                        :
                        <div>
                            <h2>{t('share_pub_calendar_title')}</h2>
                            <p>{t('add_to_share')}</p>
                            <div className="action_button margin-t4" onClick={() => goToThirdParties()}>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="m12 42-7.65-7.65L12 26.7l2.45 2.45L11 32.6h26l-3.45-3.45L36 26.7l7.65 7.65L36 42l-2.45-2.45L37 36.1H11l3.45 3.45ZM4.95 21.25v-.65q0-1.05.575-1.925T7.1 17.35q1.25-.55 2.575-.825Q11 16.25 12.45 16.25t2.775.275q1.325.275 2.575.825 1 .45 1.575 1.325t.575 1.925v.65Zm23.1 0v-.65q0-1.05.575-1.925T30.2 17.35q1.25-.55 2.575-.825 1.325-.275 2.775-.275t2.775.275q1.325.275 2.575.825 1 .45 1.575 1.325t.575 1.925v.65Zm-15.6-7.75q-1.55 0-2.65-1.1-1.1-1.1-1.1-2.65 0-1.55 1.1-2.65Q10.9 6 12.45 6 14 6 15.1 7.1q1.1 1.1 1.1 2.65 0 1.55-1.1 2.65-1.1 1.1-2.65 1.1Zm23.1 0q-1.55 0-2.65-1.1-1.1-1.1-1.1-2.65 0-1.55 1.1-2.65Q34 6 35.55 6q1.55 0 2.65 1.1 1.1 1.1 1.1 2.65 0 1.55-1.1 2.65-1.1 1.1-2.65 1.1Z" />
                                </svg>
                                {t('add_third_parties')}
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default CalendarCoShareThird