import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import Tooltip from '../../sidebar/Tooltip';

import AccountsSelection from './AccountsSelection';


import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import TKTKicon from "../../../images/social_media/tiktok-spk.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"



const ContentRSS = ({
    editorMenuItem,
    publicationData,
    setPublicationData,
    allSMAccounts,
    SMAccounts,
    setSMAccounts,
    SMGroups,
    grabRSSFeed,
    parserErrorMsg,
    urlLoader
}) => {
    const { t } = useTranslation(['composer']);


    const [showAccountsSelection, setShowAccountsSelection] = useState(false);
    const displayAccountSelection = () => {
        setShowAccountsSelection(!showAccountsSelection);
    }

    const handleInputChange = (e) => {
        setPublicationData({
            ...publicationData,
            [e.target.name]: e.target.value
        })
    }

    const checkAccountPhoto = (accountData) => {

        if (!accountData) return;

        switch (accountData.media) {
            case "Facebook":
                let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                if (accountData.type === "group") {
                    fb_picture_url = "https://graph.facebook.com/v12.0/" + accountData.social_id + "/picture?access_token=" + accountData.access_token;
                }

                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src={fb_picture_url} alt={accountData.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={FBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Twitter":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "LinkedIn":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={LKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Instagram":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={IGicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "GMB":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={GMBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "TikTok":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TKTKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
        }
    }


    return (
        <>
            <div className={editorMenuItem === 'content' ? 'editor_component active' : 'editor_component'}>
                <div className='editor_title margin-t1'>Accounts selection</div>
                <div className='accounts_selection margin-t1'>
                    {publicationData.social_accounts && publicationData.social_accounts.length > 0 ?
                        publicationData.social_accounts.map((account, i) => {
                            return (
                                <div className="account_selection_account" key={i} >
                                    {checkAccountPhoto(account)}
                                </div>
                            )
                        })
                        : ''}

                    <div className='select_account' onClick={displayAccountSelection}>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M22.5 38V25.5H10v-3h12.5V10h3v12.5H38v3H25.5V38Z" />
                        </svg>
                    </div>
                </div>

                <div className='editor_title margin-t2'>{t('rss_feed_address')}</div>
                <div className='editor_input_block margin-t1'>
                    <input type="text" className="editor" name="rss_link" placeholder='https://www.feedurl.com/rss' value={publicationData.rss_link} onChange={(e) => handleInputChange(e)} />
                    {urlLoader ?
                        <div className="lds-ring-loader-mini"><div></div><div></div><div></div><div></div></div>
                        :
                        <div className='mini_round_button' onClick={grabRSSFeed}>
                            <svg viewBox="0 0 24 24">
                                <path fill="current" d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
                            </svg>
                        </div>
                    }
                </div>
                {parserErrorMsg ?
                    <p className='text-red'>{parserErrorMsg}</p>
                    : ""}
            </div>
            {showAccountsSelection ?
                <>
                    <div className="transparent_background" onClick={displayAccountSelection}></div>
                    <div className="unsplash_picker">
                        <span className="close_modal" onClick={displayAccountSelection}>&times;</span>
                        <AccountsSelection allSMAccounts={allSMAccounts} publicationData={publicationData} setPublicationData={setPublicationData} SMGroups={SMGroups} SMAccounts={SMAccounts} setSMAccounts={setSMAccounts} />
                    </div>
                </>
                : ""
            }
        </>
    )
}

export default ContentRSS