import React from 'react'
import { useTranslation } from "react-i18next";

const CAICoTemplates = ({ templateData, templates, selectedTemplatesIds, templatesSelection, isLoadingMoreTemplates }) => {
    const { t } = useTranslation(['content_ai']);


    // const formatBase64 = (img_data) => {
    //     const formatedB64 = img_data.replace(/\s+/g, '');
    //     return formatedB64;
    // }


    return (
        <div className="card_preview">
            <div className='composer_component_thread'>
                <div className="title">
                    {t('templates')}
                    {selectedTemplatesIds.length > 1 ? ` (${selectedTemplatesIds.length} ${t('templates_selected')})` : selectedTemplatesIds.length === 1 ? ` (${selectedTemplatesIds.length} ${t('template_selected')})` : ''}

                </div>
                <div className='composer_component_previews'>
                    {templates.map((img_data) => {
                        return (
                            <div className={templateData.size === 'landscape' ? "template_container_landscape" : "template_container"} key={img_data._id} onClick={() => templatesSelection(img_data)}>
                                {selectedTemplatesIds.includes(img_data._id) ?
                                    <>
                                        <div className="selection_circle_background"></div>
                                        <div className="selection_circle selected">
                                            <svg viewBox="0 0 24 24">
                                                <path fill="current" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                                            </svg>
                                        </div>
                                    </>
                                    :
                                    <div className="selection_circle" ></div>

                                }
                                <img src={img_data.data} alt={img_data.template} />
                                {/* <p>{img_data.template}</p> */}
                            </div>
                        )
                    })
                    }
                    {isLoadingMoreTemplates ?
                        <div className={templateData.size === 'landscape' ? "template_container_landscape" : "template_container"}>
                            <div className="lds-ring-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        : ''}
                </div>
            </div>
        </div>
    )
}

export default CAICoTemplates