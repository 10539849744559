import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useAuth } from "../../auth";
import { useHistory } from "react-router-dom";
import StateModal from '../Loader/StateModal';
// Publications
// Videos
// Analytics
// AI
// 3rd party-team
// Plans

import OnboardingCoPublications from './OnboardingComponents/OnboardingCoPublications'
import OnboardingCoVideos from './OnboardingComponents/OnboardingCoVideos'
import OnboardingCoAnalytics from './OnboardingComponents/OnboardingCoAnalytics'
import OnboardingCoCAI from './OnboardingComponents/OnboardingCoCAI'
import OnboardingCoThirdParty from './OnboardingComponents/OnboardingCoThirdParty'
import OnboardingCoPlans from './OnboardingComponents/OnboardingCoPlans'

import "./onboarding.css"
import "./onboarding_forms.css"



const Onboarding = (props) => {

    const { setAuthTokens } = useAuth();
    const { authTokens } = useAuth();
    const headers = {
        Authorization: authTokens.token,
    };
    const history = useHistory();

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    const onBoardingItems = ['publications', 'videos', 'analytics', 'content_ai', 'third_party', 'plans']
    const [onBoardingItem, setOnBoardingItem] = useState('publications');
    const [upNavVisible, setUpNavVisible] = useState(false);
    const [downNavVisible, setDownNavVisible] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [onboardingResponses, setOnboardingResponses] = useState({
        publications: '',
        videos: '',
        analytics: '',
        content_ai: '',
        third_party: '',
        range: 0,
        plan: 'organic'
    })


    const handleResponse = (e) => {
        let changeRange = false;
        let range = 0;
        let changePlan = false;
        let plan = 'organic';

        const name = e.target.name;
        const value = e.target.value;

        if (name === 'publications' && value === '100') {
            changeRange = true;
            range = 1;
        }
        else if (name === 'publications' && value === '200') {
            changeRange = true;
            range = 2;
        }
        else if (name === 'publications' && value === '15') {
            changeRange = true;
            range = 0;
        }
        else if (name === 'content_ai' && value === 'true') {
            changePlan = true;
            plan = 'growth';
        }
        else if (name === 'content_ai' && value === 'false') {
            changePlan = true;
            plan = 'organic';
        }
        else if (name === 'third_party' && value === 'true') {
            changePlan = true;
            plan = 'growth';
        }


        if (changePlan) {
            setOnboardingResponses({
                ...onboardingResponses,
                plan: plan,
                [e.target.name]: e.target.value
            });
        }
        else if (changeRange) {
            setOnboardingResponses({
                ...onboardingResponses,
                range: range,
                [e.target.name]: e.target.value
            });
        }
        else {
            setOnboardingResponses({
                ...onboardingResponses,
                [e.target.name]: e.target.value
            });
        }




        moveNext();
    }


    const goToNav = (index) => {
        setOnBoardingItem(onBoardingItems[index]);
        updateNavVisibility(index, onBoardingItems.length)
    }

    const updateNavVisibility = (newIndex, length) => {

        if (newIndex === 0) {
            setUpNavVisible(false);
            setDownNavVisible(true);
        }
        else if (newIndex === length - 1) {
            setUpNavVisible(true);
            setDownNavVisible(false);
        }
        else {
            setUpNavVisible(true);
            setDownNavVisible(true);
        }

        setCurrentIndex(newIndex);
    }

    const movePrev = () => {

        if (currentIndex > 0) {
            setOnBoardingItem(onBoardingItems[currentIndex - 1])
        }

        updateNavVisibility(currentIndex - 1, onBoardingItems.length);
    }
    const moveNext = () => {
        if (currentIndex < onBoardingItems.length - 1) {
            setOnBoardingItem(onBoardingItems[currentIndex + 1]);
        }
        updateNavVisibility(currentIndex + 1, onBoardingItems.length);
    }

    const goToFreePlan = () => {
        const payload = {
            email: authTokens.user_email
        }

        axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/free_plan_selected", payload, {
                headers: headers,
            })
            .then(() => {
            })
            .catch(() => {
            });


        history.push({ pathname: '/sm_accounts' });
        setAuthTokens({
            ...authTokens,
            onboarding: true
        });
    }

    useEffect(() => {
        const session_id = props.match.params.id;

        if (session_id) {
            goToNav(5);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className="onboarding_wrapper">
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            <div className="onboarding_nav">
                <svg viewBox="0 0 48 48" className={upNavVisible ? 'svg_nav' : 'svg_nav hidden'} onClick={() => movePrev()}>
                    <path fill="current" d="M14.15 30.75 12 28.6l12-12 12 11.95-2.15 2.15L24 20.85Z" />
                </svg>
                {onBoardingItems && onBoardingItems.length > 0 ?
                    onBoardingItems.map((element, index) => {
                        return (
                            <div key={index} className={index === currentIndex ? 'onboarding_nav_dot active' : 'onboarding_nav_dot'} onClick={() => index !== currentIndex ? goToNav(index) : null}></div>
                        )
                    })
                    : ''
                }
                <svg viewBox="0 0 48 48" className={downNavVisible ? 'svg_nav' : 'svg_nav hidden'} onClick={() => moveNext()}>
                    <path fill="current" d="m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z" />
                </svg>
            </div>
            <div className="onboarding_items_wrapper">
                <div className="onboarding_items">
                    <div className={onBoardingItem === 'publications' ? 'onboarding_item active' : 'onboarding_item'} >
                        <OnboardingCoPublications onboardingResponses={onboardingResponses} handleResponse={handleResponse} />
                    </div>
                    <div className={onBoardingItem === 'videos' ? 'onboarding_item active' : 'onboarding_item'} >
                        <OnboardingCoVideos onboardingResponses={onboardingResponses} handleResponse={handleResponse} />
                    </div>
                    <div className={onBoardingItem === 'analytics' ? 'onboarding_item active' : 'onboarding_item'} >
                        <OnboardingCoAnalytics onboardingResponses={onboardingResponses} handleResponse={handleResponse} />
                    </div>
                    <div className={onBoardingItem === 'content_ai' ? 'onboarding_item active' : 'onboarding_item'} >
                        <OnboardingCoCAI onboardingResponses={onboardingResponses} handleResponse={handleResponse} />
                    </div>
                    <div className={onBoardingItem === 'third_party' ? 'onboarding_item active' : 'onboarding_item'} >
                        <OnboardingCoThirdParty onboardingResponses={onboardingResponses} handleResponse={handleResponse} />
                    </div>
                    <div className={onBoardingItem === 'plans' ? 'onboarding_item active' : 'onboarding_item'} >
                        <OnboardingCoPlans authTokens={authTokens} setAuthTokens={setAuthTokens} stateModalOptions={stateModalOptions} setStateModalOptions={setStateModalOptions} closeStateModal={closeStateModal} onboardingResponses={onboardingResponses} setOnboardingResponses={setOnboardingResponses} goToFreePlan={goToFreePlan} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Onboarding