import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import AWS from "aws-sdk";
import { useTranslation } from "react-i18next";
import GifPicker from "@progresso/react-giphy-picker-https";
import Resizer from "react-image-file-resizer";
import LibraryPickerSelect from "../../library/LibraryPickerSelect";
import Cropper from "../../library/Cropper";
import VideoChecker from "../../library/VideoChecker";
import UnsplashReact, { withDefaultProps, BlobUploader } from "unsplash-react";

import ContentMediaVideoChecker from "./ContentMediaVideoChecker";

import TooltipHelp from "../../settings/TooltipHelp";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_S3_BUCKET_LOCATION;

const IKpublicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY;
const IKauthenticationEndpoint =
  process.env.REACT_APP_SOCIALPIKA_API_URL + "/imagekit/auth";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_BUCKET_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const handleMediaFile = (file) => {
  return new Promise(function (resolve, reject) {
    let fr = new FileReader();

    fr.onload = function () {
      resolve(fr.result);
    };

    fr.onerror = function () {
      reject(fr);
    };

    fr.readAsDataURL(file);
  });
};

const ContentMedia = ({
  authTokens,
  pinterest,
  displayUpgradeModal,
  publicationData,
  setPublicationData,
  stateModalOptions,
  setStateModalOptions,
  mediaTransfer,
  bulk,
  videoCheckerResults,
  setVideoCheckerResults,
}) => {
  const { t } = useTranslation(["composer"]);

  const [mediaLoader, setMediaLoader] = useState(false);
  const [bigFileMsg, setBigFileMsg] = useState(false);

  const inputPhotoOnlyRef = useRef(null);
  const inputFullMediaRef = useRef(null);
  const dragItem = useRef();
  const dragOverItem = useRef();

  const headers = {
    Authorization: authTokens.token,
  };

  const [currentFolder, setCurrentFolder] = useState({
    name: "",
    _id: "",
    parent_folder_id: "",
  });
  const [userIdForLibrary, setUserIdForLibrary] = useState(authTokens.user_id);

  // IMAGE TRANSFORMATION

  const optimizedMediaURL = (url) => {
    if (url.includes("tr:")) {
      return url;
    }
    const url_options = "tr:n-ik_ml_thumbnail";
    // const url_options = "tr:h-150,pr-true";
    let arrayedURL = url.split("/");
    arrayedURL.splice(arrayedURL.length - 1, 0, url_options);
    const optimized_url = arrayedURL.join("/");
    return optimized_url;
  };

  const handleMedia = (new_media) => {
    let currentMedia = publicationData.medias;
    let reel_eligible = false;
    if (pinterest) {
      if (new_media.type === "image") {
        setVideoCheckerResults({
          ...videoCheckerResults,
          is_video: false,
        });
      } else {
        setVideoCheckerResults({
          ...videoCheckerResults,
          is_video: true,
          is_loading: true,
          needs_analysis: true,
        });
      }
      currentMedia = [new_media];
    } else if (!bulk) {
      if (authTokens.plan > 0) {
        if (
          (new_media.type === "image" && currentMedia.length === 0) ||
          (new_media.type === "image" &&
            currentMedia.length > 0 &&
            currentMedia[0].type === "image" &&
            currentMedia.length <= 9)
        ) {
          currentMedia.push(new_media);
          reel_eligible = false;
          setVideoCheckerResults({
            ...videoCheckerResults,
            is_video: false,
          });
        } else if (
          new_media.type === "image" &&
          currentMedia.length > 0 &&
          currentMedia[0].type !== "image"
        ) {
          currentMedia = [new_media];
          reel_eligible = false;
          setVideoCheckerResults({
            ...videoCheckerResults,
            is_video: false,
          });
        } else {
          console.log("video");
          currentMedia = [new_media];
          reel_eligible = true;
          setVideoCheckerResults({
            ...videoCheckerResults,
            is_video: true,
            is_loading: true,
            needs_analysis: true,
          });
        }
      } else {
        if (
          (new_media.type === "image" && currentMedia.length === 0) ||
          (new_media.type === "image" &&
            currentMedia.length > 0 &&
            currentMedia[0].type === "image" &&
            currentMedia.length <= 3)
        ) {
          currentMedia.push(new_media);
          reel_eligible = false;
          setVideoCheckerResults({
            ...videoCheckerResults,
            is_video: false,
          });
        } else {
          currentMedia = [new_media];
          reel_eligible = true;
          setVideoCheckerResults({
            ...videoCheckerResults,
            is_video: true,
            is_loading: true,
            needs_analysis: true,
          });
        }
      }
    } else {
      currentMedia.push(new_media);
    }

    setPublicationData({
      ...publicationData,
      reel_eligible: reel_eligible,
      medias: currentMedia,
    });
  };

  const handleMediaSentFromLibrary = () => {
    let currentMedia = publicationData.medias;
    let reel_eligible = false;

    if (pinterest) {
      currentMedia = [mediaTransfer[0]];
    } else if (!bulk) {
      mediaTransfer.forEach((new_media) => {
        if (authTokens.plan > 0) {
          if (
            (new_media.type === "image" && currentMedia.length === 0) ||
            (new_media.type === "image" &&
              currentMedia.length > 0 &&
              currentMedia[0].type === "image" &&
              currentMedia.length <= 9)
          ) {
            currentMedia.push(new_media);
            reel_eligible = false;
          } else if (
            new_media.type === "image" &&
            currentMedia.length > 0 &&
            currentMedia[0].type !== "image"
          ) {
            currentMedia = [new_media];
            reel_eligible = false;
          } else {
            currentMedia = [new_media];
            reel_eligible = true;
          }
        } else {
          if (
            (new_media.type === "image" && currentMedia.length === 0) ||
            (new_media.type === "image" &&
              currentMedia.length > 0 &&
              currentMedia[0].type === "image" &&
              currentMedia.length <= 3)
          ) {
            currentMedia.push(new_media);
            reel_eligible = false;
          } else {
            currentMedia = [new_media];
            reel_eligible = true;
          }
        }
      });
    } else {
      mediaTransfer.forEach((new_media) => {
        currentMedia.push(new_media);
      });
    }

    setPublicationData({
      ...publicationData,
      reel_eligible: reel_eligible,
      medias: currentMedia,
    });
  };

  // const recordVideoDataInDB = async (video_file_name, url) => {

  //     const media_data_for_db = {
  //         file_name: video_file_name,
  //         file_type: 'video',
  //         file_url: url,
  //         file_id: 'S3-video',
  //         file_size: videoCheckerResults.video_size,
  //         source: 'video',
  //         type: 'video',
  //         used: false,
  //         folder_id: currentFolder._id,
  //         user_id_for_folder: userIdForLibrary,
  //         company_id: authTokens.account_id,
  //         video_duration: videoCheckerResults.video_duration,
  //         video_width: videoCheckerResults.video_width,
  //         video_height: videoCheckerResults.video_height,
  //         video_size: videoCheckerResults.video_size
  //     }
  //     // CREATE NEW MEDIA IN DB
  //     await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/media/save_media_in_folder", media_data_for_db, {
  //         headers: headers,
  //     })
  //         .then((response) => {
  //             media_data_for_db._id = response.data.media_id
  //         })
  //         .catch(() => {
  //         });

  //     let updated_media = [{
  //         ...media_data_for_db
  //     }]

  //     setPublicationData({
  //         ...publicationData,
  //         reel_eligible: true,
  //         medias: updated_media
  //     });
  //     setLibraryNeedsRefresh(true);
  //     setMediaLoader(false);
  // }

  const recordVideoNThumbnailDataInDB = async (
    video_file_name,
    url,
    thumbnail_url,
    thumbnail_timecode
  ) => {
    const media_data_for_db = {
      file_name: video_file_name,
      file_type: "video",
      file_url: url,
      file_id: "S3-video",
      file_size: videoCheckerResults.video_size,
      source: "video",
      type: "video",
      used: false,
      folder_id: currentFolder._id,
      user_id_for_folder: userIdForLibrary,
      company_id: authTokens.account_id,
      video_duration: videoCheckerResults.video_duration,
      video_width: videoCheckerResults.video_width,
      video_height: videoCheckerResults.video_height,
      video_size: videoCheckerResults.video_size,
      thumbnail_url: thumbnail_url,
      thumbnail_timecode: thumbnail_timecode,
    };
    // CREATE NEW MEDIA IN DB
    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/media/save_media_in_folder",
        media_data_for_db,
        {
          headers: headers,
        }
      )
      .then((response) => {
        media_data_for_db._id = response.data.media_id;
      })
      .catch(() => {});

    let updated_media = [
      {
        ...media_data_for_db,
      },
    ];

    setPublicationData({
      ...publicationData,
      reel_eligible: true,
      medias: updated_media,
    });
    setLibraryNeedsRefresh(true);
    setMediaLoader(false);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1350,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const uploadImage = async (image) => {
    let authError = false;
    setMediaLoader(true);

    const payload = {
      company_id: authTokens.account_id,
    };

    const IKauth = await axios
      .post(IKauthenticationEndpoint, payload, {
        headers: headers,
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setMediaLoader(false);
          // props.checkUnauthorizedResponse(error.response.data);
          authError = true;
        }
      });

    if (authError) return;

    // UPLOAD TO IMAGEKIT
    const destination_folder = authTokens.account_id;

    const imagekit_headers = {
      "Content-Type": "multipart/form-data",
    };
    const params = new FormData();
    params.append("file", image);
    params.append("publicKey", IKpublicKey);
    params.append("signature", IKauth.data.signature);
    params.append("expire", IKauth.data.expire);
    params.append("token", IKauth.data.token);
    params.append("fileName", "social_pika");
    params.append("folder", "/" + destination_folder);

    const ik_upload_url = "https://upload.imagekit.io/api/v1/files/upload";

    let media_data_for_db = {};

    await axios
      .post(ik_upload_url, params, {
        headers: imagekit_headers,
      })
      .then((res) => {
        let formatted_photo_url = res.data.url;

        media_data_for_db = {
          file_name: res.data.name,
          file_type: res.data.fileType,
          file_url: res.data.url,
          file_url_watermark: formatted_photo_url,
          file_id: res.data.fileId,
          file_size: res.data.size,
          file_height: res.data.height,
          file_width: res.data.width,
          source: "image",
          type: "image",
          used: false,
          folder_id: currentFolder._id,
          user_id_for_folder: userIdForLibrary,
          company_id: authTokens.account_id,
        };
      })
      .catch(() => {});

    // CREATE NEW MEDIA IN DB
    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/media/save_media_in_folder",
        media_data_for_db,
        {
          headers: headers,
        }
      )
      .then((response) => {
        media_data_for_db._id = response.data.media_id;
      })
      .catch(() => {});

    handleMedia(media_data_for_db);
    setLibraryNeedsRefresh(true);
    setMediaLoader(false);
  };

  const [videoUploadProgress, setVideoUploadProgress] = useState(0);

  const uploadVideoNThumbnail = async (
    video_file,
    videoThumbnail,
    thumbnailTimecode
  ) => {
    let authError = false;
    setMediaLoader(true);

    let thumbnail_data = {
      file_url: "",
      timecode: 0,
    };

    if (videoThumbnail) {
      const payload = {
        company_id: authTokens.account_id,
      };

      const IKauth = await axios
        .post(IKauthenticationEndpoint, payload, {
          headers: headers,
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setMediaLoader(false);
            // props.checkUnauthorizedResponse(error.response.data);
            authError = true;
          }
        });

      if (authError) return;
      // UPLOAD TO IMAGEKIT
      const destination_folder = authTokens.account_id;

      const imagekit_headers = {
        "Content-Type": "multipart/form-data",
      };
      const paramsThumbnail = new FormData();
      paramsThumbnail.append("file", videoThumbnail);
      paramsThumbnail.append("publicKey", IKpublicKey);
      paramsThumbnail.append("signature", IKauth.data.signature);
      paramsThumbnail.append("expire", IKauth.data.expire);
      paramsThumbnail.append("token", IKauth.data.token);
      paramsThumbnail.append("fileName", "social_pika");
      paramsThumbnail.append("folder", "/" + destination_folder);

      const ik_upload_url = "https://upload.imagekit.io/api/v1/files/upload";

      await axios
        .post(ik_upload_url, paramsThumbnail, {
          headers: imagekit_headers,
        })
        .then((res) => {
          thumbnail_data = {
            file_url: res.data.url,
            timecode: thumbnailTimecode,
          };
        })
        .catch(() => {});
    }

    const video_name = video_file.name;
    const video_file_name =
      Math.random().toString(16).slice(2) +
      "_" +
      video_name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
    const destination_path = authTokens.account_id + "/" + video_file_name;
    const destination_url =
      process.env.REACT_APP_S3_BUCKET_URI + destination_path;

    const params = {
      ACL: "public-read",
      Body: video_file,
      Bucket: S3_BUCKET,
      Key: destination_path,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setVideoUploadProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send(async (err, data) => {
        if (err) {
          console.log("Error @ S3 upload");
          console.log(err);
          setMediaLoader(false);
        } else {
          console.log("video uploaded");
          console.log(data);

          await recordVideoNThumbnailDataInDB(
            video_file_name,
            destination_url,
            thumbnail_data.file_url,
            thumbnail_data.timecode
          );
        }
      });
  };

  // const uploadVideo = async (video_file) => {

  //     setMediaLoader(true);

  //     const video_name = video_file.name;
  //     const video_file_name = Math.random().toString(16).slice(2) + '_' + video_name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
  //     const destination_path = authTokens.account_id + '/' + video_file_name;
  //     const destination_url = process.env.REACT_APP_S3_BUCKET_URI + destination_path

  //     const params = {
  //         ACL: 'public-read',
  //         Body: video_file,
  //         Bucket: S3_BUCKET,
  //         Key: destination_path
  //     };

  //     myBucket.putObject(params)
  //         .on('httpUploadProgress', (evt) => {
  //             setVideoUploadProgress(Math.round((evt.loaded / evt.total) * 100))
  //         })
  //         .send(async (err, data) => {
  //             if (err) {
  //                 // console.log("Error @ S3 upload");
  //                 // console.log(err);
  //                 setMediaLoader(false);
  //             }
  //             else {
  //                 // console.log("video uploaded");
  //                 // console.log(data);

  //                 await recordVideoDataInDB(video_file_name, destination_url);
  //             }

  //         })
  // }

  const [showCropper, setShowCropper] = useState(false);
  const displayCropper = () => {
    setShowCropper(!showCropper);
  };
  const [imagesRaw, setImagesRaw] = useState([]);

  const [showVideoChecker, setShowVideoChecker] = useState(false);
  const displayVideoChecker = () => {
    setShowVideoChecker(!showVideoChecker);
  };
  const [videoRaw, setVideoRaw] = useState();

  const [libraryNeedsRefresh, setLibraryNeedsRefresh] = useState(false);

  // BUTTONS

  const displayMediaUpload = () => {
    if (authTokens.plan > 0) {
      inputFullMediaRef.current.click();
    } else {
      inputPhotoOnlyRef.current.click();
    }
  };

  const [showGif, setShowGif] = useState(false);
  const displayGif = () => {
    if (authTokens.plan > 0) {
      setShowGif(!showGif);
    } else {
      displayUpgradeModal();
    }
  };

  const onGifClick = async (gif) => {
    let authError = false;
    setMediaLoader(true);

    const destination_folder = authTokens.account_id;

    const payload = {
      file_url: gif.original.mp4,
      company_id: authTokens.account_id,
      destination_folder: destination_folder,
    };

    let media_data_for_db = {};

    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL + "/imagekit/upload_file",
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        media_data_for_db = {
          file_name: res.data.name,
          file_type: res.data.fileType,
          file_url: res.data.url,
          file_id: res.data.fileId,
          file_size: res.data.size,
          source: "GIF",
          type: "video",
          used: false,
          folder_id: currentFolder._id,
          user_id_for_folder: userIdForLibrary,
          company_id: authTokens.account_id,
        };
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setMediaLoader(false);
          authError = true;
        }
      });
    if (authError) return;

    // CREATE NEW MEDIA IN DB
    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/media/save_media_in_folder",
        media_data_for_db,
        {
          headers: headers,
        }
      )
      .then((response) => {
        media_data_for_db._id = response.data.media_id;
      })
      .catch(() => {});

    let updated_media = [
      {
        ...media_data_for_db,
      },
    ];
    setPublicationData({
      ...publicationData,
      reel_eligible: true,
      medias: updated_media,
    });
    setLibraryNeedsRefresh(true);
    setMediaLoader(false);
  };

  const [showUnsplash, setShowUnsplash] = useState(false);
  const displayUnsplash = () => {
    if (authTokens.plan > 0) {
      setShowUnsplash(!showUnsplash);
    } else {
      displayUpgradeModal();
    }
  };

  const handleFinishedUnsplashUploading = async (imageURL) => {
    const image = await resizeFile(imageURL);
    setImagesRaw([image]);
    displayCropper();
  };

  const [showLibrary, setShowLibrary] = useState(false);
  const displayLibrary = () => {
    setShowLibrary(!showLibrary);
  };

  const deleteImage = async (fileId) => {
    const updated_media = publicationData.medias.filter(
      (mediaData) => mediaData._id !== fileId
    );
    setPublicationData({
      ...publicationData,
      medias: updated_media,
    });
  };

  // DROP FILES

  const [dragActive, setDragActive] = useState(false);

  const onFileDrop = async (event) => {
    setBigFileMsg(false);
    setDragActive(false);
    setMediaLoader(true);
    event.preventDefault();
    event.stopPropagation();

    let files = event.dataTransfer.files;
    let readers = [];

    // Abort if there were no files selected
    if (!files.length) return;

    // Store promises in array
    for (let i = 0; i < files.length; i++) {
      readers.push(handleMediaFile(files[i]));
    }

    const filesData = await Promise.all(readers);

    let imagesArray = [];
    for (let f = 0; f < filesData.length; f++) {
      let match = filesData[f].match(/^data:([^/]+)\/([^;]+);/) || [];

      let type = match[1];
      if (type === "image") {
        const image = await resizeFile(files[f]);
        imagesArray.push(image);
        if (pinterest && f > 0) {
          break;
        } else if (authTokens.plan === 0 && f >= 3) {
          break;
        } else if (f >= 9) {
          break;
        }
        setPublicationData({
          ...publicationData,
          reel_eligible: false,
        });

        setVideoCheckerResults({
          ...videoCheckerResults,
          is_video: false,
        });
      } else if (type === "video" && authTokens.plan > 0) {
        const fsize = files[f].size;
        const fsizef = Math.round(fsize / 1024);

        if (fsizef > 250000) {
          setBigFileMsg(true);
          return;
        }

        setVideoCheckerResults({
          ...videoCheckerResults,
          is_video: true,
          is_loading: true,
          video_size: fsizef,
        });

        // const video = document.createElement('video')
        // video.addEventListener('loadedmetadata', event => {
        //     setVideoCheckerResults({
        //         ...videoCheckerResults,
        //         is_video: true,
        //         is_loading: true,
        //         video_duration: video.duration,
        //         video_width: video.videoWidth,
        //         video_height: video.videoHeight,
        //         video_size: fsizef
        //     })
        // })
        // video.src = URL.createObjectURL(files[f])

        // await uploadVideo(files[f]);
        setVideoRaw(files[f]);
        imagesArray = [];
        displayVideoChecker();
        setMediaLoader(false);
        return;
      } else if (type === "video" && authTokens.plan === 0) {
        displayUpgradeModal();
        setMediaLoader(false);
        return;
      }
    }

    if (imagesArray.length > 0) {
      setImagesRaw(imagesArray);
      displayCropper();
    }

    setMediaLoader(false);
  };

  const onDragOver = (event) => {
    setDragActive(true);
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragLeave = (event) => {
    setDragActive(false);
    event.preventDefault();
    event.stopPropagation();
  };

  // REORDER FILES

  const [dragPhotoActive, setDragPhotoActive] = useState(false);
  const dragStartPhoto = (e, position) => {
    setDragPhotoActive(true);
    dragItem.current = position;
    // console.log(e.target.innerHTML);
  };

  const dragEnterPhoto = (e, position) => {
    dragOverItem.current = position;
    // console.log(e.target.innerHTML);
  };

  const dropPhoto = (e) => {
    const copyListItems = [...publicationData.medias];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setPublicationData({
      ...publicationData,
      medias: copyListItems,
    });
    setDragPhotoActive(false);
  };

  // MEDIA SELECTION

  const mediaSelected = async (event) => {
    setBigFileMsg(false);

    let files = event.currentTarget.files;
    let readers = [];

    // Abort if there were no files selected
    if (!files.length) return;

    // Store promises in array
    for (let i = 0; i < files.length; i++) {
      readers.push(handleMediaFile(files[i]));
    }
    const filesData = await Promise.all(readers);

    let imagesArray = [];
    for (let i = 0; i < filesData.length; i++) {
      let match = filesData[i].match(/^data:([^/]+)\/([^;]+);/) || [];
      let type = match[1];

      if (type === "image") {
        const image = await resizeFile(files[i]);
        imagesArray.push(image);
        if (pinterest && i > 0) {
          break;
        } else if (authTokens.plan === 0 && i >= 3) {
          break;
        } else if (i >= 9) {
          break;
        }

        setPublicationData({
          ...publicationData,
          reel_eligible: false,
        });

        setVideoCheckerResults({
          ...videoCheckerResults,
          is_video: false,
        });
      } else if (type === "video") {
        const fsize = files[i].size;
        const fsizef = Math.round(fsize / 1024);

        if (fsizef > 250000) {
          setBigFileMsg(true);
          return;
        }

        const video = document.createElement("video");
        video.addEventListener("loadedmetadata", (event) => {
          setVideoCheckerResults({
            ...videoCheckerResults,
            is_video: true,
            is_loading: true,
            video_duration: video.duration,
            video_width: video.videoWidth,
            video_height: video.videoHeight,
            video_size: fsizef,
          });
        });
        video.src = URL.createObjectURL(files[i]);

        // await uploadVideo(files[i]);
        // imagesArray = [];
        setVideoRaw(files[i]);
        imagesArray = [];
        displayVideoChecker();
        return;
      }
    }

    if (imagesArray.length > 0) {
      setImagesRaw(imagesArray);
      displayCropper();
    }
  };

  const toggleSelectMedia = (mediaSelected) => {
    let selectedMedia = publicationData.medias;

    // console.log(mediaSelected);

    //Check if media is selected
    let indexFound = selectedMedia.findIndex(
      (media) => media._id === mediaSelected._id
    );

    if (indexFound !== -1) {
      //REMOVE THE MEDIA FROM SELECTED MEDIA
      const updated_media = selectedMedia.filter(
        (mediaData) => mediaData._id !== mediaSelected._id
      );
      setPublicationData({
        ...publicationData,
        medias: updated_media,
      });
    } else {
      // let formatted_photo_url = mediaSelected.file_url;

      // let new_image = {
      //     _id: mediaSelected._id,
      //     file_name: mediaSelected.file_name,
      //     file_type: mediaSelected.file_type,
      //     file_url: mediaSelected.file_url,
      //     file_url_watermark: formatted_photo_url,
      //     file_id: mediaSelected.file_id,
      //     file_size: mediaSelected.file_size,
      //     file_source_url: mediaSelected.file_url,
      //     file_height: mediaSelected.file_height,
      //     file_width: mediaSelected.file_width,
      //     source: mediaSelected.source,
      //     type: mediaSelected.type
      // }

      handleMedia(mediaSelected);
    }
  };

  // USEEFFECT

  const [team, setTeam] = useState([]);

  const fetchTeamData = async () => {
    const payload = {
      company_id: authTokens.account_id,
      user_id: authTokens.user_id,
    };

    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/get_full_team",
        payload,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setTeam(response.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchTeamData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mediaTransfer && mediaTransfer.length > 0) {
      handleMediaSentFromLibrary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaTransfer]);

  return (
    <>
      <input
        type="file"
        multiple
        ref={inputPhotoOnlyRef}
        onChange={mediaSelected}
        className="imagekit_upload"
        accept=".jpg, .jpeg, .png, .gif, .webp, .svg"
      />
      <input
        type="file"
        multiple
        ref={inputFullMediaRef}
        onChange={mediaSelected}
        className="imagekit_upload"
        accept=".jpg, .jpeg, .png, .gif, .webp, .svg, .mov, .mp4, .webm, .ts, .m3u8"
      />
      <div
        className={
          dragActive
            ? "editor_upload_block active margin-t2"
            : "editor_upload_block margin-t2"
        }
        onDrop={(e) => onFileDrop(e)}
        onDragEnter={(e) => onDragEnter(e)}
        onDragOver={(e) => onDragOver(e)}
        onDragLeave={(e) => onDragLeave(e)}
      >
        <div className="editor_title">{t("medias")}</div>
        <div className="upload_icons">
          <div className="editor_upload_icon" onClick={() => displayLibrary()}>
            <TooltipHelp content={t("media_library")} direction="left">
              <svg viewBox="0 0 48 48">
                <path
                  fill="current"
                  d="M11 36q-1.2 0-2.1-.9Q8 34.2 8 33V7q0-1.2.9-2.1Q9.8 4 11 4h12.25l3 3H43q1.2 0 2.1.9.9.9.9 2.1v23q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h32V10H25l-3-3H11v26Zm29.5 9H5q-1.2 0-2.1-.9Q2 40.2 2 39V10h3v29h35.5ZM16.7 27.45h20.6l-6.6-8.8-5.5 7.3-3.95-4.3ZM11 33V7v26Z"
                />
              </svg>
            </TooltipHelp>
          </div>
          <div className="editor_upload_icon" onClick={() => displayUnsplash()}>
            <TooltipHelp content={t("unsplash_library")} direction="left">
              <svg viewBox="0 0 448 512">
                <path
                  fill="current"
                  d="M448,230.17V480H0V230.17H141.13V355.09H306.87V230.17ZM306.87,32H141.13V156.91H306.87Z"
                />
              </svg>
            </TooltipHelp>
          </div>
          <div className="editor_upload_icon" onClick={() => displayGif()}>
            <TooltipHelp content={t("gif_library")} direction="left">
              <svg viewBox="0 0 48 48">
                <path
                  fill="current"
                  d="M16.5 28h3.05q.6 0 1.025-.45Q21 27.1 21 26.5v-2h-1.5v2h-3v-5h4.4q0-.6-.4-1.05-.4-.45-1-.45h-3q-.65 0-1.075.425Q15 20.85 15 21.5v5q0 .65.425 1.075Q15.85 28 16.5 28Zm6.75 0h1.5v-8h-1.5ZM27 28h1.5v-3H32v-1.5h-3.5v-2H33V20h-6ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z"
                />
              </svg>
            </TooltipHelp>
          </div>
          <div
            className="editor_upload_icon"
            onClick={() => displayMediaUpload()}
          >
            <TooltipHelp content={t("select_file_computer")} direction="left">
              <svg viewBox="0 0 48 48">
                <path
                  fill="current"
                  d="M3.5 42q-.65 0-1.075-.425Q2 41.15 2 40.5q0-.65.425-1.075Q2.85 39 3.5 39h41q.65 0 1.075.425Q46 39.85 46 40.5q0 .65-.425 1.075Q45.15 42 44.5 42ZM7 36q-1.2 0-2.1-.9Q4 34.2 4 33V9q0-1.2.9-2.1Q5.8 6 7 6h34q1.2 0 2.1.9.9.9.9 2.1v24q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h34V9H7v24Zm0 0V9v24Z"
                />
              </svg>
            </TooltipHelp>
          </div>
        </div>
        <div className="editor_upload_medias margin-t2">
          {!bulk ? (
            publicationData.medias && publicationData.medias.length > 0 ? (
              publicationData.medias[0].type === "image" ? (
                publicationData.medias.map((media, i) => {
                  return (
                    <div
                      key={i}
                      className="editor_media_unit"
                      style={
                        dragPhotoActive
                          ? { cursor: "grabbing" }
                          : { cursor: "grab" }
                      }
                      onClick={() => deleteImage(media._id)}
                      onDragStart={(e) => dragStartPhoto(e, i)}
                      onDragEnter={(e) => dragEnterPhoto(e, i)}
                      onDragEnd={dropPhoto}
                    >
                      <img src={optimizedMediaURL(media.file_url)} alt="" />
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="current"
                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                        />
                      </svg>
                    </div>
                  );
                })
              ) : (
                <div className="row-100">
                  <div
                    className="editor_media_unit"
                    onClick={() => deleteImage(publicationData.medias[0]._id)}
                  >
                    {publicationData.medias[0].thumbnail_url ? (
                      <img
                        src={optimizedMediaURL(
                          publicationData.medias[0].thumbnail_url
                        )}
                        alt=""
                      />
                    ) : (
                      <video key={publicationData.medias[0]._id}>
                        <source src={publicationData.medias[0].file_url} />
                      </video>
                    )}

                    <svg viewBox="0 0 24 24">
                      <path
                        fill="current"
                        d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                      />
                    </svg>
                  </div>

                  <div className="margin-l1">
                    <ContentMediaVideoChecker
                      publicationData={publicationData}
                      videoCheckerResults={videoCheckerResults}
                      setVideoCheckerResults={setVideoCheckerResults}
                      pinterest={pinterest}
                    />
                  </div>
                </div>
              )
            ) : mediaLoader ? (
              <div className="lds-ring-loader">
                {videoUploadProgress && videoUploadProgress > 0 ? (
                  <span className="progress">{videoUploadProgress}%</span>
                ) : (
                  ""
                )}
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : bigFileMsg ? (
              <p className="text-red">{t("video_file_too_big")}</p>
            ) : (
              <p className="text-light-grey">{t("drop_files_editor")}</p>
            )
          ) : publicationData.medias && publicationData.medias.length > 0 ? (
            publicationData.medias.map((media, i) => {
              return media.type === "image" ? (
                <div
                  key={i}
                  className="editor_media_unit"
                  style={
                    dragPhotoActive
                      ? { cursor: "grabbing" }
                      : { cursor: "grab" }
                  }
                  onClick={() => deleteImage(media._id)}
                  onDragStart={(e) => dragStartPhoto(e, i)}
                  onDragEnter={(e) => dragEnterPhoto(e, i)}
                  onDragEnd={dropPhoto}
                >
                  <img src={optimizedMediaURL(media.file_url)} alt="" />
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="current"
                      d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                    />
                  </svg>
                </div>
              ) : (
                <div
                  key={i}
                  className="editor_media_unit"
                  onClick={() => deleteImage(media._id)}
                >
                  {media.thumbnail_url ? (
                    <img src={optimizedMediaURL(media.thumbnail_url)} alt="" />
                  ) : (
                    <video key={media._id}>
                      <source src={media.file_url} />
                    </video>
                  )}

                  <svg viewBox="0 0 24 24">
                    <path
                      fill="current"
                      d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                    />
                  </svg>
                </div>
              );
            })
          ) : (
            ""
          )}
        </div>
      </div>

      {showCropper ? (
        <Cropper
          displayCropper={displayCropper}
          imagesRaw={imagesRaw}
          uploadImage={uploadImage}
          setMediaLoader={setMediaLoader}
        />
      ) : (
        ""
      )}
      {showVideoChecker ? (
        <VideoChecker
          displayVideoChecker={displayVideoChecker}
          videoFile={videoRaw}
          uploadVideo={uploadVideoNThumbnail}
          setMediaLoader={setMediaLoader}
          publicationData={publicationData}
          videoCheckerResults={videoCheckerResults}
          setVideoCheckerResults={setVideoCheckerResults}
          pinterest={pinterest}
        />
      ) : (
        ""
      )}
      {showLibrary ? (
        <>
          <div
            className="transparent_background"
            onClick={displayLibrary}
          ></div>
          <div className="library_picker">
            <span className="close_modal" onClick={displayLibrary}>
              &times;
            </span>
            <LibraryPickerSelect
              authTokens={authTokens}
              userIdForLibrary={userIdForLibrary}
              setUserIdForLibrary={setUserIdForLibrary}
              team={team}
              currentFolder={currentFolder}
              libraryNeedsRefresh={libraryNeedsRefresh}
              setLibraryNeedsRefresh={setLibraryNeedsRefresh}
              setCurrentFolder={setCurrentFolder}
              toggleSelectMedia={toggleSelectMedia}
              maxHeight="400px"
              displayCropper={displayCropper}
              setImagesRaw={setImagesRaw}
              hideGif={true}
              hideUnsplash={true}
            />
          </div>
        </>
      ) : (
        ""
      )}
      {showUnsplash ? (
        <>
          <div
            className="transparent_background"
            onClick={displayUnsplash}
          ></div>
          <div className="unsplash_picker">
            <span className="close_modal" onClick={displayUnsplash}>
              &times;
            </span>
            <UnsplashReact
              accessKey={process.env.REACT_APP_UNSPLASH_ACCESS_KEY}
              applicationName="SocialPika"
              columns={2}
              Uploader={withDefaultProps(BlobUploader, {
                onBlobLoaded: handleFinishedUnsplashUploading,
              })}
              preferredSize={{ width: 1200, height: 1080 }}
            />
          </div>
        </>
      ) : (
        ""
      )}
      {showGif ? (
        <>
          <div className="transparent_background" onClick={displayGif}></div>
          <div className="unsplash_picker">
            <span className="close_modal" onClick={displayGif}>
              &times;
            </span>
            <GifPicker
              width={300}
              apiKey={process.env.REACT_APP_GIPHY_API_KEY}
              onSelected={onGifClick}
              modal={false}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ContentMedia;
