import React, { useState } from 'react'
import { useTranslation } from "react-i18next";

import UK from "../../images/flags/united-kingdom.png";
import FR from "../../images/flags/france.png";
import DE from "../../images/flags/germany.png";
import SP from "../../images/flags/spain.png";
import JP from "../../images/flags/japan.png";
import CH from "../../images/flags/china.png";
import IT from "../../images/flags/italy.png";
import PT from "../../images/flags/portugal.png";
import RU from "../../images/flags/russia.png";
import SK from "../../images/flags/south-korea.png";
import TK from "../../images/flags/turkey.png";

const LanguagePicker = ({ handleLanguageChange }) => {
    const { t } = useTranslation(['content_ai']);

    const [showFlags, setShowFlags] = useState(false);
    const [selectedFlag, setSelectedFlag] = useState('');

    const checkSelectedFlag = () => {
        switch (selectedFlag) {
            case 'english':
                return `<img src="${UK}" alt='' />`;
            case 'french':
                return `<img src="${FR}" alt='' />`;
            default:
                return `<img src="${UK}" alt='' />`;
        }
    }

    const handleSelectedFlag = (flag) => {
        setSelectedFlag(flag);
        handleLanguageChange(flag);
        setShowFlags(false);
    }


    return (
        <div className='row relative_holder'>
            <label>{t('select_a_language')}:</label>
            <div className='cai_icon_selection margin-l1' onClick={() => setShowFlags(!showFlags)} dangerouslySetInnerHTML={{ __html: checkSelectedFlag(selectedFlag) }}></div>


            {showFlags ?
                <div className="cai_icon_picker">
                    <div className='cai_icon' onClick={() => handleSelectedFlag('english')}>
                        <img src={UK} alt="" />
                    </div>
                    <div className='cai_icon' onClick={() => handleSelectedFlag('french')}>
                        <img src={FR} alt="" />
                    </div>
                    <div className='cai_icon' onClick={() => handleSelectedFlag('spanish')}>
                        <img src={SP} alt="" />
                    </div>
                    <div className='cai_icon' onClick={() => handleSelectedFlag('portuguese')}>
                        <img src={PT} alt="" />
                    </div>
                    <div className='cai_icon' onClick={() => handleSelectedFlag('german')}>
                        <img src={DE} alt="" />
                    </div>
                    <div className='cai_icon' onClick={() => handleSelectedFlag('japanese')}>
                        <img src={JP} alt="" />
                    </div>
                    <div className='cai_icon' onClick={() => handleSelectedFlag('mandarin')}>
                        <img src={CH} alt="" />
                    </div>
                    <div className='cai_icon' onClick={() => handleSelectedFlag('italian')}>
                        <img src={IT} alt="" />
                    </div>
                    <div className='cai_icon' onClick={() => handleSelectedFlag('russian')}>
                        <img src={RU} alt="" />
                    </div>
                    <div className='cai_icon' onClick={() => handleSelectedFlag('korean')}>
                        <img src={SK} alt="" />
                    </div>
                    <div className='cai_icon' onClick={() => handleSelectedFlag('turkish')}>
                        <img src={TK} alt="" />
                    </div>
                </div>
                : ''}
        </div>

    )
}

export default LanguagePicker