import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useAuth } from "../../auth";
import moment from 'moment-timezone'
import StateModal from '../Loader/StateModal';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Tooltip from '../sidebar/Tooltip';


import CalWeeklySimplified from './CalWeeklySimplified';
import CalendarCoModalTz from './CalendarComponents/CalendarCoModalTz';
import CalendarCoShareThird from './CalendarComponents/CalendarCoShareThird';
import CalendarCoAccountsSelection from './CalendarComponents/CalendarCoAccountsSelection';
import CalendarCoTZSelection from './CalendarComponents/CalendarCoTZSelection';
import CalendarCoLegend from './CalendarComponents/CalendarCoLegend';

import UpgradeModal from '../Plans/UpgradeModal';


import FBicon from "../../images/social_media/facebook.svg";
import TWicon from "../../images/social_media/twitter.svg";
import LKicon from "../../images/social_media/linkedin.svg";
import IGicon from "../../images/social_media/instagram.svg";
import PNicon from "../../images/social_media/pinterest.svg";
import GMBicon from "../../images/social_media/gmb.svg";
import TKicon from "../../images/social_media/tiktok-spk.svg";
import YTicon from "../../images/social_media/youtube.svg";
import SPKNoProfile from "../../images/social_media/SPK-no-profile.png"

const CalendarAdvancedWeek = (props) => {

    const { authTokens } = useAuth();
    const headers = {
        Authorization: authTokens.token,
    };
    const { t } = useTranslation(['calendar']);

    const history = useHistory();

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }

    const [showLegend, setShowLegend] = useState(false);

    const [showAccountsSelection, setShowAccountsSelection] = useState(false);
    const displayAccountSelection = () => {
        setShowAccountsSelection(!showAccountsSelection);
    }

    const [currentTimeZone, setCurrentTimeZone] = useState({ label: Intl.DateTimeFormat().resolvedOptions().timeZone, value: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const changeTimeZone = (timezone) => {
        setCurrentTimeZone(timezone);
        moment.tz.setDefault(timezone.value);
        displayTZSelection();
    }

    const [showTZSelection, setShowTZSelection] = useState(false);
    const displayTZSelection = () => {
        if (authTokens.plan > 0) {
            setShowTZSelection(!showTZSelection);
        }
        else {
            displayUpgradeModal();
        }
    }

    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const displayUpgradeModal = () => {
        setShowUpgradeModal(!showUpgradeModal);
    }

    const goToMonthly = () => {
        history.push({
            pathname: '/calendar/monthly',
        });
    }

    const newPost = (day) => {

        const target_date = new Date(moment(day).add(12, 'hours'));

        history.push({
            pathname: '/post/new_post/D:' + target_date,
        });
    }

    const [allSMAccounts, setAllSMAccounts] = useState([]);
    const [selectedSMAccounts, setSelectedSMAccounts] = useState([]);
    const [SMGroups, setSMGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState({});

    const selectGroup = (e) => {
        setSelectedGroup(e.target.value);
        let newlyAvailableSMAccounts = [];

        if (!e.target.value) {
            setFilteredCalendarEvents([...calendarEvents]);
        }
        else if (e.target.value === 'filter_select_accounts') {
            displayAccountSelection();
        }
        else {
            const filteredGroupData = SMGroups.filter(group => group._id === e.target.value);

            filteredGroupData[0].sm_accounts.map(account => {
                let sm_account = allSMAccounts.filter(acc => acc._id === account);
                if (sm_account[0]) {
                    newlyAvailableSMAccounts.push(sm_account[0]);
                }
                return null;
            })

            if (newlyAvailableSMAccounts.length > 0) {
                let calendar_array = [];
                calendarEvents.forEach(event => {
                    let indexFound = newlyAvailableSMAccounts.findIndex((media) => media._id === event.sm_account._id);

                    if (indexFound !== -1) {
                        calendar_array.push(event)
                    }
                })

                setFilteredCalendarEvents(calendar_array)
            }
            else {
                setFilteredCalendarEvents([...calendarEvents]);
            }

        }
        setSelectedSMAccounts(newlyAvailableSMAccounts);
    }

    const selectMediaAccount = (account) => {
        let selectedMediaAccount = selectedSMAccounts;

        let resultingAccountsArray = [];

        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === account.social_id);

        if (indexFound !== -1) {
            const filteredMedia = selectedMediaAccount.filter(item => item.social_id !== account.social_id);
            resultingAccountsArray = [...filteredMedia];
            setSelectedSMAccounts(filteredMedia);
        }
        else {
            selectedMediaAccount.push(account);
            resultingAccountsArray = [...selectedMediaAccount];

            setSelectedSMAccounts([...selectedMediaAccount]);
        }

        if (resultingAccountsArray.length > 0) {
            let calendar_array = [];
            calendarEvents.forEach(event => {
                let indexFound = resultingAccountsArray.findIndex((media) => media._id === event.sm_account._id);

                if (indexFound !== -1) {
                    calendar_array.push(event)
                }
            })

            setFilteredCalendarEvents(calendar_array)
        }
        else {
            setFilteredCalendarEvents([...calendarEvents]);
        }


    }

    const checkAccountPhoto = (accountData) => {

        if (!accountData) return;

        const selectedMediaAccount = selectedSMAccounts;
        let selected = false;
        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === accountData.social_id);

        if (indexFound !== -1) {
            selected = true;
        }

        switch (accountData.media) {
            case "Facebook":
                let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                if (accountData.type === "group") {
                    fb_picture_url = "https://graph.facebook.com/v12.0/" + accountData.social_id + "/picture?access_token=" + accountData.access_token;
                }

                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={fb_picture_url} alt={accountData.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={FBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Twitter":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "LinkedIn":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={LKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Instagram":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={IGicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Pinterest":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={PNicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "GMB":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={GMBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "TikTok":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Youtube":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={YTicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
        }
    }

    const [calendarEvents, setCalendarEvents] = useState([]);
    const [filteredCalendarEvents, setFilteredCalendarEvents] = useState([]);
    const [newPublicationDate, setNewPublicationDate] = useState({
        date: '',
        timezone: { label: Intl.DateTimeFormat().resolvedOptions().timeZone, value: Intl.DateTimeFormat().resolvedOptions().timeZone }
    });

    const [selectedEvent, setSelectedEvent] = useState({});
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
        setNewPublicationDate({});
        setShowModal(false);
    }


    const selectEvent = async (event) => {
        setSelectedEvent(event);
        if (event.draft) {
            setNewPublicationDate({
                date: moment(event.scheduled_post.date).tz(event.scheduled_post.timezone && event.scheduled_post.timezone.value ? event.scheduled_post.timezone.value : Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm'),
                timezone: event.scheduled_post.timezone ? event.scheduled_post.timezone : { label: Intl.DateTimeFormat().resolvedOptions().timeZone, value: Intl.DateTimeFormat().resolvedOptions().timeZone }
            });
        }
        else {
            setNewPublicationDate({
                date: moment(event.publication_date).tz(event.scheduled_post.timezone && event.scheduled_post.timezone.value ? event.scheduled_post.timezone.value : Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm'),
                timezone: event.scheduled_post.timezone ? event.scheduled_post.timezone : { label: Intl.DateTimeFormat().resolvedOptions().timeZone, value: Intl.DateTimeFormat().resolvedOptions().timeZone }
            });
        }
        setShowModal(true);
    }

    const deleteDraft = async () => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        const payload = {
            schedule_id: selectedEvent.scheduled_post._id
        }
        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/delete_post", payload, {
                headers: headers,
            })
            .then(() => {
                const updated_events_array = calendarEvents.filter(event => event._id !== selectedEvent._id);
                setCalendarEvents(updated_events_array);
                setFilteredCalendarEvents(updated_events_array);
            })
            .catch(() => {
            });

        closeModal();
        closeStateModal();
    }

    const confirmDraft = async () => {
        const updated_events_array = calendarEvents;
        let indexFound = updated_events_array.findIndex((event) => event._id === selectedEvent._id);

        if (indexFound !== -1) {
            closeModal();
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: true
            });

            const payload = {
                scheduled_post_id: selectedEvent._id,
                new_publication_date: newPublicationDate,
                company_id: authTokens.account_id
            }
            await axios
                .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/confirm_draft", payload, {
                    headers: headers,
                })
                .then(() => {
                    fetchAllCalendarEvents();
                })
                .catch(() => {
                });
        }

        closeStateModal();
    }

    const updateDraft = async () => {
        const updated_events_array = calendarEvents;
        let indexFound = updated_events_array.findIndex((event) => event._id === selectedEvent._id);

        if (indexFound !== -1) {
            closeModal();
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: true
            });

            const payload = {
                scheduled_post_id: selectedEvent._id,
                new_publication_date: newPublicationDate
            }
            await axios
                .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/update_draft", payload, {
                    headers: headers,
                })
                .then(() => {
                    updated_events_array[indexFound].publication_date = new Date(newPublicationDate);
                    updated_events_array[indexFound].scheduled_post.date = new Date(newPublicationDate);

                    setCalendarEvents(updated_events_array);
                    setFilteredCalendarEvents(updated_events_array);
                    closeStateModal();
                })
                .catch(() => {
                    closeStateModal();
                });
        }

    }

    const deletePublication = async () => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        const payload = {
            calendar_id: selectedEvent.id
        }
        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/unschedule_single_upcoming_publication", payload, {
                headers: headers,
            })
            .then(() => {
                const updated_events_array = calendarEvents.filter(event => event._id !== selectedEvent.id);
                setCalendarEvents(updated_events_array);
                setFilteredCalendarEvents(updated_events_array);

            })
            .catch(() => {
            });

        closeModal();
        closeStateModal();
    }

    const rescheduleFailedPublication = async () => {
        const updated_events_array = calendarEvents;
        let indexFound = updated_events_array.findIndex((event) => event._id === selectedEvent.id);

        if (indexFound !== -1) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: true
            });

            const payload = {
                calendar_id: selectedEvent.id,
                new_publication_date: newPublicationDate
            }
            await axios
                .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/reschedule_failed_publication", payload, {
                    headers: headers,
                })
                .then((response) => {
                    updated_events_array[indexFound].start = new Date(response.data);
                    updated_events_array[indexFound].end = new Date(response.data);
                    updated_events_array[indexFound].publication_date = new Date(response.data);

                    setCalendarEvents(updated_events_array);
                    setFilteredCalendarEvents(updated_events_array);

                })
                .catch(() => {
                });
        }

        closeModal();
        closeStateModal();
    }

    const reschedulePublication = async () => {
        const updated_events_array = calendarEvents;
        let indexFound = updated_events_array.findIndex((event) => event._id === selectedEvent.id);

        if (indexFound !== -1) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: true
            });

            const payload = {
                calendar_id: selectedEvent.id,
                new_publication_date: newPublicationDate
            }
            await axios
                .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/reschedule_publication", payload, {
                    headers: headers,
                })
                .then((response) => {
                    updated_events_array[indexFound].start = new Date(response.data);
                    updated_events_array[indexFound].end = new Date(response.data);
                    updated_events_array[indexFound].publication_date = new Date(response.data);

                    setCalendarEvents(updated_events_array);
                    setFilteredCalendarEvents(updated_events_array);
                })
                .catch(() => {
                });
        }

        closeModal();
        closeStateModal();
    }

    const [currentWeek, setCurrentWeek] = useState(moment());

    const nextWeek = () => {
        setCurrentWeek(moment(currentWeek).add(1, 'weeks'));
    };

    const prevWeek = () => {
        setCurrentWeek(moment(currentWeek).subtract(1, 'weeks'));
    };

    const goToCurrentWeek = () => {
        setCurrentWeek(moment());
    };

    const checkFilteredEvents = (all_events) => {
        if (selectedSMAccounts.length > 0) {
            let calendar_array = [];
            all_events.forEach(event => {
                let indexFound = selectedSMAccounts.findIndex((media) => media.social_id === event.sm_account._id);

                if (indexFound !== -1) {
                    calendar_array.push(event)
                }
            })

            setFilteredCalendarEvents(calendar_array)
        }
        else {
            setFilteredCalendarEvents([...all_events]);
        }
    }

    const fetchAllCalendarEvents = async () => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });
        const payload = {
            user_id: authTokens.user_id,
            company_id: authTokens.account_id,
            date_range: currentWeek.startOf('week')
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/get_weekly_calendar_advanced", payload, {
                headers: headers,
            })
            .then((response) => {
                setCalendarEvents(response.data);
                checkFilteredEvents(response.data);

            })
            .catch(() => {
            });

        closeStateModal();
    }

    const editPost = (post_id, thread, media, bulk) => {
        if (thread) {
            history.push({
                pathname: '/post/thread/' + post_id,
            });
        }
        else if (media === 'Pinterest') {
            history.push({
                pathname: '/post/pins/' + post_id,
            });
        }
        else if (bulk) {
            history.push({
                pathname: '/post/burst_post/' + post_id,
            });
        }
        else {
            history.push({
                pathname: '/post/new_post/' + post_id,
            });
        }
    }

    const [showThirdPartyModal, setShowThirdPartyModal] = useState(false);
    const displayThirdPartyModal = () => {
        if (authTokens.plan > 0 && authTokens.cai) {
            setShowThirdPartyModal(!showThirdPartyModal);
        }
        else {
            displayUpgradeModal();
        }
    }

    const fetchAvailableSocialMedia = async () => {
        let authError = false;
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });


        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_accounts_no_auth_check", payload, {
                headers: headers,
            })
            .then((response) => {
                setAllSMAccounts(response.data.sm_accounts);
                setSMGroups(response.data.sm_groups);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
            });

        if (authError) return;
        closeStateModal();
    }

    useEffect(() => {
        fetchAllCalendarEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWeek]);

    useEffect(() => {
        fetchAvailableSocialMedia();
        props.changeNav("Calendar View", "CalendarView");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="content_row_no_margin">
            {showUpgradeModal ? <UpgradeModal upgrade={true} closeModal={() => setShowUpgradeModal(false)} closeButton={true} /> : ''}
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            {showModal ? <CalendarCoModalTz
                closeModal={closeModal}
                selectedEvent={selectedEvent}
                reschedulePublication={reschedulePublication}
                rescheduleFailedPublication={rescheduleFailedPublication}
                deletePublication={deletePublication}
                editPost={editPost}
                newPublicationDate={newPublicationDate}
                setNewPublicationDate={setNewPublicationDate}
                deleteDraft={deleteDraft}
                confirmDraft={confirmDraft}
                updateDraft={updateDraft}
            /> : ""}
            {showThirdPartyModal ? <CalendarCoShareThird displayThirdPartyModal={displayThirdPartyModal} /> : ""}
            <div className="card flex-1">
                <div className='row row_spaced'>
                    <div className='row'>
                        <select className='editor margin' name="group" onChange={e => selectGroup(e)} value={selectedGroup}>
                            <option value="">{t('filter_all_accounts')}</option>
                            {SMGroups && SMGroups.length > 0 ?
                                SMGroups.map((group, i) => {
                                    return (
                                        <option key={i} value={group._id}>{t('filter')}{group.name}</option>
                                    )
                                })
                                : ""
                            }
                            <option value="filter_select_accounts">{t('filter_select_accounts')}</option>
                        </select>
                        <div className="timezone_wrapper margin-l1" onClick={() => displayTZSelection()}>
                            <svg width="48" height="48" viewBox="0 0 48 48">
                                <path fillRule="evenodd" clipRule="evenodd" d="M24 5C13.5062 5 5 13.5062 5 24C5 34.4938 13.5062 43 24 43C34.4938 43 43 34.493 43 24C43 13.5062 34.4938 5 24 5ZM3 24C3 12.4016 12.4016 3 24 3C35.5984 3 45 12.4016 45 24C45 35.5975 35.5984 45 24 45C12.4016 45 3 35.5984 3 24Z" fill="current" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M17.0953 10.3401C15.1999 13.7854 13.9965 18.6131 13.9965 24C13.9965 29.3869 15.1999 34.2146 17.0954 37.6599C19.0106 41.1412 21.4943 43 24 43C24.5523 43 25 43.4477 25 44C25 44.5523 24.5523 45 24 45C20.4282 45 17.4102 42.3814 15.343 38.624C13.2561 34.8306 11.9965 29.6583 11.9965 24C11.9965 18.3417 13.2561 13.1694 15.343 9.37603C17.4102 5.61859 20.4281 3 24 3C24.5523 3 25 3.44772 25 4C25 4.55228 24.5523 5 24 5C21.4943 5 19.0105 6.8588 17.0953 10.3401Z" fill="current" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M24 3.12708C24.5523 3.12708 25 3.5748 25 4.12708V43.8732C25 44.4255 24.5523 44.8732 24 44.8732C23.4477 44.8732 23 44.4255 23 43.8732V4.12708C23 3.5748 23.4477 3.12708 24 3.12708Z" fill="current" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.12692 24.0009C3.12692 23.4486 3.57463 23.0009 4.12692 23.0009H24C24.5522 23.0009 25 23.4486 25 24.0009C25 24.5532 24.5522 25.0009 24 25.0009H4.12692C3.57463 25.0009 3.12692 24.5532 3.12692 24.0009Z" fill="current" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.00086 13.627C6.00086 13.0747 6.44858 12.627 7.00086 12.627H23.8078C24.3601 12.627 24.8078 13.0747 24.8078 13.627C24.8078 14.1793 24.3601 14.627 23.8078 14.627H7.00086C6.44858 14.627 6.00086 14.1793 6.00086 13.627Z" fill="current" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.00086 34.3748C6.00086 33.8225 6.44858 33.3748 7.00086 33.3748H23.8078C24.3601 33.3748 24.8078 33.8225 24.8078 34.3748C24.8078 34.9271 24.3601 35.3748 23.8078 35.3748H7.00086C6.44858 35.3748 6.00086 34.9271 6.00086 34.3748Z" fill="current" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M37.512 24C37.512 23.4477 37.9597 23 38.512 23H41.4147C41.967 23 42.4147 23.4477 42.4147 24C42.4147 24.5523 41.967 25 41.4147 25H38.512C37.9597 25 37.512 24.5523 37.512 24Z" fill="current" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M39.1911 13.5905C39.5032 14.0462 39.3869 14.6685 38.9312 14.9807L36.5371 16.6207C36.0815 16.9328 35.4591 16.8164 35.147 16.3608C34.8349 15.9052 34.9512 15.2828 35.4069 14.9707L37.8009 13.3307C38.2566 13.0185 38.879 13.1349 39.1911 13.5905Z" fill="current" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M32.2009 7.48567C32.6949 7.73254 32.8953 8.33316 32.6484 8.8272L31.3502 11.4254C31.1033 11.9194 30.5027 12.1198 30.0086 11.8729C29.5146 11.6261 29.3142 11.0254 29.5611 10.5314L30.8594 7.93323C31.1062 7.43918 31.7068 7.23881 32.2009 7.48567Z" fill="current" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M30.8016 35.6226C31.2781 35.3434 31.8907 35.5034 32.1699 35.9799L33.6376 38.4849C33.9168 38.9615 33.7568 39.5741 33.2803 39.8533C32.8037 40.1325 32.1911 39.9725 31.9119 39.496L30.4443 36.9909C30.1651 36.5144 30.3251 35.9018 30.8016 35.6226Z" fill="current" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M35.6417 30.8146C35.9228 30.3392 36.536 30.1817 37.0114 30.4628L39.5115 31.941C39.9869 32.2221 40.1445 32.8353 39.8634 33.3107C39.5823 33.7861 38.969 33.9437 38.4936 33.6626L35.9935 32.1844C35.5181 31.9033 35.3606 31.2901 35.6417 30.8146Z" fill="current" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M34.7072 9.70111C35.159 10.0187 35.2678 10.6425 34.9503 11.0943L26.3803 23.2863L36.1438 24.9872C36.6879 25.082 37.0521 25.5999 36.9573 26.144C36.8625 26.6881 36.3446 27.0523 35.8005 26.9575L22.931 24.7156L33.314 9.94416C33.6316 9.49233 34.2554 9.38352 34.7072 9.70111Z" fill="current" />
                            </svg>
                            <p className="text-grey">{currentTimeZone && currentTimeZone.hasOwnProperty('label') ? currentTimeZone.label : currentTimeZone}</p>
                        </div>
                        <div className="cal-legend margin-l1">
                            <div className="cal-legend-item">
                                <svg className='published_dot' viewBox="0 0 24 24">
                                    <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                </svg>
                                <svg className='failed_dot' viewBox="0 0 24 24">
                                    <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                </svg>
                                <svg className='publishing_dot' viewBox="0 0 24 24">
                                    <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                </svg>
                                <svg className='unpublished_dot' viewBox="0 0 24 24">
                                    <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                </svg>
                                <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24" onMouseEnter={() => setShowLegend(true)} onMouseLeave={() => setShowLegend(false)}>
                                    <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                                </svg>
                                <CalendarCoLegend showLegend={showLegend} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="nav_button" onClick={() => displayThirdPartyModal()}>
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="m12 42-7.65-7.65L12 26.7l2.45 2.45L11 32.6h26l-3.45-3.45L36 26.7l7.65 7.65L36 42l-2.45-2.45L37 36.1H11l3.45 3.45ZM4.95 21.25v-.65q0-1.05.575-1.925T7.1 17.35q1.25-.55 2.575-.825Q11 16.25 12.45 16.25t2.775.275q1.325.275 2.575.825 1 .45 1.575 1.325t.575 1.925v.65Zm23.1 0v-.65q0-1.05.575-1.925T30.2 17.35q1.25-.55 2.575-.825 1.325-.275 2.775-.275t2.775.275q1.325.275 2.575.825 1 .45 1.575 1.325t.575 1.925v.65Zm-15.6-7.75q-1.55 0-2.65-1.1-1.1-1.1-1.1-2.65 0-1.55 1.1-2.65Q10.9 6 12.45 6 14 6 15.1 7.1q1.1 1.1 1.1 2.65 0 1.55-1.1 2.65-1.1 1.1-2.65 1.1Zm23.1 0q-1.55 0-2.65-1.1-1.1-1.1-1.1-2.65 0-1.55 1.1-2.65Q34 6 35.55 6q1.55 0 2.65 1.1 1.1 1.1 1.1 2.65 0 1.55-1.1 2.65-1.1 1.1-2.65 1.1Z" />
                            </svg>
                            {t('share_third_party')}
                        </div>
                        <div className="nav_button margin-l1" onClick={() => goToMonthly()}>
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="M9 44q-1.2 0-2.1-.9Q6 42.2 6 41V10q0-1.2.9-2.1Q7.8 7 9 7h3.25V4h3.25v3h17V4h3.25v3H39q1.2 0 2.1.9.9.9.9 2.1v31q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V19.5H9V41Zm0-24.5h30V10H9Zm0 0V10v6.5ZM24 28q-.85 0-1.425-.575Q22 26.85 22 26q0-.85.575-1.425Q23.15 24 24 24q.85 0 1.425.575Q26 25.15 26 26q0 .85-.575 1.425Q24.85 28 24 28Zm-8 0q-.85 0-1.425-.575Q14 26.85 14 26q0-.85.575-1.425Q15.15 24 16 24q.85 0 1.425.575Q18 25.15 18 26q0 .85-.575 1.425Q16.85 28 16 28Zm16 0q-.85 0-1.425-.575Q30 26.85 30 26q0-.85.575-1.425Q31.15 24 32 24q.85 0 1.425.575Q34 25.15 34 26q0 .85-.575 1.425Q32.85 28 32 28Zm-8 8q-.85 0-1.425-.575Q22 34.85 22 34q0-.85.575-1.425Q23.15 32 24 32q.85 0 1.425.575Q26 33.15 26 34q0 .85-.575 1.425Q24.85 36 24 36Zm-8 0q-.85 0-1.425-.575Q14 34.85 14 34q0-.85.575-1.425Q15.15 32 16 32q.85 0 1.425.575Q18 33.15 18 34q0 .85-.575 1.425Q16.85 36 16 36Zm16 0q-.85 0-1.425-.575Q30 34.85 30 34q0-.85.575-1.425Q31.15 32 32 32q.85 0 1.425.575Q34 33.15 34 34q0 .85-.575 1.425Q32.85 36 32 36Z" />
                            </svg>
                            {t('monthly')}
                        </div>
                    </div>
                </div>
                <div className="row margin-t1">
                    {selectedGroup && selectedSMAccounts && selectedSMAccounts.length > 0 ?
                        selectedSMAccounts.map((account, i) => {
                            return (
                                <div className="account_selection_account" key={i} onClick={e => selectMediaAccount(account)}>
                                    {checkAccountPhoto(account)}
                                </div>
                            )
                        }
                        )
                        : ''
                    }
                </div>
                <CalWeeklySimplified events={filteredCalendarEvents} currentWeek={currentWeek} prevWeek={prevWeek} nextWeek={nextWeek} goToCurrentWeek={goToCurrentWeek} selectEvent={selectEvent} newPost={newPost} />
            </div>
            {showAccountsSelection ?
                <>
                    <div className="transparent_background" onClick={displayAccountSelection}></div>
                    <div className="cal_accounts_picker">
                        <span className="close_modal" onClick={displayAccountSelection}>&times;</span>
                        <CalendarCoAccountsSelection allSMAccounts={allSMAccounts} checkAccountPhoto={checkAccountPhoto} selectMediaAccount={selectMediaAccount} />
                    </div>
                </>
                : ""
            }
            {showTZSelection ?
                <>
                    <div className="transparent_background" onClick={displayTZSelection}></div>
                    <div className="cal_accounts_picker cal_tz_picker">
                        <span className="close_modal" onClick={displayTZSelection}>&times;</span>
                        <CalendarCoTZSelection changeTimeZone={changeTimeZone} currentTimeZone={currentTimeZone} />
                    </div>
                </>
                : ""
            }
        </div>
    )
}

export default CalendarAdvancedWeek
