import React, { useState, useEffect, useRef } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useTranslation } from "react-i18next";


import "./cropper.css";


const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}


const Cropper = ({ displayCropper, imagesRaw, setMediaLoader, uploadImage }) => {
    const { t } = useTranslation(['library']);

    const [loaded, setLoaded] = useState(false);
    const imgRef = useRef(null);
    const originalImg = useRef(null);

    const [rawImages, setRawImages] = useState([]);
    const [imgSrc, setImgSrc] = useState();
    const [crop, setCrop] = useState();
    const [aspect, setAspect] = useState(undefined);

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [croppingData, setCroppingData] = useState([]);
    const [localLoader, setLocalLoader] = useState(false);

    const setCropArea = (ratio) => {
        if (!ratio) {
            setAspect(undefined)
        }
        else if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(ratio)
            setCrop(centerAspectCrop(width, height, ratio))
        }
    }

    const resizePhotos = async () => {
        setMediaLoader(true);
        setLocalLoader(true);
        // displayCropper();

        for (let i = 0; i < imagesRaw.length; i++) {
            const image = imagesRaw[i];
            const index = croppingData.findIndex(data => data.index === i);


            if (index === -1) {
                console.log("image not cropped");
                await uploadImage(image);
            }
            else {
                console.log("img cropped");
                const cData = croppingData[index].cData;
                const ratio = croppingData[index].ratio;

                const finalImgCanva = document.createElement("canvas");
                finalImgCanva.width = cData.width * ratio;
                finalImgCanva.height = cData.height * ratio;

                originalImg.current.src = image;

                const originalCanva = originalImg.current;


                const ctx = finalImgCanva.getContext("2d");
                ctx.imageSmoothingQuality = 'high'
                console.log("drawing");
                ctx.drawImage(
                    originalCanva,
                    cData.x * ratio,
                    cData.y * ratio,
                    cData.width * ratio,
                    cData.height * ratio,
                    0,
                    0,
                    finalImgCanva.width,
                    finalImgCanva.height
                );

                const base64Image = finalImgCanva.toDataURL("image/jpeg", 1);

                await uploadImage(base64Image);
            }
        };

        // setImagesCropped(finalImages);
        setLocalLoader(false);
        displayCropper();
    }

    const storeCroppingData = (cData) => {
        const croppingCanva = imgRef.current;
        const originalCanva = originalImg.current;

        const ratio = originalCanva.width / croppingCanva.width;

        const dataToStore = {
            index: selectedIndex,
            cData: cData,
            aspect: aspect,
            ratio: ratio
        }

        const index = croppingData.findIndex(data => data.index === dataToStore.index);
        if (index !== -1) {
            setCroppingData(croppingData.map(croppedItem => croppedItem.index !== dataToStore.index ? croppedItem : dataToStore))
        }
        else {
            setCroppingData([...croppingData, dataToStore]);
        }
    }

    const changeImgToCrop = (index) => {
        setImgSrc(imagesRaw[index]);
        setSelectedIndex(index);

        setCrop(undefined);

        const existingIndex = croppingData.findIndex(data => data.index === index);
        if (existingIndex !== -1) {
            setCrop(croppingData[existingIndex].cData);
            setAspect(croppingData[existingIndex].aspect)
        }
        else {
            setCrop(undefined);
        }
    }

    const getImgRatio = ({ target: img }, i) => {
        const { naturalHeight, naturalWidth } = img;
        const imgRawValue = rawImages[i];

        if (naturalWidth / naturalHeight !== 1 && naturalWidth / naturalHeight !== 4 / 5 && naturalWidth / naturalHeight !== 1.91) {
            imgRawValue.ig_worthy = false;
        }
        else {
            imgRawValue.ig_worthy = true;
        }

        setRawImages([
            ...rawImages.slice(0, i),
            imgRawValue,
            ...rawImages.slice(i + 1, rawImages.length)
        ])
    };

    const handleRawImges = (rawImgArray) => {
        const rawImagesData = [];

        rawImgArray.forEach(imgData => {
            const data = {
                source: imgData,
                ig_worthy: false
            }

            rawImagesData.push(data);
        });
        setRawImages(rawImagesData);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 10);
        return () => clearTimeout(timer);

    }, [])

    useEffect(() => {
        if (imagesRaw.length > 0) {
            handleRawImges(imagesRaw);
            setImgSrc(imagesRaw[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div>
            <div className={loaded ? "cropper_bg active" : "cropper_bg"}></div>
            <div className={loaded ? "cropper_wrapper" : "cropper_wrapper inactive"}>
                <span className="close_modal" onClick={displayCropper}>&times;</span>
                {Boolean(imgSrc) && (
                    <>
                        <div className='row nowrap row-100 row_spaced'>
                            <div className="cropper_source_photos">
                                {rawImages && rawImages.length > 0 ?
                                    rawImages.map((imageRaw, i) => {
                                        return (
                                            <div className={selectedIndex === i ? 'cropper_source_photo selected' : 'cropper_source_photo'} key={'cropper_source_' + i} onClick={() => changeImgToCrop(i)}>
                                                <img src={imageRaw.source} alt="" onLoad={(e) => getImgRatio(e, i)} />
                                            </div>

                                        )
                                    })
                                    : ""}
                            </div>
                            <div className='row bottomed margin-l2'>
                                <div className="action_button" onClick={() => localLoader ? null : resizePhotos()}>
                                    {localLoader ?
                                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                        :
                                        <>
                                            <svg viewBox="0 0 24 24">
                                                <path fill="current" d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
                                            </svg>
                                            {t('save')}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='divider-100'></div>
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => storeCroppingData(c)}
                            aspect={aspect}
                            className="cropper_cropping_window"
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                            />
                        </ReactCrop>
                        <div className='divider-100'></div>
                        <div className='cropper_icons'>
                            <div className={aspect === 1 ? 'cropper_icon selected' : 'cropper_icon'} onClick={() => setCropArea(1)}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M18,18H6V6H18M18,4H6A2,2 0 0,0 4,6V18A2,2 0 0,0 6,20H18A2,2 0 0,0 20,18V6C20,4.89 19.1,4 18,4Z" />
                                </svg>
                                <p className='p-mini'>{t('square_crop')} (1/1)</p>
                                {/* <p className='p-mini text-grey'>{t('ideal_for_ig')}</p> */}
                            </div>
                            <div className={aspect === 4 / 5 ? 'cropper_icon selected' : 'cropper_icon'} onClick={() => setCropArea(4 / 5)}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M17,19H7V5H17M17,3H7A2,2 0 0,0 5,5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V5C19,3.89 18.1,3 17,3Z" />
                                </svg>
                                <p className='p-mini'>{t('portrait_crop')} (4/5)</p>
                                {/* <p className='p-mini text-grey'>{t('ideal_for_ig')}</p> */}
                            </div>
                            <div className='cropper_icon' onClick={() => setCropArea(1.91 / 1)}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M19,17H5V7H19M19,5H5A2,2 0 0,0 3,7V17A2,2 0 0,0 5,19H19A2,2 0 0,0 21,17V7C21,5.89 20.1,5 19,5Z" />
                                </svg>
                                <p className='p-mini'>{t('landscape_crop')} (1.91/1)</p>
                                {/* <p className='p-mini text-grey'>{t('ideal_for_ig')}</p> */}
                            </div>
                            <div className='cropper_icon' onClick={() => setCropArea(2 / 3)}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M17,19H7V5H17M17,3H7A2,2 0 0,0 5,5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V5C19,3.89 18.1,3 17,3Z" />
                                </svg>
                                <p className='p-mini'>{t('portrait_crop')} (2/3)</p>
                                {/* <p className='p-mini text-grey'>{t('ideal_for_pn')}</p> */}
                            </div>
                            <div className='cropper_icon' onClick={() => setCropArea()}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M19,3H15V5H19V9H21V5C21,3.89 20.1,3 19,3M19,19H15V21H19A2,2 0 0,0 21,19V15H19M5,15H3V19A2,2 0 0,0 5,21H9V19H5M3,5V9H5V5H9V3H5A2,2 0 0,0 3,5Z" />
                                </svg>
                                <p className='p-mini'>{t('free_crop')}</p>
                            </div>
                        </div>
                    </>
                )}

                <div className='cropper_source_img'>
                    <img ref={originalImg} src={imgSrc} alt="" />
                </div>


            </div>
        </div>
    )
}

export default Cropper

