import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import StateModal from '../Loader/StateModal';

import DashboardCoCal from './DashboardComponents/DashboardCoCal';
import DashboardCoNews from './DashboardComponents/DashboardCoNews';
import DashboardCoKanban from './DashboardComponents/DashboardCoKanban';
import DashboardCoIssues from './DashboardComponents/DashboardCoIssues';
import DashboardCoDrafts from './DashboardComponents/DashboardCoDrafts';
import DashboardCoActivity from './DashboardComponents/DashboardCoActivity';
import DashboardCoActivityNext from './DashboardComponents/DashboardCoActivityNext';

import SocialsModal from '../Manage/ManageComponents/SocialsModal';

import './dashboard.css'
const Dashboard = () => {
    const { t } = useTranslation(['dashboard']);
    const history = useHistory();
    const { authTokens } = useAuth();
    const currentPlan = Number(authTokens.plan);

    const headers = {
        Authorization: authTokens.token,
    };

    const goToOnboarding = () => {
        history.push({ pathname: '/onboarding' });
    }

    const goToCreatePost = () => {
        history.push({ pathname: '/post/new_post' });
    }

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    const [showSocialsModal, setShowSocialsModal] = useState(false);
    const closeSocialsModal = () => {
        setShowSocialsModal(false);
    }

    const [needsToAddSocials, setNeedsToAddSocials] = useState(false);

    const fetchAvailableSocialMedia = async () => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_accounts_no_auth_check", payload, {
                headers: headers,
            })
            .then((response) => {
                // setSMAccounts(response.data.sm_accounts);
                if (currentPlan === 0 && authTokens.user_admin && response.data.sm_accounts.length === 0 && !authTokens.onboarding) {
                    goToOnboarding();
                }
                else if (response.data.sm_accounts.length === 0) {
                    setShowSocialsModal(true);
                    setNeedsToAddSocials(true);
                }
                // fetchDashboardData();
                fetchIssues();
            })
            .catch(() => {
            });

        closeStateModal();
    }


    const [actionsToTake, setActionsToTake] = useState([])
    const fetchIssues = async () => {
        const payload = {
            company_id: authTokens.account_id,
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/get_actions_to_take", payload, {
                headers: headers,
            })
            .then((response) => {
                let codesArray = [];
                if (needsToAddSocials) {
                    codesArray = [...response.data, { reason_code: '10000' }]
                }
                else {
                    codesArray = [...response.data];
                }
                var uniqueArray = [...new Set(codesArray)];
                setActionsToTake(uniqueArray);
            })
            .catch(() => {
            });
    }

    useEffect(() => {
        fetchAvailableSocialMedia();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="dashboard_grid">
            {showSocialsModal ? <SocialsModal closeSocialsModal={closeSocialsModal} /> : ""}
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}            <div className='update_block'>
                {actionsToTake && actionsToTake.length > 0 ?
                    <div className="card">
                        <div className="title_dash_red">
                            {t('needs_your_attention')}
                        </div>
                        <DashboardCoIssues actionsToTake={actionsToTake} fetchIssues={fetchIssues} />
                    </div>
                    : ''}
                <div className={actionsToTake && actionsToTake.length > 0 ? "card margin-t1" : "card"}>
                    <div className="title_dash">{t('whats_new')}</div>
                    <DashboardCoNews />
                </div>
                <div className="card margin-t1">
                    <div className="title_dash maxed">
                        Upcoming features
                        <div className='row centered'>
                            <Link to="/news" >
                                <div className="dots">
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />
                                    </svg>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <DashboardCoKanban resultsLimit={true} />
                </div>
            </div>
            <div className='stats_block'>
                <div className="card flex-1">
                    <div className="title">{t('activity_past_7')}</div>
                    <DashboardCoActivity
                        authTokens={authTokens}
                        headers={headers}
                    />
                </div>
                <div className="card flex-1 margin-t2">
                    <div className="title">{t('next_publication')}</div>
                    <DashboardCoActivityNext />
                </div>
                <div className="card flex-1 margin-t2">
                    <div className="title">{t('drafts_area')}</div>
                    <DashboardCoDrafts
                        authTokens={authTokens}
                        headers={headers}
                    />
                </div>
            </div>
            <div className='dashboard_cal_block'>
                <div className="card">
                    <div className='action_button margin-b2' onClick={()=>goToCreatePost()}>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M9 39h2.2l22.15-22.15-2.2-2.2L9 36.8Zm30.7-24.3-6.4-6.4 2.1-2.1q.85-.85 2.1-.85t2.1.85l2.2 2.2q.85.85.85 2.1t-.85 2.1Zm-2.1 2.1L12.4 42H6v-6.4l25.2-25.2Zm-5.35-1.05-1.1-1.1 2.2 2.2Z" />
                        </svg>
                        {t('create_post')}
                    </div>
                    <DashboardCoCal
                        authTokens={authTokens}
                        headers={headers}
                        stateModalOptions={stateModalOptions}
                        setStateModalOptions={setStateModalOptions}
                        closeStateModal={closeStateModal}
                    />
                </div>
            </div>
        </div>
    )
}

export default Dashboard