import React from 'react'

const OrderCancel = () => {
    return (
        <div>
            ORDER CANCELLED!
        </div>
    )
}

export default OrderCancel
