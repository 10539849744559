import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import TooltipHelp from '../../settings/TooltipHelp';

const MAX_CHAR_COUNT = 1500;
const MAX_TWITTER_COUNT = 280;

const TagsSelection = ({ authTokens, publicationData, setPublicationData, setTwitterCharCount, setTotalCharCount }) => {
    const { t } = useTranslation(['composer']);

    const headers = {
        Authorization: authTokens.token,
    };

    const [hashtagsInUse, setHashtagsInUse] = useState([]);

    // GROUPS OF HASHTAGS
    const [hashtagsGroups, setHashtagsGroups] = useState([]);
    const [selectedHashtagsGroup, setSelectedHashtagsGroup] = useState({});
    const [newHashtagsGroupName, setNewHashtagsGroupName] = useState('');
    const [isCheckingSensitivity, setIsCheckingSensitivity] = useState(false);



    const fetchHashtagsGroups = async () => {

        const payload = {
            user_id: authTokens.user_id,
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/get_hashtags_group_team_co", payload, {
                headers: headers,
            })
            .then((response) => {
                console.log(response.data)
                setHashtagsGroups(response.data);
            })
            .catch(() => {
            });
    }

    const selectHashtagsGroup = (e) => {

        if (!e.target.value) {
            setSelectedHashtagsGroup({});
            setHashtagsInUse([]);
            setPublicationData({
                ...publicationData,
                tags: ""
            });
            return;
        }

        const filteredGroupData = hashtagsGroups.filter(group => group._id === e.target.value);

        setSelectedHashtagsGroup(filteredGroupData[0])
        setHashtagsInUse([...filteredGroupData[0].tags])

        setPublicationData({
            ...publicationData,
            tags: filteredGroupData[0].tags.join(' ')
        });

    }

    const saveNewHashtagsGroup = async () => {
        if (!newHashtagsGroupName || hashtagsInUse.length < 2) return;

        const bare_tags = [];
        hashtagsInUse.forEach(fulltag => {
            bare_tags.push(fulltag.hashtag);
        })

        // CHANGE THE LOGIC ON THE SERVER SIDE TO RECORD GROUPS
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id,
            tags: bare_tags, // THE LOGIC HAS CHANGED HERE.
            tags_group_name: newHashtagsGroupName
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/save_hashtags_group_team_co", payload, {
                headers: headers,
            })
            .then((response) => {
                setHashtagsGroups([
                    ...hashtagsGroups,
                    response.data
                ])
                setNewHashtagsGroupName('');
                setSelectedHashtagsGroup(response.data)

                // setHashtagsInUse([...response.data.tags]);
                const fullUsedTagsArray = [];
                response.data.tags.forEach(tag => {
                    fullUsedTagsArray.push({
                        hashtag: tag,
                        level: ''
                    })
                })
                setHashtagsInUse([...fullUsedTagsArray]);

                setPublicationData({
                    ...publicationData,
                    tags: response.data.tags.join(' ')
                });

            })
            .catch(() => {
            });
    }

    useEffect(() => {
        fetchHashtagsGroups();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // END: GROUPS OF HASHTAGS

    // TYPED HASHTAGS
    const [typedTags, setTypedTags] = useState('');

    const handleHashtagInput = (e) => {
        setTypedTags(e.target.value);

        if (e.key !== 'Enter' && e.key !== ' ') return;

        // TRIM THE INPUT
        const trimmed_hashtag = e.target.value.trim();

        // NO MATTER WHAT, A SELECTED GROUP CHANGES WITH A NEW HASHTAG SO RESET THE GROUP SELECT
        setSelectedHashtagsGroup({});

        // FORMAT THE NEW INPUT BY REMOVING ALL # AND ADDING # AT CHAR(0)
        const stripped_hashtags = trimmed_hashtag.replaceAll('#', '');
        const tags_array = stripped_hashtags.split(' ');
        if (tags_array.length === 0) return;

        let formatted_hashtag = [];
        tags_array.forEach(tag => {
            formatted_hashtag.push('#' + tag);
        })
        // const formatted_hashtag = '#' + stripped_hashtag;

        // STORE USED HASHTAGS
        // setHashtagsInUse([...hashtagsInUse, formatted_hashtag]);

        const updatedTags = [];
        hashtagsInUse.forEach(fullTag => {
            updatedTags.push(fullTag.hashtag);
        })

        setPublicationData({
            ...publicationData,
            tags: [...updatedTags, ...formatted_hashtag].join(' ')
        });

        // RESET HASHTAG INPUT FORM
        setTypedTags('');
    }

    const deleteHashtagFromList = (indexInHashtagsArray) => {
        // NO MATTER WHAT A SELECTED GROUP CHANGES WITH A NEW HASHTAG SO RESET THE GROUP SELECT
        setSelectedHashtagsGroup({});

        // MANIPULATE HASHTAGS IN USE ARRAY
        const updatedArray = hashtagsInUse;
        updatedArray.splice(indexInHashtagsArray, 1);
        setHashtagsInUse([...updatedArray]);

        const updatedTags = [];
        updatedArray.forEach(fullTag => {
            updatedTags.push(fullTag.hashtag);
        })

        setPublicationData({
            ...publicationData,
            tags: [...updatedTags].join(' ')
        });

    }

    const hashtagsSafeCheck = async () => {

        if (hashtagsInUse.length === 0) return;

        setIsCheckingSensitivity(true);

        const bare_tags = [];
        hashtagsInUse.forEach(fulltag => {
            bare_tags.push(fulltag.hashtag);
        })

        const payload = {
            tags_array: bare_tags
        }


        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/hashtags/sensitivity_check", payload, {
                headers: headers,
            })
            .then((response) => {
                setHashtagsInUse([...response.data]);
            })
            .catch(() => {

            });
        setIsCheckingSensitivity(false);
    }

    const checkTagColor = (sensitivityLevel) => {

        let classNameSensitivity = '';

        switch (sensitivityLevel) {
            case 0:
                classNameSensitivity = 'used_hashtag sensitive_tag';
                break;
            case 1:
                classNameSensitivity = 'used_hashtag valid_tag';
                break;
            case -1:
                classNameSensitivity = 'used_hashtag banned_tag';
                break;
            default:
                classNameSensitivity = 'used_hashtag';
        }

        return classNameSensitivity;

    }

    const updateCounters = () => {
        // TWITTER COUNTER
        let base_text = publicationData.text;
        let matches = base_text.match(/\bhttp\S+/gi);

        if (!matches || matches.length === 0) {
            setTwitterCharCount(MAX_TWITTER_COUNT - base_text.length - publicationData.tags.length - (publicationData.link_url.length.length > 0 ? 23 : 0));
            setTotalCharCount(MAX_CHAR_COUNT - base_text.length - publicationData.tags.length - (publicationData.link_url.length.length > 0 ? 23 : 0));
        }
        else {
            let string_to_count = base_text;
            matches.forEach(string => {
                string_to_count = string_to_count.replaceAll(string, "");
            })
            setTwitterCharCount(MAX_TWITTER_COUNT - string_to_count.length - publicationData.tags.length - (publicationData.link_url.length.length > 0 ? 23 : 0) - matches.length * 23);
            setTotalCharCount(MAX_CHAR_COUNT - string_to_count.length - publicationData.tags.length - (publicationData.link_url.length.length > 0 ? 23 : 0) - matches.length * 23);
        }

        // HASHTAGS COUNTER
        if (publicationData.tags.length === 0) {
            setHashtagsInUse([]);
        }
        else {
            const usedtagsArray = publicationData.tags.split(' ');
            const fullUsedTagsArray = [];
            usedtagsArray.forEach(tag => {
                fullUsedTagsArray.push({
                    hashtag: tag,
                    level: ''
                })
            })
            setHashtagsInUse([...fullUsedTagsArray]);
        }
    }


    useEffect(() => {
        // updateTags(publicationData.tags);
        updateCounters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicationData.tags, publicationData.text]);



    return (
        <div>
            <div className='composer_title'>
                {t('hashtags_composition')}
            </div>

            <div className='editor_input_block margin-t1'>
                <input type="text" className="editor" placeholder={t('type_hashtags')} value={typedTags} onKeyDown={handleHashtagInput} onChange={e => handleHashtagInput(e)} />
            </div>
            {hashtagsGroups && hashtagsGroups.length > 0 ?
                <div className="row margin-t1">
                    <select className='editor' name="tagsGroups" onChange={e => selectHashtagsGroup(e)} value={selectedHashtagsGroup._id}>
                        <option value="" selected={!selectedHashtagsGroup._id ? true : false}>{t('select_hashtags_group')}</option>
                        {hashtagsGroups.map((group, i) => {
                            return (
                                <option key={i} value={group._id}>{group.tags_group_name}</option>
                            )
                        })}
                    </select>
                </div>
                : ""}
            <div className='row margin-t1'>
                {hashtagsInUse.map((tag, t) => {
                    const classNameData = checkTagColor(tag.level);
                    return (
                        <div className={classNameData} key={'tag_' + t}>
                            {tag.hashtag}
                            <svg viewBox="0 0 24 24" onClick={() => deleteHashtagFromList(t)}>
                                <path fill="current" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                            </svg>
                        </div>)
                })}
            </div>
            {hashtagsInUse.length > 1 && !selectedHashtagsGroup._id ?
                <div className="form_row margin-t1">
                    <label>
                        {t('save_these_hashtags')}
                    </label>
                    <div className='editor_input_block margin-t1'>
                        <input type="text" className="editor" placeholder={t('hashtags_group_name')} value={newHashtagsGroupName} onChange={e => setNewHashtagsGroupName(e.target.value)} />
                        <svg viewBox="0 0 24 24" onClick={() => saveNewHashtagsGroup()}>
                            <path fill="current" d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                        </svg>
                    </div>
                </div>
                : ""}
            {hashtagsInUse && hashtagsInUse.length > 0 ?
                <>
                    <div className='divider-100'></div>
                    <div className='editor_caption_icons'>
                        <div className='editor_caption_icon_block'>
                            <TooltipHelp content={t('hashtags_sensitivity_helper')} direction="right">
                                <svg viewBox="0 0 48 48" onClick={() => hashtagsSafeCheck()}>
                                    <path fill="current" d="M24 31.3q.7 0 1.2-.5t.5-1.2q0-.7-.5-1.2t-1.2-.5q-.7 0-1.2.5t-.5 1.2q0 .7.5 1.2t1.2.5Zm-1.5-6.7h3V14.25h-3ZM24 43.95q-7-1.75-11.5-8.125T8 21.85V9.95l16-6 16 6v11.9q0 7.6-4.5 13.975T24 43.95Zm0-3.1q5.75-1.9 9.375-7.175T37 21.85v-9.8l-13-4.9-13 4.9v9.8q0 6.55 3.625 11.825Q18.25 38.95 24 40.85ZM24 24Z" />
                                </svg>
                            </TooltipHelp>
                        </div>
                        <div className='editor_caption_icon_block'>

                        </div>
                    </div>
                </>
                : ''}
        </div>
    )
}

export default TagsSelection