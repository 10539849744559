import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import axios from "axios";
import { downloadZip } from "client-zip";

const IKpublicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY;
const IKauthenticationEndpoint = process.env.REACT_APP_SOCIALPIKA_API_URL + '/imagekit/auth';

const url_dl_templates = process.env.REACT_APP_CAI_URL_DOWNLOAD;
const url_delete_templates = process.env.REACT_APP_CAI_URL_DELETE;

const CAICoExport = ({ authTokens, setAuthTokens, headers, menuItem, selectedTemplates, selectedCaptions, templateData, stateModalOptions, setStateModalOptions, closeStateModal }) => {
    const { t } = useTranslation(['content_ai']);

    // const [userIdForLibrary, setUserIdForLibrary] = useState(authTokens.user_id);
    const userIdForLibrary = authTokens.user_id;


    // const [currentFolder, setCurrentFolder] = useState({
    //     name: "",
    //     _id: "",
    //     parent_folder_id: ""
    // });

    const currentFolder = {
        name: "",
        _id: "",
        parent_folder_id: ""
    }

    // const selectRandomCaption = (captions) => {
    //     const randomIndex = Math.floor(Math.random() * captions.length);
    //     let item = captions[randomIndex].text;
    //     return item;
    // }

    const saveToSPK = async (posts_creation) => {
        let errorFlag = false;
        if (selectedTemplates.length === 0) return;

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });


        const ai_payload = {
            company_id: authTokens.account_id,
            quantity: selectedTemplates.length,
            type: 'drafts'
        }

        await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/content_ai/ai_credits_check", ai_payload, {
            headers: headers,
        })
            .catch((error) => {
                if (error.response.status === 400) {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        message: t('not_enough_credits')
                    });
                }
                else {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        message: t('pb_with_server')
                    });
                }
                errorFlag = true;
            });

        if (errorFlag) {
            return;
        }

        // 1: Save templates to IK
        // 2: Retrieve all the IK data
        // 3: Either save media only to DB or Save media+captions to DB

        const payload = {
            company_id: authTokens.account_id
        }

        // LOOP THROUGH TEMPLATES TO UPLOAD
        let media_data_from_ik = [];
        let media_data_for_db = [];


        // UPLOAD TO IMAGEKIT
        const ik_upload_url = 'https://upload.imagekit.io/api/v1/files/upload';
        const destination_folder = authTokens.account_id + '/content_ai';

        const imagekit_headers = {
            'Content-Type': 'multipart/form-data'
        }





        await Promise.all(
            selectedTemplates.map(async (template, index) => {

                const IKauth = await axios.post(IKauthenticationEndpoint, payload, {
                    headers: headers,
                })
                    .catch((error) => {
                        console.log("IKAuth error");
                        console.log(error);
                    });

                let params = new FormData();
                params.append('file', template.data);
                params.append('token', IKauth.data.token);
                params.append('fileName', 'socialpika_cai');
                params.append('publicKey', IKpublicKey)
                params.append('signature', IKauth.data.signature)
                params.append('expire', IKauth.data.expire)
                params.append('folder', '/' + destination_folder)

                // console.log(`Params @ index:${index}`)
                // console.log(IKauth.data.token);



                const ik_response = await axios.post(ik_upload_url, params, {
                    headers: imagekit_headers
                })
                    .catch(error => {
                        console.log("error @ axios");
                        console.log(error.response);
                    })

                // console.log(ik_response.data);
                return ik_response.data;
            }

            )).then((data) => {
                // console.log("upload ok");
                media_data_from_ik = data;
            }).catch((err) => {
                console.log("error @ upload photo");
                // serverLogger.error({ message: `error @ FB upload photo - Social id:${social_id}`, meta: `${JSON.stringify(err.response.data)}` })
                // errorFlag = true;
                console.log(err);
            })

        // console.log("loop finished");
        // console.log(media_data_from_ik);

        media_data_from_ik.forEach(ik_media => {
            media_data_for_db.push({
                file_name: ik_media.name,
                file_type: ik_media.fileType,
                file_url: ik_media.url,
                file_id: ik_media.fileId,
                file_size: ik_media.size,
                file_height: ik_media.height,
                file_width: ik_media.width,
                source: 'image',
                type: 'image',
                used: false,
                folder_id: currentFolder._id,
                user_id_for_folder: userIdForLibrary,
                company_id: authTokens.account_id
            })
        })


        const cai_payload = {
            templates: media_data_for_db,
            captions: selectedCaptions,
            posts_creation: posts_creation,
            company_id: authTokens.account_id,
            folder_id: currentFolder._id,
            user_id_for_folder: userIdForLibrary
        }

        // UPLOAD TO SERVER
        await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/content_ai/save_templates", cai_payload, {
            headers: headers,
        })
            .then((response) => {
                setStateModalOptions({
                    ...stateModalOptions,
                    display: true,
                    loader: false,
                    status: "check",
                    message: posts_creation ? t('ai_posts_created') : t('ai_templates_saved'),
                    button_text: posts_creation ? t('go_to_drafts') : t('go_to_library'),
                    button_link: posts_creation ? "/drafts" : '/library'
                });

                const new_credits_used = Math.floor(authTokens.credits_used + selectedTemplates.length);

                setAuthTokens({
                    ...authTokens,
                    credits_used: new_credits_used,
                })
            })
            .catch(() => {
                setStateModalOptions({
                    ...stateModalOptions,
                    display: true,
                    loader: false,
                    status: "error",
                    message: t('ai_server_error')
                });
            });

    }

    const downloadTemplates = async () => {
        let errorFlag = false;
        if (selectedTemplates.length === 0) return;

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        let selectedTemplatesName = [];
        let finalizedTemplates = [];

        selectedTemplates.forEach((templateData => {
            selectedTemplatesName.push(templateData.template);
        }))

        const cai_payload = {
            company_id: authTokens.account_id,
            quantity: selectedTemplates.length,
            type: 'templates'
        }

        // UPLOAD TO SERVER
        await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/content_ai/ai_credits_check", cai_payload, {
            headers: headers,
        })
            .catch((error) => {
                if (error.response.status === 400) {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        status: "error",
                        loader: false,
                        message: t('not_enough_credits')
                    });
                }
                else {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        status: "error",
                        loader: false,
                        message: t('pb_with_server')
                    });
                }
                errorFlag = true;
            });

        if (errorFlag) {
            return;
        }

        // Send the selected templates to server
        const payload = {
            templates: selectedTemplatesName,
            user_id: authTokens.user_id,
            account_id: authTokens.account_id,
            headline: templateData.headline,
            text: templateData.text,
            cta: templateData.cta,
            primary: templateData.primary_color,
            template_name: "download",
            size: templateData.size,
            cta_icon: templateData.cta_icon,
            cta_calc_text_color: templateData.cta_calc_text_color
        }

        await axios.post(url_dl_templates, payload, {
            headers: headers,
        })
            .then((response) => {
                finalizedTemplates = response.data.imgs;
            })
            .catch(() => {
                errorFlag = true;
                setStateModalOptions({
                    ...stateModalOptions,
                    display: true,
                    loader: false,
                    status: "error",
                    message: t('ai_server_error')
                });
            });

        if (errorFlag) return;


        const new_credits_used = Math.floor(authTokens.credits_used + selectedTemplates.length);

        setAuthTokens({
            ...authTokens,
            credits_used: new_credits_used,
        })

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: false,
            status: "check",
            message: t('templates_ready_for_dl')
        });

        if (finalizedTemplates.length > 1) {
            let templates_array = [];

            for (let index = 0; index < finalizedTemplates.length; index++) {
                templates_array.push({
                    name: `SocialPika-${templateData.brand_name}-Template-${index + 1}.png`,
                    size: new Blob([finalizedTemplates[index].data]).size,
                    input: await fetch(finalizedTemplates[index].data)
                })
            }

            const blob = await downloadZip(templates_array).blob()

            const a = document.createElement('a')
            a.href = URL.createObjectURL(blob)
            a.download = `SocialPika-${templateData.brand_name}-Templates.zip`;
            a.click()
        }
        else {
            // console.log(finalizedTemplates[0]);
            let a = document.createElement('a')
            a.href = finalizedTemplates[0].data;
            a.download = `SocialPika-${templateData.brand_name}-Template.png`;
            a.click()
        }
    }

    const deleteTemplatesCAIServer = () => {
        // console.log('sending delete templates order');
        const payload = {
            account_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        axios.post(url_delete_templates, payload)
            .then(() => {
                console.log("templates deleted");
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        return () => deleteTemplatesCAIServer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={menuItem === 'export' ? 'composer_component active' : 'composer_component'}>
            {selectedTemplates.length > 0 && selectedCaptions.length > 0 ?
                <div className="action_button" onClick={() => saveToSPK(true)}>
                    <svg viewBox="0 0 24 24">
                        <path fill="current" d="M3 3V21H21V3H3M18 18H6V17H18V18M18 16H6V15H18V16M18 12H6V6H18V12Z" />
                    </svg>
                    <div className='main_title'>{t('create_draft_post')} ({selectedTemplates.length} {selectedTemplates.length > 1 ? t('credits') : t('credit')})</div>
                </div>
                : selectedTemplates.length > 0 && selectedCaptions.length === 0 ?
                    <div className="action_button margin-t2" onClick={() => saveToSPK(false)}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M22,16V4A2,2 0 0,0 20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16M11,12L13.03,14.71L16,11L20,16H8M2,6V20A2,2 0 0,0 4,22H18V20H4V6" />
                        </svg>
                        <div className='main_title'>{t('save_to_media_library')} ({selectedTemplates.length} {selectedTemplates.length > 1 ? t('credits') : t('credit')})</div>
                    </div>
                    : ''}
            {selectedTemplates.length > 0 ?
                <div className="action_button margin-t2" onClick={() => downloadTemplates()}>
                    <svg viewBox="0 0 24 24">
                        <path fill="current" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
                    </svg>
                    <div className='main_title'>{t('dl_templates')} ({selectedTemplates.length} {selectedTemplates.length > 1 ? t('credits') : t('credit')})</div>
                </div>
                : ''}
            {/* {selectedTemplates.length > 0 && selectedCaptions.length > 0 ?
                <div className="row_hcenter row-100 margin-t4">
                    <div className="big_action_button" onClick={() => saveToSPK(true)}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M3 3V21H21V3H3M18 18H6V17H18V18M18 16H6V15H18V16M18 12H6V6H18V12Z" />
                        </svg>
                        <div className='main_title'>{t('create_draft_post')}</div>
                        <div className='sub_title'>({t('template_plus_captions')})</div>
                        <div className='mini_title'>({selectedTemplates.length} {selectedTemplates.length > 1 ? t('credits') : t('credit')})</div>
                    </div>
                </div>
                : selectedTemplates.length > 0 && selectedCaptions.length === 0 ?
                    <div className="row_hcenter row-100 margin-t4">
                        <div className="big_action_button" onClick={() => saveToSPK(false)}>
                            <svg viewBox="0 0 24 24">
                                <path fill="current" d="M22,16V4A2,2 0 0,0 20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16M11,12L13.03,14.71L16,11L20,16H8M2,6V20A2,2 0 0,0 4,22H18V20H4V6" />
                            </svg>
                            <div className='main_title'>{t('save_to_media_library')}</div>
                            <div className='mini_title'>({selectedTemplates.length} {selectedTemplates.length > 1 ? t('credits') : t('credit')})</div>
                        </div>
                    </div>
                    : ''
            }
            {selectedTemplates.length > 0 ?
                <div className="row_hcenter row-100 margin-t4">
                    <div className="big_action_button" onClick={() => downloadTemplates()}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
                        </svg>
                        <div className='main_title'>{t('dl_templates')}</div>
                        <div className='sub_title'>({t('templates_only_png')})</div>
                        <div className='mini_title'>({selectedTemplates.length} {selectedTemplates.length > 1 ? t('credits') : t('credit')})</div>
                    </div>
                </div>
                : ''} */}


        </div >
    )
}

export default CAICoExport