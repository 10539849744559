import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useAuth } from "../../auth";
import { useTranslation } from "react-i18next";
import moment from 'moment'
import StateModal from '../Loader/StateModal';

import ContentPN from './EditorComponents/ContentPN';
import Publish from './EditorComponents/Publish';

import UpgradeModal from '../Plans/UpgradeModal';
import SocialsModal from '../Manage/ManageComponents/SocialsModal';

import PostPreviewBlankPN from '../Composer/PostPreviews/PostPreviewBlankPN';
import PostPreviewPN from '../Composer/PostPreviews/PostPreviewPN';


import "./editor.css"


const PinEditor = (props) => {
    const { authTokens } = useAuth();
    const currentPlan = Number(authTokens.plan);
    const { t } = useTranslation(['composer']);

    const headers = {
        Authorization: authTokens.token,
    };

    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const displayUpgradeModal = () => {
        setShowUpgradeModal(!showUpgradeModal);
    }

    const [showSocialsModal, setShowSocialsModal] = useState(false);
    const closeSocialsModal = () => {
        setShowSocialsModal(false);
    }

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }

    let scheduled_post_id;
    let initial_date;
    const paramsId = props.match.params.id;
    if (paramsId) {
        if (paramsId.charAt(0) === 'D') {
            initial_date = paramsId.substring(paramsId.indexOf(':') + 1);
        }
        else {
            scheduled_post_id = paramsId;
        }
    }



    const [editorMenuItem, setEditorMenuItem] = useState('content');


    const initialPublicationData = {
        _id: "",
        name: "",
        text: "",
        link_url: "",
        date: initial_date || "",
        timezone: { label: Intl.DateTimeFormat().resolvedOptions().timeZone, value: Intl.DateTimeFormat().resolvedOptions().timeZone },
        tags: "",
        frequency: "0",
        repeat: "",
        company_id: authTokens.account_id,
        draft: false,
        spread: false,
        spread_period: "5",
        social_accounts: [],
        medias: []
    }
    const [publicationData, setPublicationData] = useState(initialPublicationData);

    const [videoCheckerResults, setVideoCheckerResults] = useState({
        is_video: false,
        is_loading: false,
        needs_analysis: false,
        video_size: '',
        video_duration: '',
        video_width: '',
        video_height: '',
        fb_regular: false,
        fb_regular_error: '',
        fb_reel: false,
        fb_reel_error: '',
        ig_regular: false,
        ig_regular_error: '',
        ig_reel: false,
        ig_reel_error: '',
        lk: false,
        lk_error: '',
        tw: false,
        tw_error: '',
        pn: false,
        pn_error: '',
        tk: false,
        tk_error: '',
    })

    const checkPublishableVideo = () => {
        let video_check_passed = true;
        let video_check_error = [t('video_not_publishable')];

        if (publicationData.medias && publicationData.medias.length > 0 && publicationData.medias[0].type !== 'image' && publicationData.social_accounts && publicationData.social_accounts.length > 0) {
            publicationData.social_accounts.forEach(account => {
                if (account.media === 'Pinterest' && !videoCheckerResults.pn) {
                    video_check_passed = false;
                    video_check_error.push(t('video_not_publishable_as_pn'));
                }
            })
        }

        const payload = {
            passed: video_check_passed,
            errors: video_check_error.join('')
        }

        return payload;
    }

    const [PNAccounts, setPNAccounts] = useState([]);
    const [selectedBoards, setSelectedBoards] = useState([]);

    const recordPost = async (type) => {
        let authError = false;

        let post_payload = publicationData;

        if (type === 'draft') {
            post_payload.draft = true;
        }
        else if (type === 'now') {
            post_payload.date = moment().add(3, 'minutes');
            post_payload.draft = false;
        }



        if (!post_payload.draft && (!post_payload.social_accounts || post_payload.social_accounts.length === 0)) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('select_a_board')
            });
            return
        }


        if (!post_payload.text && post_payload.medias.length === 0 && !post_payload.link_url) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('no_content')
            });
            return
        }

        if (!post_payload.date && type === "schedule") {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('no_date')
            });
            return
        }

        const titleCount = publicationData.tags.length;

        if (titleCount > 100) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('title_too_long')
            });
            return
        }

        const descCount = publicationData.text.length;

        if (descCount > 500) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('desc_too_long')
            });
            return
        }

        const video_check = checkPublishableVideo();

        if (!video_check.passed) {

            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: video_check.errors
            });

            return;
        }

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });


        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/save_post_spread_option", post_payload, {
                headers: headers,
            })
            .then(() => {
                if (!post_payload.draft) {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        upgradeOption: currentPlan === 0 ? true : false,
                        status: "check",
                        message: t('post_recorded'),
                        button_text: t('go_to_calendar'),
                        button_link: "/calendar/monthly"
                    });
                }
                else if (post_payload.draft && post_payload.date) {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        upgradeOption: false,
                        status: "check",
                        message: t('post_recorded'),
                        button_text: t('go_to_calendar'),
                        button_link: "/calendar/monthly"
                    });
                }
                else {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        upgradeOption: currentPlan === 0 ? true : false,
                        status: "check",
                        message: t('post_recorded'),
                        button_text: t('go_to_drafts'),
                        button_link: "/drafts"
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
                else {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        status: "error",
                        message: t('post_not_recorded')
                    });
                }

            });
        if (authError) return;

    }

    const fetchAvailableSocialMedia = async () => {
        let authError = false;
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });



        const payload = {
            user_id: authTokens.user_id,
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_pinterest_accounts_and_boards", payload, {
                headers: headers,
            })
            .then((response) => {
                closeStateModal();
                setPNAccounts(response.data);
                if (response.data.length === 0) {
                    setShowSocialsModal(true);
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
            });

        if (authError) return;

    }

    const fetchScheduledPostDetails = async (_id) => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });
        const payload = {
            _id: _id,
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/get_single_scheduled_post_details", payload, {
                headers: headers,
            })
            .then((response) => {
                setPublicationData(response.data);
                let selectedBoards = [];
                response.data.boards.forEach(boardData => {
                    selectedBoards.push({
                        board_id: boardData.board_id,
                        account_id: boardData.account_id
                    })
                });

                setSelectedBoards(selectedBoards);

            })
            .catch(() => {
            });
        closeStateModal();
    }

    useEffect(() => {
        if (scheduled_post_id) {
            fetchScheduledPostDetails(scheduled_post_id);
        }
        fetchAvailableSocialMedia();

        props.changeNav("New Post", "NewPost");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="content_row_maxed nowrap">
            {showUpgradeModal ? <UpgradeModal upgrade={true} closeModal={() => setShowUpgradeModal(false)} closeButton={true} /> : ''}
            {showSocialsModal ? <SocialsModal closeSocialsModal={closeSocialsModal} /> : ""}
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            <div className="editor_card">
                <div className='editor_menu'>
                    <div className={editorMenuItem === 'content' ? 'editor_menu_item active' : 'editor_menu_item'} onClick={() => setEditorMenuItem('content')}>
                        {t('content')}
                    </div>
                    <div className={editorMenuItem === 'publication' ? 'editor_menu_item active' : 'editor_menu_item'} onClick={() => setEditorMenuItem('publication')}>
                        {t('publication')}
                    </div>
                    <div className='editor_menu_indicator'></div>
                </div >
                <div className='editor_components margin-t1'>
                    <ContentPN
                        editorMenuItem={editorMenuItem}
                        authTokens={authTokens}
                        currentPlan={currentPlan}
                        displayUpgradeModal={displayUpgradeModal}
                        publicationData={publicationData}
                        setPublicationData={setPublicationData}
                        PNAccounts={PNAccounts}
                        selectedBoards={selectedBoards}
                        setSelectedBoards={setSelectedBoards}
                        stateModalOptions={stateModalOptions}
                        setStateModalOptions={setStateModalOptions}
                        mediaTransfer={props.mediaTransfer}
                        videoCheckerResults={videoCheckerResults}
                        setVideoCheckerResults={setVideoCheckerResults}
                    />
                    <Publish
                        authTokens={authTokens}
                        displayUpgradeModal={displayUpgradeModal}
                        editorMenuItem={editorMenuItem}
                        publicationData={publicationData}
                        setPublicationData={setPublicationData}
                        recordPost={recordPost}
                    />
                </div>
            </div >
            <div className="card_preview">
                <div className='composer_component_thread'>
                    <div className="title">
                        {t('pin_preview')}
                    </div>
                    <div className='composer_component_previews'>
                        {<PostPreviewBlankPN publicationData={publicationData} selectedPhotos={publicationData.medias} />}
                        {<PostPreviewPN publicationData={publicationData} selectedPhotos={publicationData.medias} />}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PinEditor