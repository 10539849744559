import React, { useState, useRef } from 'react'
import AWS from 'aws-sdk'
import axios from "axios";
import Resizer from "react-image-file-resizer";
import { useTranslation } from "react-i18next";


const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_S3_BUCKET_LOCATION;

// const IKpublicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY;
// const IKauthenticationEndpoint = process.env.REACT_APP_SOCIALPIKA_API_URL + '/imagekit/auth';


AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_BUCKET_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_KEY
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})

const handleMediaFile = (file) => {
  return new Promise(function (resolve, reject) {
    let fr = new FileReader();

    fr.onload = function () {
      resolve(fr.result);
    };

    fr.onerror = function () {
      reject(fr);
    };

    fr.readAsDataURL(file);
  });
}

const LibraryUploadTool = ({ authTokens, currentFolder, userIdForLibrary, setLibraryNeedsRefresh, libraryNeedsRefresh, setImagesRaw, displayCropper, mediaLoader, setMediaLoader }) => {
  const { t } = useTranslation(['composer']);

  const currentPlan = Number(authTokens.plan);
  const headers = {
    Authorization: authTokens.token,
  };

  const [bigFileMsg, setBigFileMsg] = useState(false);

  const inputPhotoOnlyRef = useRef(null);
  const inputFullMediaRef = useRef(null);

  const [videoMeta, setVideoMeta] = useState({});

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1080,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const [videoUploadProgress, setVideoUploadProgress] = useState(0);

  const recordVideoDataInDB = async (video_file_name, url) => {

    const media_data_for_db = {
      file_name: video_file_name,
      file_type: 'video',
      file_url: url,
      file_id: 'S3-video',
      file_size: videoMeta.video_size,
      source: 'video',
      type: 'video',
      used: false,
      folder_id: currentFolder._id,
      user_id_for_folder: userIdForLibrary,
      company_id: authTokens.account_id,
      video_duration: videoMeta.video_duration,
      video_width: videoMeta.video_width,
      video_height: videoMeta.video_height,
      video_size: videoMeta.video_size
    }
    // CREATE NEW MEDIA IN DB
    await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/media/save_media_in_folder", media_data_for_db, {
      headers: headers,
    })
      .then((response) => {
        media_data_for_db._id = response.data.media_id
      })
      .catch(() => {
      });


    setLibraryNeedsRefresh(!libraryNeedsRefresh);
    setMediaLoader(false);
  }

  const uploadVideo = async (video_file) => {

    setMediaLoader(true);

    const video_name = video_file.name;
    const video_file_name = Math.random().toString(16).slice(2) + '_' + video_name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
    const destination_path = authTokens.account_id + '/' + video_file_name;
    const destination_url = process.env.REACT_APP_S3_BUCKET_URI + destination_path

    const params = {
      ACL: 'public-read',
      Body: video_file,
      Bucket: S3_BUCKET,
      Key: destination_path
    };

    myBucket.putObject(params)
      .on('httpUploadProgress', (evt) => {
        setVideoUploadProgress(Math.round((evt.loaded / evt.total) * 100))
        console.log(Math.round((evt.loaded / evt.total) * 100) + '%');
      })
      .send(async (err, data) => {
        if (err) {
          console.log("Error @ S3 upload");
          console.log(err);
          setMediaLoader(false);
        }
        else {
          console.log("video uploaded");
          console.log(data);

          await recordVideoDataInDB(video_file_name, destination_url);
        }

      })
  }

  // const uploadVideo = async (video_file) => {
  //   let authError = false;

  //   setMediaLoader(true);

  //   const payload = {
  //     company_id: authTokens.account_id
  //   }

  //   const IKauth = await axios.post(IKauthenticationEndpoint, payload, {
  //     headers: headers,
  //   })
  //     .catch((error) => {
  //       if (error.response.status === 401) {
  //         setMediaLoader(false);
  //         // props.checkUnauthorizedResponse(error.response.data);
  //         authError = true;
  //       }
  //     });

  //   if (authError) return;

  //   const imagekit_headers = {
  //     'Content-Type': 'multipart/form-data'
  //   }

  //   const destination_folder = authTokens.account_id;

  //   const params = new FormData();
  //   params.append('file', video_file)
  //   params.append('publicKey', IKpublicKey)
  //   params.append('signature', IKauth.data.signature)
  //   params.append('expire', IKauth.data.expire)
  //   params.append('token', IKauth.data.token)
  //   params.append('fileName', video_file.name)
  //   params.append('folder', '/' + destination_folder)

  //   const ik_upload_url = 'https://upload.imagekit.io/api/v1/files/upload';

  //   let media_data_for_db = {};

  //   await axios.post(ik_upload_url, params, {
  //     headers: imagekit_headers
  //   })
  //     .then(res => {
  //       media_data_for_db = {
  //         file_name: res.data.name,
  //         file_type: res.data.fileType,
  //         file_url: res.data.url,
  //         file_id: res.data.fileId,
  //         file_size: res.data.size,
  //         source: 'video',
  //         type: 'video',
  //         used: false,
  //         folder_id: currentFolder._id,
  //         user_id_for_folder: userIdForLibrary,
  //         company_id: authTokens.account_id,
  //         video_duration: videoMeta.video_duration,
  //         video_width: videoMeta.video_width,
  //         video_height: videoMeta.video_height,
  //         video_size: videoMeta.video_size
  //       }

  //     })
  //     .catch(() => {
  //     })

  //   // CREATE NEW MEDIA IN DB
  //   await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/media/save_media_in_folder", media_data_for_db, {
  //     headers: headers,
  //   })
  //     .then((response) => {
  //       media_data_for_db._id = response.data.media_id
  //     })
  //     .catch(() => {
  //     });

  //   setLibraryNeedsRefresh(true);
  //   setMediaLoader(false);
  // }


  const mediaSelected = async (event) => {
    setBigFileMsg(false);

    let files = event.currentTarget.files;
    let readers = [];

    // Abort if there were no files selected
    if (!files.length) return;

    // Store promises in array  
    for (let i = 0; i < files.length; i++) {
      readers.push(handleMediaFile(files[i]));
    }
    const filesData = await Promise.all(readers);

    let imagesArray = [];
    for (let i = 0; i < filesData.length; i++) {
      let match = filesData[i].match(/^data:([^/]+)\/([^;]+);/) || [];
      let type = match[1];

      if (type === 'image') {
        const image = await resizeFile(files[i]);
        imagesArray.push(image);


        setVideoMeta({});
      }
      else if (type === 'video' && currentPlan > 0) {
        const fsize = files[i].size;
        const fsizef = Math.round((fsize / 1024));

        if (fsizef > 250000) {
          setBigFileMsg(true);
          return;
        }


        const video = document.createElement('video')
        video.addEventListener('loadedmetadata', event => {
          console.log(video.videoWidth, video.videoHeight, video.duration);
          setVideoMeta({
            video_duration: video.duration,
            video_width: video.videoWidth,
            video_height: video.videoHeight,
            video_size: fsizef
          })
        })
        video.src = URL.createObjectURL(files[i])


        await uploadVideo(files[i]);
        imagesArray = [];
        return;
      }
    }

    if (imagesArray.length > 0) {
      setImagesRaw(imagesArray)
      displayCropper();
    }

  };

  const onFileDrop = async (event) => {
    setBigFileMsg(false);
    setDragActive(false);
    event.preventDefault();
    event.stopPropagation();

    let files = event.dataTransfer.files;
    let readers = [];

    // Abort if there were no files selected
    if (!files.length) return;

    // Store promises in array  
    for (let i = 0; i < files.length; i++) {
      readers.push(handleMediaFile(files[i]));
    }

    const filesData = await Promise.all(readers);

    let imagesArray = [];
    for (let f = 0; f < filesData.length; f++) {
      let match = filesData[f].match(/^data:([^/]+)\/([^;]+);/) || [];

      let type = match[1];
      if (type === 'image') {
        const image = await resizeFile(files[f]);
        imagesArray.push(image);

        setVideoMeta({});
      }
      else if (type === 'video' && currentPlan > 0) {

        const fsize = files[f].size;
        const fsizef = Math.round((fsize / 1024));

        if (fsizef > 250000) {
          setBigFileMsg(true);
          return;
        }

        const video = document.createElement('video')
        video.addEventListener('loadedmetadata', event => {
          console.log(video.videoWidth, video.videoHeight, video.duration);
          setVideoMeta({
            video_duration: video.duration,
            video_width: video.videoWidth,
            video_height: video.videoHeight,
            video_size: fsizef
          })
        })
        video.src = URL.createObjectURL(files[f])

        await uploadVideo(files[f]);
        imagesArray = [];
        return;
      }
    }

    if (imagesArray.length > 0) {
      console.log(imagesArray.length)
      setImagesRaw(imagesArray)
      displayCropper();
    }

  };

  // const checkVideoMeta = (media_data) => {
  //   if (media_data.hasOwnProperty('video_duration')) {
  //     setLoadVideoChecker(false);
  //     setVideoMeta({
  //       video_duration: media_data.video_duration,
  //       video_width: media_data.video_width,
  //       video_height: media_data.video_height,
  //       video_size: media_data.video_size
  //     })
  //   }
  //   else {
  //     console.log("Video meta must be checked another way!");
  //     setLoadVideoChecker(true);
  //   }
  // }


  const [dragActive, setDragActive] = useState(false);

  const onDragOver = (event) => {
    setDragActive(true);
    event.stopPropagation();
    event.preventDefault();
  }

  const onDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  const onDragLeave = (event) => {
    setDragActive(false);
    event.preventDefault();
    event.stopPropagation();
  }

  const displayMediaUpload = () => {
    if (currentPlan > 0) {
      inputFullMediaRef.current.click();
    }
    else {
      inputPhotoOnlyRef.current.click();
    }
  }

  return (
    <>
      <input type="file" multiple ref={inputPhotoOnlyRef} onChange={mediaSelected} className="imagekit_upload" accept=".jpg, .jpeg, .png, .gif, .webp, .svg" />
      <input type="file" multiple ref={inputFullMediaRef} onChange={mediaSelected} className="imagekit_upload" accept=".jpg, .jpeg, .png, .gif, .webp, .svg, .mov, .mp4, .webm, .ts, .m3u8" />
      <div className={dragActive ? "composer_media_upload_card active" : "composer_media_upload_card"} onClick={displayMediaUpload} onDrop={(e) => onFileDrop(e)} onDragEnter={(e) => onDragEnter(e)} onDragOver={(e) => onDragOver(e)} onDragLeave={(e) => onDragLeave(e)} >
        {mediaLoader ?
          <div className="lds-ring-loader">
            {videoUploadProgress && videoUploadProgress > 0 ?
              <span className='progress'>{videoUploadProgress}%</span>
              : ""}
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          :
          bigFileMsg ?
            <p className='text-red'>{t('video_file_too_big')}</p>
            :
            <div className='column'>
              <svg viewBox="0 0 24 24">
                <path fill="current" d="M19.35,10.04C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.04C2.34,8.36 0,10.91 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.04M19,18H6A4,4 0 0,1 2,14C2,11.95 3.53,10.24 5.56,10.03L6.63,9.92L7.13,8.97C8.08,7.14 9.94,6 12,6C14.62,6 16.88,7.86 17.39,10.43L17.69,11.93L19.22,12.04C20.78,12.14 22,13.45 22,15A3,3 0 0,1 19,18M8,13H10.55V16H13.45V13H16L12,9L8,13Z" />
              </svg>
              {t('drop_files')}
            </div>
        }
      </div>
    </>
  )
}

export default LibraryUploadTool