import React, { useState, useEffect } from 'react'
import axios from "axios";
import moment from 'moment'

import CalendarEvent from '../../CalendarView/CalendarEvent';

const DashboardCoCal = ({ authTokens, headers, stateModalOptions, setStateModalOptions, closeStateModal }) => {

    const [calendarEvents, setCalendarEvents] = useState([]);

    const getLocalEvents = async () => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            user_id: authTokens.user_id,
            company_id: authTokens.account_id,
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/get_local_calendar", payload, {
                headers: headers,
            })
            .then((response) => {
                setCalendarEvents(response.data);

            })
            .catch(() => {
            });

        closeStateModal();
    }

    const renderCells = () => {
        const dateFormat = "ddd D";
        const days = [];

        const startDate = moment().subtract(2, 'days');
        let day = moment(startDate);
        let formattedDate = "";

        for (let i = 0; i < 7; i++) {
            formattedDate = day.format(dateFormat);
            const dayIsToday = moment().isSame(day, 'day');

            let eventsOfTheDay = [];
            calendarEvents.forEach(event => {
                // if (moment(event.publication_date).isSame(day, 'day')) {
                if (moment(event.publication_date).format('DDMMYYYY') === moment(day).format('DDMMYYYY')) {
                    eventsOfTheDay.push(event);
                }
            })

            days.push(
                <div className={`dashboard-cal-cell ${dayIsToday ? "dashboard-cal-today" : ""}`} key={day}>
                    <div className={`dashboard-cal-date`}>{formattedDate}</div>
                    {eventsOfTheDay.length > 0 ?
                        eventsOfTheDay.map((eventOfDay, i) => {
                            let eventClass = "cal-event cal-event-" + i;
                            if (eventOfDay.draft) {
                                eventClass = "cal-event draft-event cal-event-" + i;
                            }
                            return (
                                <span key={day - i} className={eventClass} ><CalendarEvent event={eventOfDay} hideEdit={true} clickOnDashboard={true} /></span>
                            )
                        })
                        : ""}
                </div >
            );
            day.add(1, 'days');
        }

        return <div>{days}</div>;

    }

    useEffect(() => {
        getLocalEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="calendar">
            {renderCells()}
        </div>
    )
}

export default DashboardCoCal