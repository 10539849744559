import React, { useState, useEffect } from 'react'
import Chart from "react-apexcharts";
import moment from 'moment'
import { useTranslation } from "react-i18next";



const FBAnalytics = ({ insights, instagram, mainInsights }) => {
    const { t } = useTranslation(['analytics_simple', 'countries_FB']);

    const [followersData, setFollowersData] = useState([]);
    const [countriesData, setCountriesData] = useState([]);
    const [reachData, setReachData] = useState([]);
    const [audienceData, setAudienceData] = useState([]);

    const [followersOptions, setFollowersOptions] = useState({
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            }
        },
        colors: ['#19A4FE', '#EB316F', '#617183'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        title: {
            text: '',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {},
        yaxis: {
            labels: {
                formatter: function (val, index) {
                    return val.toFixed(0);
                }
            }
        }
    })

    const [countriesOptions, setCountriesOptions] = useState({
        chart: {
            type: 'bar',
            height: 200,
            stacked: true,
        },
        colors: ['#19A4FE', '#EB316F', '#617183'],
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        title: {
            text: ''
        },
        xaxis: {
            categories: []
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        }
    })

    const [reachOptions, setReachOptions] = useState({
        chart: {
            type: 'bar',
            height: 400
        },
        colors: ['#19A4FE', '#EB316F', '#617183'],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '88%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        title: {
            text: ''
        },
        xaxis: {
            tickAmount: 15
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        }
    })

    const [audienceOptions, setAudienceOptions] = useState({
        chart: {
            type: 'bar',
            height: 400,
            stacked: true,
        },
        colors: ['#19A4FE', '#EB316F', '#617183'],
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        title: {
            text: ''
        },
        xaxis: {},
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        }
    })

    const loadData = () => {

        // FOLLOWERS
        let followers_categories = [];
        insights.followers_evolution.map(data => (
            followers_categories.push(moment(data.end_time).format('DD/MM'))
        ))

        setFollowersOptions({
            ...followersOptions,
            xaxis: {
                tickAmount: 15,
                categories: followers_categories
            }
        })
        // followersOptions.xaxis.categories = followers_categories;

        let followers_data = [];
        insights.followers_evolution.forEach(data => {
            if (!instagram) {
                followers_data.push(data.value)
            }
            else {
                followers_data.push(Number(data.value + mainInsights.followers))
            }
        })
        setFollowersData([{
            name: "Followers",
            data: followers_data
        }]);


        // COUNTRIES

        let countries_category = [];
        let countries_data = [];

        const sortedCountries = Object.entries(insights.followers_country)
            .sort(([, a], [, b]) => b - a)
            .reduce(
                (r, [k, v]) => ({
                    ...r,
                    [k]: v
                }),
                {}
            )

        let count = 0;
        for (const key in sortedCountries) {
            if (count === 5) { break; }
            countries_category.push(t(`${key}`, { ns: 'countries_FB' }));
            countries_data.push(sortedCountries[key]);
            count++;
        }
        setCountriesOptions({
            ...countriesOptions,
            xaxis: {
                categories: countries_category
            }
        })
        // countriesOptions.xaxis.categories = countries_category;

        setCountriesData([{
            name: t('countries'),
            data: countries_data
        }])


        // REACH
        let reach_categories = [];
        insights.reach.map(data => (
            reach_categories.push(moment(data.end_time).format('DD/MM'))
        ))
        setReachOptions({
            ...reachOptions,
            xaxis: {
                categories: reach_categories
            }
        })
        // reachOptions.xaxis.categories = reach_categories;

        let reach_data = [];
        insights.reach.map(data => (
            reach_data.push(data.value)
        ))
        let engagement_data = [];
        if (instagram) {
            insights.impressions.map(data => (
                engagement_data.push(data.value)
            ))
        }
        else {
            insights.engagement.map(data => (
                engagement_data.push(data.value)
            ))
        }
        setReachData([{
            name: t('reach'),
            data: reach_data
        }, {
            name: instagram ? t('impressions') : t('engagement'),
            data: engagement_data
        }])


        // AUDIENCE
        const audience_ages = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'];
        let male_data = [];
        let female_data = [];
        let unidentified_data = [];

        audience_ages.forEach(age_range => {
            if (insights.followers_gender_age.hasOwnProperty(`M.${age_range}`)) {
                male_data.push(insights.followers_gender_age[`M.${age_range}`])
            }
            if (insights.followers_gender_age.hasOwnProperty(`F.${age_range}`)) {
                female_data.push(insights.followers_gender_age[`F.${age_range}`])
            }
            if (insights.followers_gender_age.hasOwnProperty(`U.${age_range}`)) {
                unidentified_data.push(insights.followers_gender_age[`U.${age_range}`])
            }
        })

        setAudienceOptions({
            ...audienceOptions,
            xaxis: {
                categories: audience_ages
            }
        })
        // audienceOptions.xaxis.categories = audience_ages;

        setAudienceData([{
            name: t('male'),
            data: male_data
        }, {
            name: t('female'),
            data: female_data
        }, {
            name: t('unknown'),
            data: unidentified_data
        }])
    }

    useEffect(() => {

        if (insights) { loadData() }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='row-100 row_hcenter margin-t2'>
            <div className='column_left flex-1'>
                <h2>{t('followers')}</h2>
                <Chart
                    options={followersOptions}
                    series={followersData}
                    type='area'
                    height='300px'
                    width='420px'
                />
                <h2>{t('audience')}</h2>
                <Chart
                    options={audienceOptions}
                    series={audienceData}
                    type='bar'
                    height='250px'
                    width='420px'
                />

            </div>
            <div className='column_left margin-l2 flex-2'>
                <h2>{t('insights')}</h2>
                <Chart
                    options={reachOptions}
                    series={reachData}
                    type='bar'
                    height='300px'
                    width='500px'
                />
                <h2>{t('top_5_countries')}</h2>
                <Chart
                    options={countriesOptions}
                    series={countriesData}
                    type='bar'
                    height='250px'
                    width='500px'
                />
            </div>
        </div>
    )
}

export default FBAnalytics