import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from 'moment-timezone'
import { useTranslation } from "react-i18next";


import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import PNicon from "../../../images/social_media/pinterest.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import TKicon from "../../../images/social_media/tiktok-spk.svg";
import YTicon from "../../../images/social_media/youtube.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"
import FBGroupIcon from "../../../images/social_media/fb_group.png";

// import NOMedia from "../../../images/illustrations/drafts.png";

const DashboardCoDrafts = ({ authTokens, headers }) => {
    const { t } = useTranslation(['feeds']);
    const history = useHistory();

    const goToDrafts = () => {
        history.push({ pathname: '/drafts' });
    }

    const checkAccountPhoto = (draftData, sm_icon) => {


        if ((!draftData.social_accounts || draftData.social_accounts.length === 0) && draftData.cai_generated) {
            return (
                <div className="account_profile_ai" >
                    <svg viewBox="0 0 48 48">
                        <path fill="current" d="m9.35 20.45 5.3 2.25q.9-1.8 1.925-3.55Q17.6 17.4 18.75 15.8L14.8 15Zm7.7 4.05 6.65 6.65q2.85-1.3 5.35-2.95 2.5-1.65 4.05-3.2 4.05-4.05 5.95-8.3 1.9-4.25 2.05-9.6-5.35.15-9.6 2.05t-8.3 5.95q-1.55 1.55-3.2 4.05-1.65 2.5-2.95 5.35Zm11.45-4.8q-1-1-1-2.475t1-2.475q1-1 2.475-1t2.475 1q1 1 1 2.475t-1 2.475q-1 1-2.475 1t-2.475-1Zm-.75 19.15 5.45-5.45-.8-3.95q-1.6 1.15-3.35 2.175T25.5 33.55Zm16.3-34.7q.45 6.8-1.7 12.4-2.15 5.6-7.1 10.55l-.1.1-.1.1 1.1 5.5q.15.75-.075 1.45-.225.7-.775 1.25l-8.55 8.6-4.25-9.9-8.5-8.5-9.9-4.25 8.6-8.55q.55-.55 1.25-.775.7-.225 1.45-.075l5.5 1.1q.05-.05.1-.075.05-.025.1-.075 4.95-4.95 10.55-7.125 5.6-2.175 12.4-1.725Zm-36.6 27.6Q9.2 30 11.725 29.975 14.25 29.95 16 31.7q1.75 1.75 1.725 4.275Q17.7 38.5 15.95 40.25q-1.3 1.3-4.025 2.15Q9.2 43.25 3.75 44q.75-5.45 1.575-8.2.825-2.75 2.125-4.05Zm2.1 2.15q-.7.75-1.25 2.35t-.95 4.1q2.5-.4 4.1-.95 1.6-.55 2.35-1.25.95-.85.975-2.125.025-1.275-.875-2.225-.95-.9-2.225-.875-1.275.025-2.125.975Z" />
                    </svg>
                </div>
            )
        }
        else if ((!draftData.social_accounts || draftData.social_accounts.length === 0) && !draftData.cai_generated) {
            return (
                <div className="account_profile_photo" >
                    <img className="profile_picture" src={SPKNoProfile} alt="Profile" />
                </div>
            )
        }
        else {

            let accountData = draftData.social_accounts[0];


            switch (accountData.media) {
                case "Facebook":
                    let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                    if(accountData.fb_group){
                        fb_picture_url=FBGroupIcon
                    }
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={fb_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={FBicon} alt="Media" /> : ""}
                        </div>
                    )
                case "Twitter":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={TWicon} alt="Media" /> : ""}
                        </div>
                    )
                case "LinkedIn":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={LKicon} alt="Media" /> : ""}
                        </div>
                    )
                case "Instagram":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={IGicon} alt="Media" /> : ""}
                        </div>
                    )
                case "Pinterest":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={PNicon} alt="Media" /> : ""}
                        </div>
                    )
                case "GMB":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={GMBicon} alt="Media" /> : ""}
                        </div>
                    )
                case "TikTok":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={TKicon} alt="Media" /> : ""}
                        </div>
                    )
                case "Youtube":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={YTicon} alt="Media" /> : ""}
                        </div>
                    )
                default:
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={SPKNoProfile} alt="Profile" />
                        </div>
                    )
            }
        }
    }

    const hashtagsFormat = (hashtag) => {
        const formattedText = '<span class="classic_tags">' + hashtag + '</span>';
        return formattedText
    }

    const formatCaption = (postData) => {
        let text = postData.text + (postData.tags ? '\n' + postData.tags : '');
        let formatted_text = text;//`${text.toString()}`;
        const regex = /#\w*/g;
        let new_text = formatted_text.replace(regex, hashtagsFormat);


        return new_text.replace(/\r\n|\r|\n/g, "<br />") + '<br />';
        // return new_text;
    }

    const formatFeed = (postData) => {

        let formatted_link = '';
        if (postData.feed_url.length > 30) {
            formatted_link = " " + postData.feed_url.substring(0, 30) + "... ";
        }
        else {
            formatted_link = " " + postData.feed_url;
        }


        return `<svg viewBox="0 0 24 24">
        <path fill="current" d="M6.18,15.64A2.18,2.18 0 0,1 8.36,17.82C8.36,19 7.38,20 6.18,20C5,20 4,19 4,17.82A2.18,2.18 0 0,1 6.18,15.64M4,4.44A15.56,15.56 0 0,1 19.56,20H16.73A12.73,12.73 0 0,0 4,7.27V4.44M4,10.1A9.9,9.9 0 0,1 13.9,20H11.07A7.07,7.07 0 0,0 4,12.93V10.1Z" />
    </svg> ${formatted_link}`
    }

    const optimizedMediaURL = (url) => {
        if (url.includes("tr:")) {
            return url;
        }
        const url_options = "tr:n-ik_ml_thumbnail";
        // const url_options = "tr:h-150,pr-true";
        let arrayedURL = url.split("/");
        arrayedURL.splice(arrayedURL.length - 1, 0, url_options);
        const optimized_url = arrayedURL.join('/');
        return optimized_url;
    }

    const [allDrafts, setAllDrafts] = useState([])

    const fetchAllDraftsForDashboard = async () => {


        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id,
            limit: 5
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/get_drafts", payload, {
                headers: headers,
            })
            .then((response) => {
                setAllDrafts(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }


    useEffect(() => {
        fetchAllDraftsForDashboard();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className='row'>
            {allDrafts && allDrafts.length > 0 ?
                allDrafts.map((draft, i) => {
                    return (
                        <div key={draft._id} className={draft.draft ? 'draft_post draft draft_dashboard' : 'draft_post draft_dashboard'} onClick={() => goToDrafts()}>
                            <div className='account_header'>
                                {checkAccountPhoto(draft, true)}
                                <div className="profile_text">
                                    <span className="acc_name">{draft.social_accounts.length > 0 ? draft.social_accounts[0].social_name : draft.cai_generated ? t('ai_post') : t('draft_post')}</span>
                                    <p className="p-no-margin p-mini">{draft.date ? `${moment(draft.date).tz(draft.timezone && draft.timezone.value ? draft.timezone.value : Intl.DateTimeFormat().resolvedOptions().timeZone).format('Do MMM YY - HH.mm')} ${draft.timezone && draft.timezone.label ? draft.timezone.label.split(' ')[0] : Intl.DateTimeFormat().resolvedOptions().timeZone}` : ''}</p>
                                </div>
                            </div>
                            {draft.template ? <p className='text-red'>Template</p> : ''}
                            {draft.medias && draft.medias.length > 0 ?
                                <div className='draft-view-media'>
                                    {draft.medias[0].type === 'image' ?
                                        <img src={optimizedMediaURL(draft.medias[0].file_url)} alt="" />
                                        :
                                        <div className='draft-view-video-container'>
                                            {draft.medias[0].thumbnail_url ?
                                                <img key={draft.medias[0]._id} src={draft.medias[0].thumbnail_url} alt="" />
                                                :
                                                <video key={draft.medias[0]._id}>
                                                    <source src={draft.medias[0].file_url} />
                                                </video>
                                            }
                                            {/* <video>
                                                <source src={draft.medias[0].file_url} />
                                            </video> */}
                                            <div className='video_icon'>
                                                <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                                    <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                                </svg>
                                            </div>
                                            {/* <div className="video_overlay">
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                                </svg>
                                            </div> */}
                                        </div>
                                    }
                                </div>
                                :
                                draft.lk_poll && draft.lk_poll.display && draft.lk_poll.question && draft.lk_poll.question.length > 0 && draft.lk_poll.choices && draft.lk_poll.choices.length > 1 ?
                                    <div className="lk_poll_block">
                                        <div className='lk_poll_question'>{draft.lk_poll.question}</div>
                                        {draft.lk_poll.choices.map((choice, c) => {
                                            if (choice.length > 0) {
                                                return (
                                                    <div key={`choice_${c}`} className='lk_poll_choice_item'>{choice}</div>
                                                )
                                            }

                                        })}
                                    </div>
                                    : ''
                            }
                            <div className='draft-view-caption' dangerouslySetInnerHTML={{ __html: draft.feed_url ? formatFeed(draft) : formatCaption(draft) }}></div>
                        </div>
                    )
                })
                :
                <p className="text-grey">{t('no_drafts')}</p>
            }
        </div>
    )
}

export default DashboardCoDrafts