import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";

const DashboardCoActivity = ({ authTokens, headers }) => {
    const { t } = useTranslation(['dashboard']);

    const [analytics, setAnalytics] = useState({});


    const formatNumber = (value) => {
        // return value;

        if (value / 1000000 > 1) {
            return `${Number(value / 1000000).toFixed(2)} M`
        }
        else if (value / 1000 > 1) {
            return `${Number(value / 1000).toFixed(2)} k`
        }
        else {
            return value;
        }
    }

    const getDashboardAnalytics = async () => {
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id,
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/analytics/get_analytics_dashboard", payload, {
                headers: headers,
            })
            .then((response) => {
                setAnalytics(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        getDashboardAnalytics();
    }, [])

    return (
        <div>
            <div className='row row_spaced'>
                <div className='dashboard_activity_card'>
                    <div className='data'>
                        <div className='data_sub'>
                            <svg viewBox="0 0 24 24">
                                <path fill="current" d="M19 5V19H5V5H19M21 3H3V21H21V3M17 17H7V16H17V17M17 15H7V14H17V15M17 12H7V7H17V12Z" />
                            </svg>
                            {t('posts_published_7')}
                        </div>
                        <div className='main_data'>
                            {analytics.last_7_published_post ? formatNumber(analytics.last_7_published_post) : 0}
                            {analytics.previous_7_published_post ?
                                <span className={analytics.previous_7_published_post > analytics.last_7_published_post ? 'decrease' : 'increase'}>
                                    {analytics.previous_7_published_post > analytics.last_7_published_post ?
                                        <svg viewBox="0 96 960 960">
                                            <path fill="current" d="M480 896 160 576l42-42 248 248V256h60v526l248-248 42 42-320 320Z" />
                                        </svg>
                                        :
                                        <svg viewBox="0 96 960 960">
                                            <path fill="current" d="M450 896V370L202 618l-42-42 320-320 320 320-42 42-248-248v526h-60Z" />
                                        </svg>
                                    }
                                    {Math.round((analytics.last_7_published_post / analytics.previous_7_published_post - 1) * 100).toFixed(0)}%
                                </span>
                                : ''}
                        </div>
                    </div>
                </div>
                <div className='dashboard_activity_card'>
                    <div className='data'>
                        <div className='data_sub'>
                            <svg viewBox="0 0 24 24">
                                <path fill="current" d="M12 5V8.8L15.2 12H19V15.8L21 17.8V9L15 3H6.2L8.2 5H12M14 4.5L19.5 10H14V4.5M20.7 20.05L20.7 20.04L19 18.34V18.35L12.66 12H12.66L12 11.34V11.35L6.14 5.5L2.39 1.73L1.11 3L3 4.9C3 4.93 3 4.97 3 5V19C3 20.1 3.89 21 5 21H19C19.03 21 19.07 21 19.1 21L20.84 22.73L22.11 21.46L20.7 20.05M5 19V6.89L17.11 19H5Z" />
                            </svg>
                            {t('failed_posts_7')}
                        </div>
                        <div className='main_data'>
                            {analytics.last_7_failed_posts ? analytics.last_7_failed_posts : 0}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row row_spaced'>
                {analytics.last_7_impressions ?
                    <>
                        <div className='dashboard_activity_card'>
                            <div className='data'>
                                <div className='data_sub'>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" />
                                    </svg>
                                    {t('views_7')}
                                </div>
                                <div className='main_data'>
                                    {analytics.last_7_impressions ? formatNumber(analytics.last_7_impressions) : 0}
                                    {analytics.previous_7_impressions ?
                                        <span className={analytics.previous_7_impressions > analytics.last_7_impressions ? 'decrease' : 'increase'}>
                                            {analytics.previous_7_impressions > analytics.last_7_impressions ?
                                                <svg viewBox="0 96 960 960">
                                                    <path fill="current" d="M480 896 160 576l42-42 248 248V256h60v526l248-248 42 42-320 320Z" />
                                                </svg>
                                                :
                                                <svg viewBox="0 96 960 960">
                                                    <path fill="current" d="M450 896V370L202 618l-42-42 320-320 320 320-42 42-248-248v526h-60Z" />
                                                </svg>
                                            }
                                            {Math.round((analytics.last_7_impressions / analytics.previous_7_impressions - 1) * 100).toFixed(0)}%
                                        </span>
                                        : ''}
                                </div>
                            </div>
                        </div>
                        <div className='dashboard_activity_card'>
                            <div className='data'>
                                <div className='data_sub'>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M19 17V19H7V17S7 13 13 13 19 17 19 17M16 8A3 3 0 1 0 13 11A3 3 0 0 0 16 8M19.2 13.06A5.6 5.6 0 0 1 21 17V19H24V17S24 13.55 19.2 13.06M18 5A2.91 2.91 0 0 0 17.11 5.14A5 5 0 0 1 17.11 10.86A2.91 2.91 0 0 0 18 11A3 3 0 0 0 18 5M7.34 8.92L8.5 10.33L3.75 15.08L1 12.08L2.16 10.92L3.75 12.5L7.34 8.92" />
                                    </svg>
                                    {t('reach_7')}
                                </div>
                                <div className='main_data'>
                                    {analytics.last_7_reached ? formatNumber(analytics.last_7_reached) : 0}
                                    {analytics.previous_7_reached ?
                                        <span className={analytics.previous_7_reached > analytics.last_7_reached ? 'decrease' : 'increase'}>
                                            {analytics.previous_7_reached > analytics.last_7_reached ?
                                                <svg viewBox="0 96 960 960">
                                                    <path fill="current" d="M480 896 160 576l42-42 248 248V256h60v526l248-248 42 42-320 320Z" />
                                                </svg>
                                                :
                                                <svg viewBox="0 96 960 960">
                                                    <path fill="current" d="M450 896V370L202 618l-42-42 320-320 320 320-42 42-248-248v526h-60Z" />
                                                </svg>
                                            }
                                            {Math.round((analytics.last_7_reached / analytics.previous_7_reached - 1) * 100).toFixed(0)}%
                                        </span>
                                        : ''}
                                </div>
                            </div>
                        </div>
                        <div className='dashboard_activity_card'>
                            <div className='data'>
                                <div className='data_sub'>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M23,10C23,8.89 22.1,8 21,8H14.68L15.64,3.43C15.66,3.33 15.67,3.22 15.67,3.11C15.67,2.7 15.5,2.32 15.23,2.05L14.17,1L7.59,7.58C7.22,7.95 7,8.45 7,9V19A2,2 0 0,0 9,21H18C18.83,21 19.54,20.5 19.84,19.78L22.86,12.73C22.95,12.5 23,12.26 23,12V10M1,21H5V9H1V21Z" />
                                    </svg>
                                    {t('interactions_7')}
                                </div>
                                <div className='main_data'>
                                    {analytics.last_7_engagements ? formatNumber(analytics.last_7_engagements) : 0}
                                    {analytics.previous_7_engagements ?
                                        <span className={analytics.previous_7_engagements > analytics.last_7_engagements ? 'decrease' : 'increase'}>
                                            {analytics.previous_7_engagements > analytics.last_7_engagements ?
                                                <svg viewBox="0 96 960 960">
                                                    <path fill="current" d="M480 896 160 576l42-42 248 248V256h60v526l248-248 42 42-320 320Z" />
                                                </svg>
                                                :
                                                <svg viewBox="0 96 960 960">
                                                    <path fill="current" d="M450 896V370L202 618l-42-42 320-320 320 320-42 42-248-248v526h-60Z" />
                                                </svg>
                                            }
                                            {Math.round((analytics.last_7_engagements / analytics.previous_7_engagements - 1) * 100).toFixed(0)}%
                                        </span>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </>
                    : ''
                }
                {/* <div className='dashboard_activity_card'>
            <div className='data'>
                    <div className='data_sub'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M19 5V19H5V5H19M21 3H3V21H21V3M17 17H7V16H17V17M17 15H7V14H17V15M17 12H7V7H17V12Z" />
                        </svg>
                        Posts published
                    </div>
                    <div className='main_data'>
                        7
                        <span className='increase'>+30%</span>
                    </div>
                </div>
                <div className='data'>
                    28
                    <div className='data_sub'>New followers</div>
                </div>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M19 17V19H7V17S7 13 13 13 19 17 19 17M16 8A3 3 0 1 0 13 11A3 3 0 0 0 16 8M19.2 13.06A5.6 5.6 0 0 1 21 17V19H24V17S24 13.55 19.2 13.06M18 5A2.91 2.91 0 0 0 17.11 5.14A5 5 0 0 1 17.11 10.86A2.91 2.91 0 0 0 18 11A3 3 0 0 0 18 5M8 10H5V7H3V10H0V12H3V15H5V12H8Z" />
                </svg>
            </div>
            <div className='dashboard_activity_card'>
                <div className='data'>
                    5,123
                    <div className='data_sub'>New likes</div>
                </div>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z" />
                </svg>
            </div>
            <div className='dashboard_activity_card'>
                <div className='data'>
                    89
                    <div className='data_sub'>New comments</div>
                </div>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9Z" />
                </svg>
            </div>
            <div className='dashboard_activity_card'>
                <div className='data'>
                    8
                    <div className='data_sub'>New reviews</div>
                </div>
                <svg viewBox="0 0 24 24"><path fill="current" d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
                </svg>
            </div> */}
            </div>
        </div>
    )
}

export default DashboardCoActivity