import React from 'react'

const CAICoIconPicker = ({ templateData, setTemplateData, setShowIconPicker, setTemplates }) => {
    return (
        <div className='cai_icon_picker'>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'arrow_right' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'cursor' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M10.76,8.69A0.76,0.76 0 0,0 10,9.45V20.9C10,21.32 10.34,21.66 10.76,21.66C10.95,21.66 11.11,21.6 11.24,21.5L13.15,19.95L14.81,23.57C14.94,23.84 15.21,24 15.5,24C15.61,24 15.72,24 15.83,23.92L18.59,22.64C18.97,22.46 19.15,22 18.95,21.63L17.28,18L19.69,17.55C19.85,17.5 20,17.43 20.12,17.29C20.39,16.97 20.35,16.5 20,16.21L11.26,8.86L11.25,8.87C11.12,8.76 10.95,8.69 10.76,8.69M15,10V8H20V10H15M13.83,4.76L16.66,1.93L18.07,3.34L15.24,6.17L13.83,4.76M10,0H12V5H10V0M3.93,14.66L6.76,11.83L8.17,13.24L5.34,16.07L3.93,14.66M3.93,3.34L5.34,1.93L8.17,4.76L6.76,6.17L3.93,3.34M7,10H2V8H7V10" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'circle_right' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M20.03 12C20.03 7.59 16.41 3.97 12 3.97C7.59 3.97 3.97 7.59 3.97 12C3.97 16.41 7.59 20.03 12 20.03C16.41 20.03 20.03 16.41 20.03 12M22 12C22 17.54 17.54 22 12 22C6.46 22 2 17.54 2 12C2 6.46 6.46 2 12 2C17.54 2 22 6.46 22 12M13.54 13V16L17.5 12L13.54 8V11H6.5V13" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'mail' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'call' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'arrow_down' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'circle_down' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M11,6H13V14L16.5,10.5L17.92,11.92L12,17.84L6.08,11.92L7.5,10.5L11,14V6M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'star' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'check' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'vertical' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'swipe_left' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M20.11,3.89L22,2V7H17L19.08,4.92C18.55,4.23 17.64,3.66 16.36,3.19C15.08,2.72 13.63,2.5 12,2.5C10.38,2.5 8.92,2.72 7.64,3.19C6.36,3.66 5.45,4.23 4.92,4.92L7,7H2V2L3.89,3.89C4.64,3 5.74,2.31 7.2,1.78C8.65,1.25 10.25,1 12,1C13.75,1 15.35,1.25 16.8,1.78C18.26,2.31 19.36,3 20.11,3.89M19.73,16.27V16.45L19,21.7C18.92,22.08 18.76,22.39 18.5,22.64C18.23,22.89 17.91,23 17.53,23H10.73C10.36,23 10,22.86 9.7,22.55L4.73,17.63L5.53,16.83C5.75,16.61 6,16.5 6.33,16.5H6.56L10,17.25V6.5C10,6.11 10.13,5.76 10.43,5.46C10.73,5.16 11.08,5 11.5,5C11.89,5 12.24,5.16 12.54,5.46C12.84,5.76 13,6.11 13,6.5V12.5H13.78C13.88,12.5 14.05,12.55 14.3,12.61L18.84,14.86C19.44,15.14 19.73,15.61 19.73,16.27Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'expand' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M10,21V19H6.41L10.91,14.5L9.5,13.09L5,17.59V14H3V21H10M14.5,10.91L19,6.41V10H21V3H14V5H17.59L13.09,9.5L14.5,10.91Z" />
                </svg>
            </div>

            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'smile' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8C16.3,8 17,8.7 17,9.5M12,17.23C10.25,17.23 8.71,16.5 7.81,15.42L9.23,14C9.68,14.72 10.75,15.23 12,15.23C13.25,15.23 14.32,14.72 14.77,14L16.19,15.42C15.29,16.5 13.75,17.23 12,17.23Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'thumb' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M5,9V21H1V9H5M9,21A2,2 0 0,1 7,19V9C7,8.45 7.22,7.95 7.59,7.59L14.17,1L15.23,2.06C15.5,2.33 15.67,2.7 15.67,3.11L15.64,3.43L14.69,8H21C22.11,8 23,8.9 23,10V12C23,12.26 22.95,12.5 22.86,12.73L19.84,19.78C19.54,20.5 18.83,21 18,21H9M9,19H18.03L21,12V10H12.21L13.34,4.68L9,9.03V19Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'world' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M17.9,17.39C17.64,16.59 16.89,16 16,16H15V13A1,1 0 0,0 14,12H8V10H10A1,1 0 0,0 11,9V7H13A2,2 0 0,0 15,5V4.59C17.93,5.77 20,8.64 20,12C20,14.08 19.2,15.97 17.9,17.39M11,19.93C7.05,19.44 4,16.08 4,12C4,11.38 4.08,10.78 4.21,10.21L9,15V16A2,2 0 0,0 11,18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'eco' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M8.04 16.34C9.05 13.83 10.19 10.96 14.5 10C14.5 10 9.5 10 7.88 14.63C7.88 14.63 7 13.75 7 12.75S8 9.63 10.5 9.13C11.21 9 12 8.87 12.78 8.76C14.75 8.5 16.64 8.22 17 7.5C17 7.5 15.5 16 10 16C9.82 16 9.57 15.94 9.33 15.85L8.86 17L7.91 16.67L8.04 16.34M12 4C16.41 4 20 7.59 20 12S16.41 20 12 20 4 16.41 4 12 7.59 4 12 4M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'rocket' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M12 2C12 2 7 4 7 12C7 15.1 7.76 17.75 8.67 19.83C9 20.55 9.71 21 10.5 21H13.5C14.29 21 15 20.55 15.33 19.83C16.25 17.75 17 15.1 17 12C17 4 12 2 12 2M13.5 19H10.5C9.5 16.76 9 14.41 9 12C9 7.36 10.9 5.2 12 4.33C13.1 5.2 15 7.36 15 12C15 14.41 14.5 16.76 13.5 19M20 22L16.14 20.45C16.84 18.92 17.34 17.34 17.65 15.73M7.86 20.45L4 22L6.35 15.73C6.66 17.34 7.16 18.92 7.86 20.45M12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'info' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'schedule' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'help' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'touch' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M10,9A1,1 0 0,1 11,8A1,1 0 0,1 12,9V13.47L13.21,13.6L18.15,15.79C18.68,16.03 19,16.56 19,17.14V21.5C18.97,22.32 18.32,22.97 17.5,23H11C10.62,23 10.26,22.85 10,22.57L5.1,18.37L5.84,17.6C6.03,17.39 6.3,17.28 6.58,17.28H6.8L10,19V9M11,5A4,4 0 0,1 15,9C15,10.5 14.2,11.77 13,12.46V11.24C13.61,10.69 14,9.89 14,9A3,3 0 0,0 11,6A3,3 0 0,0 8,9C8,9.89 8.39,10.69 9,11.24V12.46C7.8,11.77 7,10.5 7,9A4,4 0 0,1 11,5Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'notifications' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M10 21H14C14 22.1 13.1 23 12 23S10 22.1 10 21M21 19V20H3V19L5 17V11C5 7.9 7 5.2 10 4.3V4C10 2.9 10.9 2 12 2S14 2.9 14 4V4.3C17 5.2 19 7.9 19 11V17L21 19M17 11C17 8.2 14.8 6 12 6S7 8.2 7 11V18H17V11Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'chat' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2M20 16H5.2L4 17.2V4H20V16Z" />
                </svg>
            </div>
            <div className='cai_icon' onClick={() => { setTemplateData({ ...templateData, cta_icon: 'cart' }); setShowIconPicker(false); setTemplates([]); }}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M17,18A2,2 0 0,1 19,20A2,2 0 0,1 17,22C15.89,22 15,21.1 15,20C15,18.89 15.89,18 17,18M1,2H4.27L5.21,4H20A1,1 0 0,1 21,5C21,5.17 20.95,5.34 20.88,5.5L17.3,11.97C16.96,12.58 16.3,13 15.55,13H8.1L7.2,14.63L7.17,14.75A0.25,0.25 0 0,0 7.42,15H19V17H7C5.89,17 5,16.1 5,15C5,14.65 5.09,14.32 5.24,14.04L6.6,11.59L3,4H1V2M7,18A2,2 0 0,1 9,20A2,2 0 0,1 7,22C5.89,22 5,21.1 5,20C5,18.89 5.89,18 7,18M16,11L18.78,6H6.14L8.5,11H16Z" />
                </svg>
            </div>
        </div>
    )
}

export default CAICoIconPicker