import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./sidebar_cesam.css";

const SidebarCESAM = (props) => {
  const { t } = useTranslation(["sidebar"]);

  const location = useLocation();

  const [selectedMainCategory, setSelectedMainCategory] = useState("");

  const changeMainCategory = (category) => {
    setSelectedMainCategory(category);
  };

  useEffect(() => {
    if (location.pathname.includes("/post/")) {
      setSelectedMainCategory("post");
    } else if (location.pathname.includes("/content_ai")) {
      setSelectedMainCategory("post");
    } else if (location.pathname.includes("/calendar")) {
      setSelectedMainCategory("schedule");
    } else if (location.pathname.includes("/grid")) {
      setSelectedMainCategory("schedule");
    } else if (location.pathname.includes("/drafts")) {
      setSelectedMainCategory("schedule");
    } else if (location.pathname.includes("/engage")) {
      setSelectedMainCategory("engagement");
    } else if (location.pathname.includes("/analytics")) {
      setSelectedMainCategory("analytics");
    } else if (location.pathname.includes("/sm_accounts")) {
      setSelectedMainCategory("manage");
    } else if (location.pathname.includes("/library")) {
      setSelectedMainCategory("manage");
    } else if (location.pathname.includes("/team")) {
      setSelectedMainCategory("manage");
    } else if (location.pathname.includes("/third_parties")) {
      setSelectedMainCategory("manage");
    } else if (location.pathname.includes("/lists")) {
      setSelectedMainCategory("manage");
    } else {
      setSelectedMainCategory("");
    }
  }, [location]);

  useEffect(() => {
    props.checkMessageNotification();
  }, []);

  return (
    <div className="big-sidebar">
      <ul>
        <li className={selectedMainCategory === "post" ? "selected" : ""}>
          <div
            className="main-category"
            onClick={() => changeMainCategory("post")}
          >
            {/* <svg viewBox="0 0 24 24">
                            <path fill="current" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                        </svg> */}
            <svg viewBox="0 0 48 48">
              <path
                fill="current"
                d="M9 39h2.2l22.15-22.15-2.2-2.2L9 36.8Zm30.7-24.3-6.4-6.4 2.1-2.1q.85-.85 2.1-.85t2.1.85l2.2 2.2q.85.85.85 2.1t-.85 2.1Zm-2.1 2.1L12.4 42H6v-6.4l25.2-25.2Zm-5.35-1.05-1.1-1.1 2.2 2.2Z"
              />
            </svg>
            {t("create")}
            <svg viewBox="0 0 48 48" className="nav-expand">
              <path
                fill="current"
                d="m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z"
              />
            </svg>
          </div>
          <ul className="new_post_list">
            <Link to="/post/new_post">
              <li
                className={
                  location.pathname.includes("/post/new_post")
                    ? "selected_grey"
                    : ""
                }
              >
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h19.7v3H9v30h30V19.3h3V39q0 1.2-.9 2.1-.9.9-2.1.9Zm7.05-7.85v-3H32v3Zm0-6.35v-3H32v3Zm0-6.35v-3H32v3ZM34.6 17.8v-4.4h-4.4v-3h4.4V6h3v4.4H42v3h-4.4v4.4Z"
                  />
                </svg>
                {t("post")}
              </li>
            </Link>
            <Link to="/content_ai">
              <li
                className={
                  location.pathname.includes("/content_ai")
                    ? "selected_grey"
                    : ""
                }
              >
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="m9.35 20.45 5.3 2.25q.9-1.8 1.925-3.55Q17.6 17.4 18.75 15.8L14.8 15Zm7.7 4.05 6.65 6.65q2.85-1.3 5.35-2.95 2.5-1.65 4.05-3.2 4.05-4.05 5.95-8.3 1.9-4.25 2.05-9.6-5.35.15-9.6 2.05t-8.3 5.95q-1.55 1.55-3.2 4.05-1.65 2.5-2.95 5.35Zm11.45-4.8q-1-1-1-2.475t1-2.475q1-1 2.475-1t2.475 1q1 1 1 2.475t-1 2.475q-1 1-2.475 1t-2.475-1Zm-.75 19.15 5.45-5.45-.8-3.95q-1.6 1.15-3.35 2.175T25.5 33.55Zm16.3-34.7q.45 6.8-1.7 12.4-2.15 5.6-7.1 10.55l-.1.1-.1.1 1.1 5.5q.15.75-.075 1.45-.225.7-.775 1.25l-8.55 8.6-4.25-9.9-8.5-8.5-9.9-4.25 8.6-8.55q.55-.55 1.25-.775.7-.225 1.45-.075l5.5 1.1q.05-.05.1-.075.05-.025.1-.075 4.95-4.95 10.55-7.125 5.6-2.175 12.4-1.725Zm-36.6 27.6Q9.2 30 11.725 29.975 14.25 29.95 16 31.7q1.75 1.75 1.725 4.275Q17.7 38.5 15.95 40.25q-1.3 1.3-4.025 2.15Q9.2 43.25 3.75 44q.75-5.45 1.575-8.2.825-2.75 2.125-4.05Zm2.1 2.15q-.7.75-1.25 2.35t-.95 4.1q2.5-.4 4.1-.95 1.6-.55 2.35-1.25.95-.85.975-2.125.025-1.275-.875-2.225-.95-.9-2.225-.875-1.275.025-2.125.975Z"
                  />
                </svg>

                {t("ai_post")}
              </li>
            </Link>
            <Link to="/post/thread">
              <li
                className={
                  location.pathname.includes("/post/thread")
                    ? "selected_grey"
                    : ""
                }
              >
                {/* <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
                                </svg> */}
                <svg viewBox="0 0 681 645" fill="none">
                  <path
                    d="M90.2238 87L284.406 346.644L89 557.74H132.982L304.063 372.918L442.287 557.74H591.948L386.835 283.495L568.72 87H524.739L367.187 257.212L239.884 87H90.2238ZM154.901 119.402H223.654L527.262 525.347H458.509L154.901 119.402Z"
                    fill="current"
                  />
                </svg>
                {t("thread")}
              </li>
            </Link>
            <Link to="/post/pins">
              <li
                className={
                  location.pathname.includes("/post/pins")
                    ? "selected_grey"
                    : ""
                }
              >
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M9.04,21.54C10,21.83 10.97,22 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12C2,16.25 4.67,19.9 8.44,21.34C8.35,20.56 8.26,19.27 8.44,18.38L9.59,13.44C9.59,13.44 9.3,12.86 9.3,11.94C9.3,10.56 10.16,9.53 11.14,9.53C12,9.53 12.4,10.16 12.4,10.97C12.4,11.83 11.83,13.06 11.54,14.24C11.37,15.22 12.06,16.08 13.06,16.08C14.84,16.08 16.22,14.18 16.22,11.5C16.22,9.1 14.5,7.46 12.03,7.46C9.21,7.46 7.55,9.56 7.55,11.77C7.55,12.63 7.83,13.5 8.29,14.07C8.38,14.13 8.38,14.21 8.35,14.36L8.06,15.45C8.06,15.62 7.95,15.68 7.78,15.56C6.5,15 5.76,13.18 5.76,11.71C5.76,8.55 8,5.68 12.32,5.68C15.76,5.68 18.44,8.15 18.44,11.43C18.44,14.87 16.31,17.63 13.26,17.63C12.29,17.63 11.34,17.11 11,16.5L10.33,18.87C10.1,19.73 9.47,20.88 9.04,21.57V21.54Z"
                  />
                </svg>
                {t("pin")}
              </li>
            </Link>
            <Link to="/post/burst_post">
              <li
                className={
                  location.pathname.includes("/post/burst_post")
                    ? "selected_grey"
                    : ""
                }
              >
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M1,5H3V19H1V5M5,5H7V19H5V5M22,5H10A1,1 0 0,0 9,6V18A1,1 0 0,0 10,19H22A1,1 0 0,0 23,18V6A1,1 0 0,0 22,5M11,17L13.5,13.85L15.29,16L17.79,12.78L21,17H11Z"
                  />
                </svg>

                {t("burst_posts")}
              </li>
            </Link>
            <Link to="/post/rss">
              <li
                className={
                  location.pathname.includes("/post/rss") ? "selected_grey" : ""
                }
              >
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M6.18,15.64A2.18,2.18 0 0,1 8.36,17.82C8.36,19 7.38,20 6.18,20C5,20 4,19 4,17.82A2.18,2.18 0 0,1 6.18,15.64M4,4.44A15.56,15.56 0 0,1 19.56,20H16.73A12.73,12.73 0 0,0 4,7.27V4.44M4,10.1A9.9,9.9 0 0,1 13.9,20H11.07A7.07,7.07 0 0,0 4,12.93V10.1Z"
                  />
                </svg>

                {t("rss")}
              </li>
            </Link>
          </ul>
        </li>
        <li className={selectedMainCategory === "schedule" ? "selected" : ""}>
          <div
            className="main-category"
            onClick={() => changeMainCategory("schedule")}
          >
            {/* <svg viewBox="0 0 24 24">
                            <path fill="current" d="M9,10V12H7V10H9M13,10V12H11V10H13M17,10V12H15V10H17M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H6V1H8V3H16V1H18V3H19M19,19V8H5V19H19M9,14V16H7V14H9M13,14V16H11V14H13M17,14V16H15V14H17Z" />
                        </svg> */}

            <svg viewBox="0 0 48 48">
              <path
                fill="current"
                d="M34.55 44q-3.9 0-6.675-2.775T25.1 34.55q0-3.9 2.775-6.675T34.55 25.1q3.9 0 6.675 2.775T44 34.55q0 3.9-2.775 6.675T34.55 44Zm2.9-4.4 1.4-1.4-3.75-3.75v-5.6h-1.95v6.3ZM9 41.3q-1.25 0-2.125-.875T6 38.3V9.2q0-1.25.775-2.125T8.1 6.2h10.1q.35-1.75 1.725-2.875T23.1 2.2q1.8 0 3.175 1.125Q27.65 4.45 28 6.2h10.1q1.25 0 2.125.875T41.1 9.2v14.75q-.75-.45-1.475-.775-.725-.325-1.525-.575V9.2h-5.3v6.5H14.3V9.2H9v29.1h13.65q.25.75.6 1.475.35.725.85 1.525ZM24 9q.85 0 1.425-.575Q26 7.85 26 7q0-.85-.575-1.425Q24.85 5 24 5q-.85 0-1.425.575Q22 6.15 22 7q0 .85.575 1.425Q23.15 9 24 9Z"
              />
            </svg>

            {t("schedule")}
            <svg viewBox="0 0 48 48" className="nav-expand">
              <path
                fill="current"
                d="m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z"
              />
            </svg>
          </div>
          <ul className="calendar_list">
            <Link to="/calendar/monthly">
              <li
                className={
                  location.pathname.includes("/calendar") ? "selected_grey" : ""
                }
              >
                {/* <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M9,10V12H7V10H9M13,10V12H11V10H13M17,10V12H15V10H17M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H6V1H8V3H16V1H18V3H19M19,19V8H5V19H19M9,14V16H7V14H9M13,14V16H11V14H13M17,14V16H15V14H17Z" />
                                </svg> */}
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="M9 44q-1.2 0-2.1-.9Q6 42.2 6 41V10q0-1.2.9-2.1Q7.8 7 9 7h3.25V4h3.25v3h17V4h3.25v3H39q1.2 0 2.1.9.9.9.9 2.1v31q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V19.5H9V41Zm0-24.5h30V10H9Zm0 0V10v6.5ZM24 28q-.85 0-1.425-.575Q22 26.85 22 26q0-.85.575-1.425Q23.15 24 24 24q.85 0 1.425.575Q26 25.15 26 26q0 .85-.575 1.425Q24.85 28 24 28Zm-8 0q-.85 0-1.425-.575Q14 26.85 14 26q0-.85.575-1.425Q15.15 24 16 24q.85 0 1.425.575Q18 25.15 18 26q0 .85-.575 1.425Q16.85 28 16 28Zm16 0q-.85 0-1.425-.575Q30 26.85 30 26q0-.85.575-1.425Q31.15 24 32 24q.85 0 1.425.575Q34 25.15 34 26q0 .85-.575 1.425Q32.85 28 32 28Zm-8 8q-.85 0-1.425-.575Q22 34.85 22 34q0-.85.575-1.425Q23.15 32 24 32q.85 0 1.425.575Q26 33.15 26 34q0 .85-.575 1.425Q24.85 36 24 36Zm-8 0q-.85 0-1.425-.575Q14 34.85 14 34q0-.85.575-1.425Q15.15 32 16 32q.85 0 1.425.575Q18 33.15 18 34q0 .85-.575 1.425Q16.85 36 16 36Zm16 0q-.85 0-1.425-.575Q30 34.85 30 34q0-.85.575-1.425Q31.15 32 32 32q.85 0 1.425.575Q34 33.15 34 34q0 .85-.575 1.425Q32.85 36 32 36Z"
                  />
                </svg>
                {t("calendars")}
              </li>
            </Link>
            <Link to="/grid">
              <li
                className={
                  location.pathname.includes("/grid") ? "selected_grey" : ""
                }
              >
                {/* <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M6 1H8V3H16V1H18V3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.89 3.89 3 5 3H6V1M5 8V19H19V8H5M7 10H17V12H7V10Z" />
                                </svg> */}
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="M6 22.5V6h16.5v16.5ZM6 42V25.5h16.5V42Zm19.5-19.5V6H42v16.5Zm0 19.5V25.5H42V42ZM9 19.5h10.5V9H9Zm19.5 0H39V9H28.5Zm0 19.5H39V28.5H28.5ZM9 39h10.5V28.5H9Zm19.5-19.5Zm0 9Zm-9 0Zm0-9Z"
                  />
                </svg>
                {t("grid_view")}
              </li>
            </Link>
            <Link to="/drafts">
              <li
                className={
                  location.pathname.includes("/drafts") ? "selected_grey" : ""
                }
              >
                {/* <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M6 1H8V3H16V1H18V3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.89 3.89 3 5 3H6V1M5 8V19H19V8H5M7 10H17V12H7V10Z" />
                                </svg> */}
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="m37.55 18.2-7.75-7.75 3.5-3.5q.85-.85 2.1-.85t2.1.85l3.55 3.55q.85.85.85 2.1t-.85 2.1ZM6 42v-7.8l9.7-9.7L4.65 13.4l8.7-8.8L24.5 15.75l5.3-5.3 7.75 7.75-5.3 5.3L43.4 34.65l-8.75 8.65-11.1-11.05L13.8 42Zm11.85-19.65 4.5-4.5-3.65-3.65-2.4 2.4-2.1-2.1 2.4-2.4-3.2-3.2-4.5 4.5Zm16.7 16.75 4.5-4.5-3.2-3.2-2.4 2.4-2.1-2.1 2.4-2.4-3.65-3.65-4.5 4.5ZM9 39h3.5l20.75-20.75-3.5-3.5L9 35.5Z"
                  />
                </svg>

                {t("drafts")}
              </li>
            </Link>
          </ul>
        </li>
        <li className={selectedMainCategory === "engagement" ? "selected" : ""}>
          <Link to="/engage">
            <div
              className="main-category"
              onClick={() => changeMainCategory("engagement")}
            >
              <svg viewBox="0 0 48 48">
                <path
                  fill="current"
                  d="M12 28.05h15.65v-3H12Zm0-6.5h24v-3H12Zm0-6.5h24v-3H12ZM4 44V7q0-1.15.9-2.075Q5.8 4 7 4h34q1.15 0 2.075.925Q44 5.85 44 7v26q0 1.15-.925 2.075Q42.15 36 41 36H12Zm3-7.25L10.75 33H41V7H7ZM7 7v29.75Z"
                />
              </svg>
              {t("engage")}
              {props.showMessageNotif ? (
                <svg className="sidebar_notif_dot" viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                  />
                </svg>
              ) : (
                ""
              )}
            </div>
          </Link>
        </li>
        <li className={selectedMainCategory === "analytics" ? "selected" : ""}>
          <div
            className="main-category"
            onClick={() => changeMainCategory("analytics")}
          >
            {/* <svg viewBox="0 0 24 24">
                                <path fill="current" d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M9 17H7V10H9V17M13 17H11V7H13V17M17 17H15V13H17V17Z" />
                            </svg> */}
            <svg viewBox="0 0 48 48">
              <path
                fill="current"
                d="M10 40V28h5v12Zm12.5 0V18h5v22ZM35 40V8h5v32Z"
              />
            </svg>
            {t("analyse")}
            <svg viewBox="0 0 48 48" className="nav-expand">
              <path
                fill="current"
                d="m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z"
              />
            </svg>
          </div>
          <ul className="analytics_list">
            <Link to="/analytics_per_post">
              <li
                className={
                  location.pathname.includes("/analytics_per_post")
                    ? "selected_grey"
                    : ""
                }
              >
                {/* <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M9,10V12H7V10H9M13,10V12H11V10H13M17,10V12H15V10H17M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H6V1H8V3H16V1H18V3H19M19,19V8H5V19H19M9,14V16H7V14H9M13,14V16H11V14H13M17,14V16H15V14H17Z" />
                                </svg> */}
                {/* <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M11.5 42q-3.15 0-5.325-2.175Q4 37.65 4 34.5q0-3.15 2.175-5.325Q8.35 27 11.5 27q3.15 0 5.325 2.175Q19 31.35 19 34.5q0 3.15-2.175 5.325Q14.65 42 11.5 42Zm25.05 0q-3.15 0-5.325-2.175Q29.05 37.65 29.05 34.5q0-3.15 2.175-5.325Q33.4 27 36.55 27q3.15 0 5.325 2.175Q44.05 31.35 44.05 34.5q0 3.15-2.175 5.325Q39.7 42 36.55 42ZM11.5 39q1.85 0 3.175-1.325Q16 36.35 16 34.5q0-1.85-1.325-3.175Q13.35 30 11.5 30q-1.85 0-3.175 1.325Q7 32.65 7 34.5q0 1.85 1.325 3.175Q9.65 39 11.5 39Zm25.05 0q1.85 0 3.175-1.325Q41.05 36.35 41.05 34.5q0-1.85-1.325-3.175Q38.4 30 36.55 30q-1.85 0-3.175 1.325Q32.05 32.65 32.05 34.5q0 1.85 1.325 3.175Q34.7 39 36.55 39Zm-12.5-18.05q-3.15 0-5.325-2.175Q16.55 16.6 16.55 13.45q0-3.15 2.175-5.325Q20.9 5.95 24.05 5.95q3.15 0 5.325 2.175Q31.55 10.3 31.55 13.45q0 3.15-2.175 5.325Q27.2 20.95 24.05 20.95Zm0-3q1.85 0 3.175-1.325Q28.55 15.3 28.55 13.45q0-1.85-1.325-3.175Q25.9 8.95 24.05 8.95q-1.85 0-3.175 1.325Q19.55 11.6 19.55 13.45q0 1.85 1.325 3.175Q22.2 17.95 24.05 17.95Zm0-4.5Zm12.5 21.05Zm-25.05 0Z" />
                                </svg> */}
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="M35.8 42H13.6V16.4L27.5 2l1.95 1.55q.3.25.45.7.15.45.15 1.1v.5L27.8 16.4h14.95q1.2 0 2.1.9.9.9.9 2.1v4.1q0 .35.075.725t-.075.725l-6.3 14.5q-.45 1.05-1.475 1.8Q36.95 42 35.8 42Zm-19.2-3h19.85l6.3-14.95V19.4H24.1l2.65-12.45-10.15 10.7Zm0-21.35V39Zm-3-1.25v3H6.95V39h6.65v3H3.95V16.4Z"
                  />
                </svg>
                {t("per_post")}
              </li>
            </Link>
            <Link to="/analytics_per_account">
              <li
                className={
                  location.pathname.includes("/analytics_per_account")
                    ? "selected_grey"
                    : ""
                }
              >
                {/* <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M6 1H8V3H16V1H18V3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.89 3.89 3 5 3H6V1M5 8V19H19V8H5M7 10H17V12H7V10Z" />
                                </svg> */}
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="M6 42v-3.8l3-3V42Zm8.25 0V30.2l3-3V42Zm8.25 0V27.2l3 3.05V42Zm8.25 0V30.25l3-3V42ZM39 42V22.2l3-3V42ZM6 30.2v-4.25l14-13.9 8 8L42 6v4.25L28 24.3l-8-8Z"
                  />
                </svg>
                {t("per_account")}
              </li>
            </Link>
          </ul>
        </li>
        <li className={selectedMainCategory === "manage" ? "selected" : ""}>
          <div
            className="main-category"
            onClick={() => changeMainCategory("manage")}
          >
            {/* <svg viewBox="0 0 24 24">
                                <path fill="current" d="M15.9,18.45C17.25,18.45 18.35,17.35 18.35,16C18.35,14.65 17.25,13.55 15.9,13.55C14.54,13.55 13.45,14.65 13.45,16C13.45,17.35 14.54,18.45 15.9,18.45M21.1,16.68L22.58,17.84C22.71,17.95 22.75,18.13 22.66,18.29L21.26,20.71C21.17,20.86 21,20.92 20.83,20.86L19.09,20.16C18.73,20.44 18.33,20.67 17.91,20.85L17.64,22.7C17.62,22.87 17.47,23 17.3,23H14.5C14.32,23 14.18,22.87 14.15,22.7L13.89,20.85C13.46,20.67 13.07,20.44 12.71,20.16L10.96,20.86C10.81,20.92 10.62,20.86 10.54,20.71L9.14,18.29C9.05,18.13 9.09,17.95 9.22,17.84L10.7,16.68L10.65,16L10.7,15.31L9.22,14.16C9.09,14.05 9.05,13.86 9.14,13.71L10.54,11.29C10.62,11.13 10.81,11.07 10.96,11.13L12.71,11.84C13.07,11.56 13.46,11.32 13.89,11.15L14.15,9.29C14.18,9.13 14.32,9 14.5,9H17.3C17.47,9 17.62,9.13 17.64,9.29L17.91,11.15C18.33,11.32 18.73,11.56 19.09,11.84L20.83,11.13C21,11.07 21.17,11.13 21.26,11.29L22.66,13.71C22.75,13.86 22.71,14.05 22.58,14.16L21.1,15.31L21.15,16L21.1,16.68M6.69,8.07C7.56,8.07 8.26,7.37 8.26,6.5C8.26,5.63 7.56,4.92 6.69,4.92A1.58,1.58 0 0,0 5.11,6.5C5.11,7.37 5.82,8.07 6.69,8.07M10.03,6.94L11,7.68C11.07,7.75 11.09,7.87 11.03,7.97L10.13,9.53C10.08,9.63 9.96,9.67 9.86,9.63L8.74,9.18L8,9.62L7.81,10.81C7.79,10.92 7.7,11 7.59,11H5.79C5.67,11 5.58,10.92 5.56,10.81L5.4,9.62L4.64,9.18L3.5,9.63C3.41,9.67 3.3,9.63 3.24,9.53L2.34,7.97C2.28,7.87 2.31,7.75 2.39,7.68L3.34,6.94L3.31,6.5L3.34,6.06L2.39,5.32C2.31,5.25 2.28,5.13 2.34,5.03L3.24,3.47C3.3,3.37 3.41,3.33 3.5,3.37L4.63,3.82L5.4,3.38L5.56,2.19C5.58,2.08 5.67,2 5.79,2H7.59C7.7,2 7.79,2.08 7.81,2.19L8,3.38L8.74,3.82L9.86,3.37C9.96,3.33 10.08,3.37 10.13,3.47L11.03,5.03C11.09,5.13 11.07,5.25 11,5.32L10.03,6.06L10.06,6.5L10.03,6.94Z" />
                            </svg> */}
            <svg viewBox="0 0 24 24">
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="current"
                  d="M20.083 15.2l1.202.721a.5.5 0 0 1 0 .858l-8.77 5.262a1 1 0 0 1-1.03 0l-8.77-5.262a.5.5 0 0 1 0-.858l1.202-.721L12 20.05l8.083-4.85zm0-4.7l1.202.721a.5.5 0 0 1 0 .858L12 17.65l-9.285-5.571a.5.5 0 0 1 0-.858l1.202-.721L12 15.35l8.083-4.85zm-7.569-9.191l8.771 5.262a.5.5 0 0 1 0 .858L12 13 2.715 7.429a.5.5 0 0 1 0-.858l8.77-5.262a1 1 0 0 1 1.03 0zM12 3.332L5.887 7 12 10.668 18.113 7 12 3.332z"
                />
              </g>
            </svg>
            {t("manage")}
            <svg viewBox="0 0 48 48" className="nav-expand">
              <path
                fill="current"
                d="m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z"
              />
            </svg>
          </div>
          <ul className="manage_list">
            <Link to="/sm_accounts">
              <li
                className={
                  location.pathname.includes("/sm_accounts")
                    ? "selected_grey"
                    : ""
                }
              >
                {/* <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M9,10V12H7V10H9M13,10V12H11V10H13M17,10V12H15V10H17M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H6V1H8V3H16V1H18V3H19M19,19V8H5V19H19M9,14V16H7V14H9M13,14V16H11V14H13M17,14V16H15V14H17Z" />
                                </svg> */}
                {/* <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M11.5 42q-3.15 0-5.325-2.175Q4 37.65 4 34.5q0-3.15 2.175-5.325Q8.35 27 11.5 27q3.15 0 5.325 2.175Q19 31.35 19 34.5q0 3.15-2.175 5.325Q14.65 42 11.5 42Zm25.05 0q-3.15 0-5.325-2.175Q29.05 37.65 29.05 34.5q0-3.15 2.175-5.325Q33.4 27 36.55 27q3.15 0 5.325 2.175Q44.05 31.35 44.05 34.5q0 3.15-2.175 5.325Q39.7 42 36.55 42ZM11.5 39q1.85 0 3.175-1.325Q16 36.35 16 34.5q0-1.85-1.325-3.175Q13.35 30 11.5 30q-1.85 0-3.175 1.325Q7 32.65 7 34.5q0 1.85 1.325 3.175Q9.65 39 11.5 39Zm25.05 0q1.85 0 3.175-1.325Q41.05 36.35 41.05 34.5q0-1.85-1.325-3.175Q38.4 30 36.55 30q-1.85 0-3.175 1.325Q32.05 32.65 32.05 34.5q0 1.85 1.325 3.175Q34.7 39 36.55 39Zm-12.5-18.05q-3.15 0-5.325-2.175Q16.55 16.6 16.55 13.45q0-3.15 2.175-5.325Q20.9 5.95 24.05 5.95q3.15 0 5.325 2.175Q31.55 10.3 31.55 13.45q0 3.15-2.175 5.325Q27.2 20.95 24.05 20.95Zm0-3q1.85 0 3.175-1.325Q28.55 15.3 28.55 13.45q0-1.85-1.325-3.175Q25.9 8.95 24.05 8.95q-1.85 0-3.175 1.325Q19.55 11.6 19.55 13.45q0 1.85 1.325 3.175Q22.2 17.95 24.05 17.95Zm0-4.5Zm12.5 21.05Zm-25.05 0Z" />
                                </svg> */}
                <svg viewBox="0 0 47 47">
                  <g clipPath="url(#clip0_607_591)">
                    <path
                      fill="current"
                      d="M23.5 2.60419C20.8754 2.60419 18.3584 3.64678 16.5025 5.50261C14.6467 7.35844 13.6041 9.87548 13.6041 12.5C13.6041 15.1246 14.6467 17.6416 16.5025 19.4974C18.3584 21.3533 20.8754 22.3959 23.5 22.3959C26.1245 22.3959 28.6415 21.3533 30.4974 19.4974C32.3532 17.6416 33.3958 15.1246 33.3958 12.5C33.3958 9.87548 32.3532 7.35844 30.4974 5.50261C28.6415 3.64678 26.1245 2.60419 23.5 2.60419V2.60419Z"
                    />
                    <path
                      d="M24.8396 15.1229H27.4006L27.8027 12.5213H24.8391V11.0995C24.8391 10.0187 25.1922 9.0604 26.2032 9.0604H27.8277V6.79009C27.5422 6.75155 26.9386 6.66718 25.798 6.66718C23.4162 6.66718 22.0198 7.92499 22.0198 10.7906V12.5213H19.5714V15.1229H22.0198V22.2734C22.5047 22.3463 22.9959 22.3958 23.5001 22.3958C23.9558 22.3958 24.4006 22.3542 24.8396 22.2948V15.1229Z"
                      fill="white"
                    />
                    <path
                      fill="current"
                      d="M44.875 27.4734C44.1859 27.7786 43.4448 27.9823 42.663 28.0786C43.4578 27.6068 44.0692 26.8542 44.3562 25.9651C43.6125 26.401 42.7885 26.7214 41.912 26.8901C41.212 26.1479 40.2135 25.6875 39.1073 25.6875C36.9823 25.6875 35.2599 27.3948 35.2599 29.5C35.2599 29.7979 35.2948 30.088 35.3604 30.3698C32.1635 30.2094 29.3281 28.6896 27.4312 26.3833C27.0979 26.9469 26.9104 27.6068 26.9104 28.3031C26.9104 29.6266 27.588 30.7917 28.6213 31.4781C27.9906 31.4589 27.3969 31.2833 26.8771 31.0021C26.8771 31.0135 26.8771 31.0307 26.8771 31.0469C26.8771 32.8964 28.2036 34.4365 29.962 34.787C29.6406 34.875 29.301 34.924 28.951 34.924C28.7026 34.924 28.4604 34.8958 28.226 34.8536C28.7156 36.3651 30.1359 37.4698 31.8187 37.5026C30.5021 38.5234 28.8437 39.1349 27.0406 39.1349C26.7291 39.1349 26.4245 39.1172 26.1234 39.0807C27.8271 40.1594 29.85 40.7917 32.0213 40.7917C39.0968 40.7917 42.9677 34.9813 42.9677 29.9406C42.9677 29.7755 42.9625 29.6109 42.9547 29.4484C43.7099 28.9151 44.3609 28.2422 44.875 27.4734Z"
                    />
                    <path
                      fill="current"
                      d="M21.875 40.2708C21.875 41.7094 20.7094 42.875 19.2708 42.875H5.72917C4.29115 42.875 3.125 41.7094 3.125 40.2708V26.7292C3.125 25.2906 4.29115 24.125 5.72917 24.125H19.2708C20.7094 24.125 21.875 25.2906 21.875 26.7292V40.2708Z"
                    />
                    <path
                      d="M6.25 30.8958H8.85417V39.75H6.25V30.8958ZM7.54427 29.8542H7.52969C6.7526 29.8542 6.25 29.275 6.25 28.5516C6.25 27.8125 6.76823 27.25 7.55937 27.25C8.35156 27.25 8.83958 27.8125 8.85417 28.5516C8.85417 29.2745 8.35156 29.8542 7.54427 29.8542ZM18.75 39.75H16.1458V35.0109C16.1458 33.8661 15.5078 33.0849 14.4833 33.0849C13.7016 33.0849 13.2786 33.612 13.0734 34.1214C12.9984 34.3036 13.0208 34.8078 13.0208 35.0625V39.75H10.4167V30.8958H13.0208V32.2583C13.3964 31.6771 13.9844 30.8958 15.4885 30.8958C17.3521 30.8958 18.7495 32.0677 18.7495 34.6844L18.75 39.75Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_607_591">
                      <rect width="47" height="47" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                {t("accounts")}
              </li>
            </Link>
            <Link to="/library">
              <li
                className={
                  location.pathname.includes("/library") ? "selected_grey" : ""
                }
              >
                {/* <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M6 1H8V3H16V1H18V3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.89 3.89 3 5 3H6V1M5 8V19H19V8H5M7 10H17V12H7V10Z" />
                                </svg> */}
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="M11 36q-1.2 0-2.1-.9Q8 34.2 8 33V7q0-1.2.9-2.1Q9.8 4 11 4h12.25l3 3H43q1.2 0 2.1.9.9.9.9 2.1v23q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h32V10H25l-3-3H11v26Zm29.5 9H5q-1.2 0-2.1-.9Q2 40.2 2 39V10h3v29h35.5ZM16.7 27.45h20.6l-6.6-8.8-5.5 7.3-3.95-4.3ZM11 33V7v26Z"
                  />
                </svg>
                {t("library")}
              </li>
            </Link>
            <Link to="/team">
              <li
                className={
                  location.pathname.includes("/team") ? "selected_grey" : ""
                }
              >
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="M0 36v-2.65q0-1.95 2.1-3.15T7.5 29q.65 0 1.2.025.55.025 1.1.125-.4.85-.6 1.725-.2.875-.2 1.875V36Zm12 0v-3.25q0-3.25 3.325-5.25t8.675-2q5.4 0 8.7 2 3.3 2 3.3 5.25V36Zm27 0v-3.25q0-1-.175-1.875t-.575-1.725q.55-.1 1.1-.125Q39.9 29 40.5 29q3.4 0 5.45 1.2Q48 31.4 48 33.35V36Zm-15-7.5q-4 0-6.5 1.2T15 32.75V33h18v-.3q0-1.8-2.475-3T24 28.5Zm-16.5-1q-1.45 0-2.475-1.025Q4 25.45 4 24q0-1.45 1.025-2.475Q6.05 20.5 7.5 20.5q1.45 0 2.475 1.025Q11 22.55 11 24q0 1.45-1.025 2.475Q8.95 27.5 7.5 27.5Zm33 0q-1.45 0-2.475-1.025Q37 25.45 37 24q0-1.45 1.025-2.475Q39.05 20.5 40.5 20.5q1.45 0 2.475 1.025Q44 22.55 44 24q0 1.45-1.025 2.475Q41.95 27.5 40.5 27.5ZM24 24q-2.5 0-4.25-1.75T18 18q0-2.55 1.75-4.275Q21.5 12 24 12q2.55 0 4.275 1.725Q30 15.45 30 18q0 2.5-1.725 4.25T24 24Zm0-9q-1.25 0-2.125.85T21 18q0 1.25.875 2.125T24 21q1.3 0 2.15-.875Q27 19.25 27 18q0-1.3-.85-2.15Q25.3 15 24 15Zm0 18Zm0-15Z"
                  />
                </svg>

                {t("team")}
              </li>
            </Link>
            <Link to="/third_parties">
              <li
                className={
                  location.pathname.includes("/third_parties")
                    ? "selected_grey"
                    : ""
                }
              >
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="m12 42-7.65-7.65L12 26.7l2.45 2.45L11 32.6h26l-3.45-3.45L36 26.7l7.65 7.65L36 42l-2.45-2.45L37 36.1H11l3.45 3.45ZM4.95 21.25v-.65q0-1.05.575-1.925T7.1 17.35q1.25-.55 2.575-.825Q11 16.25 12.45 16.25t2.775.275q1.325.275 2.575.825 1 .45 1.575 1.325t.575 1.925v.65Zm23.1 0v-.65q0-1.05.575-1.925T30.2 17.35q1.25-.55 2.575-.825 1.325-.275 2.775-.275t2.775.275q1.325.275 2.575.825 1 .45 1.575 1.325t.575 1.925v.65Zm-15.6-7.75q-1.55 0-2.65-1.1-1.1-1.1-1.1-2.65 0-1.55 1.1-2.65Q10.9 6 12.45 6 14 6 15.1 7.1q1.1 1.1 1.1 2.65 0 1.55-1.1 2.65-1.1 1.1-2.65 1.1Zm23.1 0q-1.55 0-2.65-1.1-1.1-1.1-1.1-2.65 0-1.55 1.1-2.65Q34 6 35.55 6q1.55 0 2.65 1.1 1.1 1.1 1.1 2.65 0 1.55-1.1 2.65-1.1 1.1-2.65 1.1Z"
                  />
                </svg>

                {t("third_parties")}
              </li>
            </Link>
            <Link to="/lists">
              <li
                className={
                  location.pathname.includes("/lists") ? "selected_grey" : ""
                }
              >
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="m12.5 40 2.15-8.5H7l.75-3h7.65l2.25-9H9l.75-3h8.65L20.5 8h2.95l-2.1 8.5h9.05L32.5 8h2.95l-2.1 8.5H41l-.75 3H32.6l-2.25 9H39l-.75 3H29.6L27.5 40h-3l2.15-8.5H17.6L15.5 40Zm5.85-11.5h9.05l2.25-9H20.6Z"
                  />
                </svg>

                {t("lists")}
              </li>
            </Link>
          </ul>
        </li>
        {/* <li>
                    <div className='main-category' onClick={() => changeMainCategory('post')}>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M23.85 35.7q.8 0 1.35-.55t.55-1.35q0-.8-.55-1.35t-1.35-.55q-.8 0-1.35.55t-.55 1.35q0 .8.55 1.35t1.35.55Zm-1.65-7.4h2.85q0-1.55.5-2.525.5-.975 1.75-2.225 1.65-1.65 2.3-2.925.65-1.275.65-2.675 0-2.65-1.7-4.2-1.7-1.55-4.6-1.55-2.55 0-4.375 1.225Q17.75 14.65 16.9 16.8l2.65 1.1q.7-1.4 1.8-2.125 1.1-.725 2.6-.725 1.6 0 2.525.8.925.8.925 2.2 0 1-.55 1.925T25 22.15q-1.85 1.75-2.325 3-.475 1.25-.475 3.15ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z" />
                        </svg>

                        {t('help_center')}
                    </div>
                </li> */}
        <li className="latest_news">
          <div className="title">{t("latest_title")}</div>
          <div className="description">{t("latest_desc")}</div>
          <Link to="/engage">
            <div className="learn_more">{t("latest_learn_more")}</div>
          </Link>
        </li>
        {/* <li></li> */}
      </ul>
    </div>
  );
};

export default SidebarCESAM;
