import React, { useState, useEffect } from 'react'
import axios from "axios";
import moment from 'moment-timezone'
import { useHistory } from "react-router-dom";

import CalendarCoModalTz from '../../CalendarView/CalendarComponents/CalendarCoModalTz';

import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import PNicon from "../../../images/social_media/pinterest.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import TKicon from "../../../images/social_media/tiktok-spk.svg";
import YTicon from "../../../images/social_media/youtube.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"

const AccountGrid = ({ authTokens, headers, account, showUpcoming, today }) => {
    const history = useHistory();

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }


    const [calendarEvents, setCalendarEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // const optimizedMediaURL = (url) => {
    //     if (url.includes("tr:")) {
    //         return url;
    //     }
    //     const url_options = "tr:n-ik_ml_thumbnail";
    //     // const url_options = "tr:h-150,pr-true";
    //     let arrayedURL = url.split("/");
    //     arrayedURL.splice(arrayedURL.length - 1, 0, url_options);
    //     const optimized_url = arrayedURL.join('/');
    //     return optimized_url;
    // }

    const checkAccountPhoto = (accountData, sm_icon) => {


        switch (accountData.media) {
            case "Facebook":
                const fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={fb_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={FBicon} alt="Media" /> : ""}
                    </div>
                )
            case "Twitter":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={TWicon} alt="Media" /> : ""}
                    </div>
                )
            case "LinkedIn":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={LKicon} alt="Media" /> : ""}
                    </div>
                )
            case "Instagram":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={IGicon} alt="Media" /> : ""}
                    </div>
                )
            case "Pinterest":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={PNicon} alt="Media" /> : ""}
                    </div>
                )
            case "GMB":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={GMBicon} alt="Media" /> : ""}
                    </div>
                )
            case "TikTok":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={TKicon} alt="Media" /> : ""}
                    </div>
                )
            case "Youtube":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={YTicon} alt="Media" /> : ""}
                    </div>
                )
            default:
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" />
                        {sm_icon ? <img className="account_profile_media" src={TWicon} alt="Media" /> : ""}
                    </div>
                )
        }
    }

    const checkSMIcon = (media) => {
        switch (media) {
            case "Facebook":
                return (
                    <img src={FBicon} alt="" />
                )
            case "Twitter":
                return (
                    <img src={TWicon} alt="" />
                )
            case "LinkedIn":
                return (
                    <img src={LKicon} alt="" />
                )
            case "Instagram":
                return (
                    <img src={IGicon} alt="" />
                )
            case "Pinterest":
                return (
                    <img src={PNicon} alt="" />
                )
            case "GMB":
                return (
                    <img src={GMBicon} alt="" />
                )
            case "TikTok":
                return (
                    <img src={TKicon} alt="" />
                )
            default:
                return (
                    <img src={FBicon} alt="" />
                )
        }
    }

    const hashtagsFormat = (hashtag) => {
        const formattedText = '<span class="classic_tags">' + hashtag + '</span>';
        return formattedText
    }

    const formatCaption = (pub_date, postData) => {
        let text = '';

        switch (account.media) {
            case "Facebook":
                text = postData.alt_text_Facebook || postData.text + '\n' + postData.tags;
                break;
            case "Twitter":
                if (postData.thread && postData.thread_content && postData.thread_content.length > 0) {
                    text = postData.thread_content[0].text + ' (Thread)';
                }
                else {
                    text = postData.alt_text_Twitter || postData.text + '\n' + postData.tags;
                }
                break;
            case "LinkedIn":
                text = postData.alt_text_LinkedIn || postData.text + '\n' + postData.tags;
                break;
            case "Instagram":
                text = postData.alt_text_Instagram || postData.text + '\n' + postData.tags;
                break;
            case "Pinterest":
                text = postData.tags;
                break;
            case "GMB":
                text = postData.alt_text_GMB || postData.text + '\n' + postData.tags;
                break;
            case "TikTok":
                text = '';
                break;
            default:
                text = postData.text + '\n' + postData.tags;;
        }

        let formatted_text = text;//`${text.toString()}`;
        const regex = /#\w*/g;
        let new_text = formatted_text.replace(regex, hashtagsFormat);
        new_text = new_text.replace(/\r\n|\r|\n/g, "<br />") + '<br />';

        new_text = '<div class="feed-pub-date">' + moment(pub_date).format('ddd, Do MMM YYYY') + '</div>' + new_text;


        return new_text;
        // return new_text.replace(/\r\n|\r|\n/g, "<br />") + '<br />';
    }

    const [selectedEvent, setSelectedEvent] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [newPublicationDate, setNewPublicationDate] = useState({
        date: '',
        timezone: { label: Intl.DateTimeFormat().resolvedOptions().timeZone, value: Intl.DateTimeFormat().resolvedOptions().timeZone }
    });

    const closeModal = () => {
        setNewPublicationDate({});
        setShowModal(false);
    }

    const selectEvent = async (event) => {
        setSelectedEvent(event);
        setNewPublicationDate({
            date: moment(event.publication_date).tz(event.scheduled_post.timezone && event.scheduled_post.timezone.value ? event.scheduled_post.timezone.value : Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm'),
            timezone: event.scheduled_post.timezone ? event.scheduled_post.timezone : { label: Intl.DateTimeFormat().resolvedOptions().timeZone, value: Intl.DateTimeFormat().resolvedOptions().timeZone }
        });
        setShowModal(true);
    }

    const deletePublication = async () => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        const payload = {
            calendar_id: selectedEvent._id
        }
        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/unschedule_single_upcoming_publication", payload, {
                headers: headers,
            })
            .then(() => {
                const updated_events_array = calendarEvents.filter(event => event._id !== selectedEvent._id);
                setCalendarEvents(updated_events_array);

            })
            .catch(() => {
            });

        closeModal();
        closeStateModal();
    }

    const rescheduleFailedPublication = async () => {
        const updated_events_array = calendarEvents;
        let indexFound = updated_events_array.findIndex((event) => event._id === selectedEvent._id);

        if (indexFound !== -1) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: true
            });

            const payload = {
                calendar_id: selectedEvent._id,
                new_publication_date: newPublicationDate
            }
            await axios
                .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/reschedule_failed_publication", payload, {
                    headers: headers,
                })
                .then((response) => {
                    updated_events_array[indexFound].start = new Date(response.data);
                    updated_events_array[indexFound].end = new Date(response.data);
                    updated_events_array[indexFound].publication_date = new Date(response.data);

                    setCalendarEvents(updated_events_array);

                })
                .catch(() => {
                });
        }

        closeModal();
        closeStateModal();
    }

    const reschedulePublication = async () => {
        const updated_events_array = calendarEvents;
        let indexFound = updated_events_array.findIndex((event) => event._id === selectedEvent._id);

        if (indexFound !== -1) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: true
            });

            const payload = {
                calendar_id: selectedEvent._id,
                new_publication_date: newPublicationDate
            }
            await axios
                .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/reschedule_publication", payload, {
                    headers: headers,
                })
                .then((response) => {
                    updated_events_array[indexFound].start = new Date(response.data);
                    updated_events_array[indexFound].end = new Date(response.data);
                    updated_events_array[indexFound].publication_date = new Date(response.data);

                    setCalendarEvents(updated_events_array);

                })
                .catch(() => {
                });
        }

        closeModal();
        closeStateModal();
    }

    const editPost = (post_id, thread, media, bulk) => {

        if (thread) {
            history.push({
                pathname: '/post/thread/' + post_id,
            });
        }
        else if (media === 'Pinterest') {
            history.push({
                pathname: '/post/pins/' + post_id,
            });
        }
        else if (bulk) {
            history.push({
                pathname: '/post/burst_post/' + post_id,
            });
        }
        else {
            history.push({
                pathname: '/post/new_post/' + post_id,
            });
        }
    }

    const fetchAllCalendarEvents = async () => {
        setIsLoading(true);

        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            user_id: authTokens.user_id,
            company_id: authTokens.account_id,
            account_id: account._id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/get_feed", payload, {
                headers: headers,
            })
            .then((response) => {
                setIsLoading(false);
                // console.log(response.data);
                setCalendarEvents(response.data);
            })
            .catch(() => {
            });

    }

    useEffect(() => {
        fetchAllCalendarEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])


    return (
        <div className='card'>
            {showModal ? <CalendarCoModalTz
                closeModal={closeModal}
                selectedEvent={selectedEvent}
                reschedulePublication={reschedulePublication}
                rescheduleFailedPublication={rescheduleFailedPublication}
                deletePublication={deletePublication}
                editPost={editPost}
                newPublicationDate={newPublicationDate}
                setNewPublicationDate={setNewPublicationDate}
            /> : ""}
            <div className="sm-badge-wrapper">
                {checkSMIcon(account.media)}
            </div>
            <div className="account_header">
                {checkAccountPhoto(account, false)}
                <div className="profile_text">
                    <span className="acc_name">{account.social_name}</span>
                </div>
            </div>

            {!isLoading ?
                <div className='feed-grid-view margin-t2'>
                    {calendarEvents && calendarEvents.length > 0 ?
                        calendarEvents.map((event, e) => {
                            return (
                                !showUpcoming && moment(event.publication_date) >= today ?
                                    ''
                                    : event.scheduled_post.medias && event.scheduled_post.medias.length > 0 ?
                                        <div key={event._id} className='grid-view-media'>
                                            {event.scheduled_post.medias[0].type === 'image' ?
                                                <img src={event.hasOwnProperty('bulk_media_index') ? event.scheduled_post.medias[event.bulk_media_index].file_url : event.scheduled_post.medias[0].file_url} alt="" />
                                                :
                                                <div className='grid-view-video-container'>
                                                    {(event.hasOwnProperty('bulk_media_index') && event.scheduled_post.medias[event.bulk_media_index].thumbnail_url) || event.scheduled_post.medias[0].thumbnail_url ?
                                                        <img src={event.hasOwnProperty('bulk_media_index') ? event.scheduled_post.medias[event.bulk_media_index].thumbnail_url : event.scheduled_post.medias[0].thumbnail_url} alt="" />
                                                        :
                                                        <video>
                                                            <source src={event.hasOwnProperty('bulk_media_index') ? event.scheduled_post.medias[event.bulk_media_index].file_url : event.scheduled_post.medias[0].file_url} />
                                                        </video>
                                                    }
                                                    <div className="video_overlay">
                                                        <svg viewBox="0 0 24 24">
                                                            <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            }
                                            {!event.published && moment(event.publication_date) >= moment() ?
                                                <div className='upcoming'></div>
                                                : !event.published && moment(event.publication_date) < moment() ?
                                                    <div className="failed">
                                                        <svg className='failed_dot' viewBox="0 0 24 24">
                                                            <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                                        </svg>
                                                    </div>
                                                    : ''
                                            }
                                            <div className={!event.published ? 'hover_caption clickable' : 'hover_caption'} onClick={() => !event.published ? selectEvent(event) : null} dangerouslySetInnerHTML={{ __html: formatCaption(event.publication_date, event.scheduled_post) }}>
                                            </div>
                                        </div>
                                        : event.scheduled_post.lk_poll && event.scheduled_post.lk_poll.display && event.scheduled_post.lk_poll.question && event.scheduled_post.lk_poll.choices && event.scheduled_post.lk_poll.choices.length > 1 ?
                                            <div key={event._id} className='grid-view-media'>
                                                <div className="lk_poll_block grid_block">
                                                    <div className='lk_poll_question'>{event.scheduled_post.lk_poll.question}</div>
                                                    {event.scheduled_post.lk_poll.choices.map((choice, c) => {
                                                        if (choice.length > 0) {
                                                            return (
                                                                <div key={`choice_${c}`} className='lk_poll_choice_item'>{choice}</div>
                                                            )
                                                        }

                                                    })}
                                                </div>
                                                <div className={!event.published ? 'hover_caption clickable' : 'hover_caption'} onClick={() => !event.published ? selectEvent(event) : null} dangerouslySetInnerHTML={{ __html: formatCaption(event.publication_date, event.scheduled_post) }}></div>
                                            </div>
                                            :
                                            <div key={event._id} className='grid-view-caption' dangerouslySetInnerHTML={{ __html: formatCaption(event.publication_date, event.scheduled_post) }}></div>
                            )
                        })
                        : ''
                    }
                </div>
                :
                <div className="lds-ring-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            }
        </div>
    )
}

export default AccountGrid