import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useAuth } from "../../auth";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import moment from 'moment'
import StateModal from '../Loader/StateModal';

import Content from './EditorComponents/Content'
import Options from './EditorComponents/Options';
import Publish from './EditorComponents/Publish';
import PublishBurst from './EditorComponents/PublishBurst';

import UpgradeModal from '../Plans/UpgradeModal';
import SocialsModal from '../Manage/ManageComponents/SocialsModal';

import PreviewBlank from '../Composer/PostPreviews/PreviewBlank';
import PreviewFB from '../Composer/PostPreviews/PreviewFB';
import PreviewIG from '../Composer/PostPreviews/PreviewIG';
import PreviewLK from '../Composer/PostPreviews/PreviewLK';
import PreviewTW from '../Composer/PostPreviews/PreviewTW';
import PreviewGMB from '../Composer/PostPreviews/PreviewGMB';
import PreviewTK from '../Composer/PostPreviews/PreviewTK';
import PreviewYT from '../Composer/PostPreviews/PreviewYT';

import BulkPostPreview from '../Composer/PostPreviews/BulkPostPreview';
import BulkPostPreviewBlank from '../Composer/PostPreviews/BulkPostPreviewBlank';

import BurstModal from '../Composer/BurstModal';

import "./editor.css"
import "../styles/toggle_switch.css";
import "../Composer/composer.css"


let keystrokeTimer;
const MAX_CHAR_COUNT = 1500;
const MAX_TWITTER_COUNT = 280;
const MAX_TAGS = 25;


const PostEditor = (props) => {
    const location = useLocation();
    const { authTokens } = useAuth();
    const currentPlan = Number(authTokens.plan);
    const { t } = useTranslation(['composer']);



    const headers = {
        Authorization: authTokens.token,
    };

    const [bulk, setBulk] = useState(false);

    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const displayUpgradeModal = () => {
        setShowUpgradeModal(!showUpgradeModal);
    }

    const [showSocialsModal, setShowSocialsModal] = useState(false);
    const closeSocialsModal = () => {
        setShowSocialsModal(false);
    }

    const [reauthOption, setReauthOption] = useState(false);
    const displayReauthModal = () => {
        setReauthOption(true);
        setShowSocialsModal(true);
    }

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }

    let scheduled_post_id;
    let initial_date;
    const paramsId = props.match.params.id;
    if (paramsId) {
        if (paramsId.charAt(0) === 'D') {
            initial_date = paramsId.substring(paramsId.indexOf(':') + 1);
        }
        else {
            scheduled_post_id = paramsId;
        }
    }



    const [editorMenuItem, setEditorMenuItem] = useState('content');


    const initialPublicationData = {
        _id: "",
        name: "",
        text: "",
        link_url: "",
        date: initial_date || "",
        raw_date: initial_date || "",
        timezone: { label: Intl.DateTimeFormat().resolvedOptions().timeZone, value: Intl.DateTimeFormat().resolvedOptions().timeZone },
        tags: "",
        frequency: "0",
        repeat: "",
        company_id: authTokens.account_id,
        draft: false,
        spread: false,
        spread_period: "5",
        bulk: false,
        bulk_spread_period: "6",
        social_accounts: [],
        medias: [],
        alt_text_Facebook: '',
        alt_text_Instagram: '',
        alt_text_Twitter: '',
        alt_text_GMB: '',
        alt_text_LinkedIn: '',
        mentions_LinkedIn: [],
        reel_eligible: false,
        reel: false,
        gmb_topic: '',
        gmb_cta: '',
        gmb_cta_url: '',
        gmb_event_title: '',
        gmb_event_start_date: '',
        gmb_event_start_time: '',
        gmb_event_end_date: '',
        gmb_event_end_time: '',
        gmb_offer_coupon_code: '',
        gmb_offer_url: '',
        gmb_offer_terms: '',
        first_comment: '',
        first_comment_accounts: [],
        fb_reel: false,
        retweet: false,
        template: false,
        yt_category: 42,
        yt_status: 'public',
        yt_description: '',
        lk_poll: {
            display: false,
            question: '',
            choices: ['', '', '', ''],
            duration: 1
        }
    }
    const [publicationData, setPublicationData] = useState(initialPublicationData);

    const [videoCheckerResults, setVideoCheckerResults] = useState({
        is_video: false,
        is_loading: false,
        analysis_failed: false,
        needs_analysis: false,
        video_size: '',
        video_duration: '',
        video_width: '',
        video_height: '',
        fb_regular: false,
        fb_regular_error: '',
        fb_reel: false,
        fb_reel_error: '',
        ig_regular: false,
        ig_regular_error: '',
        ig_reel: false,
        ig_reel_error: '',
        lk: false,
        lk_error: '',
        tw: false,
        tw_error: '',
        pn: false,
        pn_error: '',
        tk: false,
        tk_error: '',
        yt: false,
        yt_error: ''
    })

    const [twitterCharCount, setTwitterCharCount] = useState(MAX_TWITTER_COUNT);
    const [totalCharCount, setTotalCharCount] = useState(MAX_CHAR_COUNT);
    const [tagsCount, setTagsCount] = useState(MAX_TAGS);

    const countHashtags = () => {
        const hashCountTxt = MAX_TAGS - (publicationData.text.split('#').length - 1 + publicationData.tags.split('#').length - 1);
        setTagsCount(hashCountTxt);
    }

    const [linkPreview, setLinkPreview] = useState();
    const [urlToScrap, setUrlToScrap] = useState('');
    const [urlLoader, setUrlLoader] = useState(false);

    const grabLinkPreview = async (url) => {

        if (publicationData.medias.length === 0) {

            if (url) {
                setUrlLoader(true);
                await axios
                    .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/scraper/open_graph", { url }, {
                        headers: headers,
                    })
                    .then((response) => {
                        setLinkPreview(response.data);
                        setPublicationData({
                            ...publicationData,
                            link_url: response.data.url
                        });
                    })
                    .catch(() => {
                    });
                setUrlLoader(false);

            }
            else {
                setLinkPreview();
            }
            closeStateModal();
        }

        let base_text = publicationData.text;
        let matches = base_text.match(/\bhttp\S+/gi);

        if (!matches || matches.length === 0) {
            setTwitterCharCount(MAX_TWITTER_COUNT - base_text.length - publicationData.tags.length - (url.length > 0 ? 23 : 0));
            setTotalCharCount(MAX_CHAR_COUNT - base_text.length - publicationData.tags.length - (url.length > 0 ? 23 : 0));
        }
        else {
            let string_to_count = base_text;
            matches.forEach(string => {
                string_to_count = string_to_count.replaceAll(string, "");
            })
            setTwitterCharCount(MAX_TWITTER_COUNT - string_to_count.length - publicationData.tags.length - (url.length > 0 ? 23 : 0) - matches.length * 23);
            setTotalCharCount(MAX_CHAR_COUNT - string_to_count.length - publicationData.tags.length - (url.length > 0 ? 23 : 0) - matches.length * 23);
        }
    }

    const scrapLink = (url_to_scrap) => {

        if (!url_to_scrap || url_to_scrap.length === 0) {
            setUrlToScrap('');
            setLinkPreview();
            setPublicationData({
                ...publicationData,
                link_url: ''
            });

            let base_text = publicationData.text;
            let matches = base_text.match(/\bhttp\S+/gi);

            if (!matches || matches.length === 0) {
                setTwitterCharCount(MAX_TWITTER_COUNT - base_text.length - publicationData.tags.length);
                setTotalCharCount(MAX_CHAR_COUNT - base_text.length - publicationData.tags.length);
            }
            else {
                let string_to_count = base_text;
                matches.forEach(string => {
                    string_to_count = string_to_count.replaceAll(string, "");
                })
                setTwitterCharCount(MAX_TWITTER_COUNT - string_to_count.length - publicationData.tags.length - matches.length * 23);
                setTotalCharCount(MAX_CHAR_COUNT - string_to_count.length - publicationData.tags.length - matches.length * 23);
            }

            return;
        }
        else {
            setUrlToScrap(url_to_scrap);
        }


        clearTimeout(keystrokeTimer);

        keystrokeTimer = setTimeout(() => {
            const url = url_to_scrap.trim();
            grabLinkPreview(url);
        }, 800);
    }

    const fetchScheduledPostDetails = async (_id) => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });
        const payload = {
            _id: _id,
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/get_single_scheduled_post_details", payload, {
                headers: headers,
            })
            .then((response) => {
                console.log(response.data);
                setPublicationData(response.data);
                setTwitterCharCount(MAX_TWITTER_COUNT - response.data.text.length - response.data.tags.length - response.data.link_url.length);
                setTotalCharCount(MAX_CHAR_COUNT - response.data.text.length - response.data.tags.length - response.data.link_url.length);

                if (response.data.link_url.length > 0) {
                    setUrlToScrap(response.data.link_url);
                }

            })
            .catch(() => {
            });
        closeStateModal();
    }


    const [allSMAccounts, setAllSMAccounts] = useState([]);
    const [SMAccounts, setSMAccounts] = useState([]);
    const [SMGroups, setSMGroups] = useState([]);


    const checkPublishableVideo = () => {
        let video_check_passed = true;
        let video_check_error = [t('video_not_publishable')];

        if (publicationData.medias && publicationData.medias.length > 0 && publicationData.medias[0].type !== 'image' && publicationData.social_accounts && publicationData.social_accounts.length > 0) {
            publicationData.social_accounts.forEach(account => {
                if (account.media === 'Facebook' && publicationData.fb_reel && !videoCheckerResults.fb_reel) {
                    video_check_passed = false;
                    video_check_error.push(t('video_not_publishable_as_fb_reel'));
                }
                else if (account.media === 'Facebook' && !publicationData.fb_reel && !videoCheckerResults.fb_regular) {
                    video_check_passed = false;
                    video_check_error.push(t('video_not_publishable_as_fb_regular'));
                }
                else if (account.media === 'Instagram' && publicationData.reel && !videoCheckerResults.ig_reel) {
                    video_check_passed = false;
                    video_check_error.push(t('video_not_publishable_as_ig_reel'));
                }
                else if (account.media === 'Instagram' && !publicationData.reel && !videoCheckerResults.ig_regular) {
                    video_check_passed = false;
                    video_check_error.push(t('video_not_publishable_as_ig_regular'));
                }
                else if (account.media === 'Twitter' && !videoCheckerResults.tw) {
                    video_check_passed = false;
                    video_check_error.push(t('video_not_publishable_as_tw'));
                }
                else if (account.media === 'LinkedIn' && !videoCheckerResults.lk) {
                    video_check_passed = false;
                    video_check_error.push(t('video_not_publishable_as_lk'));
                }
                else if (account.media === 'TikTok' && !videoCheckerResults.tk) {
                    video_check_passed = false;
                    video_check_error.push(t('video_not_publishable_as_tk'));
                }
            })
        }



        const payload = {
            passed: video_check_passed,
            errors: video_check_error.join('')
        }

        return payload;
    }

    const recordPost = async (type) => {
        let authError = false;

        let post_payload = publicationData;



        if (type === 'draft') {
            post_payload.draft = true;
        }
        else if (type === 'now') {
            post_payload.date = moment().add(3, 'minutes');
            post_payload.draft = false;
        }
        else {
            post_payload.draft = false;
        }

        if (bulk) {
            post_payload.bulk = true;
        }

        // if (button_name !== "schedule" && button_name !== "draft") {
        //     return;
        // }

        if (totalCharCount < 0) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('too_long')
            });
            return
        }

        if (tagsCount > 25) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('too_many_tags')
            });
            return
        }


        if (!post_payload.draft && (!post_payload.social_accounts || post_payload.social_accounts.length === 0)) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('select_a_social_account')
            });
            return
        }

        let tw_check = false;

        if (post_payload.social_accounts.length > 0) {
            post_payload.social_accounts.forEach(acc => {
                if (acc.media === "Twitter") {
                    tw_check = true;
                }
            })
        }

        if (tw_check && twitterCharCount < 0) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('tw_too_long')
            });
            return
        }

        let ig_check = false;

        if (post_payload.social_accounts.length > 0) {
            post_payload.social_accounts.forEach(acc => {
                if (acc.media === "Instagram") {
                    ig_check = true;
                }
            })
        }

        if (ig_check && post_payload.medias.length === 0) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('no_img_ig')
            });
            return
        }

        let tk_check = false;

        if (post_payload.social_accounts.length > 0) {
            post_payload.social_accounts.forEach(acc => {
                if (acc.media === "TikTok") {
                    tk_check = true;
                }
            })
        }

        if (tk_check && (post_payload.medias.length === 0 || post_payload.medias[0].type !== 'video')) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('no_video_tk')
            });
            return
        }


        if (!post_payload.text && !post_payload.alt_text_Facebook && !post_payload.alt_text_Instagram && !post_payload.alt_text_Twitter && !post_payload.alt_text_LinkedIn && !post_payload.alt_text_GMB && post_payload.medias.length === 0 && !post_payload.link_url) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('no_content')
            });
            return
        }

        if (!post_payload.date && type === "schedule") {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('no_date')
            });
            return
        }

        const video_check = checkPublishableVideo();

        if (!video_check.passed) {

            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: video_check.errors
            });

            return;
        }

        let yt_check = false;

        if (post_payload.social_accounts.length > 0) {
            post_payload.social_accounts.forEach(acc => {
                if (acc.media === "Youtube") {
                    yt_check = true;
                }
            })
        }



        if (yt_check && publicationData.text.length > 100) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('yt_title_too_long')
            });
            return
        }

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        let spk_api_url = process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/save_post_spread_option";
        if (publicationData.bulk) {
            spk_api_url = process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/save_bulk_post";
        }

        await axios
            .post(spk_api_url, post_payload, {
                headers: headers,
            })
            .then(() => {
                if (!post_payload.draft) {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        upgradeOption: false,
                        status: "check",
                        message: t('post_recorded'),
                        button_text: t('go_to_calendar'),
                        button_link: "/calendar/monthly"
                    });
                }
                else if (post_payload.draft && post_payload.date) {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        upgradeOption: false,
                        status: "check",
                        message: t('post_recorded'),
                        button_text: t('go_to_calendar'),
                        button_link: "/calendar/monthly"
                    });
                }
                else {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        upgradeOption: false,
                        status: "check",
                        message: t('post_recorded'),
                        button_text: t('go_to_drafts'),
                        button_link: "/drafts"
                    });
                }
                // setPublicationData(initialPublicationData);
                // setUrlToScrap();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
                else {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        status: "error",
                        message: t('post_not_recorded')
                    });
                }

            });
        if (authError) return;

    }

    const fetchAvailableSocialMedia = async () => {
        let authError = false;
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });


        // NEW FUNCTION - TEAM COMPLIANT

        const payload = {
            user_id: authTokens.user_id,
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_accounts_ex_pinterest", payload, {
                headers: headers,
            })
            .then((response) => {
                closeStateModal();
                setAllSMAccounts(response.data.sm_accounts);
                setSMAccounts(response.data.sm_accounts);
                setSMGroups(response.data.sm_groups);
                if (response.data.sm_accounts.length === 0) {
                    setShowSocialsModal(true);
                }

            })
            .catch((error) => {
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
            });
        if (authError) return;
    }

    useEffect(() => {
        if (scheduled_post_id) {
            fetchScheduledPostDetails(scheduled_post_id);
        }
        fetchAvailableSocialMedia();

        props.changeNav("New Post", "NewPost");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showBurstModal, setShowBurstModal] = useState(false);
    const displayBurstModal = () => {
        setShowBurstModal(!showBurstModal);
    }

    useEffect(() => {
        if (location.pathname.includes('/burst_post')) {
            setPublicationData({
                ...publicationData,
                bulk: true
            })
            setBulk(true);
            displayBurstModal();
        }
        else {
            setBulk(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])


    const [socialPlatforms, setSocialPlatforms] = useState([]);

    useEffect(() => {
        if (publicationData.social_accounts && publicationData.social_accounts.length > 0) {
            const mediaArray = [];
            publicationData.social_accounts.forEach(account => {
                mediaArray.push(account.media)
            })

            var uniqueArray = [...new Set(mediaArray)];
            setSocialPlatforms(uniqueArray);
        }
        else {
            setSocialPlatforms([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicationData]);

    useEffect(() => {
        countHashtags();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicationData.text, publicationData.tags])

    return (
        <div className="content_row_maxed nowrap">
            {showBurstModal ? <BurstModal closeBurstModal={displayBurstModal} /> : ""}
            {showUpgradeModal ? <UpgradeModal upgrade={true} closeModal={() => setShowUpgradeModal(false)} closeButton={true} /> : ''}
            {showSocialsModal ? <SocialsModal closeSocialsModal={closeSocialsModal} reauth_fb_ig={reauthOption} /> : ""}
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            <div className="editor_card">
                <div className='editor_menu'>
                    <div className={editorMenuItem === 'content' ? 'editor_menu_item active' : 'editor_menu_item'} onClick={() => setEditorMenuItem('content')}>
                        {t('content')}
                    </div>
                    <div className={editorMenuItem === 'options' ? 'editor_menu_item active' : 'editor_menu_item'} onClick={() => setEditorMenuItem('options')}>
                        {t('options')}
                    </div>
                    <div className={editorMenuItem === 'publication' ? 'editor_menu_item active' : 'editor_menu_item'} onClick={() => setEditorMenuItem('publication')}>
                        {t('publication')}
                    </div>
                    <div className='editor_menu_indicator'></div>
                </div >
                <div className='editor_components margin-t1'>
                    <Content
                        editorMenuItem={editorMenuItem}
                        authTokens={authTokens}
                        currentPlan={currentPlan}
                        displayUpgradeModal={displayUpgradeModal}
                        publicationData={publicationData}
                        setPublicationData={setPublicationData}
                        allSMAccounts={allSMAccounts}
                        SMAccounts={SMAccounts}
                        setSMAccounts={setSMAccounts}
                        SMGroups={SMGroups}
                        socialPlatforms={socialPlatforms}
                        scrapLink={scrapLink}
                        urlToScrap={urlToScrap}
                        stateModalOptions={stateModalOptions}
                        setStateModalOptions={setStateModalOptions}
                        mediaTransfer={props.mediaTransfer}
                        bulk={bulk}
                        twitterCharCount={twitterCharCount}
                        setTwitterCharCount={setTwitterCharCount}
                        totalCharCount={totalCharCount}
                        setTotalCharCount={setTotalCharCount}
                        tagsCount={tagsCount}
                        urlLoader={urlLoader}
                        videoCheckerResults={videoCheckerResults}
                        setVideoCheckerResults={setVideoCheckerResults}
                    />
                    <Options
                        editorMenuItem={editorMenuItem}
                        authTokens={authTokens}
                        displayUpgradeModal={displayUpgradeModal}
                        displayReauthModal={displayReauthModal}
                        publicationData={publicationData}
                        setPublicationData={setPublicationData}
                        socialPlatforms={socialPlatforms}
                        stateModalOptions={stateModalOptions}
                        setStateModalOptions={setStateModalOptions}
                        closeStateModal={closeStateModal}
                    />
                    {bulk ?
                        <PublishBurst
                            authTokens={authTokens}
                            displayUpgradeModal={displayUpgradeModal}
                            editorMenuItem={editorMenuItem}
                            publicationData={publicationData}
                            setPublicationData={setPublicationData}
                            recordPost={recordPost}
                        />
                        :
                        <Publish
                            authTokens={authTokens}
                            displayUpgradeModal={displayUpgradeModal}
                            editorMenuItem={editorMenuItem}
                            publicationData={publicationData}
                            setPublicationData={setPublicationData}
                            recordPost={recordPost}
                        />
                    }
                </div>
            </div >
            <div className="card_preview">
                <div className='composer_component_thread'>
                    <div className="title">
                        {t('post_preview')}
                    </div>
                    <div className='composer_component_previews'>

                        {bulk ?
                            publicationData.social_accounts && publicationData.social_accounts.length > 0 ?
                                publicationData.medias.length > 0 ?
                                    publicationData.medias.map((media, index) => {
                                        return (
                                            <BulkPostPreview publicationData={publicationData} selectedPhotos={[media]} linkPreview={linkPreview} index={index} />
                                        )
                                    })
                                    :
                                    <BulkPostPreview publicationData={publicationData} selectedPhotos={[]} linkPreview={linkPreview} index={0} />
                                :
                                publicationData.medias.length > 0 ?
                                    publicationData.medias.map(media => {
                                        return (
                                            <BulkPostPreviewBlank publicationData={publicationData} selectedPhotos={[media]} linkPreview={linkPreview} />
                                        )
                                    })
                                    :
                                    <BulkPostPreviewBlank publicationData={publicationData} selectedPhotos={[]} linkPreview={linkPreview} />

                            :
                            <>
                                <PreviewBlank publicationData={publicationData} selectedPhotos={publicationData.medias} linkPreview={linkPreview} />
                                <PreviewIG publicationData={publicationData} selectedPhotos={publicationData.medias} linkPreview={linkPreview} />
                                <PreviewFB publicationData={publicationData} selectedPhotos={publicationData.medias} linkPreview={linkPreview} />
                                <PreviewTK publicationData={publicationData} selectedPhotos={publicationData.medias} />
                                <PreviewYT publicationData={publicationData} selectedPhotos={publicationData.medias} />
                                <PreviewLK publicationData={publicationData} selectedPhotos={publicationData.medias} linkPreview={linkPreview} />
                                <PreviewTW publicationData={publicationData} selectedPhotos={publicationData.medias} linkPreview={linkPreview} />
                                <PreviewGMB publicationData={publicationData} selectedPhotos={publicationData.medias} />
                            </>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PostEditor