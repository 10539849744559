import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Tooltip from '../../sidebar/Tooltip';

import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import TKTKicon from "../../../images/social_media/tiktok-spk.svg";
import YTicon from "../../../images/social_media/youtube.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"
import FBGroupIcon from "../../../images/social_media/fb_group.png";

const AccountsSelection = ({ allSMAccounts, publicationData, setPublicationData, SMGroups, SMAccounts, setSMAccounts }) => {
    const { t } = useTranslation(['composer']);
    const history = useHistory();
    const goToAccounts = () => {
        history.push({ pathname: '/sm_accounts' });
    }
    const [selectedGroup, setSelectedGroup] = useState({});



    const selectGroup = (e) => {
        setSelectedGroup(e.target.value);
        let newlyAvailableSMAccounts = [];

        if (!e.target.value) {
            setSMAccounts(allSMAccounts);
        }
        else {
            const filteredGroupData = SMGroups.filter(group => group._id === e.target.value);

            filteredGroupData[0].sm_accounts.map(account => {
                let sm_account = allSMAccounts.filter(acc => acc._id === account);
                if (sm_account[0]) {
                    newlyAvailableSMAccounts.push(sm_account[0]);
                }
                return null;
            })

            setSMAccounts(newlyAvailableSMAccounts);
        }

        setPublicationData({
            ...publicationData,
            social_accounts: newlyAvailableSMAccounts
        });
    }

    const selectMediaAccount = (account) => {
        let selectedMediaAccount = publicationData.social_accounts;

        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === account.social_id);

        if (indexFound !== -1) {
            const filteredMedia = selectedMediaAccount.filter(item => item.social_id !== account.social_id);
            setPublicationData({
                ...publicationData,
                social_accounts: filteredMedia
            });
        }
        else {
            selectedMediaAccount.push(account);
            setPublicationData({
                ...publicationData,
                social_accounts: selectedMediaAccount
            });
        }
    }

    const checkAccountPhoto = (accountData) => {

        if (!accountData) return;

        const selectedMediaAccount = publicationData.social_accounts;
        let selected = false;
        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === accountData.social_id);

        if (indexFound !== -1) {
            selected = true;
        }

        switch (accountData.media) {
            case "Facebook":
                let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                if(accountData.fb_group){
                    fb_picture_url=FBGroupIcon
                }

                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={fb_picture_url} alt={accountData.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={FBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Twitter":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "LinkedIn":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={LKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Instagram":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={IGicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "GMB":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={GMBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "TikTok":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TKTKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Youtube":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={YTicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
        }
    }


    return (
        <div>
            <div className='composer_title'>
                {t('accounts_selection')}
            </div>
            {SMGroups && SMGroups.length > 0 ?
                <div className="row margin-t2">
                    {/* <p className='text-grey text-italic margin-r1'>{t('accounts_group')}:</p> */}
                    <select className="editor" name="group" onChange={e => selectGroup(e)} value={selectedGroup}>
                        <option value="">{t('all_accounts')}</option>
                        {SMGroups.map((group, i) => {
                            return (
                                <option key={i} value={group._id}>{group.name}</option>
                            )
                        })}
                    </select>
                </div>
                :
                ""
            }
            {SMAccounts && SMAccounts.length > 0 ?
                <p className='text-grey text-italic'>{t('select_accounts_click')}</p>
                : ''}
            <div className="row margin-t2">
                {SMAccounts && SMAccounts.length > 0 ?
                    SMAccounts.map((account, i) => {
                        return (
                            <div className="account_selection_account" key={i} onClick={e => selectMediaAccount(account)}>
                                {checkAccountPhoto(account)}
                            </div>
                        )
                    }
                    )
                    :
                    <div className="action_button red" onClick={() => goToAccounts()}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M10.59,13.41C11,13.8 11,14.44 10.59,14.83C10.2,15.22 9.56,15.22 9.17,14.83C7.22,12.88 7.22,9.71 9.17,7.76V7.76L12.71,4.22C14.66,2.27 17.83,2.27 19.78,4.22C21.73,6.17 21.73,9.34 19.78,11.29L18.29,12.78C18.3,11.96 18.17,11.14 17.89,10.36L18.36,9.88C19.54,8.71 19.54,6.81 18.36,5.64C17.19,4.46 15.29,4.46 14.12,5.64L10.59,9.17C9.41,10.34 9.41,12.24 10.59,13.41M13.41,9.17C13.8,8.78 14.44,8.78 14.83,9.17C16.78,11.12 16.78,14.29 14.83,16.24V16.24L11.29,19.78C9.34,21.73 6.17,21.73 4.22,19.78C2.27,17.83 2.27,14.66 4.22,12.71L5.71,11.22C5.7,12.04 5.83,12.86 6.11,13.65L5.64,14.12C4.46,15.29 4.46,17.19 5.64,18.36C6.81,19.54 8.71,19.54 9.88,18.36L13.41,14.83C14.59,13.66 14.59,11.76 13.41,10.59C13,10.2 13,9.56 13.41,9.17Z" />
                        </svg>
                        {t('add_sm_accounts_dashboard')}
                    </div>
                }
            </div>
        </div>
    )
}

export default AccountsSelection