import React, { useState, useEffect } from 'react'
import { useAuth } from "../../auth";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from 'moment'
import axios from "axios";
import StateModal from '../Loader/StateModal';


import Invoices from '../subscriptions/Invoices';
import ProfileUpdate from './ProfileUpdate';



const Account = () => {
    const { authTokens } = useAuth();
    const { setAuthTokens } = useAuth();
    const { t } = useTranslation(['account']);
    const headers = {
        Authorization: authTokens.token,
    };
    const history = useHistory();

    const currentPlan = Number(authTokens.plan);

    const isAdmin = authTokens.user_admin;
    const plan_expiry = moment(authTokens.plan_expiry).format("DD MMM YYYY");

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    const goToPlans = () => {
        history.push({ pathname: '/plans' });
    }

    const goToCancellation = () => {
        history.push({ pathname: '/cancel_plan' });
    }



    const [showProfileUpdater, setShowProfileUpdater] = useState(false);
    const displayProfileUpdater = () => {
        setShowProfileUpdater(!showProfileUpdater);
    }

    const [publicationsRatio, setPublicationsRatio] = useState('');
    const [publicationsRatioPercent, setPublicationsRatioPercent] = useState(0);
    const [creditsRatio, setCreditsRatio] = useState('');
    const [creditsRatioPercent, setCreditsRatioPercent] = useState(0);

    const [companyData, setCompanyData] = useState({
        _id: authTokens.account_id,
        name: "",
        address_line_1: "",
        address_line_2: "",
        post_code: "",
        city: "",
        country: "",
        other_data: "",
        currency: authTokens.currency || "",
        isSubmitting: false,
        isEditing: false
    });

    const checkGaugeStyle = (ratio) => {

        let style = "";

        if (ratio > 90) {
            style = "meter meter_red";
        }
        else if (ratio > 80) {
            style = "meter meter_yellow";
        }
        else {
            style = "meter meter_green";
        }

        return style;
    }

    const checkCurrencySymbol = (currency) => {

        let currencySymbol = "";
        switch (currency) {
            case "eur":
                currencySymbol = "€"
                break;
            case "usd":
                currencySymbol = "$"
                break;
            case "chf":
                currencySymbol = "CHF"
                break;
            case "gbp":
                currencySymbol = "£"
                break;
            default:
                currencySymbol = "-"
        }

        return currencySymbol;
    }

    const editCompanyProfile = async () => {
        // e.preventDefault();

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        setCompanyData({
            ...companyData,
            isSubmitting: true
        });

        setAuthTokens({
            ...authTokens,
            currency: companyData.currency,
        });

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/update_company_profile", companyData, {
                headers: headers,
            })
            .then(() => {
                setCompanyData({
                    ...companyData,
                    isSubmitting: false,
                    isEditing: false
                });
            })
            .catch(() => {
                setCompanyData({
                    ...companyData,
                    isSubmitting: false
                });
            });
        closeStateModal();

    }

    const fetchCompanyData = async () => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        const _id = authTokens.account_id;

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/get_company_profile", { _id }, {
                headers: headers,
            })
            .then((response) => {
                setCompanyData(response.data);
                setAuthTokens({
                    ...authTokens,
                    currency: response.data.currency
                })
            })
            .catch(() => {
                setCompanyData({
                    ...companyData,
                    isSubmitting: false
                });
            });

        closeStateModal();
    }

    const fetchDashboardData = async () => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/get_advanced_dashboard_data", payload, {
                headers: headers,
            })
            .then((response) => {
                setPublicationsRatio(response.data.quotaRatioPublications);
                setPublicationsRatioPercent(response.data.quotaRatioPublicationsPercent > 100 ? 100 : response.data.quotaRatioPublicationsPercent);
                setCreditsRatio(response.data.quotaRatioCredits);
                setCreditsRatioPercent(response.data.quotaRatioCreditsPercent > 100 ? 100 : response.data.quotaRatioCreditsPercent);
            })
            .catch(() => {
            });
        closeStateModal();
    }

    useEffect(() => {
        fetchCompanyData();
        fetchDashboardData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            {showProfileUpdater ? <ProfileUpdate displayProfileUpdater={displayProfileUpdater} authTokens={authTokens} setAuthTokens={setAuthTokens} /> : ""}
            <div className="content_row">
                <div className="card flex-1">
                    <div className="title">
                        {t('my_profile')}
                        <div className='update_button' onClick={() => displayProfileUpdater()}>
                            <svg viewBox="0 0 24 24">
                                <path fill="current" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                            </svg>
                        </div>
                    </div>
                    <div className='column centered'>
                        <div className={isAdmin ? "profile_icon_big profile_blue border-gradient-blue" : "profile_icon_big profile_green border-gradient-green"} >
                            {
                                authTokens.user_profile_url ?
                                    <img src={authTokens.user_profile_url} alt={authTokens.user_first_name + " " + authTokens.name} />
                                    : authTokens.initials
                            }
                        </div>
                        <p className="text-grey margin-t1">{authTokens.user_first_name} {authTokens.user_name}</p>
                    </div>
                    <label className="text-bold margin-t2">{t('status')}</label>
                    <p className="text-grey">{isAdmin ? t('admin') : t('team_member')}</p>
                    <label className="text-bold margin-t2">{t('email')}</label>
                    <div className='row centered'>
                        <p className="text-grey">{authTokens.user_email}</p>
                    </div>
                    <label className="text-bold margin-t2">{t('password')}</label>
                    <div className='row centered'>
                        <p className="text-grey">***********</p>
                    </div>
                </div>

                <div className="card flex-1">
                    <div className="title">{t('current_plan')}</div>
                    <label className="text-bold">{currentPlan === 0 ? t('basic_plan') : authTokens.cai ? t('growth_plan') : t('organic_plan')}</label>
                    <p className="p-no-margin text-grey">{currentPlan > 0 ? 50 + (currentPlan - 1) * 150 : "15"} {t('publications_month')}</p>
                    <p className="p-no-margin text-grey">{!authTokens.cai ? '1 ' + t('user') : currentPlan === 1 ? '1 ' + t('user') : currentPlan + ' ' + t('users')}</p>
                    <p className="p-no-margin text-grey">{currentPlan === 0 ? t('no_nothing') : !authTokens.cai ? t('organic_credits') : currentPlan === 1 ? t('1_growth_credit') : currentPlan * 2 + ' ' + t('brands') + ', ' + Number(currentPlan - 1) * 25 + ' ' + t('ai_credits_month')}</p>
                    <label className="text-bold margin-t2">{t('expiry_date')}</label>
                    <p className="p-no-margin text-grey">{plan_expiry} </p>
                    <label className="text-bold margin-t2">{t('limits')}</label>

                    <p className="p-no-margin text-grey">{t('monthly_publications')} ({publicationsRatio})</p>
                    <div className={checkGaugeStyle(publicationsRatioPercent)}>
                        <span style={{ width: publicationsRatioPercent + "%" }}></span>
                    </div>
                    <p className="p-no-margin text-grey">{t('ai_credits')} ({creditsRatio})</p>
                    <div className={checkGaugeStyle(creditsRatioPercent)}>
                        <span style={{ width: creditsRatioPercent + "%" }}></span>
                    </div>
                    <div className="action_button margin-t2" onClick={goToPlans}>
                        {/* <svg className="margin-r1" viewBox="0 0 24 24">
                            <path fill="current" d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z" />
                        </svg> */}
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="m11 39.6 5.3-2.15q-.65-1.9-1.125-3.85-.475-1.95-.825-3.95L11 31.9Zm8.3-2.6h9.4q1.1-2.95 1.7-5.875Q31 28.2 31 26q0-5.75-1.65-10.1T24 7.65q-3.7 3.9-5.35 8.25Q17 20.25 17 26q0 2.2.6 5.125T19.3 37ZM24 25.5q-1.45 0-2.475-1.025Q20.5 23.45 20.5 22q0-1.45 1.025-2.475Q22.55 18.5 24 18.5q1.45 0 2.475 1.025Q27.5 20.55 27.5 22q0 1.45-1.025 2.475Q25.45 25.5 24 25.5Zm13 14.1v-7.7l-3.35-2.25q-.35 2-.825 3.95-.475 1.95-1.125 3.85ZM24 3.55q5.1 4.5 7.55 9.975T34 26v.25l4.65 3.15q.65.4 1 1.05t.35 1.4V44l-10-4H18L8 44V31.85q0-.75.35-1.4.35-.65 1-1.05L14 26.25V26q0-7 2.45-12.475T24 3.55Z" />
                        </svg>
                        {currentPlan > 0 ? t('go_to_plans') : t('upgrade_plan')}
                    </div>
                    {authTokens.plan > 0 ?
                        <div className="action_button_outline red margin-t2" onClick={e => goToCancellation()}>{t('cancel_plan')}</div>
                        : ""}
                </div>


                <div className="card flex-1">
                    <div className="title">
                        {t('company_profile')}
                        {companyData.isEditing ?
                            ""
                            :
                            <div className='update_button' onClick={() => setCompanyData({ ...companyData, isEditing: true })}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                </svg>
                            </div>
                        }

                    </div>
                    <label className="text-bold margin-t2">{t('company_name')}</label>
                    {companyData.isEditing ?
                        <input type="text" name="name" placeholder={t('company_name')} value={companyData.name} onChange={(e) => setCompanyData({ ...companyData, [e.target.name]: e.target.value })} />
                        :
                        <p className="p-no-margin text-grey">{companyData.name} </p>
                    }
                    <label className="text-bold margin-t2">{t('address')}</label>
                    {companyData.isEditing ?
                        <>
                            <input type="text" name="address_line_1" placeholder={t('address_line_1')} value={companyData.address_line_1} onChange={(e) => setCompanyData({ ...companyData, [e.target.name]: e.target.value })} />
                            <input type="text" className='margin-t1' name="address_line_2" placeholder={t('address_line_2')} value={companyData.address_line_2} onChange={(e) => setCompanyData({ ...companyData, [e.target.name]: e.target.value })} />
                        </>
                        :
                        <>
                            <p className="p-no-margin text-grey">{companyData.address_line_1} </p>
                            <p className="p-no-margin text-grey">{companyData.address_line_2} </p>
                        </>
                    }

                    <label className="text-bold margin-t2">{t('post_code')}</label>
                    {companyData.isEditing ?
                        <input type="text" name="post_code" placeholder={t('post_code')} value={companyData.post_code} onChange={(e) => setCompanyData({ ...companyData, [e.target.name]: e.target.value })} />
                        :
                        <p className="p-no-margin text-grey">{companyData.post_code} </p>
                    }
                    <label className="text-bold margin-t2">{t('city')}</label>
                    {companyData.isEditing ?
                        <input type="text" name="city" placeholder={t('city')} value={companyData.city} onChange={(e) => setCompanyData({ ...companyData, [e.target.name]: e.target.value })} />
                        :
                        <p className="p-no-margin text-grey">{companyData.city} </p>
                    }
                    <label className="text-bold margin-t2">{t('country')}</label>
                    {companyData.isEditing ?
                        <input type="text" name="country" placeholder={t('country')} value={companyData.country} onChange={(e) => setCompanyData({ ...companyData, [e.target.name]: e.target.value })} />
                        :
                        <p className="p-no-margin text-grey">{companyData.country} </p>
                    }
                    {companyData.isEditing ?
                        ""
                        :
                        <>
                            <label className="text-bold margin-t2">{t('currency')}</label>
                            <p className="p-no-margin text-grey">{checkCurrencySymbol(companyData.currency)} </p>
                        </>
                    }
                    <label className="text-bold margin-t2">{t('additional_data')}</label>
                    {companyData.isEditing ?
                        <input type="text" name="other_data" placeholder={t('additional_data')} value={companyData.other_data} onChange={(e) => setCompanyData({ ...companyData, [e.target.name]: e.target.value })} />
                        :
                        <p className="p-no-margin text-grey">{companyData.other_data} </p>
                    }
                    {companyData.isEditing ?
                        <div className="action_button margin-t2" onClick={() => editCompanyProfile()}>
                            {companyData.isSubmitting ?
                                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                :
                                <>
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="M24.15 42q-3.75 0-7.05-1.425T11.325 36.7q-2.475-2.45-3.9-5.75Q6 27.65 6 23.9t1.425-7q1.425-3.25 3.9-5.675Q13.8 8.8 17.1 7.4 20.4 6 24.15 6q4 0 7.575 1.75t6.175 4.8v-5.3h3v10.4H30.45v-3h5.25q-2.2-2.55-5.175-4.1Q27.55 9 24.15 9q-6.25 0-10.7 4.275Q9 17.55 9 23.75q0 6.35 4.4 10.8Q17.8 39 24.15 39q6.25 0 10.55-4.4Q39 30.2 39 23.95h3q0 7.5-5.2 12.775T24.15 42Zm6.1-9.85-7.7-7.6v-10.7h3v9.45L32.4 30Z" />
                                    </svg>

                                    {t('update_company')}
                                </>
                            }
                        </div>
                        : ""
                    }
                </div>
                <div className="card flex-1">
                    <div className="title">{t('invoices')}</div>
                    <Invoices authTokens={authTokens} headers={headers} />
                </div>
            </div>
        </div >
    )
}

export default Account