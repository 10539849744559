import React, { useState, useLayoutEffect, useRef } from 'react'
import { useTranslation } from "react-i18next";
// import Tooltip from '../../sidebar/Tooltip';
import TooltipHelp from '../../settings/TooltipHelp';

import ContentMedia from './ContentMedia';
import AccountsSelectionPN from './AccountsSelectionPN';
import Picker from 'emoji-picker-react';
import ComposerCoAICaption from '../../Composer/ComposerComponents/ComposerCoAICaption';


// import FBicon from "../../../images/social_media/facebook.svg";
// import TWicon from "../../../images/social_media/twitter.svg";
// import LKicon from "../../../images/social_media/linkedin.svg";
// import IGicon from "../../../images/social_media/instagram.svg";
// import GMBicon from "../../../images/social_media/gmb.svg";
// import TKTKicon from "../../../images/social_media/tiktok-spk.svg";
// import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"



const MIN_TEXTAREA_TITLE_HEIGHT = 10;
const MIN_TEXTAREA_HEIGHT = 60;
const MAX_TITLE_COUNT = 100;
const MAX_DESC_COUNT = 500;



const ContentPN = ({
    editorMenuItem,
    authTokens,
    currentPlan,
    displayUpgradeModal,
    publicationData,
    setPublicationData,
    PNAccounts,
    selectedBoards,
    setSelectedBoards,
    videoMeta,
    setVideoMeta,
    setLoadVideoChecker,
    stateModalOptions,
    setStateModalOptions,
    mediaTransfer,
    videoCheckerResults,
    setVideoCheckerResults
}) => {
    const { t } = useTranslation(['composer']);

    const textAreaTitleRef = useRef(null);
    const textAreaRef = useRef(null);

    const [showAccountsSelection, setShowAccountsSelection] = useState(false);
    const displayAccountSelection = () => {
        setShowAccountsSelection(!showAccountsSelection);
    }

    const [titleCharacterCount, setTitleCharacterCount] = useState(MAX_TITLE_COUNT);
    const [descCharacterCount, setDescCharacterCount] = useState(MAX_DESC_COUNT);

    const handleCharacterCountTitle = (e) => {
        const count = 100 - e.target.value.length
        setTitleCharacterCount(count);
    }

    const handleCharacterCountDescription = (e) => {
        const count = 500 - e.target.value.length;
        setDescCharacterCount(count);
    }

    // const checkAccountPhoto = (accountData) => {

    //     if (!accountData) return;

    //     switch (accountData.media) {
    //         case "Facebook":
    //             let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
    //             if (accountData.type === "group") {
    //                 fb_picture_url = "https://graph.facebook.com/v12.0/" + accountData.social_id + "/picture?access_token=" + accountData.access_token;
    //             }

    //             return (
    //                 <Tooltip content={accountData.social_name} direction="bottom">
    //                     <div className="account_profile_photo selected">
    //                         <img className="profile_picture" src={fb_picture_url} alt={accountData.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
    //                         <img className="account_profile_media" src={FBicon} alt="Media" />
    //                     </div>
    //                 </Tooltip>
    //             )
    //         case "Twitter":
    //             return (
    //                 <Tooltip content={accountData.social_name} direction="bottom">
    //                     <div className="account_profile_photo selected">
    //                         <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
    //                         <img className="account_profile_media" src={TWicon} alt="Media" />
    //                     </div>
    //                 </Tooltip>
    //             )
    //         case "LinkedIn":
    //             return (
    //                 <Tooltip content={accountData.social_name} direction="bottom">
    //                     <div className="account_profile_photo selected">
    //                         <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
    //                         <img className="account_profile_media" src={LKicon} alt="Media" />
    //                     </div>
    //                 </Tooltip>
    //             )
    //         case "Instagram":
    //             return (
    //                 <Tooltip content={accountData.social_name} direction="bottom">
    //                     <div className="account_profile_photo selected">
    //                         <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
    //                         <img className="account_profile_media" src={IGicon} alt="Media" />
    //                     </div>
    //                 </Tooltip>
    //             )
    //         case "GMB":
    //             return (
    //                 <Tooltip content={accountData.social_name} direction="bottom">
    //                     <div className="account_profile_photo selected">
    //                         <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
    //                         <img className="account_profile_media" src={GMBicon} alt="Media" />
    //                     </div>
    //                 </Tooltip>
    //             )
    //         case "TikTok":
    //             return (
    //                 <Tooltip content={accountData.social_name} direction="bottom">
    //                     <div className="account_profile_photo selected">
    //                         <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
    //                         <img className="account_profile_media" src={TKTKicon} alt="Media" />
    //                     </div>
    //                 </Tooltip>
    //             )
    //         default:
    //             return (
    //                 <Tooltip content={accountData.social_name} direction="bottom">
    //                     <div className="account_profile_photo selected">
    //                         <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
    //                         <img className="account_profile_media" src={TWicon} alt="Media" />
    //                     </div>
    //                 </Tooltip>
    //             )
    //     }
    // }

    // CAPTION COMPONENTS

    const [showEmoji, setShowEmoji] = useState(false);
    const displayEmoji = () => {
        setShowEmoji(!showEmoji);
    }

    const onEmojiClick = (emojiData) => {
        const cursorPosition = textAreaRef.current.selectionStart;
        const new_text = publicationData.text.substring(0, cursorPosition) + emojiData.emoji + publicationData.text.substring(cursorPosition);

        setPublicationData({
            ...publicationData,
            text: new_text
        });

        setDescCharacterCount(MAX_DESC_COUNT - publicationData.text.length - 2);
    };

    const [showAICaption, setShowAICaption] = useState(false);
    const displayAICaption = () => {
        if (currentPlan > 0) {
            setShowAICaption(!showAICaption);
        }
        else {
            displayUpgradeModal();
        }
    }


    const handleInputChange = (e) => {
        setPublicationData({
            ...publicationData,
            [e.target.name]: e.target.value
        })
    }


    useLayoutEffect(() => {
        textAreaTitleRef.current.style.height = "inherit";
        textAreaTitleRef.current.style.height = `${Math.max(
            textAreaTitleRef.current.scrollHeight + 10,
            MIN_TEXTAREA_TITLE_HEIGHT
        )}px`;

    }, [publicationData.tags]);

    useLayoutEffect(() => {
        textAreaRef.current.style.height = "inherit";
        textAreaRef.current.style.height = `${Math.max(
            textAreaRef.current.scrollHeight + 30,
            MIN_TEXTAREA_HEIGHT
        )}px`;

    }, [publicationData.text]);

    return (
        <>
            <div className={editorMenuItem === 'content' ? 'editor_component active' : 'editor_component'}>
                <div className='editor_title margin-t1'>{t('boards_selection')}</div>
                <div className='accounts_selection margin-t1'>
                    {publicationData.boards && publicationData.boards.length > 0 ?
                        publicationData.boards.map((board, i) => {
                            return (
                                <div className="account_selection_board" key={i} >
                                    {board.name}
                                </div>
                            )
                        })
                        : ''}

                    <div className='select_account' onClick={displayAccountSelection}>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M22.5 38V25.5H10v-3h12.5V10h3v12.5H38v3H25.5V38Z" />
                        </svg>
                    </div>
                </div>
                <div className='captions margin-t2'>
                    <div className='editor_title'>{t('pin_title_composition')}</div>
                    <div className='editor_textarea_block margin-t1'>
                        <textarea ref={textAreaTitleRef} name="tags" className='editor' placeholder={t('pin_title_placeholder')} style={{ minHeight: MIN_TEXTAREA_TITLE_HEIGHT, resize: "none" }} value={publicationData.tags} onChange={e => { handleInputChange(e); handleCharacterCountTitle(e); }}></textarea>
                        <div className='editor_caption_icons'>
                            <div className='editor_caption_icon_block'>
                            </div>
                            <div className='editor_caption_icon_block'>
                                <p className={titleCharacterCount > 10 ? 'text-light-grey' : titleCharacterCount > 0 ? 'text-yellow' : 'text-red'}>{titleCharacterCount}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='captions margin-t2'>
                    <div className='editor_title'>{t('pin_description_composition')}</div>
                    <div className='editor_textarea_block margin-t1'>
                        <textarea ref={textAreaRef} name="text" className='editor' placeholder={t('pin_description_placeholder')} style={{ minHeight: MIN_TEXTAREA_HEIGHT, resize: "none" }} value={publicationData.text} onChange={e => { handleInputChange(e); handleCharacterCountDescription(e); }}></textarea>
                        <div className='editor_caption_icons'>
                            <div className='editor_caption_icon_block'>
                                <TooltipHelp content={t('ai_caption')} direction="right">
                                    <svg viewBox="0 0 48 48" onClick={displayAICaption}>
                                        <path fill="current" d="m9.35 20.45 5.3 2.25q.9-1.8 1.925-3.55Q17.6 17.4 18.75 15.8L14.8 15Zm7.7 4.05 6.65 6.65q2.85-1.3 5.35-2.95 2.5-1.65 4.05-3.2 4.05-4.05 5.95-8.3 1.9-4.25 2.05-9.6-5.35.15-9.6 2.05t-8.3 5.95q-1.55 1.55-3.2 4.05-1.65 2.5-2.95 5.35Zm11.45-4.8q-1-1-1-2.475t1-2.475q1-1 2.475-1t2.475 1q1 1 1 2.475t-1 2.475q-1 1-2.475 1t-2.475-1Zm-.75 19.15 5.45-5.45-.8-3.95q-1.6 1.15-3.35 2.175T25.5 33.55Zm16.3-34.7q.45 6.8-1.7 12.4-2.15 5.6-7.1 10.55l-.1.1-.1.1 1.1 5.5q.15.75-.075 1.45-.225.7-.775 1.25l-8.55 8.6-4.25-9.9-8.5-8.5-9.9-4.25 8.6-8.55q.55-.55 1.25-.775.7-.225 1.45-.075l5.5 1.1q.05-.05.1-.075.05-.025.1-.075 4.95-4.95 10.55-7.125 5.6-2.175 12.4-1.725Zm-36.6 27.6Q9.2 30 11.725 29.975 14.25 29.95 16 31.7q1.75 1.75 1.725 4.275Q17.7 38.5 15.95 40.25q-1.3 1.3-4.025 2.15Q9.2 43.25 3.75 44q.75-5.45 1.575-8.2.825-2.75 2.125-4.05Zm2.1 2.15q-.7.75-1.25 2.35t-.95 4.1q2.5-.4 4.1-.95 1.6-.55 2.35-1.25.95-.85.975-2.125.025-1.275-.875-2.225-.95-.9-2.225-.875-1.275.025-2.125.975Z" />
                                    </svg>
                                </TooltipHelp>
                                <TooltipHelp content={t('emoji_selection')} direction="right">
                                    <svg viewBox="0 0 24 24" onClick={displayEmoji}>
                                        <path fill="current" d="M12,17.5C14.33,17.5 16.3,16.04 17.11,14H6.89C7.69,16.04 9.67,17.5 12,17.5M8.5,11A1.5,1.5 0 0,0 10,9.5A1.5,1.5 0 0,0 8.5,8A1.5,1.5 0 0,0 7,9.5A1.5,1.5 0 0,0 8.5,11M15.5,11A1.5,1.5 0 0,0 17,9.5A1.5,1.5 0 0,0 15.5,8A1.5,1.5 0 0,0 14,9.5A1.5,1.5 0 0,0 15.5,11M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                    </svg>
                                </TooltipHelp>
                            </div>
                            <div className='editor_caption_icon_block'>
                                <p className={descCharacterCount > 50 ? 'text-light-grey' : descCharacterCount > 0 ? 'text-yellow' : 'text-red'}>{descCharacterCount}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='editor_title margin-t2'>{t('link')}</div>
                <div className='editor_input_block margin-t1'>
                    <input type="text" name="link_url" className="editor" placeholder='https://www.yourlink.com' value={publicationData.link_url} onChange={(e) => handleInputChange(e)} />
                </div>
                <ContentMedia
                    authTokens={authTokens}
                    pinterest={true}
                    displayUpgradeModal={displayUpgradeModal}
                    publicationData={publicationData}
                    setPublicationData={setPublicationData}
                    videoMeta={videoMeta}
                    setVideoMeta={setVideoMeta}
                    setLoadVideoChecker={setLoadVideoChecker}
                    stateModalOptions={stateModalOptions}
                    setStateModalOptions={setStateModalOptions}
                    mediaTransfer={mediaTransfer}
                    bulk={false}
                    videoCheckerResults={videoCheckerResults}
                    setVideoCheckerResults={setVideoCheckerResults}
                />


            </div>
            {showAccountsSelection ?
                <>
                    <div className="transparent_background" onClick={displayAccountSelection}></div>
                    <div className="unsplash_picker">
                        <span className="close_modal" onClick={displayAccountSelection}>&times;</span>
                        <AccountsSelectionPN PNAccounts={PNAccounts} publicationData={publicationData} setPublicationData={setPublicationData} selectedBoards={selectedBoards} setSelectedBoards={setSelectedBoards} />
                    </div>
                </>
                : ""
            }
            {showAICaption ?
                <>
                    <div className="transparent_background" onClick={displayAICaption}></div>
                    <div className="unsplash_picker">
                        <span className="close_modal" onClick={displayAICaption}>&times;</span>
                        <ComposerCoAICaption publicationData={publicationData} setPublicationData={setPublicationData} />
                    </div>
                </>
                : ""
            }
            {showEmoji ?
                <>
                    <div className="transparent_background" onClick={displayEmoji}></div>
                    <div className="unsplash_picker">
                        <span className="close_modal" onClick={displayEmoji}>&times;</span>
                        <Picker onEmojiClick={onEmojiClick} width={350} height={450} />
                    </div>
                </>
                : ""}
        </>
    )
}

export default ContentPN