import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import Tooltip from '../sidebar/Tooltip';
import Draggable from 'react-draggable';

import "./image_cropper.css";
import "./image_tagger.css";
import IGicon from "../../images/social_media/instagram.svg";



const ImageProductTagger = ({ authTokens, displayProductTagger, publicationData, setPublicationData, displayIGXtraPermissionsModal }) => {
    const { t } = useTranslation(['composer']);
    const imgRef = useRef(null);

    const headers = {
        Authorization: authTokens.token,
    };

    const [imgSrc, setImgSrc] = useState();
    const [selectedIndex, setSelectedIndex] = useState(0);


    const changeImgToTag = (index) => {
        if (publicationData.medias && publicationData.medias.length > 0) {
            setImgSrc(publicationData.medias[index].file_url);
            setSelectedIndex(index);
        }
    }

    const [selectedIGAccount, setSelectedIGAccount] = useState();
    const [taggableProducts, setTaggableProducts] = useState([]);
    const [showCatalogLoader, setShowCatalogLoader] = useState(false);
    const [showProductLoader, setShowProductLoader] = useState(false);
    const [showIGError, setShowIGError] = useState(false);

    const checkAccountPhoto = (accountData) => {

        if (!accountData) return;

        if (selectedIGAccount && selectedIGAccount.account && accountData._id === selectedIGAccount.account._id) {
            return (
                <Tooltip content={accountData.social_name} direction="bottom">
                    <div className="account_profile_photo selected">
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" />
                        <img className="account_profile_media" src={IGicon} alt="Media" />
                    </div>
                </Tooltip>
            )
        }
        else {
            return (
                <Tooltip content={accountData.social_name} direction="bottom">
                    <div className="account_profile_photo unselected">
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" />
                        <img className="account_profile_media" src={IGicon} alt="Media" />
                    </div>
                </Tooltip>
            )
        }


    }

    const selectIGAccount = (account) => {
        // Check if account is eligible for product tagging and get catalogs

        if (!account) return;

        setShowCatalogLoader(true);
        setShowIGError(false);
        setTaggableProducts([]);

        const payload = {
            _id: account._id,
            company_id: authTokens.account_id,
            social_id: account.social_id
        }


        axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/scraper/ig_catalog_lookup", payload, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.lookup_result) {
                    setSelectedIGAccount(response.data);
                    setShowCatalogLoader(false);
                }
                else if ('permission_update' in response.data && response.data.permission_update) {
                    displayIGXtraPermissionsModal();
                }
                else {
                    setShowIGError(true);
                    setShowCatalogLoader(false);
                }
            })
            .catch(() => {
                setShowIGError(true);
                setShowCatalogLoader(false);
            });

        // setSelectedIGAccount({
        //     account: account,
        //     product_tagging_eligible: false,
        //     catalogs: [
        //         {
        //             "catalog_id": "960179311066902",
        //             "catalog_name": "Jay's Favorite Snacks",
        //             "shop_name": "Jay's Bespoke",
        //             "product_count": 11
        //         }
        //     ]
        // })

    }

    const selectCatalog = (catalog) => {
        // Get all products in catalog

        if (!catalog) return;

        setShowProductLoader(true);
        setShowIGError(false);

        console.log(selectedIGAccount);

        const payload = {
            _id: selectedIGAccount.account._id,
            company_id: authTokens.account_id,
            social_id: selectedIGAccount.account.social_id,
            catalog_id: catalog.catalog_id
        }

        axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/scraper/ig_catalog_products_lookup", payload, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.lookup_result) {
                    setTaggableProducts(response.data.products);
                    setShowProductLoader(false);
                }
                else {
                    setShowIGError(true);
                    setShowProductLoader(false);
                }
            })
            .catch(() => {
                setShowIGError(true);
                setShowProductLoader(false);
            });


        // setTaggableProducts([
        //     {
        //         "product_id": "3231775643511089",
        //         "product_name": "Gummy Wombats"
        //     }
        // ])

    }


    const tagProduct = (product) => {
        let updated_tags_array = [];

        if (publicationData.medias[selectedIndex].ig_product_tags && publicationData.medias[selectedIndex].ig_product_tags.length > 0) {
            updated_tags_array = [...publicationData.medias[selectedIndex].ig_product_tags, product];
        }
        else {
            updated_tags_array = [product];
        }
        const updated_media = {
            ...publicationData.medias[selectedIndex],
            ig_product_tags: updated_tags_array
        }

        setPublicationData({
            ...publicationData,
            medias: [
                ...publicationData.medias.slice(0, selectedIndex),
                updated_media,
                ...publicationData.medias.slice(selectedIndex + 1, publicationData.medias.length),
            ]
        })
    }

    const untagProduct = (product) => {
        console.log(product, publicationData.medias[selectedIndex].ig_product_tags);

        const updated_tags_array = publicationData.medias[selectedIndex].ig_product_tags.filter(tagData => tagData.product_id !== product.product_id);

        console.log(updated_tags_array);

        const updated_media = {
            ...publicationData.medias[selectedIndex],
            ig_product_tags: updated_tags_array
        }
        setPublicationData({
            ...publicationData,
            medias: [
                ...publicationData.medias.slice(0, selectedIndex),
                updated_media,
                ...publicationData.medias.slice(selectedIndex + 1, publicationData.medias.length),
            ]
        })
    }

    const checkProductTagCSS = (product) => {
        if (publicationData.medias[selectedIndex].ig_product_tags && publicationData.medias[selectedIndex].ig_product_tags.length > 0) {
            const check_selected_tag_index = publicationData.medias[selectedIndex].ig_product_tags.findIndex(tagData => tagData.product_id === product.product_id);

            if (check_selected_tag_index !== -1) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }

    }

    const updateTagPosition = (e, data) => {
        const product_id = e.target.id;
        e.preventDefault();

        const position_X = data.x / currentImgWidth;
        const position_Y = (data.y + currentImgHeight / 2) / currentImgHeight;


        // Look for the right tag
        const tag_index = publicationData.medias[selectedIndex].ig_product_tags.findIndex(tagData => tagData.product_id === Number(product_id));

        if (tag_index !== -1) {
            const updated_tag = {
                ...publicationData.medias[selectedIndex].ig_product_tags[tag_index],
                position_X: data.x,
                position_Y: data.y,
                ig_X: position_X,
                ig_Y: position_Y
            }

            const updated_tags = [
                ...publicationData.medias[selectedIndex].ig_product_tags.slice(0, tag_index),
                updated_tag,
                ...publicationData.medias[selectedIndex].ig_product_tags.slice(tag_index + 1, publicationData.medias[selectedIndex].ig_product_tags.length),
            ];

            const updated_media = {
                ...publicationData.medias[selectedIndex],
                ig_product_tags: updated_tags
            }

            setPublicationData({
                ...publicationData,
                medias: [
                    ...publicationData.medias.slice(0, selectedIndex),
                    updated_media,
                    ...publicationData.medias.slice(selectedIndex + 1, publicationData.medias.length),
                ]
            })
        }

    }


    const [currentImgWidth, setCurrentImgWidth] = useState(0);
    const [currentImgHeight, setCurrentImgHeight] = useState(0);
    const getImgSize = ({ target: img }) => {
        const { offsetHeight, offsetWidth } = img;
        setCurrentImgWidth(offsetWidth);
        setCurrentImgHeight(offsetHeight);
    };


    useEffect(() => {
        if (publicationData.medias && publicationData.medias.length > 0) {
            setImgSrc(publicationData.medias[0].file_url);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="img_cropper_bg active"></div>
            <div className="img_cropper_wrapper">
                <span className="close_modal" onClick={displayProductTagger}>&times;</span>
                <div className="img_cropper_title">{t('ig_product_tagging')}</div>
                <div className="img_cropper_content">
                    {publicationData.medias && publicationData.medias.length > 0 ?
                        <div className='sources_photos flex-1'>
                            <div className="tagging_title margin-b2">{t('ig_photos_tagging_select_photo')}</div>
                            <div className="source_photos_stack">
                                {publicationData.medias.map((imageRaw, i) => {
                                    return (
                                        <div key={i} className={selectedIndex === i ? 'source_photo_stack mini-img selected' : 'source_photo_stack mini-img'} onClick={() => changeImgToTag(i)}>
                                            <img src={imageRaw.file_url} alt="" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        : ""}
                    <div className='sources_photos flex-1 relative_holder'>
                        <div className="tagging_title margin-b2">{t('ig_product_tagging_select_account')}</div>
                        <div className='row row_vcenter'>
                            {publicationData.social_accounts && publicationData.social_accounts.length > 0 ?
                                publicationData.social_accounts.map((account, i) => {
                                    if (account.media === "Instagram") {
                                        return (
                                            <div className="account_selection_account" key={i} onClick={e => selectIGAccount(account)}>
                                                {checkAccountPhoto(account)}
                                            </div>
                                        )
                                    }
                                }
                                )
                                : ""
                            }
                        </div>
                        {selectedIGAccount && 'product_tagging_eligible' in selectedIGAccount && selectedIGAccount.product_tagging_eligible === false ?
                            <p className='text-red'>{t('ig_account_not_eligible_product')}</p>
                            : ""
                        }
                        <div className="tagging_title margin-b2 margin-t2">{t('ig_product_tagging_select_catalog')}</div>

                        <div className="mentions_list margin-t2">
                            {selectedIGAccount && selectedIGAccount.catalogs && selectedIGAccount.catalogs.length > 0 ?
                                selectedIGAccount.catalogs.map((catalog, c) => {
                                    return (
                                        <div className="mention_account" key={"catalog_" + c} onClick={() => selectCatalog(catalog)}>
                                            <div className='tag_account_profile'>
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="current" d="M19 1L14 6V17L19 12.5V1M21 5V18.5C19.9 18.15 18.7 18 17.5 18C15.8 18 13.35 18.65 12 19.5V6C10.55 4.9 8.45 4.5 6.5 4.5C4.55 4.5 2.45 4.9 1 6V20.65C1 20.9 1.25 21.15 1.5 21.15C1.6 21.15 1.65 21.1 1.75 21.1C3.1 20.45 5.05 20 6.5 20C8.45 20 10.55 20.4 12 21.5C13.35 20.65 15.8 20 17.5 20C19.15 20 20.85 20.3 22.25 21.05C22.35 21.1 22.4 21.1 22.5 21.1C22.75 21.1 23 20.85 23 20.6V6C22.4 5.55 21.75 5.25 21 5M10 18.41C8.75 18.09 7.5 18 6.5 18C5.44 18 4.18 18.19 3 18.5V7.13C3.91 6.73 5.14 6.5 6.5 6.5C7.86 6.5 9.09 6.73 10 7.13V18.41Z" />
                                                </svg>
                                            </div>
                                            <div className='tag_account_name'>
                                                {catalog.catalog_name}
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                showCatalogLoader ?
                                    <div className="lds-ring-loader-small"><div></div><div></div><div></div><div></div></div>
                                    :
                                    ""
                            }
                        </div>
                        <div className="tagging_title margin-b2 margin-t2">{t('ig_product_tagging_select_product')}</div>
                        <div className="mentions_list margin-t2">
                            {taggableProducts && taggableProducts.length > 0 ?
                                taggableProducts.map((product, p) => {
                                    return (
                                        <div className="mention_account" key={"product_" + p}>
                                            <div className='column' onClick={() => tagProduct(product)} >
                                                <div className='tag_product'>
                                                    {product.image_url && product.image_url.length > 0 ?
                                                        <img src={product.image_url} alt="Product to tag" />
                                                        :
                                                        ""
                                                    }
                                                </div>
                                                <div className='tag_product_name'>
                                                    {product.product_name}
                                                </div>
                                            </div>
                                            {
                                                checkProductTagCSS(product) ?
                                                    <div className='delete_mention' onClick={() => untagProduct(product)}>
                                                        <svg viewBox="0 0 24 24">
                                                            <path fill="current" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
                                                        </svg>
                                                    </div>

                                                    : ""
                                            }
                                        </div>
                                    )
                                })
                                :
                                showProductLoader ?
                                    <div className="lds-ring-loader-small"><div></div><div></div><div></div><div></div></div>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    <div className='flex-1'>
                        <div className="tagging_title margin-b2">{t('ig_product_tagging_place_tag')}</div>
                        <div className='tagging_window'>
                            {publicationData.medias[selectedIndex].ig_product_tags && publicationData.medias[selectedIndex].ig_product_tags.length > 0 ?
                                publicationData.medias[selectedIndex].ig_product_tags.map((product, a) => {
                                    return (
                                        <Draggable
                                            key={"photo_tag_" + a}
                                            bounds="parent"
                                            position={{ x: product.position_X || 0, y: product.position_Y || 0 }}
                                            onStop={(e, data) => updateTagPosition(e, data)}>
                                            <div className="photo_tag" id={product.product_id}>
                                                {product.product_name}
                                            </div>
                                        </Draggable>
                                    )

                                })
                                : ""
                            }
                            <img
                                ref={imgRef}
                                alt="Tag me"
                                src={imgSrc}
                                onLoad={(e) => getImgSize(e)}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default ImageProductTagger