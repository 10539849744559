import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import TooltipHelp from '../../settings/TooltipHelp';
import StateModal from '../../Loader/StateModal';

import "./custom_scheduler.css";

const CustomScheduler = ({ authTokens, selectedSchedule, handleSelectedCustomSchedule }) => {
    const { t } = useTranslation(['custom_scheduler']);
    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }
    const time_steps = [];
    for (let h = 0; h < 24; h++) {
        time_steps.push(<option value={h} key={h}>{h}:00</option>);
    }

    const time_zones = [];
    for (let h = -11; h < 14; h++) {
        if (h < 0) {
            time_zones.push(<option value={h * 60} key={h}>GMT{h}</option>);
        }
        else {
            time_zones.push(<option value={h * 60} key={h}>GMT+{h}</option>);
        }
    }

    const headers = {
        Authorization: authTokens.token,
    };

    const initCustomSchedule = {
        company_id: authTokens.account_id,
        days: [],
        from: '',
        to: '',
        tz_offset: -1 * (new Date().getTimezoneOffset()),
        schedule_name: ''
    }

    const [customSchedule, setCustomSchedule] = useState(initCustomSchedule)
    const [customSchedules, setCustomSchedules] = useState([]);

    const selectSchedule = (e) => {

        if (!e.target.value) {
            handleSelectedCustomSchedule();
            setCustomSchedule(initCustomSchedule)
            return;
        }

        const filteredGroupData = customSchedules.filter(sched => sched._id === e.target.value);
        setCustomSchedule(filteredGroupData[0]);
        handleSelectedCustomSchedule(filteredGroupData[0]);
    }

    const [daysCheckBoxes, setDaysCheckBoxes] = useState({
        weekends: false,
        weekdays: false
    })

    const toggleDay = (day) => {
        if (day == null) return;
        const weekdays_value = [1, 2, 3, 4, 5];

        let schedule_name = customSchedule.schedule_name;
        if (selectedSchedule._id) { schedule_name = '' };
        handleSelectedCustomSchedule()

        if (weekdays_value.includes(day)) {
            setDaysCheckBoxes({
                ...daysCheckBoxes,
                weekdays: false
            });
        }
        else {
            setDaysCheckBoxes({
                ...daysCheckBoxes,
                weekends: false
            });
        }

        let updated_days_array = [];
        if (customSchedule.days.includes(day)) {
            updated_days_array = customSchedule.days.filter(current_day => current_day !== day);
        }
        else {
            updated_days_array = [...customSchedule.days, day];
        }

        setCustomSchedule({
            ...customSchedule,
            days: updated_days_array,
            schedule_name: schedule_name
        })
    }

    const handleDays = (e) => {

        let schedule_name = customSchedule.schedule_name;
        if (selectedSchedule._id) { schedule_name = '' };
        handleSelectedCustomSchedule()

        setDaysCheckBoxes({
            ...daysCheckBoxes,
            [e.target.name]: e.target.checked
        });
        let days_value = [1, 2, 3, 4, 5];
        if (e.target.name === 'weekends') {
            days_value = [6, 0];
        }

        let updated_days_array = [...customSchedule.days];
        if (e.target.checked) {
            updated_days_array = updated_days_array.filter(item => !days_value.includes(item));
            updated_days_array = updated_days_array.concat(days_value);
        }
        else {
            updated_days_array = updated_days_array.filter(item => !days_value.includes(item));
        }

        setCustomSchedule({
            ...customSchedule,
            days: updated_days_array,
            schedule_name: schedule_name
        })
    }

    const saveScheduler = async () => {
        console.log("hello");
        if (customSchedule.days.length === 0) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('select_at_least_one_day')
            });
            return
        }

        if (!customSchedule.from) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('select_from')
            });
            return
        }

        if (!customSchedule.to) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('select_to')
            });
            return
        }

        console.log(customSchedule.to, customSchedule.from);
        if (Number(customSchedule.to) <= Number(customSchedule.from)) {
            console.log("below");
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('to_after_from')
            });
            return
        }

        if (!customSchedule.schedule_name) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('give_schedule_name')
            });
            return
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/save_scheduler", customSchedule, {
                headers: headers,
            })
            .then((response) => {
                console.log(response.data);
                setCustomSchedules([...customSchedules, response.data]);
                handleSelectedCustomSchedule(response.data);
            })
            .catch(() => {
            });
    }

    const fetchCustomSchedules = async () => {

        const payload = {
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/get_custom_schedule", payload, {
                headers: headers,
            })
            .then((response) => {
                setCustomSchedules(response.data);
            })
            .catch(() => {
            });
    }

    useEffect(() => {
        fetchCustomSchedules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div>
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            <div className='row'>
                {customSchedules && customSchedules.length > 0 ?
                    <select name="schedules" className='margin-l1' onChange={e => selectSchedule(e)} value={selectedSchedule._id}>
                        <option value="" selected={!selectedSchedule._id ? true : false}>{t('select_custom_schedule')}</option>
                        {customSchedules.map((schedule, i) => {
                            return (
                                <option key={i} value={schedule._id}>{schedule.schedule_name}</option>
                            )
                        })}
                    </select>
                    : ""}
            </div>
            <div className='divider'></div>
            <div className='row margin-t1'>
                <label>{t('or_create_new_one')}</label>
                <TooltipHelp content={t('tooltip_publication')} direction="right">
                    <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                        <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                </TooltipHelp>
            </div>

            <div className='row margin-t2'>
                <input type="checkbox" name="weekdays" checked={daysCheckBoxes.weekdays} onChange={handleDays} />
                <label>{t('weekdays')}</label>
                <input type="checkbox" name="weekends" checked={daysCheckBoxes.weekends} onChange={handleDays} />
                <label>{t('weekends')}</label>
            </div>
            <div className='row margin-t1'>
                <div className={customSchedule.days.includes(1) ? "day_button selected" : "day_button"} onClick={() => toggleDay(1)}>{t('mon')}</div>
                <div className={customSchedule.days.includes(2) ? "day_button selected" : "day_button"} onClick={() => toggleDay(2)}>{t('tue')}</div>
                <div className={customSchedule.days.includes(3) ? "day_button selected" : "day_button"} onClick={() => toggleDay(3)}>{t('wed')}</div>
                <div className={customSchedule.days.includes(4) ? "day_button selected" : "day_button"} onClick={() => toggleDay(4)}>{t('thu')}</div>
                <div className={customSchedule.days.includes(5) ? "day_button selected" : "day_button"} onClick={() => toggleDay(5)}>{t('fri')}</div>
                <div className={customSchedule.days.includes(6) ? "day_button selected" : "day_button"} onClick={() => toggleDay(6)}>{t('sat')}</div>
                <div className={customSchedule.days.includes(0) ? "day_button selected" : "day_button"} onClick={() => toggleDay(0)}>{t('sun')}</div>

            </div>
            <div className='row nowrap bottomed margin-t2'>
                <div>
                    <label>{t('from_time')}</label>
                    <select className="time_select" value={customSchedule.from} onChange={e => setCustomSchedule({ ...customSchedule, from: e.target.value })}>
                        <option value="">--:--</option>
                        {time_steps}
                    </select>
                </div>
                <div className='margin-l1'>
                    <label>{t('to_time')}</label>
                    <select className="time_select" value={customSchedule.to} onChange={e => setCustomSchedule({ ...customSchedule, to: e.target.value })}>
                        <option value="">--:--</option>
                        {time_steps}
                    </select>
                </div>
                <div className='margin-l1'>
                    <label>{t('time_zone')}</label>
                    <select className="time_select" value={customSchedule.tz_offset} onChange={e => setCustomSchedule({ ...customSchedule, tz_offset: e.target.value })}>
                        <option value="">--:--</option>
                        {time_zones}
                    </select>
                </div>
                {!selectedSchedule._id ?
                    <div className='margin-l1'>
                        <label>{t('schedule_name')}</label>
                        <div className='form_row_save_input'>
                            <input type="text" className='dashboard_input' placeholder={t('my_schedule')} name="schedule_name" value={customSchedule.schedule_name} onChange={e => setCustomSchedule({ ...customSchedule, schedule_name: e.target.value })} />
                            <svg viewBox="0 0 24 24" onClick={saveScheduler}>
                                <path fill="current" d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                            </svg>
                        </div>
                    </div>
                    : ""}
            </div>




        </div>
    )
}

export default CustomScheduler