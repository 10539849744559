import React, { useState } from 'react'
import { useTranslation } from "react-i18next";

const CAICoCaption = ({ authTokens, headers, menuItem, setMenuItem, captions, setCaptions, itemsUnlocked, setItemsUnlocked, selectedCaptions, setSelectedCaptions }) => {
    const { t } = useTranslation(['content_ai']);

    const [customCaption, setCustomCaption] = useState({
        caption_id: 'manual_caption',
        text: ''
    });
    const handleCustomCaption = (value) => {
        setCustomCaption({
            ...customCaption,
            text: value
        });

    }

    const selectCaption = (caption) => {
        // findIndex
        const selectedIndex = selectedCaptions.findIndex(selected_caption => selected_caption.caption_id === caption.caption_id);

        if (selectedIndex === -1) {
            const new_selected_caption_array = [...selectedCaptions, caption];
            setSelectedCaptions(new_selected_caption_array);
        }
        else {
            setSelectedCaptions(selectedCaptions.filter(selected_caption => selected_caption.caption_id !== caption.caption_id))
        }
    }

    const checkSelected = (caption) => {
        const selectedIndex = selectedCaptions.findIndex(selected_caption => selected_caption.caption_id === caption.caption_id);
        if (selectedIndex === -1) return false;

        return true;
    }


    return (
        <div className={menuItem === 'caption' ? 'composer_component active' : 'composer_component'}>
            <div className="title">
                {t('select_customize_caption')}
            </div>
            {captions && captions.length > 0 ?
                <>
                    <label className='checkbox-container margin-t1'>{t('caption_1')}
                        <input type="checkbox" defaultChecked={() => checkSelected(captions[0])} onChange={() => selectCaption(captions[0])} />
                        <span className="checkmark"></span>
                    </label>
                    <p className='text-grey text-italic'>{captions[0].text}</p>
                    <label className='checkbox-container margin-t2'>{t('caption_2')}
                        <input type="checkbox" defaultChecked={() => checkSelected(captions[1])} onChange={() => selectCaption(captions[1])} />
                        <span className="checkmark"></span>
                    </label>
                    <p className='text-grey text-italic'>{captions[1].text}</p>
                    <label className='checkbox-container margin-t2'>{t('caption_3')}
                        <input type="checkbox" defaultChecked={() => checkSelected(captions[2])} onChange={() => selectCaption(captions[2])} />
                        <span className="checkmark"></span>
                    </label>
                    <p className='text-grey text-italic'>{captions[2].text}</p>
                </>
                : ''}
            <label className='checkbox-container margin-t2'>{t('custom_caption')}
                <input type="checkbox" defaultChecked={() => checkSelected(customCaption)} onChange={() => selectCaption(customCaption)} />
                <span className="checkmark"></span>
            </label>
            <div className='form_row_textarea_content_ai'>
                <textarea className='contentai_captions' placeholder={t('type_own_caption')} value={customCaption.text} onChange={e => handleCustomCaption(e.target.value)}></textarea>
            </div>
            <div className="row_hcenter row-100 margin-t1">
                <div className='action_button margin-t2' onClick={() => { setMenuItem('export'); setItemsUnlocked({ ...itemsUnlocked, export: true }) }}>
                    {t('export')}
                    <svg viewBox="0 0 24 24">
                        <path fill="current" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default CAICoCaption