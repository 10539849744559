import React, { useState } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import StateModal from '../Loader/StateModal';
import moment from 'moment'

const CancellationCustom = (props) => {

    const { t } = useTranslation(['cancellation']);

    const { authTokens } = useAuth();
    const headers = {
        Authorization: authTokens.token,
    };

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    const [cancellationMsg, setCancellationMsg] = useState("");

    const cancelPlan = async () => {
        let authError = false;

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id,
            message: cancellationMsg,
        }

        axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/payment/cancel_subscription_stripe_simple", payload, {
                headers: headers,
            })
            .then(() => {
                setCancellationMsg("")

                setStateModalOptions({
                    ...stateModalOptions,
                    display: true,
                    loader: false,
                    message: t('plan_has_been_cancelled') + moment(authTokens.plan_expiry).format('DD-MM-YYYY'),
                });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
                else {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        status: "error",
                        message: t('error_contact_us')
                    });
                }

            });

        if (authError) return;

    }

    return (
        <div>
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            <div className="content_row">
                <div className="card">
                    <div className="sad-pika">
                        <svg viewBox="0 0 948 948" fill="none">
                            <circle cx="473.97" cy="473.97" r="462.97" fill="white" stroke="white" strokeWidth="22" />
                            <path d="M178.086 564.759V806.892L765.813 806.928V564.759C765.813 494.766 741.519 430.51 700.987 379.899V212.49H701.014C701.014 172.072 668.415 139.313 628.184 139.313C587.945 139.313 555.328 172.072 555.328 212.49V281.665C528.909 273.82 500.923 269.532 471.963 269.532C442.985 269.532 415.016 273.811 388.58 281.665V212.49C388.58 172.072 355.963 139.313 315.741 139.313C275.511 139.313 242.894 172.072 242.894 212.49V379.908C202.38 430.51 178.086 494.766 178.086 564.759Z" fill="white" />
                            <path d="M316.839 489.163C336.73 489.163 352.855 472.963 352.855 452.979C352.855 432.995 336.73 416.795 316.839 416.795C296.947 416.795 280.822 432.995 280.822 452.979C280.822 472.963 296.947 489.163 316.839 489.163Z" fill="#3D2BFF" />
                            <path d="M627.105 489.163C646.996 489.163 663.121 472.963 663.121 452.979C663.121 432.995 646.996 416.795 627.105 416.795C607.213 416.795 591.088 432.995 591.088 452.979C591.088 472.963 607.213 489.163 627.105 489.163Z" fill="#3D2BFF" />
                            <path d="M484.853 559.983V608.122L471.962 634.735L459.054 608.122V559.983C449.403 555.216 442.745 545.245 442.745 533.664C442.745 517.475 455.813 504.32 471.962 504.32C488.076 504.32 501.161 517.484 501.161 533.664C501.161 545.245 494.503 555.216 484.853 559.983Z" fill="#3D2BFF" />
                            <path d="M317 556.667C311.696 556.667 306.609 554.559 302.858 550.809C299.107 547.058 297 541.971 297 536.667C297 523.333 317 500.833 317 500.833C317 500.833 337 523.333 337 536.667C337 541.971 334.893 547.058 331.142 550.809C327.391 554.559 322.304 556.667 317 556.667V556.667Z" fill="#3D2BFF" />
                            <line x1="404" y1="620.5" x2="540" y2="620.5" stroke="#3D2BFF" strokeWidth="27" />
                            <path d="M471.972 29C227.32 29 29 228.243 29 474.032C29 606.493 86.6723 725.372 178.086 806.892V564.759C178.086 494.766 202.38 430.51 242.894 379.908V212.49C242.894 172.072 275.511 139.313 315.741 139.313C355.963 139.313 388.58 172.072 388.58 212.49V281.665C415.016 273.811 442.985 269.532 471.963 269.532C500.923 269.532 528.909 273.82 555.328 281.665V212.49C555.328 172.072 587.945 139.313 628.184 139.313C668.415 139.313 701.014 172.072 701.014 212.49H700.987V379.899C741.519 430.51 765.813 494.766 765.813 564.759V806.928C857.253 725.407 914.943 606.52 914.943 474.032C914.943 228.243 716.623 29 471.972 29Z" fill="#3D2BFF" />
                        </svg>
                    </div>
                    <h1>{t('oh_no')}</h1>
                    <textarea className="social_post_content" name="message" rows="16" cols="70" placeholder={t('type_text_cancellation')} value={cancellationMsg} onChange={e => setCancellationMsg(e.target.value)} required></textarea>
                    <div className="row row_hcenter margin_reg">
                        <div className="action_button red" onClick={e => cancelPlan()}>{t('cancel_plan')}</div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CancellationCustom
