import React, { useState, useEffect } from 'react'
import { useAuth } from "../../auth";
import moment from 'moment-timezone'
import axios from "axios";

import UpgradeModal from '../Plans/UpgradeModal';

import "./tz_selection.css"

const TimezonesSelection = ({ customClassName, timezoneChanged, currentTimeZone, hideFavStar }) => {
  const { authTokens } = useAuth();
  const headers = {
    Authorization: authTokens.token,
  };

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const displayUpgradeModal = () => {
    setShowUpgradeModal(!showUpgradeModal);
  }

  const [momentTimezonesList, setMomentTimezonesList] = useState([]);
  const [favTimezones, setFavTimezones] = useState([]);

  const [selectedTz, setSelectedTz] = useState({});

  const [showDropdown, setShowDropdown] = useState(false);
  const displayDropdown = () => {
    if (authTokens.plan > 0) {
      setShowDropdown(!showDropdown);
    }
    else {
      displayUpgradeModal();
    }
  }

  const handleTimeZone = (tz) => {
    setSelectedTz(tz);
    timezoneChanged(tz);
    displayDropdown(!showDropdown);
  }

  const switchFavorite = async (tz) => {
    const index_of_tz = favTimezones.findIndex(element => element === tz);
    if (index_of_tz === -1) {
      setFavTimezones([...favTimezones, tz])
    }
    else {
      setFavTimezones(favTimezones.filter(element => element !== tz))
    }

    const payload = {
      user_id: authTokens.user_id,
      timezone_data: tz
    }

    await axios
      .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/toggle_user_fav_timezones", payload, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch(() => {
        console.log("error @ toggle fav timezones")
      });

  }

  const sortByZone = (a, b) => {
    let [ahh, amm] = a.label.split("GMT")[1].split(")")[0].split(":");
    let [bhh, bmm] = b.label.split("GMT")[1].split(")")[0].split(":");
    return (+ahh * 60 + amm) - (+bhh * 60 + bmm)
  };

  const populateTimezonesList = () => {

    const formatted_timezones = [];
    const raw_moment_timezones = moment.tz.names();

    raw_moment_timezones.forEach(t => {
      if (!t.includes('Etc') && t.includes('/')) {
        const tz_object = {
          label: `(GMT${moment.tz(t).format("Z")}) ${t}`,
          value: t
        }
        formatted_timezones.push(tz_object);
      }
    })

    const sorted_tz = formatted_timezones.sort(sortByZone);
    let cities_count = 0;

    let streamlined_tz = sorted_tz.reduce((accumulator, current) => {

      const length_of_accumulator = accumulator.length;
      const previous_value = accumulator[length_of_accumulator - 1];

      const prev_GMT = previous_value.label.split(" ");
      const current_GMT = current.label.split(" ");

      if (prev_GMT[0] !== current_GMT[0]) {
        accumulator.push(current);
        cities_count = 0;
      }
      else {
        const prev_continent = prev_GMT[1].split('/')[0];
        const current_continent = current_GMT[1].split('/')[0];

        if (prev_continent !== current_continent) {
          accumulator.push(current);
          cities_count = 0;
        }
        else {
          if (cities_count < 2) {
            accumulator[length_of_accumulator - 1].label = accumulator[length_of_accumulator - 1].label + ', ' + current_GMT[1].split('/')[current_GMT[1].split('/').length - 1];
            cities_count++;
          }
          accumulator[length_of_accumulator - 1].all_values = accumulator[length_of_accumulator - 1].all_values ? [...accumulator[length_of_accumulator - 1].all_values, current.value] : [previous_value.value, current.value]
        }
      }


      return accumulator;
    }, [sorted_tz[0]])

    setMomentTimezonesList(streamlined_tz);
  }

  const getFavoriteTimezones = async () => {
    const payload = {
      user_id: authTokens.user_id
    }

    await axios
      .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/get_user_fav_timezones", payload, {
        headers: headers,
      })
      .then((response) => {
        setFavTimezones(response.data);
      })
      .catch(() => {
        console.log("error @ fav timezones")
      });

    populateTimezonesList();

  }



  useEffect(() => {
    getFavoriteTimezones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSelectedTz(currentTimeZone);
  }, [currentTimeZone])

  return (
    <div className={customClassName}>
      {showUpgradeModal ? <UpgradeModal upgrade={true} closeModal={() => setShowUpgradeModal(false)} closeButton={true} /> : ''}
      {momentTimezonesList && momentTimezonesList.length > 0 ?
        <div className='tz-dropdown-main'>
          {selectedTz && Object.keys(selectedTz).length > 0 ?
            <div className='selected-tz'>
              <p className='p-small text-grey' onClick={() => displayDropdown()}>{selectedTz.label}</p>
              <div className="row nowrap">
                {!hideFavStar ?
                  favTimezones.includes(selectedTz) ?
                    <svg viewBox="0 0 24 24" className="margin-s1" onClick={() => switchFavorite(selectedTz)}>
                      <path fill="current" d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
                    </svg>
                    :
                    <svg viewBox="0 0 24 24" className="margin-s1" onClick={() => switchFavorite(selectedTz)}>
                      <path fill="current" d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" />
                    </svg>
                  : ''
                }
                <svg viewBox="0 0 24 24" onClick={() => displayDropdown()}>
                  <path fill="current" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                </svg>
              </div>
            </div>
            :
            <div className='selected-tz' onClick={() => displayDropdown()}>
              <p className="text-grey">Select a time zone</p>
              <svg viewBox="0 0 24 24">
                <path fill="current" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            </div>
          }


          <ul className={showDropdown ? 'tz-dropdown-list displayed' : 'tz-dropdown-list'}>
            {favTimezones && favTimezones.length > 0 ?
              <>
                {favTimezones.map((tz, t) => {
                  return (
                    <li key={`favtz_${t}`} onClick={() => handleTimeZone(tz)}>
                      {/* <li key={`favtz_${t}`} onClick={() => handleTimeZone(tz)} className={tz && tz.value && (tz.value === selectedTz.value || (tz.all_values && tz.all_values.includes(selectedTz.value))) ? 'selected' : ''}> */}
                      <p className='p-small text-grey'>{tz.label}</p>
                    </li>
                  )
                })}
                <li className='divider'></li>
              </>
              : ''}
            {momentTimezonesList.map((tz, t) => {
              return (
                <li key={`tz_${t}`} onClick={() => handleTimeZone(tz)}>
                  {/* <li key={`tz_${t}`} onClick={() => handleTimeZone(tz)} className={tz && tz.value && (tz.value === selectedTz.value || (tz.all_values && tz.all_values.includes(selectedTz.value))) ? 'selected' : ''}> */}
                  <p className='p-small text-grey'>{tz.label}</p>
                </li>
              )
            })}
          </ul>
        </div>
        : ''}

    </div>
  )
}

export default TimezonesSelection