import React from 'react'

import "./post_previews.css";
import LK_icons from "../../../images/lk_icons.png";
import LKicon from "../../../images/social_media/linkedin.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"

const PreviewLK = ({ publicationData, calSelectedEvent, selectedPhotos, linkPreview }) => {


    let lk_check = false;

    let lk_index;

    if (calSelectedEvent) {
        lk_index = publicationData.social_accounts.findIndex((account) => account._id.toString() === calSelectedEvent._id.toString());
    }
    else {
        lk_index = publicationData.social_accounts.findIndex((account) => account.media === "LinkedIn");
    }
    // const lk_index = publicationData.social_accounts.findIndex((account) => account.media === "LinkedIn");

    if (lk_index !== -1) {
        lk_check = true;
    }

    let lk_formatted_link = '';
    if (publicationData.link_url) {
        const link_str = publicationData.link_url;
        lk_formatted_link = " " + link_str.substring(0, 30) + "...";
    }

    const hashtagsFormat = (hashtag) => {
        const formattedText = '<span class="tags">' + hashtag + '</span>';
        return formattedText
    }

    const formatMentions = (initialText) => {
        let formatted_text = initialText;
        if (!publicationData || !publicationData.mentions_LinkedIn || publicationData.mentions_LinkedIn.length === 0) {
            return formatted_text;
        }

        publicationData.mentions_LinkedIn.forEach(tag => {
            formatted_text = formatted_text.replace(tag.network_name, '<span class="mention_lk">' + tag.network_name + '</span>')
        })

        return formatted_text;

    }

    const formatText = (text) => {
        let formatted_text = '';
        const regex = /#[0-9A-Za-zÀ-ÿ '-]*/g;
        formatted_text = text.replace(regex, hashtagsFormat);

        formatted_text = formatMentions(formatted_text);

        return formatted_text;
    }

    const formatFirstComment = (comment) => {
        let formatted_text = comment;
        return formatted_text.replace(/\r\n|\r|\n/g, "<br />");
    }

    const source_text = publicationData.alt_text_LinkedIn || publicationData.text;

    // const optimizedMediaURL = (url) => {
    //     if (url.includes("tr:")) {
    //         return url;
    //     }
    //     const url_options = "tr:n-ik_ml_thumbnail";
    //     // const url_options = "tr:h-150,pr-true";
    //     let arrayedURL = url.split("/");
    //     arrayedURL.splice(arrayedURL.length - 1, 0, url_options);
    //     const optimized_url = arrayedURL.join('/');
    //     return optimized_url;
    // }


    return (
        <div>
            {lk_check ?
                <div className={publicationData.draft ? "preview_card preview_card_draft" : "preview_card"}>
                    <div className="sm-badge-wrapper">
                        <img src={LKicon} alt="LinkedIn" />
                    </div>
                    <div className="header">
                        <img className="co-logo" src={publicationData.social_accounts[lk_index].profile_picture_url} alt="" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <div className="co-name-lk">{publicationData.social_accounts[lk_index].social_name}</div>
                        <div className="time-lk">242 followers</div>
                        <div className="time-lk">1d</div>
                        {/* <div className="lk_nickname_block">
                            <span className="lk_nickname">{publicationData.social_accounts[lk_index].social_name}</span>
                            <span className="lk_followers">232 followers</span>
                            <span className="lk_time">1d</span>
                        </div> */}
                    </div>
                    <div className="content">
                        <div className="text"><div dangerouslySetInnerHTML={{ __html: formatText(source_text) }}></div></div>
                        <div className="tags margin-t1">{(linkPreview && selectedPhotos.length > 0) ? lk_formatted_link : ""}</div>
                        <div className="tags margin-t1">{publicationData.tags}</div>
                    </div>

                    {(linkPreview && selectedPhotos.length === 0) ?
                        <div className="link_preview">
                            <img className="link_thumb" src={linkPreview.image} alt="" />
                            <div className="link_title">{linkPreview.title}</div>
                            <div className="link_subtitle">{linkPreview.description}</div>
                        </div>
                        : (selectedPhotos.length > 0) ?
                            <div className="lk_media_container">
                                {publicationData.bulk && selectedPhotos.length > publicationData.bulk_media_index ?
                                    selectedPhotos[publicationData.bulk_media_index].type === "image" ?
                                        <div className='lk_media_wrapper'>
                                            <img src={selectedPhotos[publicationData.bulk_media_index].file_url} alt="" />
                                        </div>
                                        :
                                        <div className="media_wrapper">
                                            {selectedPhotos[publicationData.bulk_media_index].thumbnail_url ?
                                                <img key={selectedPhotos[publicationData.bulk_media_index]._id} src={selectedPhotos[publicationData.bulk_media_index].thumbnail_url} alt="" />
                                                :
                                                <video key={selectedPhotos[publicationData.bulk_media_index]._id}>
                                                    <source src={selectedPhotos[publicationData.bulk_media_index].file_url} />
                                                </video>
                                            }
                                            <div className='video_icon'>
                                                <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                                    <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                                </svg>
                                            </div>
                                            {/* <div className="video_overlay">
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                                </svg>
                                            </div> */}
                                        </div>
                                    :
                                    selectedPhotos[0].type === "image" ?
                                        selectedPhotos.map((photoData, i) => {
                                            return (
                                                <div className='lk_media_wrapper' key={'image_' + i}>
                                                    <img key={i} src={photoData.file_url} alt="" />
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="media_wrapper">
                                            {selectedPhotos[0].thumbnail_url ?
                                                <img key={selectedPhotos[0]._id} src={selectedPhotos[0].thumbnail_url} alt="" />
                                                :
                                                <video key={selectedPhotos[0]._id}>
                                                    <source src={selectedPhotos[0].file_url} />
                                                </video>
                                            }
                                            <div className='video_icon'>
                                                <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                                    <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                                </svg>
                                            </div>
                                            {/* <div className="video_overlay">
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                                </svg>
                                            </div> */}
                                        </div>
                                }
                            </div>
                            : publicationData.lk_poll && publicationData.lk_poll.display && publicationData.lk_poll.question && publicationData.lk_poll.choices && publicationData.lk_poll.choices.length > 1 ?
                                <div className="lk_poll_block">
                                    <div className='lk_poll_question'>{publicationData.lk_poll.question}</div>
                                    {publicationData.lk_poll.choices.map((choice, c) => {
                                        if (choice.length > 0) {
                                            return (
                                                <div key={`choice_${c}`} className='lk_poll_choice_item'>{choice}</div>
                                            )
                                        }

                                    })}
                                </div>
                                : ''
                    }
                    <img className="lk_icons" src={LK_icons} alt="" />
                    {/* {publicationData.first_comment && publicationData.first_comment.length > 0 ? */}
                    {publicationData.first_comment && publicationData.first_comment.length > 0 && publicationData.first_comment_accounts && publicationData.first_comment_accounts.length > 0 && publicationData.first_comment_accounts.some(el => el._id.toString() === publicationData.social_accounts[lk_index]._id.toString()) ?
                        <div className="preview_first_comment">
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="M12 28h24v-3H12Zm0-6.5h24v-3H12Zm0-6.5h24v-3H12Zm32 29-8-8H7q-1.15 0-2.075-.925Q4 34.15 4 33V7q0-1.15.925-2.075Q5.85 4 7 4h34q1.2 0 2.1.925Q44 5.85 44 7ZM7 7v26h30.25L41 36.75V7H7Zm0 0v29.75V7Z" />
                            </svg>
                            <p className="p-small text-grey" dangerouslySetInnerHTML={{ __html: formatFirstComment(publicationData.first_comment) }}></p>
                        </div>
                        : ''}
                </div>
                : ""
            }

        </div >
    )
}

export default PreviewLK
