import React, { useState, useEffect } from 'react'
import axios from "axios";
import moment from 'moment'
import StateModal from '../../Loader/StateModal';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Tooltip from '../../sidebar/Tooltip';

import HeaderThird from '../../header/HeaderThird';

import CalendarThirdWeeklySimplified from './CalendarThirdWeeklySimplified';
import CalendarThirdModal from './CalendarThirdModal';
import CalendarThirdHelpModal from './CalendarThirdHelpModal';


import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import PNicon from "../../../images/social_media/pinterest.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"

const CalendarThirdWeekly = (props) => {
    const party_id = props.match.params.id;

    const { t } = useTranslation(['calendar']);

    const history = useHistory();

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }

    const goToMonthly = () => {
        history.push({
            pathname: '/3ps/m/' + party_id,
        });
    }


    const [SMAccounts, setSMAccounts] = useState([]);
    const [selectedSMAccounts, setSelectedSMAccounts] = useState([]);


    const selectMediaAccount = (account) => {
        let selectedMediaAccount = selectedSMAccounts;

        let resultingAccountsArray = [];

        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === account.social_id);

        if (indexFound !== -1) {
            const filteredMedia = selectedMediaAccount.filter(item => item.social_id !== account.social_id);
            resultingAccountsArray = [...filteredMedia];
            setSelectedSMAccounts(filteredMedia);
        }
        else {
            selectedMediaAccount.push(account);
            resultingAccountsArray = [...selectedMediaAccount];

            setSelectedSMAccounts([...selectedMediaAccount]);
        }

        if (resultingAccountsArray.length > 0) {
            let calendar_array = [];
            calendarEvents.forEach(event => {
                let indexFound = resultingAccountsArray.findIndex((media) => media._id === event.sm_account._id);

                if (indexFound !== -1) {
                    calendar_array.push(event)
                }
            })

            setFilteredCalendarEvents(calendar_array)
        }
        else {
            setFilteredCalendarEvents([...calendarEvents]);
        }


    }

    const checkAccountPhoto = (accountData) => {

        if (!accountData) return;

        const selectedMediaAccount = selectedSMAccounts;
        let selected = false;
        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === accountData.social_id);

        if (indexFound !== -1) {
            selected = true;
        }

        switch (accountData.media) {
            case "Facebook":
                let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                if (accountData.type === "group") {
                    fb_picture_url = "https://graph.facebook.com/v12.0/" + accountData.social_id + "/picture?access_token=" + accountData.access_token;
                }

                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={fb_picture_url} alt={accountData.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={FBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Twitter":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "LinkedIn":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={LKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Instagram":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={IGicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Pinterest":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={PNicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "GMB":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={GMBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
        }
    }

    const [thirdPartyName, setThirdPartyName] = useState('')
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [filteredCalendarEvents, setFilteredCalendarEvents] = useState([]);

    const [selectedEvent, setSelectedEvent] = useState({});
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
        setShowModal(false);
    }

    const [showHelpModal, setShowHelpModal] = useState(false);
    const closeHelpModal = () => {
        setShowHelpModal(false);
    }


    const selectEvent = async (event) => {
        setSelectedEvent(event);
        setShowModal(true);
    }


    const [currentWeek, setCurrentWeek] = useState(moment());

    const nextWeek = () => {
        setCurrentWeek(moment(currentWeek).add(1, 'weeks'));
    };

    const prevWeek = () => {
        setCurrentWeek(moment(currentWeek).subtract(1, 'weeks'));
    };

    const goToCurrentWeek = () => {
        setCurrentWeek(moment());
    };

    const checkFilteredEvents = (all_events) => {
        if (selectedSMAccounts.length > 0) {
            let calendar_array = [];
            all_events.forEach(event => {
                let indexFound = selectedSMAccounts.findIndex((media) => media.social_id === event.sm_account._id);

                if (indexFound !== -1) {
                    calendar_array.push(event)
                }
            })

            setFilteredCalendarEvents(calendar_array)
        }
        else {
            setFilteredCalendarEvents([...all_events]);
        }
    }

    const fetchAllCalendarEvents = async () => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });
        const payload = {
            party_id: party_id,
            date_range: currentWeek.startOf('week')
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/third_party/get_weekly_calendar_third_party", payload)
            .then((response) => {
                setThirdPartyName(response.data.third_party_name);
                setSMAccounts(response.data.accounts)
                setCalendarEvents(response.data.events);
                checkFilteredEvents(response.data.events);
            })
            .catch(() => {
            });

        closeStateModal();
    }

    useEffect(() => {
        fetchAllCalendarEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWeek]);

    useEffect(() => {
        if (party_id) {
            fetchAllCalendarEvents();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <HeaderThird name={thirdPartyName} />
            <div className="content_row_third_party">
                {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
                {showModal ? <CalendarThirdModal closeModal={closeModal} selectedEvent={selectedEvent} /> : ""}
                {showHelpModal ? <CalendarThirdHelpModal closeModal={closeHelpModal} /> : ""}
                <div className="card flex-1">
                    <p className="text-grey text-italic">{t('filter_by_account')}:</p>
                    <div className='row row_spaced'>
                        <div className='row'>
                            {SMAccounts.map((account, i) => {
                                return (
                                    <div className="account_selection_account" key={i} onClick={e => selectMediaAccount(account)}>
                                        {checkAccountPhoto(account)}
                                    </div>
                                )
                            }
                            )}
                        </div>
                        <div className='row'>
                            <div className="nav_button" onClick={() => goToMonthly()}>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M9 44q-1.2 0-2.1-.9Q6 42.2 6 41V10q0-1.2.9-2.1Q7.8 7 9 7h3.25V4h3.25v3h17V4h3.25v3H39q1.2 0 2.1.9.9.9.9 2.1v31q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V19.5H9V41Zm0-24.5h30V10H9Zm0 0V10v6.5ZM24 28q-.85 0-1.425-.575Q22 26.85 22 26q0-.85.575-1.425Q23.15 24 24 24q.85 0 1.425.575Q26 25.15 26 26q0 .85-.575 1.425Q24.85 28 24 28Zm-8 0q-.85 0-1.425-.575Q14 26.85 14 26q0-.85.575-1.425Q15.15 24 16 24q.85 0 1.425.575Q18 25.15 18 26q0 .85-.575 1.425Q16.85 28 16 28Zm16 0q-.85 0-1.425-.575Q30 26.85 30 26q0-.85.575-1.425Q31.15 24 32 24q.85 0 1.425.575Q34 25.15 34 26q0 .85-.575 1.425Q32.85 28 32 28Zm-8 8q-.85 0-1.425-.575Q22 34.85 22 34q0-.85.575-1.425Q23.15 32 24 32q.85 0 1.425.575Q26 33.15 26 34q0 .85-.575 1.425Q24.85 36 24 36Zm-8 0q-.85 0-1.425-.575Q14 34.85 14 34q0-.85.575-1.425Q15.15 32 16 32q.85 0 1.425.575Q18 33.15 18 34q0 .85-.575 1.425Q16.85 36 16 36Zm16 0q-.85 0-1.425-.575Q30 34.85 30 34q0-.85.575-1.425Q31.15 32 32 32q.85 0 1.425.575Q34 33.15 34 34q0 .85-.575 1.425Q32.85 36 32 36Z" />
                                </svg>
                                {t('monthly')}
                            </div>
                            <div className="nav_button margin-l1" onClick={() => setShowHelpModal(true)}>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M23.85 35.7q.8 0 1.35-.55t.55-1.35q0-.8-.55-1.35t-1.35-.55q-.8 0-1.35.55t-.55 1.35q0 .8.55 1.35t1.35.55Zm-1.65-7.4h2.85q0-1.55.5-2.525.5-.975 1.75-2.225 1.65-1.65 2.3-2.925.65-1.275.65-2.675 0-2.65-1.7-4.2-1.7-1.55-4.6-1.55-2.55 0-4.375 1.225Q17.75 14.65 16.9 16.8l2.65 1.1q.7-1.4 1.8-2.125 1.1-.725 2.6-.725 1.6 0 2.525.8.925.8.925 2.2 0 1-.55 1.925T25 22.15q-1.85 1.75-2.325 3-.475 1.25-.475 3.15ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z" />
                                </svg>
                                {t('help')}
                            </div>
                        </div>
                    </div>
                    <CalendarThirdWeeklySimplified events={filteredCalendarEvents} currentWeek={currentWeek} prevWeek={prevWeek} nextWeek={nextWeek} goToCurrentWeek={goToCurrentWeek} selectEvent={selectEvent} />
                </div>
            </div>
        </>
    )
}

export default CalendarThirdWeekly