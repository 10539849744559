import React, { useState, useEffect } from "react";
import axios from "axios";
import GifPicker from "@progresso/react-giphy-picker-https";
import UnsplashReact, { withDefaultProps, BlobUploader } from "unsplash-react";
import Resizer from "react-image-file-resizer";
import { useTranslation } from "react-i18next";

import LibraryFilterMDF from "./PickerComponents/LibraryFilterMDF";
import LibraryMediaUnitMDF from "./PickerComponents/LibraryMediaUnitMDF";

import "./library_picker.css";

const LibraryPickerSelect = ({
  authTokens,
  libraryNeedsRefresh,
  setLibraryNeedsRefresh,
  userIdForLibrary,
  setUserIdForLibrary,
  team,
  currentFolder,
  setCurrentFolder,
  toggleSelectMedia,
  preSelectedImgIds,
  onGifClick,
  maxHeight,
  displayCropper,
  setImagesRaw,
  hideGif,
  hideUnsplash,
  onlyPhotos,
}) => {
  const { t } = useTranslation(["library"]);
  const headers = {
    Authorization: authTokens.token,
  };
  const currentPlan = Number(authTokens.plan);

  const [showLibrary, setShowLibrary] = useState(true);
  const [allLibrary, setAllLibrary] = useState([]);
  const [filteredLibrary, setFilteredLibrary] = useState([]);
  const [filter, setFilter] = useState("");
  const [folders, setFolders] = useState([]);

  // const [stateModalOptions, setStateModalOptions] = useState({
  //     display: false,
  //     status: "",
  //     message: "",
  //     loader: false
  // });
  // const closeStateModal = () => {
  //     setStateModalOptions({
  //         display: false,
  //         status: "",
  //         message: "",
  //         loader: false
  //     })
  // }

  const updateFilter = (filter) => {
    setFilter(filter);

    switch (filter) {
      case "Canva":
        setFilteredLibrary(
          allLibrary.filter((media) => media.source === "Canva")
        );
        setShowLibrary(true);
        break;
      case "Photos":
        setFilteredLibrary(
          allLibrary.filter((media) => media.type === "image")
        );
        setShowLibrary(true);
        break;
      case "Videos":
        setFilteredLibrary(
          allLibrary.filter((media) => media.type === "video")
        );
        setShowLibrary(true);
        break;
      case "Unused":
        setFilteredLibrary(allLibrary.filter((media) => media.used === false));
        setShowLibrary(true);
        break;
      case "Gif":
        setShowLibrary(false);
        break;
      case "Unsplash":
        setShowLibrary(false);
        break;
      default:
        setFilteredLibrary(allLibrary);
        // Sort with unused first
        // const unusedMedia = allLibrary.sort((a, b) => Number(a.used) - Number(b.used));
        // setFilteredLibrary(unusedMedia)
        setShowLibrary(true);
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1350,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const handleFinishedUnsplashUploading = async (imageURL) => {
    const image = await resizeFile(imageURL);
    setImagesRaw([image]);
    displayCropper();
  };

  const getAllFolders = async () => {
    // setStateModalOptions({
    //     ...stateModalOptions,
    //     display: true,
    //     loader: true,
    // });

    const payload = {
      folder_id: currentFolder._id,
      user_id: userIdForLibrary,
      company_id: authTokens.account_id,
    };

    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL + "/media/get_all_folders",
        payload,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setFolders(response.data);
      })
      .catch(() => {});

    setLibraryNeedsRefresh(false);
    // closeStateModal();
  };

  const getFolderMedia = async () => {
    // setStateModalOptions({
    //     ...stateModalOptions,
    //     display: true,
    //     loader: true,
    // });

    const payload = {
      folder_id: currentFolder._id,
      user_id: userIdForLibrary,
      company_id: authTokens.account_id,
      only_photos: onlyPhotos || false,
    };

    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/media/get_media_in_current_folder",
        payload,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setAllLibrary(response.data.media);
        setFilteredLibrary(response.data.media);
      })
      .catch(() => {});

    setLibraryNeedsRefresh(false);
    // closeStateModal();
  };

  useEffect(() => {
    getFolderMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolder]);

  useEffect(() => {
    getFolderMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIdForLibrary]);

  useEffect(() => {
    getAllFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (libraryNeedsRefresh) {
      getFolderMedia();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraryNeedsRefresh]);

  return (
    <div>
      <div className="row centered">
        <p className="text-grey text-italic margin-r1">{t("folder")}:</p>
        <select onChange={(e) => setCurrentFolder({ _id: e.target.value })}>
          {folders && folders.length > 0
            ? folders.map((folder) => {
                return (
                  <option
                    key={folder._id}
                    value={folder._id}
                    selected={currentFolder._id === folder._id ? true : false}
                  >
                    {folder.name}
                  </option>
                );
              })
            : ""}
        </select>
      </div>
      <LibraryFilterMDF
        filter={filter}
        updateFilter={updateFilter}
        currentPlan={currentPlan}
        hideGif={hideGif || false}
        hideUnsplash={hideUnsplash || false}
      />
      {showLibrary ? (
        <div className="library-grid" style={{ maxHeight: maxHeight }}>
          {filteredLibrary && filteredLibrary.length > 0
            ? filteredLibrary.map((media) => {
                return (
                  <LibraryMediaUnitMDF
                    key={media._id}
                    media={media}
                    toggleSelectMedia={toggleSelectMedia}
                  />
                );
              })
            : ""}
        </div>
      ) : !showLibrary & (filter === "Gif") ? (
        <div className="library-gif" style={{ maxHeight: maxHeight }}>
          <GifPicker
            width="440"
            apiKey={process.env.REACT_APP_GIPHY_API_KEY}
            onSelected={onGifClick}
            modal={false}
          />
        </div>
      ) : !showLibrary & (filter === "Unsplash") ? (
        <div className="library-unsplash">
          <UnsplashReact
            accessKey={process.env.REACT_APP_UNSPLASH_ACCESS_KEY}
            applicationName="SocialPika"
            Uploader={withDefaultProps(BlobUploader, {
              onBlobLoaded: handleFinishedUnsplashUploading,
            })}
            preferredSize={{ width: 1200, height: 1080 }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default LibraryPickerSelect;
