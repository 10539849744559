import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import TooltipHelp from '../../settings/TooltipHelp';
import CustomScheduler from '../../Composer/ComposerComponents/CustomScheduler';

const PublishRSS = ({
    authTokens,
    displayUpgradeModal,
    editorMenuItem,
    publicationData,
    setPublicationData,
    recordPost
}) => {

    const { t } = useTranslation(['composer']);

    const [showCustomScheduler, setShowCustomScheduler] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState({});

    const handleSelectedCustomSchedule = (selectedSchedule) => {
        if (selectedSchedule) {
            setSelectedSchedule(selectedSchedule)
            setPublicationData({
                ...publicationData,
                custom_schedule_id: selectedSchedule._id
            })
        }
        else {
            setSelectedSchedule({});

            setPublicationData({
                ...publicationData,
                custom_schedule_id: ''
            })
        }
    }

    const handleFrequency = (e, frequency) => {

        if (frequency === 'custom') {
            if (authTokens.plan > 0) {
                setPublicationData({ ...publicationData, [e.target.name]: 'custom' });
                setShowCustomScheduler(true);
            }
            else {
                displayUpgradeModal();
            }
        }
        else {
            setPublicationData({ ...publicationData, [e.target.name]: 'immediate' });
            setShowCustomScheduler(false);
        }
    }

    return (
        <div className={editorMenuItem === 'publication' ? 'editor_component active' : 'editor_component'}>

            <div className="toggle-switch-container margin-t4">
                <label>{t('publication_frequency')}</label>

                <div className="toggle-switch switch-vertical">
                    <input id="toggle-c" type="radio" name="publishing_schedule" onChange={e => handleFrequency(e, 'immediate')} checked={publicationData.publishing_schedule === 'immediate' ? true : false} />
                    <label htmlFor="toggle-c">
                        {t('immediately')}
                        <TooltipHelp content={t('tooltip_immediate')} direction="right">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </label>
                    <input id="toggle-d" type="radio" name="publishing_schedule" onChange={e => handleFrequency(e, 'custom')} checked={publicationData.publishing_schedule === 'custom' ? true : false} />
                    <label htmlFor="toggle-d">
                        {t('custom_schedule')}
                        <TooltipHelp content={t('tooltip_custom')} direction="right">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </label>
                    <span className="toggle-outside">
                        <span className="toggle-inside"></span>
                    </span>
                </div>
            </div>

            {showCustomScheduler ?
                <div className='margin-t2'>
                    <CustomScheduler authTokens={authTokens} selectedSchedule={selectedSchedule} handleSelectedCustomSchedule={handleSelectedCustomSchedule} />
                    <div className='divider'></div>
                </div>
                : ""}
            <div className="row margin-t2">
                <div className="action_button" name="schedule" onClick={() => recordPost('schedule')}>
                    <svg viewBox="0 0 24 24">
                        <path fill="current" d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
                    </svg>
                    {t('schedule')}
                </div>
                <div className="action_button_outline margin-l1" name="draft" onClick={() => recordPost('draft')}>
                    <svg viewBox="0 0 48 48">
                        <path fill="current" d="m37.55 18.2-7.75-7.75 3.5-3.5q.85-.85 2.1-.85t2.1.85l3.55 3.55q.85.85.85 2.1t-.85 2.1ZM6 42v-7.8l9.7-9.7L4.65 13.4l8.7-8.8L24.5 15.75l5.3-5.3 7.75 7.75-5.3 5.3L43.4 34.65l-8.75 8.65-11.1-11.05L13.8 42Zm11.85-19.65 4.5-4.5-3.65-3.65-2.4 2.4-2.1-2.1 2.4-2.4-3.2-3.2-4.5 4.5Zm16.7 16.75 4.5-4.5-3.2-3.2-2.4 2.4-2.1-2.1 2.4-2.4-3.65-3.65-4.5 4.5ZM9 39h3.5l20.75-20.75-3.5-3.5L9 35.5Z" />
                    </svg>
                    {t('save_post_as_draft')}
                </div>
            </div>
        </div>
    )
}

export default PublishRSS