import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import StateModal from '../Loader/StateModal';

import Kanban from '../dashboard/dashboardComponents/Kanban';
import WhatsNew from '../dashboard/dashboardComponents/WhatsNew';

const News = (props) => {
    const { authTokens } = useAuth();

    const { t } = useTranslation(['news']);

    const headers = {
        Authorization: authTokens.token,
    };

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    const [supportData, setSupportData] = useState({
        company_id: authTokens.account_id,
        user_id: authTokens.user_id,
        email: authTokens.user_email,
        name: authTokens.user_first_name + ' ' + authTokens.user_name,
        support_message: "",
    });

    const handleInputChange = (e) => {
        setSupportData({
            ...supportData,
            [e.target.name]: e.target.value
        })
    }

    const sendSupportMessage = (e) => {
        e.preventDefault();

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        setSupportData({
            ...supportData,
        })

        axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/support", supportData, {
                headers: headers,
            })
            .then(() => {
                setSupportData({
                    ...supportData,
                    support_message: ""
                })
                setStateModalOptions({
                    ...stateModalOptions,
                    display: true,
                    loader: false,
                    status: "check",
                    message: t('message_sent'),
                });
            })
            .catch((error) => {
                setSupportData({
                    ...supportData,
                })
                setStateModalOptions({
                    ...stateModalOptions,
                    display: true,
                    loader: false,
                    status: "error",
                    message: t('message_not_sent')
                });
            });
    }



    useEffect(() => {
        props.changeNav("Support", "Support");
    }, []);
    return (
        <div>
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            <div className="content_row">
                <div className="card flex-1">
                    <div className="title">{t('upcoming_features')}</div>
                    <Kanban />
                </div>
                <div className="card flex-1">
                    <div className="title">
                        {t('whats_new')}
                    </div>
                    <WhatsNew />
                </div>
                {/* <div className="card flex-1">
                    <div className="title">
                        {t('need_assistance')}
                    </div>
                    <p>{t('assitance_msg')}</p>
                    <form onSubmit={sendSupportMessage}>
                        <textarea className="social_post_content" name="support_message" rows="16" cols="70" placeholder={t('type_text_here')} value={supportData.support_message} onChange={handleInputChange} required></textarea>
                        <button>{t('send')}</button>
                    </form>
                </div> */}
            </div>
        </div>
    )
}

export default News
