import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useAuth } from "../../auth";
import { useTranslation } from "react-i18next";
import Tooltip from '../sidebar/Tooltip';
import StateModal from '../Loader/StateModal';
import UpgradeModal from '../Plans/UpgradeModal';
import AccountGrid from './GridComponents/AccountGrid';

import CalendarCoAccountsSelection from '../CalendarView/CalendarComponents/CalendarCoAccountsSelection';

import FBicon from "../../images/social_media/facebook.svg";
import TWicon from "../../images/social_media/twitter.svg";
import LKicon from "../../images/social_media/linkedin.svg";
import IGicon from "../../images/social_media/instagram.svg";
import PNicon from "../../images/social_media/pinterest.svg";
import GMBicon from "../../images/social_media/gmb.svg";
import TKicon from "../../images/social_media/tiktok-spk.svg";
import YTicon from "../../images/social_media/youtube.svg";
import SPKNoProfile from "../../images/social_media/SPK-no-profile.png"

import BlurFeed from "../../images/blur_feed.png"


import "./grid_view.css";

const GridViews = (props) => {

    const { authTokens } = useAuth();
    const headers = {
        Authorization: authTokens.token,
    };
    const { t } = useTranslation(['feeds']);

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }

    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [showUpcoming, setShowUpcoming] = useState(false);
    const today = new Date();

    const [allSMAccounts, setAllSMAccounts] = useState([]);
    const [SMAccounts, setSMAccounts] = useState([]);
    const [selectedSMAccounts, setSelectedSMAccounts] = useState([]);
    const [SMGroups, setSMGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState({});

    const [showAccountsSelection, setShowAccountsSelection] = useState(false);
    const displayAccountSelection = () => {
        setShowAccountsSelection(!showAccountsSelection);
    }


    const selectGroup = (e) => {
        setSelectedGroup(e.target.value);
        let newlyAvailableSMAccounts = [];

        if (!e.target.value) {
            setSMAccounts(allSMAccounts);
        }
        else if (e.target.value === 'filter_select_accounts') {
            displayAccountSelection();
        }
        else {
            const filteredGroupData = SMGroups.filter(group => group._id === e.target.value);

            filteredGroupData[0].sm_accounts.map(account => {
                let sm_account = allSMAccounts.filter(acc => acc._id === account);
                if (sm_account[0]) {
                    newlyAvailableSMAccounts.push(sm_account[0]);
                }
                return null;
            })

            setSMAccounts(newlyAvailableSMAccounts);
        }
        setSelectedSMAccounts(newlyAvailableSMAccounts);
    }

    const selectMediaAccount = (account) => {
        let selectedMediaAccount = selectedSMAccounts;

        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === account.social_id);

        if (indexFound !== -1) {
            const filteredMedia = selectedMediaAccount.filter(item => item.social_id !== account.social_id);
            setSelectedSMAccounts(filteredMedia);

        }
        else {
            selectedMediaAccount.push(account);
            setSelectedSMAccounts([...selectedMediaAccount]);
        }
    }

    const checkAccountPhoto = (accountData) => {

        if (!accountData) return;

        const selectedMediaAccount = selectedSMAccounts;
        let selected = false;
        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === accountData.social_id);

        if (indexFound !== -1) {
            selected = true;
        }

        switch (accountData.media) {
            case "Facebook":
                let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                if (accountData.type === "group") {
                    fb_picture_url = "https://graph.facebook.com/v12.0/" + accountData.social_id + "/picture?access_token=" + accountData.access_token;
                }

                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={fb_picture_url} alt={accountData.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={FBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Twitter":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "LinkedIn":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={LKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Instagram":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={IGicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Pinterest":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={PNicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "GMB":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={GMBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "TikTok":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Youtube":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={YTicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
        }
    }

    const fetchAvailableSocialMedia = async () => {
        let authError = false;
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_accounts_no_auth_check", payload, {
                headers: headers,
            })
            .then((response) => {
                setAllSMAccounts(response.data.sm_accounts);
                setSMAccounts(response.data.sm_accounts);
                setSMGroups(response.data.sm_groups);
                if (response.data.sm_accounts.length > 0) {
                    let preSelectedAccounts = [];
                    response.data.sm_accounts.forEach(account => {
                        if (account.media === "Instagram") {
                            preSelectedAccounts.push(account);
                        }
                    })
                    setSelectedSMAccounts(preSelectedAccounts)
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
            });

        if (authError) return;
        closeStateModal();
    }


    useEffect(() => {
        if (authTokens.plan > 0) {
            fetchAvailableSocialMedia();
        }
        else {
            setShowUpgradeModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {showUpgradeModal ?
                <div>
                    <img src={BlurFeed} alt="" />
                    <UpgradeModal upgrade={true} closeModal={() => setShowUpgradeModal(false)} />
                </div>
                :
                <div className="content_row">
                    {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
                    <div className="card card_with_width">
                        <div className='row_vcenter row_spaced'>
                            <div className='column_left'>
                                <select className='editor' name="group" onChange={e => selectGroup(e)} value={selectedGroup}>
                                    <option value="">{t('select_group_or_accounts')}</option>
                                    {SMGroups && SMGroups.length > 0 ?
                                        SMGroups.map((group, i) => {
                                            return (
                                                <option key={i} value={group._id}>{t('filter')}{group.name}</option>
                                            )
                                        })
                                        : ""
                                    }
                                    <option value="filter_select_accounts">{t('filter_select_accounts')}</option>
                                </select>
                                <div className="row margin-t1">
                                    {selectedGroup && selectedSMAccounts && selectedSMAccounts.length > 0 ?
                                        selectedSMAccounts.map((account, i) => {
                                            return (
                                                <div className="account_selection_account" key={i} onClick={e => selectMediaAccount(account)}>
                                                    {checkAccountPhoto(account)}
                                                </div>
                                            )
                                        }
                                        )
                                        : ''
                                    }
                                </div>

                                {/* {SMGroups && SMGroups.length > 0 ?
                                    <select name="group" onChange={e => selectGroup(e)} value={selectedGroup}>
                                        <option value="">{t('all_accounts')}</option>
                                        {SMGroups.map((group, i) => {
                                            return (
                                                <option key={i} value={group._id}>{group.name}</option>
                                            )
                                        })}
                                    </select>
                                    : ""
                                }
                                <div className='row margin-t1'>

                                    {SMAccounts.map((account, i) => {
                                        return (
                                            <div className="account_selection_account" key={i} onClick={e => selectMediaAccount(account)}>
                                                {checkAccountPhoto(account)}
                                            </div>
                                        )
                                    }
                                    )}
                                </div> */}
                            </div>
                            <div className='column_left'>
                                <div className='row'>
                                    <div className="onoffswitch_draft">
                                        <input type="checkbox" className="onoffswitch_draft-checkbox" id="onoffSwitchUpcoming" checked={showUpcoming} onChange={() => setShowUpcoming(!showUpcoming)} />
                                        <label className="onoffswitch_draft-label" htmlFor="onoffSwitchUpcoming">
                                            <span className="onoffswitch_draft-inner"></span>
                                            <span className="onoffswitch_draft-switch"></span>
                                        </label>
                                    </div>
                                    <label className='margin-l1'>{t('show_upcoming')} (6)</label>
                                </div>
                            </div>
                            {/* <div className='flex-1 feed-nav'>
                        <svg viewBox="0 0 24 24" onClick={() => prevMonth()}>
                            <path fill="current" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                        </svg>
                        {currentMonth}
                        <svg viewBox="0 0 24 24" onClick={() => nextMonth()}>
                            <path fill="current" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                        </svg>
                    </div> */}

                        </div>
                    </div>

                    <div className='row'>
                        {selectedSMAccounts && selectedSMAccounts.length > 0 ?
                            selectedSMAccounts.map((account, i) => {
                                return (
                                    <AccountGrid key={i} authTokens={authTokens} headers={headers} account={account} showUpcoming={showUpcoming} today={today} />
                                )
                            }
                            )
                            : ''}
                    </div>
                </div>
            }
            {showAccountsSelection ?
                <>
                    <div className="transparent_background" onClick={displayAccountSelection}></div>
                    <div className="cal_accounts_picker">
                        <span className="close_modal" onClick={displayAccountSelection}>&times;</span>
                        <CalendarCoAccountsSelection allSMAccounts={allSMAccounts} checkAccountPhoto={checkAccountPhoto} selectMediaAccount={selectMediaAccount} />
                    </div>
                </>
                : ""
            }
        </>
    )
}

export default GridViews