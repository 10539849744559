import React, { useEffect, useState, useRef } from 'react'
import axios from "axios";

type IWindowProps = {
    url: string;
    title: string;
    width: number;
    height: number;
};

type IPopupProps = IWindowProps & {
    onClose: () => void;
    onCode: (code: string, oauth_token: string, oauth_verifier: string) => void;
    onTikTokCode: (auth_code: string) => void;
    children: React.ReactNode;
    initTokenTwitter: boolean;
    initTikTok: boolean;
};

const createPopup = ({
    url, title, height, width,
}: IWindowProps) => {
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const externalPopup = window.open(
        url,
        title,
        `width=${width},height=${height},left=${left},top=${top}`,
    );
    return externalPopup;
};



const PopUpAuth2 = ({
    title = '',
    width = 500,
    height = 500,
    url,
    children,
    onCode,
    onTikTokCode,
    onClose,
    initTokenTwitter,
    initTikTok,
}: IPopupProps) => {

    const [externalWindow, setExternalWindow] = useState<Window | null>();
    const intervalRef = useRef<number>();

    const clearTimer = () => {
        window.clearInterval(intervalRef.current);
    };

    const onContainerClick = async () => {
        if (initTokenTwitter) {
            await axios
                .get(process.env.REACT_APP_SOCIALPIKA_API_URL + "/social_media/twitter_request_token")
                .then((response) => {
                    const token_url = response.data.oauth_token;
                    url = "https://api.twitter.com/oauth/authenticate?oauth_token=" + token_url;

                })
                .catch(() => {
                });
        }

        setExternalWindow(createPopup({
            url, title, width, height,
        }));
    };

    useEffect(() => {
        if (externalWindow) {
            intervalRef.current = window.setInterval(() => {
                if (initTikTok) {
                    console.log("Tiktok login");
                    try {
                        const currentUrl = externalWindow.location.href;
                        console.log(currentUrl);
                        const params = new URL(currentUrl).searchParams;
                        const auth_code = params.get('code') || "";
                        console.log(auth_code);

                        onTikTokCode(auth_code);
                        clearTimer();
                        externalWindow.close();
                    } catch (error) {
                        // eslint-ignore-line
                    } finally {
                        if (!externalWindow || externalWindow.closed) {
                            onClose();
                            clearTimer();
                        }
                    }
                } else {
                    try {
                        const currentUrl = externalWindow.location.href;
                        const params = new URL(currentUrl).searchParams;
                        const code = params.get('code') || "";
                        const oauth_token = params.get('oauth_token') || "";
                        const oauth_verifier = params.get('oauth_verifier') || "";

                        onCode(code, oauth_token, oauth_verifier);
                        clearTimer();
                        externalWindow.close();
                    } catch (error) {
                        // eslint-ignore-line
                    } finally {
                        if (!externalWindow || externalWindow.closed) {
                            onClose();
                            clearTimer();
                        }
                    }
                }

            }, 3000);
        }
        return () => {
            if (externalWindow) externalWindow.close();
            if (onClose) onClose();
        };
    }, [externalWindow]);


    return (
        <div
            onClick={() => {
                onContainerClick();
            }}
        >
            {children}
        </div>
    )
}

export default PopUpAuth2
