import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";


import BurstModalImg from "../../images/welcome/burst-modal.png";


const BurstModal = ({ closeBurstModal }) => {

    const { t } = useTranslation(['composer']);


    const [loaded, setLoaded] = useState(false);

    const formatText = (text) => {
        let formatted_text = text;
        return formatted_text.replace(/\r\n|\r|\n/g, "<br />") + '<br />';
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 10);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            <div className={loaded ? "modal_background active" : "modal_background"}></div>
            <div className={loaded ? "welcome_modal_wrapper" : "welcome_modal_wrapper inactive"}>
                <span className="close_modal" onClick={closeBurstModal}>&times;</span>
                <div className="welcome_items_wrapper">
                    <div className="welcome_items">
                        <div className='welcome_item active' >
                            <img src={BurstModalImg} alt="" />
                            <div className='welcome_title'>{t('burst_welcome_title')}</div>
                            <div className="welcome_desc" dangerouslySetInnerHTML={{ __html: formatText(t('burst_welcome_desc')) }}></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default BurstModal