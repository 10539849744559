import React, { useState, useEffect } from 'react'
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";


const TWAnalytics = ({ insights, mainInsights }) => {
    const { t } = useTranslation('analytics_simple');

    const [impressionsData, setImpressionsData] = useState([]);
    const [engagementData, setEngagementData] = useState([]);

    const [impressionsOptions, setImpressionsOptions] = useState({
        chart: {
            height: 250,
            type: 'bar',
            zoom: {
                enabled: false
            }
        },
        colors: ['#19A4FE'],
        dataLabels: {
            enabled: false
        },
        title: {
            text: '',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {},
        yaxis: {
            labels: {
                formatter: function (val, index) {
                    return val.toFixed(0);
                }
            }
        }
    })

    const loadData = () => {

        // IMPRESSIONS
        let impressions_categories = [];
        insights.impressions_evolution.map(data => (
            impressions_categories.push(data.date)
        ))

        setImpressionsOptions({
            ...impressionsOptions,
            xaxis: {
                tickAmount: 15,
                categories: impressions_categories
            }
        })



        let impressions_data = [];
        insights.impressions_evolution.forEach(data => {
            impressions_data.push(Number(data.value))
        })
        setImpressionsData([{
            name: "Views",
            data: impressions_data
        }]);

        let engagements_data = [];
        insights.engagements_evolution.forEach(data => {
            engagements_data.push(Number(data.value))
        })
        setEngagementData([{
            name: "Views",
            data: engagements_data
        }]);
    }

    useEffect(() => {

        if (insights) { loadData() }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='row-100 row_hcenter margin-t2'>
            <div className='column_left flex-1'>
                <h2>{t('impressions')}</h2>
                <Chart
                    options={impressionsOptions}
                    series={impressionsData}
                    type='bar'
                    height='250px'
                    width='420px'
                />
            </div>
            <div className='column_left flex-1'>
                <h2>{t('engagement')}</h2>
                <Chart
                    options={impressionsOptions}
                    series={engagementData}
                    type='bar'
                    height='250px'
                    width='420px'
                />

            </div>
        </div>
    )
}

export default TWAnalytics