import React, { useState, useEffect } from 'react'
import { useAuth } from "../../../auth";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LogoSPK from "../../../images/logoSPK.svg"

import "./socials_modal.css";

const SocialsModal = ({ closeSocialsModal, twitter, pinterest, reauth_fb_ig }) => {
  const { t } = useTranslation(['smlink']);
  const history = useHistory();
  const { authTokens } = useAuth();

  const goToSMAccounts = () => {
    history.push({ pathname: '/sm_accounts' });
  }

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <div className={loaded ? "modal_background active" : "modal_background"}></div>
      <div className={loaded ? "modal_wrapper" : "modal_wrapper inactive"}>
        <span className="close_modal" onClick={closeSocialsModal}>&times;</span>
        <div className="column">
          <img src={LogoSPK} className="socials_modal_logo" alt="" />
          <h2>{t('hi')} {authTokens.user_first_name}, {t('first_things_first')}...</h2>
          {twitter ?
            <p>{t('link_sm_accounts_modal_twitter_simple')} 👇</p>
            : pinterest ?
              <p>{t('link_sm_accounts_modal_pinterest_simple')} 👇</p>
              : reauth_fb_ig ?
                <p>{t('link_sm_accounts_modal_fb_ig_auth')} 👇</p>
                :
                <p>{t('link_sm_accounts_modal_simple')} 👇</p>
          }
          {/* <p className="text-grey text-italic">{t('link_unlimited_account_modal')}</p> */}
          <div className="action_button margin-t2" onClick={() => goToSMAccounts()}>
            {t('connect_account')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SocialsModal