import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useAuth } from "../../auth";
import moment from 'moment'
import { useTranslation } from "react-i18next";
import Tooltip from '../sidebar/Tooltip';
import StateModal from '../Loader/StateModal';
import UpgradeModal from '../Plans/UpgradeModal';

import FBicon from "../../images/social_media/facebook.svg";
import TWicon from "../../images/social_media/twitter.svg";
import LKicon from "../../images/social_media/linkedin.svg";
import IGicon from "../../images/social_media/instagram.svg";
import PNicon from "../../images/social_media/pinterest.svg";
import GMBicon from "../../images/social_media/gmb.svg";
import TKTKicon from "../../images/social_media/tiktok-spk.svg";
import YTicon from "../../images/social_media/youtube.svg";
import SPKNoProfile from "../../images/social_media/SPK-no-profile.png"

import BlurPost from "../../images/blur_post.png"

import "./analytics.css";

const AnalyticsPerPost = (props) => {
    const { authTokens } = useAuth();
    const headers = {
        Authorization: authTokens.token,
    };
    const { t } = useTranslation(['analytics_simple']);
    const headerDateFormat = "MMMM YYYY";

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const [allPosts, setAllPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([])

    const [currentMonth, setCurrentMonth] = useState(moment());

    const nextMonth = () => {
        setCurrentMonth(moment(currentMonth).add(1, 'months'));
    };

    const prevMonth = () => {
        setCurrentMonth(moment(currentMonth).subtract(1, 'months'));
    };

    const formatNumber = (number) => {
        if (number / 1000000 > 1) {
            return Number(number / 1000000).toFixed(1) + 'M'
        }
        else if (number / 1000 > 1) {
            return Number(number / 1000).toFixed(1) + 'K'
        }
        else return number
    }

    const optimizedMediaURL = (url) => {
        if (url.includes("tr:")) {
            return url;
        }
        const url_options = "tr:n-ik_ml_thumbnail";
        // const url_options = "tr:h-150,pr-true";
        let arrayedURL = url.split("/");
        arrayedURL.splice(arrayedURL.length - 1, 0, url_options);
        const optimized_url = arrayedURL.join('/');
        return optimized_url;
    }

    const hashtagsFormat = (hashtag) => {
        const formattedText = '<span class="classic_tags">' + hashtag + '</span>';
        return formattedText
    }

    const formatCaption = (postData) => {
        let text = postData.text + (postData.tags ? '\n' + postData.tags : '');

        if (postData.thread) {
            text = postData.thread_content[0].text
        }


        let formatted_text = text;//`${text.toString()}`;
        const regex = /#\w*/g;
        let new_text = formatted_text.replace(regex, hashtagsFormat);


        return new_text.replace(/\r\n|\r|\n/g, "<br />") + '<br />';
        // return new_text;
    }

    const checkAccountPhoto = (accountData) => {

        if (!accountData) return;

        const selectedMediaAccount = selectedSMAccounts;
        let selected = false;
        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === accountData.social_id);

        if (indexFound !== -1) {
            selected = true;
        }

        switch (accountData.media) {
            case "Facebook":
                let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                if (accountData.type === "group") {
                    fb_picture_url = "https://graph.facebook.com/v12.0/" + accountData.social_id + "/picture?access_token=" + accountData.access_token;
                }

                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={fb_picture_url} alt={accountData.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={FBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Twitter":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "LinkedIn":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={LKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Instagram":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={IGicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Pinterest":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={PNicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "GMB":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={GMBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "TikTok":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TKTKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Youtube":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={YTicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
        }
    }

    const checkAccountPhotoPost = (event, sm_icon) => {

        let accountData = event.sm_account;

        switch (accountData.media) {
            case "Facebook":
                const fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={fb_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={FBicon} alt="Media" /> : ""}
                    </div>
                )
            case "Twitter":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={TWicon} alt="Media" /> : ""}
                    </div>
                )
            case "LinkedIn":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={LKicon} alt="Media" /> : ""}
                    </div>
                )
            case "Instagram":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={IGicon} alt="Media" /> : ""}
                    </div>
                )
            case "Pinterest":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={PNicon} alt="Media" /> : ""}
                    </div>
                )
            case "GMB":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={GMBicon} alt="Media" /> : ""}
                    </div>
                )
            default:
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" />
                        {sm_icon ? <img className="account_profile_media" src={TWicon} alt="Media" /> : ""}
                    </div>
                )
        }
    }

    const [allSMAccounts, setAllSMAccounts] = useState([]);
    const [SMAccounts, setSMAccounts] = useState([]);
    const [selectedSMAccounts, setSelectedSMAccounts] = useState([]);
    const [SMGroups, setSMGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState({});


    const selectGroup = (e) => {
        setSelectedGroup(e.target.value);
        let newlyAvailableSMAccounts = [];
        setShowPostsByPerformance('date');

        if (!e.target.value) {
            setSMAccounts(allSMAccounts);
        }
        else {
            const filteredGroupData = SMGroups.filter(group => group._id === e.target.value);

            filteredGroupData[0].sm_accounts.map(account => {
                let sm_account = allSMAccounts.filter(acc => acc._id === account);
                if (sm_account[0]) {
                    newlyAvailableSMAccounts.push(sm_account[0]);
                }
                return null;
            })

            setSMAccounts(newlyAvailableSMAccounts);
            if (newlyAvailableSMAccounts.length > 0) {
                let calendar_array = [];
                allPosts.forEach(event => {
                    let indexFound = newlyAvailableSMAccounts.findIndex((media) => media.social_id === event.event.sm_account.social_id);

                    if (indexFound !== -1) {
                        calendar_array.push(event)
                    }
                })

                setFilteredPosts(calendar_array)
            }
            else {
                setFilteredPosts([...allPosts]);
            }
        }
        setSelectedSMAccounts(newlyAvailableSMAccounts);
    }

    const selectMediaAccount = (account) => {
        let selectedMediaAccount = selectedSMAccounts;
        let resultingAccountsArray = [];
        setShowPostsByPerformance('date');

        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === account.social_id);

        if (indexFound !== -1) {
            const filteredMedia = selectedMediaAccount.filter(item => item.social_id !== account.social_id);
            resultingAccountsArray = [...filteredMedia];
            setSelectedSMAccounts(filteredMedia);

        }
        else {
            selectedMediaAccount.push(account);
            resultingAccountsArray = [...selectedMediaAccount];
            setSelectedSMAccounts([...selectedMediaAccount]);
        }

        if (resultingAccountsArray.length > 0) {
            let calendar_array = [];
            allPosts.forEach(event => {
                let indexFound = resultingAccountsArray.findIndex((media) => media.social_id === event.event.sm_account.social_id);

                if (indexFound !== -1) {
                    calendar_array.push(event)
                }
            })

            setFilteredPosts(calendar_array)
        }
        else {
            setFilteredPosts([...allPosts]);
        }
    }

    const [showPostsByPerformance, setShowPostsByPerformance] = useState('date');

    const sortPerformance = (perf) => {
        if (showPostsByPerformance === perf) {
            setShowPostsByPerformance('date');
        }
        else {
            setShowPostsByPerformance(perf);
        }
    }

    const sortPosts = () => {
        if (showPostsByPerformance === 'reach' && filteredPosts.length > 0) {

            let post_without_data = [];
            let post_with_data = [];

            filteredPosts.forEach(postData => {
                if (!postData.hasOwnProperty('insights') || !postData.insights.hasOwnProperty('people_reached')) {
                    post_without_data.push(postData);

                }
                else {
                    post_with_data.push(postData);
                }
            })


            const orderedArray = post_with_data.sort((a, b) => (b.insights.people_reached - a.insights.people_reached));

            setFilteredPosts([...orderedArray, ...post_without_data]);
        }
        else if (showPostsByPerformance === 'engagement' && filteredPosts.length > 0) {

            let post_without_data = [];
            let post_with_data = [];

            filteredPosts.forEach(postData => {
                if (!postData.hasOwnProperty('insights') || !postData.insights.hasOwnProperty('engagements')) {
                    post_without_data.push(postData);

                }
                else {
                    post_with_data.push(postData);
                }
            })


            const orderedArray = post_with_data.sort((a, b) => (b.insights.engagements - a.insights.engagements));

            setFilteredPosts([...orderedArray, ...post_without_data]);
        }
        else if (filteredPosts.length > 0) {
            const orderedArray = filteredPosts.sort((a, b) => moment(a.event.publication_date) - moment(b.event.publication_date));
            setFilteredPosts([...orderedArray]);
        }
    }

    const fetchAvailableSocialMedia = async () => {
        let authError = false;
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_accounts_no_auth_check", payload, {
                headers: headers,
            })
            .then((response) => {
                setAllSMAccounts(response.data.sm_accounts);
                setSMAccounts(response.data.sm_accounts);
                setSMGroups(response.data.sm_groups);
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
            });

        if (authError) return;
        closeStateModal();
    }

    const fetchAllAnalysedPosts = async () => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        setAllPosts([]);
        setFilteredPosts([]);

        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id,
            month: moment(currentMonth)
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/analytics/get_analysed_posts", payload, {
                headers: headers,
            })
            .then((response) => {
                setAllPosts(response.data);
                setFilteredPosts(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

        closeStateModal();
    }


    useEffect(() => {
        if (authTokens.plan > 0) {
            fetchAvailableSocialMedia();
            fetchAllAnalysedPosts();
            setShowPostsByPerformance('date');
        }
        else {
            setShowUpgradeModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (authTokens.plan > 0) {
            fetchAllAnalysedPosts();
            setShowPostsByPerformance('date');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMonth]);

    useEffect(() => {
        if (authTokens.plan > 0) {
            sortPosts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPostsByPerformance]);

    return (
        <>
            {showUpgradeModal ?
                <div>
                    <img src={BlurPost} alt="" />
                    <UpgradeModal upgrade={true} closeModal={() => setShowUpgradeModal(false)} />
                </div>
                :
                <div className="content_row">
                    {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
                    <div className="card card_with_width">
                        <div className='row_vcenter'>
                            <div className='flex-1'>
                                <div className='feed-nav'>
                                    <svg viewBox="0 0 24 24" onClick={() => prevMonth()}>
                                        <path fill="current" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                                    </svg>
                                    {currentMonth.format(headerDateFormat)}
                                    <svg viewBox="0 0 24 24" onClick={() => nextMonth()}>
                                        <path fill="current" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                                    </svg>
                                </div>
                                <div className='row margin-t2'>
                                    <div className="onoffswitch_draft">
                                        <input type="checkbox" className="onoffswitch_draft-checkbox" id="onoffSwitchReach" checked={showPostsByPerformance === 'reach' ? true : false} onChange={() => sortPerformance('reach')} />
                                        <label className="onoffswitch_draft-label" htmlFor="onoffSwitchReach">
                                            <span className="onoffswitch_draft-inner"></span>
                                            <span className="onoffswitch_draft-switch"></span>
                                        </label>
                                    </div>
                                    <label className='margin-l1'>{t('sort_reach')}</label>
                                </div>
                                <div className='row margin-t1'>
                                    <div className="onoffswitch_draft">
                                        <input type="checkbox" className="onoffswitch_draft-checkbox" id="onoffSwitchEngagement" checked={showPostsByPerformance === 'engagement' ? true : false} onChange={() => sortPerformance('engagement')} />
                                        <label className="onoffswitch_draft-label" htmlFor="onoffSwitchEngagement">
                                            <span className="onoffswitch_draft-inner"></span>
                                            <span className="onoffswitch_draft-switch"></span>
                                        </label>
                                    </div>
                                    <label className='margin-l1'>{t('sort_engagement')}</label>
                                </div>
                            </div>
                            <div className='column_left flex-2'>
                                {SMGroups && SMGroups.length > 0 ?
                                    <select className="editor" name="group" onChange={e => selectGroup(e)} value={selectedGroup}>
                                        <option value="">{t('all_accounts')}</option>
                                        {SMGroups.map((group, i) => {
                                            return (
                                                <option key={i} value={group._id}>{group.name}</option>
                                            )
                                        })}
                                    </select>
                                    : ""
                                }
                                <div className='row margin-t1'>

                                    {SMAccounts.map((account, i) => {
                                        return (
                                            <div className="account_selection_account" key={i} onClick={e => selectMediaAccount(account)}>
                                                {checkAccountPhoto(account, true)}
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='row'>
                        {filteredPosts && filteredPosts.length > 0 ?
                            filteredPosts.map((post, i) => {
                                return (
                                    <div key={post._id} className='draft_post'>
                                        <div className='account_header'>
                                            {checkAccountPhotoPost(post.event, true)}
                                            <div className="profile_text">
                                                <span className="acc_name">{post.event.sm_account.social_name}</span>
                                                <span className="pub_date">{moment(post.event.publication_date).format('ddd, Do @ HH.mm')}</span>
                                            </div>
                                        </div>
                                        {post.event.scheduled_post.medias && post.event.scheduled_post.medias.length > 0 ?
                                            <div className='draft-view-media'>
                                                {post.event.scheduled_post.medias[0].type === 'image' ?
                                                    <img src={optimizedMediaURL(post.event.scheduled_post.medias[0].file_url)} alt="" />
                                                    :
                                                    <div className='analytics-view-video-container'>
                                                        {post.event.scheduled_post.medias[0].thumbnail_url ?
                                                            <img src={post.event.scheduled_post.medias[0].thumbnail_url} alt="" />
                                                            :
                                                            <video>
                                                                <source src={post.event.scheduled_post.medias[0].file_url} />
                                                            </video>
                                                        }
                                                        <div className='video_icon'>
                                                            <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                                                <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                }
                                                <div className='hover_caption' dangerouslySetInnerHTML={{ __html: formatCaption(post.event.scheduled_post) }}></div>
                                            </div>
                                            :
                                            <div className='draft-view-caption' dangerouslySetInnerHTML={{ __html: formatCaption(post.event.scheduled_post) }}></div>
                                        }
                                        {/* <p>{post.event.linkedin_type}</p> */}
                                        {/* <p>{post.event.post_id}</p> */}
                                        <div className='post_analytics'>
                                            {post.hasOwnProperty('insights') && post.insights.hasOwnProperty('impressions') ?
                                                <div className="post_analytics_data">
                                                    <p>{t('impressions')}</p>
                                                    <p>{formatNumber(post.insights.impressions)}</p>
                                                </div>
                                                : ''}
                                            {post.hasOwnProperty('insights') && post.insights.hasOwnProperty('people_reached') ?
                                                <div className="post_analytics_data">
                                                    <p>{t('reach')}</p>
                                                    <p>{formatNumber(post.insights.people_reached)}</p>
                                                </div>
                                                : ''}
                                            {post.hasOwnProperty('insights') && post.insights.hasOwnProperty('engagements') ?
                                                <div className="post_analytics_data">
                                                    <p>{t('engagement')}</p>
                                                    <p>{formatNumber(post.insights.engagements)}</p>
                                                </div>
                                                : ''}
                                        </div>
                                    </div>
                                )
                            })
                            : ''
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default AnalyticsPerPost