import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from "react-i18next";
import VideoComplianceChecker from './VideoComplianceChecker';
import Resizer from "react-image-file-resizer";

const handleMediaFile = (file) => {
    return new Promise(function (resolve, reject) {
        let fr = new FileReader();

        fr.onload = function () {
            resolve(fr.result);
        };

        fr.onerror = function () {
            reject(fr);
        };

        fr.readAsDataURL(file);
    });
}

const VideoChecker = ({ displayVideoChecker, videoFile, setMediaLoader, uploadVideo, videoCheckerResults, setVideoCheckerResults, pinterest, thread }) => {
    const { t } = useTranslation(['media_checker']);
    const videoSourceRef = useRef(null);

    const [videoSrc, setVideoSrc] = useState('');
    const [thumbnailTimecode, setThumbnailTimecode] = useState(0);

    const [localLoader, setLocalLoader] = useState(false);

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1200,
                1080,
                "JPEG",
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    const handleVideo = () => {
        console.log("hanlding new video");
        const url = URL.createObjectURL(videoFile);
        setVideoSrc(url);

        // const video = document.createElement('video')
        // video.addEventListener('loadedmetadata', event => {
        //     setVideoSrc(video.src);
        // })
        // video.src = URL.createObjectURL(videoFile)
    }

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 10);
        return () => clearTimeout(timer);
    }, [])

    const captureFrame = (video) => {

        var w = video.videoWidth;
        var h = video.videoHeight;
        var canvas = document.createElement('canvas');
        canvas.width = w;
        canvas.height = h;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, w, h);
        return canvas;
    }

    const uploadVideoPrep = () => {

        uploadVideo(videoFile, frameOutput, thumbnailTimecode);
        displayVideoChecker();
    }

    const [frameOutput, setFrameOutput] = useState();

    const shoot = () => {
        var video = videoSourceRef.current;
        setThumbnailTimecode(Math.floor(video.currentTime * 1000));
        var canvas = captureFrame(video);

        setFrameOutput(canvas.toDataURL("image/jpeg", 1));
    }

    const [dragActive, setDragActive] = useState(false);

    const onFileDrop = async (event) => {
        setDragActive(false);
        setLocalLoader(true);
        event.preventDefault();
        event.stopPropagation();

        let files = event.dataTransfer.files;
        let readers = [];

        // Abort if there were no files selected
        if (!files.length) return;

        // Store promises in array  
        readers.push(handleMediaFile(files[0]));


        const filesData = await Promise.all(readers);

        let match = filesData[0].match(/^data:([^/]+)\/([^;]+);/) || [];

        let type = match[1];
        if (type === 'image') {
            const image = await resizeFile(files[0]);
            setFrameOutput(image);
        }
        else if (type === 'video') {
            return;
        }

        setLocalLoader(false);
    };

    const onDragOver = (event) => {
        setDragActive(true);
        event.stopPropagation();
        event.preventDefault();
    }

    const onDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const onDragLeave = (event) => {
        setDragActive(false);
        event.preventDefault();
        event.stopPropagation();
    }


    useEffect(() => {
        handleVideo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoFile])


    return (
        <div>
            <div className={loaded ? "cropper_bg active" : "cropper_bg"}></div>
            <div className={loaded ? "cropper_wrapper" : "cropper_wrapper inactive"}>
                <span className="close_modal" onClick={displayVideoChecker}>&times;</span>
                <div className='row row-100 row_spaced margin-t2'>
                    <div></div>
                    <div className="action_button" onClick={() => uploadVideoPrep()}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
                        </svg>
                        {t('save')}
                    </div>
                </div>
                {(videoSrc) ?
                    <div className='row nowrap margin-t2'>
                        <div className="column">
                            <div className='video_container'>
                                <video ref={videoSourceRef} controls>
                                    <source src={videoSrc} />
                                </video>
                                <div className='action_button_outline margin-t2' onClick={() => shoot()}>
                                    <svg viewBox='0 0 48 48'>
                                        <path fill="current" d="M24 35q3.6 0 6.225-2.6 2.625-2.6 2.625-6.25 0-3.6-2.625-6.225Q27.6 17.3 24 17.3q-3.65 0-6.25 2.625t-2.6 6.225q0 3.65 2.6 6.25T24 35Zm0-1.65-2.25-5.1-4.95-2.05 4.95-2.1L24 19l2.2 5.1 4.95 2.1-4.95 2.05ZM7 42q-1.2 0-2.1-.9Q4 40.2 4 39V13.35q0-1.15.9-2.075.9-.925 2.1-.925h7.35L18 6h12l3.65 4.35H41q1.15 0 2.075.925Q44 12.2 44 13.35V39q0 1.2-.925 2.1-.925.9-2.075.9Zm34-3V13.35h-8.75L28.6 9h-9.2l-3.65 4.35H7V39ZM24 26.15Z" />
                                    </svg>
                                    {t('capture_thumbnail')}
                                </div>
                            </div>
                            <div className="row-100 margin-t4">
                                <VideoComplianceChecker videoSrc={videoSrc} pinterest={pinterest} thread={thread} videoCheckerResults={videoCheckerResults} setVideoCheckerResults={setVideoCheckerResults} />
                            </div>
                        </div>
                        <div className="column margin-l2">
                            <div className={dragActive ? 'thumbnail_capture_mockup active' : 'thumbnail_capture_mockup'} onDrop={(e) => onFileDrop(e)} onDragEnter={(e) => onDragEnter(e)} onDragOver={(e) => onDragOver(e)} onDragLeave={(e) => onDragLeave(e)}>
                                {localLoader ?
                                    <div className="lds-ring-loader">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    : frameOutput ?
                                        <>
                                            <div className='captured_thumbnail'>
                                                <img src={frameOutput} alt="" />
                                            </div>
                                            {thumbnailTimecode > 0 ?
                                                <p className="text-grey margin-t2">{thumbnailTimecode / 1000}s</p>
                                                : ''}
                                        </>
                                        :
                                        <>
                                            <svg viewBox='0 0 48 48'>
                                                <path fill="current" d="M24 35q3.6 0 6.225-2.6 2.625-2.6 2.625-6.25 0-3.6-2.625-6.225Q27.6 17.3 24 17.3q-3.65 0-6.25 2.625t-2.6 6.225q0 3.65 2.6 6.25T24 35Zm0-1.65-2.25-5.1-4.95-2.05 4.95-2.1L24 19l2.2 5.1 4.95 2.1-4.95 2.05ZM7 42q-1.2 0-2.1-.9Q4 40.2 4 39V13.35q0-1.15.9-2.075.9-.925 2.1-.925h7.35L18 6h12l3.65 4.35H41q1.15 0 2.075.925Q44 12.2 44 13.35V39q0 1.2-.925 2.1-.925.9-2.075.9Zm34-3V13.35h-8.75L28.6 9h-9.2l-3.65 4.35H7V39ZM24 26.15Z" />
                                            </svg>
                                            <p className="text-grey">{t('play_video_capture_thumbnail')}</p>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div className="lds-ring-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                }
            </div>
        </div>
    )
}

export default VideoChecker