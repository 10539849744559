import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";

import Intro from "../../../images/welcome/3ps-intro.png";
import Posts from "../../../images/welcome/3ps-posts.png";
import Nav from "../../../images/welcome/3ps-nav.png";

const CalendarThirdHelpModal = ({ closeModal }) => {
    const { t } = useTranslation(['calendar']);
    const [loaded, setLoaded] = useState(false);


    const modalItems = ['intro', 'posts', 'navcal'];
    const [modalItem, setModalItem] = useState('intro');
    const [currentIndex, setCurrentIndex] = useState(0);
    const goToNav = (index) => {
        setModalItem(modalItems[index]);
        setCurrentIndex(index);
    }

    const formatText = (text) => {
        let formatted_text = text;
        return formatted_text.replace(/\r\n|\r|\n/g, "<br />") + '<br />';
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 10);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            <div className={loaded ? "modal_background active" : "modal_background"} onClick={closeModal}></div>
            <div className={loaded ? "welcome_modal_wrapper" : "welcome_modal_wrapper inactive"}>
                <span className="close_modal" onClick={closeModal}>&times;</span>
                <div className="welcome_items_wrapper">
                    <div className="welcome_items">
                        <div className={modalItem === 'intro' ? 'welcome_item active' : 'welcome_item'} >
                            <img src={Intro} alt="" />
                            <div className='welcome_title'>{t('3ps_intro_title')}</div>
                            <div className="welcome_desc" dangerouslySetInnerHTML={{ __html: formatText(t('3ps_intro_desc')) }}></div>
                        </div>
                        <div className={modalItem === 'posts' ? 'welcome_item active' : 'welcome_item'} >
                            <img src={Posts} alt="" />
                            <div className='welcome_title'>{t('3ps_posts_title')}</div>
                            <div className="welcome_desc" dangerouslySetInnerHTML={{ __html: formatText(t('3ps_posts_desc')) }}></div>
                        </div>
                        <div className={modalItem === 'navcal' ? 'welcome_item active' : 'welcome_item'} >
                            <img src={Nav} alt="" />
                            <div className='welcome_title'>{t('3ps_nav_title')}</div>
                            <div className="welcome_desc" dangerouslySetInnerHTML={{ __html: formatText(t('3ps_nav_desc')) }}></div>
                        </div>
                        <div className="welcome_nav">
                            {modalItems && modalItems.length > 0 ?
                                modalItems.map((element, index) => {
                                    return (
                                        <div key={index} className={index === currentIndex ? 'welcome_nav_dot active' : 'welcome_nav_dot'} onClick={() => index !== currentIndex ? goToNav(index) : null}></div>
                                    )
                                })
                                : ''
                            }
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default CalendarThirdHelpModal