import React from 'react'
import axios from "axios";
import { useAuth } from '../../../auth';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import TKTKicon from "../../../images/social_media/tiktok-spk.svg";
import PNicon from "../../../images/social_media/pinterest.svg";

const DashboardCoIssues = ({ actionsToTake, fetchIssues }) => {
    const { t } = useTranslation(['issues']);
    const { authTokens } = useAuth();
    const headers = {
        Authorization: authTokens.token,
    };

    const clearAction = async (issue_id) => {
        const payload = {
            action_id: issue_id,
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/action_handled_by_user", payload, {
                headers: headers,
            })
            .then(() => {
                fetchIssues();
            })
            .catch(() => {
            });
    }

    const checkCode = (code, media, social_name, issue_id) => {
        let icon_code = '';
        if (media === 'Instagram') {
            icon_code = <img src={IGicon} alt="Instgram" />
        }
        else if (media === 'Facebook') {
            icon_code = <img src={FBicon} alt="Facebook" />
        }
        else if (media === 'Twitter') {
            icon_code = <img src={TWicon} alt="Twitter" />
        }
        else if (media === 'LinkedIn') {
            icon_code = <img src={LKicon} alt="LinkedIn" />
        }
        else if (media === 'Pinterest') {
            icon_code = <img src={PNicon} alt="Pinterest" />
        }
        else if (media === 'GMB') {
            icon_code = <img src={GMBicon} alt="Google Business Profile" />
        }
        else if (media === 'TikTok') {
            icon_code = <img src={TKTKicon} alt="TikTok" />
        }

        switch (Number(code)) {
            case 10000:
                return (
                    <>
                        <div className='issue_block'>
                            <svg viewBox="0 0 47 47">
                                <g clipPath="url(#clip0_607_591)">
                                    <path fill="current" d="M23.5 2.60419C20.8754 2.60419 18.3584 3.64678 16.5025 5.50261C14.6467 7.35844 13.6041 9.87548 13.6041 12.5C13.6041 15.1246 14.6467 17.6416 16.5025 19.4974C18.3584 21.3533 20.8754 22.3959 23.5 22.3959C26.1245 22.3959 28.6415 21.3533 30.4974 19.4974C32.3532 17.6416 33.3958 15.1246 33.3958 12.5C33.3958 9.87548 32.3532 7.35844 30.4974 5.50261C28.6415 3.64678 26.1245 2.60419 23.5 2.60419V2.60419Z" />
                                    <path d="M24.8396 15.1229H27.4006L27.8027 12.5213H24.8391V11.0995C24.8391 10.0187 25.1922 9.0604 26.2032 9.0604H27.8277V6.79009C27.5422 6.75155 26.9386 6.66718 25.798 6.66718C23.4162 6.66718 22.0198 7.92499 22.0198 10.7906V12.5213H19.5714V15.1229H22.0198V22.2734C22.5047 22.3463 22.9959 22.3958 23.5001 22.3958C23.9558 22.3958 24.4006 22.3542 24.8396 22.2948V15.1229Z" fill="white" />
                                    <path fill="current" d="M44.875 27.4734C44.1859 27.7786 43.4448 27.9823 42.663 28.0786C43.4578 27.6068 44.0692 26.8542 44.3562 25.9651C43.6125 26.401 42.7885 26.7214 41.912 26.8901C41.212 26.1479 40.2135 25.6875 39.1073 25.6875C36.9823 25.6875 35.2599 27.3948 35.2599 29.5C35.2599 29.7979 35.2948 30.088 35.3604 30.3698C32.1635 30.2094 29.3281 28.6896 27.4312 26.3833C27.0979 26.9469 26.9104 27.6068 26.9104 28.3031C26.9104 29.6266 27.588 30.7917 28.6213 31.4781C27.9906 31.4589 27.3969 31.2833 26.8771 31.0021C26.8771 31.0135 26.8771 31.0307 26.8771 31.0469C26.8771 32.8964 28.2036 34.4365 29.962 34.787C29.6406 34.875 29.301 34.924 28.951 34.924C28.7026 34.924 28.4604 34.8958 28.226 34.8536C28.7156 36.3651 30.1359 37.4698 31.8187 37.5026C30.5021 38.5234 28.8437 39.1349 27.0406 39.1349C26.7291 39.1349 26.4245 39.1172 26.1234 39.0807C27.8271 40.1594 29.85 40.7917 32.0213 40.7917C39.0968 40.7917 42.9677 34.9813 42.9677 29.9406C42.9677 29.7755 42.9625 29.6109 42.9547 29.4484C43.7099 28.9151 44.3609 28.2422 44.875 27.4734Z" />
                                    <path fill="current" d="M21.875 40.2708C21.875 41.7094 20.7094 42.875 19.2708 42.875H5.72917C4.29115 42.875 3.125 41.7094 3.125 40.2708V26.7292C3.125 25.2906 4.29115 24.125 5.72917 24.125H19.2708C20.7094 24.125 21.875 25.2906 21.875 26.7292V40.2708Z" />
                                    <path d="M6.25 30.8958H8.85417V39.75H6.25V30.8958ZM7.54427 29.8542H7.52969C6.7526 29.8542 6.25 29.275 6.25 28.5516C6.25 27.8125 6.76823 27.25 7.55937 27.25C8.35156 27.25 8.83958 27.8125 8.85417 28.5516C8.85417 29.2745 8.35156 29.8542 7.54427 29.8542ZM18.75 39.75H16.1458V35.0109C16.1458 33.8661 15.5078 33.0849 14.4833 33.0849C13.7016 33.0849 13.2786 33.612 13.0734 34.1214C12.9984 34.3036 13.0208 34.8078 13.0208 35.0625V39.75H10.4167V30.8958H13.0208V32.2583C13.3964 31.6771 13.9844 30.8958 15.4885 30.8958C17.3521 30.8958 18.7495 32.0677 18.7495 34.6844L18.75 39.75Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_607_591">
                                        <rect width="47" height="47" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            {t('add_accounts')}
                        </div>
                        <div className='action_description'>{t('add_account_description')}</div>
                        <div className='action_link'>
                            <Link to="/sm_accounts">
                                {t('go')}
                            </Link>
                        </div>
                    </>
                )
            case 10001:
                return (
                    <>
                        <div className='issue_block'>
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="M44 11v26q0 1.2-.9 2.1-.9.9-2.1.9H7q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1ZM7 16.45h34V11H7Zm0 6.45V37h34V22.9ZM7 37V11v26Z" />
                            </svg>
                            {t('payment_failure')}
                        </div>
                        <div className='action_description'>{t('payment_failure_desc')}</div>
                        <div className='action_link'>
                            <Link to="/plans">
                                {t('go')}
                            </Link>
                        </div>
                    </>
                )
            case 88001:
                return (
                    <>
                        <div className='issue_block'>
                            {icon_code}
                            {t('ig_account_not_business')}
                        </div>
                        <div className='action_description'>{t('ig_account')} - <span className="text-bold">{social_name}</span> - {t('ig_account_not_business_solution')}</div>
                        <div className='clear_link' onClick={() => clearAction(issue_id)}>
                        <svg viewBox="0 96 960 960">
                                <path fill="current" d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                            </svg>
                            {t('clear')}
                            </div>
                    </>
                )
            case 88101:
                return (
                    <>
                        <div className='issue_block'>
                            {icon_code}
                            {t('tw_account_temp_lock')}
                        </div>
                        <div className='action_description'>{t('tw_temp_lock')} - <span className="text-bold">{social_name}</span>. {t('tw_account_temp_lock_solution')}</div>
                        <div className='clear_link' onClick={() => clearAction(issue_id)}>
                        <svg viewBox="0 96 960 960">
                                <path fill="current" d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                            </svg>
                            {t('clear')}
                            </div>
                    </>
                )
            case 88201:
                return (
                    <>
                        <div className='issue_block'>
                            {icon_code}
                            {t('gmb_cant_auth')}
                        </div>
                        <div className='action_description'>{t('gmb_cant_auth_desc')} - <span className="text-bold">{social_name}</span>. {t('gmb_cant_auth_solution')}</div>
                        <div className='clear_link' onClick={() => clearAction(issue_id)}>
                            <svg viewBox="0 96 960 960">
                                <path fill="current" d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                            </svg>
                            {t('clear')}
                        </div>
                    </>
                )
            case 88301:
                return (
                    <>
                        <div className='issue_block'>
                            {icon_code}
                            {t('lk_auth')}
                        </div>
                        <div className='action_description'>{t('lk_auth_desc')} - <span className="text-bold">{social_name}</span>. {t('lk_auth_solution')}</div>
                        <div className='clear_link' onClick={() => clearAction(issue_id)}>
                            <svg viewBox="0 96 960 960">
                                <path fill="current" d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                            </svg>
                            {t('clear')}
                        </div>
                    </>
                )
            default:
                return (
                    <>
                        <p>Default</p>
                    </>
                )
        }
    }

    return (
        <div>
            <ul className="dash_issues_list">
                {actionsToTake.map(item => {
                    return (
                        <li>{checkCode(item.reason_code, item.social_media, item.social_name, item._id)}</li>
                    )
                })}
            </ul>
        </div>
    )
}

export default DashboardCoIssues