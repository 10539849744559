import React, { useEffect } from 'react'
// import axios from "axios";
import { useAuth } from "../../auth";
// import { useHistory } from "react-router-dom";

import TagsList from './ToolsComponents/TagsList';
import SchedulesList from './ToolsComponents/SchedulesList';
import SignaturesList from './ToolsComponents/SignaturesList';
import RSSList from './ToolsComponents/RSSList';

import "./tools.css"

const Tools = (props) => {
    const { authTokens } = useAuth();

    // const headers = {
    //     Authorization: authTokens.token,
    // };
    // const history = useHistory();


    // const [stateModalOptions, setStateModalOptions] = useState({
    //     display: false,
    //     status: "",
    //     message: "",
    //     loader: false
    // });
    // const closeStateModal = () => {
    //     setStateModalOptions({
    //         display: false,
    //         status: "",
    //         message: "",
    //         loader: false
    //     })
    // }

    // const goToPlans = () => {
    //     history.push({ pathname: '/subscription' });
    // }

    // const [SMAccounts, setSMAccounts] = useState([]);

    // const fetchAvailableSocialMedia = async () => {

    //     setStateModalOptions({
    //         ...stateModalOptions,
    //         display: true,
    //         loader: true
    //     });

    //     // NEW FUNCTION - TEAM COMPLIANT
    //     const payload = {
    //         company_id: authTokens.account_id,
    //         user_id: authTokens.user_id
    //     }

    //     await axios
    //         .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_accounts_no_auth_check", payload, {
    //             headers: headers,
    //         })
    //         .then((response) => {
    //             setSMAccounts(response.data.sm_accounts);
    //         })
    //         .catch(() => {
    //         });

    //     closeStateModal();
    // }







    useEffect(() => {
        // fetchAvailableSocialMedia();
        props.changeNav("Tools", "Tools");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="content_row_maxed">
                <TagsList authTokens={authTokens} />
                <SignaturesList authTokens={authTokens} />
                <SchedulesList authTokens={authTokens} />
            </div>
            <div className="content_row_maxed margin-t2">
                <RSSList authTokens={authTokens} />
            </div>
        </div>
    )
}

export default Tools