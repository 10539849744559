import React from 'react'

import "./post_preview_pn.css";
import PN_icons from "../../../images/pn_icons.png";
// import PNicon from "../../../images/social_media/pinterest.svg";


const PostPreviewPN = ({ publicationData, calSelectedEvent, selectedPhotos }) => {

    let pn_check = false;

    let pn_index;

    if (calSelectedEvent) {
        pn_index = publicationData.social_accounts.findIndex((account) => account._id.toString() === calSelectedEvent._id.toString());
    }
    else {
        pn_index = publicationData.social_accounts.findIndex((account) => account.media === "Pinterest");
    }
    // const pn_index = publicationData.social_accounts.findIndex((account) => account.media === "Pinterest");

    if (pn_index !== -1) {
        pn_check = true;
    }

    // const optimizedMediaURL = (url) => {
    //     if (url.includes("tr:")) {
    //         return url;
    //     }
    //     const url_options = "tr:n-ik_ml_thumbnail";
    //     // const url_options = "tr:h-150,pr-true";
    //     let arrayedURL = url.split("/");
    //     arrayedURL.splice(arrayedURL.length - 1, 0, url_options);
    //     const optimized_url = arrayedURL.join('/');
    //     return optimized_url;
    // }

    return (
        pn_check ?
            <div>
                {/* <div className="pn_container_preview">
                    <div className="pn_media_preview">
                        {selectedPhotos && selectedPhotos.length > 0 ?
                            <div>
                                <div className="pn_plus_sign">
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                                    </svg>
                                </div>
                                {selectedPhotos[0].file_type === "image" ?
                                    <img src={watermark ? selectedPhotos[0].file_url_watermark : selectedPhotos[0].file_url} alt="" />
                                    :
                                    <div className="video-pn-container">
                                        <video className="reference-tw-thumb-video">
                                            <source src={selectedPhotos[0].file_url} />
                                        </video>
                                        <div className="video-overlay">
                                            <svg viewBox="0 0 24 24">
                                                <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                            </svg>
                                        </div>
                                    </div>
                                }
                            </div>
                            : ""}
                    </div>
                    <div className="pn_title_preview">{publicationData.tags}</div>
                </div> */}
                <div className={publicationData.draft ? "pn_container preview_card_draft" : "pn_container"}>
                    <div className="pn_media">
                        {selectedPhotos && selectedPhotos.length > 0 ?
                            selectedPhotos[0].type === "image" ?
                                <img src={selectedPhotos[0].file_url} alt="" />
                                :
                                <div className="video-pn-container">
                                    {selectedPhotos[0].thumbnail_url ?
                                        <img key={selectedPhotos[0]._id} src={selectedPhotos[0].thumbnail_url} alt="" />
                                        :
                                        <video className="reference-tw-thumb-video">
                                            <source src={selectedPhotos[0].file_url} />
                                        </video>
                                    }
                                    <div className='video_icon'>
                                        <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                            <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                        </svg>
                                    </div>
                                    {/* <div className="video-overlay">
                                        <svg viewBox="0 0 24 24">
                                            <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                        </svg>
                                    </div> */}
                                </div>
                            : ""}
                    </div>
                    <div className="pn_content">
                        <div className="pn_header">
                            <img className="pn_icons" src={PN_icons} alt="" />
                        </div>
                        <div className="pn_link">{publicationData.link_url}</div>
                        <div className="pn_title">{publicationData.tags}</div>
                        <div className="pn_description">{publicationData.text}</div>
                        {/* <div className="pn_description" dangerouslySetInnerHTML={{ __html: formatText(publicationData.text) }}></div> */}
                        <div className="pn_account">
                            <img className="pn_icon" src={publicationData.social_accounts[pn_index].profile_picture_url} alt="" />
                            <div className="pn_nickname_block">
                                <span className="pn_nickname">{publicationData.social_accounts[pn_index].social_name}</span>
                                <span className="pn_followers">232 followers</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : ""
    )
}

export default PostPreviewPN
