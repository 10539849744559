import React, { useState, useLayoutEffect, useRef } from 'react'
import { useTranslation } from "react-i18next";
import Tooltip from '../../sidebar/Tooltip';
import TooltipHelp from '../../settings/TooltipHelp';

import ContentMedia from './ContentMedia';
import AccountsSelection from './AccountsSelection';
import Picker from 'emoji-picker-react';
import ComposerCoAICaption from '../../Composer/ComposerComponents/ComposerCoAICaption';
import TagsSelection from './TagsSelection';
import Signature from './Signature';

import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import TKTKicon from "../../../images/social_media/tiktok-spk.svg";
import YTicon from "../../../images/social_media/youtube.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"
import FBGroupIcon from "../../../images/social_media/fb_group.png";



const MIN_TEXTAREA_HEIGHT = 60;
const MAX_CHAR_COUNT = 1500;
const MAX_TWITTER_COUNT = 280;



const Content = ({
    editorMenuItem,
    authTokens,
    currentPlan,
    displayUpgradeModal,
    publicationData,
    setPublicationData,
    allSMAccounts,
    SMAccounts,
    setSMAccounts,
    SMGroups,
    socialPlatforms,
    scrapLink,
    urlToScrap,
    stateModalOptions,
    setStateModalOptions,
    mediaTransfer,
    bulk,
    twitterCharCount,
    setTwitterCharCount,
    totalCharCount,
    setTotalCharCount,
    tagsCount,
    urlLoader,
    videoCheckerResults,
    setVideoCheckerResults
}) => {
    const { t } = useTranslation(['composer']);

    const textareaRef = useRef(null);

    const [showAccountsSelection, setShowAccountsSelection] = useState(false);
    const displayAccountSelection = () => {
        setShowAccountsSelection(!showAccountsSelection);
    }

    const checkAccountPhoto = (accountData) => {

        if (!accountData) return;

        switch (accountData.media) {
            case "Facebook":
                let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                if(accountData.fb_group){
                    fb_picture_url=FBGroupIcon
                }

                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src={fb_picture_url} alt={accountData.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={FBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Twitter":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "LinkedIn":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={LKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Instagram":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={IGicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "GMB":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={GMBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "TikTok":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TKTKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Youtube":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={YTicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className="account_profile_photo selected">
                            <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
        }
    }

    // CAPTION COMPONENTS

    const handleCharacterCount = (input_text) => {
        let matches = input_text.match(/\bhttp\S+/gi);

        if (!matches || matches.length === 0) {
            setTwitterCharCount(MAX_TWITTER_COUNT - input_text.length - publicationData.tags.length - (publicationData.link_url.length > 0 ? 23 : 0));
            setTotalCharCount(MAX_CHAR_COUNT - input_text.length - publicationData.tags.length - (publicationData.link_url.length > 0 ? 23 : 0));
        }
        else {
            let string_to_count = input_text;
            matches.forEach(string => {
                string_to_count = string_to_count.replaceAll(string, "");
            })
            setTwitterCharCount(MAX_TWITTER_COUNT - string_to_count.length - publicationData.tags.length - (publicationData.link_url.length > 0 ? 23 : 0) - matches.length * 23);
            setTotalCharCount(MAX_CHAR_COUNT - string_to_count.length - publicationData.tags.length - (publicationData.link_url.length > 0 ? 23 : 0) - matches.length * 23);
        }
    }

    const [showTagsSelection, setShowTagsSelection] = useState(false);
    const displayTagSelection = () => {
        if (currentPlan > 0) {
            setShowTagsSelection(!showTagsSelection);
        }
        else {
            displayUpgradeModal();
        }
    }

    const [showSignatureSelection, setShowSignatureSelection] = useState(false);
    const displaySignature = () => {
        if (currentPlan > 0) {
            setShowSignatureSelection(!showSignatureSelection);
        }
        else {
            displayUpgradeModal();
        }
    }

    const [showEmoji, setShowEmoji] = useState(false);
    const displayEmoji = () => {
        setShowEmoji(!showEmoji);
    }

    const onEmojiClick = (emojiData) => {
        const cursorPosition = textareaRef.current.selectionStart;
        const new_text = publicationData.text.substring(0, cursorPosition) + emojiData.emoji + publicationData.text.substring(cursorPosition);


        setPublicationData({
            ...publicationData,
            text: new_text
        });

        let matches = new_text.match(/\bhttp\S+/gi);

        if (!matches || matches.length === 0) {
            setTwitterCharCount(MAX_TWITTER_COUNT - new_text.length - publicationData.tags.length - (publicationData.link_url.length > 0 ? 23 : 0));
            setTotalCharCount(MAX_CHAR_COUNT - new_text.length - publicationData.tags.length - (publicationData.link_url.length > 0 ? 23 : 0));
        }
        else {
            let string_to_count = new_text;
            matches.forEach(string => {
                string_to_count = string_to_count.replaceAll(string, "");
            })
            setTwitterCharCount(MAX_TWITTER_COUNT - string_to_count.length - publicationData.tags.length - (publicationData.link_url.length > 0 ? 23 : 0) - matches.length * 23);
            setTotalCharCount(MAX_CHAR_COUNT - string_to_count.length - publicationData.tags.length - (publicationData.link_url.length > 0 ? 23 : 0) - matches.length * 23);
        }

    };

    const [showAICaption, setShowAICaption] = useState(false);
    const displayAICaption = () => {
        if (currentPlan > 0) {
            setShowAICaption(!showAICaption);
        }
        else {
            displayUpgradeModal();
        }
    }


    const handleInputCaption = (e) => {
        setPublicationData({
            ...publicationData,
            [e.target.name]: e.target.value
        })
    }

    const [selectedAltTextNetwork, setSelectedAltTextNetwork] = useState('');
    const handleAltText = (network) => {
        if (network === selectedAltTextNetwork) {
            setSelectedAltTextNetwork('');
        }
        else {
            setSelectedAltTextNetwork(network);
        }
    }

    useLayoutEffect(() => {
        textareaRef.current.style.height = "inherit";
        textareaRef.current.style.height = `${Math.max(
            textareaRef.current.scrollHeight + 30,
            MIN_TEXTAREA_HEIGHT
        )}px`;

    }, [publicationData.text]);

    return (
        <>
            <div className={editorMenuItem === 'content' ? 'editor_component active' : 'editor_component'}>
                <div className='editor_title margin-t1'>{t('accounts_selection')}</div>
                <div className='accounts_selection margin-t1'>
                    {publicationData.social_accounts && publicationData.social_accounts.length > 0 ?
                        publicationData.social_accounts.map((account, i) => {
                            return (
                                <div className="account_selection_account" key={i} >
                                    {checkAccountPhoto(account)}
                                </div>
                            )
                        })
                        : ''}

                    <div className='select_account' onClick={displayAccountSelection}>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M22.5 38V25.5H10v-3h12.5V10h3v12.5H38v3H25.5V38Z" />
                        </svg>
                    </div>
                </div>
                <div className='captions margin-t2'>
                    <div className='editor_title'>{t('text_composition')}{socialPlatforms.includes('Youtube') ? t('yt_video_title') : ''}</div>
                    <div className='editor_textarea_block margin-t1'>
                        <textarea ref={textareaRef} className='editor' placeholder={selectedAltTextNetwork ? t(`type_custom_text_${selectedAltTextNetwork}`) : t('type_text_here')} style={{ minHeight: MIN_TEXTAREA_HEIGHT, resize: "none" }} name={selectedAltTextNetwork ? "alt_text_" + selectedAltTextNetwork : "text"} value={selectedAltTextNetwork ? publicationData[`alt_text_${selectedAltTextNetwork}`] : publicationData.text} onChange={e => { handleInputCaption(e); handleCharacterCount(e.target.value); }}></textarea>
                        <div className='editor_tags'>
                            <p className='p-small text-blue'>{publicationData.tags}</p>
                        </div>
                        <div className='editor_caption_icons'>
                            <div className='editor_caption_icon_block'>
                                <TooltipHelp content={t('ai_caption')} direction="right">
                                    <svg viewBox="0 0 48 48" onClick={displayAICaption}>
                                        <path fill="current" d="m9.35 20.45 5.3 2.25q.9-1.8 1.925-3.55Q17.6 17.4 18.75 15.8L14.8 15Zm7.7 4.05 6.65 6.65q2.85-1.3 5.35-2.95 2.5-1.65 4.05-3.2 4.05-4.05 5.95-8.3 1.9-4.25 2.05-9.6-5.35.15-9.6 2.05t-8.3 5.95q-1.55 1.55-3.2 4.05-1.65 2.5-2.95 5.35Zm11.45-4.8q-1-1-1-2.475t1-2.475q1-1 2.475-1t2.475 1q1 1 1 2.475t-1 2.475q-1 1-2.475 1t-2.475-1Zm-.75 19.15 5.45-5.45-.8-3.95q-1.6 1.15-3.35 2.175T25.5 33.55Zm16.3-34.7q.45 6.8-1.7 12.4-2.15 5.6-7.1 10.55l-.1.1-.1.1 1.1 5.5q.15.75-.075 1.45-.225.7-.775 1.25l-8.55 8.6-4.25-9.9-8.5-8.5-9.9-4.25 8.6-8.55q.55-.55 1.25-.775.7-.225 1.45-.075l5.5 1.1q.05-.05.1-.075.05-.025.1-.075 4.95-4.95 10.55-7.125 5.6-2.175 12.4-1.725Zm-36.6 27.6Q9.2 30 11.725 29.975 14.25 29.95 16 31.7q1.75 1.75 1.725 4.275Q17.7 38.5 15.95 40.25q-1.3 1.3-4.025 2.15Q9.2 43.25 3.75 44q.75-5.45 1.575-8.2.825-2.75 2.125-4.05Zm2.1 2.15q-.7.75-1.25 2.35t-.95 4.1q2.5-.4 4.1-.95 1.6-.55 2.35-1.25.95-.85.975-2.125.025-1.275-.875-2.225-.95-.9-2.225-.875-1.275.025-2.125.975Z" />
                                    </svg>
                                </TooltipHelp>
                                <TooltipHelp content={t('emoji_selection')} direction="right">
                                    <svg viewBox="0 0 24 24" onClick={displayEmoji}>
                                        <path fill="current" d="M12,17.5C14.33,17.5 16.3,16.04 17.11,14H6.89C7.69,16.04 9.67,17.5 12,17.5M8.5,11A1.5,1.5 0 0,0 10,9.5A1.5,1.5 0 0,0 8.5,8A1.5,1.5 0 0,0 7,9.5A1.5,1.5 0 0,0 8.5,11M15.5,11A1.5,1.5 0 0,0 17,9.5A1.5,1.5 0 0,0 15.5,8A1.5,1.5 0 0,0 14,9.5A1.5,1.5 0 0,0 15.5,11M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                    </svg>
                                </TooltipHelp>
                                <TooltipHelp content={t('tags_selection')} direction="right">
                                    <svg viewBox="0 0 48 48" onClick={displayTagSelection}>
                                        <path fill="current" d="m12.5 40 2.15-8.5H7l.75-3h7.65l2.25-9H9l.75-3h8.65L20.5 8h2.95l-2.1 8.5h9.05L32.5 8h2.95l-2.1 8.5H41l-.75 3H32.6l-2.25 9H39l-.75 3H29.6L27.5 40h-3l2.15-8.5H17.6L15.5 40Zm5.85-11.5h9.05l2.25-9H20.6Z" />
                                    </svg>
                                </TooltipHelp>
                                <TooltipHelp content={t('signature_selection')} direction="right">
                                    <svg viewBox="0 0 48 48" onClick={displaySignature}>
                                        <path fill="current" d="M6 36v-3h12v3Zm0-10.5v-3h24v3ZM6 15v-3h36v3Z" />
                                    </svg>
                                </TooltipHelp>
                            </div>
                            <div className='editor_caption_icon_block'>
                                {socialPlatforms.includes('Twitter') ?
                                    <div className='editor_twitter_counter'>
                                        <img className="alternative_txt_sm_icon" src={TWicon} alt="Twitter" />
                                        <p className={twitterCharCount > 20 ? 'text-light-grey' : twitterCharCount > 0 ? 'text-yellow' : 'text-red'}>:{twitterCharCount}</p>
                                    </div>
                                    : ''}
                                <div className='editor_twitter_counter'>
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="m12.5 40 2.15-8.5H7l.75-3h7.65l2.25-9H9l.75-3h8.65L20.5 8h2.95l-2.1 8.5h9.05L32.5 8h2.95l-2.1 8.5H41l-.75 3H32.6l-2.25 9H39l-.75 3H29.6L27.5 40h-3l2.15-8.5H17.6L15.5 40Zm5.85-11.5h9.05l2.25-9H20.6Z" />
                                    </svg>
                                    <p className={tagsCount > 5 ? 'text-light-grey' : tagsCount > 0 ? 'text-yellow' : 'text-red'}>:{tagsCount}</p>
                                </div>
                                <p className={totalCharCount > 50 ? 'text-light-grey' : totalCharCount > 0 ? 'text-yellow' : 'text-red'}>{totalCharCount}</p>
                            </div>
                            <div className='editor_caption_alt_icons'>
                                {socialPlatforms.includes('Facebook') ?
                                    <TooltipHelp content={t('alt_text_facebook')} direction="left">
                                        <img className={selectedAltTextNetwork === 'Facebook' ? "alternative_txt_sm_icon selected" : "alternative_txt_sm_icon"} onClick={() => handleAltText('Facebook')} src={FBicon} alt="Facebook" />
                                    </TooltipHelp>
                                    : ''}
                                {socialPlatforms.includes('Instagram') ?
                                    <TooltipHelp content={t('alt_text_instagram')} direction="left">
                                        <img className={selectedAltTextNetwork === 'Instagram' ? "alternative_txt_sm_icon selected" : "alternative_txt_sm_icon"} onClick={() => handleAltText('Instagram')} src={IGicon} alt="Instagram" />
                                    </TooltipHelp>
                                    : ''}
                                {socialPlatforms.includes('Twitter') ?
                                    <TooltipHelp content={t('alt_text_twitter')} direction="left">
                                        <img className={selectedAltTextNetwork === 'Twitter' ? "alternative_txt_sm_icon selected" : "alternative_txt_sm_icon"} onClick={() => handleAltText('Twitter')} src={TWicon} alt="Twitter" />
                                    </TooltipHelp>
                                    : ''}
                                {socialPlatforms.includes('LinkedIn') ?
                                    <TooltipHelp content={t('alt_text_linkedin')} direction="left">
                                        <img className={selectedAltTextNetwork === 'LinkedIn' ? "alternative_txt_sm_icon selected" : "alternative_txt_sm_icon"} onClick={() => handleAltText('LinkedIn')} src={LKicon} alt="LinkedIn" />
                                    </TooltipHelp>
                                    : ''}
                                {socialPlatforms.includes('GMB') ?
                                    <TooltipHelp content={t('alt_text_gmb')} direction="left">
                                        <img className={selectedAltTextNetwork === 'GMB' ? "alternative_txt_sm_icon selected" : "alternative_txt_sm_icon"} onClick={() => handleAltText('GMB')} src={GMBicon} alt="Google My Business" />
                                    </TooltipHelp>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
                {socialPlatforms.includes('Facebook') || socialPlatforms.includes('Twitter') || socialPlatforms.includes('LinkedIn') ?
                    <>
                        <div className='editor_title margin-t2'>{t('link')}</div>
                        <div className='editor_input_block margin-t1'>
                            <input type="text" className="editor" placeholder='https://www.yourlink.com' value={urlToScrap} onChange={(e) => scrapLink(e.target.value)} />
                            {urlLoader ?
                                <div className="lds-ring-loader-mini"><div></div><div></div><div></div><div></div></div>
                                : ''
                            }
                        </div>
                    </>
                    : ''
                }
                <ContentMedia
                    authTokens={authTokens}
                    displayUpgradeModal={displayUpgradeModal}
                    publicationData={publicationData}
                    setPublicationData={setPublicationData}
                    stateModalOptions={stateModalOptions}
                    setStateModalOptions={setStateModalOptions}
                    mediaTransfer={mediaTransfer}
                    bulk={bulk}
                    videoCheckerResults={videoCheckerResults}
                    setVideoCheckerResults={setVideoCheckerResults}
                />


            </div>
            {showAccountsSelection ?
                <>
                    <div className="transparent_background" onClick={displayAccountSelection}></div>
                    <div className="unsplash_picker">
                        <span className="close_modal" onClick={displayAccountSelection}>&times;</span>
                        <AccountsSelection allSMAccounts={allSMAccounts} publicationData={publicationData} setPublicationData={setPublicationData} SMGroups={SMGroups} SMAccounts={SMAccounts} setSMAccounts={setSMAccounts} />
                    </div>
                </>
                : ""
            }
            {showTagsSelection ?
                <>
                    <div className="transparent_background" onClick={displayTagSelection}></div>
                    <div className="unsplash_picker">
                        <span className="close_modal" onClick={displayTagSelection}>&times;</span>
                        <TagsSelection
                            authTokens={authTokens}
                            publicationData={publicationData}
                            setPublicationData={setPublicationData}
                            displayUpgradeModal={displayUpgradeModal}
                            setTwitterCharCount={setTwitterCharCount}
                            setTotalCharCount={setTotalCharCount}
                        />
                    </div>
                </>
                : ""
            }
            {showAICaption ?
                <>
                    <div className="transparent_background" onClick={displayAICaption}></div>
                    <div className="unsplash_picker">
                        <span className="close_modal" onClick={displayAICaption}>&times;</span>
                        <ComposerCoAICaption publicationData={publicationData} setPublicationData={setPublicationData} />
                    </div>
                </>
                : ""
            }
            {showEmoji ?
                <>
                    <div className="transparent_background" onClick={displayEmoji}></div>
                    <div className="unsplash_picker">
                        <span className="close_modal" onClick={displayEmoji}>&times;</span>
                        <Picker onEmojiClick={onEmojiClick} width={350} height={450} />
                    </div>
                </>
                : ""}
            {showSignatureSelection ?
                <>
                    <div className="transparent_background" onClick={displaySignature}></div>
                    <div className="unsplash_picker">
                        <span className="close_modal" onClick={displaySignature}>&times;</span>
                        <Signature authTokens={authTokens} publicationData={publicationData} setPublicationData={setPublicationData} handleCharacterCount={handleCharacterCount} displaySignature={displaySignature} width={350} height={450} />
                    </div>
                </>
                : ""}
        </>
    )
}

export default Content