import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import { useHistory, useLocation } from "react-router-dom";

// import Socials from './ManageComponents/Socials';
import SocialsInline from './ManageComponents/SocialsInline';
import SocialGroups from './ManageComponents/SocialGroups';
import WelcomeModal from './WelcomeModal';

// import Socials from '../dashboard/dashboardComponents/Socials';
// import SocialGroups from '../dashboard/dashboardComponents/SocialGroups';
// import Team from '../dashboard/dashboardComponents/Team';
// import UpgradeCard from '../dashboard/dashboardComponents/UpgradeCard';

import "./tools.css"

const SMAccounts = (props) => {
    const { authTokens } = useAuth();
    const location = useLocation();

    const { t } = useTranslation(['manage']);
    const headers = {
        Authorization: authTokens.token,
    };
    const history = useHistory();

    const currentPlan = Number(authTokens.plan);
    const isAdmin = authTokens.user_admin;

    const planMultiplicator = currentPlan > 0 && !authTokens.cai ? currentPlan : currentPlan > 0 && authTokens.cai ? currentPlan * 2 : 1;
    const current_allowed_accounts = planMultiplicator * 5;

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const displayWelcomeModal = () => {
        setShowWelcomeModal(!showWelcomeModal);
    }

    const goToPlans = () => {
        history.push({ pathname: '/subscription' });
    }

    const [SMAccounts, setSMAccounts] = useState([]);

    const fetchAvailableSocialMedia = async () => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_accounts_no_auth_check", payload, {
                headers: headers,
            })
            .then((response) => {
                setSMAccounts(response.data.sm_accounts);
                if (response.data.sm_accounts.length === 0) {
                    displayWelcomeModal();
                }
            })
            .catch(() => {
            });

        closeStateModal();
    }

    // const singleFBIGConnector = async (response) => {

    //     const all_params = response.split('&');
    //     if (all_params.length > 0) {
    //         const access_token = all_params[0].split('=');

    //         setStateModalOptions({
    //             ...stateModalOptions,
    //             display: true,
    //             loader: true
    //         });

    //         const socialMediaData = {
    //             company_id: authTokens.account_id,
    //             media: "Instagram",
    //             ig_user_token: access_token[1]
    //         }


    //         await axios
    //             .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/social_media/get_ig_tokens", socialMediaData, {
    //                 headers: headers,
    //             })
    //             .then(() => {
    //                 fetchAvailableSocialMedia();
    //             })
    //             .catch(() => {
    //             });

    //         closeStateModal();

    //     }


    //     setStateModalOptions({
    //         ...stateModalOptions,
    //         display: true,
    //         loader: true
    //     });

    //     const socialMediaData = {
    //         company_id: authTokens.account_id,
    //         user_id: response.userID,
    //         user_token: response.accessToken
    //     }

    //     await axios
    //         .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/social_media/get_fb_ig_tokens", socialMediaData, {
    //             headers: headers,
    //         })
    //         .then(() => {
    //             fetchSocialAccounts();
    //         })
    //         .catch(() => {
    //         });
    //     closeStateModal();
    // }

    const connectToInstagram = async (response) => {
        const all_params = response.split('&');
        if (all_params.length > 0) {
            const access_token = all_params[0].split('=');

            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: true
            });

            const socialMediaData = {
                company_id: authTokens.account_id,
                media: "Instagram",
                ig_user_token: access_token[1]
            }


            await axios
                .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/social_media/get_ig_tokens", socialMediaData, {
                    headers: headers,
                })
                .then(() => {
                    fetchAvailableSocialMedia();
                })
                .catch(() => {
                });

            closeStateModal();

        }

    }

    const connectToTikTok = async () => {

        const params = props.match.params.id;



        // if (auth_code) {
        //     setStateModalOptions({
        //         ...stateModalOptions,
        //         display: true,
        //         loader: true
        //     });
        //     let tiktok_payload = { code: auth_code, company_id: authTokens.account_id }

        //     await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/social_media/tiktok_auth", tiktok_payload, {
        //         headers: headers,
        //     })
        //         .then(() => {
        //             fetchSocialAccounts();
        //         })
        //         .catch(() => {
        //         });
        //     closeStateModal();
        // }
    }

    useEffect(() => {
        if (location.hash.length > 0) {
            connectToInstagram(location.hash);
        }
        else if (location.pathname.includes('/tiktok')) {
            connectToTikTok()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.hash])


    useEffect(() => {
        fetchAvailableSocialMedia();
        props.changeNav("Tools", "Tools");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {showWelcomeModal ? <WelcomeModal closeWelcomeModal={displayWelcomeModal} /> : ""}
            <div className='content_row_maxed'>
                <div className="card flex-1">
                    <div className="title">{t('social_accounts')} {isAdmin ? `(${SMAccounts.length}/${authTokens.plan === 0 ? 5 : (authTokens.plan > 0 && !authTokens.cai) ? authTokens.plan * 5 : authTokens.plan * 10})` : ''}</div>
                    {/* <Socials SMAccounts={SMAccounts} setSMAccounts={setSMAccounts} checkUnauthorizedResponse={props.checkUnauthorizedResponse} fetchSocialAccounts={fetchAvailableSocialMedia} headers={headers} goToPlans={goToPlans} isAdmin={isAdmin} /> */}
                    <SocialsInline SMAccounts={SMAccounts} setSMAccounts={setSMAccounts} current_allowed_accounts={current_allowed_accounts} checkUnauthorizedResponse={props.checkUnauthorizedResponse} fetchSocialAccounts={fetchAvailableSocialMedia} headers={headers} goToPlans={goToPlans} isAdmin={isAdmin} />
                </div>
                <div className="card flex-1">
                    <div className="title">{t('account_groups')}</div>
                    <SocialGroups SMAccounts={SMAccounts} headers={headers} />
                </div>
            </div>
        </div>
    )
}

export default SMAccounts