import React from 'react'
import { useTranslation } from "react-i18next";

const CalendarCoLegend = ({ showLegend }) => {
    const { t } = useTranslation(['calendar']);
    return (
        <div className={showLegend ? "cal-legend-overlay visible" : "cal-legend-overlay"}>
            <div className="cal-overlay-legend-item">
                <svg className='published_dot' viewBox="0 0 24 24">
                    <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                {t('cal_legend_published')}
            </div>

            <div className="cal-overlay-legend-item">
                <svg className='failed_dot' viewBox="0 0 24 24">
                    <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                {t('cal_legend_failed')}
            </div>

            <div className="cal-overlay-legend-item">
                <svg className='publishing_dot' viewBox="0 0 24 24">
                    <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                {t('cal_legend_publishing')}
            </div>

            <div className="cal-overlay-legend-item">
                <svg className='unpublished_dot' viewBox="0 0 24 24">
                    <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                {t('cal_legend_unpublished')}
            </div>

            <div className="cal-overlay-legend-item">
                <div className="legend-draft"></div>
                {t('cal_lengend_draft')}
            </div>
        </div>
    )
}

export default CalendarCoLegend