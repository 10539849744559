import React from 'react'
import { useTranslation } from "react-i18next";
import TimezonesSelection from '../../settings/TimezonesSelection';

const CalendarCoTZSelection = ({ changeTimeZone, currentTimeZone }) => {
    const { t } = useTranslation(['calendar']);
    return (
        <div>
            <div className='composer_title'>
                {t('timezone_selection')}
            </div>
            <TimezonesSelection
                customClassName='margin-t1'
                currentTimeZone={currentTimeZone}
                timezoneChanged={changeTimeZone}
                hideFavStar={true}
            />
        </div>
    )
}

export default CalendarCoTZSelection