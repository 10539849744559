import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import { useHistory } from "react-router-dom";

import Team from './ManageComponents/Team';
import UpgradeModal from '../Plans/UpgradeModal';

import "./tools.css"

const TeamManagement = (props) => {
    const { authTokens } = useAuth();

    const { t } = useTranslation(['manage']);
    const headers = {
        Authorization: authTokens.token,
    };
    const history = useHistory();

    const currentPlan = Number(authTokens.plan);
    const isAdmin = authTokens.user_admin;

    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    const goToPlans = () => {
        history.push({ pathname: '/subscription' });
    }

    const [SMAccounts, setSMAccounts] = useState([]);

    const fetchAvailableSocialMedia = async () => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_accounts_no_auth_check", payload, {
                headers: headers,
            })
            .then((response) => {
                setSMAccounts(response.data.sm_accounts);
            })
            .catch(() => {
            });

        closeStateModal();
    }




    useEffect(() => {
        if (authTokens.plan > 0 && authTokens.cai) {
            fetchAvailableSocialMedia();
        }
        else {
            setShowUpgradeModal(true);
        }
        props.changeNav("Tools", "Tools");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {showUpgradeModal ?
                <UpgradeModal upgrade={true} />
                :
                <div className='content_row_maxed'>
                    {isAdmin ?
                        currentPlan > 1 && authTokens.cai ?
                            <div className="card">
                                <div className="title">{t('team')}</div>
                                <Team currentPlan={currentPlan} authTokens={authTokens} isAdmin={isAdmin} headers={headers} />
                            </div>
                            : currentPlan === 1 ?
                                <div className="card">
                                    <div className="title">{t('team')}</div>
                                    <div className="column centered">
                                        <p className="text-grey">{t('upgrade_plan_for_team')}</p>
                                        <div className="action_button margin-t2" onClick={goToPlans}>
                                            <svg viewBox="0 0 48 48">
                                                <path fill="current" d="m11 39.6 5.3-2.15q-.65-1.9-1.125-3.85-.475-1.95-.825-3.95L11 31.9Zm8.3-2.6h9.4q1.1-2.95 1.7-5.875Q31 28.2 31 26q0-5.75-1.65-10.1T24 7.65q-3.7 3.9-5.35 8.25Q17 20.25 17 26q0 2.2.6 5.125T19.3 37ZM24 25.5q-1.45 0-2.475-1.025Q20.5 23.45 20.5 22q0-1.45 1.025-2.475Q22.55 18.5 24 18.5q1.45 0 2.475 1.025Q27.5 20.55 27.5 22q0 1.45-1.025 2.475Q25.45 25.5 24 25.5Zm13 14.1v-7.7l-3.35-2.25q-.35 2-.825 3.95-.475 1.95-1.125 3.85ZM24 3.55q5.1 4.5 7.55 9.975T34 26v.25l4.65 3.15q.65.4 1 1.05t.35 1.4V44l-10-4H18L8 44V31.85q0-.75.35-1.4.35-.65 1-1.05L14 26.25V26q0-7 2.45-12.475T24 3.55Z" />
                                            </svg>
                                            {t('upgrade')}
                                        </div>
                                    </div>
                                </div>
                                : ""
                        : ""
                    }
                </div>
            }
        </div>
    )
}

export default TeamManagement