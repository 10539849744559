import React from 'react'
import moment from 'moment'
import { useTranslation } from "react-i18next";

import "./post_previews.css";
import GMBicon from "../../../images/social_media/gmb.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"


const PreviewGMB = ({ publicationData, calSelectedEvent, selectedPhotos }) => {
    const { t } = useTranslation(['composer']);

    let gmb_check = false;

    let gmb_index;

    if (calSelectedEvent) {
        gmb_index = publicationData.social_accounts.findIndex((account) => account._id.toString() === calSelectedEvent._id.toString());
    }
    else {
        gmb_index = publicationData.social_accounts.findIndex((account) => account.media === "GMB");
    }

    // const gmb_index = publicationData.social_accounts.findIndex((account) => account.media === "GMB");

    if (gmb_index !== -1) {
        gmb_check = true;
    }

    const checkCTA = (cta) => {

        if (publicationData.gmb_topic === "OFFER") {
            return t('gmb_view_offer');
        }

        switch (cta) {
            case "LEARN_MORE":
                return t('gmb_cta_learn_more')
            case "SIGN_UP":
                return t('gmb_cta_sign_up')
            case "SHOP":
                return t('gmb_cta_shop')
            case "ORDER":
                return t('gmb_cta_order')
            case "BOOK":
                return t('gmb_cta_book')
            case "CALL":
                return t('gmb_cta_call')
            default:
                return;
        }
    }

    const checkEventSchedule = (start_date, start_time, end_date, end_time) => {
        console.log(start_date, end_date);

        if (!start_date || !end_date || !start_time || !end_time) return;

        if (start_date !== end_date) {
            return moment(start_date).format("MMM D") + " - " + moment(end_date).format("MMM D")
        }
        else {
            return moment(start_date).format("MMM D") + ", " + start_time.split(":")[0] + ":" + start_time.split(":")[1] + " - " + end_time.split(":")[0] + ":" + end_time.split(":")[1]
        }
    }

    const source_text = publicationData.alt_text_GMB || publicationData.text;

    // const optimizedMediaURL = (url) => {
    //     if (url.includes("tr:")) {
    //         return url;
    //     }
    //     const url_options = "tr:n-ik_ml_thumbnail";
    //     // const url_options = "tr:h-150,pr-true";
    //     let arrayedURL = url.split("/");
    //     arrayedURL.splice(arrayedURL.length - 1, 0, url_options);
    //     const optimized_url = arrayedURL.join('/');
    //     return optimized_url;
    // }


    return (
        <div>
            {gmb_check ?
                <div className={publicationData.draft ? "preview_card preview_card_draft ig_preview" : "preview_card ig_preview"}>
                    <div className='sm-badge-wrapper'>
                        <img src={GMBicon} alt="Google My Business" />
                    </div>
                    <div className='header gmb'>
                        <img className="co-logo" src={publicationData.social_accounts[gmb_index].profile_picture_url} alt="" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <div className="co-name-gmb">{publicationData.social_accounts[gmb_index].social_name}</div>
                        <div className="time">1d</div>
                    </div>
                    {publicationData.bulk && selectedPhotos.length > publicationData.bulk_media_index ?
                        selectedPhotos[publicationData.bulk_media_index].type === "image" ?
                            <div className='gmb_media_container'>
                                <div className='gmb_media_wrapper'>
                                    <img src={selectedPhotos[publicationData.bulk_media_index].file_url} alt="" />
                                </div>
                            </div>
                            : ""
                        : selectedPhotos.length > 0 && selectedPhotos[0].type === "image" ?
                            <div className='gmb_media_container'>
                                <div className='gmb_media_wrapper'>
                                    <img src={selectedPhotos[0].file_url} alt="" />
                                </div>
                            </div>
                            : ""}
                    <div className='gmb_content'>
                        {publicationData.gmb_event_title && publicationData.gmb_event_title.length > 0 ?
                            <div className="gmb_event_wrapper">
                                {publicationData.gmb_topic === "OFFER" ?
                                    <div className='gmb_event_offer'>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="current" d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z" />
                                        </svg>
                                    </div>
                                    : ""}
                                <div className='gmb_event_title'>{publicationData.gmb_event_title}</div>
                                <div className='gmb_event_dates'>{checkEventSchedule(publicationData.gmb_event_start_date, publicationData.gmb_event_start_time, publicationData.gmb_event_end_date, publicationData.gmb_event_end_time)}</div>
                                <div className='gmb_text'>{source_text}</div>
                            </div>
                            :
                            <div className='gmb_text'>{source_text}</div>
                        }

                        {(publicationData.gmb_cta && publicationData.gmb_cta.length > 0) || publicationData.gmb_topic === "OFFER" ?
                            <div className='gmb_cta'>{checkCTA(publicationData.gmb_cta)}</div>
                            : ""}
                    </div>
                </div>
                : ""
            }
        </div>
    )
}

export default PreviewGMB