import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import Tooltip from '../../sidebar/Tooltip';


import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import PNicon from "../../../images/social_media/pinterest.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import TKTKicon from "../../../images/social_media/tiktok-spk.svg";
import YTicon from "../../../images/social_media/youtube.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"

const ThirdPartyAdd = ({ partyData, setPartyData, saveThirdParty, selectedSMAccounts, setSelectedSMAccounts, SMGroups, allSMAccounts, SMAccounts, setSMAccounts }) => {
    const { t } = useTranslation(['third_party']);

    const [selectedGroup, setSelectedGroup] = useState({});

    const selectGroup = (e) => {
        setSelectedGroup(e.target.value);
        let newlyAvailableSMAccounts = [];

        if (!e.target.value) {
            setSMAccounts(allSMAccounts);
        }
        else {
            const filteredGroupData = SMGroups.filter(group => group._id === e.target.value);

            filteredGroupData[0].sm_accounts.map(account => {
                let sm_account = allSMAccounts.filter(acc => acc._id === account);
                if (sm_account[0]) {
                    newlyAvailableSMAccounts.push(sm_account[0]);
                }
                return null;
            })

            setSMAccounts(newlyAvailableSMAccounts);
        }
        setSelectedSMAccounts(newlyAvailableSMAccounts);
    }

    const selectMediaAccount = (account) => {
        let selectedMediaAccount = selectedSMAccounts;

        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === account.social_id);

        if (indexFound !== -1) {
            const filteredMedia = selectedMediaAccount.filter(item => item.social_id !== account.social_id);
            setSelectedSMAccounts(filteredMedia);

        }
        else {
            selectedMediaAccount.push(account);
            setSelectedSMAccounts([...selectedMediaAccount]);
        }
    }

    const checkAccountPhoto = (accountData) => {

        if (!accountData) return;

        const selectedMediaAccount = selectedSMAccounts;
        let selected = false;
        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === accountData.social_id);

        if (indexFound !== -1) {
            selected = true;
        }

        switch (accountData.media) {
            case "Facebook":
                let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                if (accountData.type === "group") {
                    fb_picture_url = "https://graph.facebook.com/v12.0/" + accountData.social_id + "/picture?access_token=" + accountData.access_token;
                }

                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={fb_picture_url} alt={accountData.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={FBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Twitter":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "LinkedIn":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={LKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Instagram":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={IGicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Pinterest":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={PNicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "GMB":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={GMBicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "TikTok":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TKTKicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            case "Youtube":
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={YTicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip content={accountData.social_name} direction="bottom">
                        <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                            <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <img className="account_profile_media" src={TWicon} alt="Media" />
                        </div>
                    </Tooltip>
                )
        }
    }



    const handleInput = (e) => {
        setPartyData({
            ...partyData,
            [e.target.name]: e.target.value
        })
    }


    return (
        <div className='card card_max_width'>
            {/* <p className="text-grey text-italic">{t('third_party_intro')}</p> */}
            <div className='composer_component_thread'>
                <div className="title">
                    {t('add_third_party')}
                </div>
                <div className='column_left margin-t1'>
                    <label>{t('name_third_party')}<sup>*</sup></label>
                    <input type="text" name="third_party_name" placeholder={t('my_client_1')} value={partyData.third_party_name} onChange={e => handleInput(e)} />
                </div>
                <div className='column_left margin-t1'>
                    <label>{t('email')}<sup>*</sup></label>
                    <input type="text" name="third_party_email" placeholder={t('my_client_email')} value={partyData.third_party_email} onChange={e => handleInput(e)} />
                </div>
                <div className="title margin-t2">
                    {t('account_selection')}
                </div>
                <p className="text-grey text-italic">{t('third_party_acc_selection')}</p>
                <div className='column_left'>
                    {SMGroups && SMGroups.length > 0 ?
                        <select className="editor" name="group" onChange={e => selectGroup(e)} value={selectedGroup}>
                            <option value="">{t('all_accounts')}</option>
                            {SMGroups.map((group, i) => {
                                return (
                                    <option key={i} value={group._id}>{group.name}</option>
                                )
                            })}
                        </select>
                        : ""
                    }
                    <div className='row margin-t1'>

                        {SMAccounts.map((account, i) => {
                            return (
                                <div className="account_selection_account" key={i} onClick={e => selectMediaAccount(account)}>
                                    {checkAccountPhoto(account)}
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
            </div>
            <div className="action_button margin-t2" onClick={() => saveThirdParty()}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
                </svg>
                {t('save')}
            </div>
        </div>
    )
}

export default ThirdPartyAdd