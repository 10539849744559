import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";


const SchedulesList = ({ authTokens }) => {

    const { t } = useTranslation(['manage']);
    const headers = {
        Authorization: authTokens.token,
    };

    const [customSchedules, setCustomSchedules] = useState([]);
    const fetchCustomSchedules = async () => {
        const payload = {
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/get_custom_schedule", payload, {
                headers: headers,
            })
            .then((response) => {
                setCustomSchedules(response.data);
            })
            .catch(() => {
            });
    }


    useEffect(() => {
        fetchCustomSchedules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="card flex-1">
            <div className="title">{t('custom_schedules')}</div>
            <ul className='tools_list'>
                {customSchedules && customSchedules.length > 0 ?
                    customSchedules.map((schedule, s) => {
                        return (
                            <li key={s}>
                                <div>
                                    <div className='tools_list_title'>
                                        {schedule.schedule_name}
                                        {/* <svg viewBox="0 0 24 24" className="social_delete_button">
                                                <path fill="current" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                                            </svg> */}
                                    </div>
                                    <div className='margin-t2'>
                                        <div className='row margin-t1'>
                                            <div className={schedule.days.includes(1) ? "day_button selected" : "day_button"}>{t('mon')}</div>
                                            <div className={schedule.days.includes(2) ? "day_button selected" : "day_button"}>{t('tue')}</div>
                                            <div className={schedule.days.includes(3) ? "day_button selected" : "day_button"}>{t('wed')}</div>
                                            <div className={schedule.days.includes(4) ? "day_button selected" : "day_button"}>{t('thu')}</div>
                                            <div className={schedule.days.includes(5) ? "day_button selected" : "day_button"}>{t('fri')}</div>
                                            <div className={schedule.days.includes(6) ? "day_button selected" : "day_button"}>{t('sat')}</div>
                                            <div className={schedule.days.includes(0) ? "day_button selected" : "day_button"}>{t('sun')}</div>
                                        </div>
                                        <div className='row'>
                                            <p>{t('from_time')} {schedule.from}h {t('to_time')} {schedule.to}h (GMT{schedule.tz_offset < 0 ? '-' + schedule.tz_offset / 60 : '+' + schedule.tz_offset / 60})</p>
                                        </div>
                                    </div>

                                </div>
                            </li>
                        )
                    })
                    :
                    <div className='row centered'>
                        <p className='text-grey'>{t('create_schedule')}</p>
                    </div>
                }
            </ul>
        </div>
    )
}

export default SchedulesList