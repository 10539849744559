import React from 'react'
import moment from 'moment'

import CalendarEvent from './CalendarEvent'

import './cal_events.scss';
import './cal_weekly_simplified.css';

const CalWeeklySimplified = ({ currentWeek, prevWeek, nextWeek, goToCurrentWeek, events, selectEvent, newPost }) => {

    const now = moment();

    const renderHeader = () => {
        const headerDateFormat = "DD MMM YYYY";
        const startOfWeek = moment(currentWeek).startOf('week');
        const endOfWeek = moment(currentWeek).endOf('week');

        return (
            <div className="cal-header flex-middle">
                <div className="cal-header-nav">
                    <div className="cal-icon" onClick={() => prevWeek()}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                        </svg>
                    </div>
                    <span>{startOfWeek.format(headerDateFormat)} - {endOfWeek.format(headerDateFormat)}</span>
                    <div className="cal-icon" onClick={() => nextWeek()}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                        </svg>
                    </div>
                </div>
            </div>
        )
    }

    const renderDates = () => {
        const startOfWeek = moment(currentWeek).startOf('week');
        const dateFormat = "ddd DD";
        const days = [];

        for (let i = 0; i < 7; i++) {
            const dayIsToday = now.isSame(startOfWeek, 'day');
            const this_day = moment(startOfWeek);

            days.push(
                <div className={`cal-col-weekly cal-col-start ${dayIsToday ? "cal-today-weekly" : ""}`} key={i}>
                    {startOfWeek.format(dateFormat)}
                    <div className='cal-header-buttons'>
                        {moment().diff(moment(this_day), 'days') <= 0 ?
                            <div className='new-post-button' onClick={() => newPost(this_day)}>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h19.7v3H9v30h30V19.3h3V39q0 1.2-.9 2.1-.9.9-2.1.9Zm7.05-7.85v-3H32v3Zm0-6.35v-3H32v3Zm0-6.35v-3H32v3ZM34.6 17.8v-4.4h-4.4v-3h4.4V6h3v4.4H42v3h-4.4v4.4Z" />
                                </svg>
                            </div>
                            : ''}
                    </div>
                </div>
            );
            startOfWeek.add(1, 'days');
        }
        return <div className="cal-days cal-row">{days}</div>;
    }

    const renderDays = () => {
        const startOfWeek = moment(currentWeek).startOf('week');
        let columns = [];

        for (let d = 0; d < 7; d++) {
            let day_time = moment(startOfWeek);
            day_time.add(d, 'days');

            let day_time_1 = moment(day_time);
            day_time_1.add(1, 'days');

            let eventsOfTheDay = [];
            events.forEach(event => {
                if (moment(event.publication_date).isBetween(day_time, day_time_1, null, '[)')) {
                    eventsOfTheDay.push(event);
                }
            })

            columns.push(
                <div className={`cal-col-weekly-simple`} key={day_time}>
                    {eventsOfTheDay.length > 0 ?
                        eventsOfTheDay.map((eventOfTheDay, v) => {
                            let eventClass = "cal-event-simple"
                            if (eventOfTheDay.draft) {
                                eventClass = "cal-event-simple draft-event";
                            }
                            return (
                                <span key={day_time - v} className={eventClass} ><CalendarEvent event={eventOfTheDay} selectEvent={selectEvent} /></span>
                            )
                        })
                        : ""}
                </div >
            )
        }

        return <div className="cal-body-simple">{columns}</div>;
    }

    return (
        <div>
            <div className="calendar">
                {renderHeader()}
                {renderDates()}
                {renderDays()}
            </div>
        </div>
    )
}

export default CalWeeklySimplified