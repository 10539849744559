import React from 'react'
import { useTranslation } from "react-i18next";

import "./promo_card.css"

const UpgradeCard = ({ goToPlans }) => {
    const { t } = useTranslation(['upgrade']);
    return (
        <div className="promo_card flex-1">
            <div className='title'>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
                </svg>
                {t('try_7_days')}
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
                </svg>
            </div>
            <div className='list'>
                <ul>
                    {/* <li>{t('no_watermark')}</li> */}
                    {/* <li>{t('tags')}</li> */}
                    {/* <li>{t('spread_out_posts')}</li> */}
                    <li>{t('videos')}, Reels, GIFs</li>
                    <li>{t('analytics')}</li>
                    <li>{t('more_publications')}</li>
                    <li>{t('more_accounts')}</li>
                    <li>{t('create_team')}</li>
                    <li>{t('upgrade')}</li>
                    <li>{t('cancel')}</li>
                </ul>
                <div className="row row_hcenter">
                    <div className="green_button margin-t4" onClick={goToPlans}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M4,15V9H12V4.16L19.84,12L12,19.84V15H4Z" />
                        </svg>
                        {t('try_now')}
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M20,9V15H12V19.84L4.16,12L12,4.16V9H20Z" />
                        </svg>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UpgradeCard