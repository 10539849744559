import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import UpgradeCard from '../dashboard/dashboardComponents/UpgradeCard';
import { useHistory } from "react-router-dom";


import "./state_modal.css";

const StateModal = ({ modalOptions, closeStateModal }) => {
    const { status, message, message_details, loader, button_text, button_link, choice, choice_data, callBackFn, dataForCallback, upgradeOption } = modalOptions;

    const history = useHistory();
    const goToPlans = () => {
        history.push({ pathname: '/plans' });
    }

    const [loaded, setLoaded] = useState(false);

    const formatTxt = (message) => {
        const formattedMsg = message.replaceAll("\n", "<br/>");

        return formattedMsg;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 10);
        return () => clearTimeout(timer);
    }, [])

    return (
        <div>
            <div className={loaded ? "modal_background active" : "modal_background"}></div>
            {loader ?
                <div className={loaded ? "modal_wrapper_loader" : "modal_wrapper_loader inactive"}>
                    <div className="modal_content">
                        <div className="lds-ring-loader-white"><div></div><div></div><div></div><div></div></div>
                        {/* <div className='loader-wrapper'> */}
                        {/* <div className="spk-loader"></div> */}
                        {/* <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div> */}
                        {/* <div class="lds-circle"><div> */}
                        {/* <img src={LogoSPK} alt="" /> */}
                        {/* </div></div> */}
                        {/* </div> */}
                    </div>

                </div>
                :
                <div className={loaded ? "modal_wrapper" : "modal_wrapper inactive"}>
                    <span className="close_modal" onClick={closeStateModal}>&times;</span>
                    <div className="modal_content">
                        {upgradeOption ?
                            <UpgradeCard goToPlans={goToPlans} />
                            : ""}
                        {status === "error" ?
                            <svg className="svg-red" viewBox="0 0 24 24">
                                <path fill="current" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
                            </svg>
                            // <img src={ErrorIllustration} alt="Info" className="modal_illustration" />
                            : status === "info" ?
                                <svg className="svg-blue" viewBox="0 0 24 24">
                                    <path fill="current" d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />
                                </svg>
                                // <img src={InfoIllustration} alt="Info" className="modal_illustration" />
                                : status === "alert" ?
                                    <svg className="svg-yellow" viewBox="0 0 24 24">
                                        <path fill="current" d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z" />
                                    </svg>
                                    : status === "sent" ?
                                        <svg className="svg-blue" viewBox="0 0 24 24">
                                            <path fill="current" d="M22 5.5H9C7.9 5.5 7 6.4 7 7.5V16.5C7 17.61 7.9 18.5 9 18.5H22C23.11 18.5 24 17.61 24 16.5V7.5C24 6.4 23.11 5.5 22 5.5M22 16.5H9V9.17L15.5 12.5L22 9.17V16.5M15.5 10.81L9 7.5H22L15.5 10.81M5 16.5C5 16.67 5.03 16.83 5.05 17H1C.448 17 0 16.55 0 16S.448 15 1 15H5V16.5M3 7H5.05C5.03 7.17 5 7.33 5 7.5V9H3C2.45 9 2 8.55 2 8S2.45 7 3 7M1 12C1 11.45 1.45 11 2 11H5V13H2C1.45 13 1 12.55 1 12Z" />
                                        </svg>
                                        : status === "video" ?
                                            <svg className="svg-blue" viewBox="0 0 24 24">
                                                <path fill="current" d="M15,8V16H5V8H15M16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5V7A1,1 0 0,0 16,6Z" />
                                            </svg>
                                            :
                                            <svg className="svg-green" viewBox="0 0 24 24">
                                                <path fill="current" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" />
                                            </svg>
                        }


                        <div className="modal_message margin-b2" dangerouslySetInnerHTML={{ __html: formatTxt(message) }}></div>
                        {message_details && message_details.length > 0 ?
                            message_details.map((msg, m) => {
                                return (
                                    <p className='p-small' key={m}>{msg.message}</p>
                                )
                            })
                            : ""
                        }
                    </div>
                    {button_text && button_link ?
                        <div className="modal_button">
                            <Link to={button_link} >
                                <div className="action_button">
                                    {button_text}
                                </div>
                            </Link>
                        </div>
                        : ""
                    }
                    {choice && callBackFn ?
                        <div className="modal_buttons">
                            <div className={choice_data.button_action_1_style} onClick={() => callBackFn(dataForCallback, true)}>{choice_data.button_action_1_title}</div>
                            <div className="margin-s2"></div>
                            <div className={choice_data.button_action_2_style} onClick={() => callBackFn(dataForCallback, false)}>{choice_data.button_action_2_title}</div>
                        </div>
                        : ""
                    }
                </div>
            }
        </div>
    )
}

export default StateModal
