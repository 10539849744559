import React from 'react'
import { useTranslation } from "react-i18next";

import ThirdParty from "../../../images/onboarding/onboarding_third_party.png";


const OnboardingCoThirdParty = ({ onboardingResponses, handleResponse }) => {
    const { t } = useTranslation(['onboarding_cesam']);
    return (
        <div className="onboarding_content">
            <div className='onboarding_qa'>
                <div className='onboarding_question'>{t('onbq_third_party')}</div>
                <div className='onboarding_sub_question'>{t('onbq_third_party_sub')}</div>
                <div className='onboarding_divider_wrapper'>
                    <div className='onboarding_divider'></div>
                </div>
                <div className='onboarding_response'>
                    <label className="onboarding_radio">
                        <input type="radio" name="third_party" value="true" checked={onboardingResponses.third_party === "true" ? true : false} onChange={e => handleResponse(e)} />
                        {t('yes')}
                    </label>
                    <label className="onboarding_radio">
                        <input type="radio" name="third_party" value="false" checked={onboardingResponses.third_party === "false" ? true : false} onChange={e => handleResponse(e)} />
                        {t('no')}
                    </label>
                </div>
            </div>
            <div className='onboarding_illustration'>
                <img src={ThirdParty} alt="" />
            </div>
        </div>
    )
}

export default OnboardingCoThirdParty