import React, { useState, useEffect } from 'react'
import moment from 'moment'
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import StateModal from '../Loader/StateModal';

import WelcomeModal from '../Manage/WelcomeModal';
import EngageCoMsgList from './EngageComponents/EngageCoMsgList';
import EngageCoMsgAction from './EngageComponents/EngageCoMsgAction';
import UpgradeModal from '../Plans/UpgradeModal';
import SocialsModal from '../Manage/ManageComponents/SocialsModal';

import FBicon from "../../images/social_media/facebook.svg";
import TWicon from "../../images/social_media/twitter.svg";
import LKicon from "../../images/social_media/linkedin.svg";
import IGicon from "../../images/social_media/instagram.svg";
import PNicon from "../../images/social_media/pinterest.svg";
import GMBicon from "../../images/social_media/gmb.svg";
import TKTKicon from "../../images/social_media/tiktok-spk.svg";
import YTicon from "../../images/social_media/youtube.svg";
import SPKNoProfile from "../../images/social_media/SPK-no-profile.png"
import FBGroupIcon from "../../images/social_media/fb_group.png";

import "./engage.css"


// const available_media = ['Instagram', 'Facebook', 'LinkedIn', 'GMB'];

const Engage = (props) => {

    const { t } = useTranslation(['engage']);
    const { authTokens } = useAuth();
    const headers = {
        Authorization: authTokens.token,
    };
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const displayWelcomeModal = () => {
        setShowWelcomeModal(!showWelcomeModal);
    }

    const [selectedAccount, setSelectedAccount] = useState({});

    const changeAccount = (account) => {

        if (authTokens.plan === 0) {
            setShowUpgradeModal(true);
        }
        else {
            setSelectedAccount(account);
            setSelectedMessage({});
        }
    }


    const [selectedMessage, setSelectedMessage] = useState({});
    const [reply, setReply] = useState('');


    const sendReplyToComment = async () => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        const payload = {
            sm_account: selectedMessage.post_data.sm_account,
            comment_id: selectedMessage.comment_data.id,
            message: reply,
            post: selectedMessage.post_data
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/engagement/send_reply_to_comment", payload, {
                headers: headers,
            })
            .then((response) => {

                let message_to_update = selectedMessage;
                let updated_replies = selectedMessage.comment_data.replies_data || [];
                updated_replies.push({
                    id: 'new_message_' + moment().format('HHmmss'),
                    from_id: selectedMessage.post_data.sm_account.social_id,
                    from_handle: selectedMessage.post_data.sm_account.social_name,
                    comment: payload.message
                });
                message_to_update.comment_data.replies_data = updated_replies;
                setSelectedMessage(message_to_update);
                setReply('');
            })
            .catch(() => {
            });

        closeStateModal();
    }


    // const [accountGroups, setAccountGroups] = useState([]);
    // const fetchAccountsGroups = async () => {
    //     setStateModalOptions({
    //         ...stateModalOptions,
    //         display: true,
    //         loader: true
    //     });


    //     // NEW FUNCTION - TEAM COMPLIANT
    //     const payload = {
    //         company_id: authTokens.account_id,
    //         user_id: authTokens.user_id
    //     }

    //     await axios
    //         .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_account_groups", payload, {
    //             headers: headers,
    //         })
    //         .then((response) => {
    //             setAccountGroups(response.data.sm_groups);

    //         })
    //         .catch(() => {
    //         });

    //     closeStateModal();
    // }



    // const [SMAccounts, setSMAccounts] = useState([]);
    // const fetchAvailableSocialMedia = async () => {

    //     setStateModalOptions({
    //         ...stateModalOptions,
    //         display: true,
    //         loader: true
    //     });

    //     // NEW FUNCTION - TEAM COMPLIANT
    //     const payload = {
    //         company_id: authTokens.account_id,
    //         user_id: authTokens.user_id
    //     }

    //     await axios
    //         .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/engagement/get_social_media_accounts_with_msg_notifs", payload, {
    //             headers: headers,
    //         })
    //         .then((response) => {
    //             setSMAccounts(response.data);
    //             if (response.data.length === 0) {
    //                 displayWelcomeModal();
    //             }
    //         })
    //         .catch(() => {
    //         });

    //     closeStateModal();
    // }

    const checkAccountPhoto = (accountData, sm_icon) => {
        switch (accountData.media) {
            case "Facebook":
                let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                if(accountData.fb_group){
                    fb_picture_url=FBGroupIcon
                }
                return (
                    <div className="account_profile_photo_inbox" >
                        <img className="profile_picture_inbox" src={fb_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={FBicon} alt="Media" /> : ""}
                    </div>
                )
            case "Twitter":
                return (
                    <div className="account_profile_photo_inbox" >
                        <img className="profile_picture_inbox" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={TWicon} alt="Media" /> : ""}
                    </div>
                )
            case "LinkedIn":
                return (
                    <div className="account_profile_photo_inbox" >
                        <img className="profile_picture_inbox" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={LKicon} alt="Media" /> : ""}
                    </div>
                )
            case "Instagram":
                return (
                    <div className="account_profile_photo_inbox" >
                        <img className="profile_picture_inbox" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={IGicon} alt="Media" /> : ""}
                    </div>
                )
            case "Pinterest":
                return (
                    <div className="account_profile_photo_inbox" >
                        <img className="profile_picture_inbox" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={PNicon} alt="Media" /> : ""}
                    </div>
                )
            case "GMB":
                return (
                    <div className="account_profile_photo_inbox" >
                        <img className="profile_picture_inbox" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={GMBicon} alt="Media" /> : ""}
                    </div>
                )
            case "TikTok":
                return (
                    <div className="account_profile_photo_inbox" >
                        <img className="profile_picture_inbox" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={TKTKicon} alt="Media" /> : ""}
                    </div>
                )
            case "Youtube":
                return (
                    <div className="account_profile_photo_inbox" >
                        <img className="profile_picture_inbox" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={YTicon} alt="Media" /> : ""}
                    </div>
                )
            default:
                return (
                    <div className="account_profile_photo_inbox" >
                        <img className="profile_picture_inbox" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" />
                        {sm_icon ? <img className="account_profile_media" src={TWicon} alt="Media" /> : ""}
                    </div>
                )
        }
    }


    const [showSocialsModal, setShowSocialsModal] = useState(false);
    const closeSocialsModal = () => {
        setShowSocialsModal(false);
    }

    const [reauthOption, setReauthOption] = useState(false);
    const displayReauthModal = () => {
        setReauthOption(true);
        setShowSocialsModal(true);
    }

    const checkFBIGReauth = () => {
        // CHECK FB IG REAUTH
        let need_reauth_fb_ig = false;
        if (props.newMessagesNotif && props.newMessagesNotif.length > 0) {
            props.newMessagesNotif.forEach(newMsgData => {
                if (newMsgData.account.media === 'Facebook' || newMsgData.account.media === 'Instagram') {
                    if (!newMsgData.account.fb_ig_comments_allowed) {
                        need_reauth_fb_ig = true;
                    }
                }
            })
        }

        if (need_reauth_fb_ig) {
            displayReauthModal();
            return;
        }
    }

    useEffect(() => {
        return () => props.checkMessageNotification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.newMessagesNotif && props.newMessagesNotif.length > 0) {
            checkFBIGReauth();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.newMessagesNotif])

    return (
        <div className='content_row_start'>
            {showSocialsModal ? <SocialsModal closeSocialsModal={closeSocialsModal} reauth_fb_ig={reauthOption} /> : ""}
            {showUpgradeModal ? <UpgradeModal upgrade={true} closeModal={() => setShowUpgradeModal(false)} /> : ''}
            {showWelcomeModal ? <WelcomeModal closeWelcomeModal={displayWelcomeModal} /> : ""}
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            <div className="accounts_column">
                {props.newMessagesNotif && props.newMessagesNotif.length > 0 ?
                    <div className="group_wrapper">
                        {/* <div className={selectedGroup === 'all_accounts' ? 'account_group_header selected' : 'account_group_header'} onClick={() => setSelectedGroup('all_accounts')}>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z" />
                                </svg>
                                {t('all_accounts')}
                            </div> */}
                        <ul className="accounts_list selected">
                            {props.newMessagesNotif.map(newMsgData => {
                                return (
                                    <li className={selectedAccount._id === newMsgData.account._id ? 'selected' : ''} key={`${newMsgData.account._id}`} onClick={() => changeAccount(newMsgData.account)}>
                                        <div className='account_block_inbox'>
                                            {checkAccountPhoto(newMsgData.account, true)}
                                            <div className="account_profile_name">{newMsgData.account.social_name}</div>
                                            {newMsgData.new_message ?
                                                <svg className='engagement_notif_dot' viewBox="0 0 24 24">
                                                    <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                                </svg>
                                                : ''}
                                        </div>
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </div>
                    :
                    <div className="column">
                        <div className="lds-ring-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <p className="text-grey margin-t2">{t('grabbing_comments_few_minutes')}</p>
                    </div>
                }
                {/* {accountGroups && accountGroups.length > 0 ?
                    accountGroups.map(group => {
                        return (
                            <div key={group._id} className="group_wrapper">
                                <div className={selectedGroup === group._id ? 'account_group_header selected' : 'account_group_header'} onClick={() => setSelectedGroup(group._id)}>
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z" />
                                    </svg>
                                    {group.name}
                                </div>
                                <ul className={selectedGroup === group._id ? 'accounts_list selected' : 'accounts_list'}>
                                    {group.sm_accounts && group.sm_accounts.length > 0 ?
                                        group.sm_accounts.map(account => {
                                            return (
                                                available_media.includes(account.media) ?
                                                    <li className={selectedAccount._id === account._id ? 'selected' : ''} key={`${group._id}_${account._id}`} onClick={() => changeAccount(account)}>
                                                        <div className='account_block_inbox'>
                                                            {checkAccountPhoto(account, true)}
                                                            {account.social_name}
                                                        </div>
                                                    </li>
                                                    : ''
                                            )
                                        })
                                        : ''}
                                </ul>
                            </div>
                        )
                    })
                    : ''
                } */}
            </div>

            {
                Object.keys(selectedAccount).length > 0 ?
                    <EngageCoMsgList
                        authTokens={authTokens}
                        selectedAccount={selectedAccount}
                        selectedMessage={selectedMessage}
                        setSelectedMessage={setSelectedMessage}
                        stateModalOptions={stateModalOptions}
                        setStateModalOptions={setStateModalOptions}
                        closeStateModal={closeStateModal}
                    />
                    : ''
            }

            {
                Object.keys(selectedMessage).length > 0 ?
                    <EngageCoMsgAction
                        selectedMessage={selectedMessage}
                        sendReplyToComment={sendReplyToComment}
                        reply={reply}
                        setReply={setReply}
                    />
                    : ''
            }
        </div >
    )
}

export default Engage