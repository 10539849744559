import React from 'react'
import moment from 'moment'
import { useHistory } from "react-router-dom";

import FBicon from "../../images/social_media/facebook.svg";
import TWicon from "../../images/social_media/twitter.svg";
import LKicon from "../../images/social_media/linkedin.svg";
import IGicon from "../../images/social_media/instagram.svg";
import PNicon from "../../images/social_media/pinterest.svg";
import GMBicon from "../../images/social_media/gmb.svg";
import TKicon from "../../images/social_media/tiktok-spk.svg";
import YTicon from "../../images/social_media/youtube.svg";
import SPKNoProfile from "../../images/social_media/SPK-no-profile.png"
import FBGroupIcon from "../../images/social_media/fb_group.png";

const CalendarEvent = ({ event, selectEvent, hideEdit, clickOnDashboard }) => {
    const history = useHistory();

    const goToCalendar = () => {
        history.push({ pathname: '/calendar/monthly' });
    }

    const editPost = (post_id, thread, media, bulk) => {
        if (thread) {
            history.push({
                pathname: '/post/thread/' + post_id,
            });
        }
        else if (media === 'Pinterest') {
            history.push({
                pathname: '/post/pins/' + post_id,
            });
        }
        else if (bulk) {
            history.push({
                pathname: '/post/burst_post/' + post_id,
            });
        }
        else {
            history.push({
                pathname: '/post/new_post/' + post_id,
            });
        }
    }

    const checkPublicationStatus = (event) => {
        const now = moment();
        if (event.published) {
            return (
                <svg className='published_dot' viewBox="0 0 24 24">
                    <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
            )
        }
        else if (moment(event.publication_date) > now) {
            return (
                <svg className='unpublished_dot' viewBox="0 0 24 24">
                    <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
            )
        }
        else if (now.diff(moment(event.publication_date), 'minutes') < 3) {
            return (
                <svg className='publishing_dot' viewBox="0 0 24 24">
                    <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
            )
        }
        else {
            return (
                <svg className='failed_dot' viewBox="0 0 24 24">
                    <path fill="current" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
            )
        }
    }

    const formatText = (text) => {
        let formatted_text = text;

        if (event.sm_account.media === 'Facebook' && event.scheduled_post.alt_text_Facebook && event.scheduled_post.alt_text_Facebook.length > 0) {
            formatted_text = event.scheduled_post.alt_text_Facebook
        }
        else if (event.sm_account.media === 'Instagram' && event.scheduled_post.alt_text_Instagram && event.scheduled_post.alt_text_Instagram.length > 0) {
            formatted_text = event.scheduled_post.alt_text_Instagram
        }
        else if (event.sm_account.media === 'GMB' && event.scheduled_post.alt_text_GMB && event.scheduled_post.alt_text_GMB.length > 0) {
            formatted_text = event.scheduled_post.alt_text_GMB
        }
        else if (event.sm_account.media === 'LinkedIn' && event.scheduled_post.alt_text_LinkedIn && event.scheduled_post.alt_text_LinkedIn.length > 0) {
            formatted_text = event.scheduled_post.alt_text_LinkedIn
        }
        else if (event.sm_account.media === 'Twitter' && event.scheduled_post.alt_text_Twitter && event.scheduled_post.alt_text_Twitter.length > 0) {
            formatted_text = event.scheduled_post.alt_text_Twitter
        }
        else if (event.sm_account.media === 'Twitter' && event.scheduled_post.thread && event.scheduled_post.thread_content && event.scheduled_post.thread_content.length > 0) {
            formatted_text = event.scheduled_post.thread_content[0].text
        }

        if (formatted_text && formatted_text.length > 80) {
            formatted_text = formatted_text.substring(0, 80) + "...";
        }
        return formatted_text;
    }

    const formatFirstComment = (comment) => {
        let formatted_comment = comment;
        if (formatted_comment && formatted_comment.length > 50) {
            formatted_comment = formatted_comment.substring(0, 50) + "...";
        }
        return formatted_comment;
    }

    const checkAccountPhoto = (calendarEvent) => {
        switch (calendarEvent.media) {
            case "Facebook":
                let fb_picture_url = "https://graph.facebook.com/v11.0/" + calendarEvent.social_id + "/picture";
                if(calendarEvent.fb_group){
                    fb_picture_url=FBGroupIcon
                }
                return (
                    <div className="account_profile_photo_calendar" key={calendarEvent._id}>
                        <img className="profile_picture_calendar" src={fb_picture_url} alt={calendarEvent.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media_calendar" src={FBicon} alt="Media" />
                    </div>)
            case "Twitter":
                return (
                    <div className="account_profile_photo_calendar" key={calendarEvent._id}>
                        <img className="profile_picture_calendar" src={calendarEvent.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media_calendar" src={TWicon} alt="Media" />
                    </div>)
            case "LinkedIn":
                return (
                    <div className="account_profile_photo_calendar" key={calendarEvent._id}>
                        <img className="profile_picture_calendar" src={calendarEvent.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media_calendar" src={LKicon} alt="Media" />
                    </div>)
            case "Instagram":
                return (
                    <div className="account_profile_photo_calendar" key={calendarEvent._id}>
                        <img className="profile_picture_calendar" src={calendarEvent.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media_calendar" src={IGicon} alt="Media" />
                    </div>)
            case "Pinterest":
                return (
                    <div className="account_profile_photo_calendar" key={calendarEvent._id}>
                        <img className="profile_picture_calendar" src={calendarEvent.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media_calendar" src={PNicon} alt="Media" />
                    </div>)
            case "GMB":
                return (
                    <div className="account_profile_photo_calendar" key={calendarEvent._id}>
                        <img className="profile_picture_calendar" src={calendarEvent.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media_calendar" src={GMBicon} alt="Media" />
                    </div>)
            case "TikTok":
                return (
                    <div className="account_profile_photo_calendar" key={calendarEvent._id}>
                        <img className="profile_picture_calendar" src={calendarEvent.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media_calendar" src={TKicon} alt="Media" />
                    </div>)
            case "Youtube":
                return (
                    <div className="account_profile_photo_calendar" key={calendarEvent._id}>
                        <img className="profile_picture_calendar" src={calendarEvent.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media_calendar" src={YTicon} alt="Media" />
                    </div>)
            default:
                return (
                    <div className="account_profile_photo_calendar" key={calendarEvent._id}>
                        <img className="profile_picture_calendar" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" />
                        <img className="account_profile_media_calendar" src={FBicon} alt="Media" />
                    </div>)
        }
    }

    const optimizedMediaURL = (url) => {
        if (url.includes("tr:")) {
            return url;
        }
        const url_options = "tr:n-ik_ml_thumbnail";
        // const url_options = "tr:h-150,pr-true";
        let arrayedURL = url.split("/");
        arrayedURL.splice(arrayedURL.length - 1, 0, url_options);
        const optimized_url = arrayedURL.join('/');
        return optimized_url;
    }


    return (
        <div className='cal-event-wrapper'>
            <div className="cal-event-root" onClick={() => { clickOnDashboard ? goToCalendar() : selectEvent(event) }}>
                <div className='row row-100 row_spaced toped'>
                    <div className='row nowrap'>
                        {checkAccountPhoto(event.sm_account)}
                        <p className="p-no-margin p-small p-nowrap text-bold"> {event.sm_account.social_name}</p>
                    </div>
                    <div className='row nowrap'>
                        <p className="p-no-margin p-small">{moment(event.publication_date).format('HH.mm')}</p>
                        {!event.draft ? checkPublicationStatus(event) : ''}
                    </div>
                </div>
                <div className="row">
                    <p className="p-no-margin p-small">{formatText(event.scheduled_post.text)}</p>
                </div>
                {event.scheduled_post.medias.length > 0 ?
                    event.scheduled_post.bulk && event.scheduled_post.medias.length > event.bulk_media_index ?
                        event.scheduled_post.medias[event.bulk_media_index].type === "image" ?
                            <span className="cal-media">
                                <img src={optimizedMediaURL(event.scheduled_post.medias[event.bulk_media_index].file_url)} alt="" />
                            </span>
                            :
                            <span className="cal-media">
                                {event.scheduled_post.medias[event.bulk_media_index].thumbnail_url ?
                                    <img key={event.scheduled_post.medias[event.bulk_media_index]._id} src={event.scheduled_post.medias[event.bulk_media_index].thumbnail_url} alt="" />
                                    :
                                    <video>
                                        <source src={event.scheduled_post.medias[event.bulk_media_index].file_url} />
                                    </video>
                                }
                                <div className='video_icon'>
                                    <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                        <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                    </svg>
                                </div>
                            </span>
                        :
                        event.scheduled_post.medias[0].type === "image" ?
                            <span className="cal-media">
                                <img src={optimizedMediaURL(event.scheduled_post.medias[0].file_url)} alt="" />
                            </span>
                            :
                            <span className="cal-media">
                                {event.scheduled_post.medias[0].thumbnail_url ?
                                    <img key={event.scheduled_post.medias[0]._id} src={event.scheduled_post.medias[0].thumbnail_url} alt="" />
                                    :
                                    <video>
                                        <source src={event.scheduled_post.medias[0].file_url} />
                                    </video>
                                }
                                <div className='video_icon'>
                                    <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                        <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                    </svg>
                                </div>
                            </span>
                    : event.scheduled_post.lk_poll && event.scheduled_post.lk_poll.display && event.scheduled_post.lk_poll.question && event.scheduled_post.lk_poll.choices && event.scheduled_post.lk_poll.choices.length > 1 ?
                        <div className="lk_poll_block cal_block">
                            <div className='lk_poll_question'>{event.scheduled_post.lk_poll.question}</div>
                            {event.scheduled_post.lk_poll.choices.map((choice, c) => {
                                if (choice.length > 0) {
                                    return (
                                        <div key={`choice_${c}`} className='lk_poll_choice_item'>{choice}</div>
                                    )
                                }

                            })}
                        </div>
                        : ''
                }
                {event.scheduled_post.first_comment && event.scheduled_post.first_comment.length > 0 && event.scheduled_post.first_comment_accounts && event.scheduled_post.first_comment_accounts.length > 0  ?
                    <div className="first_comment">
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M12 28h24v-3H12Zm0-6.5h24v-3H12Zm0-6.5h24v-3H12Zm32 29-8-8H7q-1.15 0-2.075-.925Q4 34.15 4 33V7q0-1.15.925-2.075Q5.85 4 7 4h34q1.2 0 2.1.925Q44 5.85 44 7ZM7 7v26h30.25L41 36.75V7H7Zm0 0v29.75V7Z" />
                        </svg>
                        <p className="p-small text-grey">{formatFirstComment(event.scheduled_post.first_comment)}</p>
                    </div>
                    : ''}
            </div>
            {!hideEdit ?
                <div className='row' >
                    <svg className='svg-2 svg-red svg-margin-t1' viewBox="0 0 24 24" onClick={() => editPost(event.scheduled_post._id, event.scheduled_post.thread, event.scheduled_post.medias, event.scheduled_post.bulk)}>
                        <path fill="current" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                    </svg>
                </div>
                : ''}
            {event.draft ?
                <div className='draft_event_accounts'>
                    {event.scheduled_post.social_accounts && event.scheduled_post.social_accounts.length > 0 ?
                        event.scheduled_post.social_accounts.map(account => {
                            return (
                                checkAccountPhoto(account)
                            )
                        })
                        : ''}
                </div>
                : ''}
        </div>
    )
}

export default CalendarEvent
