import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import extractColors from 'extract-colors';
import { ChromePicker } from 'react-color'


const url_logo = process.env.REACT_APP_CAI_URL_LOGO;
const url_logo_from_cloud = process.env.REACT_APP_CAI_URL_LOGO_FROM_CLOUD;

const IKpublicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY;
const IKauthenticationEndpoint = process.env.REACT_APP_SOCIALPIKA_API_URL + '/imagekit/auth';


const handleMediaFile = (file) => {
    return new Promise(function (resolve, reject) {
        let fr = new FileReader();

        fr.onload = function () {
            resolve(fr.result);
        };

        fr.onerror = function () {
            reject(fr);
        };

        fr.readAsDataURL(file);
    });
}

const CAICoBrands = ({ authTokens, headers, menuItem, setMenuItem, templateData, setTemplateData, itemsUnlocked, setItemsUnlocked, stateModalOptions, setStateModalOptions, closeStateModal }) => {
    const { t } = useTranslation(['content_ai']);
    const inputLogoRef = useRef(null);
    const inputLogoBWRef = useRef(null);
    const colorPickerRef = useRef(null);
    const history = useHistory();

    const initNewBrandData = {
        name: '',
        description: '',
        primary_color: '',
        url: '',
        logo: '',
        logo_bw: ''
    }


    const goToPlans = () => {
        history.push({ pathname: '/plans' });
    }

    const [isAllowedToAddBrand, setIsAllowedToAddBrand] = useState(false);

    const [selectedBrandOption, setSelectedBrandOption] = useState('existing');

    const [newBrandData, setNewBrandData] = useState(initNewBrandData);
    const [brands, setBrands] = useState([]);
    const [mediaLoaderW, setMediaLoaderW] = useState(false);
    const [mediaLoaderB, setMediaLoaderB] = useState(false);


    // COLORS

    const [showColorPickerNewBrand, setShowColorPickerNewBrand] = useState(false);
    const [logoColors, setLogoColors] = useState([]);
    const [logoCustomColor, setLogoCustomColor] = useState('');

    const handlePrimaryColor = (color) => {
        setNewBrandData({
            ...newBrandData,
            primary_color: color.hex,
            cta_calc_text_color: (color.red * 0.299 + color.green * 0.587 + color.blue * 0.114) > 150 ? '#000000' : '#FFFFFF'
        })
        setTemplateData({
            ...templateData,
            primary_color: color.hex,
            cta_calc_text_color: (color.red * 0.299 + color.green * 0.587 + color.blue * 0.114) > 150 ? '#000000' : '#FFFFFF'
        })
    }

    const handleCustomPrimaryColor = (color) => {
        setLogoCustomColor(color.hex);
        setNewBrandData({
            ...newBrandData,
            primary_color: color.hex,
            cta_calc_text_color: (color.rgb.r * 0.299 + color.rgb.g * 0.587 + color.rgb.b * 0.114) > 150 ? '#000000' : '#FFFFFF'
        })
        setTemplateData({
            ...templateData,
            primary_color: color.hex,
            cta_calc_text_color: (color.rgb.r * 0.299 + color.rgb.g * 0.587 + color.rgb.b * 0.114) > 150 ? '#000000' : '#FFFFFF'
        })
    }


    const fetchBrands = async () => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        const payload = {
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/content_ai/get_brands", payload, {
                headers: headers,
            })
            .then((response) => {
                setBrands(response.data.brands);
                setIsAllowedToAddBrand(response.data.brand_allowed);
                closeStateModal();
            })
            .catch(() => {
            });
    }



    const addBrand = async () => {
        let authError = false;

        if (!newBrandData.name) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('info_brand_name')
            });
            return
        }

        if (!newBrandData.primary_color) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('info_primary_color')
            });
            return
        }

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        // UPLOAD TO IMAGEKIT

        let logo_url = '';
        let logo_bw_url = '';


        const payload_ik_auth = {
            company_id: authTokens.account_id
        }

        const IKauth = await axios.post(IKauthenticationEndpoint, payload_ik_auth, {
            headers: headers,
        })
            .catch((error) => {
                if (error.response.status === 401) {
                    // props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
            });

        if (authError) return;

        const destination_folder = authTokens.account_id + '/content_ai_logos';

        const imagekit_headers = {
            'Content-Type': 'multipart/form-data'
        }
        const params = new FormData();
        params.append('file', newBrandData.logo)
        params.append('publicKey', IKpublicKey)
        params.append('signature', IKauth.data.signature)
        params.append('expire', IKauth.data.expire)
        params.append('token', IKauth.data.token)
        params.append('fileName', newBrandData.name + '_logo_regular')
        params.append('folder', '/' + destination_folder)

        const ik_upload_url = 'https://upload.imagekit.io/api/v1/files/upload';


        await axios.post(ik_upload_url, params, {
            headers: imagekit_headers
        })
            .then(res => {
                logo_url = res.data.url;
            })
            .catch((err) => {
                console.log(err)
            })

        if (newBrandData.logo_bw) {

            const IKauthDark = await axios.post(IKauthenticationEndpoint, payload_ik_auth, {
                headers: headers,
            })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // props.checkUnauthorizedResponse(error.response.data);
                        authError = true;
                    }
                });

            if (authError) return;

            const params_dark_logo = new FormData();
            params_dark_logo.append('file', newBrandData.logo_bw)
            params_dark_logo.append('publicKey', IKpublicKey)
            params_dark_logo.append('signature', IKauthDark.data.signature)
            params_dark_logo.append('expire', IKauthDark.data.expire)
            params_dark_logo.append('token', IKauthDark.data.token)
            params_dark_logo.append('fileName', newBrandData.name + '_logo_bw')
            params_dark_logo.append('folder', '/' + destination_folder)


            await axios.post(ik_upload_url, params_dark_logo, {
                headers: imagekit_headers
            })
                .then(res => {
                    logo_bw_url = res.data.url;
                })
                .catch((err) => {
                    console.log(err)
                })
        }


        const payload = {
            company_id: authTokens.account_id,
            brand_name: newBrandData.name,
            url: newBrandData.url,
            primary_color: newBrandData.primary_color,
            cta_calc_text_color: newBrandData.cta_calc_text_color,
            logo_url: logo_url,
            logo_bw_url: logo_bw_url
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/content_ai/add_brand", payload, {
                headers: headers,
            })
            .then((response) => {
                const selectedBrand = newBrandData;

                setBrands([...brands, response.data]);
                setNewBrandData(initNewBrandData);
                setTemplateData({
                    ...templateData,
                    brand_name: selectedBrand.name,
                    logo: selectedBrand.logo,
                    primary_color: selectedBrand.primary_color
                })
                setItemsUnlocked({
                    ...itemsUnlocked,
                    visuals: true
                })
                setMenuItem('visuals');
                closeStateModal();

            })
            .catch(() => {
            });



    }


    // IMG HANDLING

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1200,
                1080,
                "PNG",
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    const displayLogoUpload = (logo_type) => {
        if (logo_type === 'logo') {
            inputLogoRef.current.click();
        }
        else if (logo_type === 'logo_bw') {
            inputLogoBWRef.current.click();
        }
    }



    const updateLogoData = async (srcImg, logo_type) => {

        if (logo_type === 'logo') {
            setMediaLoaderW(true);
        }
        else {
            setMediaLoaderB(true);
        }

        const payload = {
            img_base64: srcImg,
            account_id: authTokens.account_id,
            user_id: authTokens.user_id,
            logo_type: logo_type
        }

        await axios
            .post(url_logo, payload)
            .catch((err) => {
                console.log("Error @ logo BSH upload");
                console.log(err);
            });

        if (logo_type === 'logo') {
            extractColors(srcImg)
                .then(data => {
                    if (data.length > 0) {
                        const cta_calc_text_color = (data[0].red * 0.299 + data[0].green * 0.587 + data[0].blue * 0.114) > 150 ? '#000000' : '#FFFFFF';
                        setLogoColors(data);
                        setNewBrandData({
                            ...newBrandData,
                            logo: srcImg,
                            primary_color: data[0].hex,
                            cta_calc_text_color: cta_calc_text_color
                        })
                        setTemplateData({
                            ...templateData,
                            logo: srcImg,
                            primary_color: data[0].hex,
                            cta_calc_text_color: cta_calc_text_color
                        })
                    }
                })
                .catch(err => console.log(err))
        }
        else if (logo_type === 'logo_bw') {
            setNewBrandData({
                ...newBrandData,
                logo_bw: srcImg
            })
            setTemplateData({
                ...templateData,
                logo_bw: srcImg
            })
        }

        setMediaLoaderW(false);
        setMediaLoaderB(false);

    }

    const logoSelected = async (event, logo_type) => {

        let files = event.currentTarget.files;
        let readers = [];

        // Abort if there were no files selected
        if (!files.length) return;

        // Store promises in array  
        readers.push(handleMediaFile(files[0]));
        const filesData = await Promise.all(readers);

        let match = filesData[0].match(/^data:([^/]+)\/([^;]+);/) || [];
        let type = match[1];

        if (type === 'image') {
            const image = await resizeFile(files[0]);
            updateLogoData(image, logo_type);
        }
        else {
            return;
        }

        // if (imagesArray.length > 0) {
        //     setImagesRaw(imagesArray)
        //     displayCropper();
        // }

    };

    // const populateDescription = () => {
    //     setNewBrandData({
    //         ...newBrandData,
    //         description: websiteDescription
    //     })
    // }

    const [dragActiveLogo, setDragActiveLogo] = useState(false);
    const [dragActiveLogoBW, setDragActiveLogoBW] = useState(false);


    const onDragOver = (event, logo_type) => {
        logo_type === 'logo' ? setDragActiveLogo(true) : setDragActiveLogoBW(true);
        event.stopPropagation();
        event.preventDefault();
    }

    const onDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const onDragLeave = (event, logo_type) => {
        logo_type === 'logo' ? setDragActiveLogo(false) : setDragActiveLogoBW(false);

        event.preventDefault();
        event.stopPropagation();
    }

    const onLogoDrop = async (event, logo_type) => {
        setDragActiveLogo(false);
        setDragActiveLogoBW(false);
        event.preventDefault();
        event.stopPropagation();

        let files = event.dataTransfer.files;
        let readers = [];

        // Abort if there were no files selected
        if (!files.length) return;

        // Store promises in array  
        readers.push(handleMediaFile(files[0]));

        const filesData = await Promise.all(readers);
        let match = filesData[0].match(/^data:([^/]+)\/([^;]+);/) || [];
        let type = match[1];
        if (type === 'image') {
            const image = await resizeFile(files[0]);
            updateLogoData(image, logo_type);
        }
        else {
            return;
        }


        // if (imagesArray.length > 0) {
        //     setImagesRaw(imagesArray)
        //     displayCropper();
        // }

    };



    const selectBrand = async (_id) => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        // findIndex
        const brand_index = brands.findIndex(brand => brand._id === _id);

        if (brand_index === -1) {
            closeStateModal();
            return;
        }

        const payload = {
            logo_url: brands[brand_index].logo_url,
            logo_bw_url: brands[brand_index].logo_bw_url,
            account_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        // SEND LOGOS URL TO CAI SERVER


        await axios
            .post(url_logo_from_cloud, payload)
            .then((response) => {
                console.log('ok');
            })
            .catch((err) => {
                // console.log("Error @ CAI server logo");
                console.log(err);
            });

        setTemplateData({
            ...templateData,
            brand_name: brands[brand_index].brand_name,
            logo: brands[brand_index].logo_url,
            logo_bw: brands[brand_index].logo_bw_url,
            primary_color: brands[brand_index].primary_color,
            cta_calc_text_color: brands[brand_index].cta_calc_text_color
        })
        setItemsUnlocked({
            ...itemsUnlocked,
            visuals: true
        })

        setMenuItem('visuals');
        closeStateModal();
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                setShowColorPickerNewBrand(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [colorPickerRef]);

    useEffect(() => {
        fetchBrands();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={menuItem === 'brand' ? 'composer_component active' : 'composer_component'}>
            <div className={selectedBrandOption === 'existing' ? "title_action active" : "title_action"} onClick={() => setSelectedBrandOption('existing')}>
                <svg viewBox="0 0 24 24">
                    <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                </svg>
                {t('brands')} {brands ? '(' + brands.length + '/' + authTokens.brands_allowed + ')' : ''}
            </div>
            <div className={selectedBrandOption === 'existing' ? "brands_table active margin-t1" : "brands_table margin-t1"}>
                <div className='brands_container'>
                    {isAllowedToAddBrand ?
                        <div className='add_new_brand' onClick={() => setSelectedBrandOption('new')}>
                            <svg viewBox="0 0 24 24">
                                <path fill="current" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                            </svg>
                            {t('create_new_brand')}
                        </div>
                        :
                        <div className='add_new_brand' onClick={() => goToPlans()}>
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="m11 39.6 5.3-2.15q-.65-1.9-1.125-3.85-.475-1.95-.825-3.95L11 31.9Zm8.3-2.6h9.4q1.1-2.95 1.7-5.875Q31 28.2 31 26q0-5.75-1.65-10.1T24 7.65q-3.7 3.9-5.35 8.25Q17 20.25 17 26q0 2.2.6 5.125T19.3 37ZM24 25.5q-1.45 0-2.475-1.025Q20.5 23.45 20.5 22q0-1.45 1.025-2.475Q22.55 18.5 24 18.5q1.45 0 2.475 1.025Q27.5 20.55 27.5 22q0 1.45-1.025 2.475Q25.45 25.5 24 25.5Zm13 14.1v-7.7l-3.35-2.25q-.35 2-.825 3.95-.475 1.95-1.125 3.85ZM24 3.55q5.1 4.5 7.55 9.975T34 26v.25l4.65 3.15q.65.4 1 1.05t.35 1.4V44l-10-4H18L8 44V31.85q0-.75.35-1.4.35-.65 1-1.05L14 26.25V26q0-7 2.45-12.475T24 3.55Z" />
                            </svg>
                            {t('upgrade_to_add_brand')}
                        </div>
                    }
                    {(brands && brands.length > 0) ?
                        brands.map(brand => {
                            return (
                                <div className='brand_picker' key={brand._id} onClick={() => selectBrand(brand._id)}>
                                    <img src={brand.logo_url} alt={brand.brand_name} />
                                    {brand.brand_name}
                                </div>
                            )
                        })
                        :
                        ''
                    }
                </div>
            </div>


            <div className={selectedBrandOption === 'new' ? "new_brand active margin-t1" : "new_brand margin-t1"}>
                <div className="title">
                    {t('create_brand')}
                </div>
                <div className='row margin-t2'>
                    <div className='column_left'>
                        <label>{t('brand_name')}<sup>*</sup></label>
                        <input type="text" value={newBrandData.name} placeholder={t('my_brand')} onChange={(e) => setNewBrandData({ ...newBrandData, name: e.target.value })} required />
                    </div>
                    <div className='column_left margin-l4'>
                        <label>{t('brand_website')}</label>
                        <input type="text" placeholder='https://...' onChange={(e) => { setNewBrandData({ ...newBrandData, url: e.target.value }); }} />
                    </div>
                </div>
                <div className='row margin-t2'>
                    <input type="file" ref={inputLogoRef} onChange={(e) => logoSelected(e, 'logo')} className="imagekit_upload" accept=".png" />
                    <input type="file" ref={inputLogoBWRef} onChange={(e) => logoSelected(e, 'logo_bw')} className="imagekit_upload" accept=".png" />
                    <label>{t('logos_upload')} <span className='text-italic'>({t('png_requirement')})</span></label>
                    <div className='row-100 row_around margin-t1'>
                        <div className={dragActiveLogo ? "contentai_logo_upload active" : "contentai_logo_upload"} onClick={() => displayLogoUpload('logo')} onDrop={(e) => onLogoDrop(e, 'logo')} onDragEnter={(e) => onDragEnter(e)} onDragOver={(e) => onDragOver(e, 'logo')} onDragLeave={(e) => onDragLeave(e, 'logo')}>
                            {mediaLoaderW ?
                                <div className="lds-ring-loader">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                : newBrandData.logo ?
                                    <div className="contentai_logo_img">
                                        <img src={newBrandData.logo} alt="" />
                                    </div>
                                    :
                                    <>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="current" d="M12,11A1,1 0 0,1 13,12A1,1 0 0,1 12,13A1,1 0 0,1 11,12A1,1 0 0,1 12,11M4.22,4.22C5.65,2.79 8.75,3.43 12,5.56C15.25,3.43 18.35,2.79 19.78,4.22C21.21,5.65 20.57,8.75 18.44,12C20.57,15.25 21.21,18.35 19.78,19.78C18.35,21.21 15.25,20.57 12,18.44C8.75,20.57 5.65,21.21 4.22,19.78C2.79,18.35 3.43,15.25 5.56,12C3.43,8.75 2.79,5.65 4.22,4.22M15.54,8.46C16.15,9.08 16.71,9.71 17.23,10.34C18.61,8.21 19.11,6.38 18.36,5.64C17.62,4.89 15.79,5.39 13.66,6.77C14.29,7.29 14.92,7.85 15.54,8.46M8.46,15.54C7.85,14.92 7.29,14.29 6.77,13.66C5.39,15.79 4.89,17.62 5.64,18.36C6.38,19.11 8.21,18.61 10.34,17.23C9.71,16.71 9.08,16.15 8.46,15.54M5.64,5.64C4.89,6.38 5.39,8.21 6.77,10.34C7.29,9.71 7.85,9.08 8.46,8.46C9.08,7.85 9.71,7.29 10.34,6.77C8.21,5.39 6.38,4.89 5.64,5.64M9.88,14.12C10.58,14.82 11.3,15.46 12,16.03C12.7,15.46 13.42,14.82 14.12,14.12C14.82,13.42 15.46,12.7 16.03,12C15.46,11.3 14.82,10.58 14.12,9.88C13.42,9.18 12.7,8.54 12,7.97C11.3,8.54 10.58,9.18 9.88,9.88C9.18,10.58 8.54,11.3 7.97,12C8.54,12.7 9.18,13.42 9.88,14.12M18.36,18.36C19.11,17.62 18.61,15.79 17.23,13.66C16.71,14.29 16.15,14.92 15.54,15.54C14.92,16.15 14.29,16.71 13.66,17.23C15.79,18.61 17.62,19.11 18.36,18.36Z" />
                                        </svg>
                                        {t('drop_logo_white_backround')}
                                    </>
                            }
                        </div>
                        {/* <label>{t('brand_description')}</label>
                    <textarea className='contentai_textarea' placeholder={t('brand_desc_placeholder')} value={newBrandData.description} onChange={(e) => { setNewBrandData({ ...newBrandData, description: e.target.value }) }} ></textarea>
                    {websiteDescription ?
                        <div className='red_button margin-t1' onClick={() => populateDescription()}>{t('use_web_descripiotn')}</div>
                        : ""} */}
                        <div className={dragActiveLogoBW ? "contentai_logo_upload dark_bg active" : "contentai_logo_upload dark_bg"} onClick={() => displayLogoUpload('logo_bw')} onDrop={(e) => onLogoDrop(e, 'logo_bw')} onDragEnter={(e) => onDragEnter(e)} onDragOver={(e) => onDragOver(e, 'logo_bw')} onDragLeave={(e) => onDragLeave(e, 'logo_bw')}>
                            {mediaLoaderB ?
                                <div className="lds-ring-loader">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                : newBrandData.logo_bw ?
                                    <div className="contentai_logo_img">
                                        <img src={newBrandData.logo_bw} alt="" />
                                    </div>
                                    :
                                    <>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="current" d="M12,11A1,1 0 0,1 13,12A1,1 0 0,1 12,13A1,1 0 0,1 11,12A1,1 0 0,1 12,11M4.22,4.22C5.65,2.79 8.75,3.43 12,5.56C15.25,3.43 18.35,2.79 19.78,4.22C21.21,5.65 20.57,8.75 18.44,12C20.57,15.25 21.21,18.35 19.78,19.78C18.35,21.21 15.25,20.57 12,18.44C8.75,20.57 5.65,21.21 4.22,19.78C2.79,18.35 3.43,15.25 5.56,12C3.43,8.75 2.79,5.65 4.22,4.22M15.54,8.46C16.15,9.08 16.71,9.71 17.23,10.34C18.61,8.21 19.11,6.38 18.36,5.64C17.62,4.89 15.79,5.39 13.66,6.77C14.29,7.29 14.92,7.85 15.54,8.46M8.46,15.54C7.85,14.92 7.29,14.29 6.77,13.66C5.39,15.79 4.89,17.62 5.64,18.36C6.38,19.11 8.21,18.61 10.34,17.23C9.71,16.71 9.08,16.15 8.46,15.54M5.64,5.64C4.89,6.38 5.39,8.21 6.77,10.34C7.29,9.71 7.85,9.08 8.46,8.46C9.08,7.85 9.71,7.29 10.34,6.77C8.21,5.39 6.38,4.89 5.64,5.64M9.88,14.12C10.58,14.82 11.3,15.46 12,16.03C12.7,15.46 13.42,14.82 14.12,14.12C14.82,13.42 15.46,12.7 16.03,12C15.46,11.3 14.82,10.58 14.12,9.88C13.42,9.18 12.7,8.54 12,7.97C11.3,8.54 10.58,9.18 9.88,9.88C9.18,10.58 8.54,11.3 7.97,12C8.54,12.7 9.18,13.42 9.88,14.12M18.36,18.36C19.11,17.62 18.61,15.79 17.23,13.66C16.71,14.29 16.15,14.92 15.54,15.54C14.92,16.15 14.29,16.71 13.66,17.23C15.79,18.61 17.62,19.11 18.36,18.36Z" />
                                        </svg>
                                        {t('drop_logo_dark_backround')}
                                    </>
                            }
                        </div>
                    </div>
                </div>
                {newBrandData.logo ?
                    <div className='column_left margin-t2'>
                        <label>{t('brand_main_color')}</label>
                        <div className='row relative_holder'>
                            {logoColors && logoColors.length > 0 ?
                                logoColors.map((color, index) => {
                                    if (index < 3) {
                                        return (
                                            <div key={index} className={newBrandData.primary_color === color.hex ? 'primary_color_container selected' : 'primary_color_container'} onClick={() => handlePrimaryColor(color)}>
                                                <div className="primary_color_square" style={{ backgroundColor: color.hex }}></div>
                                                {color.hex}
                                            </div>
                                        )
                                    }
                                })
                                : ''}
                            <div className={logoCustomColor && newBrandData.primary_color === logoCustomColor ? 'primary_color_container selected' : 'primary_color_container'} onClick={() => setShowColorPickerNewBrand(!showColorPickerNewBrand)}>
                                {logoCustomColor ?
                                    <>
                                        <div className="primary_color_square" style={{ backgroundColor: logoCustomColor }}></div>
                                        {logoCustomColor}
                                    </>
                                    : t('pick_color')
                                }
                            </div>

                            {showColorPickerNewBrand ?
                                <div className='color_picker_wrapper' ref={colorPickerRef}>
                                    <ChromePicker color={logoCustomColor || '#FFFFFF'} onChange={(color) => handleCustomPrimaryColor(color)} />
                                </div>
                                : ""}
                        </div>
                    </div>
                    : ''}
                {/* {newBrandData.logo_bw ?
                    <div className="row margin-t2 nowrap">
                        <div className='column_left'>
                            <label>{t('logo_for_dark_bg')}</label>
                            <div className='row row_vcenter margin-t1'>
                                <div className='column_left'>
                                    <div className={newBrandData.dark_logo === 'logo' ? "contentai_logo_img_dark selected" : "contentai_logo_img_dark"} onClick={() => { setNewBrandData({ ...newBrandData, dark_logo: 'logo' }); setTemplateData({ ...templateData, dark_logo: 'logo_bw' }) }}>
                                        <img src={newBrandData.logo} alt="" />
                                    </div>
                                </div>
                                <div className='column_left'>
                                    <div className={newBrandData.dark_logo === 'logo_bw' ? "contentai_logo_img_dark selected" : "contentai_logo_img_dark"} onClick={() => { setNewBrandData({ ...newBrandData, dark_logo: 'logo_bw' }); setTemplateData({ ...templateData, dark_logo: 'logo_bw' }) }}>
                                        <img src={newBrandData.logo_bw} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='column_left margin-l2'>
                            <div className='column_left relative margin-t1'>
                                <label>{t('brand_main_color')}</label>
                                <div className='row relative_holder'>
                                    {logoColors && logoColors.length > 0 ?
                                        logoColors.map((color, index) => {
                                            if (index < 3) {
                                                return (
                                                    <div key={index} className={newBrandData.primary_color === color.hex ? 'primary_color_container selected' : 'primary_color_container'} onClick={() => handlePrimaryColor(color)}>
                                                        <div className="primary_color_square" style={{ backgroundColor: color.hex }}></div>
                                                        {color.hex}
                                                    </div>
                                                )
                                            }
                                        })
                                        : ''}
                                    <div className={logoCustomColor && newBrandData.primary_color === logoCustomColor ? 'primary_color_container selected' : 'primary_color_container'} onClick={() => setShowColorPickerNewBrand(!showColorPickerNewBrand)}>
                                        {logoCustomColor ?
                                            <>
                                                <div className="primary_color_square" style={{ backgroundColor: logoCustomColor }}></div>
                                                {logoCustomColor}
                                            </>
                                            : t('pick_color')
                                        }
                                    </div>

                                    {showColorPickerNewBrand ?
                                        <div className='color_picker_wrapper' ref={colorPickerRef}>
                                            <ChromePicker color={logoCustomColor || '#FFFFFF'} onChange={(color) => handleCustomPrimaryColor(color)} />
                                        </div>
                                        : ""}
                                </div>

                            </div>
                        </div>
                    </div >
                    : ''
                } */}
                {
                    newBrandData.logo ?
                        <div className="row_hcenter row-100 margin-t1">
                            <div className='action_button margin-t2' onClick={() => addBrand()}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z" />
                                </svg>
                                {t('add_brand')}
                            </div>
                        </div>
                        : ''
                }
            </div>
            {/* <div className='column_left'>
                    <div className='row row_vcenter'>
                        <label>Logo</label>
                        <TooltipHelp content={t('logo_tooltip')} direction="right">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                            </svg>
                        </TooltipHelp>
                    </div>
                    <input type="file" ref={inputLogoRef} onChange={logoSelected} className="imagekit_upload" accept=".png" />
                    <div className={dragActive ? "contentai_logo_upload margin-t1 active" : "contentai_logo_upload margin-t1"} onClick={displayLogoUpload} onDrop={(e) => onLogoDrop(e)} onDragEnter={(e) => onDragEnter(e)} onDragOver={(e) => onDragOver(e)} onDragLeave={(e) => onDragLeave(e)}>
                        {newBrandData.logo ?
                            <div className="contentai_logo_img">
                                <img src={newBrandData.logo} alt="" />
                            </div>
                            :
                            <>
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M19.35,10.04C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.04C2.34,8.36 0,10.91 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.04M19,18H6A4,4 0 0,1 2,14C2,11.95 3.53,10.24 5.56,10.03L6.63,9.92L7.13,8.97C8.08,7.14 9.94,6 12,6C14.62,6 16.88,7.86 17.39,10.43L17.69,11.93L19.22,12.04C20.78,12.14 22,13.45 22,15A3,3 0 0,1 19,18M8,13H10.55V16H13.45V13H16L12,9L8,13Z" />
                                </svg>
                                {t('drop_logo')}
                            </>
                        }
                    </div>
                </div> */}

        </div >
    )
}

export default CAICoBrands