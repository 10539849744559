import React, { useState } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import StateModal from '../Loader/StateModal';


import "./plans.css";

const Plans = ({ upgrade, triggerHeaderReload }) => {

    const { t, i18n } = useTranslation(['plans_CESAM']);
    const { setAuthTokens } = useAuth();
    const { authTokens } = useAuth();
    const headers = {
        Authorization: authTokens.token,
    };

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }


    const [planRange, setPlanRange] = useState(authTokens.plan > 0 ? authTokens.plan - 1 : 0);
    const [hideSelection, setHideSelection] = useState(false);

    const [yearlyBilling, setYearlyBilling] = useState(true);

    const handleYearlyBilling = (e) => {
        if (e.target.checked) {
            setYearlyBilling(true);
        }
        else {
            setYearlyBilling(false);
        }
    }

    const [selectedCurrency, setSelectedCurrency] = useState('usd');

    const updateCurrency = (currency) => {
        setSelectedCurrency(currency);

        setAuthTokens({
            ...authTokens,
            currency: currency,
        });
    }

    const checkCurrencySymbol = (currency) => {

        let currencySymbol = "";
        switch (currency) {
            case "eur":
                currencySymbol = "€"
                break;
            case "usd":
                currencySymbol = "$"
                break;
            case "chf":
                currencySymbol = "Fr"
                break;
            case "gbp":
                currencySymbol = "£"
                break;
            default:
                currencySymbol = ""
        }
        return currencySymbol;
    }

    const goToCheckout = async (plan) => {

        const productToBill = {
            currency: selectedCurrency || "usd",
            currencySymbol: checkCurrencySymbol(selectedCurrency || "usd"),
            name: authTokens.initials + " " + authTokens.user_name,
            email: authTokens.user_email,
            company_id: authTokens.account_id,
            billing_frequency_factor: yearlyBilling ? 1 : 0,
            language: i18n.language,
            qty: plan === 'business' || plan === 'agency' ? 1 : Math.floor(Number(planRange) + 1),
            plan: plan
        }

        if (authTokens.plan > 0) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: true,
                message: t('updating_plan')
            });

            if (plan !== 'lifetime') {
                //UPDATE SUBSCRIPTION
                await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/payment/update_subscription_simple_cesam", productToBill, {
                    headers: headers,
                })
                    .then((response) => {
                        setStateModalOptions({
                            ...stateModalOptions,
                            display: true,
                            loader: false,
                            status: "ok",
                            message: t('plan_updated')
                        });
                        setAuthTokens({
                            ...authTokens,
                            cai: response.data.cai,
                            plan: response.data.plan,
                            plan_expiry: response.data.plan_expiry,
                            billing: response.data.billing
                        });
                        setHideSelection(true);
                        triggerHeaderReload();
                    })
                    .catch(error => {
                        console.log(error);
                        setStateModalOptions({
                            ...stateModalOptions,
                            display: true,
                            loader: false,
                            status: "error",
                            message: t('order_error')
                        });
                    });
            }
            else {
                //UPDATE SUBSCRIPTION
                await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/payment/update_subscription_to_lifetime_cesam", productToBill, {
                    headers: headers,
                })
                    .then((response) => {
                        window.location.href = response.data.stripe_url;
                    })
                    .catch(error => {
                        console.log(error);
                        setStateModalOptions({
                            ...stateModalOptions,
                            display: true,
                            loader: false,
                            status: "error",
                            message: t('order_error')
                        });
                    });
            }

        }
        else {
            //STRIPE CHECKOUT
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: true,
                message: t('redirecting_to_stripe')
            });
            //CHECKOUT
            await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/payment/stripe_checkout_simple_free_trial_cesam", productToBill, {
                headers: headers,
            })
                .then((response) => {
                    window.location.href = response.data.stripe_url;
                })
                .catch(error => {
                    closeStateModal();
                });
        }
    }

    return (
        <div>
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            <div className="plans_title">
                <svg viewBox="0 0 48 48">
                    <path fill="current" d="m11 39.6 5.3-2.15q-.65-1.9-1.125-3.85-.475-1.95-.825-3.95L11 31.9Zm8.3-2.6h9.4q1.1-2.95 1.7-5.875Q31 28.2 31 26q0-5.75-1.65-10.1T24 7.65q-3.7 3.9-5.35 8.25Q17 20.25 17 26q0 2.2.6 5.125T19.3 37ZM24 25.5q-1.45 0-2.475-1.025Q20.5 23.45 20.5 22q0-1.45 1.025-2.475Q22.55 18.5 24 18.5q1.45 0 2.475 1.025Q27.5 20.55 27.5 22q0 1.45-1.025 2.475Q25.45 25.5 24 25.5Zm13 14.1v-7.7l-3.35-2.25q-.35 2-.825 3.95-.475 1.95-1.125 3.85ZM24 3.55q5.1 4.5 7.55 9.975T34 26v.25l4.65 3.15q.65.4 1 1.05t.35 1.4V44l-10-4H18L8 44V31.85q0-.75.35-1.4.35-.65 1-1.05L14 26.25V26q0-7 2.45-12.475T24 3.55Z" />
                </svg>
                {upgrade ? t('upgrade') : t('manage_plan')}
            </div>
            {authTokens.plan === 0 && !authTokens.strc ?
                <div className="plans_subtitle">
                    {t('7_days')}<br />{t('card_charged')}<br />{t('upgrade_cancel')}
                </div>
                : ''}
            <div className='plans_options'>
                <div className="row">
                    <div className={selectedCurrency === 'usd' ? 'onbording_currency_button selected' : 'onbording_currency_button'} onClick={() => updateCurrency('usd')}>$</div>
                    <div className={selectedCurrency === 'eur' ? 'onbording_currency_button selected' : 'onbording_currency_button'} onClick={() => updateCurrency('eur')}>€</div>
                    <div className={selectedCurrency === 'gbp' ? 'onbording_currency_button selected' : 'onbording_currency_button'} onClick={() => updateCurrency('gbp')}>£</div>
                    <div className={selectedCurrency === 'chf' ? 'onbording_currency_button selected' : 'onbording_currency_button'} onClick={() => updateCurrency('chf')}>CHF</div>
                </div>
                <div className='row row_vcenter'>
                    <div className="onoffswitch_draft">
                        <input type="checkbox" className="onoffswitch_draft-checkbox" id="onoffSwitchUpcoming" checked={yearlyBilling} onChange={(e) => handleYearlyBilling(e)} />
                        <label className="onoffswitch_draft-label" htmlFor="onoffSwitchUpcoming">
                            <span className="onoffswitch_draft-inner"></span>
                            <span className="onoffswitch_draft-switch"></span>
                        </label>
                    </div>
                    <p className={yearlyBilling ? "column margin-l1" : "column margin-l1"}>{t('yearly_billing')}<span className="discount">{t('discount')}</span></p>
                </div>
            </div>
            <div className='plans_grid'>
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'></div>
                    <div className='plans_grid_data_block plans_grid_name'>BASIC</div>
                    <div className='plans_grid_data_block plans_grid_name'>ORGANIC</div>
                    <div className='plans_grid_data_block plans_grid_name'>GROWTH</div>
                </div>
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'></div>
                    <div className='plans_grid_data_block plans_grid_price'><span className="currency">{checkCurrencySymbol(selectedCurrency)}</span>0</div>
                    <div className='plans_grid_data_block plans_grid_price'><span className="currency">{checkCurrencySymbol(selectedCurrency)}</span>{yearlyBilling ? 9 + Number(planRange) * 10 : Math.floor((9 + Number(planRange) * 10) * 1.2).toFixed()}<span className='currency'>{yearlyBilling ? '' : '.80'}</span></div>
                    <div className='plans_grid_data_block plans_grid_price'><span className="currency">{checkCurrencySymbol(selectedCurrency)}</span>{yearlyBilling ? 9 + Number(Number(planRange) + 1) * 20 : Math.floor((9 + Number(Number(planRange) + 1) * 20) * 1.2).toFixed()}<span className='currency'>{yearlyBilling ? '' : '.80'}</span></div>
                </div>
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'></div>
                    <div className='plans_grid_data_block plans_grid_price'><span className="currency"></span></div>
                    <div className='plans_grid_data_block plans_grid_price'><span className="currency">{t('per_month')}</span></div>
                    <div className='plans_grid_data_block plans_grid_price'><span className="currency">{t('per_month')}</span></div>
                </div>
                {yearlyBilling ?
                    <div className='plans_grid_row'>
                        <div className='plans_grid_description'></div>
                        <div className='plans_grid_data_block plans_grid_price'></div>
                        <div className='plans_grid_data_block plans_grid_price'><p className='p-small text-grey text-italic'>{checkCurrencySymbol(selectedCurrency)} {Number(9 + Number(planRange) * 10) * 12} {t('per_year')}</p></div>
                        <div className='plans_grid_data_block plans_grid_price'><p className='p-small text-grey text-italic'>{checkCurrencySymbol(selectedCurrency)} {Number(9 + Number(Number(planRange) + 1) * 20) * 12} {t('per_year')}</p></div>
                    </div>
                    : ''}
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'></div>
                    <div className='plans_grid_data_block plans_grid_price'></div>
                    <div className='plans_grid_data_slider'>
                        <p>{t('slide_customise')}</p>
                        <div className="slider">
                            👉 <input style={{ width: "100%" }} type="range" min="0" max="8" value={planRange} onChange={e => setPlanRange(e.target.value)} />
                        </div>
                    </div>

                </div>
                {hideSelection ? '' :
                    <div className='plans_grid_row'>
                        <div className='plans_grid_description'></div>
                        <div className='plans_grid_data_block plans_grid_selection'>
                            {authTokens.plan === 0 ?
                                <div className="plans_selected_button">
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                    </svg>
                                    {t('selected')}
                                </div>
                                : <div></div>}
                        </div>
                        <div className='plans_grid_data_block plans_grid_selection'>
                            {authTokens.plan > 0 && !authTokens.cai && authTokens.plan === Math.round(planRange + 1) ?
                                <div className="plans_selected_button">
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                    </svg>
                                    {t('selected')}
                                </div>
                                :
                                <div className="plans_upgrade_button" onClick={() => goToCheckout('organic')}>
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="m11 39.6 5.3-2.15q-.65-1.9-1.125-3.85-.475-1.95-.825-3.95L11 31.9Zm8.3-2.6h9.4q1.1-2.95 1.7-5.875Q31 28.2 31 26q0-5.75-1.65-10.1T24 7.65q-3.7 3.9-5.35 8.25Q17 20.25 17 26q0 2.2.6 5.125T19.3 37ZM24 25.5q-1.45 0-2.475-1.025Q20.5 23.45 20.5 22q0-1.45 1.025-2.475Q22.55 18.5 24 18.5q1.45 0 2.475 1.025Q27.5 20.55 27.5 22q0 1.45-1.025 2.475Q25.45 25.5 24 25.5Zm13 14.1v-7.7l-3.35-2.25q-.35 2-.825 3.95-.475 1.95-1.125 3.85ZM24 3.55q5.1 4.5 7.55 9.975T34 26v.25l4.65 3.15q.65.4 1 1.05t.35 1.4V44l-10-4H18L8 44V31.85q0-.75.35-1.4.35-.65 1-1.05L14 26.25V26q0-7 2.45-12.475T24 3.55Z" />
                                    </svg>
                                    {t('select')}
                                </div>
                            }
                        </div>
                        <div className='plans_grid_data_block plans_grid_selection'>
                            {authTokens.plan > 0 && authTokens.cai && authTokens.plan === Math.round(planRange + 1) ?
                                <div className="plans_selected_button">
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                    </svg>
                                    {t('selected')}
                                </div>
                                :
                                <div className='plans_grid_data_block plans_grid_selection' onClick={() => goToCheckout('growth')}>
                                    <div className="plans_upgrade_button">
                                        <svg viewBox="0 0 48 48">
                                            <path fill="current" d="m11 39.6 5.3-2.15q-.65-1.9-1.125-3.85-.475-1.95-.825-3.95L11 31.9Zm8.3-2.6h9.4q1.1-2.95 1.7-5.875Q31 28.2 31 26q0-5.75-1.65-10.1T24 7.65q-3.7 3.9-5.35 8.25Q17 20.25 17 26q0 2.2.6 5.125T19.3 37ZM24 25.5q-1.45 0-2.475-1.025Q20.5 23.45 20.5 22q0-1.45 1.025-2.475Q22.55 18.5 24 18.5q1.45 0 2.475 1.025Q27.5 20.55 27.5 22q0 1.45-1.025 2.475Q25.45 25.5 24 25.5Zm13 14.1v-7.7l-3.35-2.25q-.35 2-.825 3.95-.475 1.95-1.125 3.85ZM24 3.55q5.1 4.5 7.55 9.975T34 26v.25l4.65 3.15q.65.4 1 1.05t.35 1.4V44l-10-4H18L8 44V31.85q0-.75.35-1.4.35-.65 1-1.05L14 26.25V26q0-7 2.45-12.475T24 3.55Z" />
                                        </svg>
                                        {t('select')}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {authTokens.plan === 0 && !authTokens.strc ?
                    <div className='plans_grid_row grey'>
                        <div className='plans_grid_description'>
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="m11 39.6 5.3-2.15q-.65-1.9-1.125-3.85-.475-1.95-.825-3.95L11 31.9Zm8.3-2.6h9.4q1.1-2.95 1.7-5.875Q31 28.2 31 26q0-5.75-1.65-10.1T24 7.65q-3.7 3.9-5.35 8.25Q17 20.25 17 26q0 2.2.6 5.125T19.3 37ZM24 25.5q-1.45 0-2.475-1.025Q20.5 23.45 20.5 22q0-1.45 1.025-2.475Q22.55 18.5 24 18.5q1.45 0 2.475 1.025Q27.5 20.55 27.5 22q0 1.45-1.025 2.475Q25.45 25.5 24 25.5Zm13 14.1v-7.7l-3.35-2.25q-.35 2-.825 3.95-.475 1.95-1.125 3.85ZM24 3.55q5.1 4.5 7.55 9.975T34 26v.25l4.65 3.15q.65.4 1 1.05t.35 1.4V44l-10-4H18L8 44V31.85q0-.75.35-1.4.35-.65 1-1.05L14 26.25V26q0-7 2.45-12.475T24 3.55Z" />
                            </svg>
                            {t('7_days')}
                        </div>
                        <div className='plans_grid_data_block plans_grid_data'>-</div>
                        <div className='plans_grid_data_block plans_grid_data'>
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                            </svg>
                        </div>
                        <div className='plans_grid_data_block plans_grid_data'>
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                            </svg>
                        </div>
                    </div>
                    : ''}
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 47 47">
                            <g clipPath="url(#clip0_607_591)">
                                <path fill="current" d="M23.5 2.60419C20.8754 2.60419 18.3584 3.64678 16.5025 5.50261C14.6467 7.35844 13.6041 9.87548 13.6041 12.5C13.6041 15.1246 14.6467 17.6416 16.5025 19.4974C18.3584 21.3533 20.8754 22.3959 23.5 22.3959C26.1245 22.3959 28.6415 21.3533 30.4974 19.4974C32.3532 17.6416 33.3958 15.1246 33.3958 12.5C33.3958 9.87548 32.3532 7.35844 30.4974 5.50261C28.6415 3.64678 26.1245 2.60419 23.5 2.60419V2.60419Z" />
                                <path d="M24.8396 15.1229H27.4006L27.8027 12.5213H24.8391V11.0995C24.8391 10.0187 25.1922 9.0604 26.2032 9.0604H27.8277V6.79009C27.5422 6.75155 26.9386 6.66718 25.798 6.66718C23.4162 6.66718 22.0198 7.92499 22.0198 10.7906V12.5213H19.5714V15.1229H22.0198V22.2734C22.5047 22.3463 22.9959 22.3958 23.5001 22.3958C23.9558 22.3958 24.4006 22.3542 24.8396 22.2948V15.1229Z" fill="white" />
                                <path fill="current" d="M44.875 27.4734C44.1859 27.7786 43.4448 27.9823 42.663 28.0786C43.4578 27.6068 44.0692 26.8542 44.3562 25.9651C43.6125 26.401 42.7885 26.7214 41.912 26.8901C41.212 26.1479 40.2135 25.6875 39.1073 25.6875C36.9823 25.6875 35.2599 27.3948 35.2599 29.5C35.2599 29.7979 35.2948 30.088 35.3604 30.3698C32.1635 30.2094 29.3281 28.6896 27.4312 26.3833C27.0979 26.9469 26.9104 27.6068 26.9104 28.3031C26.9104 29.6266 27.588 30.7917 28.6213 31.4781C27.9906 31.4589 27.3969 31.2833 26.8771 31.0021C26.8771 31.0135 26.8771 31.0307 26.8771 31.0469C26.8771 32.8964 28.2036 34.4365 29.962 34.787C29.6406 34.875 29.301 34.924 28.951 34.924C28.7026 34.924 28.4604 34.8958 28.226 34.8536C28.7156 36.3651 30.1359 37.4698 31.8187 37.5026C30.5021 38.5234 28.8437 39.1349 27.0406 39.1349C26.7291 39.1349 26.4245 39.1172 26.1234 39.0807C27.8271 40.1594 29.85 40.7917 32.0213 40.7917C39.0968 40.7917 42.9677 34.9813 42.9677 29.9406C42.9677 29.7755 42.9625 29.6109 42.9547 29.4484C43.7099 28.9151 44.3609 28.2422 44.875 27.4734Z" />
                                <path fill="current" d="M21.875 40.2708C21.875 41.7094 20.7094 42.875 19.2708 42.875H5.72917C4.29115 42.875 3.125 41.7094 3.125 40.2708V26.7292C3.125 25.2906 4.29115 24.125 5.72917 24.125H19.2708C20.7094 24.125 21.875 25.2906 21.875 26.7292V40.2708Z" />
                                <path d="M6.25 30.8958H8.85417V39.75H6.25V30.8958ZM7.54427 29.8542H7.52969C6.7526 29.8542 6.25 29.275 6.25 28.5516C6.25 27.8125 6.76823 27.25 7.55937 27.25C8.35156 27.25 8.83958 27.8125 8.85417 28.5516C8.85417 29.2745 8.35156 29.8542 7.54427 29.8542ZM18.75 39.75H16.1458V35.0109C16.1458 33.8661 15.5078 33.0849 14.4833 33.0849C13.7016 33.0849 13.2786 33.612 13.0734 34.1214C12.9984 34.3036 13.0208 34.8078 13.0208 35.0625V39.75H10.4167V30.8958H13.0208V32.2583C13.3964 31.6771 13.9844 30.8958 15.4885 30.8958C17.3521 30.8958 18.7495 32.0677 18.7495 34.6844L18.75 39.75Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_607_591">
                                    <rect width="47" height="47" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        {t('social_accounts')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>5</div>
                    <div className='plans_grid_data_block plans_grid_data flex-text'>{Math.round(Number(planRange) + 1) * 5}</div>
                    <div className='plans_grid_data_block plans_grid_data flex-text'>{Math.round(Number(planRange) + 1) * 10}</div>
                </div>
                <div className='plans_grid_row grey'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M34.55 44q-3.9 0-6.675-2.775T25.1 34.55q0-3.9 2.775-6.675T34.55 25.1q3.9 0 6.675 2.775T44 34.55q0 3.9-2.775 6.675T34.55 44Zm2.9-4.4 1.4-1.4-3.75-3.75v-5.6h-1.95v6.3ZM9 41.3q-1.25 0-2.125-.875T6 38.3V9.2q0-1.25.775-2.125T8.1 6.2h10.1q.35-1.75 1.725-2.875T23.1 2.2q1.8 0 3.175 1.125Q27.65 4.45 28 6.2h10.1q1.25 0 2.125.875T41.1 9.2v14.75q-.75-.45-1.475-.775-.725-.325-1.525-.575V9.2h-5.3v6.5H14.3V9.2H9v29.1h13.65q.25.75.6 1.475.35.725.85 1.525ZM24 9q.85 0 1.425-.575Q26 7.85 26 7q0-.85-.575-1.425Q24.85 5 24 5q-.85 0-1.425.575Q22 6.15 22 7q0 .85.575 1.425Q23.15 9 24 9Z" />
                        </svg>
                        {t('pub_mo')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>15</div>
                    <div className='plans_grid_data_block plans_grid_data flex-text'>{Math.floor(50 + Number(planRange) * 150)}</div>
                    <div className='plans_grid_data_block plans_grid_data flex-text'>{Math.floor(50 + Number(planRange) * 150)}</div>
                </div>
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M0 36v-2.65q0-1.95 2.1-3.15T7.5 29q.65 0 1.2.025.55.025 1.1.125-.4.85-.6 1.725-.2.875-.2 1.875V36Zm12 0v-3.25q0-3.25 3.325-5.25t8.675-2q5.4 0 8.7 2 3.3 2 3.3 5.25V36Zm27 0v-3.25q0-1-.175-1.875t-.575-1.725q.55-.1 1.1-.125Q39.9 29 40.5 29q3.4 0 5.45 1.2Q48 31.4 48 33.35V36Zm-15-7.5q-4 0-6.5 1.2T15 32.75V33h18v-.3q0-1.8-2.475-3T24 28.5Zm-16.5-1q-1.45 0-2.475-1.025Q4 25.45 4 24q0-1.45 1.025-2.475Q6.05 20.5 7.5 20.5q1.45 0 2.475 1.025Q11 22.55 11 24q0 1.45-1.025 2.475Q8.95 27.5 7.5 27.5Zm33 0q-1.45 0-2.475-1.025Q37 25.45 37 24q0-1.45 1.025-2.475Q39.05 20.5 40.5 20.5q1.45 0 2.475 1.025Q44 22.55 44 24q0 1.45-1.025 2.475Q41.95 27.5 40.5 27.5ZM24 24q-2.5 0-4.25-1.75T18 18q0-2.55 1.75-4.275Q21.5 12 24 12q2.55 0 4.275 1.725Q30 15.45 30 18q0 2.5-1.725 4.25T24 24Zm0-9q-1.25 0-2.125.85T21 18q0 1.25.875 2.125T24 21q1.3 0 2.15-.875Q27 19.25 27 18q0-1.3-.85-2.15Q25.3 15 24 15Zm0 18Zm0-15Z" />
                        </svg>
                        {t('team')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data flex-text'>{Math.floor(Number(planRange) + 1)}</div>
                </div>
                <div className='plans_grid_row grey'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="m9.35 20.45 5.3 2.25q.9-1.8 1.925-3.55Q17.6 17.4 18.75 15.8L14.8 15Zm7.7 4.05 6.65 6.65q2.85-1.3 5.35-2.95 2.5-1.65 4.05-3.2 4.05-4.05 5.95-8.3 1.9-4.25 2.05-9.6-5.35.15-9.6 2.05t-8.3 5.95q-1.55 1.55-3.2 4.05-1.65 2.5-2.95 5.35Zm11.45-4.8q-1-1-1-2.475t1-2.475q1-1 2.475-1t2.475 1q1 1 1 2.475t-1 2.475q-1 1-2.475 1t-2.475-1Zm-.75 19.15 5.45-5.45-.8-3.95q-1.6 1.15-3.35 2.175T25.5 33.55Zm16.3-34.7q.45 6.8-1.7 12.4-2.15 5.6-7.1 10.55l-.1.1-.1.1 1.1 5.5q.15.75-.075 1.45-.225.7-.775 1.25l-8.55 8.6-4.25-9.9-8.5-8.5-9.9-4.25 8.6-8.55q.55-.55 1.25-.775.7-.225 1.45-.075l5.5 1.1q.05-.05.1-.075.05-.025.1-.075 4.95-4.95 10.55-7.125 5.6-2.175 12.4-1.725Zm-36.6 27.6Q9.2 30 11.725 29.975 14.25 29.95 16 31.7q1.75 1.75 1.725 4.275Q17.7 38.5 15.95 40.25q-1.3 1.3-4.025 2.15Q9.2 43.25 3.75 44q.75-5.45 1.575-8.2.825-2.75 2.125-4.05Zm2.1 2.15q-.7.75-1.25 2.35t-.95 4.1q2.5-.4 4.1-.95 1.6-.55 2.35-1.25.95-.85.975-2.125.025-1.275-.875-2.225-.95-.9-2.225-.875-1.275.025-2.125.975Z" />
                        </svg>
                        {t('brands')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>2</div>
                    <div className='plans_grid_data_block plans_grid_data flex-text'>{Math.floor(2 + Number(planRange) * 2)}</div>
                </div>
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="m9.35 20.45 5.3 2.25q.9-1.8 1.925-3.55Q17.6 17.4 18.75 15.8L14.8 15Zm7.7 4.05 6.65 6.65q2.85-1.3 5.35-2.95 2.5-1.65 4.05-3.2 4.05-4.05 5.95-8.3 1.9-4.25 2.05-9.6-5.35.15-9.6 2.05t-8.3 5.95q-1.55 1.55-3.2 4.05-1.65 2.5-2.95 5.35Zm11.45-4.8q-1-1-1-2.475t1-2.475q1-1 2.475-1t2.475 1q1 1 1 2.475t-1 2.475q-1 1-2.475 1t-2.475-1Zm-.75 19.15 5.45-5.45-.8-3.95q-1.6 1.15-3.35 2.175T25.5 33.55Zm16.3-34.7q.45 6.8-1.7 12.4-2.15 5.6-7.1 10.55l-.1.1-.1.1 1.1 5.5q.15.75-.075 1.45-.225.7-.775 1.25l-8.55 8.6-4.25-9.9-8.5-8.5-9.9-4.25 8.6-8.55q.55-.55 1.25-.775.7-.225 1.45-.075l5.5 1.1q.05-.05.1-.075.05-.025.1-.075 4.95-4.95 10.55-7.125 5.6-2.175 12.4-1.725Zm-36.6 27.6Q9.2 30 11.725 29.975 14.25 29.95 16 31.7q1.75 1.75 1.725 4.275Q17.7 38.5 15.95 40.25q-1.3 1.3-4.025 2.15Q9.2 43.25 3.75 44q.75-5.45 1.575-8.2.825-2.75 2.125-4.05Zm2.1 2.15q-.7.75-1.25 2.35t-.95 4.1q2.5-.4 4.1-.95 1.6-.55 2.35-1.25.95-.85.975-2.125.025-1.275-.875-2.225-.95-.9-2.225-.875-1.275.025-2.125.975Z" />
                        </svg>
                        {t('ai_credits_mo')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>2</div>
                    <div className='plans_grid_data_block plans_grid_data flex-text'>{Number(planRange) === 0 ? 10 : Math.floor(Number(planRange) * 25)}</div>
                </div>
                <div className='plans_grid_row grey'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M23.85 35.7q.8 0 1.35-.55t.55-1.35q0-.8-.55-1.35t-1.35-.55q-.8 0-1.35.55t-.55 1.35q0 .8.55 1.35t1.35.55Zm-1.65-7.4h2.85q0-1.55.5-2.525.5-.975 1.75-2.225 1.65-1.65 2.3-2.925.65-1.275.65-2.675 0-2.65-1.7-4.2-1.7-1.55-4.6-1.55-2.55 0-4.375 1.225Q17.75 14.65 16.9 16.8l2.65 1.1q.7-1.4 1.8-2.125 1.1-.725 2.6-.725 1.6 0 2.525.8.925.8.925 2.2 0 1-.55 1.925T25 22.15q-1.85 1.75-2.325 3-.475 1.25-.475 3.15ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z" />
                        </svg>
                        {t('custom_features')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M23.85 35.7q.8 0 1.35-.55t.55-1.35q0-.8-.55-1.35t-1.35-.55q-.8 0-1.35.55t-.55 1.35q0 .8.55 1.35t1.35.55Zm-1.65-7.4h2.85q0-1.55.5-2.525.5-.975 1.75-2.225 1.65-1.65 2.3-2.925.65-1.275.65-2.675 0-2.65-1.7-4.2-1.7-1.55-4.6-1.55-2.55 0-4.375 1.225Q17.75 14.65 16.9 16.8l2.65 1.1q.7-1.4 1.8-2.125 1.1-.725 2.6-.725 1.6 0 2.525.8.925.8.925 2.2 0 1-.55 1.925T25 22.15q-1.85 1.75-2.325 3-.475 1.25-.475 3.15ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z" />
                        </svg>
                        {t('priority_support')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row grey'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h26q1.2 0 2.1.9.9.9.9 2.1v10.75l8-8v20.5l-8-8V37q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h26V11H7v26Zm0 0V11v26Z" />
                        </svg>
                        {t('video_posts')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="m9.35 20.45 5.3 2.25q.9-1.8 1.925-3.55Q17.6 17.4 18.75 15.8L14.8 15Zm7.7 4.05 6.65 6.65q2.85-1.3 5.35-2.95 2.5-1.65 4.05-3.2 4.05-4.05 5.95-8.3 1.9-4.25 2.05-9.6-5.35.15-9.6 2.05t-8.3 5.95q-1.55 1.55-3.2 4.05-1.65 2.5-2.95 5.35Zm11.45-4.8q-1-1-1-2.475t1-2.475q1-1 2.475-1t2.475 1q1 1 1 2.475t-1 2.475q-1 1-2.475 1t-2.475-1Zm-.75 19.15 5.45-5.45-.8-3.95q-1.6 1.15-3.35 2.175T25.5 33.55Zm16.3-34.7q.45 6.8-1.7 12.4-2.15 5.6-7.1 10.55l-.1.1-.1.1 1.1 5.5q.15.75-.075 1.45-.225.7-.775 1.25l-8.55 8.6-4.25-9.9-8.5-8.5-9.9-4.25 8.6-8.55q.55-.55 1.25-.775.7-.225 1.45-.075l5.5 1.1q.05-.05.1-.075.05-.025.1-.075 4.95-4.95 10.55-7.125 5.6-2.175 12.4-1.725Zm-36.6 27.6Q9.2 30 11.725 29.975 14.25 29.95 16 31.7q1.75 1.75 1.725 4.275Q17.7 38.5 15.95 40.25q-1.3 1.3-4.025 2.15Q9.2 43.25 3.75 44q.75-5.45 1.575-8.2.825-2.75 2.125-4.05Zm2.1 2.15q-.7.75-1.25 2.35t-.95 4.1q2.5-.4 4.1-.95 1.6-.55 2.35-1.25.95-.85.975-2.125.025-1.275-.875-2.225-.95-.9-2.225-.875-1.275.025-2.125.975Z" />
                        </svg>
                        {t('ai_captions')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row grey'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="m9.35 20.45 5.3 2.25q.9-1.8 1.925-3.55Q17.6 17.4 18.75 15.8L14.8 15Zm7.7 4.05 6.65 6.65q2.85-1.3 5.35-2.95 2.5-1.65 4.05-3.2 4.05-4.05 5.95-8.3 1.9-4.25 2.05-9.6-5.35.15-9.6 2.05t-8.3 5.95q-1.55 1.55-3.2 4.05-1.65 2.5-2.95 5.35Zm11.45-4.8q-1-1-1-2.475t1-2.475q1-1 2.475-1t2.475 1q1 1 1 2.475t-1 2.475q-1 1-2.475 1t-2.475-1Zm-.75 19.15 5.45-5.45-.8-3.95q-1.6 1.15-3.35 2.175T25.5 33.55Zm16.3-34.7q.45 6.8-1.7 12.4-2.15 5.6-7.1 10.55l-.1.1-.1.1 1.1 5.5q.15.75-.075 1.45-.225.7-.775 1.25l-8.55 8.6-4.25-9.9-8.5-8.5-9.9-4.25 8.6-8.55q.55-.55 1.25-.775.7-.225 1.45-.075l5.5 1.1q.05-.05.1-.075.05-.025.1-.075 4.95-4.95 10.55-7.125 5.6-2.175 12.4-1.725Zm-36.6 27.6Q9.2 30 11.725 29.975 14.25 29.95 16 31.7q1.75 1.75 1.725 4.275Q17.7 38.5 15.95 40.25q-1.3 1.3-4.025 2.15Q9.2 43.25 3.75 44q.75-5.45 1.575-8.2.825-2.75 2.125-4.05Zm2.1 2.15q-.7.75-1.25 2.35t-.95 4.1q2.5-.4 4.1-.95 1.6-.55 2.35-1.25.95-.85.975-2.125.025-1.275-.875-2.225-.95-.9-2.225-.875-1.275.025-2.125.975Z" />
                        </svg>
                        {t('ai_posts_ads')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M12,15C12.81,15 13.5,14.7 14.11,14.11C14.7,13.5 15,12.81 15,12C15,11.19 14.7,10.5 14.11,9.89C13.5,9.3 12.81,9 12,9C11.19,9 10.5,9.3 9.89,9.89C9.3,10.5 9,11.19 9,12C9,12.81 9.3,13.5 9.89,14.11C10.5,14.7 11.19,15 12,15M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12V13.45C22,14.45 21.65,15.3 21,16C20.3,16.67 19.5,17 18.5,17C17.3,17 16.31,16.5 15.56,15.5C14.56,16.5 13.38,17 12,17C10.63,17 9.45,16.5 8.46,15.54C7.5,14.55 7,13.38 7,12C7,10.63 7.5,9.45 8.46,8.46C9.45,7.5 10.63,7 12,7C13.38,7 14.55,7.5 15.54,8.46C16.5,9.45 17,10.63 17,12V13.45C17,13.86 17.16,14.22 17.46,14.53C17.76,14.84 18.11,15 18.5,15C18.92,15 19.27,14.84 19.57,14.53C19.87,14.22 20,13.86 20,13.45V12C20,9.81 19.23,7.93 17.65,6.35C16.07,4.77 14.19,4 12,4C9.81,4 7.93,4.77 6.35,6.35C4.77,7.93 4,9.81 4,12C4,14.19 4.77,16.07 6.35,17.65C7.93,19.23 9.81,20 12,20H17V22H12C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z" />
                        </svg>
                        {t('tags')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row grey'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="m12.5 40 2.15-8.5H7l.75-3h7.65l2.25-9H9l.75-3h8.65L20.5 8h2.95l-2.1 8.5h9.05L32.5 8h2.95l-2.1 8.5H41l-.75 3H32.6l-2.25 9H39l-.75 3H29.6L27.5 40h-3l2.15-8.5H17.6L15.5 40Zm5.85-11.5h9.05l2.25-9H20.6Z" />
                        </svg>
                        {t('hastags_group')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M24 42v-3.55l10.8-10.8 3.55 3.55L27.55 42ZM6 31.5v-3h15v3Zm34.5-2.45-3.55-3.55 1.45-1.45q.4-.4 1.05-.4t1.05.4l1.45 1.45q.4.4.4 1.05t-.4 1.05ZM6 23.25v-3h23.5v3ZM6 15v-3h23.5v3Z" />
                        </svg>
                        {t('custom_captions')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row grey'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="m12.5 40 2.15-8.5H7l.75-3h7.65l2.25-9H9l.75-3h8.65L20.5 8h2.95l-2.1 8.5h9.05L32.5 8h2.95l-2.1 8.5H41l-.75 3H32.6l-2.25 9H39l-.75 3H29.6L27.5 40h-3l2.15-8.5H17.6L15.5 40Zm5.85-11.5h9.05l2.25-9H20.6Z" />
                        </svg>
                        {t('first_comment')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M6 36v-3h12v3Zm0-10.5v-3h24v3ZM6 15v-3h36v3Z" />
                        </svg>
                        {t('caption_signatures')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row grey'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="m37.55 18.2-7.75-7.75 3.5-3.5q.85-.85 2.1-.85t2.1.85l3.55 3.55q.85.85.85 2.1t-.85 2.1ZM6 42v-7.8l9.7-9.7L4.65 13.4l8.7-8.8L24.5 15.75l5.3-5.3 7.75 7.75-5.3 5.3L43.4 34.65l-8.75 8.65-11.1-11.05L13.8 42Zm11.85-19.65 4.5-4.5-3.65-3.65-2.4 2.4-2.1-2.1 2.4-2.4-3.2-3.2-4.5 4.5Zm16.7 16.75 4.5-4.5-3.2-3.2-2.4 2.4-2.1-2.1 2.4-2.4-3.65-3.65-4.5 4.5ZM9 39h3.5l20.75-20.75-3.5-3.5L9 35.5Z" />
                        </svg>
                        {t('drafts')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M10 40V28h5v12Zm12.5 0V18h5v22ZM35 40V8h5v32Z" />
                        </svg>
                        {t('analytics')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row grey'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M6 22.5V6h16.5v16.5ZM6 42V25.5h16.5V42Zm19.5-19.5V6H42v16.5Zm0 19.5V25.5H42V42ZM9 19.5h10.5V9H9Zm19.5 0H39V9H28.5Zm0 19.5H39V28.5H28.5ZM9 39h10.5V28.5H9Zm19.5-19.5Zm0 9Zm-9 0Zm0-9Z" />
                        </svg>
                        {t('feeds')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M9 44q-1.2 0-2.1-.9Q6 42.2 6 41V10q0-1.2.9-2.1Q7.8 7 9 7h3.25V4h3.25v3h17V4h3.25v3H39q1.2 0 2.1.9.9.9.9 2.1v31q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V19.5H9V41Zm0-24.5h30V10H9Zm0 0V10v6.5ZM24 28q-.85 0-1.425-.575Q22 26.85 22 26q0-.85.575-1.425Q23.15 24 24 24q.85 0 1.425.575Q26 25.15 26 26q0 .85-.575 1.425Q24.85 28 24 28Zm-8 0q-.85 0-1.425-.575Q14 26.85 14 26q0-.85.575-1.425Q15.15 24 16 24q.85 0 1.425.575Q18 25.15 18 26q0 .85-.575 1.425Q16.85 28 16 28Zm16 0q-.85 0-1.425-.575Q30 26.85 30 26q0-.85.575-1.425Q31.15 24 32 24q.85 0 1.425.575Q34 25.15 34 26q0 .85-.575 1.425Q32.85 28 32 28Zm-8 8q-.85 0-1.425-.575Q22 34.85 22 34q0-.85.575-1.425Q23.15 32 24 32q.85 0 1.425.575Q26 33.15 26 34q0 .85-.575 1.425Q24.85 36 24 36Zm-8 0q-.85 0-1.425-.575Q14 34.85 14 34q0-.85.575-1.425Q15.15 32 16 32q.85 0 1.425.575Q18 33.15 18 34q0 .85-.575 1.425Q16.85 36 16 36Zm16 0q-.85 0-1.425-.575Q30 34.85 30 34q0-.85.575-1.425Q31.15 32 32 32q.85 0 1.425.575Q34 33.15 34 34q0 .85-.575 1.425Q32.85 36 32 36Z" />
                        </svg>
                        {t('cal_view')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row grey'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="m12 42-7.65-7.65L12 26.7l2.45 2.45L11 32.6h26l-3.45-3.45L36 26.7l7.65 7.65L36 42l-2.45-2.45L37 36.1H11l3.45 3.45ZM4.95 21.25v-.65q0-1.05.575-1.925T7.1 17.35q1.25-.55 2.575-.825Q11 16.25 12.45 16.25t2.775.275q1.325.275 2.575.825 1 .45 1.575 1.325t.575 1.925v.65Zm23.1 0v-.65q0-1.05.575-1.925T30.2 17.35q1.25-.55 2.575-.825 1.325-.275 2.775-.275t2.775.275q1.325.275 2.575.825 1 .45 1.575 1.325t.575 1.925v.65Zm-15.6-7.75q-1.55 0-2.65-1.1-1.1-1.1-1.1-2.65 0-1.55 1.1-2.65Q10.9 6 12.45 6 14 6 15.1 7.1q1.1 1.1 1.1 2.65 0 1.55-1.1 2.65-1.1 1.1-2.65 1.1Zm23.1 0q-1.55 0-2.65-1.1-1.1-1.1-1.1-2.65 0-1.55 1.1-2.65Q34 6 35.55 6q1.55 0 2.65 1.1 1.1 1.1 1.1 2.65 0 1.55-1.1 2.65-1.1 1.1-2.65 1.1Z" />
                        </svg>
                        {t('cal_sharing')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>-</div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row '>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M11 36q-1.2 0-2.1-.9Q8 34.2 8 33V7q0-1.2.9-2.1Q9.8 4 11 4h12.25l3 3H43q1.2 0 2.1.9.9.9.9 2.1v23q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h32V10H25l-3-3H11v26Zm29.5 9H5q-1.2 0-2.1-.9Q2 40.2 2 39V10h3v29h35.5ZM16.7 27.45h20.6l-6.6-8.8-5.5 7.3-3.95-4.3ZM11 33V7v26Z" />
                        </svg>
                        {t('library')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                        </svg>
                    </div>
                </div>
                <div className='plans_grid_row grey'>
                    <div className='plans_grid_description'>
                        <svg viewBox="0 0 48 48">
                            <path fill="current" d="M11 36q-1.2 0-2.1-.9Q8 34.2 8 33V7q0-1.2.9-2.1Q9.8 4 11 4h12.25l3 3H43q1.2 0 2.1.9.9.9.9 2.1v23q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h32V10H25l-3-3H11v26Zm29.5 9H5q-1.2 0-2.1-.9Q2 40.2 2 39V10h3v29h35.5ZM16.7 27.45h20.6l-6.6-8.8-5.5 7.3-3.95-4.3ZM11 33V7v26Z" />
                        </svg>
                        {t('media_post')}
                    </div>
                    <div className='plans_grid_data_block plans_grid_data'>4</div>
                    <div className='plans_grid_data_block plans_grid_data'>10</div>
                    <div className='plans_grid_data_block plans_grid_data'>10</div>
                </div>
            </div>
        </div>
    )
}

export default Plans