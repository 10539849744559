import React from 'react'
import IG_icons from "../../../images/ig_icons.png";

import "./bulk_post_preview.css";

const BulkPostPreviewBlank = ({ publicationData, selectedPhotos, linkPreview }) => {


    let display_check = true;

    publicationData.social_accounts.length > 0 ? display_check = false : display_check = true;

    let fb_formatted_link = '';
    if (publicationData.link_url) {
        const link_str = publicationData.link_url;
        if (link_str.length > 120) {
            fb_formatted_link = " " + link_str.substring(0, 120) + "... ";
        }
        else {
            fb_formatted_link = " " + link_str + " "
        }
    }

    const hashtagsFormat = (hashtag) => {
        const formattedText = '<span class="tags">' + hashtag + '</span>';
        return formattedText
    }

    const formatText = (text) => {
        const regex = /#\w*/g;
        return text.replace(regex, hashtagsFormat);
    }


    return (
        <div>
            {display_check ?
                <div className="bulk-card">
                    <div className="header">
                        <div className="co-logo-mockup skeleton-box"></div>
                        <div className="co-name-mockup skeleton-box"></div>
                        <div className="time-mockup skeleton-box"></div>
                    </div>
                    {selectedPhotos.length > 0 ?
                        <div className="bulk-media-container">
                            {selectedPhotos[0].type === "image" ?
                                selectedPhotos.slice(0, 4).map((photoData, i) => {
                                    return (
                                        <div className='fb-media-wrapper' key={'image_' + i}>
                                            {i === 3 && selectedPhotos.length > 4 ? <div className='fb-media-wrapper-overlay'>+{(selectedPhotos.length - 4)}</div> : ""}
                                            <img key={i} src={photoData.file_url} alt="" />
                                        </div>
                                    )
                                })
                                :
                                <div className="fb-media-wrapper">
                                    {selectedPhotos[0].thumbnail_url ?
                                        <img key={selectedPhotos[0]._id} src={selectedPhotos[0].thumbnail_url} alt="" />
                                        :
                                        <video key={selectedPhotos[0]._id}>
                                            <source src={selectedPhotos[0].file_url} />
                                        </video>
                                    }
                                    {/* <div className="video-overlay">
                                        <svg viewBox="0 0 24 24">
                                            <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                        </svg>
                                    </div> */}
                                    <div className='video_icon'>
                                        <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                            <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                        </svg>
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div className='img-mockup skeleton-box'></div>
                    }
                    <img className="bulk_ig_icons" src={IG_icons} alt="" />
                    <span className="ig_likes">323 likes</span>

                    <div className="content">
                        {publicationData.text.length === 0 && publicationData.link_url.length === 0 && publicationData.tags.length === 0 ?
                            <div className='text-mockup skeleton-box'></div>
                            :
                            <>
                                <div className="text">
                                    <div dangerouslySetInnerHTML={{ __html: formatText(publicationData.text) }}></div>
                                </div>
                                <div className="tags">{publicationData.link_url ? fb_formatted_link : ""}</div>
                                <div className="tags">{publicationData.tags}</div>
                            </>
                        }
                    </div>
                    {/* <div className="social">
                        <div className="social-content"></div>
                        <div className="social-buttons">
                            <span><i className="fa fa-thumbs-up"></i>Like</span>
                            <span><i className="fa fa-comment"></i>Comment</span>
                            <span><i className="fa fa-share"></i>Share</span></div>
                    </div> */}
                </div>
                : ""
            }

        </div >
    )
}

export default BulkPostPreviewBlank