import React from 'react'
import { Redirect } from "react-router-dom";

const NotFound = () => {

    const goBackToSafety = () => {
        <Redirect to="/signin" />
    }

    return (
        <div className="not_found">
            <div className="logo" onClick={goBackToSafety}>
                <svg viewBox="0 0 86 76">
                    <path d="M27.9595 44.6999C29.8916 44.6999 31.458 43.1263 31.458 41.1851C31.458 39.244 29.8916 37.6704 27.9595 37.6704C26.0273 37.6704 24.461 39.244 24.461 41.1851C24.461 43.1263 26.0273 44.6999 27.9595 44.6999Z" fill="current" />
                    <path d="M58.0973 44.6999C60.0295 44.6999 61.5958 43.1263 61.5958 41.1851C61.5958 39.244 60.0295 37.6704 58.0973 37.6704C56.1651 37.6704 54.5988 39.244 54.5988 41.1851C54.5988 43.1263 56.1651 44.6999 58.0973 44.6999Z" fill="current" />
                    <path d="M34.8799 52.9338C34.3914 52.4413 33.5985 52.4413 33.11 52.9338C32.6206 53.4263 32.6206 54.2212 33.11 54.7137C35.7588 57.3756 39.2805 58.8401 43.0275 58.8401C46.772 58.8401 50.2945 57.3756 52.9433 54.7137C53.4352 54.222 53.4352 53.4272 52.945 52.9338C52.4566 52.4413 51.6636 52.4413 51.1734 52.9338C49.2926 54.8217 46.8846 55.9742 44.2797 56.255V51.5791C45.2171 51.116 45.8638 50.1474 45.8638 49.0225C45.8638 47.4509 44.5927 46.1721 43.0275 46.1721C41.4589 46.1721 40.1895 47.45 40.1895 49.0225C40.1895 50.1474 40.8362 51.116 41.7736 51.5791V56.255C39.1713 55.9734 36.7598 54.8208 34.8799 52.9338Z" fill="current" />
                    <path d="M43.0284 0.00163269C19.264 0.00163269 0 19.3552 0 43.2302C0 56.0968 5.60204 67.6442 14.4815 75.5628V52.043C14.4815 45.2441 16.8414 39.0026 20.7767 34.0873V17.8251C20.7767 13.8991 23.945 10.717 27.8528 10.717C31.7598 10.717 34.928 13.8991 34.928 17.8251V24.5444C37.496 23.7815 40.2127 23.3659 43.0275 23.3659C45.8406 23.3659 48.559 23.7824 51.1253 24.5444V17.8251C51.1253 13.8991 54.2935 10.717 58.2022 10.717C62.1101 10.717 65.2766 13.8991 65.2766 17.8251H65.274V34.0864C69.2111 39.0026 71.5709 45.2441 71.5709 52.043V75.5662C80.453 67.6477 86.0568 56.0994 86.0568 43.2302C86.0568 19.3552 66.7928 0.00163269 43.0284 0.00163269Z" fill="current" />
                </svg>
            </div>
            OOPS PAGE NOT FOUND
        </div>
    )
}

export default NotFound
