import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import axios from "axios";


const Signature = ({ authTokens, publicationData, setPublicationData, handleCharacterCount, displaySignature }) => {
    const { t } = useTranslation(['composer']);

    const headers = {
        Authorization: authTokens.token,
    };

    const [signatures, setSignatures] = useState([]);
    const [selectedSignature, setSelectedSignature] = useState({});
    const [signatureData, setSignatureData] = useState('');
    const [newSignature, setNewSignature] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const handleSignature = (input_text) => {
        setSignatureData(input_text);

        const initial_text = publicationData.text;
        const signature_index = initial_text.indexOf("\n\n___\n");

        let new_text = publicationData.text;

        if (signature_index !== -1) {
            new_text = initial_text.substring(0, signature_index);
        }

        new_text += "\n\n___\n" + input_text;

        setPublicationData({
            ...publicationData,
            text: new_text
        });

        handleCharacterCount(new_text);
    }


    const selectSignature = (e) => {
        if (!e.target.value) {
            setSelectedSignature({})

            return;
        }

        const filteredSignatures = signatures.filter(sign => sign._id === e.target.value);
        setSelectedSignature(filteredSignatures[0]);

        handleSignature(filteredSignatures[0].signature)
    }

    const saveNewSignature = async () => {

        if (newSignature.length === 0 || signatureData.length === 0) return;

        setIsSaving(true);

        const payload = {
            company_id: authTokens.account_id,
            name: newSignature,
            signature: signatureData
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/save_signature", payload, {
                headers: headers,
            })
            .then(() => {
                setIsSaving(false);
                displaySignature();
            })
            .catch(() => {
                setIsSaving(false);
            });
    }

    const fetchSignatures = async () => {
        const payload = {
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/get_signatures", payload, {
                headers: headers,
            })
            .then((response) => {
                setSignatures(response.data);
            })
            .catch(() => {
            });
    }

    useEffect(() => {
        fetchSignatures();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className='composer_title'>
                {t('signatures_title')}
            </div>
            {signatures && signatures.length > 0 ?
                <div className="row margin-t1">
                    <select className='editor' name="tagsGroups" onChange={e => selectSignature(e)} value={selectedSignature._id}>
                        <option value="" selected={!selectedSignature._id ? true : false}>{t('select_signature')}</option>
                        {signatures.map((signature, i) => {
                            return (
                                <option key={i} value={signature._id}>{signature.name}</option>
                            )
                        })}
                    </select>
                </div>
                : ""}
            <div className='column_left margin-t1'>
                <div className='form_row_textarea_caption_ai'>
                    <textarea className='caption_ai' placeholder={signatures.length > 0 ? t('select_or_type_signature') : t('type_new_signature')} value={signatureData} onChange={e => handleSignature(e.target.value)} ></textarea>
                </div>
            </div>
            <div className="form_row margin-t1">
                <label>
                    {t('save_this_signature')}
                </label>
                <div className='editor_input_block margin-t1'>
                    <input type="text" className="editor" placeholder={t('signature_name')} value={newSignature} onChange={e => setNewSignature(e.target.value)} />
                    {isSaving ?
                        <div className="lds-ring-loader-mini"><div></div><div></div><div></div><div></div></div>
                        :
                        <svg viewBox="0 0 24 24" onClick={() => saveNewSignature()}>
                            <path fill="current" d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                        </svg>
                    }

                </div>
            </div>
        </div>
    )
}

export default Signature