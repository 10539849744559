import React, { useState } from 'react'

import "./tooltip_help.css"

const TooltipHelp = (props) => {

    const [active, setActive] = useState(false);

    const showTip = () => {
        setActive(true);
    }

    const hideTip = () => {
        setActive(false);
    }

    return (
        <div className="tooltip_help_wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
            {props.children}
            {active && (
                <div className={`tooltip_help_tip ${props.direction || "right"}`} >
                    {props.content}
                </div>
            )}
        </div>
    )
}

export default TooltipHelp
