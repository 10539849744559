import React, { useRef, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import TooltipHelp from "../../settings/TooltipHelp";

import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import TKTKicon from "../../../images/social_media/tiktok-spk.svg";
import PNicon from "../../../images/social_media/pinterest.svg";
import YTicon from "../../../images/social_media/youtube.svg";

const ContentMediaVideoChecker = ({
  publicationData,
  pinterest,
  thread,
  videoCheckerResults,
  setVideoCheckerResults,
}) => {
  const { t } = useTranslation(["media_checker"]);

  const videoLoaderRef = useRef(null);

  const get_gcd = (media_width, media_height) => {
    if (typeof media_width !== "number" || typeof media_height !== "number")
      return false;
    media_width = Math.abs(media_width);
    media_height = Math.abs(media_height);
    while (media_height) {
      var t = media_height;
      media_height = media_width % media_height;
      media_width = t;
    }
    return media_width;
  };

  // const handleLoadedMetadata = () => {
  //     const video = videoLoaderRef.current;
  //     if (!video) return;

  //     setVideoCheckerResults({
  //         ...videoCheckerResults,
  //         video_duration: video.duration,
  //         video_width: video.videoWidth,
  //         video_height: video.videoHeight
  //     })
  // };

  const formatVideoDimensions = (videoWidth, videoHeight) => {
    if (!videoWidth) {
      return "-";
    }
    const gcd = get_gcd(videoWidth, videoHeight);
    const ratio = videoWidth / gcd + "/" + videoHeight / gcd;

    return ratio;
  };

  // const formatVideoDuration = (time_sec) => {
  //     if (!time_sec) {
  //         return "-";
  //     }
  //     const minutes = Math.floor(time_sec / 60);
  //     const seconds = time_sec - minutes * 60;

  //     return minutes + "m" + seconds.toFixed(0) + "s";
  // }

  const checkVideoDetails = async () => {
    const video = videoLoaderRef.current;
    if (!video || !video.videoWidth) {
      // console.log("non video");
      return;
    }
    // console.log("check video data");

    let videoCheck = {
      facebook: {
        regular: {
          error: "",
          dimensions: true,
          ratio: true,
          duration: true,
          format: true,
        },
        reel: {
          error: "",
          dimensions: true,
          ratio: true,
          duration: true,
          format: true,
        },
      },
      instagram: {
        regular: {
          error: "",
          dimensions: true,
          ratio: true,
          duration: true,
          format: true,
          bitrate: true,
          file_size: true,
        },
        reel: {
          error: "",
          dimensions: true,
          ratio: true,
          duration: true,
          format: true,
          bitrate: true,
        },
      },
      linkedin: {
        error: "",
        dimensions: true,
        ratio: true,
        duration: true,
        format: true,
        file_size: true,
      },
      twitter: {
        error: "",
        dimensions: true,
        ratio: true,
        duration: true,
        format: true,
      },
      pinterest: {
        error: "",
        dimensions: true,
        ratio: true,
        duration: true,
        format: true,
      },
      tiktok: {
        error: "",
        dimensions: true,
        ratio: true,
        duration: true,
        format: true,
        file_size: true,
      },
      youtube: {
        error: "",
        dimensions: true,
        ratio: true,
        duration: true,
      },
    };

    const width = video.videoWidth;
    const height = video.videoHeight;
    const duration = video.duration;
    const ratio = formatVideoDimensions(width, height);
    const ratio_dec = Number(width / height);

    let bitrate = 0;
    let file_size = 0;
    let error_loading = false;
    if (videoCheckerResults.video_size) {
      file_size = videoCheckerResults.video_size;
      // console.log(videoCheckerResults.video_size);
      // console.log(duration);
      bitrate = (videoCheckerResults.video_size / duration) * 8;
    } else {
      const headers = {
        "Cache-Control": "no-cache",
      };
      const dl_file_size = await axios
        .head(publicationData.medias[0].file_url, { headers })
        .catch((error) => {
          //   console.log("ERROR FROM S3");
          console.log(error);
          error_loading = true;
        });
      //   console.log("S3 returns:");
      //   console.log(dl_file_size.headers);
      //   console.log("video headers");
      if (!error_loading) {
        // console.log("headers ok");
        file_size = dl_file_size.headers["content-length"] / 1000;
        bitrate = (file_size / duration) * 8;
      }
    }

    if (error_loading) {
      // console.log("Error @ video loading. Returning.")
      setVideoCheckerResults({
        ...videoCheckerResults,
        is_loading: false,
        analysis_failed: true,
      });

      return;
    }

    // //////////////////// FILESIZE CHECKS

    // FILESIZE CHECK IG REGULAR
    if (file_size > 100000) {
      videoCheck.instagram.regular.file_size = false;
      videoCheck.instagram.regular.error = t("file_size_above_100");
    }

    if (file_size > 200000) {
      videoCheck.linkedin.file_size = false;
      videoCheck.linkedin.error = t("file_size_above_200");
    }

    if (file_size < 750) {
      videoCheck.linkedin.file_size = false;
      videoCheck.linkedin.error = t("file_size_below_75k");
    }

    if (file_size > 50000) {
      videoCheck.tiktok.file_size = false;
      videoCheck.tiktok.error = t("file_size_above_50");
    }

    // //////////////////// BITRATE CHECKS

    // BITRATE CHECK IG REEL & REGULAR
    if (bitrate > 25000) {
      videoCheck.instagram.reel.bitrate = false;
      videoCheck.instagram.regular.bitrate = false;
      videoCheck.instagram.regular.error = t("bitrate_above_25");
      videoCheck.instagram.reel.error = t("bitrate_above_25");
    }

    // //////////////////// RATIO CHECKS

    // RATIO CHECK FB REEL
    if (ratio_dec < Number(9 / 16)) {
      videoCheck.facebook.reel.ratio = false;
      videoCheck.facebook.reel.error = t("ratio_below_9_16");
    }

    // RATIO CHECK IG POST
    if (ratio_dec < Number(4 / 5)) {
      videoCheck.instagram.regular.ratio = false;
      videoCheck.instagram.regular.error = t("ratio_below_4_5");
    }
    if (ratio_dec > Number(16 / 9)) {
      videoCheck.instagram.regular.ratio = false;
      videoCheck.instagram.regular.error = t("ratio_above_16_9");
    }

    // RATIO CHECK IG REEL
    if (ratio_dec < 0.01) {
      videoCheck.instagram.reel.ratio = false;
      videoCheck.instagram.reel.error = t("ratio_below_001");
    }
    if (ratio_dec > 10) {
      videoCheck.instagram.reel.ratio = false;
      videoCheck.instagram.reel.error = t("ratio_above_10");
    }

    // RATIO CHECK LK
    if (ratio_dec < Number(1 / 2.4)) {
      videoCheck.linkedin.ratio = false;
      videoCheck.linkedin.error = t("ratio_below_lk");
    }
    if (ratio_dec > 2.4) {
      videoCheck.linkedin.ratio = false;
      videoCheck.linkedin.error = t("ratio_above_lk");
    }

    // RATIO CHECK TW
    if (ratio_dec < Number(1 / 3)) {
      videoCheck.twitter.ratio = false;
      videoCheck.twitter.error = t("ratio_below_tw");
    }
    if (ratio_dec > 3) {
      videoCheck.twitter.ratio = false;
      videoCheck.twitter.error = t("ratio_above_tw");
    }

    // RATIO CHECK PN
    if (ratio_dec < Number(9 / 16)) {
      videoCheck.pinterest.ratio = false;
      videoCheck.pinterest.error = t("ratio_below_9_16");
    }
    if (ratio_dec > 1) {
      videoCheck.pinterest.ratio = false;
      videoCheck.pinterest.error = t("ratio_above_1");
    }

    // RATIO CHECK PN
    if (ratio !== "9/16") {
      videoCheck.tiktok.ratio = false;
      videoCheck.tiktok.error = t("ratio_different_9_16");
    }

    // RATIO CHECK YT
    if (ratio !== "9/16" && ratio_dec !== 1) {
      videoCheck.youtube.ratio = false;
      videoCheck.youtube.error = t("ratio_different_9_16_or_1");
    }

    // //////////////////// DURATIONS CHECKS

    if (duration < 3) {
      videoCheck.instagram.regular.duration = false;
      videoCheck.instagram.regular.error = t("duration_below_3s");
      videoCheck.instagram.reel.duration = false;
      videoCheck.instagram.reel.error = t("duration_below_3s");

      videoCheck.linkedin.duration = false;
      videoCheck.linkedin.error = t("duration_below_3s");

      videoCheck.tiktok.duration = false;
      videoCheck.tiktok.error = t("duration_below_3s");
    }

    if (duration < 4) {
      videoCheck.facebook.reel.duration = false;
      videoCheck.facebook.reel.error = t("duration_below_4s");

      videoCheck.pinterest.duration = false;
      videoCheck.pinterest.error = t("duration_below_4s");
    }

    if (duration < 0.5) {
      videoCheck.twitter.duration = false;
      videoCheck.twitter.error = t("duration_below_05s");
    }

    if (duration >= 60) {
      videoCheck.facebook.reel.duration = false;
      videoCheck.facebook.reel.error = t("duration_above_60s");
      videoCheck.instagram.regular.duration = false;
      videoCheck.instagram.regular.error = t("duration_above_60s");

      videoCheck.tiktok.duration = false;
      videoCheck.tiktok.error = t("duration_above_60s");

      videoCheck.youtube.duration = false;
      videoCheck.youtube.error = t("duration_above_60s");
    }

    if (duration > 140) {
      videoCheck.twitter.duration = false;
      videoCheck.twitter.error = t("duration_above_140s");
    }

    if (duration > 900) {
      videoCheck.instagram.reel.duration = false;
      videoCheck.instagram.reel.error = t("duration_above_15mn");

      videoCheck.pinterest.duration = false;
      videoCheck.pinterest.error = t("duration_above_15mn");
    }

    if (duration > 1800) {
      videoCheck.linkedin.duration = false;
      videoCheck.linkedin.error = t("duration_above_30mn");
    }

    // //////////////////// DIMENSIONS CHECKS

    if (width < 540) {
      videoCheck.facebook.reel.dimensions = false;
      videoCheck.facebook.reel.error = t("video_width_below_540");
      videoCheck.tiktok.dimensions = false;
      videoCheck.tiktok.error = t("video_width_below_540");
    }
    if (width < 32) {
      videoCheck.twitter.dimensions = false;
      videoCheck.twitter.error = t("video_width_below_32");
    }

    if (width < 1080) {
      videoCheck.youtube.dimensions = false;
      videoCheck.youtube.error = t("video_width_below_1080");
    }

    if (width > 1280) {
      videoCheck.twitter.dimensions = false;
      videoCheck.twitter.error = t("video_width_above_1280");
    }
    if (width > 1920) {
      videoCheck.facebook.reel.dimensions = false;
      videoCheck.facebook.reel.error = t("video_width_above_1920");
      videoCheck.instagram.regular.dimensions = false;
      videoCheck.instagram.regular.error = t("video_width_above_1920");
      videoCheck.instagram.reel.dimensions = false;
      videoCheck.instagram.reel.error = t("video_width_above_1920");
    }

    if (height < 32) {
      videoCheck.twitter.dimensions = false;
      videoCheck.twitter.error = t("video_height_below_32");
    }
    // if (height < 540) {
    //     videoCheck.tiktok.dimensions = false;
    //     videoCheck.tiktok.error = t('video_height_below_540');
    // }

    if (height < 960) {
      videoCheck.facebook.reel.dimensions = false;
      videoCheck.facebook.reel.error = t("video_height_below_960");
      videoCheck.tiktok.dimensions = false;
      videoCheck.tiktok.error = t("video_height_below_960");
    }

    if (height > 1920) {
      videoCheck.twitter.dimensions = false;
      videoCheck.twitter.error = t("video_height_above_1920");
    }

    setVideoCheckerResults({
      ...videoCheckerResults,
      is_video: true,
      is_loading: false,
      analysis_failed: false,
      video_duration: video.duration,
      video_width: video.videoWidth,
      video_height: video.videoHeight,
      fb_regular:
        videoCheck.facebook.regular.dimensions &&
        videoCheck.facebook.regular.ratio &&
        videoCheck.facebook.regular.duration,
      fb_regular_error: videoCheck.facebook.regular.error,
      fb_reel:
        videoCheck.facebook.reel.dimensions &&
        videoCheck.facebook.reel.ratio &&
        videoCheck.facebook.reel.duration,
      fb_reel_error: videoCheck.facebook.reel.error,
      ig_regular:
        videoCheck.instagram.regular.file_size &&
        videoCheck.instagram.regular.bitrate &&
        videoCheck.instagram.regular.dimensions &&
        videoCheck.instagram.regular.ratio &&
        videoCheck.instagram.regular.duration,
      ig_regular_error: videoCheck.instagram.regular.error,
      ig_reel:
        videoCheck.instagram.reel.bitrate &&
        videoCheck.instagram.reel.dimensions &&
        videoCheck.instagram.reel.ratio &&
        videoCheck.instagram.reel.duration,
      ig_reel_error: videoCheck.instagram.reel.error,
      lk:
        videoCheck.linkedin.file_size &&
        videoCheck.linkedin.dimensions &&
        videoCheck.linkedin.ratio &&
        videoCheck.linkedin.duration,
      lk_error: videoCheck.linkedin.error,
      tw:
        videoCheck.twitter.dimensions &&
        videoCheck.twitter.ratio &&
        videoCheck.twitter.duration,
      tw_error: videoCheck.twitter.error,
      pn:
        videoCheck.pinterest.dimensions &&
        videoCheck.pinterest.ratio &&
        videoCheck.pinterest.duration,
      pn_error: videoCheck.pinterest.error,
      tk:
        videoCheck.tiktok.file_size &&
        videoCheck.tiktok.dimensions &&
        videoCheck.tiktok.ratio &&
        videoCheck.tiktok.duration,
      tk_error: videoCheck.tiktok.error,
      yt:
        videoCheck.youtube.dimensions &&
        videoCheck.youtube.ratio &&
        videoCheck.youtube.duration,
      yt_error: videoCheck.youtube.error,
    });
  };

  useEffect(() => {
    // console.log("relaunching video checker")
    // if (publicationData.social_accounts && publicationData.social_accounts.length > 0 && publicationData.medias && publicationData.medias.length > 0 && publicationData.medias[0].type !== "image") {
    checkVideoDetails();
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicationData.social_accounts]);

  return (
    <div>
      {videoCheckerResults.is_video ? (
        videoCheckerResults.is_loading ? (
          <p className="text-grey text-italic">{t("checking_video")}</p>
        ) : videoCheckerResults.analysis_failed ? (
          <p className="text-grey text-italic">{t("video_analysis_failed")}</p>
        ) : (
          <div className="video_checker_wrapper">
            {!pinterest ? (
              <>
                <div className="video_checker">
                  <img
                    src={FBicon}
                    className="video_checker_sm_icon"
                    alt="Facebook"
                  />
                  {videoCheckerResults.fb_regular ? (
                    <div className="video_checker_icons">
                      <svg viewBox="0 0 48 48" className="svg-green">
                        <path
                          fill="current"
                          d="M24 44q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05 12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q3.75 0 7 1.2t5.85 3.3l-2.15 2.15q-2.2-1.75-4.9-2.7Q27.1 7 24 7q-7.25 0-12.125 4.875T7 24q0 7.25 4.875 12.125T24 41q7.25 0 12.125-4.875T41 24q0-1.5-.225-2.925-.225-1.425-.675-2.775l2.3-2.3q.8 1.85 1.2 3.85.4 2 .4 4.15 0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q28.2 44 24 44Zm-2.95-10.9-8.25-8.3 2.25-2.25 6 6 20.7-20.7 2.3 2.25Z"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className="video_checker_icons">
                      <TooltipHelp
                        content={videoCheckerResults.fb_regular_error}
                        direction="bottom"
                      >
                        <svg viewBox="0 0 48 48" className="svg-red">
                          <path
                            fill="current"
                            d="M22.65 34h3V22h-3ZM24 18.3q.7 0 1.175-.45.475-.45.475-1.15t-.475-1.2Q24.7 15 24 15q-.7 0-1.175.5-.475.5-.475 1.2t.475 1.15q.475.45 1.175.45ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z"
                          />
                        </svg>
                      </TooltipHelp>
                    </div>
                  )}
                  <p className="text-grey p-small">(Video post)</p>
                </div>
                <div className="video_checker">
                  <img
                    src={FBicon}
                    className="video_checker_sm_icon"
                    alt="Facebook"
                  />
                  {videoCheckerResults.fb_reel ? (
                    <div className="video_checker_icons">
                      <svg viewBox="0 0 48 48" className="svg-green">
                        <path
                          fill="current"
                          d="M24 44q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05 12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q3.75 0 7 1.2t5.85 3.3l-2.15 2.15q-2.2-1.75-4.9-2.7Q27.1 7 24 7q-7.25 0-12.125 4.875T7 24q0 7.25 4.875 12.125T24 41q7.25 0 12.125-4.875T41 24q0-1.5-.225-2.925-.225-1.425-.675-2.775l2.3-2.3q.8 1.85 1.2 3.85.4 2 .4 4.15 0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q28.2 44 24 44Zm-2.95-10.9-8.25-8.3 2.25-2.25 6 6 20.7-20.7 2.3 2.25Z"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className="video_checker_icons">
                      <TooltipHelp
                        content={videoCheckerResults.fb_reel_error}
                        direction="bottom"
                      >
                        <svg viewBox="0 0 48 48" className="svg-red">
                          <path
                            fill="current"
                            d="M22.65 34h3V22h-3ZM24 18.3q.7 0 1.175-.45.475-.45.475-1.15t-.475-1.2Q24.7 15 24 15q-.7 0-1.175.5-.475.5-.475 1.2t.475 1.15q.475.45 1.175.45ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z"
                          />
                        </svg>
                      </TooltipHelp>
                    </div>
                  )}
                  <p className="text-grey p-small">(Reel)</p>
                </div>
                <div className="video_checker">
                  <img
                    src={IGicon}
                    className="video_checker_sm_icon"
                    alt="Instagram"
                  />
                  {videoCheckerResults.ig_regular ? (
                    <div className="video_checker_icons">
                      <svg viewBox="0 0 48 48" className="svg-green">
                        <path
                          fill="current"
                          d="M24 44q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05 12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q3.75 0 7 1.2t5.85 3.3l-2.15 2.15q-2.2-1.75-4.9-2.7Q27.1 7 24 7q-7.25 0-12.125 4.875T7 24q0 7.25 4.875 12.125T24 41q7.25 0 12.125-4.875T41 24q0-1.5-.225-2.925-.225-1.425-.675-2.775l2.3-2.3q.8 1.85 1.2 3.85.4 2 .4 4.15 0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q28.2 44 24 44Zm-2.95-10.9-8.25-8.3 2.25-2.25 6 6 20.7-20.7 2.3 2.25Z"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className="video_checker_icons">
                      <TooltipHelp
                        content={videoCheckerResults.ig_regular_error}
                        direction="bottom"
                      >
                        <svg viewBox="0 0 48 48" className="svg-red">
                          <path
                            fill="current"
                            d="M22.65 34h3V22h-3ZM24 18.3q.7 0 1.175-.45.475-.45.475-1.15t-.475-1.2Q24.7 15 24 15q-.7 0-1.175.5-.475.5-.475 1.2t.475 1.15q.475.45 1.175.45ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z"
                          />
                        </svg>
                      </TooltipHelp>
                    </div>
                  )}
                  <p className="text-grey p-small">(Video post)</p>
                </div>
                <div className="video_checker">
                  <img
                    src={IGicon}
                    className="video_checker_sm_icon"
                    alt="Instagram"
                  />
                  {videoCheckerResults.ig_reel ? (
                    <div className="video_checker_icons">
                      <svg viewBox="0 0 48 48" className="svg-green">
                        <path
                          fill="current"
                          d="M24 44q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05 12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q3.75 0 7 1.2t5.85 3.3l-2.15 2.15q-2.2-1.75-4.9-2.7Q27.1 7 24 7q-7.25 0-12.125 4.875T7 24q0 7.25 4.875 12.125T24 41q7.25 0 12.125-4.875T41 24q0-1.5-.225-2.925-.225-1.425-.675-2.775l2.3-2.3q.8 1.85 1.2 3.85.4 2 .4 4.15 0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q28.2 44 24 44Zm-2.95-10.9-8.25-8.3 2.25-2.25 6 6 20.7-20.7 2.3 2.25Z"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className="video_checker_icons">
                      <TooltipHelp
                        content={videoCheckerResults.ig_reel_error}
                        direction="bottom"
                      >
                        <svg viewBox="0 0 48 48" className="svg-red">
                          <path
                            fill="current"
                            d="M22.65 34h3V22h-3ZM24 18.3q.7 0 1.175-.45.475-.45.475-1.15t-.475-1.2Q24.7 15 24 15q-.7 0-1.175.5-.475.5-.475 1.2t.475 1.15q.475.45 1.175.45ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z"
                          />
                        </svg>
                      </TooltipHelp>
                    </div>
                  )}
                  <p className="text-grey p-small">(Reel)</p>
                </div>
                <div className="video_checker">
                  <img
                    src={TKTKicon}
                    className="video_checker_sm_icon"
                    alt="TikTok"
                  />
                  {videoCheckerResults.tk ? (
                    <div className="video_checker_icons">
                      <svg viewBox="0 0 48 48" className="svg-green">
                        <path
                          fill="current"
                          d="M24 44q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05 12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q3.75 0 7 1.2t5.85 3.3l-2.15 2.15q-2.2-1.75-4.9-2.7Q27.1 7 24 7q-7.25 0-12.125 4.875T7 24q0 7.25 4.875 12.125T24 41q7.25 0 12.125-4.875T41 24q0-1.5-.225-2.925-.225-1.425-.675-2.775l2.3-2.3q.8 1.85 1.2 3.85.4 2 .4 4.15 0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q28.2 44 24 44Zm-2.95-10.9-8.25-8.3 2.25-2.25 6 6 20.7-20.7 2.3 2.25Z"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className="video_checker_icons">
                      <TooltipHelp
                        content={videoCheckerResults.tk_error}
                        direction="bottom"
                      >
                        <svg viewBox="0 0 48 48" className="svg-red">
                          <path
                            fill="current"
                            d="M22.65 34h3V22h-3ZM24 18.3q.7 0 1.175-.45.475-.45.475-1.15t-.475-1.2Q24.7 15 24 15q-.7 0-1.175.5-.475.5-.475 1.2t.475 1.15q.475.45 1.175.45ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z"
                          />
                        </svg>
                      </TooltipHelp>
                    </div>
                  )}
                </div>
                <div className="video_checker">
                  <img
                    src={YTicon}
                    className="video_checker_sm_icon"
                    alt="YouTube"
                  />
                  {videoCheckerResults.yt ? (
                    <div className="video_checker_icons">
                      <svg viewBox="0 0 48 48" className="svg-green">
                        <path
                          fill="current"
                          d="M24 44q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05 12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q3.75 0 7 1.2t5.85 3.3l-2.15 2.15q-2.2-1.75-4.9-2.7Q27.1 7 24 7q-7.25 0-12.125 4.875T7 24q0 7.25 4.875 12.125T24 41q7.25 0 12.125-4.875T41 24q0-1.5-.225-2.925-.225-1.425-.675-2.775l2.3-2.3q.8 1.85 1.2 3.85.4 2 .4 4.15 0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q28.2 44 24 44Zm-2.95-10.9-8.25-8.3 2.25-2.25 6 6 20.7-20.7 2.3 2.25Z"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className="video_checker_icons">
                      <TooltipHelp
                        content={videoCheckerResults.yt_error}
                        direction="bottom"
                      >
                        <svg viewBox="0 0 48 48" className="svg-red">
                          <path
                            fill="current"
                            d="M22.65 34h3V22h-3ZM24 18.3q.7 0 1.175-.45.475-.45.475-1.15t-.475-1.2Q24.7 15 24 15q-.7 0-1.175.5-.475.5-.475 1.2t.475 1.15q.475.45 1.175.45ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z"
                          />
                        </svg>
                      </TooltipHelp>
                    </div>
                  )}
                </div>
                <div className="video_checker">
                  <img
                    src={TWicon}
                    className="video_checker_sm_icon"
                    alt="Twitter"
                  />
                  {videoCheckerResults.tw ? (
                    <div className="video_checker_icons">
                      <svg viewBox="0 0 48 48" className="svg-green">
                        <path
                          fill="current"
                          d="M24 44q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05 12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q3.75 0 7 1.2t5.85 3.3l-2.15 2.15q-2.2-1.75-4.9-2.7Q27.1 7 24 7q-7.25 0-12.125 4.875T7 24q0 7.25 4.875 12.125T24 41q7.25 0 12.125-4.875T41 24q0-1.5-.225-2.925-.225-1.425-.675-2.775l2.3-2.3q.8 1.85 1.2 3.85.4 2 .4 4.15 0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q28.2 44 24 44Zm-2.95-10.9-8.25-8.3 2.25-2.25 6 6 20.7-20.7 2.3 2.25Z"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className="video_checker_icons">
                      <TooltipHelp
                        content={videoCheckerResults.tw_error}
                        direction="bottom"
                      >
                        <svg viewBox="0 0 48 48" className="svg-red">
                          <path
                            fill="current"
                            d="M22.65 34h3V22h-3ZM24 18.3q.7 0 1.175-.45.475-.45.475-1.15t-.475-1.2Q24.7 15 24 15q-.7 0-1.175.5-.475.5-.475 1.2t.475 1.15q.475.45 1.175.45ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z"
                          />
                        </svg>
                      </TooltipHelp>
                    </div>
                  )}
                </div>
                <div className="video_checker">
                  <img
                    src={LKicon}
                    className="video_checker_sm_icon"
                    alt="LinkedIn"
                  />
                  {videoCheckerResults.lk ? (
                    <div className="video_checker_icons">
                      <svg viewBox="0 0 48 48" className="svg-green">
                        <path
                          fill="current"
                          d="M24 44q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05 12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q3.75 0 7 1.2t5.85 3.3l-2.15 2.15q-2.2-1.75-4.9-2.7Q27.1 7 24 7q-7.25 0-12.125 4.875T7 24q0 7.25 4.875 12.125T24 41q7.25 0 12.125-4.875T41 24q0-1.5-.225-2.925-.225-1.425-.675-2.775l2.3-2.3q.8 1.85 1.2 3.85.4 2 .4 4.15 0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q28.2 44 24 44Zm-2.95-10.9-8.25-8.3 2.25-2.25 6 6 20.7-20.7 2.3 2.25Z"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className="video_checker_icons">
                      <TooltipHelp
                        content={videoCheckerResults.lk_error}
                        direction="bottom"
                      >
                        <svg viewBox="0 0 48 48" className="svg-red">
                          <path
                            fill="current"
                            d="M22.65 34h3V22h-3ZM24 18.3q.7 0 1.175-.45.475-.45.475-1.15t-.475-1.2Q24.7 15 24 15q-.7 0-1.175.5-.475.5-.475 1.2t.475 1.15q.475.45 1.175.45ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z"
                          />
                        </svg>
                      </TooltipHelp>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="video_checker">
                <img
                  src={PNicon}
                  className="video_checker_sm_icon"
                  alt="Pinterest"
                />
                {videoCheckerResults.pn ? (
                  <div className="video_checker_icons">
                    <svg viewBox="0 0 48 48" className="svg-green">
                      <path
                        fill="current"
                        d="M24 44q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05 12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q3.75 0 7 1.2t5.85 3.3l-2.15 2.15q-2.2-1.75-4.9-2.7Q27.1 7 24 7q-7.25 0-12.125 4.875T7 24q0 7.25 4.875 12.125T24 41q7.25 0 12.125-4.875T41 24q0-1.5-.225-2.925-.225-1.425-.675-2.775l2.3-2.3q.8 1.85 1.2 3.85.4 2 .4 4.15 0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q28.2 44 24 44Zm-2.95-10.9-8.25-8.3 2.25-2.25 6 6 20.7-20.7 2.3 2.25Z"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="video_checker_icons">
                    <TooltipHelp
                      content={videoCheckerResults.pn_error}
                      direction="bottom"
                    >
                      <svg viewBox="0 0 48 48" className="svg-red">
                        <path
                          fill="current"
                          d="M22.65 34h3V22h-3ZM24 18.3q.7 0 1.175-.45.475-.45.475-1.15t-.475-1.2Q24.7 15 24 15q-.7 0-1.175.5-.475.5-.475 1.2t.475 1.15q.475.45 1.175.45ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z"
                        />
                      </svg>
                    </TooltipHelp>
                  </div>
                )}
              </div>
            )}
            <div className="video_checker margin-t2">
              <p className="p-small">
                👉{" "}
                <a
                  className="light_blue_link underline"
                  href="https://docs.google.com/spreadsheets/d/1RxlhWVYr64dghDmeExkc6dCqdHO9FtI9r4CEyNCe38I/edit#gid=0"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("video_specs_sheet")}
                </a>
              </p>
            </div>
          </div>
        )
      ) : (
        ""
      )}
      {(publicationData.medias &&
        publicationData.medias.length > 0 &&
        publicationData.medias[0].type !== "image") ||
      (publicationData.medias &&
        publicationData.medias.length > 0 &&
        publicationData.medias[0].type !== "image" &&
        videoCheckerResults.needs_analysis) ? (
        <div className="hidden_video_container">
          <video
            id="selected_video_container"
            ref={videoLoaderRef}
            src={publicationData.medias[0].file_url}
            onLoadedMetadata={checkVideoDetails}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ContentMediaVideoChecker;

// Video files constraints
//
// Facebook:
// - regular: No constraints
//     - reels:
// - resolution: min(540x960) max(N / A)
//     - ratio: 9 / 16
//         - duration: min(4s) max(60s)
//             - filesize: N / A
//                 - format: all basically

// Instagram:
// - regular:
// - resolution: max(1920 width)
//     - ratio: min(4 / 5) max(16 / 9)
//         - duration: min(3s) max(60s)
//             - filesize: 100MB
//                 - format: .MOV or.MP4
//                     - reels:
// - resolution: max(1920 width)
//     - ratio: min(0.01 / 1) max(10 / 1) recommended 9 / 16
//         - duration: min(3s) max(15mn)
//             - filesize: 1GB
//                 - format: .MOV or.MP4
// LinkedIn:
// - regular:
// - resolution: N / A
//     - ratio: min(1 / 2.4) max(2.4 / 1) bests are 16 / 9, 1 / 1, 4 / 5, 9 / 16
//         - duration: min(3s) max(30mn)
//             - filesize: 200MB
//                 - format: .MP4
// Twitter:
// - regular:
// - resolution: 1280x720, 720x1280, 720x720
//     - ratio: min(1 / 3) max(3 / 1)=> Reco 16 / 9 and 1 / 1
//         - duration: min(0.5s) max(140s)
//             - filesize: 512MB
//                 - format: .MP4 ?
//                     Pinterest :
//                     - regular:
// - resolution:
// - ratio: min(1 / 2) max(1.91 / 1) => reco 1 / 1, 2 / 3 or 9 / 16
//     - duration: min(4s) max(15m)
//         - filesize: max(2GB)
//             - format: .MP4 or.MOV
