import React from 'react'
import { useTranslation } from "react-i18next";

import Analytics from "../../../images/onboarding/onboarding_analytics.png";

const OnboardingCoAnalytics = ({ onboardingResponses, handleResponse }) => {
    const { t } = useTranslation(['onboarding_cesam']);
    return (
        <div className="onboarding_content">
            <div className='onboarding_qa'>
                <div className='onboarding_question'>{t('onbq_analytics')}</div>
                <div className='onboarding_divider_wrapper'>
                    <div className='onboarding_divider'></div>
                </div>
                <div className='onboarding_response'>
                    <label className="onboarding_radio">
                        <input type="radio" name="analytics" value="true" checked={onboardingResponses.analytics === "true" ? true : false} onChange={e => handleResponse(e)} />
                        {t('yes')}
                    </label>
                    <label className="onboarding_radio">
                        <input type="radio" name="analytics" value="false" checked={onboardingResponses.analytics === "false" ? true : false} onChange={e => handleResponse(e)} />
                        {t('no')}
                    </label>
                </div>
            </div>
            <div className='onboarding_illustration'>
                <img src={Analytics} alt="" />
            </div>
        </div>
    )
}

export default OnboardingCoAnalytics