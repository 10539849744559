import React from 'react'
import { useTranslation } from "react-i18next";

const OptionsLKPoll = ({ publicationData, setPublicationData }) => {
    const { t } = useTranslation(['composer']);

    const handleChoices = (index, value) => {
        if (value.length <= 30) {
            const arrayToUpdate = publicationData.lk_poll.choices;
            arrayToUpdate[index] = value;

            setPublicationData({
                ...publicationData,
                lk_poll: {
                    ...publicationData.lk_poll,
                    choices: arrayToUpdate
                }
            })
        }
    }

    const handlePollDuration = (e) => {
        setPublicationData({
            ...publicationData,
            lk_poll: {
                ...publicationData.lk_poll,
                duration: e.target.value
            }
        })
    }


    return (
        <div className="margin-t1">
            <label className='margin-t1'>{t('poll_duration')}</label>
            <select className='editor' name="poll_duration" onChange={e => handlePollDuration(e)}>
                <option value="1">{t('poll_1_day')}</option>
                <option value="3">{t('poll_3_days')}</option>
                <option value="7">{t('poll_7_days')}</option>
                <option value="14">{t('poll_14_days')}</option>
            </select>
            <label className='margin-t1'>{t('lk_poll_your_question')}<sup>*</sup></label>
            <div className='editor_input_block'>
                <input type="text" className="editor" placeholder={t('lk_poll_your_question_placeholder')} value={publicationData.lk_poll.question} onChange={e => e.target.value.length <= 140 ? setPublicationData({ ...publicationData, lk_poll: { ...publicationData.lk_poll, question: e.target.value } }) : null} />
            </div>
            <label className='margin-t2'>Option 1<sup>*</sup></label>
            <div className='editor_input_block'>
                <input type="text" className="editor" placeholder={t('lk_poll_option_1_placeholder')} value={publicationData.lk_poll.choices && publicationData.lk_poll.choices.length > 0 ? publicationData.lk_poll.choices[0] : ''} onChange={e => handleChoices(0, e.target.value)} />
            </div>
            <label className='margin-t1'>Option 2<sup>*</sup></label>
            <div className='editor_input_block'>
                <input type="text" className="editor" placeholder={t('lk_poll_option_2_placeholder')} value={publicationData.lk_poll.choices && publicationData.lk_poll.choices.length > 0 ? publicationData.lk_poll.choices[1] : ''} onChange={e => handleChoices(1, e.target.value)} />
            </div>
            <label className='margin-t1'>Option 3</label>
            <div className='editor_input_block'>
                <input type="text" className="editor" placeholder={t('lk_poll_option_3_placeholder')} value={publicationData.lk_poll.choices && publicationData.lk_poll.choices.length > 0 ? publicationData.lk_poll.choices[2] : ''} onChange={e => handleChoices(2, e.target.value)} />
            </div>
            <label className='margin-t1'>Option 4</label>
            <div className='editor_input_block'>
                <input type="text" className="editor" placeholder={t('lk_poll_option_4_placeholder')} value={publicationData.lk_poll.choices && publicationData.lk_poll.choices.length > 0 ? publicationData.lk_poll.choices[3] : ''} onChange={e => handleChoices(3, e.target.value)} />
            </div>
        </div>
    )
}

export default OptionsLKPoll