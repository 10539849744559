import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import moment from 'moment-timezone'
import { useHistory } from "react-router-dom";
import { useAuth } from "../../auth";
import { useTranslation } from "react-i18next";
import StateModal from '../Loader/StateModal';

import DraftModal from './DraftModal';

import "./drafts.css"

import FBicon from "../../images/social_media/facebook.svg";
import TWicon from "../../images/social_media/twitter.svg";
import LKicon from "../../images/social_media/linkedin.svg";
import IGicon from "../../images/social_media/instagram.svg";
import PNicon from "../../images/social_media/pinterest.svg";
import GMBicon from "../../images/social_media/gmb.svg";
import TKicon from "../../images/social_media/tiktok-spk.svg";
import YTicon from "../../images/social_media/youtube.svg";
import SPKNoProfile from "../../images/social_media/SPK-no-profile.png"
import FBGroupIcon from "../../images/social_media/fb_group.png";

import NOMedia from "../../images/illustrations/drafts.png";


import SocialsModal from '../Manage/ManageComponents/SocialsModal';

const Drafts = (props) => {
    const draftRef = useRef([]);
    const history = useHistory();

    const { authTokens } = useAuth();
    const currentPlan = Number(authTokens.plan);

    const headers = {
        Authorization: authTokens.token,
    };
    const { t } = useTranslation(['feeds']);

    const [showSocialsModal, setShowSocialsModal] = useState(false);
    const closeSocialsModal = () => {
        setShowSocialsModal(false);
    }

    const [modalAbsolutePosition, setModalAbsolutePosition] = useState({ left: "10rem" });

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }

    const initialPublicationData = {
        _id: "",
        name: "",
        text: "",
        link_url: "",
        date: "",
        tags: "",
        frequency: "0",
        repeat: "",
        company_id: authTokens.account_id,
        draft: false,
        spread: false,
        spread_period: "5",
        social_accounts: [],
        medias: [],
        alt_text_Facebook: '',
        alt_text_Instagram: '',
        alt_text_Twitter: '',
        alt_text_GMB: '',
        alt_text_LinkedIn: '',
        mentions_LinkedIn: [],
        reel_eligible: false,
        reel: false,
        gmb_topic: '',
        gmb_cta: '',
        gmb_cta_url: '',
        gmb_event_title: '',
        gmb_event_start_date: '',
        gmb_event_start_time: '',
        gmb_event_end_date: '',
        gmb_event_end_time: '',
        gmb_offer_coupon_code: '',
        gmb_offer_url: '',
        gmb_offer_terms: '',
        template: false
    }
    const [publicationData, setPublicationData] = useState(initialPublicationData);

    const recordPost = async (index, switch_draft) => {
        let authError = false;

        let post_payload = publicationData;

        if (switch_draft) {
            post_payload.draft = false;
        }


        if (!post_payload.social_accounts || post_payload.social_accounts.length === 0) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('select_a_social_account')
            });
            return
        }

        let ig_check = false;

        if (post_payload.social_accounts.length > 0) {
            post_payload.social_accounts.forEach(acc => {
                if (acc.media === "Instagram") {
                    ig_check = true;
                }
            })
        }

        if (ig_check && post_payload.medias.length === 0) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('no_img_ig')
            });
            return
        }

        if (!post_payload.text && !post_payload.alt_text_Facebook && !post_payload.alt_text_Instagram && !post_payload.alt_text_Twitter && !post_payload.alt_text_LinkedIn && !post_payload.alt_text_GMB && post_payload.medias.length === 0 && !post_payload.link_url) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('no_content')
            });
            return
        }

        if (!post_payload.date) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                status: "info",
                message: t('no_date')
            });
            return
        }

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });


        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/save_post_spread_option", post_payload, {
                headers: headers,
            })
            .then(() => {
                displayModal(null, index);
                if (!publicationData.draft) {
                    setAllDrafts(allDrafts.filter(eDraft => eDraft._id !== publicationData._id))

                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        upgradeOption: currentPlan === 0 ? true : false,
                        status: "check",
                        message: t('post_recorded'),
                        button_text: t('go_to_calendar'),
                        button_link: "/calendar/monthly"
                    });
                }
                else {
                    fetchAllDraftsNTemplates();
                }

            })
            .catch((error) => {
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
                else {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        loader: false,
                        status: "error",
                        message: t('post_not_recorded')
                    });
                }

            });
        if (authError) return;
    }


    const editPost = (post_id, thread, social_accounts, bulk, rss) => {
        if (social_accounts && social_accounts.length > 0 && social_accounts[0].media === 'Pinterest') {
            history.push({
                pathname: '/post/pins/' + post_id,
            });
        }
        else if (thread) {
            history.push({
                pathname: '/post/thread/' + post_id,
            });
        }
        else if (bulk) {
            history.push({
                pathname: '/post/burst_post/' + post_id,
            });
        }
        else if (rss) {
            history.push({
                pathname: '/post/rss/' + post_id,
            });
        }
        else {
            history.push({
                pathname: '/post/new_post/' + post_id,
            });
        }
    }

    const deleteScheduledPost = async (schedule_id, index) => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/delete_post", { schedule_id }, {
            headers: headers,
        }).then(() => {
            fetchAllDraftsNTemplates();
        });

        displayModal(null, index);
        closeStateModal();
    }

    const deleteRSS = async (schedule_id, index) => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/rss/delete_feed", { feed_id: schedule_id }, {
            headers: headers,
        }).then(() => {
            fetchAllDraftsNTemplates();
        });

        displayModal(null, index);
        closeStateModal();
    }

    const confirmRSS = async (feed_id, index) => {

        if (!feed_id) return;

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        const payload = {
            feed_id: feed_id,
        }
        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/rss/toggle_feed_publication", payload, {
                headers: headers,
            })
            .then(() => {
                fetchAllDraftsNTemplates();
            })
            .catch(() => {
            });


        displayModal(null, index);
        closeStateModal();
    }

    const [showDraftModal, setShowDraftModal] = useState(true);
    const displayModal = (draft, index) => {
        // setPublicationData(draft);

        if (draft) {
            setPublicationData(draft);
        }
        else {
            setPublicationData(initialPublicationData);
        }

        if (draftRef.current[index].offsetLeft + 100 + 500 + 20 > window.innerWidth) {
            setModalAbsolutePosition({ left: "-250px" });
        }
        else {
            setModalAbsolutePosition({ left: "10rem" });
        }

        setShowDraftModal(state => ({
            ...state, // <-- copy previous state
            [index]: !state[index] // <-- update value by index key
        }));
    }

    const optimizedMediaURL = (url) => {
        if (url.includes("tr:")) {
            return url;
        }
        const url_options = "tr:n-ik_ml_thumbnail";
        // const url_options = "tr:h-150,pr-true";
        let arrayedURL = url.split("/");
        arrayedURL.splice(arrayedURL.length - 1, 0, url_options);
        const optimized_url = arrayedURL.join('/');
        return optimized_url;
    }

    const hashtagsFormat = (hashtag) => {
        const formattedText = '<span class="classic_tags">' + hashtag + '</span>';
        return formattedText
    }

    const formatCaption = (postData) => {
        let text = postData.text + (postData.tags ? '\n' + postData.tags : '');
        let formatted_text = text;//`${text.toString()}`;
        const regex = /#\w*/g;
        let new_text = formatted_text.replace(regex, hashtagsFormat);


        return new_text.replace(/\r\n|\r|\n/g, "<br />") + '<br />';
        // return new_text;
    }

    const formatFeed = (postData) => {

        let formatted_link = '';
        if (postData.feed_url.length > 30) {
            formatted_link = " " + postData.feed_url.substring(0, 30) + "... ";
        }
        else {
            formatted_link = " " + postData.feed_url;
        }


        return `<svg viewBox="0 0 24 24">
        <path fill="current" d="M6.18,15.64A2.18,2.18 0 0,1 8.36,17.82C8.36,19 7.38,20 6.18,20C5,20 4,19 4,17.82A2.18,2.18 0 0,1 6.18,15.64M4,4.44A15.56,15.56 0 0,1 19.56,20H16.73A12.73,12.73 0 0,0 4,7.27V4.44M4,10.1A9.9,9.9 0 0,1 13.9,20H11.07A7.07,7.07 0 0,0 4,12.93V10.1Z" />
    </svg> ${formatted_link}`
    }

    const checkAccountPhoto = (draftData, sm_icon) => {


        if ((!draftData.social_accounts || draftData.social_accounts.length === 0) && draftData.cai_generated) {
            return (
                <div className="account_profile_ai" >
                    <svg viewBox="0 0 48 48">
                        <path fill="current" d="m9.35 20.45 5.3 2.25q.9-1.8 1.925-3.55Q17.6 17.4 18.75 15.8L14.8 15Zm7.7 4.05 6.65 6.65q2.85-1.3 5.35-2.95 2.5-1.65 4.05-3.2 4.05-4.05 5.95-8.3 1.9-4.25 2.05-9.6-5.35.15-9.6 2.05t-8.3 5.95q-1.55 1.55-3.2 4.05-1.65 2.5-2.95 5.35Zm11.45-4.8q-1-1-1-2.475t1-2.475q1-1 2.475-1t2.475 1q1 1 1 2.475t-1 2.475q-1 1-2.475 1t-2.475-1Zm-.75 19.15 5.45-5.45-.8-3.95q-1.6 1.15-3.35 2.175T25.5 33.55Zm16.3-34.7q.45 6.8-1.7 12.4-2.15 5.6-7.1 10.55l-.1.1-.1.1 1.1 5.5q.15.75-.075 1.45-.225.7-.775 1.25l-8.55 8.6-4.25-9.9-8.5-8.5-9.9-4.25 8.6-8.55q.55-.55 1.25-.775.7-.225 1.45-.075l5.5 1.1q.05-.05.1-.075.05-.025.1-.075 4.95-4.95 10.55-7.125 5.6-2.175 12.4-1.725Zm-36.6 27.6Q9.2 30 11.725 29.975 14.25 29.95 16 31.7q1.75 1.75 1.725 4.275Q17.7 38.5 15.95 40.25q-1.3 1.3-4.025 2.15Q9.2 43.25 3.75 44q.75-5.45 1.575-8.2.825-2.75 2.125-4.05Zm2.1 2.15q-.7.75-1.25 2.35t-.95 4.1q2.5-.4 4.1-.95 1.6-.55 2.35-1.25.95-.85.975-2.125.025-1.275-.875-2.225-.95-.9-2.225-.875-1.275.025-2.125.975Z" />
                    </svg>
                </div>
            )
        }
        else if ((!draftData.social_accounts || draftData.social_accounts.length === 0) && !draftData.cai_generated) {
            return (
                <div className="account_profile_photo" >
                    <img className="profile_picture" src={SPKNoProfile} alt="Profile" />
                </div>
            )
        }
        else {

            let accountData = draftData.social_accounts[0];


            switch (accountData.media) {
                case "Facebook":
                    let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                    if(accountData.fb_group){
                        fb_picture_url=FBGroupIcon
                    }
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={fb_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={FBicon} alt="Media" /> : ""}
                        </div>
                    )
                case "Twitter":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={TWicon} alt="Media" /> : ""}
                        </div>
                    )
                case "LinkedIn":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={LKicon} alt="Media" /> : ""}
                        </div>
                    )
                case "Instagram":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={IGicon} alt="Media" /> : ""}
                        </div>
                    )
                case "Pinterest":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={PNicon} alt="Media" /> : ""}
                        </div>
                    )
                case "GMB":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={GMBicon} alt="Media" /> : ""}
                        </div>
                    )
                case "TikTok":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={TKicon} alt="Media" /> : ""}
                        </div>
                    )
                case "Youtube":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={YTicon} alt="Media" /> : ""}
                        </div>
                    )
                default:
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={SPKNoProfile} alt="Profile" />
                        </div>
                    )
            }
        }
    }


    const [allDrafts, setAllDrafts] = useState([])

    const fetchAllDraftsNTemplates = async () => {
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/post/get_drafts", payload, {
                headers: headers,
            })
            .then((response) => {
                setAllDrafts(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

        closeStateModal();
    }

    const [allSMAccounts, setAllSMAccounts] = useState([]);
    const [SMAccounts, setSMAccounts] = useState([]);
    const [SMGroups, setSMGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState({});

    const selectGroup = (e) => {
        setSelectedGroup(e.target.value);
        let newlyAvailableSMAccounts = [];

        if (!e.target.value) {
            setSMAccounts(allSMAccounts);
        }
        else {
            const filteredGroupData = SMGroups.filter(group => group._id === e.target.value);

            filteredGroupData[0].sm_accounts.map(account => {
                let sm_account = allSMAccounts.filter(acc => acc._id === account);
                if (sm_account[0]) {
                    newlyAvailableSMAccounts.push(sm_account[0]);
                }
                return null;
            })

            setSMAccounts(newlyAvailableSMAccounts);
        }

        setPublicationData({
            ...publicationData,
            social_accounts: newlyAvailableSMAccounts
        });
    }

    const selectMediaAccount = (account) => {
        let selectedMediaAccount = publicationData.social_accounts;

        let indexFound = selectedMediaAccount.findIndex((media) => media.social_id === account.social_id);

        if (indexFound !== -1) {
            const filteredMedia = selectedMediaAccount.filter(item => item.social_id !== account.social_id);
            setPublicationData({
                ...publicationData,
                social_accounts: filteredMedia
            });
        }
        else {
            selectedMediaAccount.push(account);

            setPublicationData({
                ...publicationData,
                social_accounts: selectedMediaAccount
            });
        }
    }

    const fetchAvailableSocialMedia = async () => {
        let authError = false;
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });


        // NEW FUNCTION - TEAM COMPLIANT

        const payload = {
            user_id: authTokens.user_id,
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_accounts_ex_pinterest", payload, {
                headers: headers,
            })
            .then((response) => {
                closeStateModal();
                setAllSMAccounts(response.data.sm_accounts);
                setSMAccounts(response.data.sm_accounts);
                setSMGroups(response.data.sm_groups);
                if (response.data.sm_accounts.length === 0) {
                    setShowSocialsModal(true);
                }

            })
            .catch((error) => {
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
            });
        if (authError) return;
    }

    useEffect(() => {
        fetchAllDraftsNTemplates();
        fetchAvailableSocialMedia();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className='content_row_maxed'>
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            {showSocialsModal ? <SocialsModal closeSocialsModal={closeSocialsModal} /> : ""}
            {allDrafts && allDrafts.length > 0 ?
                allDrafts.map((draft, i) => {
                    return (
                        <div key={draft._id} ref={ref => { draftRef.current[i] = ref; }} className={draft.draft ? 'draft_post draft' : 'draft_post'}>
                            {showDraftModal[i] ? <DraftModal index={i} displayModal={displayModal} modalAbsolutePosition={modalAbsolutePosition} recordPost={recordPost} editPost={editPost} deleteScheduledPost={deleteScheduledPost} publicationData={publicationData} setPublicationData={setPublicationData} SMAccounts={SMAccounts} SMGroups={SMGroups} selectedGroup={selectedGroup} selectGroup={selectGroup} selectMediaAccount={selectMediaAccount} deleteRSS={deleteRSS} confirmRSS={confirmRSS} /> : ''}
                            <div className='account_header'>
                                {checkAccountPhoto(draft, true)}
                                <div className="profile_text">
                                    <span className="acc_name">{draft.social_accounts.length > 0 ? draft.social_accounts[0].social_name : draft.cai_generated ? t('ai_post') : t('draft_post')}</span>
                                    <p className="p-no-margin p-mini">{draft.date ? `${moment(draft.date).tz(draft.timezone && draft.timezone.value ? draft.timezone.value : Intl.DateTimeFormat().resolvedOptions().timeZone).format('Do MMM YY - HH.mm')} ${draft.timezone && draft.timezone.label ? draft.timezone.label.split(' ')[0] : Intl.DateTimeFormat().resolvedOptions().timeZone}` : ''}</p>
                                </div>
                                <div className="dots_container">
                                    <div className="dots" onClick={() => displayModal(draft, i)}>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="current" d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            {draft.template ? <p className='text-red'>Template</p> : ''}
                            {draft.medias && draft.medias.length > 0 ?
                                <div className='draft-view-media'>
                                    {draft.medias[0].type === 'image' ?
                                        <img src={optimizedMediaURL(draft.medias[0].file_url)} alt="" />
                                        :
                                        <div className='draft-view-video-container'>
                                            {draft.medias[0].thumbnail_url ?
                                                <img key={draft.medias[0]._id} src={draft.medias[0].thumbnail_url} alt="" />
                                                :
                                                <video key={draft.medias[0]._id}>
                                                    <source src={draft.medias[0].file_url} />
                                                </video>
                                            }
                                            {/* <video>
                                                <source src={draft.medias[0].file_url} />
                                            </video> */}
                                            <div className='video_icon'>
                                                <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                                    <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                                </svg>
                                            </div>
                                            {/* <div className="video_overlay">
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                                </svg>
                                            </div> */}
                                        </div>
                                    }
                                </div>
                                :
                                draft.lk_poll && draft.lk_poll.display && draft.lk_poll.question && draft.lk_poll.question.length > 0 && draft.lk_poll.choices && draft.lk_poll.choices.length > 1 ?
                                    <div className="lk_poll_block">
                                        <div className='lk_poll_question'>{draft.lk_poll.question}</div>
                                        {draft.lk_poll.choices.map((choice, c) => {
                                            if (choice.length > 0) {
                                                return (
                                                    <div key={`choice_${c}`} className='lk_poll_choice_item'>{choice}</div>
                                                )
                                            }

                                        })}
                                    </div>
                                    : ''
                            }
                            <div className='draft-view-caption' dangerouslySetInnerHTML={{ __html: draft.feed_url ? formatFeed(draft) : formatCaption(draft) }}></div>
                        </div>
                    )
                })
                :
                <div className="no_media">
                    <div className="no_media_title">{t('no_media_drafts')}</div>
                    <img src={NOMedia} alt="No media yet" />
                </div>
            }
        </div>
    )
}

export default Drafts