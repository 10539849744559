import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

import DraftModal from '../../Drafts/DraftModal';

import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import PNicon from "../../../images/social_media/pinterest.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
// import TKTKicon from "../../../images/social_media/tiktok-spk.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"

const RSSList = ({ authTokens }) => {
    const rssRef = useRef([]);
    const history = useHistory();

    const { t } = useTranslation(['manage']);
    const headers = {
        Authorization: authTokens.token,
    };


    const [modalAbsolutePosition, setModalAbsolutePosition] = useState({ left: "10rem" });


    const [showDraftModal, setShowDraftModal] = useState(true);
    const displayModal = (draft, index) => {

        if (rssRef.current[index].offsetLeft + 100 + 500 + 20 > window.innerWidth) {
            setModalAbsolutePosition({ left: "-250px" });
        }
        else {
            setModalAbsolutePosition({ left: "10rem" });
        }

        setShowDraftModal(state => ({
            ...state, // <-- copy previous state
            [index]: !state[index] // <-- update value by index key
        }));
    }

    const [activeRSS, setActiveRSS] = useState([]);

    const checkAccountPhoto = (draftData, sm_icon) => {


        if ((!draftData.social_accounts || draftData.social_accounts.length === 0) && draftData.cai_generated) {
            return (
                <div className="account_profile_ai" >
                    <svg viewBox="0 0 48 48">
                        <path fill="current" d="m9.35 20.45 5.3 2.25q.9-1.8 1.925-3.55Q17.6 17.4 18.75 15.8L14.8 15Zm7.7 4.05 6.65 6.65q2.85-1.3 5.35-2.95 2.5-1.65 4.05-3.2 4.05-4.05 5.95-8.3 1.9-4.25 2.05-9.6-5.35.15-9.6 2.05t-8.3 5.95q-1.55 1.55-3.2 4.05-1.65 2.5-2.95 5.35Zm11.45-4.8q-1-1-1-2.475t1-2.475q1-1 2.475-1t2.475 1q1 1 1 2.475t-1 2.475q-1 1-2.475 1t-2.475-1Zm-.75 19.15 5.45-5.45-.8-3.95q-1.6 1.15-3.35 2.175T25.5 33.55Zm16.3-34.7q.45 6.8-1.7 12.4-2.15 5.6-7.1 10.55l-.1.1-.1.1 1.1 5.5q.15.75-.075 1.45-.225.7-.775 1.25l-8.55 8.6-4.25-9.9-8.5-8.5-9.9-4.25 8.6-8.55q.55-.55 1.25-.775.7-.225 1.45-.075l5.5 1.1q.05-.05.1-.075.05-.025.1-.075 4.95-4.95 10.55-7.125 5.6-2.175 12.4-1.725Zm-36.6 27.6Q9.2 30 11.725 29.975 14.25 29.95 16 31.7q1.75 1.75 1.725 4.275Q17.7 38.5 15.95 40.25q-1.3 1.3-4.025 2.15Q9.2 43.25 3.75 44q.75-5.45 1.575-8.2.825-2.75 2.125-4.05Zm2.1 2.15q-.7.75-1.25 2.35t-.95 4.1q2.5-.4 4.1-.95 1.6-.55 2.35-1.25.95-.85.975-2.125.025-1.275-.875-2.225-.95-.9-2.225-.875-1.275.025-2.125.975Z" />
                    </svg>
                </div>
            )
        }
        else if ((!draftData.social_accounts || draftData.social_accounts.length === 0) && !draftData.cai_generated) {
            return (
                <div className="account_profile_photo" >
                    <img className="profile_picture" src={SPKNoProfile} alt="Profile" />
                </div>
            )
        }
        else {

            let accountData = draftData.social_accounts[0];


            switch (accountData.media) {
                case "Facebook":
                    const fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={fb_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={FBicon} alt="Media" /> : ""}
                        </div>
                    )
                case "Twitter":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={TWicon} alt="Media" /> : ""}
                        </div>
                    )
                case "LinkedIn":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={LKicon} alt="Media" /> : ""}
                        </div>
                    )
                case "Instagram":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={IGicon} alt="Media" /> : ""}
                        </div>
                    )
                case "Pinterest":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={PNicon} alt="Media" /> : ""}
                        </div>
                    )
                case "GMB":
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            {sm_icon ? <img className="account_profile_media" src={GMBicon} alt="Media" /> : ""}
                        </div>
                    )
                default:
                    return (
                        <div className="account_profile_photo" >
                            <img className="profile_picture" src={SPKNoProfile} alt="Profile" />
                        </div>
                    )
            }
        }
    }

    const formatFeed = (postData) => {

        let formatted_link = '';
        if (postData.feed_url.length > 30) {
            formatted_link = " " + postData.feed_url.substring(0, 30) + "... ";
        }
        else {
            formatted_link = " " + postData.feed_url;
        }


        return `<svg viewBox="0 0 24 24">
        <path fill="current" d="M6.18,15.64A2.18,2.18 0 0,1 8.36,17.82C8.36,19 7.38,20 6.18,20C5,20 4,19 4,17.82A2.18,2.18 0 0,1 6.18,15.64M4,4.44A15.56,15.56 0 0,1 19.56,20H16.73A12.73,12.73 0 0,0 4,7.27V4.44M4,10.1A9.9,9.9 0 0,1 13.9,20H11.07A7.07,7.07 0 0,0 4,12.93V10.1Z" />
    </svg> ${formatted_link}`
    }



    const editRSS = (post_id, thread, social_accounts, bulk, rss) => {
        if (rss) {
            history.push({
                pathname: '/post/rss/' + post_id,
            });
        }
    }

    const deleteRSS = async (feed_id) => {
        const payload = {
            feed_id: feed_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/rss/delete_feed", payload, {
                headers: headers,
            })
            .then(() => {
                setActiveRSS(activeRSS.filter(group => group._id !== feed_id));
            })
            .catch(() => {
            });
    }

    const fetchRSS = async () => {
        const payload = {
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/rss/get_feeds", payload, {
                headers: headers,
            })
            .then((response) => {
                setActiveRSS(response.data);
            })
            .catch(() => {
            });
    }

    useEffect(() => {
        fetchRSS();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="card flex-1">
            <div className="title">{t('active_RSS')}</div>
            {activeRSS && activeRSS.length > 0 ?
                activeRSS.map((rssData, i) => {
                    return (
                        <div key={rssData._id} ref={ref => { rssRef.current[i] = ref; }} className='draft_post'>
                            {showDraftModal[i] ? <DraftModal index={i} displayModal={displayModal} modalAbsolutePosition={modalAbsolutePosition} publicationData={rssData} editPost={editRSS} deleteRSS={deleteRSS} /> : ''}
                            <div className='account_header'>
                                {checkAccountPhoto(rssData, true)}
                                <div className="profile_text">
                                    <span className="acc_name">{rssData.social_accounts.length > 0 ? rssData.social_accounts[0].social_name : ''}</span>
                                    {/* <p className="p-no-margin p-mini">{draft.date ? moment(draft.date).format('Do MMM YY - HH.mm') : ''}</p> */}
                                </div>
                                <div className="dots_container">
                                    <div className="dots" onClick={() => displayModal(rssData, i)}>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="current" d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='draft-view-caption' dangerouslySetInnerHTML={{ __html: formatFeed(rssData) }}></div>
                            {/* <div className='draft_event_accounts'>
                                {rssData.social_accounts && rssData.social_accounts.length > 0 ?
                                    rssData.social_accounts.map(account => {
                                        return (
                                            checkAccountPhoto(account)
                                        )
                                    })
                                    : ''}
                            </div> */}
                        </div>
                    )
                })
                :
                <div className='row centered'>
                    <p className='text-grey'>{t('create_rss')}</p>
                </div>
            }
        </div>
    )
}

export default RSSList