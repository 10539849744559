import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useAuth } from "../../auth";
import { useTranslation } from "react-i18next";
import StateModal from '../Loader/StateModal';
import UpgradeModal from '../Plans/UpgradeModal';

import FBAnalytics from './AnalyticsComponents/FBAnalytics';
import PNAnalytics from './AnalyticsComponents/PNAnalytics';
import LKAnalytics from './AnalyticsComponents/LKAnalytics';
import TWAnalytics from './AnalyticsComponents/TWAnalytics';


import FBicon from "../../images/social_media/facebook.svg";
import TWicon from "../../images/social_media/twitter.svg";
import LKicon from "../../images/social_media/linkedin.svg";
import IGicon from "../../images/social_media/instagram.svg";
import PNicon from "../../images/social_media/pinterest.svg";
import GMBicon from "../../images/social_media/gmb.svg";
import SPKNoProfile from "../../images/social_media/SPK-no-profile.png"

import BlurAccount from "../../images/blur_account.png"


const AnalyticsPerAccount = (props) => {

    const { authTokens } = useAuth();
    const headers = {
        Authorization: authTokens.token,
    };
    const { t } = useTranslation(['analytics_simple']);
    // const headerDateFormat = "MMMM YYYY";

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false,
            button_text: "",
            button_link: ""
        })
    }
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);



    const [loader, setLoader] = useState(false);
    const [allInsights, setAllInsights] = useState({});
    const [insightsForNetwork, setInsightsForNetwork] = useState();

    const fetchAccountAnalytics = async () => {
        setLoader(true);
        setInsightsForNetwork(selectedAccount.account.media);
        setAllInsights({});

        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id,
            account_data: selectedAccount.account
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/analytics/get_account_full_analytics", payload, {
                headers: headers,
            })
            .then((response) => {
                setAllInsights(response.data);
            })
            .catch((error) => {
                console.log(error);

            });

        setLoader(false);
    }


    const [selectedAccount, setSelectedAccount] = useState();
    const displayAnalyticsModal = (accountData) => {

        let analytics_available = false;
        let message = '';

        switch (accountData.account.media) {
            case "Facebook":
                if (accountData.hasOwnProperty('main_insights') && accountData.main_insights.hasOwnProperty('likes') && accountData.main_insights.likes >= 100) {
                    analytics_available = true;
                }
                else {
                    message = t('not_enough_likes_100')
                }
                break;
            case "Instagram":
                if (accountData.hasOwnProperty('main_insights') && accountData.main_insights.hasOwnProperty('followers') && accountData.main_insights.followers >= 100) {
                    analytics_available = true;
                }
                else {
                    message = t('not_enough_followers_100')
                }
                break;
            case "Pinterest":
                analytics_available = true;
                break;
            case "LinkedIn":
                if (accountData.account.type === 'pro') {
                    analytics_available = true;
                }
                else {
                    message = t('no_analytics_perso_lk')
                }
                break;
            case "Twitter":
                analytics_available = true;
                break;
            default:
        }

        if (analytics_available) {
            setSelectedAccount(accountData);
        }
        else {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                status: "info",
                loader: false,
                message: message
            });
        }

    }

    const formatNumber = (number) => {
        if (number / 1000000 > 1) {
            return Number(number / 1000000).toFixed(1) + 'M'
        }
        else if (number / 1000 > 1) {
            return Number(number / 1000).toFixed(1) + 'K'
        }
        else return number
    }

    const checkAccountPhoto = (accountData, sm_icon) => {


        switch (accountData.media) {
            case "Facebook":
                const fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={fb_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={FBicon} alt="Media" /> : ""}
                    </div>
                )
            case "Twitter":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={TWicon} alt="Media" /> : ""}
                    </div>
                )
            case "LinkedIn":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={LKicon} alt="Media" /> : ""}
                    </div>
                )
            case "Instagram":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={IGicon} alt="Media" /> : ""}
                    </div>
                )
            case "Pinterest":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={PNicon} alt="Media" /> : ""}
                    </div>
                )
            case "GMB":
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {sm_icon ? <img className="account_profile_media" src={GMBicon} alt="Media" /> : ""}
                    </div>
                )
            default:
                return (
                    <div className="account_profile_photo" >
                        <img className="profile_picture" src="https://graph.facebook.com/v11.0/103219298760786/picture" alt="Profile" />
                        {sm_icon ? <img className="account_profile_media" src={TWicon} alt="Media" /> : ""}
                    </div>
                )
        }
    }

    const [allAccounts, setAllAccounts] = useState([]);

    const fetchAccountsWithGlobalAnalytics = async () => {
        let authError = false;
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });

        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/analytics/get_accounts_main_analytics", payload, {
                headers: headers,
            })
            .then((response) => {
                setAllAccounts(response.data);
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401) {
                    closeStateModal();
                    props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
            });

        if (authError) return;
        closeStateModal();
    }

    useEffect(() => {
        if (authTokens.plan > 0) {
            fetchAccountsWithGlobalAnalytics();
        }
        else {
            setShowUpgradeModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedAccount) {
            fetchAccountAnalytics()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount]);


    return (
        <>
            {showUpgradeModal ?
                <div>
                    <img src={BlurAccount} alt="" />
                    <UpgradeModal upgrade={true} closeModal={() => setShowUpgradeModal(false)} />
                </div>
                :
                <div className='content_row_start'>
                    {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
                    <div className="inner_menu">
                        <div className="column_analytics_account">
                            {allAccounts && allAccounts.length > 0 ?
                                allAccounts.map((accountData, i) => {
                                    return (
                                        <div className={selectedAccount && selectedAccount.account._id === accountData.account._id ? 'draft_post selected ' : 'draft_post clickable'} key={i} onClick={() => displayAnalyticsModal(accountData)}>
                                            <div className='account_header'>
                                                {checkAccountPhoto(accountData.account, true)}
                                                <div className="profile_text">
                                                    <span className="acc_name">{accountData.account.social_name}</span>
                                                    <span className="pub_date">{t('click_to_see_more')}</span>
                                                </div>
                                            </div>
                                            <div className='post_analytics'>
                                                {accountData.hasOwnProperty('main_insights') && accountData.main_insights.hasOwnProperty('followers') ?
                                                    <div className="post_analytics_data">
                                                        <p className='p-small p-no-margin'>{t('followers')}</p>
                                                        <p className='p-small p-no-margin'>{formatNumber(accountData.main_insights.followers)}</p>
                                                    </div>
                                                    : ''}
                                                {accountData.hasOwnProperty('main_insights') && accountData.main_insights.hasOwnProperty('likes') ?
                                                    <div className="post_analytics_data">
                                                        <p className='p-small p-no-margin'>{t('likes')}</p>
                                                        <p className='p-small p-no-margin'>{formatNumber(accountData.main_insights.likes)}</p>
                                                    </div>
                                                    : ''}
                                                {accountData.hasOwnProperty('main_insights') && accountData.main_insights.hasOwnProperty('following') ?
                                                    <div className="post_analytics_data">
                                                        <p className='p-small p-no-margin'>{t('following')}</p>
                                                        <p className='p-small p-no-margin'>{formatNumber(accountData.main_insights.following)}</p>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                    )
                                })
                                : ''
                            }
                        </div>
                    </div>
                    <div className='row-100 row_hcenter'>
                        {selectedAccount ?
                            <div className='card'>
                                <div className='row'>
                                    <div className='account_header'>
                                        {checkAccountPhoto(selectedAccount.account, true)}
                                        <div className="profile_text">
                                            <span className="acc_name">{selectedAccount.account.social_name}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row-100 row_hcenter'>
                                    {loader ?
                                        <div className="lds-ring-loader"><div></div><div></div><div></div><div></div></div>
                                        : insightsForNetwork === 'Facebook' && Object.keys(allInsights).length !== 0 ? <FBAnalytics insights={allInsights} instagram={false} mainInsights={selectedAccount.main_insights} />
                                            : insightsForNetwork === 'Instagram' && Object.keys(allInsights).length !== 0 ? <FBAnalytics insights={allInsights} instagram={true} mainInsights={selectedAccount.main_insights} />
                                                : insightsForNetwork === 'Pinterest' && Object.keys(allInsights).length !== 0 ? <PNAnalytics insights={allInsights} mainInsights={selectedAccount.main_insights} />
                                                    : insightsForNetwork === 'LinkedIn' && Object.keys(allInsights).length !== 0 ? <LKAnalytics insights={allInsights} mainInsights={selectedAccount.main_insights} />
                                                        : insightsForNetwork === 'Twitter' && Object.keys(allInsights).length !== 0 ? <TWAnalytics insights={allInsights} mainInsights={selectedAccount.main_insights} />
                                                            : ''
                                    }
                                </div>
                            </div>
                            : ''}
                    </div>
                </div>
            }
        </>
    )
}

export default AnalyticsPerAccount