import React, { useState } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";


import "../../styles/pricing.css"


const OnboardingCoPlans = ({ authTokens, setAuthTokens, stateModalOptions, setStateModalOptions, closeStateModal, onboardingResponses, setOnboardingResponses, goToFreePlan }) => {
    const { t, i18n } = useTranslation(['onboarding_cesam']);

    const headers = {
        Authorization: authTokens.token,
    };

    const [yearlyBilling, setYearlyBilling] = useState(true);

    const handleYearlyBilling = (e) => {
        if (e.target.checked) {
            setYearlyBilling(true);
        }
        else {
            setYearlyBilling(false);
        }
    }

    const [selectedCurrency, setSelectedCurrency] = useState('usd');

    const updateCurrency = (currency) => {
        setSelectedCurrency(currency);

        setAuthTokens({
            ...authTokens,
            currency: currency,
        });
    }

    const checkCurrencySymbol = (currency) => {

        let currencySymbol = "";
        switch (currency) {
            case "eur":
                currencySymbol = "€"
                break;
            case "usd":
                currencySymbol = "$"
                break;
            case "chf":
                currencySymbol = "Fr"
                break;
            case "gbp":
                currencySymbol = "£"
                break;
            default:
                currencySymbol = ""
        }
        return currencySymbol;
    }

    const goToCheckout = async (plan) => {

        const productToBill = {
            currency: selectedCurrency || "usd",
            currencySymbol: checkCurrencySymbol(selectedCurrency || "usd"),
            name: authTokens.initials + " " + authTokens.user_name,
            email: authTokens.user_email,
            company_id: authTokens.account_id,
            billing_frequency_factor: yearlyBilling ? 1 : 0,
            language: i18n.language,
            qty: Math.floor(Number(onboardingResponses.range) + 1),
            plan: plan
        }

        //STRIPE CHECKOUT
        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true,
            message: t('redirecting_to_stripe')
        });
        //CHECKOUT
        await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/payment/stripe_checkout_cesam_onboarding", productToBill, {
            headers: headers,
        })
            .then((response) => {
                window.location.href = response.data.stripe_url;
            })
            .catch(error => {
                closeStateModal();
            });
    }


    return (
        <div className="onboarding_plans">
            <div className='column'>
                <div className="card_onboarding">
                    <div className='pricing_grid margin-t1'>
                        <div className='pricing_grid_row options'>

                            <div className="billing_options flex-1">
                                <div className={selectedCurrency === 'usd' ? 'onbording_currency_button selected' : 'onbording_currency_button'} onClick={() => updateCurrency('usd')}>$</div>
                                <div className={selectedCurrency === 'eur' ? 'onbording_currency_button selected' : 'onbording_currency_button'} onClick={() => updateCurrency('eur')}>€</div>
                                <div className={selectedCurrency === 'gbp' ? 'onbording_currency_button selected' : 'onbording_currency_button'} onClick={() => updateCurrency('gbp')}>£</div>
                                <div className={selectedCurrency === 'chf' ? 'onbording_currency_button selected' : 'onbording_currency_button'} onClick={() => updateCurrency('chf')}>CHF</div>
                            </div>
                            <div className="billing_options flex-1">
                                <div className="onoffswitch_draft">
                                    <input type="checkbox" className="onoffswitch_draft-checkbox" id="onoffSwitchUpcoming" checked={yearlyBilling} onChange={(e) => handleYearlyBilling(e)} />
                                    <label className="onoffswitch_draft-label" htmlFor="onoffSwitchUpcoming">
                                        <span className="onoffswitch_draft-inner"></span>
                                        <span className="onoffswitch_draft-switch"></span>
                                    </label>
                                </div>
                                {/* <div className="onoffswitch margin-s1">
                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" tabIndex="0" onChange={handleYearlyBilling} checked={yearlyBilling} />
                                    <label className="onoffswitch-label" htmlFor="myonoffswitch">
                                        <span className="onoffswitch-inner"></span>
                                        <span className="onoffswitch-switch"></span>
                                    </label>
                                </div> */}
                                <p className="column margin-l1">{t('yearly_billing')}<span className="discount">{t('discount')}</span></p>
                            </div>
                        </div>
                        <div className='pricing_grid_row margin-t4'>
                            <div className='pricing_grid_description_block '></div>
                            <div className='flex-1 pricing_grid_data_header'>
                                <div className='pricing_header'>
                                    {onboardingResponses.plan === 'organic' ?
                                        <div className='reco_mention'>{t('recommended')}</div>
                                        : ''}
                                    <div className='pricing_header_plan_name'>Organic</div>
                                    <div className='pricing_header_price'><span className="currency">{checkCurrencySymbol(selectedCurrency)}</span>{yearlyBilling ? 9 + Number(onboardingResponses.range) * 10 : Math.floor((9 + Number(onboardingResponses.range) * 10) * 1.2).toFixed()}<span className='currency'>{yearlyBilling ? '' : '.80'}</span></div>
                                    <div className='pricing_header_period'>{t('per_month')}</div>
                                </div>
                                {yearlyBilling ? <p className='p-small text-grey text-italic'>{checkCurrencySymbol(selectedCurrency)} {Number(9 + Number(onboardingResponses.range) * 10) * 12} {t('per_year')}</p> : ''}
                                <div className="action_button margin-t1" onClick={() => goToCheckout('organic')}>
                                    {t('select_organic')}
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z" />
                                    </svg>
                                </div>
                                <p className='p-small text-bold text-red'>{t('7_days_free_trial')}</p>
                            </div>
                            <div className='flex-1 pricing_grid_data_header'>
                                <div className='pricing_header'>
                                    {onboardingResponses.plan === 'growth' ?
                                        <div className='reco_mention'>{t('recommended')}</div>
                                        : ''}
                                    <div className='pricing_header_plan_name'>Growth</div>
                                    <div className='pricing_header_price'><span className="currency">{checkCurrencySymbol(selectedCurrency)}</span>{yearlyBilling ? 9 + Number(Number(onboardingResponses.range) + 1) * 20 : Math.floor((9 + Number(Number(onboardingResponses.range) + 1) * 20) * 1.2).toFixed()}<span className='currency'>{yearlyBilling ? '' : '.80'}</span></div>
                                    <div className='pricing_header_period'>{t('per_month')}</div>
                                </div>
                                {yearlyBilling ? <p className='p-small text-grey text-italic'>{checkCurrencySymbol(selectedCurrency)} {Number(9 + Number(Number(onboardingResponses.range) + 1) * 20) * 12} {t('per_year')}</p> : ''}
                                <div className="action_button margin-t1" onClick={() => goToCheckout('growth')}>
                                    {t('select_growth')}
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z" />
                                    </svg>
                                </div>
                                <p className='p-small text-bold text-red'>{t('7_days_free_trial')}</p>
                            </div>

                            {/* <div className='flex-1 pricing_grid_data_header'>
                                <div className='pricing_header recommended'>
                                    <div className='pricing_header_limited'>{t('ending_jan_23')}</div>
                                    <div className='pricing_header_plan_name'>Lifetime</div>
                                    <div className='pricing_header_price'><span className="currency">{checkCurrencySymbol(selectedCurrency)}</span>999</div>
                                    <div className='pricing_header_period'>{t('once')}</div>
                                </div>
                                {yearlyBilling ? <p className='p-small text-grey text-italic'>{t('charged_once')}</p> : ''}
                                <div className="action_button margin-t1" onClick={() => goToCheckout('lifetime')}>
                                    {t('select_lifetime')}
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z" />
                                    </svg>
                                </div>
                                <p className='p-small text-bold text-red'>-</p>
                            </div> */}
                        </div>
                        <div className='pricing_grid_row'>
                            <div className='pricing_grid_description_block '></div>
                            <div className='flex-2 pricing_grid_data_header_slider'>
                                <p>{t('slide_customise')}</p>
                                <div className="slider">
                                    👉 <input style={{ width: "100%" }} type="range" min="0" max="10" value={onboardingResponses.range} onChange={e => setOnboardingResponses({ ...onboardingResponses, range: e.target.value })} />
                                </div>
                            </div>
                            <div className='flex-1 pricing_grid_data_header'></div>
                        </div>
                        <div className='pricing_grid_row margin-t2'>
                            <div className='pricing_grid_description_block'>{t('sm_accounts')}</div>
                            <div className='flex-1 pricing_grid_data main'>{Math.round(Number(onboardingResponses.range) + 1) * 5}</div>
                            <div className='flex-1 pricing_grid_data main'>{Math.round(Number(onboardingResponses.range) + 1) * 10}</div>
                            {/* <div className='flex-1 pricing_grid_data main'>&#8734;</div> */}
                        </div>
                        <div className='pricing_grid_row grey'>
                            <div className='pricing_grid_description_block'>{t('pub_month')}</div>
                            <div className='flex-1 pricing_grid_data main'>{Math.floor(50 + Number(onboardingResponses.range) * 150)}</div>
                            <div className='flex-1 pricing_grid_data main'>{Math.floor(50 + Number(onboardingResponses.range) * 150)}</div>
                            {/* <div className='flex-1 pricing_grid_data main'>&#8734;</div> */}
                        </div>
                        <div className='pricing_grid_row'>
                            <div className='pricing_grid_description_block'>{t('team')}</div>
                            <div className='flex-1 pricing_grid_data main'>-</div>
                            <div className='flex-1 pricing_grid_data main'>{Math.floor(Number(onboardingResponses.range) + 1)}</div>
                            {/* <div className='flex-1 pricing_grid_data main'>&#8734;</div> */}
                        </div>
                        <div className='pricing_grid_row grey'>
                            <div className='pricing_grid_description_block'>{t('brands')}</div>
                            <div className='flex-1 pricing_grid_data main'>2</div>
                            <div className='flex-1 pricing_grid_data main'>{Math.floor(2 + Number(onboardingResponses.range) * 2)}</div>
                            {/* <div className='flex-1 pricing_grid_data main'>&#8734;</div> */}
                        </div>
                        <div className='pricing_grid_row'>
                            <div className='pricing_grid_description_block'>{t('ai_credits_month')}</div>
                            <div className='flex-1 pricing_grid_data main'>2</div>
                            <div className='flex-1 pricing_grid_data main'>{Number(onboardingResponses.range) === 0 ? 10 : Math.floor(Number(onboardingResponses.range) * 25)}</div>
                            {/* <div className='flex-1 pricing_grid_data main'>1,000</div> */}
                        </div>
                        <div className='pricing_grid_row grey'>
                            <div className='pricing_grid_description_block'>{t('bulk_threads_rss')}</div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            {/* <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div> */}
                        </div>
                        <div className='pricing_grid_row'>
                            <div className='pricing_grid_description_block'>{t('video_post')}</div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            {/* <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div> */}
                        </div>
                        <div className='pricing_grid_row grey'>
                            <div className='pricing_grid_description_block'>{t('library')}</div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            {/* <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div> */}
                        </div>
                        <div className='pricing_grid_row'>
                            <div className='pricing_grid_description_block'>{t('canva_import')}</div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            {/* <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div> */}
                        </div>
                        <div className='pricing_grid_row grey'>
                            <div className='pricing_grid_description_block'>{t('tags_group')}</div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            {/* <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div> */}
                        </div>
                        <div className='pricing_grid_row'>
                            <div className='pricing_grid_description_block'>{t('tag_people_products')}</div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            {/* <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div> */}
                        </div>
                        <div className='pricing_grid_row grey'>
                            <div className='pricing_grid_description_block'>{t('ai_captions')}</div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            {/* <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div> */}
                        </div>
                        <div className='pricing_grid_row'>
                            <div className='pricing_grid_description_block'>{t('ai_posts_ads')}</div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            {/* <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div> */}
                        </div>
                        <div className='pricing_grid_row grey'>
                            <div className='pricing_grid_description_block'>{t('analytics')}</div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            {/* <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div> */}
                        </div>
                        <div className='pricing_grid_row'>
                            <div className='pricing_grid_description_block'>{t('feeds')}</div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            {/* <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div> */}
                        </div>
                        <div className='pricing_grid_row grey'>
                            <div className='pricing_grid_description_block'>{t('cal_view')}</div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            {/* <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div> */}
                        </div>
                        <div className='pricing_grid_row'>
                            <div className='pricing_grid_description_block'>{t('cal_sharing')}</div>
                            <div className='flex-1 pricing_grid_data main'>-</div>
                            <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div>
                            {/* <div className='flex-1 pricing_grid_data main'>
                                <svg viewBox="0 0 48 48">
                                    <path fill="current" d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
                                </svg>
                            </div> */}
                        </div>

                    </div>
                </div>
                <p className='text-underline text-white margin-t1'><a href="https://www.socialpika.com/demo" target="_blank" rel="noreferrer">{t('request_demo')}</a></p>
                <p className='text-underline text-white margin-t1 clickable' onClick={() => goToFreePlan()}>{t('select_basic_plan')}</p>
                <p className='text-underline text-white margin-t1'><a href="https://www.socialpika.com/pricing" target="_blank" rel="noreferrer">{t('see_pricing_details')}</a></p>

                {/* <div className='plan_buttons'>
                    <div className='free_trial_button' onClick={() => goToFreePlan()}>
                        {t('select_basic_plan')}
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                        </svg>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default OnboardingCoPlans