import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import TooltipHelp from '../../settings/TooltipHelp';
import TimezonesSelection from '../../settings/TimezonesSelection';


const PublishBurst = ({
    authTokens,
    displayUpgradeModal,
    editorMenuItem,
    publicationData,
    setPublicationData,
    recordPost
}) => {

    const { t } = useTranslation(['composer']);

    const handleInputChange = (e) => {
        setPublicationData({
            ...publicationData,
            [e.target.name]: e.target.value
        })
    }

    const [showTimeZones, setShowTimeZones] = useState(false);

    const displayTimeZones = () => {
        if (authTokens.plan > 0) {
            setShowTimeZones(!showTimeZones)
        }
        else {
            displayUpgradeModal();
        }
    }


    const handleTimeZone = (tzInput) => {
        setPublicationData({
            ...publicationData,
            timezone: tzInput
        });

    }

    const handleDateChange = (e) => {
        setPublicationData({
            ...publicationData,
            date: e.target.value
        })

    }

    const [showExpiry, setShowExpiry] = useState(false);
    const handleShowExpiry = (e) => {
        e.target.value !== "0" ? setShowExpiry(true) : setShowExpiry(false);
    }

    const toDatetimeLocal = (isoDate) => {
        if (!isoDate) return;
        let date = new Date(isoDate);

        const ten = (i) => {
            return (i < 10 ? '0' : '') + i;
        }

        const YYYY = date.getFullYear();
        const MM = ten(date.getMonth() + 1);
        const DD = ten(date.getDate());
        const HH = ten(date.getHours());
        const II = ten(date.getMinutes());

        return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II;
    }

    return (
        <div className={editorMenuItem === 'publication' ? 'editor_component active' : 'editor_component'}>
            <div className='composer_title margin-t2'>{t('post_scheduling')}</div>
            <div className="margin-t2">
                <label>{t('date_time_of_bulk_publication')}&nbsp;{showTimeZones ? '' : <span className="text-grey text-italic">({t('current_timezone')})</span>}</label>
                <div className='editor_input_block_dates'>
                    <input type="datetime-local" className='editor' name="date" min={new Date().toISOString().slice(0, -8)} value={toDatetimeLocal(publicationData.date)} onChange={handleDateChange} />
                </div>
            </div>
            <div className='row margin-t1'>
                <div className="onoffswitch_draft">
                    <input type="checkbox" className="onoffswitch_draft-checkbox" id="onoffSwitchTZ" checked={showTimeZones} onChange={() => displayTimeZones()} />
                    <label className="onoffswitch_draft-label" htmlFor="onoffSwitchTZ">
                        <span className="onoffswitch_draft-inner"></span>
                        <span className="onoffswitch_draft-switch"></span>
                    </label>
                </div>
                <label className='margin-l1'>{t('select_different_timezone')}</label>
            </div>
            {showTimeZones ?
                <TimezonesSelection
                    customClassName='margin-t1'
                    timezoneChanged={handleTimeZone}
                    currentTimeZone={publicationData.timezone}
                />
                : ''}
            <div className="margin-t2">
                <label>{t('time_between_bulk_post')}</label>
                <select className='editor' name="bulk_spread_period" onChange={e => handleInputChange(e)}>
                    <option value="6">{t('bulk_spread_6_hrs')}</option>
                    <option value="12">{t('bulk_spread_12_hrs')}</option>
                    <option value="24">{t('bulk_spread_1_day')}</option>
                    <option value="48">{t('bulk_spread_2_days')}</option>
                </select>
            </div>

            <div className="margin-t2">
                <label>{t('repeat_publication')}</label>
                <div className='row'>
                    <select className='editor' name="frequency" onChange={e => { handleInputChange(e); handleShowExpiry(e) }}>
                        <option value="0">{t('never')}</option>
                        <option value="2d">{t('every_2_days')}</option>
                        <option value="1">{t('every_week')}</option>
                        <option value="2">{t('every_2_weeks')}</option>
                        <option value="4">{t('every_4_weeks')}</option>
                        <option value="3m">{t('every_3_months')}</option>
                        <option value="6m">{t('every_6_months')}</option>
                        <option value="12m">{t('every_year')}</option>
                    </select>
                    {publicationData.frequency === "2d" ?
                        <TooltipHelp content={t('2d_warning')} direction="right">
                            <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                <path fill="current" d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
                            </svg>
                        </TooltipHelp>
                        : ""}
                </div>
            </div>
            <div className={showExpiry ? "form_row" : "form_row hidden"}>
                <label>{t('expires_after_x_pub')}</label>
                <div className="row_vcenter">
                    <select className='editor' name="repeat" onChange={handleInputChange}>
                        <option>-</option>
                        <option value="1">1 {t('other_publication')}</option>
                        <option value="2">2 {t('other_publications')}</option>
                        <option value="3">3 {t('other_publications')}</option>
                        <option value="4">4 {t('other_publications')}</option>
                        <option value="5">5 {t('other_publications')}</option>
                        <option value="6">6 {t('other_publications')}</option>
                        <option value="7">7 {t('other_publications')}</option>
                        <option value="8">8 {t('other_publications')}</option>
                        <option value="9">9 {t('other_publications')}</option>
                        <option value="10">10 {t('other_publications')}</option>
                        <option value="20">20 {t('other_publications')}</option>
                        <option value="30">30 {t('other_publications')}</option>
                    </select>

                </div>
            </div>
            <div className="row margin-t2">
                <div className="action_button" name="schedule" onClick={() => recordPost('schedule')}>
                    <svg viewBox="0 0 24 24">
                        <path fill="current" d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
                    </svg>
                    {t('schedule')}
                </div>
                <div className="action_button_outline margin-l1" name="draft" onClick={() => recordPost('draft')}>
                    <svg viewBox="0 0 48 48">
                        <path fill="current" d="m37.55 18.2-7.75-7.75 3.5-3.5q.85-.85 2.1-.85t2.1.85l3.55 3.55q.85.85.85 2.1t-.85 2.1ZM6 42v-7.8l9.7-9.7L4.65 13.4l8.7-8.8L24.5 15.75l5.3-5.3 7.75 7.75-5.3 5.3L43.4 34.65l-8.75 8.65-11.1-11.05L13.8 42Zm11.85-19.65 4.5-4.5-3.65-3.65-2.4 2.4-2.1-2.1 2.4-2.4-3.2-3.2-4.5 4.5Zm16.7 16.75 4.5-4.5-3.2-3.2-2.4 2.4-2.1-2.1 2.4-2.4-3.65-3.65-4.5 4.5ZM9 39h3.5l20.75-20.75-3.5-3.5L9 35.5Z" />
                    </svg>
                    {t('save_post_as_draft')}
                </div>
            </div>
        </div>
    )
}

export default PublishBurst