import React from 'react'

const LibraryMediaUnitMDF = ({ media, toggleSelectMedia }) => {

    const optimizedMediaURL = (url) => {
        if (url.includes("tr:")) {
            return url;
        }
        const url_options = "tr:n-media_library_thumbnail";
        // const url_options = "tr:n-ik_ml_thumbnail";
        // const url_options = "tr:h-150,pr-true";
        let arrayedURL = url.split("/");
        arrayedURL.splice(arrayedURL.length - 1, 0, url_options);
        const optimized_url = arrayedURL.join('/');
        return optimized_url;
    }

    return (
        <div className='library_picker_media_card' onClick={() => toggleSelectMedia(media)}>
            {media.used ?
                <div className="clipped">
                    <svg viewBox="0 0 24 24">
                        <path fill="current" d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z" />
                    </svg>
                </div>
                : ""}
            {media.type === "image" ?
                <img className="library_picker_media_img" src={optimizedMediaURL(media.file_url)} loading="lazy" alt={media.file_name} />
                :
                <div className="library_picker_media_video">
                    {media.thumbnail_url ?
                        <img src={optimizedMediaURL(media.thumbnail_url)} alt="" />
                        :
                        <video key={media._id} poster={media.thumbnail_url ? optimizedMediaURL(media.thumbnail_url) : ''}>
                            <source src={media.file_url} />
                        </video>
                    }
                    <div className="library_picker_video_overlay">
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                        </svg>
                    </div>
                </div>
            }
        </div>
    )
}

export default LibraryMediaUnitMDF