import React from 'react'
import moment from 'moment'

import CalendarThirdEvent from './CalendarThirdEvent';


const CalendarThird = ({ events, currentMonth, prevMonth, nextMonth, selectEvent }) => {

    const today = moment();

    const renderHeader = () => {
        const headerDateFormat = "MMMM YYYY";

        return (
            <div className="cal-header cal-row flex-middle">
                <div className="cal-header-nav">
                    <div className="cal-icon" onClick={() => prevMonth()}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                        </svg>
                    </div>
                    <span>{currentMonth.format(headerDateFormat)}</span>
                    <div className="cal-icon" onClick={() => nextMonth()}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                        </svg>
                    </div>
                </div>
            </div>
        )
    }

    const renderDays = () => {
        const dateFormat = "ddd";
        const days = [];

        // let startDate = moment(currentMonth).startOf('week');
        // console.log(startDate.format('DD MMM'));

        const monthStart = moment(currentMonth).startOf('month');
        let startDate = moment(monthStart).startOf('week');


        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="cal-col cal-col-start" key={i}>
                    {startDate.format(dateFormat)}
                </div>
            );
            startDate.add(1, 'days');
        }

        return <div className="cal-days cal-row">{days}</div>;
    }

    const renderCells = () => {
        const monthStart = moment(currentMonth).startOf('month');
        const monthEnd = moment(monthStart).endOf('month');
        const startDate = moment(monthStart).startOf('week');
        const endDate = moment(monthEnd).endOf('week');

        const dateFormat = "D";
        const rows = [];

        let days = [];
        let day = moment(startDate);
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = day.format(dateFormat);
                const dayIsToday = today.isSame(day, 'day');
                const dayIsFromMonth = day.isSame(monthStart, 'month');
                //Check event
                let eventsOfTheDay = [];
                events.forEach(event => {
                    if (moment(event.publication_date).isSame(day, 'day')) {
                        eventsOfTheDay.push(event);
                    }
                })

                days.push(
                    <div
                        className={`cal-col cal-cell ${dayIsToday
                            ? "cal-today"
                            : ""
                            } ${!dayIsFromMonth ? "cal-diff-month" : ""}`}
                        key={day}
                    >
                        <div className={`cal-number ${!dayIsFromMonth ? "cal-diff-month-number" : ""}`}>
                            {formattedDate}
                        </div>


                        {eventsOfTheDay.length > 0 ?
                            eventsOfTheDay.map((eventOfDay, i) => {
                                const eventClass = "cal-event cal-event-" + i;
                                return (
                                    <span key={day - i} className={eventClass} ><CalendarThirdEvent event={eventOfDay} selectEvent={selectEvent} hideEdit={true} /></span>
                                )
                            })
                            : ""}
                    </div >
                );
                day.add(1, 'days');
            }
            rows.push(
                <div className="cal-row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="cal-body">{rows}</div>;
    }


    return (
        <div>
            <div className="calendar">
                {renderHeader()}
                {renderDays()}
                {renderCells()}
            </div>
        </div>
    )
}

export default CalendarThird
