import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../auth";
import DataTable from 'react-data-table-component';
import Tooltip from '../../sidebar/Tooltip';


import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import PNicon from "../../../images/social_media/pinterest.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"
import TKicon from "../../../images/social_media/tiktok-spk.svg";
import YTicon from "../../../images/social_media/youtube.svg";
import FBGroupIcon from "../../../images/social_media/fb_group.png";

const ExpandableComponentSMAccountsGroup = (props) => {
    return (
        <div className="expandedRow">
            <div className="row">
                {props.SMAccounts.map((account, i) => {
                    return (
                        <Tooltip key={i} content={account.social_name} direction="top">
                            <div key={i} onClick={e => props.selectMediaAccountForGroup(account, props.data)}>
                                {props.checkAccountPhoto(account, props.data.sm_accounts)}
                            </div>
                        </Tooltip>
                    )
                }
                )}
            </div>
        </div>
    )
}

const SocialGroups = ({ SMAccounts, headers }) => {

    const { t } = useTranslation(['smlink']);
    const { authTokens } = useAuth();


    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    const [accountGroups, setAccountGroups] = useState([]);
    const [groupName, setGroupName] = useState('')
    const handleGroupName = (e) => {
        setGroupName(e.target.value);
    }

    const checkAccountPhoto = (accountData, groupSMAccounts) => {
        let selected = false;
        let indexFound = groupSMAccounts.findIndex((media_id) => media_id._id === accountData._id);

        if (indexFound !== -1) {
            selected = true;
        }

        switch (accountData.media) {
            case "Facebook":
                let fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
                if(accountData.fb_group){
                    fb_picture_url=FBGroupIcon
                }
                return (
                    <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                        <img className="profile_picture" src={fb_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media" src={FBicon} alt="Media" />
                    </div>)
            case "Twitter":
                return (
                    <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media" src={TWicon} alt="Media" />
                    </div>)
            case "LinkedIn":
                return (
                    <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media" src={LKicon} alt="Media" />
                    </div>)
            case "Instagram":
                return (
                    <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media" src={IGicon} alt="Media" />
                    </div>)
            case "Pinterest":
                return (
                    <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media" src={PNicon} alt="Media" />
                    </div>)
            case "GMB":
                return (
                    <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media" src={GMBicon} alt="Media" />
                    </div>)
            case "TikTok":
                return (
                    <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media" src={TKicon} alt="Media" />
                    </div>)
            case "Youtube":
                return (
                    <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                        <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        <img className="account_profile_media" src={YTicon} alt="Media" />
                    </div>)
            default:
                return (
                    <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
                        <img className="profile_picture" src={SPKNoProfile} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                        {/* <img className="account_profile_media" src={TWicon} alt="Media" /> */}
                    </div>)
        }
    }

    const addGroup = () => {

        if (!groupName) {
            return;
        }

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });


        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            name: groupName,
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/add_accounts_group", payload, {
                headers: headers,
            })
            .then((response) => {
                setAccountGroups([
                    ...accountGroups,
                    response.data.groups
                ]);
                setGroupName('');
            })
            .catch(() => {
            });


        closeStateModal();
    }

    const selectMediaAccountForGroup = (account, group) => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });
        //Select all groups
        let updatedAllGroups = [...accountGroups];
        //Select the group
        let indexGroup = accountGroups.findIndex((initalgroup) => initalgroup._id === group._id);

        //check within that group if account already exist.
        let indexSpecialGroup = updatedAllGroups[indexGroup].sm_accounts.findIndex(sm_account => sm_account._id === account._id);
        if (indexSpecialGroup === -1) {
            updatedAllGroups[indexGroup].sm_accounts.push(account);
        }
        else {
            const updated_sm_accounts = updatedAllGroups[indexGroup].sm_accounts.filter(sm_account => sm_account._id !== account._id);
            updatedAllGroups[indexGroup].sm_accounts = updated_sm_accounts;
        }

        // AXIOS UPDATE GROUP DOCUMENT WITH NEW ASSOCIATED ACCOUNTS
        const payload = updatedAllGroups[indexGroup];

        axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/update_accounts_group", payload, {
                headers: headers,
            })
            .then(() => {
            })
            .catch(() => {
            });

        setAccountGroups(updatedAllGroups);

        closeStateModal();
    }

    const deleteGroup = (groupId) => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });


        // NEW FUNCTION - TEAM COMPLIANT

        const payload = {
            _id: groupId,
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }
        axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/delete_accounts_group", payload, {
                headers: headers,
            })
            .then(() => {
                setAccountGroups(accountGroups.filter(item => item._id !== groupId));

            })
            .catch(() => {
            });


        closeStateModal();
    }

    const SocialGroupDeleteButtonCell = ({ row, deleteGroup }) => {
        return (
            <div>
                <svg viewBox="0 0 24 24" className="social_delete_button" onClick={e => deleteGroup(row._id)}>
                    <path fill="current" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                </svg>
            </div>
        )
    }

    const GroupCols = [
        {
            // name: 'Group name',
            // selector: 'name',
            // sortable: true,
            cell: row => `${row.name} (${row.sm_accounts.length})`,
        },
        {
            cell: row => <SocialGroupDeleteButtonCell row={row} deleteGroup={deleteGroup} />,
            allowOverflow: true,
            button: true,
            width: '56px',
        }
        // {
        //     ignoreRowClick: true,
        //     cell: (row) => (
        //         <div className="cell_svg">
        //             <svg viewBox="0 0 24 24" className="svg-red" onClick={e => deleteGroup(row._id)}>
        //                 <path fill="current" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
        //             </svg>
        //         </div>
        //     )
        // }
    ]

    const fetchCompanyGroups = async () => {

        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true
        });


        // NEW FUNCTION - TEAM COMPLIANT
        const payload = {
            company_id: authTokens.account_id,
            user_id: authTokens.user_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_account/get_social_media_account_groups", payload, {
                headers: headers,
            })
            .then((response) => {
                setAccountGroups(response.data.sm_groups);

            })
            .catch(() => {
            });

        closeStateModal();
    }

    useEffect(() => {
        fetchCompanyGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <span className="table_search_bar">
                <input type="text" name="group_name" className="dashboard_input" placeholder={t('new_group')} value={groupName} onChange={handleGroupName} />
                <span className="table_add_item">
                    <svg viewBox="0 0 24 24" onClick={addGroup}>
                        <path fill="current" d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                    </svg>
                </span>
            </span>
            {(accountGroups && accountGroups.length > 0) ?
                <>
                    <p className='text-grey text-italic'>{t('click_arrow_expand_click_account_assign')}</p>

                    <DataTable
                        columns={GroupCols}
                        data={accountGroups}
                        noHeader={true}
                        fixedHeader={true}
                        noTableHead={true}
                        expandableRows
                        expandableRowsComponent={ExpandableComponentSMAccountsGroup}
                        expandableRowsComponentProps={{ SMAccounts: SMAccounts, checkAccountPhoto: checkAccountPhoto, selectMediaAccountForGroup: selectMediaAccountForGroup }}
                    />
                </>
                :
                <p className="text-grey text-bold">{t('add_account_groups')}</p>
            }
        </div>
    )
}

export default SocialGroups
