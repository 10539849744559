import React from 'react'
import { useTranslation } from "react-i18next";

const TeamTree = ({ userIdForLibrary, setTeamMoveDestination, teamMoveDestination, teamMoveFiles, team }) => {
    const { t } = useTranslation(['library']);
    return (
        <div>
            <ul className="folders-tree-content move-files">
                {(team && team.length > 1) ?
                    <>
                        {team.map(member => {
                            return (
                                member._id !== userIdForLibrary ?
                                    <li className={teamMoveDestination === member._id ? 'team-folder-data team-selected-for-move' : 'team-folder-data'} key={member._id} onClick={() => setTeamMoveDestination(member._id)}>{member.first_name + " " + member.name}</li>
                                    : ''
                            )
                        })}
                        <li className={teamMoveDestination === 'shared' ? 'team-folder-data team-selected-for-move' : 'team-folder-data'} onClick={() => setTeamMoveDestination('shared')}><p className='p-small'>{t('team_shared')}</p></li>
                    </>
                    : ''
                }
            </ul>
            {teamMoveDestination ?
                <div className='green_button margin-t2' onClick={() => teamMoveFiles()}>{t('send')}</div>
                : ""}
        </div>
    )
}

export default TeamTree