import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAuth } from '../../../auth';

import LanguagePicker from '../../settings/LanguagePicker';

const ComposerCoAICaption = ({ publicationData, setPublicationData }) => {
    const { authTokens } = useAuth();
    const { setAuthTokens } = useAuth();
    const { t } = useTranslation(['composer']);

    const headers = {
        Authorization: authTokens.token,
    };

    const [aiCaptionData, setAiCaptionData] = useState({
        company_id: authTokens.account_id,
        description: '',
        target: '',
        language: 'english',
        isSubmitting: false,
        description_error: false,
        max_credits: false
    });

    const [aiCaptions, setAiCaptions] = useState([]);

    const handleLanguageChange = (language) => {
        setAiCaptionData({
            ...aiCaptionData,
            language: language
        })
    }


    const generateCaptions = async () => {
        if (aiCaptionData.isSubmitting) return;

        console.log('submitting');

        if (aiCaptionData.description.length < 10) {
            setAiCaptionData({
                ...aiCaptionData,
                description_error: true
            })
        }
        else {
            setAiCaptionData({
                ...aiCaptionData,
                isSubmitting: true,
                description_error: false,
                max_credits: false
            })
        }

        await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/content_ai/generate_captions", aiCaptionData, {
            headers: headers,
        })
            .then((response) => {
                const initial_credits = authTokens.credits_used;
                setAiCaptions(response.data);
                setAuthTokens({
                    ...authTokens,
                    credits_used: initial_credits + 1
                })

            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setAiCaptionData({
                        ...aiCaptionData,
                        max_credits: true
                    })
                }
            });

    }


    return (
        <div>
            <div className='composer_title'>
                {t('ai_caption_generator')}
            </div>
            {aiCaptions && aiCaptions.length > 0 ?
                aiCaptions.map((caption, index) => {
                    return (
                        <div key={index}>
                            <label className='checkbox-container margin-t1' onClick={() => setPublicationData({ ...publicationData, text: caption.text })}>{t('click_to_select_caption')}</label>
                            <p className='text-grey text-italic'>{caption.text}</p>
                        </div>
                    )
                })
                :
                <div>
                    <div className='column_left margin-t1'>
                        <LanguagePicker handleLanguageChange={handleLanguageChange} />
                    </div>
                    <div className='column_left margin-t1'>
                        <label>{t('description_caption_ai')}<sup>*</sup></label>
                        <div className='form_row_textarea_caption_ai'>
                            <textarea className='caption_ai' placeholder={t('placeholder_description_caption_ai')} value={aiCaptionData.description} onChange={e => setAiCaptionData({ ...aiCaptionData, description: e.target.value })} ></textarea>
                        </div>
                        {aiCaptionData.description_error ?
                            <p className='p-small text-red'>{t('description_too_short')}</p>
                            : ''}
                    </div>
                    <div className='column_left margin-t2'>
                        <label>{t('target_optional_caption_ai')}</label>
                        <div className='form_row_textarea_caption_ai'>
                            <textarea className='caption_ai' placeholder={t('placeholder_target_caption_ai')} value={aiCaptionData.target} onChange={e => setAiCaptionData({ ...aiCaptionData, target: e.target.value })} ></textarea>
                        </div>
                    </div>
                    <div className={aiCaptionData.max_credits ? 'action_button red margin-t2' : 'action_button margin-t2'} onClick={() => generateCaptions()}>
                        {aiCaptionData.isSubmitting ?
                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                            :
                            <>
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M15.9,18.45C17.25,18.45 18.35,17.35 18.35,16C18.35,14.65 17.25,13.55 15.9,13.55C14.54,13.55 13.45,14.65 13.45,16C13.45,17.35 14.54,18.45 15.9,18.45M21.1,16.68L22.58,17.84C22.71,17.95 22.75,18.13 22.66,18.29L21.26,20.71C21.17,20.86 21,20.92 20.83,20.86L19.09,20.16C18.73,20.44 18.33,20.67 17.91,20.85L17.64,22.7C17.62,22.87 17.47,23 17.3,23H14.5C14.32,23 14.18,22.87 14.15,22.7L13.89,20.85C13.46,20.67 13.07,20.44 12.71,20.16L10.96,20.86C10.81,20.92 10.62,20.86 10.54,20.71L9.14,18.29C9.05,18.13 9.09,17.95 9.22,17.84L10.7,16.68L10.65,16L10.7,15.31L9.22,14.16C9.09,14.05 9.05,13.86 9.14,13.71L10.54,11.29C10.62,11.13 10.81,11.07 10.96,11.13L12.71,11.84C13.07,11.56 13.46,11.32 13.89,11.15L14.15,9.29C14.18,9.13 14.32,9 14.5,9H17.3C17.47,9 17.62,9.13 17.64,9.29L17.91,11.15C18.33,11.32 18.73,11.56 19.09,11.84L20.83,11.13C21,11.07 21.17,11.13 21.26,11.29L22.66,13.71C22.75,13.86 22.71,14.05 22.58,14.16L21.1,15.31L21.15,16L21.1,16.68M6.69,8.07C7.56,8.07 8.26,7.37 8.26,6.5C8.26,5.63 7.56,4.92 6.69,4.92A1.58,1.58 0 0,0 5.11,6.5C5.11,7.37 5.82,8.07 6.69,8.07M10.03,6.94L11,7.68C11.07,7.75 11.09,7.87 11.03,7.97L10.13,9.53C10.08,9.63 9.96,9.67 9.86,9.63L8.74,9.18L8,9.62L7.81,10.81C7.79,10.92 7.7,11 7.59,11H5.79C5.67,11 5.58,10.92 5.56,10.81L5.4,9.62L4.64,9.18L3.5,9.63C3.41,9.67 3.3,9.63 3.24,9.53L2.34,7.97C2.28,7.87 2.31,7.75 2.39,7.68L3.34,6.94L3.31,6.5L3.34,6.06L2.39,5.32C2.31,5.25 2.28,5.13 2.34,5.03L3.24,3.47C3.3,3.37 3.41,3.33 3.5,3.37L4.63,3.82L5.4,3.38L5.56,2.19C5.58,2.08 5.67,2 5.79,2H7.59C7.7,2 7.79,2.08 7.81,2.19L8,3.38L8.74,3.82L9.86,3.37C9.96,3.33 10.08,3.37 10.13,3.47L11.03,5.03C11.09,5.13 11.07,5.25 11,5.32L10.03,6.06L10.06,6.5L10.03,6.94Z" />
                                </svg>
                                {aiCaptionData.max_credits ? t('max_credits_reached') : t('generate_captions') + ' (1 ' + t('credit') + ')'}
                            </>
                        }

                    </div>
                </div>
            }
        </div>
    )
}

export default ComposerCoAICaption