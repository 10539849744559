import React from 'react'
import moment from 'moment'
import { useTranslation } from "react-i18next";

import CalendarEvent from './CalendarEvent'


import './calendar.css';
import './cal_events.scss';


const Calendar = ({
    events,
    currentMonth,
    prevMonth,
    nextMonth,
    goToCurrentMonth,
    selectEvent,
    newPost
}) => {

    const today = moment();
    const MarketCal = require('../../assets/marketing_dates_2023.json')
    const { i18n } = useTranslation(['calendar']);


    const renderHeader = () => {
        const headerDateFormat = "MMMM YYYY";

        return (
            <div className="cal-header flex-middle">
                <div className="cal-header-nav">
                    <div className="cal-icon" onClick={() => prevMonth()}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                        </svg>
                    </div>
                    <span>{currentMonth.format(headerDateFormat)}</span>
                    <div className="cal-icon" onClick={() => nextMonth()}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                        </svg>
                    </div>
                </div>


            </div>
        )
    }

    const renderDays = () => {
        const dateFormat = "ddd";
        const days = [];

        // let startDate = moment(currentMonth).startOf('week');
        // console.log(startDate.format('DD MMM'));

        const monthStart = moment(currentMonth).startOf('month');
        let startDate = moment(monthStart).startOf('week');


        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="cal-col cal-col-start" key={i}>
                    {startDate.format(dateFormat)}
                </div>
            );
            startDate.add(1, 'days');
        }

        return <div className="cal-days cal-row">{days}</div>;
    }

    const renderCells = () => {
        const monthStart = moment(currentMonth).startOf('month');
        const monthEnd = moment(monthStart).endOf('month');
        const startDate = moment(monthStart).startOf('week');
        const endDate = moment(monthEnd).endOf('week');

        const dateFormat = "D";
        const rows = [];

        let days = [];
        let day = moment(startDate);
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                const this_day = moment(day);
                formattedDate = day.format(dateFormat);
                const dayIsToday = today.isSame(day, 'day');
                const dayIsFromMonth = day.isSame(monthStart, 'month');
                //Check event
                let eventsOfTheDay = [];
                events.forEach(event => {
                    // if (moment(event.publication_date).isSame(day, 'day')) {
                    if (moment(event.publication_date).format('DDMMYYYY') === moment(day).format('DDMMYYYY')) {
                        eventsOfTheDay.push(event);
                    }
                })

                let special_day_event = [];
                MarketCal.forEach(special_day => {
                    if (moment(special_day.date).format('DDMMYYYY') === moment(day).format('DDMMYYYY')) {
                        if (i18n.language === 'fr' && special_day.title_fr) {
                            special_day_event.push(special_day.title_fr);
                        }
                        else if (i18n.language === 'en' && special_day.title) {
                            special_day_event.push(special_day.title);
                        }
                    }
                })

                days.push(
                    <div
                        className={`cal-col cal-cell ${dayIsToday
                            ? "cal-today"
                            : ""
                            } ${!dayIsFromMonth ? "cal-diff-month" : ""}`}
                        key={day}
                    >
                        <div className={`cal-number ${!dayIsFromMonth ? "cal-diff-month-number" : ""}`}>
                            {formattedDate}
                            <div className='cal-header-buttons'>
                                {moment().diff(moment(this_day), 'days') <= 0 ?
                                    <div className='new-post-button' onClick={() => newPost(this_day)}>
                                        <svg viewBox="0 0 48 48">
                                            <path fill="current" d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h19.7v3H9v30h30V19.3h3V39q0 1.2-.9 2.1-.9.9-2.1.9Zm7.05-7.85v-3H32v3Zm0-6.35v-3H32v3Zm0-6.35v-3H32v3ZM34.6 17.8v-4.4h-4.4v-3h4.4V6h3v4.4H42v3h-4.4v4.4Z" />
                                        </svg>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                        {
                            special_day_event.length > 0 ?
                                special_day_event.map((event, e) => {
                                    return (
                                        <p key={`special_day_${e}`} className='text-grey p-small'>{event}</p>
                                    )
                                })
                                : ''
                        }

                        {eventsOfTheDay.length > 0 ?
                            eventsOfTheDay.map((eventOfDay, i) => {
                                let eventClass = "cal-event cal-event-" + i;
                                if (eventOfDay.draft) {
                                    eventClass = "cal-event draft-event cal-event-" + i;
                                }
                                return (
                                    <span key={day - i} className={eventClass} ><CalendarEvent event={eventOfDay} selectEvent={selectEvent} /></span>
                                )
                            })
                            : ""}
                    </div >
                );
                day.add(1, 'days');
            }
            rows.push(
                <div className="cal-row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="cal-body">{rows}</div>;
    }


    return (
        <div>
            <div className="calendar">
                {renderHeader()}
                {renderDays()}
                {renderCells()}
            </div>
        </div>
    )
}

export default Calendar
