import React from 'react'
import Plans from './Plans'

const PlansManagement = ({ triggerHeaderReload }) => {


    return (
        <div className="content_row_maxed">
            <div className="card">
                <Plans triggerHeaderReload={triggerHeaderReload} />
            </div>
        </div>
    )
}

export default PlansManagement