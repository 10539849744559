import React, { useState, useEffect } from 'react'
import Chart from "react-apexcharts";
import moment from 'moment'



const LKAnalytics = ({ insights, mainInsights }) => {

    const [followersData, setFollowersData] = useState([]);
    const [reachData, setReachData] = useState([]);

    const [followersOptions, setFollowersOptions] = useState({
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            }
        },
        colors: ['#19A4FE', '#EB316F', '#617183'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        title: {
            text: '',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {},
        yaxis: {
            labels: {
                formatter: function (val, index) {
                    return val.toFixed(0);
                }
            }
        }
    })


    const [reachOptions, setReachOptions] = useState({
        chart: {
            type: 'bar',
            height: 400
        },
        colors: ['#19A4FE', '#EB316F', '#617183'],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '88%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        title: {
            text: ''
        },
        xaxis: {
            tickAmount: 15
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        }
    })


    const loadData = () => {
        console.log("insights here");
        console.log(insights);
        // FOLLOWERS
        let followers_categories = [];
        insights.followers_evolution.map(data => (
            followers_categories.push(moment(data.timeRange.start).format('DD/MM'))
        ))

        setFollowersOptions({
            ...followersOptions,
            xaxis: {
                tickAmount: 15,
                categories: followers_categories
            }
        })
        // followersOptions.xaxis.categories = followers_categories;

        let followers_data = [];
        insights.followers_evolution.forEach(data => {
            followers_data.push(Number(data.followerGains.organicFollowerGain + data.followerGains.paidFollowerGain + mainInsights.followers))
        })
        setFollowersData([{
            name: "Followers",
            data: followers_data
        }]);



        // REACH
        let reach_categories = [];
        insights.insights.map(data => (
            reach_categories.push(moment(data.timeRange.start).format('DD/MM'))
        ))
        setReachOptions({
            ...reachOptions,
            xaxis: {
                categories: reach_categories
            }
        })
        // reachOptions.xaxis.categories = reach_categories;

        let reach_data = [];
        insights.insights.map(data => (
            reach_data.push(data.totalShareStatistics.uniqueImpressionsCount)
        ))
        let impressions_data = [];
        insights.insights.map(data => (
            impressions_data.push(data.totalShareStatistics.impressionCount)
        ))
        let engagement_data = [];
        insights.insights.map(data => (
            engagement_data.push(data.totalShareStatistics.shareCount + data.totalShareStatistics.clickCount + data.totalShareStatistics.likeCount + data.totalShareStatistics.commentCount)
        ))
        setReachData([{
            name: "Impressions",
            data: impressions_data
        }, {
            name: "Reach",
            data: reach_data
        }, {
            name: "Engagement",
            data: engagement_data
        }])

    }

    useEffect(() => {
        if (insights) { loadData() }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='row-100 row_hcenter margin-t2'>
            <div className='column_left flex-1'>
                <h2>Followers</h2>
                <Chart
                    options={followersOptions}
                    series={followersData}
                    type='area'
                    height='300px'
                    width='420px'
                />
            </div>
            <div className='column_left margin-l2 flex-2'>
                <h2>Insights</h2>
                <Chart
                    options={reachOptions}
                    series={reachData}
                    type='bar'
                    height='300px'
                    width='500px'
                />
            </div>
        </div>
    )
}

export default LKAnalytics