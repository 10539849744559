import React from 'react';
import axios from "axios";
import DataTable from 'react-data-table-component';
import { useTranslation } from "react-i18next";
import Tooltip from '../../sidebar/Tooltip';

import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import PNicon from "../../../images/social_media/pinterest.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import TKTKicon from "../../../images/social_media/tiktok-spk.svg";
import YTicon from "../../../images/social_media/youtube.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"


const ExpandableComponentSMAccountsGroup = (props) => {
  return (
    <div className="expandedRow">
      <div className="column_left">
        <p className='text-grey'>{props.t('assigned_accounts')}:</p>
        <div className="row margin-t1">
          {props.SMAccounts.map((account, i) => {
            return (
              <Tooltip key={i} content={account.social_name} direction="top">
                <div onClick={e => props.selectMediaAccountForThirdParty(account, props.data)}>
                  {props.checkAccountPhoto(account, props.data.sm_accounts)}
                </div>
              </Tooltip>
            )
          }
          )}
        </div>
      </div>
    </div>
  )
}

const ThirdParties = ({ headers, authTokens, isAdmin, thirdParties, setThirdParties, fetchThirdParties, SMAccounts, setStateModalOptions, stateModalOptions, closeStateModal }) => {

  const { t } = useTranslation(['third_party']);


  const selectMediaAccountForThirdParty = async (account, thisParty) => {

    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true
    });
    //Select all groups
    let updatedParties = [...thirdParties];
    //Select the group
    let indexParty = thirdParties.findIndex((party) => party._id === thisParty._id);

    //check within that group if account already exist.
    let indexSpecialGroup = updatedParties[indexParty].sm_accounts.findIndex(sm_account => sm_account._id === account._id);
    if (indexSpecialGroup === -1) {
      updatedParties[indexParty].sm_accounts.push(account);
    }
    else {
      const updated_sm_accounts = updatedParties[indexParty].sm_accounts.filter(sm_account => sm_account._id !== account._id);
      updatedParties[indexParty].sm_accounts = updated_sm_accounts;
    }

    // AXIOS UPDATE GROUP DOCUMENT WITH NEW ASSOCIATED ACCOUNTS
    const payload = updatedParties[indexParty];

    await axios
      .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/third_party/update_accounts_third_party", payload, {
        headers: headers,
      })
      .then(() => {
      })
      .catch(() => {
      });

    setThirdParties(updatedParties);

    closeStateModal();
  }

  const unlinkAccountConfirmation = async (data, confirmation) => {

    if (!confirmation) {
      closeStateModal();
      return;
    }

    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true
    });

    const payload = {
      _id: data.party_id,
      company_id: authTokens.account_id,
      admin_id: authTokens.user_id
    }

    await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/third_party/remove_third_party/", payload, {
      headers: headers,
    }).then(() => {
      setThirdParties(thirdParties.filter((account) => account._id !== data.party_id));
      fetchThirdParties();
    })
      .catch((error) => {
        console.log(error);
      });

    closeStateModal();

  }

  const unlinkAccountModal = (party_id) => {

    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: false,
      status: "alert",
      message: t('warning_third_party_delete'),
      choice: true,
      choice_data: {
        button_action_1_title: t('remove_third'),
        button_action_1_style: "action_button red",
        button_action_2_title: t('keep_third'),
        button_action_2_style: "action_button",
      },
      callBackFn: unlinkAccountConfirmation,
      dataForCallback: {
        party_id: party_id
      }
    });
  }



  const PartiesDeleteButtonCell = ({ row, unlinkAccountModal }) => {
    return (
      <div>
        <Tooltip content={t('delete_third')} direction="left">
          <svg viewBox="0 0 24 24" className="social_delete_button" onClick={() => unlinkAccountModal(row._id)}>
            <path fill="current" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
          </svg>
        </Tooltip>
      </div>
    )
  }


  const PartyCols = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: row => {
        return row.name + ' (' + row.email + ')'
      }
    },
    {
      cell: row => <PartiesDeleteButtonCell row={row} unlinkAccountModal={unlinkAccountModal} />,
      allowOverflow: true,
      omit: !isAdmin,
      button: true,
      width: '56px',
    }
  ]



  const checkAccountPhoto = (accountData, partiesSMAccounts) => {
    let selected = false;
    let indexFound = partiesSMAccounts.findIndex((media) => media._id === accountData._id);

    if (indexFound !== -1) {
      selected = true;
    }

    switch (accountData.media) {
      case "Facebook":
        const fb_picture_url = "https://graph.facebook.com/v11.0/" + accountData.social_id + "/picture";
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={fb_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={FBicon} alt="Media" />
          </div>)
      case "Twitter":
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={TWicon} alt="Media" />
          </div>)
      case "LinkedIn":
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={LKicon} alt="Media" />
          </div>)
      case "Instagram":
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={IGicon} alt="Media" />
          </div>)
      case "Pinterest":
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={PNicon} alt="Media" />
          </div>)
      case "GMB":
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={GMBicon} alt="Media" />
          </div>)
      case "TikTok":
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={TKTKicon} alt="Media" />
          </div>)
      case "Youtube":
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={accountData.profile_picture_url} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            <img className="account_profile_media" src={YTicon} alt="Media" />
          </div>)
      default:
        return (
          <div className={selected ? "account_profile_photo selected" : "account_profile_photo unselected"}>
            <img className="profile_picture" src={SPKNoProfile} alt="Profile" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
          </div>)
    }
  }


  return (
    <div className='card card_max_width'>
      {(thirdParties && thirdParties.length > 0) ?
        <div className='composer_component_thread'>
          <div className="title">
            {t('third_parties')}
          </div>
          {isAdmin ?
            <>
              <p className='text-grey text-italic'>{t('click_arrow_expand')}</p>

              <DataTable
                columns={PartyCols}
                data={thirdParties}
                noHeader={true}
                noTableHead={true}
                expandableRows
                expandableRowsComponent={ExpandableComponentSMAccountsGroup}
                expandableRowsComponentProps={{ SMAccounts: SMAccounts, checkAccountPhoto: checkAccountPhoto, selectMediaAccountForThirdParty: selectMediaAccountForThirdParty, t: t }}
              />
            </>
            :
            <DataTable
              columns={PartyCols}
              data={thirdParties}
              noHeader={true}
              noTableHead={true}
            />
          }
        </div>

        :
        <p className="text-grey text-bold">{t('third_party_intro')}</p>

      }
    </div>
  )
}

export default ThirdParties