import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import TooltipHelp from "../../settings/TooltipHelp";
import Tooltip from "../../sidebar/Tooltip";

import ImageTagger from "../../library/ImageTagger";
import ImageProductTagger from "../../library/ImageProductTagger";
import GMBeventCreator from "../../Composer/ComposerComponents/GMBeventCreator";
import OptionsLKPoll from "./OptionsLKPoll";

import FBicon from "../../../images/social_media/facebook.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import TKTKicon from "../../../images/social_media/tiktok-spk.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import YTicon from "../../../images/social_media/youtube.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png";
import FBGroupIcon from "../../../images/social_media/fb_group.png";

const allowed_first_comment_media = ["Instagram", "Facebook", "LinkedIn"];

// const app_id = process.env.REACT_APP_FACEBOOK_SOCIALPIKA_APP_ID;
const MIN_TEXTAREA_HEIGHT = 60;
// let keystrokeTimer;

// const py_test_account = process.env.REACT_APP_PY_TEST_ACCOUNT
// const testy_test_account = process.env.REACT_APP_TESTY_TEST_ACCOUNT
// const py_test_account_for_fb = process.env.REACT_APP_TEST_ACCOUNT_PY_FOR_FB

const Options = ({
  authTokens,
  editorMenuItem,
  displayUpgradeModal,
  displayReauthModal,
  publicationData,
  setPublicationData,
  socialPlatforms,
  stateModalOptions,
  setStateModalOptions,
  closeStateModal,
}) => {
  const { t } = useTranslation(["composer"]);

  const headers = {
    Authorization: authTokens.token,
  };

  const textareaCommentRef = useRef(null);
  const textareaYTDescRef = useRef(null);
  const IGbutton = useRef(null);

  const selectAccountFirstComment = (account) => {
    let indexFound = publicationData.first_comment_accounts.findIndex(
      (acc) => acc.social_id === account.social_id
    );

    let updatedAccountsFirstComment = [];
    if (indexFound !== -1) {
      updatedAccountsFirstComment =
        publicationData.first_comment_accounts.filter(
          (item) => item.social_id !== account.social_id
        );
    } else {
      updatedAccountsFirstComment = [
        ...publicationData.first_comment_accounts,
        account,
      ];
    }

    setPublicationData({
      ...publicationData,
      first_comment_accounts: updatedAccountsFirstComment,
    });
  };

  const checkAccountPhoto = (accountData) => {
    if (!accountData) return;

    const selectedMediaAccount = publicationData.first_comment_accounts;
    let selected = false;
    let indexFound = selectedMediaAccount.findIndex(
      (media) => media.social_id === accountData.social_id
    );

    if (indexFound !== -1) {
      selected = true;
    }

    switch (accountData.media) {
      case "Facebook":
        let fb_picture_url =
          "https://graph.facebook.com/v11.0/" +
          accountData.social_id +
          "/picture";
        if (accountData.fb_group) {
          fb_picture_url = FBGroupIcon;
        }

        return (
          <Tooltip content={accountData.social_name} direction="bottom">
            <div
              className={
                selected
                  ? "account_profile_photo selected"
                  : "account_profile_photo unselected"
              }
            >
              <img
                className="profile_picture"
                src={fb_picture_url}
                alt={accountData.social_name}
                onError={(e) => {
                  e.target.src = SPKNoProfile;
                  e.onerror = null;
                }}
              />
              <img className="account_profile_media" src={FBicon} alt="Media" />
            </div>
          </Tooltip>
        );
      case "Twitter":
        return (
          <Tooltip content={accountData.social_name} direction="bottom">
            <div
              className={
                selected
                  ? "account_profile_photo selected"
                  : "account_profile_photo unselected"
              }
            >
              <img
                className="profile_picture"
                src={accountData.profile_picture_url}
                alt="Profile"
                onError={(e) => {
                  e.target.src = SPKNoProfile;
                  e.onerror = null;
                }}
              />
              <img className="account_profile_media" src={TWicon} alt="Media" />
            </div>
          </Tooltip>
        );
      case "LinkedIn":
        return (
          <Tooltip content={accountData.social_name} direction="bottom">
            <div
              className={
                selected
                  ? "account_profile_photo selected"
                  : "account_profile_photo unselected"
              }
            >
              <img
                className="profile_picture"
                src={accountData.profile_picture_url}
                alt="Profile"
                onError={(e) => {
                  e.target.src = SPKNoProfile;
                  e.onerror = null;
                }}
              />
              <img className="account_profile_media" src={LKicon} alt="Media" />
            </div>
          </Tooltip>
        );
      case "Instagram":
        return (
          <Tooltip content={accountData.social_name} direction="bottom">
            <div
              className={
                selected
                  ? "account_profile_photo selected"
                  : "account_profile_photo unselected"
              }
            >
              <img
                className="profile_picture"
                src={accountData.profile_picture_url}
                alt="Profile"
                onError={(e) => {
                  e.target.src = SPKNoProfile;
                  e.onerror = null;
                }}
              />
              <img className="account_profile_media" src={IGicon} alt="Media" />
            </div>
          </Tooltip>
        );
      case "GMB":
        return (
          <Tooltip content={accountData.social_name} direction="bottom">
            <div
              className={
                selected
                  ? "account_profile_photo selected"
                  : "account_profile_photo unselected"
              }
            >
              <img
                className="profile_picture"
                src={accountData.profile_picture_url}
                alt="Profile"
                onError={(e) => {
                  e.target.src = SPKNoProfile;
                  e.onerror = null;
                }}
              />
              <img
                className="account_profile_media"
                src={GMBicon}
                alt="Media"
              />
            </div>
          </Tooltip>
        );
      case "TikTok":
        return (
          <Tooltip content={accountData.social_name} direction="bottom">
            <div
              className={
                selected
                  ? "account_profile_photo selected"
                  : "account_profile_photo unselected"
              }
            >
              <img
                className="profile_picture"
                src={accountData.profile_picture_url}
                alt="Profile"
                onError={(e) => {
                  e.target.src = SPKNoProfile;
                  e.onerror = null;
                }}
              />
              <img
                className="account_profile_media"
                src={TKTKicon}
                alt="Media"
              />
            </div>
          </Tooltip>
        );
      case "Youtube":
        return (
          <Tooltip content={accountData.social_name} direction="bottom">
            <div
              className={
                selected
                  ? "account_profile_photo selected"
                  : "account_profile_photo unselected"
              }
            >
              <img
                className="profile_picture"
                src={accountData.profile_picture_url}
                alt="Profile"
                onError={(e) => {
                  e.target.src = SPKNoProfile;
                  e.onerror = null;
                }}
              />
              <img className="account_profile_media" src={YTicon} alt="Media" />
            </div>
          </Tooltip>
        );
      default:
        return (
          <Tooltip content={accountData.social_name} direction="bottom">
            <div
              className={
                selected
                  ? "account_profile_photo selected"
                  : "account_profile_photo unselected"
              }
            >
              <img
                className="profile_picture"
                src="https://graph.facebook.com/v11.0/103219298760786/picture"
                alt="Profile"
                onError={(e) => {
                  e.target.src = SPKNoProfile;
                  e.onerror = null;
                }}
              />
              <img className="account_profile_media" src={TWicon} alt="Media" />
            </div>
          </Tooltip>
        );
    }
  };

  const [showTagger, setShowTagger] = useState(false);
  const displayTagger = () => {
    setShowTagger(!showTagger);
  };

  const [showProductTagger, setShowProductTagger] = useState(false);
  const displayProductTagger = () => {
    setShowProductTagger(!showProductTagger);
  };

  // const [showCoTagger, setShowCoTagger] = useState(false);
  // const displayCoTagger = () => {
  //     setShowCoTagger(!showCoTagger);
  // }

  const displayLKPoll = () => {
    if (authTokens.plan > 0) {
      setPublicationData({
        ...publicationData,
        lk_poll: {
          ...publicationData.lk_poll,
          display: !publicationData.lk_poll.display,
        },
      });
    } else {
      displayUpgradeModal();
    }
  };

  const [showGMBeventCreator, setShowGMBeventCreator] = useState(false);
  const displayGMBcreator = () => {
    setShowGMBeventCreator(!showGMBeventCreator);
  };

  const IGcallback = (data, confirmation) => {
    if (!confirmation) {
      closeStateModal();
      return;
    }

    IGbutton.current.click();
    closeStateModal();
  };

  const displayIGXtraPermissionsModal = () => {
    setStateModalOptions({
      display: true,
      loader: false,
      status: "info",
      message: t("ig_permission"),
      choice: true,
      choice_data: {
        button_action_1_title: t("give_ig_permissions"),
        button_action_1_style: "green_button",
      },
      callBackFn: IGcallback,
      dataForCallback: {},
    });

    displayProductTagger();
  };

  // const handleCheckbox = (e) => {
  //     setPublicationData({
  //         ...publicationData,
  //         [e.target.name]: e.target.checked
  //     })
  // }

  // NA FOR SHORTS WHICH CATEGORY_ID=42
  // const [ytCategories, setYtCategories] = useState([]);

  // const getYTCategories = async () => {
  //     const payload = {
  //         company_id: authTokens.account_id
  //     }

  //     await axios
  //         .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/get_yt_categories", payload, {
  //             headers: headers,
  //         })
  //         .then((response) => {
  //             console.log(response.data.items);
  //             setYtCategories(response.data.items);
  //         })
  //         .catch(() => {
  //         });
  // }

  const [lkTags, setLkTags] = useState([]);
  // const [lookupData, setLookupData] = useState([]);
  // const [showTagSelector, setShowTagSelector] = useState(false);
  // const [tagInput, setTagInput] = useState('');

  // const tagAccount = (account) => {
  //     if (account.network === "LinkedIn") {
  //         const updated_lk_tags_array = [...lkTags, account];
  //         setShowTagSelector(false);
  //         setLookupData([]);

  //         setPublicationData({
  //             ...publicationData,
  //             mentions_LinkedIn: updated_lk_tags_array
  //         });

  //         setLkTags(updated_lk_tags_array);
  //         setTagInput('');
  //     }
  // }

  const untagAccount = (account) => {
    if (account.network === "LinkedIn") {
      const updated_lk_tags_array = lkTags.filter(
        (tagged_account) => tagged_account.tag !== account.tag
      );

      setPublicationData({
        ...publicationData,
        mentions_LinkedIn: updated_lk_tags_array,
      });

      setLkTags(updated_lk_tags_array);
    }
  };

  // const tagLookup = (tag) => {
  //     console.log("tagging called");

  //     const payload = {
  //         tag: tag,
  //         network: "LinkedIn",
  //         company_id: authTokens.account_id
  //     }
  //     axios
  //         .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/scraper/tag_lookup", payload, {
  //             headers: headers,
  //         })
  //         .then((response) => {
  //             if (response.data.lookup_result) {
  //                 setLookupData(response.data.lookup_data);
  //             }
  //             else {
  //                 setLookupData([]);
  //                 setShowTagSelector(false);
  //             }

  //         })
  //         .catch(() => {
  //             setLookupData([]);
  //             setShowTagSelector(false);
  //         });
  // }

  // const handleLKTags = async (e) => {
  //     const alt_text = e.target.value;
  //     setTagInput(e.target.value);

  //     const alt_text_words = alt_text.split(/\s+/);
  //     if (alt_text_words.length === 0) return;

  //     clearTimeout(keystrokeTimer);

  //     alt_text_words.forEach(tag => {
  //         if (tag.charAt(0) === "@" && !lkTags.some(e => e.tag === tag) && tag.length > 3) {
  //             keystrokeTimer = setTimeout(() => {
  //                 setShowTagSelector(true);
  //                 tagLookup(tag);
  //             }, 800);

  //         }
  //         else {
  //             setShowTagSelector(false);
  //         }
  //     })

  // }

  // const handleVideoTitle = (e) => {
  //     setPublicationData({
  //         ...publicationData,
  //         video_title: e.target.value
  //     })
  // }

  const handleFirstComment = (e) => {
    // CHECK FB IG REAUTH
    let need_reauth_fb_ig = false;
    if (
      publicationData.social_accounts &&
      publicationData.social_accounts.length > 0
    ) {
      publicationData.social_accounts.forEach((account) => {
        if (account.media === "Facebook" || account.media === "Instagram") {
          if (!account.fb_ig_comments_allowed) {
            need_reauth_fb_ig = true;
          }
        }
      });
    }

    if (need_reauth_fb_ig) {
      displayReauthModal();
      return;
    }

    if (authTokens.plan > 0) {
      setPublicationData({
        ...publicationData,
        first_comment: e.target.value,
      });
    } else {
      displayUpgradeModal();
    }
  };

  const getTikTokCreatorOptions = async (tiktokAccountsArray) => {
    const payload = {
      sm_accounts: tiktokAccountsArray,
    };

    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/sm_tools/get_tiktok_account_options",
        payload,
        {
          headers: headers,
        }
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch(() => {});
  };

  useLayoutEffect(() => {
    if (textareaCommentRef.current) {
      textareaCommentRef.current.style.height = "inherit";
      textareaCommentRef.current.style.height = `${Math.max(
        textareaCommentRef.current.scrollHeight + 10,
        MIN_TEXTAREA_HEIGHT
      )}px`;
    }
  }, [publicationData.first_comment]);

  useLayoutEffect(() => {
    if (textareaYTDescRef.current) {
      textareaYTDescRef.current.style.height = "inherit";
      textareaYTDescRef.current.style.height = `${Math.max(
        textareaYTDescRef.current.scrollHeight + 10,
        MIN_TEXTAREA_HEIGHT
      )}px`;
    }
  }, [publicationData.yt_description]);

  useEffect(() => {
    console.log("TIKTOK CHECK");

    if (
      publicationData.social_accounts &&
      publicationData.social_accounts.length > 0
    ) {
      const tiktokAccountsArray = [];

      publicationData.social_accounts.forEach((account) => {
        if (account.media === "TikTok") {
          tiktokAccountsArray.push(account);
        }
      });

      if (tiktokAccountsArray.length > 0) {
        getTikTokCreatorOptions(tiktokAccountsArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicationData]);

  // NA FOR SHORTS
  // useEffect(() => {
  //     if (socialPlatforms.includes('Youtube') && ytCategories.length === 0) {
  //         getYTCategories();
  //     }

  // }, [socialPlatforms])

  return (
    <>
      <div
        className={
          editorMenuItem === "options"
            ? "editor_component active"
            : "editor_component"
        }
      >
        {/* <div className="row row_vcenter margin-t2">
                    <div className="onoffswitch_draft">
                        <input type="checkbox" name="template" className="onoffswitch_draft-checkbox" id="myonoffswitchTemplate" checked={publicationData.template} onChange={(e) => handleCheckbox(e)} />
                        <label className="onoffswitch_draft-label" htmlFor="myonoffswitchTemplate">
                            <span className="onoffswitch_draft-inner"></span>
                            <span className="onoffswitch_draft-switch"></span>
                        </label>
                    </div>
                    <label className='margin-l1'>{t('save_as_template')}</label>
                    <TooltipHelp content={t('tooltip_template')} direction="bottom">
                        <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                            <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                        </svg>
                    </TooltipHelp>
                </div> */}
        {socialPlatforms.length === 0 ? (
          <p className="text-grey">{t("options_displayed")}</p>
        ) : (
          ""
        )}
        {socialPlatforms.includes("Instagram") ||
        socialPlatforms.includes("Facebook") ||
        socialPlatforms.includes("LinkedIn") ? (
          <div>
            <div className="editor_title margin-t1">
              {t("first_comment")}
              {authTokens.plan > 0 ? (
                ""
              ) : (
                <div className="lock_svg">
                  <svg viewBox="0 0 48 48">
                    <path
                      fill="current"
                      d="M11 44q-1.25 0-2.125-.875T8 41V19.3q0-1.25.875-2.125T11 16.3h3.5v-4.8q0-3.95 2.775-6.725Q20.05 2 24 2q3.95 0 6.725 2.775Q33.5 7.55 33.5 11.5v4.8H37q1.25 0 2.125.875T40 19.3V41q0 1.25-.875 2.125T37 44Zm0-3h26V19.3H11V41Zm13-7q1.6 0 2.725-1.1t1.125-2.65q0-1.5-1.125-2.725T24 26.3q-1.6 0-2.725 1.225T20.15 30.25q0 1.55 1.125 2.65Q22.4 34 24 34Zm-6.5-17.7h13v-4.8q0-2.7-1.9-4.6Q26.7 5 24 5q-2.7 0-4.6 1.9-1.9 1.9-1.9 4.6ZM11 41V19.3 41Z"
                    />
                  </svg>
                </div>
              )}
            </div>
            <div className="editor_textarea_block margin-t1">
              <textarea
                ref={textareaCommentRef}
                className="editor"
                placeholder={t("type_comment_here")}
                style={{ minHeight: MIN_TEXTAREA_HEIGHT, resize: "none" }}
                name="first_comment"
                value={publicationData.first_comment}
                onChange={(e) => handleFirstComment(e)}
              ></textarea>
            </div>
            <div className="editor_title margin-t2">
              {t("select_acc_first_comment")}
            </div>
            <div className="row margin-t1">
              {publicationData.social_accounts &&
              publicationData.social_accounts.length > 0
                ? publicationData.social_accounts.map((account, i) => {
                    return allowed_first_comment_media.includes(
                      account.media
                    ) ? (
                      <div
                        className="account_selection_account"
                        key={i}
                        onClick={() => selectAccountFirstComment(account)}
                      >
                        {checkAccountPhoto(account)}
                      </div>
                    ) : (
                      ""
                    );
                  })
                : ""}
            </div>
          </div>
        ) : (
          ""
        )}

        {socialPlatforms.includes("Instagram") &&
        publicationData.medias &&
        publicationData.medias.length > 0 ? (
          <>
            <div className="divider-100"></div>
            <div className="editor_title margin-t1">
              <img src={IGicon} alt="Instagram" />
              Instagram
            </div>
            {publicationData.medias[0].type === "image" ? (
              <>
                <div className="row margin-t2">
                  <TooltipHelp content={t("ig_tag_people")} direction="right">
                    <div className="round_button" onClick={displayTagger}>
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="current"
                          d="M12,15C12.81,15 13.5,14.7 14.11,14.11C14.7,13.5 15,12.81 15,12C15,11.19 14.7,10.5 14.11,9.89C13.5,9.3 12.81,9 12,9C11.19,9 10.5,9.3 9.89,9.89C9.3,10.5 9,11.19 9,12C9,12.81 9.3,13.5 9.89,14.11C10.5,14.7 11.19,15 12,15M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12V13.45C22,14.45 21.65,15.3 21,16C20.3,16.67 19.5,17 18.5,17C17.3,17 16.31,16.5 15.56,15.5C14.56,16.5 13.38,17 12,17C10.63,17 9.45,16.5 8.46,15.54C7.5,14.55 7,13.38 7,12C7,10.63 7.5,9.45 8.46,8.46C9.45,7.5 10.63,7 12,7C13.38,7 14.55,7.5 15.54,8.46C16.5,9.45 17,10.63 17,12V13.45C17,13.86 17.16,14.22 17.46,14.53C17.76,14.84 18.11,15 18.5,15C18.92,15 19.27,14.84 19.57,14.53C19.87,14.22 20,13.86 20,13.45V12C20,9.81 19.23,7.93 17.65,6.35C16.07,4.77 14.19,4 12,4C9.81,4 7.93,4.77 6.35,6.35C4.77,7.93 4,9.81 4,12C4,14.19 4.77,16.07 6.35,17.65C7.93,19.23 9.81,20 12,20H17V22H12C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z"
                        />
                      </svg>
                    </div>
                  </TooltipHelp>
                  <TooltipHelp content={t("ig_tag_products")} direction="right">
                    <div
                      className="round_button margin-l1"
                      onClick={displayProductTagger}
                    >
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="current"
                          d="M21.41 11.58L12.41 2.58A2 2 0 0 0 11 2H4A2 2 0 0 0 2 4V11A2 2 0 0 0 2.59 12.42L11.59 21.42A2 2 0 0 0 13 22A2 2 0 0 0 14.41 21.41L21.41 14.41A2 2 0 0 0 22 13A2 2 0 0 0 21.41 11.58M13 20L4 11V4H11L20 13M6.5 5A1.5 1.5 0 1 1 5 6.5A1.5 1.5 0 0 1 6.5 5Z"
                        />
                      </svg>
                    </div>
                  </TooltipHelp>
                </div>
              </>
            ) : (
              ""
            )}
            {publicationData.medias[0].type === "video" ? (
              <div className="row margin-t1">
                <div className="onoffswitch_draft">
                  <input
                    type="checkbox"
                    className="onoffswitch_draft-checkbox"
                    id="onoffSwitchIGReel"
                    checked={publicationData.reel}
                    onChange={() =>
                      setPublicationData({
                        ...publicationData,
                        reel: !publicationData.reel,
                      })
                    }
                  />
                  <label
                    className="onoffswitch_draft-label"
                    htmlFor="onoffSwitchIGReel"
                  >
                    <span className="onoffswitch_draft-inner"></span>
                    <span className="onoffswitch_draft-switch"></span>
                  </label>
                </div>
                <label className="margin-l1">{t("publish_as_reel")}</label>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {/* {socialPlatforms.includes('Facebook') && publicationData.medias && publicationData.medias.length > 0 && publicationData.medias[0].type === 'video' ?
                    <>
                        <div className="divider-100"></div>
                        <div className='editor_title margin-t1'>
                            <img src={FBicon} alt="Facebook" />Facebook</div>
                        <div className='row margin-t2'>
                            <div className="onoffswitch_draft">
                                <input type="checkbox" className="onoffswitch_draft-checkbox" id="onoffSwitchFBReel" checked={publicationData.fb_reel} onChange={() => setPublicationData({ ...publicationData, fb_reel: !publicationData.fb_reel })} />
                                <label className="onoffswitch_draft-label" htmlFor="onoffSwitchFBReel">
                                    <span className="onoffswitch_draft-inner"></span>
                                    <span className="onoffswitch_draft-switch"></span>
                                </label>
                            </div>
                            <label className='margin-l1'>{t('publish_as_reel')}</label>
                        </div>
                    </>
                    : ''} */}
        {/* {socialPlatforms.includes('Twitter') ?
                    <>
                        <div className="divider-100"></div>
                        <div className='editor_title margin-t1'>
                            <img src={TWicon} alt="Twitter" />Twitter</div>
                        <div className='row margin-t2'>
                            <div className="onoffswitch_draft">
                                <input type="checkbox" className="onoffswitch_draft-checkbox" id="onoffSwitchTWretweet" checked={publicationData.retweet} onChange={() => setPublicationData({ ...publicationData, retweet: !publicationData.retweet })} />
                                <label className="onoffswitch_draft-label" htmlFor="onoffSwitchTWretweet">
                                    <span className="onoffswitch_draft-inner"></span>
                                    <span className="onoffswitch_draft-switch"></span>
                                </label>
                            </div>
                            <label className='margin-l1'>{t('retweet_own_tweet')}</label>
                        </div>
                    </>
                    : ''} */}
        {/* {socialPlatforms.includes('LinkedIn') ?
                    <>
                        <div className="divider-100"></div>
                        <div className='editor_title margin-t1'>
                            <img src={LKicon} alt="LinkedIn" />LinkedIn</div>
                        <div className='row margin-t2'>
                            <TooltipHelp content={t('lk_tag_companies')} direction="right">
                                <div className='round_button' onClick={displayCoTagger}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M12,15C12.81,15 13.5,14.7 14.11,14.11C14.7,13.5 15,12.81 15,12C15,11.19 14.7,10.5 14.11,9.89C13.5,9.3 12.81,9 12,9C11.19,9 10.5,9.3 9.89,9.89C9.3,10.5 9,11.19 9,12C9,12.81 9.3,13.5 9.89,14.11C10.5,14.7 11.19,15 12,15M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12V13.45C22,14.45 21.65,15.3 21,16C20.3,16.67 19.5,17 18.5,17C17.3,17 16.31,16.5 15.56,15.5C14.56,16.5 13.38,17 12,17C10.63,17 9.45,16.5 8.46,15.54C7.5,14.55 7,13.38 7,12C7,10.63 7.5,9.45 8.46,8.46C9.45,7.5 10.63,7 12,7C13.38,7 14.55,7.5 15.54,8.46C16.5,9.45 17,10.63 17,12V13.45C17,13.86 17.16,14.22 17.46,14.53C17.76,14.84 18.11,15 18.5,15C18.92,15 19.27,14.84 19.57,14.53C19.87,14.22 20,13.86 20,13.45V12C20,9.81 19.23,7.93 17.65,6.35C16.07,4.77 14.19,4 12,4C9.81,4 7.93,4.77 6.35,6.35C4.77,7.93 4,9.81 4,12C4,14.19 4.77,16.07 6.35,17.65C7.93,19.23 9.81,20 12,20H17V22H12C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z" />
                                    </svg>
                                </div>
                            </TooltipHelp>

                        </div>
                        {showCoTagger ?
                            <div className='margin-t1 relative_holder'>
                                <label>{t('lk_co_tag_explainer')}</label>
                                <div className='editor_input_block_dates'>
                                    <input type="text" className='editor' placeholder='@company' value={tagInput} onChange={e => handleLKTags(e)} />
                                </div>
                                {showTagSelector ?
                                    <div className='tag_selector' style={{ top: "30px", left: "0px" }}>
                                        {lookupData && lookupData.length > 0 ?
                                            lookupData.map((account, a) => {
                                                return (
                                                    <div key={a} className='tag_account' onClick={() => tagAccount(account)}>
                                                        <div className='tag_account_profile'>
                                                            {account.profile_url.length > 0 ?
                                                                <img src={account.profile_url} alt="account to tag" />
                                                                :
                                                                ""
                                                            }
                                                        </div>
                                                        <div className='tag_account_name'>
                                                            {account.network_name}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="lds-ring-loader-small"><div></div><div></div><div></div><div></div></div>
                                        }
                                    </div>
                                    : ""}
                            </div>

                            : ''}
                        {publicationData.mentions_LinkedIn && publicationData.mentions_LinkedIn.length > 0 ?
                            <div className="mentions_list">
                                {publicationData.mentions_LinkedIn.map((account, i) => {
                                    return (
                                        <div className="mention_account" key={`lk_account_${i}`}>
                                            <div className='tag_account_profile'>
                                                {account.profile_url.length > 0 ?
                                                    <img src={account.profile_url} alt="account to tag" />
                                                    :
                                                    ""
                                                }
                                            </div>
                                            <div className='tag_account_name'>
                                                {account.network_name}
                                            </div>
                                            <div className='delete_mention' onClick={() => untagAccount(account)}>
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="current" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
                                                </svg>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>

                            : ""
                        }
                    </>
                    : ''} */}

        {socialPlatforms.includes("LinkedIn") && !publicationData.bulk ? (
          <>
            <div className="divider-100"></div>
            <div className="editor_title margin-t1">
              <img src={LKicon} alt="LinkedIn" />
              LinkedIn
            </div>
            <div className="row margin-t2">
              <TooltipHelp content={t("lk_create_poll")} direction="right">
                <div className="round_button" onClick={displayLKPoll}>
                  <svg viewBox="0 96 960 960">
                    <path
                      fill="current"
                      d="M160 896V456h140v440H160Zm250 0V256h140v640H410Zm250 0V616h140v280H660Z"
                    />
                  </svg>
                </div>
              </TooltipHelp>
            </div>
            {publicationData.lk_poll && publicationData.lk_poll.display ? (
              <OptionsLKPoll
                publicationData={publicationData}
                setPublicationData={setPublicationData}
              />
            ) : (
              ""
            )}
            {publicationData.mentions_LinkedIn &&
            publicationData.mentions_LinkedIn.length > 0 ? (
              <div className="mentions_list">
                {publicationData.mentions_LinkedIn.map((account, i) => {
                  return (
                    <div className="mention_account" key={`lk_account_${i}`}>
                      <div className="tag_account_profile">
                        {account.profile_url.length > 0 ? (
                          <img src={account.profile_url} alt="account to tag" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="tag_account_name">
                        {account.network_name}
                      </div>
                      <div
                        className="delete_mention"
                        onClick={() => untagAccount(account)}
                      >
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="current"
                            d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                          />
                        </svg>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {socialPlatforms.includes("GMB") ? (
          <>
            <div className="divider-100"></div>
            <div className="editor_title margin-t1">
              <img src={GMBicon} alt="Google My Business" />
              Google Business Profile
            </div>
            <div className="row margin-t2">
              <TooltipHelp content={t("gmb_specials")} direction="right">
                <div className="round_button" onClick={displayGMBcreator}>
                  <svg viewBox="0 0 48 48">
                    <path
                      fill="current"
                      d="M8.85 40v-3h4.2V26.8L2 10h25.1L16.05 26.8V37h4.2v3ZM10 17.3h9.1l2.75-4.3H7.25ZM31.75 40q-2.5 0-4.125-1.625T26 34.25q0-2.5 1.625-4.125T31.75 28.5q.75 0 1.425.15t1.325.4V10H44v4.95h-6.5v19.3q0 2.5-1.625 4.125T31.75 40Z"
                    />
                  </svg>
                </div>
              </TooltipHelp>
            </div>
          </>
        ) : (
          ""
        )}
        {socialPlatforms.includes("Youtube") ? (
          <>
            <div className="divider-100"></div>
            <div className="editor_title margin-t1">
              <img src={YTicon} alt="Google My Business" />
              Youtube
            </div>
            <label className="margin-t1">{t("yt_visibility")}</label>
            <select
              className="editor"
              name="yt_status"
              onChange={(e) =>
                setPublicationData({
                  ...publicationData,
                  yt_status: e.target.value,
                })
              }
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
              <option value="unlisted">Unlisted</option>
            </select>
            <div className="editor_textarea_block margin-t1">
              <textarea
                ref={textareaYTDescRef}
                className="editor"
                placeholder={t("yt_video_description_here")}
                style={{ minHeight: MIN_TEXTAREA_HEIGHT, resize: "none" }}
                name="yt_description"
                value={publicationData.yt_description}
                onChange={(e) =>
                  setPublicationData({
                    ...publicationData,
                    yt_description: e.target.value,
                  })
                }
              ></textarea>
            </div>
          </>
        ) : (
          ""
        )}
        {/* {socialPlatforms.includes('Youtube') && publicationData.medias && publicationData.medias.length > 0 && publicationData.medias[0].type === 'video' && ytCategories && ytCategories.length > 0 ?
                    <>
                        <div className="divider-100"></div>
                        <div className='editor_title margin-t1'>
                            <img src={YTicon} alt="YouTube" />Youtube</div>
                        <div className='margin-t1'>
                            <label>{t('yt_video_category')}</label>
                            <select className='editor' name="yt_category">
                                <option>Select a category for the video</option>
                                {ytCategories.map(category => {
                                    return (
                                        <option key={category.id} value={category.id}>{category.snippet.title}</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </>
                    : ''} */}
      </div>
      {showTagger ? (
        <ImageTagger
          displayTagger={displayTagger}
          authTokens={authTokens}
          publicationData={publicationData}
          setPublicationData={setPublicationData}
        />
      ) : (
        ""
      )}
      {showProductTagger ? (
        <ImageProductTagger
          displayProductTagger={displayProductTagger}
          authTokens={authTokens}
          publicationData={publicationData}
          setPublicationData={setPublicationData}
          displayIGXtraPermissionsModal={displayIGXtraPermissionsModal}
        />
      ) : (
        ""
      )}
      {showGMBeventCreator ? (
        <GMBeventCreator
          displayGMBcreator={displayGMBcreator}
          authTokens={authTokens}
          publicationData={publicationData}
          setPublicationData={setPublicationData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Options;
