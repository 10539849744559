import React from 'react'
import { useTranslation } from "react-i18next";

const CAICoSelectedTemplates = ({ templateData, selectedTemplates, setSelectedTemplates, selectedTemplatesIds, setSelectedTemplatesIds, selectedCaptions }) => {
    const { t } = useTranslation(['content_ai']);

    const hashtagsFormat = (hashtag) => {
        const formattedText = '<span class="classic_tags">' + hashtag + '</span>';
        return formattedText
    }

    const formatCaption = (text) => {
        let formatted_text = text;//`${text.toString()}`;
        const regex = /#\w*/g;
        let new_text = formatted_text.replace(regex, hashtagsFormat);


        // return formatted_text.replace(/\r\n|\r|\n/g, "<br />") + '<br />';
        return new_text;
    }

    const displayRandomCaption = (captions) => {
        if (captions && captions.length > 0) {
            // get random index value
            const randomIndex = Math.floor(Math.random() * captions.length);
            // get random item
            let item = formatCaption(captions[randomIndex].text);
            // console.log(item);
            return item;
        }
        else {
            return ''
        }
    }

    const deleteTemplate = (_id) => {
        setSelectedTemplates(selectedTemplates.filter(template => template._id !== _id));
        setSelectedTemplatesIds(selectedTemplatesIds.filter(element => element !== _id));
    }

    return (
        <div className="card_preview">
            <div className='composer_component_thread'>
                <div className="title">
                    {t('templates')}
                </div>
                <div className='composer_component_previews'>
                    {selectedTemplates.map((img_data) => {
                        return (
                            <div key={img_data._id} className={templateData.size === 'landscape' ? "selected_template_container_landscape" : "selected_template_container"} >
                                <div className="delete_template" onClick={() => deleteTemplate(img_data._id)}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="current" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
                                    </svg>
                                </div>
                                <img src={img_data.data} alt={img_data.template} />
                                {/* <p className="p-mini">{img_data.template}</p> */}
                                <div className="content_ai_caption" dangerouslySetInnerHTML={{ __html: displayRandomCaption(selectedCaptions) }}></div>
                                {/* <div className='row row_spaced margin-t1'>
                                                <div className='mini_button active'>
                                                    <svg viewBox="0 0 24 24">
                                                        <path fill="current" d="M3 3V21H21V3H3M18 18H6V17H18V18M18 16H6V15H18V16M18 12H6V6H18V12Z" />
                                                    </svg>
                                                    Create Post/Ad
                                                </div>
                                                <div className='mini_button active' onClick={() => saveToLibrary(img_data.data)}>
                                                    <svg viewBox="0 0 24 24">
                                                        <path fill="current" d="M22,16V4A2,2 0 0,0 20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16M11,12L13.03,14.71L16,11L20,16H8M2,6V20A2,2 0 0,0 4,22H18V20H4V6" />
                                                    </svg>
                                                    Add to Library
                                                </div>
                                                <div className='mini_button active' onClick={() => downloadFile(img_data.data)}>
                                                    <svg viewBox="0 0 24 24">
                                                        <path fill="current" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
                                                    </svg>
                                                    Download
                                                </div>
                                            </div> */}
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}

export default CAICoSelectedTemplates