import React from 'react'

import "./post_previews.css";
import YTicon from "../../../images/social_media/youtube.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"

import YTui from "../../../images/platforms_icons/yt-interface.png";


const PreviewYT = ({ publicationData, calSelectedEvent, selectedPhotos }) => {

    let yt_check = false;

    let yt_index;

    if (calSelectedEvent) {
        yt_index = publicationData.social_accounts.findIndex((account) => account._id.toString() === calSelectedEvent._id.toString());
    }
    else {
        yt_index = publicationData.social_accounts.findIndex((account) => account.media === "Youtube");
    }

    // const yt_index = publicationData.social_accounts.findIndex((account) => account.media === "Youtube");

    if (yt_index !== -1) {
        yt_check = true;
    }



    return (
        <div>
            {yt_check ?
                <div className={publicationData.draft ? "preview_card ig_preview preview_card_draft" : "preview_card ig_preview"}>
                    <div className="sm-badge-wrapper">
                        <img src={YTicon} alt="TikTok" />
                    </div>
                    <div className="ig_header">
                        <div className="header_block">
                            <img className="ig_icon" src={publicationData.social_accounts[yt_index].profile_picture_url} alt="" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                            <span className="ig_nickname tiktok">{publicationData.social_accounts[yt_index].social_name}</span>
                        </div>
                    </div>

                    {selectedPhotos.length > 0 && selectedPhotos[0].type === 'video' ?
                        <div className="media_wrapper">
                            {publicationData.bulk && selectedPhotos.length > publicationData.bulk_media_index ?
                                selectedPhotos[publicationData.bulk_media_index].type === "image" ?
                                    ''
                                    : selectedPhotos[publicationData.bulk_media_index].thumbnail_url ?
                                        <img key={selectedPhotos[publicationData.bulk_media_index]._id} src={selectedPhotos[publicationData.bulk_media_index].thumbnail_url} alt="" />
                                        :
                                        <video key={selectedPhotos[publicationData.bulk_media_index]._id}>
                                            <source src={selectedPhotos[publicationData.bulk_media_index].file_url} />
                                        </video>
                                :
                                selectedPhotos[0].type === "video" ?
                                    selectedPhotos[0].thumbnail_url ?
                                        <img key={selectedPhotos[0]._id} src={selectedPhotos[0].thumbnail_url} alt="" />
                                        :
                                        <video key={selectedPhotos[0]._id}>
                                            <source src={selectedPhotos[0].file_url} />
                                        </video>
                                    :
                                    ''
                            }
                            <div className='video_icon'>
                                <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 485 512.03">
                                    <path d="M33.31 211.27H485v262.14c0 21.23-17.39 38.62-38.61 38.62H68.75c-21.23 0-38.61-17.38-38.61-38.61V212.18L0 120.11l65.64-18.64 33.77 90.89-66.1 18.91zm105.94-30.31 78.91-22.58-33.69-90.65-75.87 21.54 30.65 91.69zm118.85-34 89.09-25.49-33.6-90.41-86.06 24.44 30.57 91.46zm128.95-36.89 66.07-18.91L422.99 0l-66.43 18.86 30.49 91.21zM89.8 262.66h335.54c3.07 0 5.59 2.56 5.59 5.59v186.8c0 3.03-2.56 5.59-5.59 5.59H89.8c-3.03 0-5.59-2.51-5.59-5.59v-186.8c0-3.08 2.52-5.59 5.59-5.59zm220.14 108.09c8.13-5.25 8.12-11.1 0-15.74l-83.98-48.27c-6.63-4.16-13.54-1.71-13.35 6.94l.27 97.56c.56 9.37 5.92 11.95 13.81 7.6l83.25-48.09zM67.51 242.85h380.12c3.07 0 5.58 2.54 5.58 5.59v226.42c0 3.05-2.54 5.59-5.58 5.59H67.51c-3.04 0-5.58-2.51-5.58-5.59V248.44c0-3.08 2.51-5.59 5.58-5.59z" />
                                </svg>
                            </div>
                            <div className="yt_video_social_name">
                                <img className="yt_ui_icons_profile" src={publicationData.social_accounts[yt_index].profile_picture_url} alt="" onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
                                {publicationData.social_accounts[yt_index].social_name}
                            </div>
                            <div className="yt_video_text">{publicationData.text}</div>
                        </div>
                        : ""
                    }
                    {selectedPhotos.length > 0 && selectedPhotos[0].type === 'video' ?
                        <div className='ui_icons_yt'>
                            <img className="ui_icons" src={YTui} alt="" />
                        </div>
                        : ''}
                </div>
                : ""
            }

        </div >
    )
}

export default PreviewYT
