import React, { useLayoutEffect, useRef } from 'react'
import { useTranslation } from "react-i18next";
import moment from 'moment'

import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png"

import PreviewFB from '../../Composer/PostPreviews/PreviewFB';
import PreviewIG from '../../Composer/PostPreviews/PreviewIG';
import PreviewTW from '../../Composer/PostPreviews/PreviewTW';
import PreviewLK from '../../Composer/PostPreviews/PreviewLK';
import PostPreviewPN from '../../Composer/PostPreviews/PostPreviewPN';
import PreviewTK from '../../Composer/PostPreviews/PreviewTK';
import PreviewYT from '../../Composer/PostPreviews/PreviewYT';

const MIN_TEXTAREA_HEIGHT = 30;


const EngageCoMsgAction = ({
    selectedMessage,
    reply,
    setReply,
    sendReplyToComment
}) => {

    const textareaRef = useRef(null);
    const { t, i18n } = useTranslation(['engage']);


    // const [showEmoji, setShowEmoji] = useState(false);
    // const displayEmoji = () => {
    //     setShowEmoji(!showEmoji);
    // }

    // const onEmojiClick = (emojiData) => {
    //     const cursorPosition = textareaRef.current.selectionStart;
    //     const new_text = reply.substring(0, cursorPosition) + emojiData.emoji + reply.substring(cursorPosition);
    //     setReply(new_text);
    // };

    const checkTimeAgo = (date) => {
        let now = moment();
        let comment_date = moment(date);
        let time_diff = now.diff(comment_date, 'minutes');

        if (time_diff < 1) {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${t('just_now')}`
        }
        else if (time_diff < 2) {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${time_diff} ${t('minute')} ${i18n.language === 'en' ? t('ago') : ''}`
        }
        else if (time_diff < 60) {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${time_diff} ${t('minutes')} ${i18n.language === 'en' ? t('ago') : ''}`
        }
        else if (time_diff < 120) {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${Math.floor(time_diff / 60).toFixed(0)} ${t('hour')} ${i18n.language === 'en' ? t('ago') : ''}`
        }
        else if (time_diff < 1440) {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${Math.floor(time_diff / 60).toFixed(0)} ${t('hours')} ${i18n.language === 'en' ? t('ago') : ''}`
        }
        else if (time_diff < 2880) {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${Math.floor(time_diff / 60 / 24).toFixed(0)} ${t('day')} ${i18n.language === 'en' ? t('ago') : ''}`
        }
        else {
            return `${i18n.language === 'fr' ? t('ago') : ''} ${Math.floor(time_diff / 60 / 24).toFixed(0)} ${t('days')} ${i18n.language === 'en' ? t('ago') : ''}`
        }
    }

    const checkProfilePic = (comment_data, account_data, gmb_reply) => {

        if (gmb_reply) {
            return <img className="msg_list_profile_picture" src={account_data.profile_picture_url ? account_data.profile_picture_url : SPKNoProfile} alt={account_data.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
        }
        else if (account_data.media !== 'Facebook') {
            if (comment_data.from_id === account_data.social_id) {
                return <img className="msg_list_profile_picture" src={account_data.profile_picture_url ? account_data.profile_picture_url : SPKNoProfile} alt={account_data.social_name} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            }
            else {
                return <img className="msg_list_profile_picture" src={comment_data.profile_url ? comment_data.profile_url : SPKNoProfile} alt={comment_data.from_handle} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            }
        }
        else {
            if (comment_data.from_id === account_data.social_id) {
                const profile_url = `https://graph.facebook.com/v16.0/${account_data.social_id}/picture`
                return <img className="msg_list_profile_picture" src={profile_url} alt={comment_data.from_handle} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            }
            else {
                const profile_url = `https://graph.facebook.com/v16.0/${comment_data.from_id}/picture?access_token=${account_data.access_token}`
                return <img className="msg_list_profile_picture" src={profile_url} alt={comment_data.from_handle} onError={e => { e.target.src = SPKNoProfile; e.onerror = null; }} />
            }
        }
    }

    const checkStarRating = (starRating) => {

        if (starRating === 'STAR_RATING_UNSPECIFIED') return '';

        let full_star_svg = '<svg class="gmb_star" viewBox="0 0 24 24"><path fill="current" d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" /></svg>'
        let empty_star_svg = '<svg class="gmb_star" viewBox="0 0 24 24"><path fill="current" d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" /></svg>'
        let half_full_star_svg = '<svg class="gmb_star" viewBox="0 0 24 24"><path fill="current" d="M12,15.4V6.1L13.71,10.13L18.09,10.5L14.77,13.39L15.76,17.67M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" /></svg>'

        let full_stars = 0;
        let half_stars = 0;
        let empty_stars = 0;

        switch (starRating) {
            case "FIVE":
                full_stars = 5;
                empty_stars = 5 - full_stars;
                break;

            case "FOUR":
                full_stars = 4;
                empty_stars = 5 - full_stars;
                break;

            case "THREE":
                full_stars = 3;
                empty_stars = 5 - full_stars;
                break;

            case "TWO":
                full_stars = 2;
                empty_stars = 5 - full_stars;
                break;

            case "ONE":
                full_stars = 1;
                empty_stars = 5 - full_stars;
                break;

            case "FOUR_HALF":
                full_stars = 3;
                half_stars = 1;
                empty_stars = 5 - full_stars - half_stars;
                break;
            default:
        }

        let rendering = '';
        for (let i = 0; i < full_stars; i++) {
            rendering += full_star_svg;
        }
        for (let i = 0; i < half_stars; i++) {
            rendering += half_full_star_svg;
        }
        for (let i = 0; i < empty_stars; i++) {
            rendering += empty_star_svg;
        }

        return rendering;
    }

    useLayoutEffect(() => {
        textareaRef.current.style.height = "inherit";
        textareaRef.current.style.height = `${Math.max(
            textareaRef.current.scrollHeight + 5,
            MIN_TEXTAREA_HEIGHT
        )}px`;

    }, [reply]);

    // useEffect(() => {
    //     console.log(selectedMessage);
    // }, [])

    return (
        <div className='msg_action_column'>
            <div className="msg_action_post">
                <div className='title'>{t('post')}</div>
                <div className='inbox_post_preview'>
                    {selectedMessage.post_data.sm_account.media === 'Facebook' ? <PreviewFB publicationData={selectedMessage.post_data.scheduled_post} selectedPhotos={selectedMessage.post_data.scheduled_post.bulk ? [selectedMessage.post_data.scheduled_post.medias[selectedMessage.post_data.bulk_media_index]] : selectedMessage.post_data.scheduled_post.medias} linkPreview={selectedMessage.post_data.scheduled_post.link_url} />
                        : selectedMessage.post_data.sm_account.media === 'Instagram' ? <PreviewIG publicationData={selectedMessage.post_data.scheduled_post} selectedPhotos={selectedMessage.post_data.scheduled_post.bulk ? [selectedMessage.post_data.scheduled_post.medias[selectedMessage.post_data.bulk_media_index]] : selectedMessage.post_data.scheduled_post.medias} linkPreview={selectedMessage.post_data.scheduled_post.link_url} />
                            : selectedMessage.post_data.sm_account.media === 'LinkedIn' ? <PreviewLK publicationData={selectedMessage.post_data.scheduled_post} selectedPhotos={selectedMessage.post_data.scheduled_post.bulk ? [selectedMessage.post_data.scheduled_post.medias[selectedMessage.post_data.bulk_media_index]] : selectedMessage.post_data.scheduled_post.medias} linkPreview={selectedMessage.post_data.scheduled_post.link_url} />
                                : selectedMessage.post_data.sm_account.media === 'Pinterest' ? <PostPreviewPN publicationData={selectedMessage.post_data.scheduled_post} selectedPhotos={selectedMessage.post_data.scheduled_post.bulk ? [selectedMessage.post_data.scheduled_post.medias[selectedMessage.post_data.bulk_media_index]] : selectedMessage.post_data.scheduled_post.medias} linkPreview={selectedMessage.post_data.scheduled_post.link_url} />
                                    : selectedMessage.post_data.sm_account.media === 'Twitter' ? <PreviewTW publicationData={selectedMessage.post_data.scheduled_post} selectedPhotos={selectedMessage.post_data.scheduled_post.bulk ? [selectedMessage.post_data.scheduled_post.medias[selectedMessage.post_data.bulk_media_index]] : selectedMessage.post_data.scheduled_post.medias} linkPreview={selectedMessage.post_data.scheduled_post.link_url} />
                                        // : selectedMessage.post_data.sm_account.media === 'GMB' ? <PreviewGMB publicationData={selectedMessage.post_data.scheduled_post} selectedPhotos={selectedMessage.post_data.scheduled_post.bulk ? [selectedMessage.post_data.scheduled_post.medias[selectedMessage.post_data.bulk_media_index]] : selectedMessage.post_data.scheduled_post.medias} />
                                        : selectedMessage.post_data.sm_account.media === 'TikTok' ? <PreviewTK publicationData={selectedMessage.post_data.scheduled_post} selectedPhotos={selectedMessage.post_data.scheduled_post.bulk ? [selectedMessage.post_data.scheduled_post.medias[selectedMessage.post_data.bulk_media_index]] : selectedMessage.post_data.scheduled_post.medias} />
                                            : selectedMessage.post_data.sm_account.media === 'Youtube' ? <PreviewYT publicationData={selectedMessage.post_data.scheduled_post} selectedPhotos={selectedMessage.post_data.scheduled_post.bulk ? [selectedMessage.post_data.scheduled_post.medias[selectedMessage.post_data.bulk_media_index]] : selectedMessage.post_data.scheduled_post.medias} />
                                                : ''}
                </div>
            </div>
            <div className="msg_action_comment">
                <div className='title'>{t('reply')}</div>
                <div className="comment_wrapper">
                    <div className="msg_list_account_data">
                        {checkProfilePic(selectedMessage.comment_data, selectedMessage.post_data.sm_account)}
                        <div className="msg_list_account_header">
                            <div className="msg_list_handle">{selectedMessage.comment_data.from_handle}</div>
                            <div className='msg_list_time'>{i18n.language === 'fr' ? t('ago') : ''} {checkTimeAgo(selectedMessage.comment_data.date)} {i18n.language === 'en' ? t('ago') : ''}</div>
                        </div>
                    </div>
                    {selectedMessage.comment_data.star ?
                        <div className="gmb_rating" dangerouslySetInnerHTML={{ __html: checkStarRating(selectedMessage.comment_data.star) }}></div>
                        : ''}
                    <div className='msg_list_comment_full'>{selectedMessage.comment_data.comment}</div>

                    <div className="replies_container">
                        {selectedMessage.comment_data.replies_data && selectedMessage.comment_data.replies_data.length > 0 ?
                            selectedMessage.comment_data.replies_data.map(reply => {
                                return (
                                    <div key={reply.id} className="reply_wrapper">
                                        <div className="msg_list_account_data">
                                            {checkProfilePic(reply, selectedMessage.post_data.sm_account, true)}
                                            <div className="msg_list_account_header">
                                                <div className="msg_list_handle">{reply.from_handle}</div>
                                                <div className='msg_list_time'>{checkTimeAgo(reply.date)}</div>
                                            </div>
                                        </div>
                                        <div className='msg_list_comment_full'>{reply.comment}</div>
                                    </div>
                                )
                            })
                            : ''}
                    </div>
                    <div className='editor_textarea_block margin-t1'>
                        <textarea ref={textareaRef} className='editor' placeholder={t('type_reply_here')} value={reply} onChange={(e) => setReply(e.target.value)}></textarea>
                    </div>
                    <div className='row row-100 row_spaced margin-t1'>
                        <div></div>
                        <div className='action_button' onClick={() => sendReplyToComment()}>
                            <svg viewBox="0 0 48 48">
                                <path fill="current" d="M6 40V8l38 16Zm3-4.65L36.2 24 9 12.5v8.4L21.1 24 9 27Zm0 0V12.5 27Z" />
                            </svg>
                            {t('send_reply')}
                        </div>
                    </div>
                </div>
                {/* {showEmoji ?
                    <>
                        <div className="transparent_background" onClick={displayEmoji}></div>
                        <div className="unsplash_picker">
                            <span className="close_modal" onClick={displayEmoji}>&times;</span>
                            <Picker onEmojiClick={onEmojiClick} width={350} height={450} />
                        </div>
                    </>
                    : ""} */}
            </div>
        </div>
    )
}

export default EngageCoMsgAction