import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";



const TagsList = ({ authTokens }) => {

    const { t } = useTranslation(['manage']);
    const headers = {
        Authorization: authTokens.token,
    };

    const [tagsGroups, setTagsGroups] = useState([]);

    const deleteHashTagGroup = async (group_id) => {
        const payload = {
            group_id: group_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/delete_hashtags_group", payload, {
                headers: headers,
            })
            .then(() => {
                setTagsGroups(tagsGroups.filter(group => group._id !== group_id));
            })
            .catch(() => {
            });
    }

    const fetchHashtagsGroups = async () => {
        const payload = {
            user_id: authTokens.user_id,
            company_id: authTokens.account_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/sm_tools/get_hashtags_group_team_co", payload, {
                headers: headers,
            })
            .then((response) => {
                setTagsGroups(response.data);
            })
            .catch(() => {
            });
    }

    useEffect(() => {
        fetchHashtagsGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="card flex-1">
            <div className="title">{t('hashtag_groups')}</div>
            <ul className='tools_list'>
                {tagsGroups && tagsGroups.length > 0 ?
                    tagsGroups.map((tagGroup, i) => {
                        return (
                            <li key={i}>
                                <div>
                                    <div className='tools_list_title'>
                                        {tagGroup.tags_group_name}
                                        <svg viewBox="0 0 24 24" className="social_delete_button" onClick={() => deleteHashTagGroup(tagGroup._id)}>
                                            <path fill="current" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                                        </svg>
                                    </div>

                                    <div className='row margin-t1'>
                                        {tagGroup.tags.map((tag, t) => {
                                            return (
                                                <div className='tools_hashtag' key={'tag_' + t}>
                                                    {tag}
                                                </div>)
                                        })}
                                    </div>
                                </div>
                            </li>
                        )
                    })
                    :
                    <div className='row centered'>
                        <p className='text-grey'>{t('create_tags_group')}</p>
                    </div>
                }
            </ul>
        </div>
    )
}

export default TagsList