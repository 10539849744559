import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../auth";
import { LinkedIn } from "react-linkedin-login-oauth2";
import PopUpAuth from "../../smlink/PopUpAuth";
import PopUpAuth2 from "../../smlink/PopUpAuth2";
import StateModal from "../../Loader/StateModal";
import FacebookLogin from "@greatsumini/react-facebook-login";

import SocialsGroups from "./SocialsGroups";

import FBnIGicon from "../../../images/social_media/FB_IG.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import PNicon from "../../../images/social_media/pinterest.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import TKTKicon from "../../../images/social_media/tiktok-spk.svg";
import YTicon from "../../../images/social_media/youtube.svg";
// import FIGMAicon from "../../../images/social_media/figma.svg";
// import NoSocials from "../../../images/social_media/no_socials.png";
import WarningIllustration from "../../../images/illustrations/spk-smaccounts-warning.svg";

const pinterest_oauth_url =
  "https://www.pinterest.com/oauth/?client_id=" +
  process.env.REACT_APP_PINTEREST_SOCIALPIKA_APP_ID +
  "&redirect_uri=" +
  process.env.REACT_APP_PINTEREST_SOCIALPIKA_REDIRECT_URI +
  "&response_type=code&scope=boards:read,pins:read,user_accounts:read,boards:write,pins:write&state=S0c1@lp1K@";
// const tiktok_oauth_url = "https://ads.tiktok.com/marketing_api/auth?app_id=" + process.env.REACT_APP_TIKTOK_SOCIALPIKA_APP_ID + "&state=your_custom_params&redirect_uri=https%3A%2F%2Fapp.socialpika.com%2Fsm_accounts%2Ftiktok%2F&rid=6jvdmq4dmp";
// const tiktok_oauth_url = "https://ads.tiktok.com/marketing_api/auth?app_id=" + process.env.REACT_APP_TIKTOK_SOCIALPIKA_APP_ID + "&state=your_custom_params&redirect_uri=" + process.env.REACT_APP_TIKTOK_SOCIALPIKA_REDIRECT_URI + "&rid=6jvdmq4dmp";
const tiktok_oauth_url =
  "https://www.tiktok.com/auth/authorize/?client_key=" +
  process.env.REACT_APP_TIKTOK_SOCIALPIKA_CLIENT_KEY +
  "&scope=user.info.basic,video.list,video.upload&response_type=code&state=S0c1@lp1K@T1kT0k&redirect_uri=" +
  process.env.REACT_APP_TIKTOK_SOCIALPIKA_REDIRECT_URI;

const tiktok_oauth_url_py =
  "https://www.tiktok.com/auth/authorize/?client_key=" +
  process.env.REACT_APP_TIKTOK_SOCIALPIKA_CLIENT_KEY +
  "&scope=user.info.basic,video.list,video.upload,video.publish&response_type=code&state=S0c1@lp1K@T1kT0k&redirect_uri=" +
  process.env.REACT_APP_TIKTOK_SOCIALPIKA_REDIRECT_URI;
// const gmb_client_id = process.env.REACT_APP_GMB_CLIENT_ID

const SocialsInline = ({
  SMAccounts,
  current_allowed_accounts,
  setSMAccounts,
  fetchSocialAccounts,
  headers,
  checkUnauthorizedResponse,
  goToPlans,
  isAdmin,
}) => {
  const py_test_account = process.env.REACT_APP_PY_TEST_ACCOUNT;
  const testy_test_account = process.env.REACT_APP_TESTY_TEST_ACCOUNT;
  const py_test_account_for_fb = process.env.REACT_APP_TEST_ACCOUNT_PY_FOR_FB;

  const app_id_fb = process.env.REACT_APP_FACEBOOK_SOCIALPIKA_APP_ID;
  const app_id_fb_test = process.env.REACT_APP_FACEBOOK_SOCIALPIKA_TEST_APP_ID;

  // const ig_scopes = 'pages_show_list,pages_read_engagement,instagram_basic,instagram_content_publish,instagram_manage_insights,catalog_management,business_management,instagram_shopping_tag_products,ads_management';
  // const all_fb_ig_scopes = 'pages_show_list, pages_manage_posts, read_insights, pages_read_engagement,instagram_basic,instagram_content_publish,instagram_manage_insights,catalog_management,business_management,instagram_shopping_tag_products,ads_management';
  const all_fb_ig_scopes =
    "pages_show_list, pages_manage_posts, read_insights, pages_read_engagement, pages_read_user_content, pages_manage_engagement, instagram_basic,instagram_content_publish,instagram_manage_insights,catalog_management,business_management,instagram_shopping_tag_products,ads_management,instagram_manage_comments";
  const ig_login_url = `https://www.facebook.com/dialog/oauth?client_id=${app_id_fb}&display=page&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=${process.env.REACT_APP_INSTAGRAM_SOCIALPIKA_REDIRECT_URI}&response_type=token&scope=${all_fb_ig_scopes}`;

  const all_fb_ig_new_scopes =
    "pages_show_list, pages_manage_posts, read_insights, pages_read_engagement, pages_read_user_content, pages_manage_engagement, instagram_basic,instagram_content_publish,instagram_manage_insights,catalog_management,business_management,instagram_shopping_tag_products,ads_management,instagram_manage_comments,publish_to_groups,groups_access_member_info";

  const { t } = useTranslation(["smlink"]);
  const { authTokens } = useAuth();
  const TKButton = useRef(null);
  const current_amount_accounts = SMAccounts.length;

  const [stateModalOptions, setStateModalOptions] = useState({
    display: false,
    status: "",
    message: "",
    loader: false,
  });
  const closeStateModal = () => {
    setStateModalOptions({
      display: false,
      status: "",
      message: "",
      loader: false,
    });
  };

  const [twitterAuthUrl, setTwitterAuthUrl] = useState("");

  const ytConsent = async () => {
    await axios
      .get(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/social_media/get_youtube_consent_screen",
        {
          headers: headers,
        }
      )
      .then((response) => {
        openYTPopupOauth(response.data.consent_url);
      })
      .catch((error) => {
        console.log(error);
      });
    closeStateModal();
  };

  const [YTexternalPopup, setYTExternalPopup] = useState(null);

  const openYTPopupOauth = (oauth_url) => {
    const width = 500;
    const height = 700;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = `YouTube account`;
    const url = oauth_url;
    const popup = window.open(
      url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`
    );
    setYTExternalPopup(popup);
  };

  useEffect(() => {
    if (!YTexternalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!YTexternalPopup) {
        timer && clearInterval(timer);
        return;
      }
      const currentUrl = YTexternalPopup.location.href;
      if (!currentUrl) {
        return;
      }
      const searchParams = new URL(currentUrl).searchParams;
      const code = searchParams.get("code");
      if (code) {
        YTexternalPopup.close();
        connectToYT(code);
        setYTExternalPopup(null);
        timer && clearInterval(timer);
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [YTexternalPopup]);

  const connectToYT = async (code) => {
    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true,
    });

    const ytOauthData = {
      code: code,
      company_id: authTokens.account_id,
    };

    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/social_media/get_youtube_token",
        ytOauthData,
        {
          headers: headers,
        }
      )
      .then(() => {
        closeStateModal();
        fetchSocialAccounts();
      })
      .catch((error) => {
        if (
          error.response.data.errorCode &&
          error.response.data.errorCode === "10001"
        ) {
          setStateModalOptions({
            ...stateModalOptions,
            display: true,
            status: "error",
            loader: false,
            message: t("no_yt_accounts_found"),
          });
        } else {
          closeStateModal();
        }
      });
  };

  const gmbConsent = async () => {
    await axios
      .get(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/social_media/get_gmb_consent_screen",
        {
          headers: headers,
        }
      )
      .then((response) => {
        openGMBPopupOauth(response.data.consent_url);
      })
      .catch((error) => {
        console.log(error);
      });
    closeStateModal();
  };

  const [GMBexternalPopup, setGMBExternalPopup] = useState(null);

  const openGMBPopupOauth = (oauth_url) => {
    const width = 500;
    const height = 400;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = `Google My Business`;
    const url = oauth_url;
    const popup = window.open(
      url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`
    );
    setGMBExternalPopup(popup);
  };

  useEffect(() => {
    if (!GMBexternalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!GMBexternalPopup) {
        timer && clearInterval(timer);
        return;
      }
      const currentUrl = GMBexternalPopup.location.href;
      if (!currentUrl) {
        return;
      }
      const searchParams = new URL(currentUrl).searchParams;
      const code = searchParams.get("code");
      if (code) {
        GMBexternalPopup.close();
        connectToGMB(code);
        setGMBExternalPopup(null);
        timer && clearInterval(timer);
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GMBexternalPopup]);

  const connectToGMB = async (code) => {
    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true,
    });

    const gmbOauthData = {
      code: code,
      company_id: authTokens.account_id,
    };

    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/social_media/get_gmb_token",
        gmbOauthData,
        {
          headers: headers,
        }
      )
      .then(() => {
        closeStateModal();
        fetchSocialAccounts();
      })
      .catch((error) => {
        if (
          error.response.data.errorCode &&
          error.response.data.errorCode === "10001"
        ) {
          setStateModalOptions({
            ...stateModalOptions,
            display: true,
            status: "error",
            loader: false,
            message: t("no_gmb_accounts_found"),
          });
        } else {
          closeStateModal();
        }
      });
  };

  const unlinkAccountTest = (account_id) => {
    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: false,
      status: "alert",
      message: t("warning"),
      choice: true,
      choice_data: {
        button_action_1_title: t("delete_account"),
        button_action_1_style: "action_button red",
        button_action_2_title: t("keep_account"),
        button_action_2_style: "action_button",
      },
      callBackFn: unlinkConfirmation,
      dataForCallback: {
        account_id: account_id,
      },
    });
  };

  const unlinkConfirmation = async (data, confirmation) => {
    if (!confirmation) {
      closeStateModal();
      return;
    }

    let authError = false;
    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true,
    });

    const payload = {
      _id: data.account_id,
      company_id: authTokens.account_id,
      user_id: authTokens.user_id,
    };

    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/social_media/remove_social_media_account/",
        payload,
        {
          headers: headers,
        }
      )
      .then(() => {
        setSMAccounts(
          SMAccounts.filter((account) => account._id !== data.account_id)
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          closeStateModal();
          checkUnauthorizedResponse(error.response.data);
          authError = true;
        }
      });

    if (authError) return;

    closeStateModal();

    fetchSocialAccounts();
  };

  const connectToInstagramError = (error) => {
    console.log("IGErrorLog");
    console.log(error);
  };

  const singleFBIGConnectorTest = async (response) => {
    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true,
    });

    const socialMediaData = {
      company_id: authTokens.account_id,
      user_id: response.userID,
      user_token: response.accessToken,
    };

    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL + "/social_media/meta_auth",
        socialMediaData,
        {
          headers: headers,
        }
      )
      .then(() => {
        fetchSocialAccounts();
      })
      .catch(() => {});
    closeStateModal();
  };

  const singleFBIGConnector = async (response) => {
    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true,
    });

    const socialMediaData = {
      company_id: authTokens.account_id,
      user_id: response.userID,
      user_token: response.accessToken,
    };

    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/social_media/get_fb_ig_tokens",
        socialMediaData,
        {
          headers: headers,
        }
      )
      .then(() => {
        fetchSocialAccounts();
      })
      .catch(() => {});
    closeStateModal();
  };

  const connectToLinkedIn = async (data) => {
    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true,
    });

    const linkedinAuth = {
      code: data.code,
      company_id: authTokens.account_id,
    };

    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/social_media/get_linkedin_token",
        linkedinAuth,
        {
          headers: headers,
        }
      )
      .then((response) => {
        fetchSocialAccounts();
      })
      .catch(() => {});

    closeStateModal();
  };

  const connectToLinkedInFailure = (error) => {
    closeStateModal();
  };

  const onPopUpClose = () => {
    closeStateModal();
  };

  const connectToTwitter = async (code, oauth_token, oauth_verifier) => {
    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: true,
    });

    let twitter_payload = {
      oauth_token: oauth_token,
      oauth_verifier: oauth_verifier,
      company_id: authTokens.account_id,
    };

    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/social_media/twitter_auth_verifier",
        twitter_payload,
        {
          headers: headers,
        }
      )
      .then(() => {
        fetchSocialAccounts();
      })
      .catch(() => {});

    closeStateModal();
  };

  const connectToPinterest = async (code) => {
    if (code) {
      setStateModalOptions({
        ...stateModalOptions,
        display: true,
        loader: true,
      });
      let pinterest_payload = { code: code, company_id: authTokens.account_id };

      await axios
        .post(
          process.env.REACT_APP_SOCIALPIKA_API_URL +
            "/social_media/pinterest_auth",
          pinterest_payload,
          {
            headers: headers,
          }
        )
        .then(() => {
          fetchSocialAccounts();
        })
        .catch(() => {});
      closeStateModal();
    }
  };

  const connectToTikTok = async (code) => {
    if (code) {
      setStateModalOptions({
        ...stateModalOptions,
        display: true,
        loader: true,
      });
      let tiktok_payload = { code: code, company_id: authTokens.account_id };

      await axios
        .post(
          process.env.REACT_APP_SOCIALPIKA_API_URL +
            "/social_media/tiktok_auth",
          tiktok_payload,
          {
            headers: headers,
          }
        )
        .then(() => {
          fetchSocialAccounts();
        })
        .catch(() => {});
      closeStateModal();
    }
  };

  const TKcallback = (data, confirmation) => {
    console.log(confirmation);
    if (!confirmation) {
      closeStateModal();
      return;
    }

    TKButton.current.click();
    closeStateModal();
  };

  const displayTKinfo = () => {
    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      loader: false,
      status: "info",
      message: t("TK_info"),
      choice: true,
      choice_data: {
        button_action_1_title: t("add_ig"),
        button_action_1_style: "action_button",
        button_action_2_title: t("cancel"),
        button_action_2_style: "action_button red",
      },
      callBackFn: TKcallback,
      dataForCallback: {},
    });
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div>
      {stateModalOptions.display ? (
        <StateModal
          modalOptions={stateModalOptions}
          closeStateModal={closeStateModal}
        />
      ) : (
        ""
      )}
      {isAdmin ? (
        current_amount_accounts <= current_allowed_accounts ? (
          <div className="column">
            <p>{t("sm_icon_desc")}</p>
            <div className="row row_hcenter margin-t2">
              <div className="sm_button_container">
                {/* {authTokens.account_id===py_test_account_for_fb || authTokens.account_id===py_test_account?
                                    <FacebookLogin
                                    appId={app_id_fb_test}
                                    scope={all_fb_ig_new_scopes}
                                    onSuccess={(response) => singleFBIGConnectorTest(response)}
                                    onFail={(error) => connectToInstagramError(error)}
                                    loginOptions={{
                                        return_scopes: true,
                                        auth_type: 'rerequest',
                                        ignoreSdkError: true
                                    }}
                                    initParams={{
                                        localStorage: false,
                                    }}
                                    render={({ onClick }) => (
                                        <img className="sm_button" src={FBnIGicon} alt="Connect an Instagram account" onClick={onClick} />
                                    )}
                                />
                                : */}
                <FacebookLogin
                  appId={app_id_fb}
                  scope={all_fb_ig_scopes}
                  onSuccess={(response) => singleFBIGConnector(response)}
                  onFail={(error) => connectToInstagramError(error)}
                  loginOptions={{
                    return_scopes: true,
                    auth_type: "rerequest,reauthenticate,reauthorize",
                    ignoreSdkError: true,
                  }}
                  initParams={{
                    localStorage: false,
                  }}
                  render={({ onClick }) => (
                    <img
                      className="sm_button"
                      src={FBnIGicon}
                      alt="Connect an Instagram account"
                      onClick={onClick}
                    />
                  )}
                />
                {/* } */}
              </div>
              <div className="sm_button_container">
                <PopUpAuth
                  url={twitterAuthUrl}
                  onCode={connectToTwitter}
                  onClose={onPopUpClose}
                  initTokenTwitter={true}
                  width="500"
                  height="700"
                >
                  <img
                    className="sm_button"
                    src={TWicon}
                    alt="Connect a Twitter account"
                  />
                </PopUpAuth>
              </div>
              <div className="sm_button_container">
                <LinkedIn
                  clientId={process.env.REACT_APP_LINKEDIN_SOCIALPIKA_CLIENT_ID}
                  onFailure={connectToLinkedInFailure}
                  onSuccess={connectToLinkedIn}
                  scope="r_organization_social w_organization_social w_member_social r_liteprofile r_basicprofile rw_organization_admin rw_ads r_1st_connections_size"
                  redirectUri={process.env.REACT_APP_LINKEDIN_CALLBACK_URL}
                  renderElement={({ onClick }) => (
                    <img
                      className="sm_button"
                      src={LKicon}
                      alt="Connect a LinkedIn account"
                      onClick={onClick}
                    />
                  )}
                />
              </div>
              <div className="sm_button_container">
                <PopUpAuth
                  url={pinterest_oauth_url}
                  onCode={connectToPinterest}
                  onClose={onPopUpClose}
                  initTokenTwitter={false}
                  width="500"
                  height="700"
                >
                  <img
                    className="sm_button"
                    src={PNicon}
                    alt="Connect a Pinterest account"
                  />
                </PopUpAuth>
              </div>
              <div className="sm_button_container">
                <img
                  className="sm_button"
                  src={GMBicon}
                  alt="Connect a Google My Business account"
                  onClick={() => gmbConsent()}
                />
              </div>
              <div className="sm_button_container">
                <img
                  className="sm_button"
                  src={TKTKicon}
                  alt="Connect a TikTok account"
                  onClick={() => displayTKinfo()}
                />
                {authTokens.account_id === py_test_account ? (
                  <PopUpAuth2
                    url={tiktok_oauth_url_py}
                    onTikTokCode={connectToTikTok}
                    onClose={onPopUpClose}
                    initTokenTwitter={false}
                    initTikTok={true}
                    width="500"
                    height="700"
                  >
                    <div ref={TKButton}></div>
                  </PopUpAuth2>
                ) : (
                  <PopUpAuth2
                    url={tiktok_oauth_url}
                    onTikTokCode={connectToTikTok}
                    onClose={onPopUpClose}
                    initTokenTwitter={false}
                    initTikTok={true}
                    width="500"
                    height="700"
                  >
                    <div ref={TKButton}></div>
                  </PopUpAuth2>
                )}
              </div>
              {/* {(authTokens.account_id === py_test_account || authTokens.account_id === testy_test_account) ? */}
              <div className="sm_button_container">
                <img
                  className="sm_button"
                  src={YTicon}
                  alt="Connect a YouTube account"
                  onClick={() => ytConsent()}
                />
              </div>
              {/* : ''} */}
            </div>
          </div>
        ) : (
          <div>
            <div className="column topped">
              <img className="img-h10" src={WarningIllustration} alt="" />
              <div className="margin-l2">
                <p className="text-grey text-bold text-centered">
                  {t("max_accounts")}
                </p>
                <p className="text-grey text-centered">
                  {t("upgrade_or_delete")}
                </p>
              </div>
            </div>

            <div className="column bottomed">
              <div className="action_button margin-t2" onClick={goToPlans}>
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="m11 39.6 5.3-2.15q-.65-1.9-1.125-3.85-.475-1.95-.825-3.95L11 31.9Zm8.3-2.6h9.4q1.1-2.95 1.7-5.875Q31 28.2 31 26q0-5.75-1.65-10.1T24 7.65q-3.7 3.9-5.35 8.25Q17 20.25 17 26q0 2.2.6 5.125T19.3 37ZM24 25.5q-1.45 0-2.475-1.025Q20.5 23.45 20.5 22q0-1.45 1.025-2.475Q22.55 18.5 24 18.5q1.45 0 2.475 1.025Q27.5 20.55 27.5 22q0 1.45-1.025 2.475Q25.45 25.5 24 25.5Zm13 14.1v-7.7l-3.35-2.25q-.35 2-.825 3.95-.475 1.95-1.125 3.85ZM24 3.55q5.1 4.5 7.55 9.975T34 26v.25l4.65 3.15q.65.4 1 1.05t.35 1.4V44l-10-4H18L8 44V31.85q0-.75.35-1.4.35-.65 1-1.05L14 26.25V26q0-7 2.45-12.475T24 3.55Z"
                  />
                </svg>
                {t("upgrade")}
              </div>
              <p className="text-grey margin-l1">{t("or_remove_accounts")}👇</p>
            </div>
          </div>
        )
      ) : (
        ""
      )}
      {isAdmin && current_amount_accounts <= current_allowed_accounts ? (
        <p className="p-small margin-t1 text-centered text-italic">
          {t("ig_blog")}{" "}
          <a
            className="light_blue_link underline"
            href={ig_login_url}
            target="_blank"
            rel="noopener"
          >
            {t("ig_try")}{" "}
          </a>{" "}
        </p>
      ) : (
        ""
      )}
      {SMAccounts && SMAccounts.length === 0 ? (
        <p className="text-grey text-bold">{t("add_social_accounts")}</p>
      ) : (
        ""
      )}
      <div className="margin-t4">
        <SocialsGroups
          authTokens={authTokens}
          SMAccounts={SMAccounts}
          unlinkAccountTest={unlinkAccountTest}
        />
      </div>
    </div>
  );
};

export default SocialsInline;
