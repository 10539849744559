import React, { useState, useEffect } from "react";

import FBicon from "../../../images/social_media/facebook.svg";
import TWicon from "../../../images/social_media/twitter.svg";
import LKicon from "../../../images/social_media/linkedin.svg";
import IGicon from "../../../images/social_media/instagram.svg";
import PNicon from "../../../images/social_media/pinterest.svg";
import GMBicon from "../../../images/social_media/gmb.svg";
import YTicon from "../../../images/social_media/youtube.svg";
import TKTKicon from "../../../images/social_media/tiktok-spk.svg";
import SPKNoProfile from "../../../images/social_media/SPK-no-profile.png";
import FBGroupIcon from "../../../images/social_media/fb_group.png";

const SocialsGroups = ({ authTokens, SMAccounts, unlinkAccountTest }) => {
  const isAdmin = authTokens.user_admin;

  const [accountsMedia, setAccountsMedia] = useState([]);

  const checkAccountPhoto = (accountData) => {
    switch (accountData.media) {
      case "Facebook":
        let fb_picture_url =
          "https://graph.facebook.com/v11.0/" +
          accountData.social_id +
          "/picture";
        if (accountData.fb_group) {
          fb_picture_url = FBGroupIcon;
        }

        return (
          <div className="account_profile_photo">
            <img
              className="profile_picture"
              src={fb_picture_url}
              alt="Profile"
              onError={(e) => {
                e.target.src = SPKNoProfile;
                e.onerror = null;
              }}
            />
            <img className="account_profile_media" src={FBicon} alt="Media" />
          </div>
        );
      case "Twitter":
        return (
          <div className="account_profile_photo">
            <img
              className="profile_picture"
              src={accountData.profile_picture_url || SPKNoProfile}
              alt="Profile"
              onError={(e) => {
                e.target.src = SPKNoProfile;
                e.onerror = null;
              }}
            />
            <img className="account_profile_media" src={TWicon} alt="Media" />
          </div>
        );
      case "LinkedIn":
        return (
          <div className="account_profile_photo">
            <img
              className="profile_picture"
              src={accountData.profile_picture_url || SPKNoProfile}
              alt="Profile"
              onError={(e) => {
                e.target.src = SPKNoProfile;
                e.onerror = null;
              }}
            />
            <img className="account_profile_media" src={LKicon} alt="Media" />
          </div>
        );
      case "Instagram":
        return (
          <div className="account_profile_photo">
            <img
              className="profile_picture"
              src={accountData.profile_picture_url || SPKNoProfile}
              alt="Profile"
              onError={(e) => {
                e.target.src = SPKNoProfile;
                e.onerror = null;
              }}
            />
            <img className="account_profile_media" src={IGicon} alt="Media" />
          </div>
        );
      case "Pinterest":
        return (
          <div className="account_profile_photo">
            <img
              className="profile_picture"
              src={accountData.profile_picture_url || SPKNoProfile}
              alt="Profile"
              onError={(e) => {
                e.target.src = SPKNoProfile;
                e.onerror = null;
              }}
            />
            <img className="account_profile_media" src={PNicon} alt="Media" />
          </div>
        );
      case "GMB":
        return (
          <div className="account_profile_photo">
            <img
              className="profile_picture"
              src={accountData.profile_picture_url || SPKNoProfile}
              alt="Profile"
              onError={(e) => {
                e.target.src = SPKNoProfile;
                e.onerror = null;
              }}
            />
            <img className="account_profile_media" src={GMBicon} alt="Media" />
          </div>
        );
      case "TikTok":
        return (
          <div className="account_profile_photo">
            <img
              className="profile_picture"
              src={accountData.profile_picture_url || SPKNoProfile}
              alt="Profile"
              onError={(e) => {
                e.target.src = SPKNoProfile;
                e.onerror = null;
              }}
            />
            <img className="account_profile_media" src={TKTKicon} alt="Media" />
          </div>
        );
      case "Youtube":
        return (
          <div className="account_profile_photo">
            <img
              className="profile_picture"
              src={accountData.profile_picture_url || SPKNoProfile}
              alt="Profile"
              onError={(e) => {
                e.target.src = SPKNoProfile;
                e.onerror = null;
              }}
            />
            <img className="account_profile_media" src={YTicon} alt="Media" />
          </div>
        );
      default:
        return (
          <div className="account_profile_photo">
            <img
              className="profile_picture"
              src="https://graph.facebook.com/v11.0/103219298760786/picture"
              alt="Profile"
              onError={(e) => {
                e.target.src = SPKNoProfile;
                e.onerror = null;
              }}
            />
            <img className="account_profile_media" src={TWicon} alt="Media" />
          </div>
        );
    }
  };

  // const [showAccountModal, setShowAccountModal] = useState(true);
  // const displayModal = (index) => {
  //     setShowAccountModal(state => ({
  //         ...state, // <-- copy previous state
  //         [index]: !state[index] // <-- update value by index key
  //     }));
  // }

  useEffect(() => {
    if (SMAccounts && SMAccounts.length > 0) {
      const mediaArray = [];
      SMAccounts.forEach((account) => {
        mediaArray.push(account.media);
      });

      var uniqueArray = [...new Set(mediaArray)];
      setAccountsMedia(uniqueArray);
    }
  }, [SMAccounts]);

  return (
    <div>
      {accountsMedia &&
      accountsMedia.length > 0 &&
      accountsMedia.includes("Facebook") ? (
        <div className="composer_component_thread">
          <div className="title">Facebook</div>
          <div className="row">
            {SMAccounts.map((account) => {
              if (account.media === "Facebook") {
                return (
                  <div key={account._id} className="account_block">
                    {checkAccountPhoto(account)}
                    <p>{account.social_name}</p>
                    {isAdmin ? (
                      <svg
                        viewBox="0 0 24 24"
                        onClick={() => unlinkAccountTest(account._id)}
                      >
                        <path
                          fill="current"
                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        ""
      )}
      {accountsMedia &&
      accountsMedia.length > 0 &&
      accountsMedia.includes("Instagram") ? (
        <div className="composer_component_thread margin-t2">
          <div className="title">Instagram</div>
          <div className="row">
            {SMAccounts.map((account) => {
              if (account.media === "Instagram") {
                return (
                  <div key={account._id} className="account_block">
                    {checkAccountPhoto(account)}
                    <p>{account.social_name}</p>
                    {isAdmin ? (
                      <svg
                        viewBox="0 0 24 24"
                        onClick={() => unlinkAccountTest(account._id)}
                      >
                        <path
                          fill="current"
                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        ""
      )}
      {accountsMedia &&
      accountsMedia.length > 0 &&
      accountsMedia.includes("Twitter") ? (
        <div className="composer_component_thread margin-t1">
          <div className="title">Twitter/X</div>
          <div className="row">
            {SMAccounts.map((account) => {
              if (account.media === "Twitter") {
                return (
                  <div key={account._id} className="account_block">
                    {checkAccountPhoto(account)}
                    <p>{account.social_name}</p>
                    {isAdmin ? (
                      <svg
                        viewBox="0 0 24 24"
                        onClick={() => unlinkAccountTest(account._id)}
                      >
                        <path
                          fill="current"
                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        ""
      )}
      {accountsMedia &&
      accountsMedia.length > 0 &&
      accountsMedia.includes("LinkedIn") ? (
        <div className="composer_component_thread margin-t2">
          <div className="title">LinkedIn</div>
          <div className="row">
            {SMAccounts.map((account) => {
              if (account.media === "LinkedIn") {
                return (
                  <div key={account._id} className="account_block">
                    {checkAccountPhoto(account)}
                    <p>{account.social_name}</p>
                    {isAdmin ? (
                      <svg
                        viewBox="0 0 24 24"
                        onClick={() => unlinkAccountTest(account._id)}
                      >
                        <path
                          fill="current"
                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                    {/* <svg viewBox="0 0 24 24" onClick={() => displayModal(account._id)}>
                                            <path fill="current" d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
                                        </svg>
                                        <div className={showAccountModal[account._id] ? 'account_options' : 'account_options inactive'} onClick={() => unlinkAccountTest(account._id)}>
                                            <svg viewBox="0 0 24 24" className="delete_button" >
                                                <path fill="current" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                                            </svg>
                                            {t('delete')}
                                        </div> */}
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        ""
      )}
      {accountsMedia &&
      accountsMedia.length > 0 &&
      accountsMedia.includes("Pinterest") ? (
        <div className="composer_component_thread margin-t2">
          <div className="title">Pinterest</div>
          <div className="row">
            {SMAccounts.map((account) => {
              if (account.media === "Pinterest") {
                return (
                  <div key={account._id} className="account_block">
                    {checkAccountPhoto(account)}
                    <p>{account.social_name}</p>
                    {isAdmin ? (
                      <svg
                        viewBox="0 0 24 24"
                        onClick={() => unlinkAccountTest(account._id)}
                      >
                        <path
                          fill="current"
                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        ""
      )}
      {accountsMedia &&
      accountsMedia.length > 0 &&
      accountsMedia.includes("GMB") ? (
        <div className="composer_component_thread margin-t2">
          <div className="title">Google Business Profile</div>
          <div className="row">
            {SMAccounts.map((account) => {
              if (account.media === "GMB") {
                return (
                  <div key={account._id} className="account_block">
                    {checkAccountPhoto(account)}
                    <p>{account.social_name}</p>
                    {isAdmin ? (
                      <svg
                        viewBox="0 0 24 24"
                        onClick={() => unlinkAccountTest(account._id)}
                      >
                        <path
                          fill="current"
                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        ""
      )}
      {accountsMedia &&
      accountsMedia.length > 0 &&
      accountsMedia.includes("TikTok") ? (
        <div className="composer_component_thread margin-t2">
          <div className="title">TikTok</div>
          <div className="row">
            {SMAccounts.map((account) => {
              if (account.media === "TikTok") {
                return (
                  <div key={account._id} className="account_block">
                    {checkAccountPhoto(account)}
                    <p>{account.social_name}</p>
                    {isAdmin ? (
                      <svg
                        viewBox="0 0 24 24"
                        onClick={() => unlinkAccountTest(account._id)}
                      >
                        <path
                          fill="current"
                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        ""
      )}
      {accountsMedia &&
      accountsMedia.length > 0 &&
      accountsMedia.includes("Youtube") ? (
        <div className="composer_component_thread margin-t2">
          <div className="title">YouTube</div>
          <div className="row">
            {SMAccounts.map((account) => {
              if (account.media === "Youtube") {
                return (
                  <div key={account._id} className="account_block">
                    {checkAccountPhoto(account)}
                    <p>{account.social_name}</p>
                    {isAdmin ? (
                      <svg
                        viewBox="0 0 24 24"
                        onClick={() => unlinkAccountTest(account._id)}
                      >
                        <path
                          fill="current"
                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SocialsGroups;
