import React, { useState, useEffect } from 'react'
import Chart from "react-apexcharts";
import moment from 'moment'



const PNAnalytics = ({ insights, mainInsights }) => {

    const [reachData, setReachData] = useState([]);


    const [reachOptions, setReachOptions] = useState({
        chart: {
            type: 'bar',
            height: 400
        },
        colors: ['#19A4FE', '#EB316F', '#617183'],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '88%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        title: {
            text: ''
        },
        xaxis: {
            tickAmount: 15
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        }
    })


    const loadData = () => {


        // REACH
        let reach_categories = [];
        insights.dates.map(value => (
            reach_categories.push(moment(value).format('DD/MM'))
        ))

        setReachOptions({
            ...reachOptions,
            xaxis: {
                categories: reach_categories,
                tickAmount: 15
            }
        })
        // reachOptions.xaxis.categories = reach_categories;


        setReachData([{
            name: "Impressions",
            data: insights.impressions
        }, {
            name: "Engagement",
            data: insights.engagement
        }])



    }

    useEffect(() => {
        if (insights) { loadData() }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='row-100 row_hcenter margin-t2'>
            <div className='column_left flex-1'>
                <h2>Insights</h2>
                <Chart
                    options={reachOptions}
                    series={reachData}
                    type='bar'
                    height='300px'
                    width='500px'
                />
            </div>
        </div>
    )
}

export default PNAnalytics