import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useAuth } from "../../auth";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";


import "./onboarding.css"
import "./onboarding_forms.css"



const OnboardingPaymentConfirmed = (props) => {
    const { t } = useTranslation(['plans_CESAM']);

    const { setAuthTokens } = useAuth();
    const { authTokens } = useAuth();
    const headers = {
        Authorization: authTokens.token,
    };
    const history = useHistory();

    const goToAccounts = () => {
        history.push({ pathname: '/sm_accounts' });
    }

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    useEffect(() => {
        const session_id = props.match.params.id;

        if (session_id) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: true
            });
            axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/payment/record_payment_stripe_checkout", {
                session_id: session_id
            }, {
                headers: headers,
            })
                .then((response) => {
                    console.log(response);
                    setAuthTokens({
                        ...authTokens,
                        token: response.data.token,
                        cai: response.data.cai === 'true' ? true : false,
                        plan: response.data.plan,
                        plan_expiry: response.data.plan_expiry,
                        billing: response.data.billing,
                        onboarding: true
                    })
                })
                .catch(() => {
                });

            closeStateModal();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className="onboarding_wrapper">

            <div className="onboarding_items_wrapper">
                <div className="onboarding_items">
                    <div className='onboarding_item active'>
                        <div className="onboarding_content">
                            <div className='onboarding_qa'>
                                <div className='onboarding_question'>{t('thank_you_order')}</div>
                                <div className='onboarding_divider_wrapper'>
                                    <div className='onboarding_divider'></div>
                                </div>
                                <div className='plan_buttons'>
                                    <div className='free_trial_button' onClick={() => goToAccounts()}>
                                        {t('start_linking_account')}
                                        <svg viewBox="0 0 24 24">
                                            <path fill="current" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OnboardingPaymentConfirmed