import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import Resizer from "react-image-file-resizer";
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import "./profile_update.css"

const IKpublicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY;
const IKauthenticationEndpoint = process.env.REACT_APP_SOCIALPIKA_API_URL + '/imagekit/auth';

const handleMediaFile = (file) => {
    return new Promise(function (resolve, reject) {
        let fr = new FileReader();

        fr.onload = function () {
            resolve(fr.result);
        };

        fr.onerror = function () {
            reject(fr);
        };

        fr.readAsDataURL(file);
    });
}

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

const ProfileUpdate = ({ displayProfileUpdater, authTokens, setAuthTokens }) => {

    const { t } = useTranslation(['dashboard']);
    const [loaded, setLoaded] = useState(false);
    const inputPhotoOnlyRef = useRef(null);
    const imgRef = useRef(null);
    const originalImg = useRef(null);

    const headers = {
        Authorization: authTokens.token,
    };

    const [dragActive, setDragActive] = useState(false);
    const [imgSrc, setImgSrc] = useState();
    const [crop, setCrop] = useState();
    const [croppingData, setCroppingData] = useState({});
    const [localLoader, setLocalLoader] = useState(false);

    const [emailAddress, setEmailAddress] = useState({
        email: authTokens.user_email,
        isUpdating: false,
        isUpdated: false,
        srv_error: false,
        wrong_email_synthax: false,
        email_used: false
    });
    const [pwd, setPwd] = useState({
        password: '',
        password_confirmation: '',
        isUpdating: false,
        isUpdated: false,
        not_matching: false,
        srv_error: false
    });


    const onDragOver = (event) => {
        setDragActive(true);
        event.stopPropagation();
        event.preventDefault();
    }

    const onDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const onDragLeave = (event) => {
        setDragActive(false);
        event.preventDefault();
        event.stopPropagation();
    }

    const displayMediaUpload = () => {
        inputPhotoOnlyRef.current.click();
    }

    const storeCroppingData = (cData) => {
        const croppingCanva = imgRef.current;
        const originalCanva = originalImg.current;

        const ratio = originalCanva.width / croppingCanva.width;

        setCroppingData({
            cData: cData,
            ratio: ratio
        })
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1200,
                1080,
                "JPEG",
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    const onFileDrop = async (event) => {
        setDragActive(false);
        event.preventDefault();
        event.stopPropagation();

        let files = event.dataTransfer.files;
        let readers = [];

        // Abort if there were no files selected
        if (!files.length) return;

        // Store promises in array  
        readers.push(handleMediaFile(files[0]));
        const filesData = await Promise.all(readers);
        let match = filesData[0].match(/^data:([^/]+)\/([^;]+);/) || [];

        let type = match[1];
        if (type === 'image') {
            const image = await resizeFile(files[0]);
            setImgSrc(image);
        }
    }
    const mediaSelected = async (event) => {
        let files = event.currentTarget.files;
        let readers = [];

        if (!files.length) return;

        // Store promises in array  
        readers.push(handleMediaFile(files[0]));
        const filesData = await Promise.all(readers);
        let match = filesData[0].match(/^data:([^/]+)\/([^;]+);/) || [];

        let type = match[1];
        if (type === 'image') {
            const image = await resizeFile(files[0]);
            setImgSrc(image);
        }
    }

    const onImageLoad = () => {
        const croppingCanva = imgRef.current;
        const { width, height } = croppingCanva
        setCrop(centerAspectCrop(width, height, 1))
    }

    const uploadImage = async (image) => {
        let authError = false;

        const payload = {
            company_id: authTokens.account_id
        }

        const IKauth = await axios.post(IKauthenticationEndpoint, payload, {
            headers: headers,
        })
            .catch((error) => {
                if (error.response.status === 401) {
                    setLocalLoader(false);
                    // props.checkUnauthorizedResponse(error.response.data);
                    authError = true;
                }
            });

        if (authError) return;

        const imagekit_headers = {
            'Content-Type': 'multipart/form-data'
        }
        const params = new FormData();
        params.append('file', image)
        params.append('publicKey', IKpublicKey)
        params.append('signature', IKauth.data.signature)
        params.append('expire', IKauth.data.expire)
        params.append('token', IKauth.data.token)
        params.append('fileName', 'social_pika')
        params.append('folder', '/' + authTokens.account_id + "/profiles")

        const ik_upload_url = 'https://upload.imagekit.io/api/v1/files/upload';

        const uploaded_image = await axios.post(ik_upload_url, params, {
            headers: imagekit_headers
        })
            .catch(() => {
                setLocalLoader(false);
                return;
            })

        // UPDATE SERVER
        const new_profile_url = uploaded_image.data.url;

        const payload2 = {
            _id: authTokens.user_id,
            new_profile_url: new_profile_url,
            old_profile_url: authTokens.user_profile_url
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/update_profile_picture_advanced", payload2, {
                headers: headers,
            })
            .then(() => {
                setAuthTokens({
                    ...authTokens,
                    user_profile_url: new_profile_url
                })
                displayProfileUpdater();

            })
            .catch(() => {
                setLocalLoader(false);

            });

    }

    const resizePhotos = async () => {
        setLocalLoader(true);

        if (!croppingData) {
            await uploadImage(imgSrc);
        }
        else {
            const cData = croppingData.cData;
            const ratio = croppingData.ratio;

            const finalImgCanva = document.createElement("canvas");
            finalImgCanva.width = cData.width * ratio;
            finalImgCanva.height = cData.height * ratio;

            originalImg.current.src = imgSrc;

            const originalCanva = originalImg.current;


            const ctx = finalImgCanva.getContext("2d");
            ctx.imageSmoothingQuality = 'high'
            ctx.drawImage(
                originalCanva,
                cData.x * ratio,
                cData.y * ratio,
                cData.width * ratio,
                cData.height * ratio,
                0,
                0,
                finalImgCanva.width,
                finalImgCanva.height
            );

            const base64Image = finalImgCanva.toDataURL("image/jpeg", 1);

            await uploadImage(base64Image);
        }
    }

    const updateEmail = async () => {
        if (!emailAddress.email || emailAddress.email.length === 0 || emailAddress.email === authTokens.user_email) return;

        setEmailAddress({
            ...emailAddress,
            isUpdating: true
        })

        const payload = {
            user_id: authTokens.user_id,
            email: emailAddress.email
        }

        await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/update_email", payload, {
            headers: headers,
        })
            .then(() => {
                setEmailAddress({
                    ...emailAddress,
                    isUpdating: false,
                    isUpdated: true,
                })
            })
            .catch((error) => {

                if (error.response.data && error.response.data.errorCode && error.response.data.errorCode === 4000) {
                    setEmailAddress({
                        ...emailAddress,
                        isUpdating: false,
                        isUpdated: false,
                        wrong_email_synthax: true,
                        email_used: false,
                        srv_error: false
                    })
                }
                else if (error.response.data && error.response.data.errorCode && error.response.data.errorCode === 4001) {
                    setEmailAddress({
                        ...emailAddress,
                        isUpdating: false,
                        isUpdated: false,
                        wrong_email_synthax: false,
                        email_used: true,
                        srv_error: false
                    })
                }
                else {
                    setEmailAddress({
                        ...emailAddress,
                        isUpdating: false,
                        isUpdated: false,
                        wrong_email_synthax: false,
                        email_used: false,
                        srv_error: true
                    })
                }
            });
    }

    const updatePassword = async () => {
        if (!pwd.password || pwd.password.length === 0) return;

        if (pwd.password !== pwd.password_confirmation) {
            setPwd({
                ...pwd,
                not_matching: true
            })
            return;
        }

        setPwd({
            ...pwd,
            isUpdating: true,
            not_matching: false
        });

        const payload = {
            user_id: authTokens.user_id,
            password: pwd.password
        }


        await axios.post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/user/update_password", payload, {
            headers: headers,
        })
            .then(() => {
                setPwd({
                    ...pwd,
                    isUpdating: false,
                    isUpdated: true,
                    password: '',
                    password_confirmation: '',
                    not_matching: false
                });
            })
            .catch(() => {
                setPwd({
                    ...pwd,
                    isUpdating: false,
                    isUpdated: false,
                    srv_error: true,
                    not_matching: false
                });
            });

    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 10);
        return () => clearTimeout(timer);

    }, [])

    return (
        <div>
            <div className={loaded ? "profile_update_bg active" : "profile_update_bg"}></div>
            <div className={loaded ? "profile_update_wrapper" : "profile_update_wrapper inactive"}>
                <span className="close_modal" onClick={displayProfileUpdater}>&times;</span>
                <div className='title margin-b4'>{t('profile_picture')}</div>
                <input type="file" ref={inputPhotoOnlyRef} onChange={mediaSelected} className="imagekit_upload" accept=".jpg, .jpeg, .png, .gif, .webp, .svg" />
                {!imgSrc ?
                    <div className={dragActive ? "composer_media_upload_card active margin-b2" : "composer_media_upload_card margin-b2"} onClick={displayMediaUpload} onDrop={(e) => onFileDrop(e)} onDragEnter={(e) => onDragEnter(e)} onDragOver={(e) => onDragOver(e)} onDragLeave={(e) => onDragLeave(e)} >
                        <div className='column'>
                            <svg viewBox="0 0 24 24">
                                <path fill="current" d="M19.35,10.04C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.04C2.34,8.36 0,10.91 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.04M19,18H6A4,4 0 0,1 2,14C2,11.95 3.53,10.24 5.56,10.03L6.63,9.92L7.13,8.97C8.08,7.14 9.94,6 12,6C14.62,6 16.88,7.86 17.39,10.43L17.69,11.93L19.22,12.04C20.78,12.14 22,13.45 22,15A3,3 0 0,1 19,18M8,13H10.55V16H13.45V13H16L12,9L8,13Z" />
                            </svg>
                            {t('drop_file_or_click')}
                        </div>
                    </div>
                    :
                    <div className='profile_cropping_window'>
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => storeCroppingData(c)}
                            aspect={1}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                className="profile_img_cropper"
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                        {localLoader ?
                            <div className="lds-ring-loader"><div></div><div></div><div></div><div></div></div>
                            :
                            <button className="margin-l2" onClick={() => resizePhotos()}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
                                </svg>
                                {t('upload')}
                            </button>
                        }
                    </div>
                }
                <div className='divider'></div>
                <div className='title'>{t('email_address')}</div>
                <div className="column_left margin-t2 margin-b2">
                    <input type="email" className='dashboard_input' placeholder={t('email_placeholder')} name="email" value={emailAddress.email} onChange={e => setEmailAddress({ ...emailAddress, email: e.target.value })} />
                    {emailAddress.email_used ? <p className='text-red'>{t('email_already_used')}</p> : ""}
                    {emailAddress.wrong_email_synthax ? <p className='text-red'>{t('wrong_email_synthax')}</p> : ""}
                    {emailAddress.srv_error ? <p className='text-red'>{t('srv_error')}</p> : ""}
                    <div className='action_button margin-t1' onClick={() => updateEmail()} disabled={emailAddress.isUpdating || emailAddress.isUpdated}>
                        {emailAddress.isUpdating ?
                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                            :
                            emailAddress.isUpdated ?
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                                :
                                <>
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="M24.15 42q-3.75 0-7.05-1.425T11.325 36.7q-2.475-2.45-3.9-5.75Q6 27.65 6 23.9t1.425-7q1.425-3.25 3.9-5.675Q13.8 8.8 17.1 7.4 20.4 6 24.15 6q4 0 7.575 1.75t6.175 4.8v-5.3h3v10.4H30.45v-3h5.25q-2.2-2.55-5.175-4.1Q27.55 9 24.15 9q-6.25 0-10.7 4.275Q9 17.55 9 23.75q0 6.35 4.4 10.8Q17.8 39 24.15 39q6.25 0 10.55-4.4Q39 30.2 39 23.95h3q0 7.5-5.2 12.775T24.15 42Zm6.1-9.85-7.7-7.6v-10.7h3v9.45L32.4 30Z" />
                                    </svg>

                                    {t('update')}
                                </>

                        }
                    </div>
                </div>
                <div className='divider'></div>
                <div className='title'>{t('pwd')}</div>
                <div className="column_left margin-t2">
                    <input type="password" className='dashboard_input' placeholder={t('pwd_placeholder')} name="password" value={pwd.password} onChange={e => setPwd({ ...pwd, password: e.target.value })} />
                    <input type="password" className='dashboard_input margin-t1' placeholder={t('pwd_conf_placeholder')} name="password_confirmation" value={pwd.password_confirmation} onChange={e => setPwd({ ...pwd, password_confirmation: e.target.value })} />
                    {pwd.not_matching ? <p className='text-red'>{t('pwd_not_matching')}</p> : ""}
                    {pwd.srv_error ? <p className='text-red'>{t('srv_error')}</p> : ""}
                    <div className='action_button margin-t1' onClick={() => updatePassword()} disabled={pwd.isUpdating || pwd.isUpdated}>
                        {pwd.isUpdating ?
                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                            :
                            pwd.isUpdated ?
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                                :
                                <>
                                    <svg viewBox="0 0 48 48">
                                        <path fill="current" d="M24.15 42q-3.75 0-7.05-1.425T11.325 36.7q-2.475-2.45-3.9-5.75Q6 27.65 6 23.9t1.425-7q1.425-3.25 3.9-5.675Q13.8 8.8 17.1 7.4 20.4 6 24.15 6q4 0 7.575 1.75t6.175 4.8v-5.3h3v10.4H30.45v-3h5.25q-2.2-2.55-5.175-4.1Q27.55 9 24.15 9q-6.25 0-10.7 4.275Q9 17.55 9 23.75q0 6.35 4.4 10.8Q17.8 39 24.15 39q6.25 0 10.55-4.4Q39 30.2 39 23.95h3q0 7.5-5.2 12.775T24.15 42Zm6.1-9.85-7.7-7.6v-10.7h3v9.45L32.4 30Z" />
                                    </svg>

                                    {t('update')}
                                </>
                        }
                    </div>
                </div>
            </div>
            <div className='source-img'>
                <img ref={originalImg} src={imgSrc} alt="" />
            </div>
        </div>
    )
}

export default ProfileUpdate