import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { AuthContext } from "./auth";
import { useTranslation } from "react-i18next";
import PrivateRoute from "./PrivateRoute";

import axios from "axios";

// MAINTENANCE
// import Maintenance from "./components/Maintenance";
// import ThreadMask from "./components/Composer/ThreadMask";

// NAV
import Header from "./components/header/Header";
import SidebarCESAM from "./components/sidebar/SidebarCESAM";
import DashboardCesam from "./components/dashboard/DashboardCesam";
import Dashboard from "./components/DashboardAdv/Dashboard";
import NotFound from "./components/NotFound";
import Footer from "./components/header/Footer";
import StateModal from "./components/Loader/StateModal";

// SIGNUP - ONBOARDING
import Onboarding from "./components/Onboarding/Onboarding";
import OnboardingPaymentConfirmed from "./components/Onboarding/OnboardingPaymentConfirmed";

import CanvaAuth from "./components/signin/CanvaAuth";
import CanvaAuthTest from "./components/signin/CanvaAuthTest";
import SigninStripped from "./components/signin/SigninStripped";
import SignupStripped from "./components/signin/SignupStripped";
import SignupCanva from "./components/signin/SignupCanva";
import TeamSignupStripped from "./components/signin/TeamSignupStripped";
import PasswordReset from "./components/signin/PasswordReset";
import NewPassword from "./components/signin/NewPassword";
import EmailConf from "./components/signin/EmailConf";

// CREATE
import ComposeThreadS3 from "./components/Composer/ComposeThreadS3";
import ContentAIComposer from "./components/ContentAI/ContentAIComposer";

import PostEditor from "./components/Editor/PostEditor";
import PinEditor from "./components/Editor/PinEditor";
import RSSPostEditor from "./components/Editor/RSSPostEditor";

// SCHEDULE
import CalendarAdvanced from "./components/CalendarView/CalendarAdvanced";
import CalendarAdvancedWeek from "./components/CalendarView/CalendarAdvancedWeek";
import Drafts from "./components/Drafts/Drafts";
import GridViews from "./components/Grid/GridViews";

import CalendarThirdMonthly from "./components/CalendarView/CalendarThirdParty/CalendarThirdMonthly";
import CalendarThirdWeekly from "./components/CalendarView/CalendarThirdParty/CalendarThirdWeekly";

// ENGAGE
import Engage from "./components/Engage/Engage";

// ANALYSE
import AnalyticsPerPost from "./components/Analytics/AnalyticsPerPost";
import AnalyticsPerAccount from "./components/Analytics/AnalyticsPerAccount";

// MANAGE
// import Smlink from "./components/smlink/Smlink";
import SmlinkCallback from "./components/smlink/SmlinkCallback";
import LibraryMDFGrid from "./components/library/LibraryMDFGrid";
import SMAccounts from "./components/Manage/SMAccounts";
import TeamManagement from "./components/Manage/TeamManagement";
import ThirdPartyManagement from "./components/Manage/ThirdPartyManagement";
import Tools from "./components/Manage/Tools";

// OTHER
import AffiliationIntro from "./components/subscriptions/AffiliationIntro";
import News from "./components/settings/News";

// SUBSCRIPTION
import Account from "./components/settings/Account";
import PlansMDN from "./components/subscriptions/PlansMDN";
import PlansManagement from "./components/Plans/PlansManagement";
import CancellationCustom from "./components/subscriptions/CancellationCustom";
import OrderComplete from "./components/subscriptions/OrderComplete";
import OrderCancel from "./components/subscriptions/OrderCancel";

import { LinkedInPopUp } from "react-linkedin-login-oauth2";

import "./components/styles/style.css";
import "./components/styles/forms.css";
import "./components/styles/tables.css";

const App = () => {
  const [authTokens, setAuthTokens] = useState(
    JSON.parse(localStorage.getItem("authTokens")) || ""
  );

  const setTokens = (data) => {
    localStorage.setItem("authTokens", JSON.stringify(data));
    setAuthTokens(data);
  };

  const headers = {
    Authorization: authTokens.token,
  };

  const { t } = useTranslation(["authorizations"]);

  const [navComponent, setNavComponent] = useState("contacts");
  const [navTitle, setNavTitle] = useState("");
  const [mediaTransfer, setMediaTransfer] = useState([]);

  const changeNav = (newTitle, navComponent) => {
    setNavTitle(newTitle);
    setNavComponent(navComponent);
  };

  const [stateModalOptions, setStateModalOptions] = useState({
    display: false,
    status: "",
    message: "",
    loader: false,
  });

  const closeStateModal = () => {
    setStateModalOptions({
      display: false,
      status: "",
      message: "",
      loader: false,
      button_text: "",
      button_link: "",
    });
  };

  const checkUnauthorizedResponse = (response) => {
    let message;
    let button_text = "";
    let button_link = "";
    let message_details = [];

    if (response.isExpired) {
      message = t("account_expired");
    } else if (response.analyticsIsExpired) {
      message = t("analytics_expired");
    } else if (response.teamIsExpired) {
      message = t("team_expired");
    } else if (response.pubMaxed) {
      message = t("pub_maxed");
    } else if (response.pubMaxedAdv) {
      message = t("pub_maxed_adv");
      button_text = t("upgrade");
      button_link = "/plans";
      message_details = response.message_details;
    } else if (response.smMaxed) {
      message = t("sm_maxed");
      button_text = t("upgrade");
      button_link = "/plans";
    } else if (response.isNotAdmin) {
      message = t("not_admin");
    } else if (response.planDoesNotallow) {
      message = t("plan_does_not_allow");
    } else if (response.teamMaxed) {
      message = t("team_maxed");
    } else {
      message = t("contact_us");
    }

    setStateModalOptions({
      ...stateModalOptions,
      display: true,
      status: "error",
      loader: false,
      message: message,
      message_details: message_details,
      button_text: button_text,
      button_link: button_link,
    });

    if (response.analyticsIsExpired) {
      setAuthTokens({
        ...authTokens,
        analytics: false,
      });
    } else if (response.teamIsExpired) {
      setAuthTokens({
        ...authTokens,
        team: false,
      });
    } else {
      setAuthTokens({
        ...authTokens,
        plan: response.plan,
        plan_expiry: response.expiry_date,
      });
    }
  };

  const [reloadHeaderData, setReloadHeaderData] = useState(false);

  const triggerHeaderReload = () => {
    setReloadHeaderData(!reloadHeaderData);
  };

  const [newMessagesNotif, setNewMessagesNotif] = useState([]);
  const [showMessageNotif, setShowMessageNotif] = useState(false);

  const checkMessageNotification = async () => {
    const payload = {
      company_id: authTokens.account_id,
      user_id: authTokens.user_id,
    };

    await axios
      .post(
        process.env.REACT_APP_SOCIALPIKA_API_URL +
          "/engagement/get_latest_notif/",
        payload,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setNewMessagesNotif(response.data);

        let new_msg = false;
        if (response && response.data && response.data.length > 0) {
          response.data.forEach((mediaNotif) => {
            if (mediaNotif.new_message) {
              new_msg = true;
            }
          });
        }
        setShowMessageNotif(new_msg);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      console.log("Dev mode: No chat app");
    } else {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_ID;

      (function () {
        var d = document;
        var s = d.createElement("script");

        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
        <Route
          render={() => (
            <Switch>
              {/* <Route path="/" render={(props) => <Maintenance {...props} />} /> */}
              <Route
                path="/canva_auth"
                render={(props) => <CanvaAuth {...props} />}
              />
              <Route
                path="/canva_auth_test"
                render={(props) => <CanvaAuthTest {...props} />}
              />
              <Route
                path="/signin"
                exact
                render={(props) => <SigninStripped {...props} />}
              />
              <Route
                path="/signup"
                exact
                render={(props) => <SignupStripped {...props} />}
              />
              <Route
                path="/signup_canva"
                exact
                render={(props) => <SignupCanva {...props} />}
              />
              <Route
                path="/team_signup/:id"
                exact
                render={(props) => <TeamSignupStripped {...props} />}
              />
              <Route
                path="/password_reset"
                exact
                render={(props) => <PasswordReset {...props} />}
              />
              <Route
                path="/new_password/:id"
                exact
                render={(props) => <NewPassword {...props} />}
              />
              <Route
                path="/confirm_email/:id"
                exact
                render={(props) => <EmailConf {...props} />}
              />

              <Route
                path="/onboarding"
                exact
                render={(props) => <Onboarding {...props} />}
              />
              <Route
                path="/onboarding/:id"
                exact
                render={(props) => <Onboarding {...props} />}
              />
              <Route
                path="/onboarding_confirmed/:id"
                exact
                render={(props) => <OnboardingPaymentConfirmed {...props} />}
              />

              <Route
                path="/3ps/m/:id"
                render={(props) => <CalendarThirdMonthly {...props} />}
              />
              <Route
                path="/3ps/w/:id"
                render={(props) => <CalendarThirdWeekly {...props} />}
              />

              <div className="grid-container2">
                {stateModalOptions.display ? (
                  <StateModal
                    modalOptions={stateModalOptions}
                    closeStateModal={closeStateModal}
                  />
                ) : (
                  ""
                )}
                <div className="grid-sidebar2">
                  <SidebarCESAM
                    changeNav={changeNav}
                    navComponent={navComponent}
                    checkMessageNotification={checkMessageNotification}
                    showMessageNotif={showMessageNotif}
                  />
                </div>
                <div className="grid-header2">
                  <Header
                    navTitle={navTitle}
                    reloadHeaderData={reloadHeaderData}
                  />
                </div>
                <div className="grid-content2">
                  <PrivateRoute
                    path="/"
                    exact
                    render={(props) => (
                      <Dashboard
                        {...props}
                        changeNav={changeNav}
                        checkUnauthorizedResponse={checkUnauthorizedResponse}
                      />
                    )}
                  />

                  <PrivateRoute
                    path="/engage"
                    exact
                    render={(props) => (
                      <Engage
                        {...props}
                        changeNav={changeNav}
                        checkMessageNotification={checkMessageNotification}
                        newMessagesNotif={newMessagesNotif}
                      />
                    )}
                  />

                  <PrivateRoute
                    path="/post/new_post"
                    exact
                    render={(props) => (
                      <PostEditor
                        {...props}
                        changeNav={changeNav}
                        mediaTransfer={mediaTransfer}
                        checkUnauthorizedResponse={checkUnauthorizedResponse}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/post/new_post/:id"
                    render={(props) => (
                      <PostEditor {...props} changeNav={changeNav} />
                    )}
                    checkUnauthorizedResponse={checkUnauthorizedResponse}
                  />
                  <PrivateRoute
                    path="/post/thread"
                    exact
                    render={(props) => (
                      <ComposeThreadS3
                        {...props}
                        changeNav={changeNav}
                        checkUnauthorizedResponse={checkUnauthorizedResponse}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/post/thread/:id"
                    render={(props) => (
                      <ComposeThreadS3
                        {...props}
                        changeNav={changeNav}
                        checkUnauthorizedResponse={checkUnauthorizedResponse}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/post/rss"
                    exact
                    render={(props) => (
                      <RSSPostEditor
                        {...props}
                        changeNav={changeNav}
                        checkUnauthorizedResponse={checkUnauthorizedResponse}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/post/rss/:id"
                    exact
                    render={(props) => (
                      <RSSPostEditor
                        {...props}
                        changeNav={changeNav}
                        checkUnauthorizedResponse={checkUnauthorizedResponse}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/post/burst_post"
                    exact
                    render={(props) => (
                      <PostEditor
                        {...props}
                        changeNav={changeNav}
                        mediaTransfer={mediaTransfer}
                        checkUnauthorizedResponse={checkUnauthorizedResponse}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/post/burst_post/:id"
                    render={(props) => (
                      <PostEditor {...props} changeNav={changeNav} />
                    )}
                    checkUnauthorizedResponse={checkUnauthorizedResponse}
                  />
                  <PrivateRoute
                    path="/post/pins"
                    exact
                    render={(props) => (
                      <PinEditor
                        {...props}
                        changeNav={changeNav}
                        mediaTransfer={mediaTransfer}
                        checkUnauthorizedResponse={checkUnauthorizedResponse}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/post/pins/:id"
                    render={(props) => (
                      <PinEditor {...props} changeNav={changeNav} />
                    )}
                    checkUnauthorizedResponse={checkUnauthorizedResponse}
                  />

                  <PrivateRoute
                    path="/content_ai"
                    exact
                    render={(props) => (
                      <ContentAIComposer
                        {...props}
                        changeNav={changeNav}
                        mediaTransfer={mediaTransfer}
                        checkUnauthorizedResponse={checkUnauthorizedResponse}
                      />
                    )}
                  />
                  {/* <PrivateRoute path="/content_ai" render={(props) => (<Maintenance {...props} />)} /> */}

                  <PrivateRoute
                    path="/calendar/weekly"
                    exact
                    render={(props) => (
                      <CalendarAdvancedWeek {...props} changeNav={changeNav} />
                    )}
                  />
                  <PrivateRoute
                    path="/calendar/monthly"
                    exact
                    render={(props) => (
                      <CalendarAdvanced {...props} changeNav={changeNav} />
                    )}
                  />
                  <PrivateRoute
                    path="/grid"
                    exact
                    render={(props) => (
                      <GridViews {...props} changeNav={changeNav} />
                    )}
                  />
                  <PrivateRoute
                    path="/drafts"
                    exact
                    render={(props) => (
                      <Drafts {...props} changeNav={changeNav} />
                    )}
                  />

                  <PrivateRoute
                    path="/analytics_per_post"
                    exact
                    render={(props) => (
                      <AnalyticsPerPost
                        {...props}
                        changeNav={changeNav}
                        checkUnauthorizedResponse={checkUnauthorizedResponse}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/analytics_per_account"
                    exact
                    render={(props) => (
                      <AnalyticsPerAccount
                        {...props}
                        changeNav={changeNav}
                        checkUnauthorizedResponse={checkUnauthorizedResponse}
                      />
                    )}
                  />

                  <PrivateRoute
                    path="/linkedinLogin"
                    exact
                    render={(props) => <LinkedInPopUp {...props} />}
                  />
                  <PrivateRoute
                    path="/linked"
                    exact
                    render={(props) => <SmlinkCallback {...props} />}
                  />
                  <PrivateRoute
                    path="/library"
                    exact
                    render={(props) => (
                      <LibraryMDFGrid
                        {...props}
                        changeNav={changeNav}
                        setMediaTransfer={setMediaTransfer}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/sm_accounts"
                    exact
                    render={(props) => (
                      <SMAccounts {...props} changeNav={changeNav} />
                    )}
                  />
                  <PrivateRoute
                    path="/sm_accounts/ig_login/"
                    render={(props) => (
                      <SMAccounts {...props} changeNav={changeNav} />
                    )}
                  />
                  <PrivateRoute
                    path="/sm_accounts/tiktok/"
                    render={(props) => (
                      <SMAccounts {...props} changeNav={changeNav} />
                    )}
                  />
                  <PrivateRoute
                    path="/team"
                    exact
                    render={(props) => (
                      <TeamManagement {...props} changeNav={changeNav} />
                    )}
                  />
                  <PrivateRoute
                    path="/third_parties"
                    exact
                    render={(props) => (
                      <ThirdPartyManagement {...props} changeNav={changeNav} />
                    )}
                  />
                  <PrivateRoute
                    path="/lists"
                    exact
                    render={(props) => (
                      <Tools {...props} changeNav={changeNav} />
                    )}
                  />

                  <PrivateRoute
                    path="/subscription"
                    exact
                    render={(props) => (
                      <PlansMDN {...props} changeNav={changeNav} />
                    )}
                  />
                  <PrivateRoute
                    path="/plans"
                    exact
                    render={(props) => (
                      <PlansManagement
                        {...props}
                        changeNav={changeNav}
                        triggerHeaderReload={triggerHeaderReload}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/cancel_plan"
                    exact
                    render={(props) => (
                      <CancellationCustom
                        {...props}
                        changeNav={changeNav}
                        checkUnauthorizedResponse={checkUnauthorizedResponse}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/order_complete/:id"
                    exact
                    render={(props) => (
                      <OrderComplete
                        {...props}
                        changeNav={changeNav}
                        triggerHeaderReload={triggerHeaderReload}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/order_cancel"
                    exact
                    render={(props) => (
                      <OrderCancel {...props} changeNav={changeNav} />
                    )}
                  />

                  <PrivateRoute
                    path="/account"
                    exact
                    render={(props) => (
                      <Account {...props} changeNav={changeNav} />
                    )}
                  />
                  <PrivateRoute
                    path="/news"
                    exact
                    render={(props) => (
                      <News {...props} changeNav={changeNav} />
                    )}
                  />
                  <PrivateRoute
                    path="/affiliates"
                    exact
                    render={(props) => (
                      <AffiliationIntro {...props} changeNav={changeNav} />
                    )}
                  />
                </div>
                <div className="grid-footer2">
                  <Footer navTitle={navTitle} />
                </div>
              </div>
              <Route component={NotFound} />
            </Switch>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
