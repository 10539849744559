import React, { useState } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import StateModal from '../../Loader/StateModal';

const FoldersTree = ({ authTokens, userIdForLibrary, userFolders, setUserFolders, currentFolder, setCurrentFolder, getUserFolders, moveFiles, bulkMoveDestination, setBulkMoveDestination, bulkMoveFiles, selectFolder }) => {
    const { t } = useTranslation(['library']);

    const isAdmin = authTokens.user_admin;
    const headers = {
        Authorization: authTokens.token,
    };

    const [stateModalOptions, setStateModalOptions] = useState({
        display: false,
        status: "",
        message: "",
        loader: false,
        button_link: "",
        button_text: ""
    });
    const closeStateModal = () => {
        setStateModalOptions({
            display: false,
            status: "",
            message: "",
            loader: false
        })
    }

    const [showNewFolder, setShowNewFolder] = useState(false);
    const displayNewFolder = () => {
        setShowNewFolder(!showNewFolder);
    }



    const [newFolder, setNewFolder] = useState({
        name: "",
        isSubmitting: false
    })

    const deleteFolder = async (folder_id) => {

        if (userIdForLibrary !== authTokens.user_id && !isAdmin) {
            setStateModalOptions({
                ...stateModalOptions,
                display: true,
                loader: false,
                message: t('no_auth_to_delete')
            });
            return;
        }



        setStateModalOptions({
            ...stateModalOptions,
            display: true,
            loader: true,
            message: t('building_library')
        });

        const payload = {
            folder_id: folder_id,
            company_id: authTokens.account_id,
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/media/delete_folder", payload, {
                headers: headers,
            })
            .then(() => {
                getUserFolders();
                closeStateModal();
            })
            .catch((error) => {
                if (error.response.data.statusCode === 4000 || error.response.data.statusCode === 4001) {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        status: "error",
                        loader: false,
                        message: t('folder_not_empty')
                    });
                }
                else {
                    setStateModalOptions({
                        ...stateModalOptions,
                        display: true,
                        status: "error",
                        loader: false,
                        message: t('error_srv')
                    });
                }

            });
    }

    const addFolder = async (e) => {

        if (e.key !== 'Enter') return;

        setNewFolder({
            ...newFolder,
            isSubmitting: true
        })

        const payload = {
            company_id: authTokens.account_id,
            user_id: userIdForLibrary,
            folder_name: newFolder.name,
            parent_folder_id: newFolder.parent_folder_id
        }

        await axios
            .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/media/create_folder", payload, {
                headers: headers,
            })
            .then(() => {
                getUserFolders()
                setNewFolder({});
            })
            .catch(() => {
                setNewFolder({
                    ...newFolder,
                    isSubmitting: false
                })
            });
        displayNewFolder();

    }



    return (
        <>
            {stateModalOptions.display ? <StateModal modalOptions={stateModalOptions} closeStateModal={closeStateModal} /> : ""}
            {showNewFolder ?
                <div className='new-folder'>
                    <span className="close_modal" onClick={displayNewFolder}>&times;</span>
                    <p>{t('new_folder')}</p>
                    <input type="text" className="new_folder_input" placeholder={t('type_name_enter')} autoFocus={true} onChange={e => setNewFolder({ ...newFolder, name: e.target.value })} onKeyDown={(e) => addFolder(e)} />
                </div>
                : ""
            }
            <ul className={moveFiles ? "folders-tree-content move-files" : "folders-tree-content"} >
                {(userFolders && userFolders.length > 0) ?
                    userFolders.map((folder, f) => {
                        return (
                            <li key={folder._id} id={folder._id}>
                                <div className={moveFiles && bulkMoveDestination._id === folder._id ? "folder-data selected-for-move" : "folder-data"} onClick={() => moveFiles ? setBulkMoveDestination(folder) : selectFolder(folder)}>
                                    {!currentFolder.parent_folder_id || currentFolder._id === folder._id ?
                                        <svg viewBox="0 0 24 24">
                                            <path fill="current" d="M6.1,10L4,18V8H21A2,2 0 0,0 19,6H12L10,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H19C19.9,20 20.7,19.4 20.9,18.5L23.2,10H6.1M19,18H6L7.6,12H20.6L19,18Z" />
                                        </svg>
                                        :
                                        <svg viewBox="0 0 24 24">
                                            <path fill="current" d="M20,18H4V8H20M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z" />
                                        </svg>
                                    }
                                    {f === 0 ? "/" : folder.name}
                                    <div className='folder-action'>
                                        <svg viewBox="0 0 24 24" className='svg-green' onClick={() => { displayNewFolder(); setNewFolder({ ...newFolder, parent_folder_id: folder._id }) }}>
                                            <path fill="current" d="M13 19C13 19.34 13.04 19.67 13.09 20H4C2.9 20 2 19.11 2 18V6C2 4.89 2.89 4 4 4H10L12 6H20C21.1 6 22 6.89 22 8V13.81C21.39 13.46 20.72 13.22 20 13.09V8H4V18H13.09C13.04 18.33 13 18.66 13 19M20 18V15H18V18H15V20H18V23H20V20H23V18H20Z" />
                                        </svg>
                                    </div>
                                </div>

                                {folder.subFolders && folder.subFolders.length > 0 ?
                                    <ul className="subfolder-content">
                                        {folder.subFolders.map(subfolder => {
                                            return (
                                                <li key={subfolder._id} id={subfolder._id} >
                                                    <div className='row centered'>
                                                        <div className={moveFiles && bulkMoveDestination._id === subfolder._id ? "folder-data selected-for-move" : "folder-data"} onClick={() => moveFiles ? setBulkMoveDestination(subfolder) : selectFolder(subfolder)}>
                                                            {currentFolder._id === subfolder._id ?
                                                                <svg viewBox="0 0 24 24">
                                                                    <path fill="current" d="M6.1,10L4,18V8H21A2,2 0 0,0 19,6H12L10,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H19C19.9,20 20.7,19.4 20.9,18.5L23.2,10H6.1M19,18H6L7.6,12H20.6L19,18Z" />
                                                                </svg>
                                                                :
                                                                <svg viewBox="0 0 24 24">
                                                                    <path fill="current" d="M20,18H4V8H20M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z" />
                                                                </svg>
                                                            }
                                                            {subfolder.name}
                                                        </div>
                                                        <div className='subfolder-action'>
                                                            <svg viewBox="0 0 24 24" className='svg-green' onClick={() => { displayNewFolder(); setNewFolder({ ...newFolder, parent_folder_id: subfolder._id }) }}>
                                                                <path fill="current" d="M13 19C13 19.34 13.04 19.67 13.09 20H4C2.9 20 2 19.11 2 18V6C2 4.89 2.89 4 4 4H10L12 6H20C21.1 6 22 6.89 22 8V13.81C21.39 13.46 20.72 13.22 20 13.09V8H4V18H13.09C13.04 18.33 13 18.66 13 19M20 18V15H18V18H15V20H18V23H20V20H23V18H20Z" />
                                                            </svg>
                                                            <svg viewBox="0 0 24 24" className='svg-red' onClick={() => deleteFolder(subfolder._id)}>
                                                                <path fill="current" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    {subfolder.subFolders && subfolder.subFolders.length > 0 ?
                                                        <ul className="subfolder-content">
                                                            {subfolder.subFolders.map(subsubfolder => {
                                                                return (
                                                                    <li key={subsubfolder._id} id={subsubfolder._id} >
                                                                        <div className='row centered'>
                                                                            <div className={moveFiles && bulkMoveDestination._id === subsubfolder._id ? "folder-data selected-for-move" : "folder-data"} onClick={() => moveFiles ? setBulkMoveDestination(subsubfolder) : selectFolder(subsubfolder)}>
                                                                                {currentFolder._id === subsubfolder._id ?
                                                                                    <svg viewBox="0 0 24 24">
                                                                                        <path fill="current" d="M6.1,10L4,18V8H21A2,2 0 0,0 19,6H12L10,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H19C19.9,20 20.7,19.4 20.9,18.5L23.2,10H6.1M19,18H6L7.6,12H20.6L19,18Z" />
                                                                                    </svg>
                                                                                    :
                                                                                    <svg viewBox="0 0 24 24">
                                                                                        <path fill="current" d="M20,18H4V8H20M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z" />
                                                                                    </svg>
                                                                                }
                                                                                {subsubfolder.name}
                                                                            </div>
                                                                            <div className='subfolder-action'>
                                                                                <svg viewBox="0 0 24 24" className='svg-red' onClick={() => deleteFolder(subsubfolder._id)}>
                                                                                    <path fill="current" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                        : ""}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    : ""}
                            </li>
                        )
                    })
                    :
                    ""
                }
            </ul>
            {moveFiles && bulkMoveDestination._id && bulkMoveDestination._id !== currentFolder._id ?
                <div className='green_button margin-t2' onClick={() => bulkMoveFiles()}>{t('move_files_to')} /{bulkMoveDestination.parent_folder_id ? bulkMoveDestination.name : ""}</div>
                : ""}
        </>
    )
}

export default FoldersTree