import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import TooltipHelp from '../settings/TooltipHelp';
import Draggable from 'react-draggable';

import "./image_cropper.css";
import "./image_tagger.css";

let keystrokeTimer;


const ImageTagger = ({ authTokens, displayTagger, publicationData, setPublicationData }) => {
    const { t } = useTranslation(['composer']);
    const imgRef = useRef(null);

    const headers = {
        Authorization: authTokens.token,
    };

    const [imgSrc, setImgSrc] = useState();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [lookupData, setLookupData] = useState([]);
    const [showTagSelector, setShowTagSelector] = useState(false);
    const [inputTag, setInputTag] = useState('');

    const changeImgToTag = (index) => {
        if (publicationData.medias && publicationData.medias.length > 0) {
            setImgSrc(publicationData.medias[index].file_url);
            setSelectedIndex(index);
        }
    }


    const handleTagInput = (e) => {
        setInputTag(e.target.value);

        clearTimeout(keystrokeTimer);

        keystrokeTimer = setTimeout(() => {
            const tag = e.target.value.trim();
            igLookup(tag);
        }, 1000);
    }

    const igLookup = (tag) => {
        if (tag.charAt(0) === "@" && tag.length > 0 && ((publicationData.medias.ig_tags && publicationData.medias.ig_tags.length > 0 && !publicationData.medias.ig_tags.some(e => e.tag === tag)) || !publicationData.medias.ig_tags || (publicationData.medias.ig_tags && publicationData.medias.ig_tags.length === 0))) {
            // if (tag.charAt(0) === "@" && !tags.some(e => e.tag === tag) && tag.length > 3) {
            setShowTagSelector(true);
            const payload = {
                tag: tag,
                network: "Instagram",
                company_id: authTokens.account_id
            }
            axios
                .post(process.env.REACT_APP_SOCIALPIKA_API_URL + "/scraper/tag_lookup", payload, {
                    headers: headers,
                })
                .then((response) => {
                    if (response.data.lookup_result) {
                        setLookupData(response.data.lookup_data);
                    }
                    else {
                        setLookupData([]);
                        setShowTagSelector(false);
                    }

                })
                .catch(() => {
                    setLookupData([]);
                    setShowTagSelector(false);
                });
        }
        else {
            setShowTagSelector(false);
        }
    }

    const tagAccount = (account) => {
        let updated_tags_array = [];

        if (publicationData.medias[selectedIndex].ig_tags && publicationData.medias[selectedIndex].ig_tags.length > 0) {
            updated_tags_array = [...publicationData.medias[selectedIndex].ig_tags, account];
        }
        else {
            updated_tags_array = [account];
        }
        const updated_media = {
            ...publicationData.medias[selectedIndex],
            ig_tags: updated_tags_array
        }
        setShowTagSelector(false);
        setLookupData([]);
        setInputTag('');

        setPublicationData({
            ...publicationData,
            medias: [
                ...publicationData.medias.slice(0, selectedIndex),
                updated_media,
                ...publicationData.medias.slice(selectedIndex + 1, publicationData.medias.length),
            ]
        })
    }

    const untagAccount = (account) => {
        const updated_tags_array = publicationData.medias[selectedIndex].ig_tags.filter(tagData => tagData.tag !== account.tag);
        const updated_media = {
            ...publicationData.medias[selectedIndex],
            ig_tags: updated_tags_array
        }
        setPublicationData({
            ...publicationData,
            medias: [
                ...publicationData.medias.slice(0, selectedIndex),
                updated_media,
                ...publicationData.medias.slice(selectedIndex + 1, publicationData.medias.length),
            ]
        })
    }

    const [currentImgWidth, setCurrentImgWidth] = useState(0);
    const [currentImgHeight, setCurrentImgHeight] = useState(0);
    const getImgSize = ({ target: img }) => {
        const { offsetHeight, offsetWidth } = img;
        setCurrentImgWidth(offsetWidth);
        setCurrentImgHeight(offsetHeight);
    };

    const updateTagPosition = (e, data) => {
        const tag = e.target.id;
        e.preventDefault();

        const position_X = data.x / currentImgWidth;
        const position_Y = (data.y + currentImgHeight / 2) / currentImgHeight;

        // Look for the right tag
        const tag_index = publicationData.medias[selectedIndex].ig_tags.findIndex(tagData => tagData.tag === tag);

        if (tag_index !== -1) {
            const updated_tag = {
                ...publicationData.medias[selectedIndex].ig_tags[tag_index],
                position_X: data.x,
                position_Y: data.y,
                ig_X: position_X,
                ig_Y: position_Y
            }

            const updated_tags = [
                ...publicationData.medias[selectedIndex].ig_tags.slice(0, tag_index),
                updated_tag,
                ...publicationData.medias[selectedIndex].ig_tags.slice(tag_index + 1, publicationData.medias[selectedIndex].ig_tags.length),
            ];

            const updated_media = {
                ...publicationData.medias[selectedIndex],
                ig_tags: updated_tags
            }

            setPublicationData({
                ...publicationData,
                medias: [
                    ...publicationData.medias.slice(0, selectedIndex),
                    updated_media,
                    ...publicationData.medias.slice(selectedIndex + 1, publicationData.medias.length),
                ]
            })
        }

    }




    useEffect(() => {
        if (publicationData.medias && publicationData.medias.length > 0) {
            setImgSrc(publicationData.medias[0].file_url);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="img_cropper_bg active"></div>
            <div className="img_cropper_wrapper">
                <span className="close_modal" onClick={displayTagger}>&times;</span>
                <div className="img_cropper_title">{t('ig_photos_tagging')}</div>
                <div className="img_cropper_content">
                    {publicationData.medias && publicationData.medias.length > 0 ?
                        <div className='sources_photos flex-1'>
                            <div className="tagging_title margin-b2">{t('ig_photos_tagging_select_photo')}</div>
                            <div className="source_photos_stack">
                                {publicationData.medias.map((imageRaw, i) => {
                                    return (
                                        <div key={i} className={selectedIndex === i ? 'source_photo_stack mini-img selected' : 'source_photo_stack mini-img'} onClick={() => changeImgToTag(i)}>
                                            <img src={imageRaw.file_url} alt="" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        : ""}
                    <div className='sources_photos flex-1 relative_holder'>
                        <div className="tagging_title margin-b2">{t('ig_photos_tagging_select_people')}</div>
                        {showTagSelector ?
                            <div className='tag_selector fixed'>
                                {lookupData && lookupData.length > 0 ?
                                    lookupData.map((account, a) => {
                                        return (
                                            <div key={a} className='tag_account' onClick={() => tagAccount(account)}>
                                                <div className='tag_account_profile'>
                                                    {account.profile_url.length > 0 ?
                                                        <img src={account.profile_url} alt="account to tag" />
                                                        :
                                                        ""
                                                    }
                                                </div>
                                                <div className='tag_account_name'>
                                                    {account.network_name}
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="lds-ring-loader-small"><div></div><div></div><div></div><div></div></div>
                                }
                            </div>
                            : ""}
                        <div className='row row_vcenter'>
                            <input type="text" placeholder={t('ig_tag_via')} value={inputTag} onChange={handleTagInput} />
                            <TooltipHelp content={t('ig_input_help')} direction="right">
                                <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                    <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                                </svg>
                            </TooltipHelp>
                        </div>
                        <div className="mentions_list margin-t2">
                            {publicationData.medias[selectedIndex].ig_tags && publicationData.medias[selectedIndex].ig_tags.length > 0 ?
                                publicationData.medias[selectedIndex].ig_tags.map((account, a) => {
                                    return (
                                        <div className="mention_account" key={a}>
                                            <div className='tag_account_profile'>
                                                {account.profile_url.length > 0 ?
                                                    <img src={account.profile_url} alt="account to tag" />
                                                    :
                                                    ""
                                                }
                                            </div>
                                            <div className='tag_account_name'>
                                                {account.network_name}
                                            </div>
                                            <div className='delete_mention' onClick={() => untagAccount(account)}>
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="current" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
                                                </svg>
                                            </div>
                                        </div>
                                    )

                                })
                                : ""
                            }
                        </div>
                    </div>
                    <div className='flex-1'>
                        <div className="tagging_title margin-b2">{t('ig_photos_tagging_place_tag')}</div>
                        <div className='tagging_window'>
                            {publicationData.medias[selectedIndex].ig_tags && publicationData.medias[selectedIndex].ig_tags.length > 0 ?
                                publicationData.medias[selectedIndex].ig_tags.map((account, a) => {
                                    return (
                                        <Draggable
                                            key={"photo_tag_" + a}
                                            bounds="parent"
                                            position={{ x: account.position_X || 0, y: account.position_Y || 0 }}
                                            onStop={(e, data) => updateTagPosition(e, data)}>
                                            <div className="photo_tag" id={account.tag}>
                                                {account.tag}
                                            </div>
                                        </Draggable>
                                    )

                                })
                                : ""
                            }
                            <img
                                ref={imgRef}
                                alt="Tag me"
                                src={imgSrc}
                                onLoad={(e) => getImgSize(e)}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ImageTagger