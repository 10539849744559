import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import TooltipHelp from '../../settings/TooltipHelp';
// import PostPreviewGMB from '../../new_post/PostPreviewGMB';
import PreviewGMB from '../PostPreviews/PreviewGMB';

import "./gmb_event_creator.css";


const GMBeventCreator = ({ displayGMBcreator, publicationData, setPublicationData }) => {

    const { t } = useTranslation(['composer']);

    const [showCTAOptions, setShowCTAOptions] = useState(false);
    const displayCTAOptions = () => {
        if (showCTAOptions) {
            setPublicationData({
                ...publicationData,
                gmb_cta: ''
            });
        }
        setShowCTAOptions(!showCTAOptions);
    }

    const [showEventOptions, setShowEventOptions] = useState(false);
    const displayEventOptions = () => {
        if (showEventOptions) {
            setPublicationData({
                ...publicationData,
                gmb_event_title: '',
                gmb_event_start_date: '',
                gmb_event_start_time: '',
                gmb_event_end_date: '',
                gmb_event_end_time: '',
                gmb_topic: 'STANDARD'
            });
        }
        else {
            setPublicationData({
                ...publicationData,
                gmb_topic: 'EVENT'
            });
        }
        setShowEventOptions(!showEventOptions);
    }

    const [showOfferOptions, setShowOfferOptions] = useState(false);
    const displayOfferOptions = () => {
        if (showOfferOptions && showEventOptions) {
            setPublicationData({
                ...publicationData,
                gmb_topic: 'EVENT'
            });
        }
        else if (showOfferOptions && !showEventOptions) {
            setPublicationData({
                ...publicationData,
                gmb_event_title: '',
                gmb_topic: 'STANDARD'
            });
        }
        else {
            setPublicationData({
                ...publicationData,
                gmb_cta: '',
                gmb_topic: 'OFFER'
            });
        }
        setShowOfferOptions(!showOfferOptions);
    }


    const handleInputChange = (e) => {
        setPublicationData({
            ...publicationData,
            [e.target.name]: e.target.value
        });
    }

    return (
        <div>
            <div className="gmb_event_creator_bg"></div>
            <div className='gmb_event_creator_wrapper'>
                <span className="close_modal" onClick={displayGMBcreator}>&times;</span>
                <div className="gmb_event_creator_title">{t('gmb_event_creator_title')}</div>
                <div className="gmb_event_creator_content">
                    <div className='flex-1 gmb_event_creator_forms'>
                        <div className="row row_vcenter">
                            <div className="onoffswitch_draft">
                                <input type="checkbox" name="gmb_cta" className="onoffswitch_draft-checkbox" id="myonoffswitchGMB_CTA" checked={showCTAOptions} onChange={() => displayCTAOptions()} />
                                <label className="onoffswitch_draft-label" htmlFor="myonoffswitchGMB_CTA">
                                    <span className="onoffswitch_draft-inner"></span>
                                    <span className="onoffswitch_draft-switch"></span>
                                </label>
                            </div>
                            <label className='margin-l1'>{t('gmb_cta_switch')}</label>
                            {/* <TooltipHelp content={t('gmb_add_cta_help')} direction="right">
                                <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                    <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                                </svg>
                            </TooltipHelp> */}
                        </div>
                        {showCTAOptions ?
                            <div className='gmb_form_options'>
                                <select name="gmb_cta" onChange={e => handleInputChange(e)}>
                                    <option value="">{t('gmb_select_cta')}</option>
                                    <option value="LEARN_MORE">{t('gmb_learn_more_desc')}</option>
                                    <option value="SIGN_UP">{t('gmb_sign_up_desc')}</option>
                                    <option value="SHOP">{t('gmb_shop_desc')}</option>
                                    <option value="ORDER">{t('gmb_order_desc')}</option>
                                    <option value="BOOK">{t('gmb_book_desc')}</option>
                                    <option value="CALL">{t('gmb_call_desc')}</option>
                                </select>
                                {publicationData.gmb_cta !== "CALL" ?
                                    <div className='margin-t1'>
                                        <label>{t('gmb_cta_url')}<sup>*</sup></label>
                                        <input type="url" name="gmb_cta_url" placeholder="https://www.example.com" value={publicationData.gmb_cta_url} onChange={e => handleInputChange(e)} />
                                    </div>
                                    : ""}
                            </div>
                            : ""}
                        <div className="row row_vcenter margin-t2">
                            <div className="onoffswitch_draft">
                                <input type="checkbox" name="gmb_event" className="onoffswitch_draft-checkbox" id="myonoffswitchGMB_event" checked={showEventOptions} onChange={() => displayEventOptions()} />
                                <label className="onoffswitch_draft-label" htmlFor="myonoffswitchGMB_event">
                                    <span className="onoffswitch_draft-inner"></span>
                                    <span className="onoffswitch_draft-switch"></span>
                                </label>
                            </div>
                            <label className='margin-l1'>{t('gmb_event_switch')}</label>
                            {/* <TooltipHelp content={t('2d_warning')} direction="right">
                                <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                    <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                                </svg>
                            </TooltipHelp> */}
                        </div>
                        {showEventOptions ?
                            <div className='gmb_form_options'>
                                <div>
                                    <label>{t('gmb_event_title')}<sup>*</sup></label>
                                    <input type="text" placeholder={t('gmb_event_placeholder')} name="gmb_event_title" value={publicationData.gmb_event_title} onChange={e => handleInputChange(e)} />
                                </div>
                                <div className='row margin-t1'>
                                    <div>
                                        <label>{t('gmb_event_start_date')}<sup>*</sup></label>
                                        <input type="date" name="gmb_event_start_date" value={publicationData.gmb_event_start_date} onChange={e => handleInputChange(e)} />
                                    </div>
                                    <div className='margin-l1'>
                                        <label>{t('gmb_event_start_time')}<sup>*</sup></label>
                                        <input type="time" className='time_small' placeholder="HH:MM" name="gmb_event_start_time" value={publicationData.gmb_event_start_time} onChange={e => handleInputChange(e)} />
                                    </div>
                                </div>
                                <div className='row margin-t1'>
                                    <div>
                                        <label>{t('gmb_event_end_date')}<sup>*</sup></label>
                                        <input type="date" name="gmb_event_end_date" value={publicationData.gmb_event_end_date} onChange={e => handleInputChange(e)} />
                                    </div>
                                    <div className='margin-l1'>
                                        <label>{t('gmb_event_end_time')}<sup>*</sup></label>
                                        <input type="time" className="time_small" placeholder="HH:MM" name="gmb_event_end_time" value={publicationData.gmb_event_end_time} onChange={e => handleInputChange(e)} />
                                    </div>
                                </div>
                            </div>
                            : ""}
                        <div className="row row_vcenter margin-t2">
                            <div className="onoffswitch_draft">
                                <input type="checkbox" name="gmb_offer" className="onoffswitch_draft-checkbox" id="myonoffswitchGMB_offer" checked={showOfferOptions} onChange={() => displayOfferOptions()} disabled={!showEventOptions} />
                                <label className="onoffswitch_draft-label" htmlFor="myonoffswitchGMB_offer">
                                    <span className="onoffswitch_draft-inner"></span>
                                    <span className="onoffswitch_draft-switch"></span>
                                </label>
                            </div>
                            <label className='margin-l1'>{t('gmb_offer_switch')}</label>
                            <TooltipHelp content={t('gmb_offer_help')} direction="right">
                                <svg className="svg-2 svg-red margin-l1" viewBox="0 0 24 24">
                                    <path fill="current" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                                </svg>
                            </TooltipHelp>
                        </div>
                        {showOfferOptions ?
                            <div className='gmb_form_options'>
                                <div>
                                    <label>{t('gmb_offer_terms')}<sup>*</sup></label>
                                    <input type="text" className='long_input' placeholder={t('gmb_offer_terms_placeholder')} name="gmb_offer_terms" value={publicationData.gmb_offer_terms} onChange={e => handleInputChange(e)} />
                                </div>
                                <div className='row margin-t1'>
                                    <div>
                                        <label>{t('gmb_offer_coupon')}</label>
                                        <input type="text" placeholder={t('gmb_offer_coupon_placeholder')} name="gmb_offer_coupon_code" value={publicationData.gmb_offer_coupon_code} onChange={e => handleInputChange(e)} />
                                    </div>
                                    <div className='margin-l1'>
                                        <label>{t('gmb_offer_url')}</label>
                                        <input type="url" placeholder="https://example.com" name="gmb_offer_url" value={publicationData.gmb_offer_url} onChange={e => handleInputChange(e)} />
                                    </div>
                                </div>
                            </div>
                            : ""}
                    </div>
                    <div className='flex-1'>
                        {<PreviewGMB publicationData={publicationData} selectedPhotos={publicationData.medias} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GMBeventCreator